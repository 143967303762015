<div class ="container">
<div class="row content-area"><br>
    <br>
    <br>
    <div class="col-sm-12 title">
        <div *ngIf="notificationType == 'OLD'">
            SCRUTINY WORKBENCH - Old Notification
        </div>
        <div *ngIf="notificationType == 'NEW'">
            SCRUTINY WORKBENCH - New Notification
        </div>
    </div>
    
    <div class="label col-sm-10"  *ngIf = "applicationData">
  
        <div class ="col-sm-12 text-title">JCC NO:{{applicationData.applicationNo}} ( {{title}} )</div>
      
        <div >
            <button (click)="clickBack()"class="back-link">Back</button>
         </div>
       <div>
        <button  (click) ="encloPage()" class="enclosure-link">Enclosure </button> 
    </div> 
    
   
 
   
 <br>
<br>
<br>
    <div class="sub-title">
           Personal Details
        </div>
        
        <div class="row" >
            <div class="col-sm-8 " >
                <label >Name of the applicant</label>
            </div>
            <div class="col-sm-4">
                <label >{{applicationData.applicantName}}</label>
              </div>
        </div>
        <div class="row" >
            <div class="col-sm-8 " >
                <label >Name of the Village</label>
            </div>
            <div class="col-sm-4">
                <label >{{ applicationData.villageDTO.name}}</label>
              </div>
        </div>
       
        <div class="row" >
            <div class="col-sm-8 " >
                <label >Address of the applicant</label>
            </div>
            <div class="col-sm-4">
                <label >{{applicationData.applicantAddress}}</label>
              </div>
        </div>
        <div class="row" >
            <div class="col-sm-8 " >
                <label >Landline Number</label>
            </div>
            <div class="col-sm-4">
                <label >{{applicationData.telephoneNumber}}</label>
              </div>
        </div>
        <div class="row" >
            <div class="col-sm-8 " >
                <label >Mobile Number</label>
            </div>
            <div class="col-sm-4">
                <label >{{applicationData.mobileNumber}}</label>
              </div>
        </div>
        <div class="row" >
            <div class="col-sm-8 " >
                <label >Email</label>
            </div>
            <div class="col-sm-4">
                <label >{{applicationData.email}}</label>
              </div>
        </div>
        <div class="row" >
            <div class="col-sm-8 " >
                <label >Survey Number & Site Number</label>
            </div>
            <div class="col-sm-4">
                <label >{{applicationData.surveyNumber}}</label>
              </div>
        </div>
        <!-- <div class="row" >
            <div class="col-sm-8 " >
                <label >Aadhar Number</label>
            </div>
            <div class="col-sm-4">
                <label >{{ applicationData.aadharNumber}}</label>
              </div>
        </div> -->
        <!-- <div class="row" >
            <div class="col-sm-8 " >
                <label >Age</label>
            </div>
            <div class="col-sm-4">
                <label >{{applicationData.applicantAge}}</label>
              </div>
        </div>
        <div class="row" >
            <div class="col-sm-8 " >
                <label >Gender</label>
            </div>
            <div class="col-sm-4">
                <label >{{applicationData.applicantGender}}</label>
              </div>
        </div> -->
        <div class="sub-title">
            Site Details
         </div>
       
   
        <!-- <div class="row" >
            <div class="col-sm-8 " >
                <label >Site Number</label>
            </div>
            <div class="col-sm-4">
                <label >{{applicationData.siteNumber}}</label>
              </div>
        </div> -->
        <div class="row" >
            <div class="col-sm-8 " >
                <label >Length of the site</label>
            </div>
            <div class="col-sm-4">
                <label >{{applicationData.siteLength}}</label>
              </div>
        </div>
        <div class="row" >
            <div class="col-sm-8 " >
                <label >Width of the site</label>
            </div>
            <div class="col-sm-4">
                <label >{{applicationData.siteBreadth}}</label>
              </div>
        </div>
        <div class="row" >
            <div class="col-sm-8 " >
                <label >Total Area in Sq ft</label>
            </div>
            <div class="col-sm-4">
                <label >{{applicationData.siteArea}}</label>
              </div>
        </div>
        <div class="row" >
            <div class="col-sm-8 " >
                <label >Site North</label>
            </div>
            <div class="col-sm-4">
                <label >{{applicationData.siteNorth}}</label>
              </div>
        </div>
        <div class="row" >
            <div class="col-sm-8 " >
                <label >Side East</label>
            </div>
            <div class="col-sm-4">
                <label >{{applicationData.siteEast}}</label>
              </div>
        </div>
        <div class="row" >
            <div class="col-sm-8 " >
                <label >Site West</label>
            </div>
            <div class="col-sm-4">
                <label >{{applicationData.siteWest}}</label>
              </div>
        </div>
        <div class="row" >
            <div class="col-sm-8 " >
                <label >Site South</label>
            </div>
            <div class="col-sm-4">
                <label >{{applicationData.siteSouth}}</label>
              </div>
        </div>
        <div class="row" >
            <div class="col-sm-8 " >
                <label >Khata Type</label>
            </div>
            <div class="col-sm-4">
                <label >{{applicationData.khataBbmp == 'Y' ? 'BBMP' : applicationData.khataGp == 'Y' ? 'Gram Panchayat' : applicationData.khataBda ? 'BDA' : '' }}</label>
              </div>
        </div>
        <div class="row" >
            <div class="col-sm-8 " >
                <label >Khata Number </label>
            </div>
            <div class="col-sm-4">
                <label >{{applicationData.khataNumber}}</label>
              </div>
        </div>
        <div class="row" >
            <div class="col-sm-8 " >
                <label >Khata Date </label>
            </div>
            <div class="col-sm-4">
                <label >{{applicationData.khataDate | date:'dd/MM/yyyy'}}</label>
              </div>
        </div>
        <div class="row" >
            <div class="col-sm-8 " >
                <label >Address of the building</label>
            </div>
            <div class="col-sm-4">
                <label >{{ applicationData.buildingAddress}}</label>
              </div>
        </div>
        <div class="sub-title">
            Residential House Details
         </div>
        <div class="row" >
            <div class="col-sm-8 " >
                <label >Residential Builtup area </label>
            </div>
            <div class="col-sm-4">
                <label >{{applicationData.resArea}}</label>
              </div>
        </div>
        <div class="row" >
            <div class="col-sm-8 " >
                <label >Residential No of floors </label>
            </div>
            <div class="col-sm-4">
                <label >{{applicationData.resFloors}}</label>
              </div>
        </div>
        <div class="row" >
            <div class="col-sm-8 " >
                <label >Residential Total Area </label>
            </div>
            <div class="col-sm-4">
                <label >{{applicationData.resTotalarea}}</label>
              </div>
        </div>
        <div class="sub-title">
            Commercial/Industrial Building Details
         </div>
        <div class="row" >
            <div class="col-sm-8 " >
                <label >Commercial Build up area </label>
            </div>
            <div class="col-sm-4">
                <label >{{applicationData.commArea}}</label>
              </div>
        </div>
        <div class="row" >
            <div class="col-sm-8 " >
                <label >Commercial No of floors </label>
            </div>
            <div class="col-sm-4">
                <label >{{applicationData.commFloors}}</label>
              </div>
        </div>
        <div class="row" >
            <div class="col-sm-8 " >
                <label >Commercial Total Area </label>
            </div>
            <div class="col-sm-4">
                <label >{{applicationData.commTotalarea}}</label>
              </div>
        </div>
        <div class="row" >
            <div class="col-sm-8 " >
                <label >purpose/use</label>
            </div>
            <div class="col-sm-4">
                <label >{{applicationData.commPurpose}}</label>
              </div>
        </div>
        <div class="sub-title">
            Apartment / High-rise / Multi-dwelling  Details
         </div>
         <div class="row" >
            <div class="col-sm-8 " >
                <label > Area of apartment/Total area of Complex</label>
            </div>
            <div class="col-sm-4">
                <label >{{applicationData.aptTotalarea}}</label>
              </div>
        </div>
        <div class="row" >
            <div class="col-sm-8 " >
                <label > Built up area</label>
            </div>
            <div class="col-sm-4">
                <label >{{applicationData.aptBuildUpArea}}</label>
              </div>
        </div>
        <div class="row" >
            <div class="col-sm-8 " >
                <label > Floors/Blocks</label>
            </div>
            <div class="col-sm-4">
                <label >{{applicationData.aptFloors}}</label>
              </div>
        </div>
        <div class="sub-title">
            Gated Community   Details
         </div>
        <div class="row" >
            <div class="col-sm-8 " >
                <label > Gated Community Length</label>
            </div>
            <div class="col-sm-4">
                <label >{{applicationData.gcLength}}</label>
              </div>
        </div>
        <div class="row" >
            <div class="col-sm-8 " >
                <label > Gated Community Breadth</label>
            </div>
            <div class="col-sm-4">
                <label >{{applicationData.gcBreadth}}</label>
              </div>
        </div>
        <div class="row" >
            <div class="col-sm-8 " >
                <label > Gated Community Total Area</label>
            </div>
            <div class="col-sm-4">
                <label >{{applicationData.gcTotalarea}}</label>
              </div>
        </div>
        <div class="row" >
            <div class="col-sm-8 " >
                <label > Gated Community Sital Area</label>
            </div>
            <div class="col-sm-4">
                <label >{{applicationData.gcSitalarea}}</label>
              </div>
        </div>

        <div class="sub-title">
            Other Property  Details
         </div>
         <div class="row" >
            <div class="col-sm-8 " >
                <label >Build up area </label>
            </div>
            <div class="col-sm-4">
                <label >{{applicationData.otherArea}}</label>
              </div>
        </div>
        <div class="row" >
            <div class="col-sm-8 " >
                <label >Total Area </label>
            </div>
            <div class="col-sm-4">
                <label >{{applicationData.otherTotalarea}}</label>
              </div>
        </div>
        <div class="row" >
            <div class="col-sm-8 " >
                <label >purpose/use</label>
            </div>
            <div class="col-sm-4">
                <label >{{applicationData.otherPurpose}}</label>
              </div>
        </div>
        <div class="sub-title">
            Constructed with approval 
         </div>
            <div class="row" >
            <div class="col-sm-8 " >
                <label >Is constructed with approval</label>
            </div>
            <div class="col-sm-4">
                <label >{{applicationData.constNoAproval ? 'Yes' : applicationData.constNoAproval != null && applicationData.constNoAproval != undefined ? 'No' : ''}}</label>
              </div>
        </div>
        <div class="sub-title">
            BDA approved layout  Details
         </div>
        <div class="row" >
            <div class="col-sm-8 " >
                <label > BDA approval Layout LP Number</label>
            </div>
            <div class="col-sm-4">
                <label >{{applicationData.bdaLayoutAprovalNumber}}</label>
              </div>
        </div>
        <div class="row" >
            <div class="col-sm-8 " >
                <label > BDA approval Layout Date </label>
            </div>
            <div class="col-sm-4">
                <label >{{applicationData.bdaLayoutAprovalDate | date:'dd/MM/yyyy'}}</label>
              </div>
        </div>
        <div class="sub-title">
            Building Plan approved by BDA Details
         </div>
        <div class="row" >
            <div class="col-sm-8 " >
                <label >  Building plan approved by BDA LP Number</label>
            </div>
            <div class="col-sm-4">
                <label >{{applicationData.bdaAprovalNumber}}</label>
              </div>
        </div>
        <div class="row" >
            <div class="col-sm-8" >
                <label >  Building plan approved by BDA Date </label>
            </div>
            <div class="col-sm-4">
                <label >{{applicationData.bdaAprovalDate | date:'dd/MM/yyyy'}}</label>
              </div>
        </div>
        <div class="sub-title">
            Approval from panchayat Details
         </div>
        <div class="row" >
            <div class="col-sm-8 " >
                <label > Approval from panchayat LP Number</label>
            </div>
            <div class="col-sm-4">
                <label >{{applicationData.gpAprovalNumber}}</label>
              </div>
        </div>
        <div class="row" >
            <div class="col-sm-8 " >
                <label > Panchayat Date of approval</label>
            </div>
            <div class="col-sm-4">
                <label >{{applicationData.gpAprovalDate | date:'dd/MM/yyyy'}}</label>
              </div>
        </div>
        <div class="row" >
            <div class="col-sm-8 " >
                <label > Approval from Panchayat Name</label>
            </div>
            <div class="col-sm-4">
                <label >{{selectedPanchayat}}</label>
              </div>
        </div>
        <div class="sub-title">
            Approval from BBMP Details
         </div>
        <div class="row" >
            <div class="col-sm-8 " >
                <label > BBMP Approval Number</label>
            </div>
            <div class="col-sm-4">
                <label >{{applicationData.bbmpAprovalNumber}}</label>
              </div>
        </div>
        <div class="row" >
            <div class="col-sm-8 " >
                <label > BBMP Date of approval</label>
            </div>
            <div class="col-sm-4">
                <label >{{applicationData.bbmpAprovalDate | date:'dd/MM/yyyy'}}</label>
              </div>
        </div>
        <div class="sub-title">
            Conversion order of DC Details
         </div>
        <div class="row" >
            <div class="col-sm-8" >
                <label >Conversion order of DC Approval Number</label>
            </div>
            <div class="col-sm-4">
                <label >{{applicationData.dcAprovalNumber}}</label>
              </div>
        </div>
        <div class="row" >
            <div class="col-sm-8 " >
                <label >Conversion order of DC Date of approval</label>
            </div>
            <div class="col-sm-4">
                <label >{{applicationData.dcAprovalDate | date:'dd/MM/yyyy'}}</label>
              </div>
        </div>
        <div class="sub-title">
            Commencement Certificate Details
         </div>
        <div class="row" >
            <div class="col-sm-8 " >
                <label >Commencement Number</label>
            </div>
            <div class="col-sm-4">
                <label >{{applicationData.commenceNumber}}</label>
              </div>
        </div>
        <div class="row" >
            <div class="col-sm-8 " >
                <label >Commencement Date</label>
            </div>
            <div class="col-sm-4">
                <label >{{applicationData.commenceDate | date:'dd/MM/yyyy'}}</label>
              </div>
        </div>
        <div class="row" >
            <div class="col-sm-8 " >
                <label >Commencement Issuing Authority</label>
            </div>
            <div class="col-sm-4">
                <label >{{applicationData.commenceAuth}}</label>
              </div>
        </div>
        <div class="sub-title">
            Completion Certificate  Details
         </div>
        <div class="row" >
            <div class="col-sm-8 " >
                <label >Completion Number</label>
            </div>
            <div class="col-sm-4">
                <label >{{applicationData.completionNumber}}</label>
              </div>
        </div>
        <div class="row" >
            <div class="col-sm-8 " >
                <label >Completion Date</label>
            </div>
            <div class="col-sm-4">
                <label >{{applicationData.completionDate | date:'dd/MM/yyyy'}}</label>
              </div>
        </div>
        <div class="row" >
            <div class="col-sm-8 " >
                <label >Completion Issuing Authority</label>
            </div>
            <div class="col-sm-4">
                <label >{{applicationData.completionAuth}}</label>
              </div>
        </div>
        <div class="sub-title">
            Occupancy Certificate Details
         </div>
        <div class="row" >
            <div class="col-sm-8 " >
                <label >Occupancy Number</label>
            </div>
            <div class="col-sm-4">
                <label >{{applicationData.occupancyNumber}}</label>
              </div>
        </div>
        <div class="row" >
            <div class="col-sm-8 " >
                <label >Occupancy Date</label>
            </div>
            <div class="col-sm-4">
                <label >{{applicationData.occupancyDate | date:'dd/MM/yyyy'}}</label>
              </div>
        </div>
        <div class="row" >
            <div class="col-sm-8 " >
                <label >Occupancy Issuing Authority</label>
            </div>
            <div class="col-sm-4">
                <label >{{applicationData.occupancyAuth}}</label>
              </div>
        </div>
    
        <div class="sub-title">
            Sanctions of temporary electricity connection Details
         </div>
        <div class="row" >
            <div class="col-sm-8 " >
                <label >Electricity Temporary connection RR No</label>
            </div>
            <div class="col-sm-4">
                <label >{{applicationData.bescomTempNumber}}</label>
              </div>
        </div>
        <div class="row" >
            <div class="col-sm-8 " >
                <label >Electricity Temporary connection Date</label>
            </div>
            <div class="col-sm-4">
                <label >{{applicationData.bescomTempDate | date:'dd/MM/yyyy'}}</label>
              </div>
        </div>
        <div class="sub-title">
            Sanctions of Permanent electricity connection Details
         </div>
        <div class="row" >
            <div class="col-sm-8 " >
                <label >Electricity Permanent connection RR No</label>
            </div>
            <div class="col-sm-4">
                <label >{{applicationData.bescomPermNumber}}</label>
              </div>
        </div>
        <div class="row" >
            <div class="col-sm-8 " >
                <label >Electricity Permanent connection Date</label>
            </div>
            <div class="col-sm-4">
                <label >{{applicationData.bescomPermDate | date:'dd/MM/yyyy'}}</label>
              </div>
        </div>
        <div class="sub-title">
            Sanctions of temporary water connection Details
         </div>
        <div class="row" >
            <div class="col-sm-8 " >
                <label >Water Temporary connection RR No</label>
            </div>
            <div class="col-sm-4">
                <label >{{applicationData.bwssbTempNumber}}</label>
              </div>
        </div>
        <div class="row" >
            <div class="col-sm-8 " >
                <label >Water Temporary connection Date</label>
            </div>
            <div class="col-sm-4">
                <label >{{applicationData.bwssbTempDate | date:'dd/MM/yyyy'}}</label>
              </div>
        </div>
        <div class="sub-title">
            Sanctions of permanent water connection Details
         </div>
        <div class="row" >
            <div class="col-sm-8 " >
                <label >Water Permanent connection RR No</label>
            </div>
            <div class="col-sm-4">
                <label >{{applicationData.bwssbPermNumber}}</label>
              </div>
        </div>
        <div class="row" >
            <div class="col-sm-8 " >
                <label >Water Permanent connection Date</label>
            </div>
            <div class="col-sm-4">
                <label >{{applicationData.bwssbPermDate | date:'dd/MM/yyyy'}}</label>
              </div>
        </div>
        <div class="sub-title">
            Details of loan availed  Details
         </div>
        <div class="row" >
            <div class="col-sm-8 " >
                <label >Financier/Financial Institution/Bank</label>
            </div>
            <div class="col-sm-4">
                <label >{{applicationData.bankName}}</label>
              </div>
        </div>
        <div class="row" >
            <div class="col-sm-8 " >
                <label >Amount Sanctioned</label>
            </div>
            <div class="col-sm-4">
                <label >{{applicationData.vsanctionAmount}}</label>
              </div>
        </div>
        <div class="row" >
            <div class="col-sm-8 " >
                <label >Duration of payment</label>
            </div>
            <div class="col-sm-4">
                <label >{{applicationData.paymentDuration}}</label>
              </div>
        </div>
        <div class="row" >
            <div class="col-sm-8 " >
                <label >Single/Joint application</label>
            </div>
            <div class="col-sm-4">
                <label >{{applicationData.singleApplication == 'Y' ? 'Single Application' : applicationData.jointApplication == 'Y' ? 'Joint Application' : ''}}</label>
              </div>
        </div>
        <div class="sub-title">
            Last loan paid receipt Details
         </div>
        <div class="row" >
            <div class="col-sm-8 " >
                <label >Receipt Number</label>
            </div>
            <div class="col-sm-4">
                <label >{{applicationData.receiptNumber}}</label>
              </div>
        </div>
        <div class="row" >
            <div class="col-sm-8 " >
                <label >Receipt Date</label>
            </div>
            <div class="col-sm-4">
                <label >{{applicationData.receiptDate | date:'dd/MM/yyyy'}}</label>
              </div>
        </div>
        <div class="row" >
            <div class="col-sm-8 " >
                <label >Receipt Amount</label>
            </div>
            <div class="col-sm-4">
                <label >{{applicationData.receiptAmount}}</label>
              </div>
        </div>
        <div class="sub-title">
            Court case pending/stay order
         </div>
         <div class="row" >
            <div class="col-sm-8 " >
                <label >Is court case pending</label>
            </div>
            <div class="col-sm-4">
                <label style="text-transform: capitalize;">{{applicationData.isCasePending}}</label>
              </div>
        </div>
        <div class="row" >
            <div class="col-sm-8 " >
                <label > If yes, Name of court</label>
            </div>
            <div class="col-sm-4">
                <label >{{applicationData.courtName}}</label>
              </div>
        </div>
        <div class="row" >
            <div class="col-sm-8 " >
                <label >Case No</label>
            </div>
            <div class="col-sm-4">
                <label >{{applicationData.caseNumber}}</label>
              </div>
        </div>
        <div class="row" >
            <div class="col-sm-8 " >
                <label >Present Status</label>
            </div>
            <div class="col-sm-4">
                <label >{{applicationData.caseStatus}}</label>
              </div>
        </div>
        <br>
        <div class="row sub-title" *ngIf="applicationData">
            <div class="col-sm-8 " style="font-size: 17px;" >
                <label >Recommended by </label>
            </div>
            <div class="col-sm-2">
                <label class="color">{{applicationData.applicantRecommendation ? applicationData.applicantRecommendation.recommendationName : ''}}</label>
              </div>
        </div>
        <div class="row" *ngIf='applicationData.comment!=null'>
            <div class="col-sm-8 " >
                <label >Comments</label>
            </div>
            <div class="col-sm-4">
                <label >{{applicationData.comment}}</label>
              </div>
        </div>
        <br>
        <div *ngIf='applicationData.comment==null'>
           <div>
            <textarea  class="form-control" 
            style=" min-width:500px; max-width:100%;min-height:50px;height:100%;width:100%;" placeholder="Enter Comments/Observations/Recommendations (if any)"
            [(ngModel)]="comment"></textarea> 
        </div>
        <div>
        <br>
        <div fxLayout="row" fxLayoutGap="30px" fxLayoutAlign="space-around">
            <div *ngFor="let message of commentTypes">
                <mat-checkbox [(ngModel)]="message.checked">{{message.landType}}</mat-checkbox>
            </div>
        </div>
         <br>
         <div class="status-btn" fxLayout="row wrap" fxLayoutGap="10px" fxLayoutAlign="space-between">
             <div>
                <button class="scrutiny-user-btn committee-btn" (click)="updateScrutinyDetails('scrutinized_referred_committee')">Scrutinized & Furthered For Committee</button>
              </div>
              <div>
                <button class="scrutiny-user-btn doubtful-btn" (click)="updateScrutinyDetails('referred_for_helpdesk_for_clarification')">Referred for Helpdesk for Clarification</button>
              </div>
              <div>
                <button class="scrutiny-user-btn field-btn" (click)="updateScrutinyDetails('referred_field_visit')" >Referred for Field Visit</button>
             </div>
         </div>
        </div>
        </div>
    </div>
    
</div>

</div>
