import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { Router, ActivatedRoute } from '@angular/router';
import { faSync } from '@fortawesome/free-solid-svg-icons';
import { ConfirmDialogService } from 'src/app/shared/confirm-dialog/confirm-dialog.service';
import { MessageDialogService } from 'src/app/shared/message-dialog/message-dialog.service';
import { ApplicationService } from 'src/services/application.service';
import { StrorageService } from 'src/services/strorage.service';

@Component({
  selector: 'app-rc-register-form-view',
  templateUrl: './rc-register-form-view.component.html',
  styleUrls: ['./rc-register-form-view.component.css']
})
export class RcRegisterFormViewComponent implements OnInit {

  public pageSize =5;
  public currentPage = 0;
  public totalSize: number= 0;
  public faSync = faSync;
  public isRefreshing = false;
  public displayedColumns: string[] = ['all','rcSlNo','groupNumber','jccNo','applicantNameEng','applicantNameKann','propAddr','annexure','reportSlNo','east', 'west', 'north', 'south','villageEng','villagekan','length','breadth','sitalArea','plinthArea','buildUpArea','noOfFloor','structureType','action'];
  public selectedApplicants: any = [];
  public dataSource = new MatTableDataSource();
  selectedDateFilter:any;
  @Input() isEditable : any;
  user: any;
  public applicationNo: any;
  selectedVillages: number;
  public totalApplicantCount: any = 0;
  show: boolean;
  applicantServiceApiCallObserver: any;
  public id: number;
  applicationName: any;
  villageName: any;
  public applicantNameKannada: any;
  public buildingAddress: any;
  public groupNumber: any;
  public groupSize: any;
  public structureType: any;
  public villages: any[];
  public villageId: any;
  public siteNumber: any;
  public surveyNumber: any;
  public activeStatus: any;
  public plinthArea: any;
  public cdFeederReportData: any;
  public sitalArea: any;
  public noOfFloors: any;
  public buildUpArea: any;
  public east: any;
  public west: any;
  public north: any;
  public south: any;
  public length: any;
  public breadth: any;
  public batchSize: number;
  public batchName: String;
  applicantId: any;
  applicationData: any;
  processedBy: any;
  applicationNameKannada: any;
  comment: any;
  rcRegisterId: any;
  rcSerialNo:any;
  public annexure: any;
  public scApprovedNo: any;
  public reportDate: any;
  public maxDate: any;
  annexureName: any;
  scApprovalNo: any;
  approvalDate: any;
  statusCode: any;
  sitalAreaDimension: any;
  dataList: any =[];

  constructor(private applicationService: ApplicationService,
    private cdr:ChangeDetectorRef, 
    public dialog: MatDialog,
    private confirmDialogService: ConfirmDialogService, 
    private router: Router,private storageService:StrorageService,
    private messageDialogService: MessageDialogService,
    private route:ActivatedRoute) { 
      this.rcRegisterId = this.route.snapshot.queryParams['rcRegisterId'];
      this.applicantId = this.route.snapshot.queryParams['applicantId'];
    }

    ngOnInit(): void {
      this.user=this.storageService.getUser();
      this.fetchVillages();
      this.fetchBatchProcessingApplicantsByRcRegisterId();
    }

    editForm(){
      this.router.navigate(['/rc-register-batch-processing']);
    }
    fetchBatchProcessingApplicantsByRcRegisterId() {
      this.applicationService.fetchRcDisbursementByApplicantId(this.applicantId).subscribe(res=>{
        this.dataList = res;
        for(let i=0; i< res.length;i++) {
          this.groupNumber = res[i].groupNumber;
        }
    })

  }

    fetchVillages(){
      this.applicantServiceApiCallObserver = this.applicationService.fetchAllVillages().subscribe(res=>{
        this.villages = res;
        this.villages.unshift({
          "id":"",
          "name":"All"
        });
        })
      }
  
    clickBack(){
      window.history.back();
    }

     refreshClick(){
      let currentComponent = this;
      this.isRefreshing = true;
      setTimeout(()=>{
        currentComponent.isRefreshing = false;
      }, 3000)
    
    }
  }