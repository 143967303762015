import { Component, Input, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-layout-map',
  templateUrl: './layout-map.component.html',
  styleUrls: ['./layout-map.component.scss']
})
export class LayoutMapComponent implements OnInit {

  public comprehensiveMapUrl:string;

  constructor() { 
    this.comprehensiveMapUrl = environment.BASE_URL+'layout_formation/layout_map/Comphrehensive-page-001.jpg';
  }

  ngOnInit(): void {
  }

}
