import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { faSync } from '@fortawesome/free-solid-svg-icons';
import { FormGroup, FormControl } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { JccnoEncodeDecodeService } from 'src/services/jccno-encode-decode/jccno-encode-decode.service';
import { UserService } from 'src/services/user-service/user.service';
import { ApplicationService } from 'src/services/application.service';
import { StrorageService } from 'src/services/strorage.service';
import { PageEvent } from '@angular/material/paginator';
import { Observable } from 'rxjs';
import { ChangeDetectorRef } from '@angular/core';

@Component({
  selector: 'app-adjudication-dashboard',
  templateUrl: './adjudication-dashboard.component.html',
  styleUrls: ['./adjudication-dashboard.component.scss']
})
export class AdjudicationDashboardComponent implements OnInit {

  @Input() color: string;
  public displayedColumns: string[] = ['sn','applicationNo'];

  public dataSource = new MatTableDataSource();

  public pageSize = 5;
  public currentPage = 0;
  public totalSize = 0;

  public dateFilters = [
    {
      "label":'Pre 2009',
      "subtitle":'(Pre 31 Dec 2008)',
      "tag":"pre_2009",
      "color":"orange",
      'active':false,
      'count': 0,
      "id":1
    },
    {
      "label":'2009 - 26 Nov 2014',
      "subtitle":'(1 Jan 2009 - 26 Nov 2014)',
      "tag":"2009_2014",
      "color":"blue",
      "active":false,
      'count': 0,
      "id":2
    },
    {
      "label":'27 Nov 2014 - 3 Aug 2018',
      "subtitle":'(27 Nov 2014 - 3 Aug 2018)',
      "tag":"2015_3_aug_2018",
      "color":"#8a8a5c",
      "active":false,
      'count': 0,
      "id":3
    },
    {
      "label":'Post Aug 4 - 2018\n',
      "subtitle":'(4 Aug 2018 Onwards)',
      "tag":"post_4_aug_2018",
      "color":"brown",
      'count': 0,
      "active":false,
      "id":4
    }
  ];

  public approvedFilters = [
    {
      "label":'Plan Approved',
      "tag":"plan_approved",
      "color":"green",
      'active':false
    },
    {
      "label":'Plan Not Approved',
      "tag":"plan_not_approved",
      "color":"red",
      "active":false
    },
    {
      "label":'N/A',
      "tag":"na",
      "color":"lightgray",
      "active":false
    }
  ];

  public preDateFilters = [
    {
      "label":'Clear & Report Ready',
      "subtitle":'(All dates Pre 3 Aug 2018)',
      "tag":"pre_3_aug_2018",
      "color":"#043805",
      'display': 'none',
      'count': 0,
      "id":1
    },
    {
      "label":'Spillover',
      "subtitle":'(Any date Post 3 Aug 2018)',
      "tag":"post_3_aug_2018",
      "color":"red",
      'display': 'none',
      'count': 0,
      "id":2
    }
  ];

  public userName = "firstName";
  public villageName="Villagename";
  public show;
  public faSync = faSync;
  public isRefreshing = false;
  public isScrutinyUser;
  public isDateFilterSelected = false;
  public isApplicationSelected=false;
  public selectedButton=false;
  
  public applicants:any[]=[];
  public buttonsTexts:Array<string> = ['First button'];
  enableEdit: boolean = false;
  public isLoading = false;
  public colors=['#FF9633','#11C4E8','#F2FE2E','#CE8E05'];

status = 'Enable';
   
   myGroup = new FormGroup({
    applicationNo: new FormControl()
  
  });
  options: any[] = [];
  filters :any[] = [];
  villageId: any;
  village: any;
  villages : any[];
  selectedVillages: number;
  selectapp:boolean=false;
  selectedDateFilter: any;
  selectedPlanApproveFilter: any;
  public user:any;
  public title: string;
  totalCount: any[]=[];
  count: any;
  totalRecord:any;
 
  filteredOptions: Observable<any[]>;
  applicationNo=null;

  constructor(private router: Router, private route: ActivatedRoute,public dialog: MatDialog, 
    private jccEncodeDecoder: JccnoEncodeDecodeService,private userService: UserService,
    private applicationService: ApplicationService,private strorageService:StrorageService,private cdr:ChangeDetectorRef) { }

  ngOnInit(): void {
    this.user=this.strorageService.getUser();
    this.fetchVillages();
    this.getTotalCount();
    this.getDashboardCount();
   }
   
   ngAfterViewInit() {
       this.route.queryParams.subscribe(params => {
        this.showHidePreDashboardFilters(params['selected_date']);
        this.cdr.detectChanges();
      });
    }

    public showHidePreDashboardFilters = (selectedFileter: any) => {
      var total=0;
      for(let i =0; i < this.preDateFilters.length;i++) {
        if(this.preDateFilters[i].tag == selectedFileter) {
          this.preDateFilters[i].display = 'block';
        }
      }
      this.applicationService.fetchAdjurictionDashboardCount(this.villageId).subscribe(res =>{
        console.log("filter count yet to be process",res.data);
        let data = res.data;
        for(let i =0;i < this.preDateFilters.length;i++) {
          for(let j =0;j < data.length;j++) {
              if(this.preDateFilters[i].tag == data[j].key) {
                this.preDateFilters[i].count = data[j].value;
                total = total + parseInt(data[j].value);
                break;
              }
          }
        }
        this.totalSize = total;
      });
    }
    

  public getApplicantPaginatorData(event: PageEvent) {
    this.currentPage = event.pageIndex;
    this.pageSize = event.pageSize;
    this.getApplicants();
  }
  fetchVillages(){
    this.applicationService.fetchAllVillages().subscribe(res=>{
      this.villages = res;
      this.villages.unshift({
        "id":"",
        "name":"All"
       });
    })
  }
 
  onVillageChange(): void{
    this.show = false;
    this.getDashboardCount();
  }

 getTotalCount(){
  this.applicationService.fetchAdjurictionDashboardCount(this.villageId).subscribe(res =>{
    console.log("filter count yet to be process",res.data);
    let data = res.data;
    this.totalSize=res.data[0].count;
  })
 }
  getDashboardCount() {
    var total=0;
    this.applicationService.fetchAdjurictionDashboardCount(this.villageId).subscribe(res =>{
      console.log("filter count yet to be process",res.data);
      let data = res.data;
      for(let i =0;i < this.dateFilters.length;i++) {
        for(let j =0;j < data.length;j++) {
            if(this.dateFilters[i].tag == data[j].key) {
              this.dateFilters[i].count = data[j].value;
              total = total + parseInt(data[j].value);
              console.log(this.dateFilters[i].count);
              break;
            }
        }
      }
      this.totalSize = total;
    });
}


getApplicants() {
    this.applicationService.getApplicantsByStatusList(this.selectedDateFilter, 
      this.currentPage, this.pageSize,this.applicationNo,this.villageId).subscribe(res =>{
      this.totalSize = res.data.totalRecord;
      this.refreshDataTable(res);
    });
}

private refreshDataTable = (res: any) => {
 // this.totalSize = res.totalRecord;
  this.dataSource.data = res.data;
  this.dataSource._updateChangeSubscription();
  this.cdr.detectChanges();
}

clickSelectPostField = (event:any,dateFilter:any) => {
      if(!this.isApplicationSelected) {
        this.isApplicationSelected = true;
      }
      dateFilter.active=!dateFilter.active
      
}
searchApplicant(){
  this.selectedDateFilter = undefined;
  this.show=false;
  this.getApplicants();
 this.show= true;
}
onClear(){
  this.applicationNo="";
  this.show= false;
}

clickApplication = (event:any,dateFilter:any) => {
      if(!this.isApplicationSelected) {
        this.isApplicationSelected = true;
      }
      dateFilter.active=!dateFilter.active
      if(this.isApplicationSelected){
        dateFilter.active=this.colors;
        //this.isLoading=true;
        console.log(dateFilter.active)
      }
}
    
clickDateFilter = (event: any, dateFilter: any) => {
      this.title = "";
      this.title = dateFilter.label;
      dateFilter.active=!dateFilter.active
      if(this.isDateFilterSelected){
        dateFilter.active=this.colors;
         }
        console.log(dateFilter.active)
    if(!this.isDateFilterSelected) {
      this.isDateFilterSelected = true;
    }
   
    let selectedActiveIndex = 0;
    for(let i = 0;i < this.dateFilters.length;i++) {
      if(this.dateFilters[i].id == dateFilter.id) {
        selectedActiveIndex = i;
        break;
      }
    }
    for(let i = 0;i < this.dateFilters.length;i++) {
      if(i != selectedActiveIndex) {
        this.dateFilters[i].active= false;
      }
    }
   
    if(dateFilter.tag==this.isDateFilterSelected){
      dateFilter.color;
    }
    this.selectedDateFilter = dateFilter.tag;
    this.getApplicants();
    this.show =true ;
}
clickReportingLink=()=>{
  this.router.navigate(['/adjudication-reporting']);
}
 onPreviewClick = (id: any) => {
 // this.title = this.title + " | " +this.selectedDateFilter.title;
  this.router.navigate(['/adjudication-workbench'], {queryParams:{application_id:id}});
  console.log("query",this.selectedDateFilter)
 }

refreshClick = () => {
  let currentComponent = this;
  this.isRefreshing = true;
  setTimeout(()=>{
    currentComponent.isRefreshing = false;
  }, 3000)
  this.applicationService.fetchApplicants();
 
}

public showActivityLog = () => {
  this.router.navigate(['/adjudication-activity-log']);
   
  }

}
