<div class="row content-area">
    <div class="col-sm-12 col-md-12">
        <div class="row application-form-row ">
            <label class="form-label">Survey/Site No / ಸರ್ವೆ ಸಂಖ್ಯೆ / ನಿವೇಶನದ ಸಂಖ್ಯೆ. :</label>
            <input type="text" [(ngModel)]="siteNumber" class="form-textbox" readonly/>
        </div>
    </div>

    <div class="col-sm-12 col-md-12">
        <div class="row application-form-row ">
            <label class="form-label">Purpose / Use - Temple, Mosque, Church, Orphanage etc / ಉದ್ದೇಶ / ಬಳಕೆ -ಉದಾಹರಣೆ - ದೇವಾಲಯ, ಮಸೀದಿ, ಚರ್ಚ್, ಅನಾಥಾಶ್ರಮ ಇತ್ಯಾದಿ :</label>
            <input type="text" [(ngModel)]="otherPurpose" class="form-textbox"  [readonly] = "(data && data.otherPurpose) && !isEditable"/>
        </div>
    </div>
    <div class="col-sm-12 col-md-12">
        <div class="row application-form-row ">
            <label class="form-label">Built up area (in Sq.ft) / ಕಟ್ಟಡದ ವಿಸ್ತೀರ್ಣ :</label>
            <input type="number" [(ngModel)]="otherArea" step="any" class="form-textbox" [readonly] = "(data && data.otherArea) && !isEditable"/>
        </div>
    </div>
    <div class="col-sm-12 col-md-12">
        <div class="row application-form-row ">
            <label class="form-label">Total area (in Sq.ft) / ಒಟ್ಟು ವಿಸ್ತೀರ್ಣ :</label>
            <input type="number" [(ngModel)]="otherTotalarea" step="any" class="form-textbox" [readonly] = "(data && data.otherTotalarea) && !isEditable"/>
        </div>
    </div>
    <div class="col-sm-12 col-md-12">
        <div class="row application-form-row ">
            <label class="form-label">Address of the property / ಆಸ್ತಿಯ ವಿಳಾಸ :</label>
            <input type="text" [(ngModel)]="buildingAddress" class="form-textbox" placeholder="Address" readonly/>
        </div>
    </div>

</div>



