<div class="container">
  <div>
    <app-top-bar selectedTab="home"></app-top-bar>
  </div>
  <br>

  <div class="tool-bar">
    <div class="refresh-btn">
      <button mat-button class="btn btn-primary" (click)="clickBack()">Back</button>
    </div>

    <div class="col-sm-3 offset-sm-1 user-details">
      <div class="value-text">
        Welcome <br>
        {{user['firstName']}} !
      </div>
    </div>
  </div>


  <div>
    <section>
      <div class="d-flex justify-content-end row mr-2">
          <div class="col-sm-2">
            <input class="form-control border-end-0 border rounded-pill" style="font-size: 14px !important;" type="text"
              [(ngModel)]="applicationNo" placeholder="Search" id="applicationNo">
          </div>
          <div class="col-sm-1">
              <button mat-button class="btn btn-primary ">Search</button>
          </div>
        </div>
      <div class="row justify-content-between">
        <div class="col-6 title">
          <div class="p1">
            <mat-form-field class="w-50" appearance="fill">
              <mat-label>Village</mat-label>
              <mat-select id="status" name="selectedStatus" (selectionChange)="onVillageChange($event.value)">
                <!-- [(ngModel)]="" -->
                <mat-option *ngFor="let village of villageList" [value]="village.id">
                {{village.villageName}}</mat-option>
              </mat-select>
            </mat-form-field>

          </div>
        </div>
        <div class="col-6 title">
          <div class="p1">
            <mat-form-field class="w-50" appearance="fill">
              <mat-label>Sy. No</mat-label>
              <mat-select id="status" name="selectedStatus" (selectionChange)="onSurveyChange($event.value)">
                <!-- [(ngModel)]="" -->
                <mat-option *ngFor="let syNo of syNoList" [value]="syNo.id">
                  {{syNo.surveyNo}}</mat-option>
              </mat-select>

            </mat-form-field>

          </div>
        </div>
      </div>



      <div class="col-sm-11 title">
        Tackled & Completed 
      </div>



      <!-- data table -->
      <mat-card>
        <table mat-table [dataSource]="dataSource">
          <!-- DAP No Column -->
          <ng-container matColumnDef="dapNo">
            <th mat-header-cell *matHeaderCellDef> DAP No </th>
            <td mat-cell *matCellDef="let element ;let i =index;" class="column-container">{{element.dapNo}}
          </ng-container>

          <!-- Village Column -->
          <ng-container matColumnDef="village">
            <th mat-header-cell *matHeaderCellDef> Village </th>
            <td mat-cell *matCellDef="let element"> {{element.village}} </td>
          </ng-container>

          <!-- Sy. No Column -->
          <ng-container matColumnDef="syNo">
            <th mat-header-cell *matHeaderCellDef> Sy. No </th>
            <td mat-cell *matCellDef="let element ;let i =index;" class="column-container">{{element.syNo}}
          </ng-container>         
          
          <!-- Main Column -->
          <ng-container matColumnDef="function">
            <th mat-header-cell *matHeaderCellDef> Main Activity </th>
            <td mat-cell *matCellDef="let element"> {{element.mainActivity}} </td>
          </ng-container>

          <!-- Function Column -->
          <ng-container matColumnDef="mainActivity">
            <th mat-header-cell *matHeaderCellDef> Function </th>
            <td mat-cell *matCellDef="let element"> {{element.function}} </td>
          </ng-container>

          <!-- Work Column -->
          <ng-container matColumnDef="work">
            <th mat-header-cell *matHeaderCellDef> Work </th>
            <td mat-cell *matCellDef="let element">{{element.work}} </td>
          </ng-container>

          <!-- Created On Column -->
          <ng-container matColumnDef="createdOn">
            <th mat-header-cell *matHeaderCellDef> Execution Date </th>
            <td mat-cell *matCellDef="let element" class="column-container">{{this.formattDate(element.createdOn)}}
          </ng-container>
          <!-- Created By Column -->
          <ng-container matColumnDef="contractorName">
            <th mat-header-cell *matHeaderCellDef> Contractor Name </th>
            <td mat-cell *matCellDef="let element" class="column-container">{{element.contractorName}}
          </ng-container>
          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>
        <div class="no-records-text" *ngIf="!dataSource.data || dataSource.data.length <= 0">No Records Found!</div>
        <mat-paginator [pageSize]="pageSize" [pageSizeOptions]="[5, 10, 20]" [showFirstLastButtons]="true"
          [pageIndex]="currentPage" [length]="totalSize" (page)="getApplicantPaginatorData($event)">
        </mat-paginator>
      </mat-card>
    </section>
  </div>
  <app-footer></app-footer>
</div>