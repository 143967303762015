import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { PageEvent } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { faSync } from '@fortawesome/free-solid-svg-icons';
import { PiUserDashboardComponent } from 'src/app/pi-user/pi-user-dashboard/pi-user-dashboard.component';
import { PiUserTrackActivityService } from 'src/app/pi-user/services/pi-user-track-activity.service';
import { ConfirmDialogService } from 'src/app/shared/confirm-dialog/confirm-dialog.service';
import { MessageDialogService } from 'src/app/shared/message-dialog/message-dialog.service';
import { ApplicationService } from 'src/services/application.service';
import { StrorageService } from 'src/services/strorage.service';

@Component({
  selector: 'app-rc-disbursement-dashboard',
  templateUrl: './rc-disbursement-dashboard.component.html',
  styleUrls: ['./rc-disbursement-dashboard.component.scss']
})
export class RcDisbursementDashboardComponent implements OnInit {
  public pageSize =5;
  public currentPage = 0;
  public totalSize: number= 0;
  public faSync = faSync;
  public isRefreshing = false;
  public displayedColumns: string[] = ['sn','groupNumber','applicationNo','applicantName','villageName', 'actions'];
  public selectedApplicants: any = [];
  public dataSource = new MatTableDataSource<any>();
  selectedDateFilter:any;
  user: any;
  village: any;
  villages : any[];
  villageId: any;
  public applicationNo: any;
  selectedVillages: number;
  public totalApplicantCount: any = 0;
  show: boolean;
  applicantServiceApiCallObserver: any;
  private piUserLastActivity: any;
  public id: number;
  public groupNumber:number;
  applicationName: any;
  villageName: any;
  // isRefreshing: boolean;

  constructor(private applicationService: ApplicationService,
    private cdr:ChangeDetectorRef, 
    private dialogRef: MatDialogRef<PiUserDashboardComponent>,
    private piUserTrackActivityService: PiUserTrackActivityService,
    private confirmDialogService: ConfirmDialogService, 
    private router: Router,private storageService:StrorageService,
    private messageDialogService: MessageDialogService) { }

    ngOnInit(): void {
      this.user=this.storageService.getUser();
      this.fetchVillages();
      this.fetchDisbursement();
    }
    fetchDisbursement(){
      this.applicantServiceApiCallObserver = this.applicationService.fetchAllRcDisbursement(this.applicationNo,this.villageId,this.currentPage,this.pageSize).subscribe(res=>{
        this.refreshDataTable(res);
       console.log(res);
        })
      }
  
    fetchVillages(){
      this.applicantServiceApiCallObserver = this.applicationService.fetchAllVillages().subscribe(res=>{
        this.villages = res;
        this.villages.unshift({
          "id":"",
          "name":"All"
        });
        })
      }
      private refreshDataTable = (res: any) => {
        this.dataSource.data = res.data;
        this.totalSize = res.totalRecord;
        this.currentPage = (res.currentPage - 1);
        this.dataSource._updateChangeSubscription();
        if (!this.cdr['destroyed']) {
          this.cdr.detectChanges();
        }
      }

      public getApplicantPaginatorData(event: PageEvent) {
        this.currentPage = event.pageIndex + 1;
        this.pageSize = event.pageSize;
        this.fetchDisbursement();
      }

    onVillageChange(): void{
      this.show=false;
      this.fetchDisbursement();
    }

    onClear() {
      this.applicationNo = null;  
      this.fetchDisbursement();
    }
    
    public showActivityLog = () => {
      this.router.navigate(['/rc-disbursement-activity-log']);
    }
    searchApplicant(){
      this.resetPagination();
      this.fetchDisbursement();
    }
    resetPagination() {
      this.currentPage = 0;
      this.pageSize = 5;
    }
  
    clickBack(){
      window.history.back();
  
    }
    onPreviewClick(rcRegisterId: any, userType: any, applicantId: any){
      this.router.navigate(['/rc-disbursement-workbench'],  {queryParams:{rcRegisterId:rcRegisterId, heading: 'Rc Disbursement Workbench', userType: userType, applicantId: applicantId}});
     }

     refreshClick(){
      let currentComponent = this;
      this.isRefreshing = true;
      setTimeout(()=>{
        currentComponent.isRefreshing = false;
      }, 3000)
      this.fetchDisbursement();
    }
  
  parseAllSelectedApplicationNo(): any {
    let jccNos = [];
    for(let i = 0;i < this.selectedApplicants.length;i++) {
      jccNos.push(this.selectedApplicants[i].applicationNo);
    }
    return jccNos;
  }

  selectOrDeSelectApplicants(event:any, element: any) {
    if(this.selectedApplicants.length == 0) {
      if(element.checked) {
        this.selectedApplicants.push(element);
      }
    } else {
      let idx = -1;
      for(let i = 0;i < this.selectedApplicants.length;i++) {
        if(this.selectedApplicants[i].applicationNo == element.applicationNo) {
          idx = i;
          break;
        }
      }
      if(idx == -1) {
        if(element.checked) {
          this.selectedApplicants.push(element);
        }
      } else {
        if(!element.checked) {
          this.selectedApplicants.splice(idx, 1);
        } else {
          this.selectedApplicants[idx].checked = element.checked;
        }
      }
    }
  }

  submitReport(): void {
    let jccNos = this.parseAllSelectedApplicationNo();
    if(jccNos.length == 0) {
      const options = {
        title: 'Alert!',
        message: 'Please select Applicants',
        confirmText: 'OK'
      };
      this.messageDialogService.openDialog(options);
      return;
    }
   
    else{
      const options = {
        title: 'Confirmation!',
        message: 'Confirm RC Successfully Disbursed for ' + jccNos +'.', 
        cancelText:'No',
        confirmText: 'Yes'
      };
      this.confirmDialogService.openDialog(options);
      this.confirmDialogService.confirmed().subscribe(confirmed => {
        if(confirmed) {
          let payload = [];
          for(let i = 0;i< jccNos.length;i++) {
            let payload1 = {};
            payload1['applicationNo'] = jccNos[i];
            payload.push(payload1);
          }
            this.applicationService.submitRcDisbursmentSelectedApplicants(payload).subscribe(res =>{
              this.fetchDisbursement();
              this.selectedApplicants = [];
            return;
          })
        }
      });
    }
}
}