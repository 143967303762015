import { Component, OnInit, ChangeDetectorRef, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { faSync } from '@fortawesome/free-solid-svg-icons';
import { ApplicationService } from 'src/services/application.service';
import { PageEvent } from '@angular/material/paginator';
import { Router, ActivatedRoute } from '@angular/router';
import { StatusOptionTilesComponent } from '../status-option-tiles/status-option-tiles.component';

@Component({
  selector: 'app-committe-report-generated',
  templateUrl: './committe-report-generated.component.html',
  styleUrls: ['./committe-report-generated.component.scss']
})
export class CommitteReportGeneratedComponent implements OnInit {

  constructor(private applicationService: ApplicationService, 
    private cdr:ChangeDetectorRef, private router: Router,private route: ActivatedRoute,) { 
    }
  public faSync = faSync;
  public villages : any = [];
  // public statusCode:any = 'ready_for_reporting' //used to load sub status options
  public selectVillage:any;
  public statusOptionId: any;
  public colors = ['#E74C3C', '#1ABB9C', '#3498DB', '#F39C12', '#9B59B6', '#50C1CF', '#34495E', '#E9967A', '#FA8072', '#E74C3C','#E74C3C', '#1ABB9C', '#3498DB', '#F39C12', '#9B59B6', '#50C1CF', '#34495E'];

  ngOnInit(): void {
    this.loadReportGeneratedAggregatedData();
  }

  public loadReportGeneratedAggregatedData(){
    this.applicationService.fetchApplicantCountForReportGeneratedByVillage().subscribe(res =>{
      this.villages = res;
    })
  }

  viewReport(): void{
    this.router.navigate(['/view-committee-report']);
  }

  onVillageChange(villageId:any){
    this.selectVillage=villageId;
  }
}
