import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-apartment',
  templateUrl: './apartment.component.html',
  styleUrls: ['./apartment.component.scss']
})
export class ApartmentComponent implements OnInit {

  @Input() data : any;

  @Input() isEditable : any;

  public aptTotalarea : string;

  public aptFloors : string;

  public aptBuildUpArea : number;

  public siteNumber : string;

  public buildingAddress : string;
  

  constructor() { }

  ngOnInit(): void {
  }

  reset(){
    this.aptTotalarea = null;
    this.aptFloors = null;
  }

  getPayload(){
    let payload = {};
    payload['aptTotalarea'] = this.aptTotalarea;
    payload['aptBuildUpArea'] = this.aptBuildUpArea;
    payload['aptFloors'] = this.aptFloors;
    return payload;
  }

  ngOnChanges(data): void{
    if(!this.data){
      return;
    }
    this.aptTotalarea = this.data.aptTotalarea;
    this.aptBuildUpArea = this.data.aptBuildUpArea ? this.data.aptBuildUpArea.toFixed(2) : this.data.aptBuildUpArea;
    this.aptFloors = this.data.aptFloors;
    this.siteNumber = this.data.surveyNumber;
    this.buildingAddress = this.data.buildingAddress;
   }

}
