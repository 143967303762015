import { Component, OnInit } from '@angular/core';
import { ApplicationService } from 'src/services/application.service';
import * as Highcharts from 'highcharts';
import { DateAdapter } from '@angular/material/core';



@Component({
  selector: 'app-application-bar-chart',
  templateUrl: './application-bar-chart.component.html',
  styleUrls: ['./application-bar-chart.component.scss'],

})
export class ApplicationBarChartComponent implements OnInit {

  public fromDate;

  public toDate;

  highcharts = Highcharts;

  chart;
  
  chartCallback;

  updateFromInput = false;

  maxDate = new Date();

  Days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday']

  title = 'Population (in millions)';
  type = 'ColumnChart';
  data = [
   //   ["2012", 900, 390],
   //   ["2013", 1000, 400],
   //   ["2014", 1170, 440],
   //   ["2015", 1250, 480],
   //   ["2016", 1530, 540]
  ];
  columnNames = ['Date','Helpdesk Applications', 'Online Applications','Without enclosures'];
  options = {    
     title: 'Daily Statistics',

     hAxis: {
      title: 'Days',
     ticks: [50,100,150,200] ,
      textPosition: 'none',
      textStyle: {
       color: '#283745',
       fontSize: 15,
       fontName: 'Arial',
       bold: false,
       italic: false
    },    
    titleTextStyle: {
       color: '#888887',
       fontSize: 18,
       fontName: 'Arial',
       bold: false,
       italic: false
    }
   },
   vAxis:{
      title: 'Application Numbers',
      textStyle: {
       color: '#283745',
       fontSize: 15,
       fontName: 'Arial',
       bold: false,
       italic: false
    },    
    titleTextStyle: {
       color: '#888887',
       fontSize: 18,
       fontName: 'Arial',
       bold: false,
       italic: false
    }
   },
   titleTextStyle: {
      color: '#111111',
      fontSize: 23,
      marginBottom: 20,
      bold: true,
      textAlign: 'center'
  },
  legend:{textStyle:{fontSize:11},position: 'bottom'},

     isStacked:true	,
     tooltip: {
      isHtml : true
     }  ,
     colors: ['#1ABB9C', '#3498DB', '#E74C3C']
  };
  width = 1200;
  height = 400;

  public colors = ['#E74C3C', '#1ABB9C', '#3498DB', '#F39C12', '#9B59B6', '#50C1CF', '#34495E', '#E9967A', '#FA8072', '#E74C3C'];
   notificationType =  null;

  constructor(private applicationService: ApplicationService, private dateAdapter: DateAdapter<Date>) { 
   this.dateAdapter.setLocale('en-GB');
  }
  

  ngOnInit(): void {
  
   this.fetchApplicantData(this.notificationType);
  
  }

  fetchApplicantData(notificationType: any){
   this.notificationType = notificationType;
   this.fetchApplicantDataByDateFilter();
  }

  fetchApplicantDataByDateFilter(){
   let fromDate;
   let toDate;
   if(this.fromDate != undefined) {
      fromDate = `${this.fromDate.getFullYear()}-${this.fromDate.getMonth()+1 > 9 ? this.fromDate.getMonth()+1 : '0' + (this.fromDate.getMonth()+1)}-${this.fromDate.getDate() > 9 ? this.fromDate.getDate() : '0' + this.fromDate.getDate()}`;
   }
   if(this.toDate != undefined){
      toDate = `${this.toDate.getFullYear()}-${this.toDate.getMonth()+1 > 9 ? this.toDate.getMonth()+1 : '0' + (this.toDate.getMonth()+1)}-${this.toDate.getDate() > 9 ? this.toDate.getDate() : '0' + this.toDate.getDate()}`;
   }
    this.applicationService.getApplicantCountByDateFilter(fromDate, toDate, this.notificationType).subscribe(res => {
       this.data = this.parseResponseData(res);
    })
 }


// To be refactor 

  parseResponseData(data){
     let onlineUsersData = data.online;
     let helpdeskUsersData = data.helpdesk;
     let withOutEnclosures = data.without_enclosures;
     let dataArr = [];
     for(let prop in onlineUsersData){
         let usersArr = [];
         usersArr.push(prop);

         usersArr.push(0)
         usersArr.push(onlineUsersData[prop])
         usersArr.push(0)
         dataArr.push(usersArr);
     }    
      for(let prop in helpdeskUsersData){
         let isAdded = false;
        dataArr.map((row, index)=>{
          if(row[0] == prop){
             dataArr[index][1] = helpdeskUsersData[prop];
              isAdded = true;
          }
      })
      if(!isAdded){
      let usersArr = [];
      usersArr.push(prop);
      usersArr.push(helpdeskUsersData[prop])
      usersArr.push(0)

      usersArr.push(0)
      dataArr.push(usersArr);
      }

  }

  for(let prop in withOutEnclosures){
  dataArr.map((row, index)=>{
    if(row[0] == prop){
       dataArr[index][3] = withOutEnclosures[prop]
    }
})
}
console.log(dataArr)
     return this.sortData(dataArr);
  }

  sortData(data){
   data.sort((a, b) => {
      let date1 = new Date(a[0]);
      let date2 = new Date(b[0]);
  
      if (date1 < date2) {
          return -1;
      }
      if (date1 > date2) {
          return 1;
      }
      return 0;
  });
  data.map((row, index)=>{
   console.log(" ",row[0])
   row[0] = this.parseDate(row[0])
})
  return data;
  }

  parseDate(dateString){
     let dateObj = new Date(dateString);
  let dateArr = dateString.split('-');
//   return dateObj;
     return `${dateArr[2]}-${dateArr[1]}-${dateArr[0]}`
  }

  fetchDay(dateString){
   let date = new Date(dateString);
   return date.getDay();
  }

  
   onFromDateChange(event){
     if(!this.fromDate || !this.toDate){
       return;
     }
    
     this.fetchApplicantDataByDateFilter();
   }

   onToDateChange(event){
    if(!this.fromDate || !this.toDate){
      return;
    }
    this.fetchApplicantDataByDateFilter();
  }


}
