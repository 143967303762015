import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { PageEvent } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { ConfirmDialogService } from 'src/app/shared/confirm-dialog/confirm-dialog.service';
import { ApplicationService } from 'src/services/application.service';
import { StrorageService } from 'src/services/strorage.service';

@Component({
  selector: 'app-rc-register-data-enrichment',
  templateUrl: './rc-register-data-enrichment.component.html',
  styleUrls: ['./rc-register-data-enrichment.component.scss']
})
export class RcRegisterDataEnrichmentComponent implements OnInit {
  public pageSize =5;
  public currentPage = 0;
  public totalSize: number= 0;
  public isRefreshing = false;
  public user: any;
  public displayedColumns: string[] = ['all','groupNumber','groupSize','jccNo','notificationType','applicantName','possibleCdError', 'activeStatus','annexure','slNo','reportDate','structureType','cdProcessedBy'];
  public selectedStructureType:any;
  public dataSource = new MatTableDataSource<any>();
  applicationNo: any;
  villageId: any;
  statusId: any;
  statusCode:any;
  villages: any[];
  statuses: any[];
  annexureNames: any[];
  show: boolean;
  structureTypeId: any;
  structureTypes: any[];
  applicantData: any;
  cdError: any;
  possibleCdError: any;
  selectedAnnexureName: any;
  public selectedApplicants: any = [];
  public selectedApplicantsSize : any;
  public selectedApplicationNos : any = [];
  dataTableApplicatioNo: any;
  groupApplicationNo: any;
  selectedApplicationNo: any;

  constructor(private applicationService: ApplicationService,
    private cdr:ChangeDetectorRef, 
    private confirmDialogService: ConfirmDialogService, 
    private router: Router,private storageService:StrorageService,
   ) { }

    ngOnInit(): void {
      this.user=this.storageService.getUser();
       this.fetchVillages();
       this.fetchStructureTypes();
       this.fetchRcDataEnrichmentApplicants();
       this.fetchAllStatus();
       this.fetchAnnexureNames();
    }
    fetchRcDataEnrichmentApplicants(){
      let pStatusCode = this.statusCode;
      let annexureName = this.selectedAnnexureName;
      if(pStatusCode == '') {
        pStatusCode = undefined;
      }
      if(annexureName == '') {
        annexureName = undefined;
      }
     this.applicationService.fetchRcRegisterDataEnrichmentApplicants(this.applicationNo,this.villageId,this.selectedStructureType,this.selectedAnnexureName,pStatusCode,this.currentPage,this.pageSize).subscribe(res=>{
        this.applicantData = res;
        this.refreshDataTable(res);
        })
      }
  
    fetchVillages(){
       this.applicationService.fetchAllVillages().subscribe(res=>{
        this.villages = res;
        this.villages.unshift({
          "id":"",
          "name":"All"
        });
        })
      }

      fetchStructureTypes(){
      this.applicationService.fetchAllStructureTypes().subscribe(res=>{
        this.structureTypes = res;
        this.structureTypes.unshift({
          "id":"",
          "structureType":"All"
        });
        })
      }

      fetchAnnexureNames =()=> {
        let annexureNames = [
          {
            "annexureName":"",
            "label":"All"
          },
          {
            "annexureName":"Annexure - L",
            "label":"Annexure - L"
          },
          {
            "annexureName":"Annexure - M",
            "label":"Annexure - M"
          },
          {
            "annexureName":"Annexure - N",
            "label":"Annexure - N"
          },
        ];
        this.annexureNames = annexureNames;
      }

      fetchAllStatus(){
        this.applicationService.fetchAllStatus().subscribe(res=>{
         this.statuses = res;
         console.log(res);
         this.statuses.unshift({
           "statusId":"",
           "status":"All"
         });
         })
       }

      private refreshDataTable = (res: any) => {
        this.dataSource.data = res.data;
        this.totalSize = res.totalRecord;
        this.currentPage = (res.currentPage - 1);
        this.dataSource._updateChangeSubscription();
        if (!this.cdr['destroyed']) {
          this.cdr.detectChanges();
        }
      }

      public getApplicantPaginatorData(event: PageEvent) {
        this.currentPage = event.pageIndex + 1;
        this.pageSize = event.pageSize;
        this.fetchRcDataEnrichmentApplicants();
      }

    onVillageChange(): void{
      this.resetPagination();
      this.show=false;
      this.fetchRcDataEnrichmentApplicants();
    }

    onStatusChange(): void {
      this.resetPagination();
      this.show = false;
      this.fetchRcDataEnrichmentApplicants();
    }

    onStructureTypeChange(structureTypeId: any) {
      this.resetPagination();
      this.selectedStructureType = structureTypeId;
      this.fetchRcDataEnrichmentApplicants();
  }
    onAnnexureNameChange(annexureName: any) {
      this.resetPagination();
      this.selectedAnnexureName = annexureName;
      this.fetchRcDataEnrichmentApplicants();
  }
    searchApplicant(){
      this.resetPagination();
      this.fetchRcDataEnrichmentApplicants();
    }

    resetPagination() {
      this.currentPage = 0;
      this.pageSize = 5;
    }
  
    clickBack(){
      window.history.back();
  
    }
    onClear() {
      this.applicationNo = null;
      this.fetchRcDataEnrichmentApplicants();
    }

     updateBtnClick (applicantId: any) {
      this.router.navigate(['/rc-register-data-enrichment-form'],{ queryParams: { applicantId :applicantId} })
     }

     refreshClick(){
      let currentComponent = this;
      this.isRefreshing = true;
      setTimeout(()=>{
        currentComponent.isRefreshing = false;
      }, 3000)
      this.fetchRcDataEnrichmentApplicants();
    }

    selectOrDeSelectApplicants(event:any, element: any) {
      if(this.selectedApplicants.length == 0) {
        if(element.checked) {
          this.selectedApplicants.push(element);
        }
      } else {
        let idx = -1;
        for(let i = 0;i < this.selectedApplicants.length;i++) {
          if(this.selectedApplicants[i].applicationNo == element.applicationNo) {
            idx = i;
            break;
          }
        }
        if(idx == -1) {
          if(element.checked) {
            this.selectedApplicants.push(element);
          }
        } else {
          if(!element.checked) {
            this.selectedApplicants.splice(idx, 1);
          } else {
            this.selectedApplicants[idx].checked = element.checked;
          }
        }
      }
      this.selectedApplicantsSize = this.selectedApplicants.length;
    }



  constructPayload(data: any) {
    let jccNos = [];
    for(let i = 0;i < data.length;i++) {
      jccNos.push(data[i].applicationNo);
    }
    return jccNos;
  }

    onSubmit() {
      let jccNos = this.constructPayload(this.selectedApplicants);
      const options = {
        title: 'Confirmation!',
        message: 'Confirm '+ this.selectedApplicantsSize+ ' Applications move for SD Data Validation', 
        cancelText:'No',
        confirmText: 'Yes'
      };
      this.confirmDialogService.openDialog(options);
      this.confirmDialogService.confirmed().subscribe(confirmed => {
        if(confirmed) {
          let payload = {};
          payload['applicationNos'] = jccNos;
            this.applicationService.submitSelectedRcRegisterApplicants(payload).subscribe(res =>{
            this.selectedApplicantsSize = 0;
            this.fetchRcDataEnrichmentApplicants();
          })
        }
      });
    }
    
    showActivityLog() {
      this.router.navigate(['/rc-register-data-enrichemnt-activity-log']);
    }

}