<div class="container">
  <div>
      <app-top-bar selectedTab="home"></app-top-bar>
      <app-header></app-header>
  </div>
  <br>
<div class="container">
  <div class="row">
    <div class="refresh-btn col-sm-2">
      <button mat-button class="btn btn-primary" (click)="clickBack()">Back</button>
    </div>
    <div class="col-sm-6 offset-sm-1 text-center">
      <div *ngIf="roleCode == 'comittee_user'"><h2>COMMITTE - Dashboard</h2></div>
    </div>

    <div class="col-sm-2 offset-sm-1 user-details">
      <div class="value-text">
        Welcome <br>
        {{user['firstName']}} !
      </div>
    </div>
    <div class="col-sm-12">
      <div class="d-flex justify-content-around mb-3">
      <div class="col-sm-4 d-flex justify-content-around">
        <span class="label-link" style="font-size: 18px;" (click) = "onResourceMobilizationClicked()">
          Resource Mobilization 
          </span>
        </div>
        <div class="col-sm-4 d-flex justify-content-around">
          <span class="label-link" style="font-size: 18px;"  (click) = "onSectorWiseProgressClicked()">
            Sector-wise Progress</span>
        </div>
        <div class="col-sm-4 d-flex justify-content-around">
          <span  class="label-link" style="font-size: 18px;"  (click) = "onDrawingsHandedOverByPMCClicked()">Drawings handed over by PMC</span>
      </div>
      </div>
    </div>
  </div>
</div>
<div class="container">
<div class="d-flex justify-content-around my-3">
  <div class="col-sm-4">
    <button mat-button class="btn content-btn btn-width w-75 text-white" 
    id="selectapp" type="button" (click)="this.currentTab = 'DU' " [ngStyle]="{ 'background-color': this.currentTab === 'DU' ?  '#000080' : '#9C9C9C;' }">Tackled & Completed</button>
  </div>  

  <div class="col-sm-4">
    <button  mat-button  class="btn content-btn btn-width w-75 text-white"  
    type="button" (click)="this.currentTab = 'PDF' " [ngStyle]="{ 'background-color': this.currentTab === 'PDF' ?  '#000080' : '#9C9C9C;' }">DP Map View</button>
  </div>
</div>
</div> 
<div *ngIf="this.currentTab==='DU'">
  <app-dpa-activity-log-common></app-dpa-activity-log-common>
</div>
<div *ngIf="this.currentTab==='PDF'">
  <app-daily-progress-activitylog-datatable></app-daily-progress-activitylog-datatable>
</div>
<app-footer></app-footer>
</div>