import { Component, Inject, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { ApplicationService } from 'src/services/application.service';
import { ActivatedRoute, Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { faCheck, faTimes, faTrash } from '@fortawesome/free-solid-svg-icons';
import { DOCUMENT } from '@angular/common';
import { JccnoEncodeDecodeService } from 'src/services/jccno-encode-decode/jccno-encode-decode.service';



@Component({
  selector: 'app-upload-enclosure',
  templateUrl: './upload-enclosure.component.html',
  styleUrls: ['./upload-enclosure.component.scss']
})
export class UploadEnclosureComponent implements OnInit {

  private applicantId: any;

  public faCheck = faCheck;

  public faTimes = faTimes;

  public faTrash = faTrash;

  public files: any[];

  public isLoading = false;

  public data: any;

  public enclosure: any;

  @Output() nextScreenCallback = new EventEmitter<any>();

  constructor(private applicationService: ApplicationService, private route: ActivatedRoute,
    private jccEncodeDecoder: JccnoEncodeDecodeService, private router: Router, private _snackBar: MatSnackBar, @Inject(DOCUMENT) private document) {
    this.files = [];
  }

  ngOnInit(): void {
    console.log("enclosure form");
    let encodedNo = this.route.snapshot.queryParams["applicationNo"];
    if(encodedNo) {
      let applicantNo = this.jccEncodeDecoder.decode(this.route.snapshot.queryParams["applicationNo"]);
      this.fetchApplicationData(applicantNo);
    } else {
      this.applicantId = window.localStorage.getItem("applicantId");
      this.fetchApplicantById(this.applicantId);
    }
  }

  public fetchApplicationData = (applicantNo: any) => {
    this.applicationService.fetchApplicantByApplicationNo(applicantNo).subscribe(res=>{
        console.log("upload enclosure form details", res);
        this.applicantId = res.id;
        this.data = res;
        this.enclosure = res.enclosure;
    })
  }

  openSnackbar = (message: string, action?: string) => {
    if(!action) {
      action = "Dismiss";
    }
    this._snackBar.open(message, action);
  }

  onFileChanged = (event: any) => {
    const file = {};
    file['name'] = event.target.name;
    file['data'] = event.target.files[0];
    this.files.push(file);
  }

  public onSubmit = (): void => {
    if(!this.applicantId) {
      this.openSnackbar("Application not created. please fill Basic Details Form");
      return;
    }
    const formData = new FormData();
    for (const file of this.files) {
      formData.append(file.name, file.data,file.data.name);
    }
    this.applicationService.uploadDocuments(this.applicantId, formData).subscribe(res =>{
      this.data = res;
      this.enclosure = res.enclosure;
      this.openSnackbar("Successfully Uploaded");
    })
  }

  private fetchApplicantById = (applicantId: any) => {
    if(!applicantId) {
      return;
    }
    this.applicationService.fetchApplicantById(applicantId).subscribe(res =>{
        this.data = res;
        this.enclosure = res.enclosure;
    })
  }

  dismissIconClick = (id: any) => {
  (<HTMLInputElement> this.document.getElementById(id)).value = null;
     if(!this.files || this.files.length <= 0){
       return;
     }
  this.files.map((row, index) =>{
     if(row.name == id){
      this.files.splice(index, 1);
     }
  })
  }
}
