       <div class="col-sm-12"> 
                <div class="col-sm-11 header-top" >
                  <button class="btn btn-primary back-btn" (click)="goBack()">Back</button>
                    <span class="header">{{heading}} WORKBENCH - DECISION MAKING</span>
                   </div>
         </div>
                   <div class="label col-sm-10"  *ngIf = "data">
      
                    <div class ="col-sm-12 text-title">JCC NO:{{applicationNo}}<br>({{title}})</div>
                  
                   </div >
   
   
                   <div class="col-sm-11 title" >
                      ASSOCIATED APPLICATIONS
                      </div>
   
            
                    <div class="row key-text col-sm-12" >
                       <div class="col-sm-4  offset-sm-1 groupId"  *ngIf = "data">
                           Group ID: {{data.group ? data.group.groupNo : ''}}
                      </div>
                      <div class="col-sm-4 offset-sm-4 readyforreporting "  *ngIf = "data" style="color:darkgreen;">
                        Available for Adjudication : {{data.readyForReportingStatusApplicantCount}}
                   </div>
                   </div>

                   <div class="row key-text col-sm-12" >
                   <div class="col-sm-4 groupApplicantCount"  *ngIf = "data"  >
                    Group Size : {{groupApplicantCount}}
                   </div>
                   <div class="col-sm-4 offset-sm-4 otherscount"  *ngIf = "data" style="color:red;">
                    Not Available for Adjudication : {{data.otherStatusApplicantCount}}
              </div>
                </div>
            
                          
                <div class="application-container mat-elevation-z8" >
               <!-- data table -->
               <mat-card style="margin-top: -25px;">
                   <table  mat-table matTableExporter id="ExampleTable" [dataSource]="dataSource" #exporter="matTableExporter" matSort>
                   
                    <ng-container  style="width:7px" matColumnDef="select_all">
                      <th mat-header-cell *matHeaderCellDef  > Select All </th>
                      <td mat-cell *matCellDef="let element" class="column-container">
                          <mat-checkbox labelPosition="before" [(ngModel)]="element.checked" [disabled]="!element.isEligible"
                          [checked]="element.checked"></mat-checkbox>
                      </td>
                  </ng-container>
                       <ng-container  style="width:100%"  matColumnDef="sn">
                           <th mat-header-cell *matHeaderCellDef> Serial No. </th>
                           <td  mat-cell *matCellDef="let element ;let i =index;" class="column-container">{{i+1}}
                             </ng-container>
                       <!-- Position Column -->
                       <ng-container style=" max-width: 100%;
                       width: 9%;"  matColumnDef="applicationNo">
                        <th mat-header-cell *matHeaderCellDef> JCC No. </th>
                        <td mat-cell *matCellDef="let element"
                           [ngClass]="{
                              'positive' : element.isEligible == true,
                              'negative' : element.isEligible == false
                           }">
                           <a href="javascript:void(0)" (click)="onPreviewClick(element.id)" >{{element.applicationNo}}</a>
                        
                          </td>
                      </ng-container>


                       <ng-container matColumnDef="currentStatus">
                        <th mat-header-cell *matHeaderCellDef style="width:150px !important"> Current Status </th>
                        <td mat-cell *matCellDef="let element" title="{{element.status}}"
                        [ngClass]="{
                          'positive' : element.isEligible == true,
                          'negative' : element.isEligible == false
                       }"
                        > {{element.status}} - {{element.subStatus}} </td>
                    </ng-container>
                    
                    <ng-container style=" max-width: 100%;
                    width: 9%;"  matColumnDef="notificationType">
                     <th mat-header-cell *matHeaderCellDef> Notification Type </th>
                     <td mat-cell *matCellDef="let element" style="text-align: center;">
                        {{element.notificationType}}
                       </td>
                   </ng-container>

                   <ng-container class="right-1pc" style=" max-width: 100%;
                    width: 9%;"  matColumnDef="recommendationName">
                     <th mat-header-cell *matHeaderCellDef> Recommended By </th>
                     <td mat-cell *matCellDef="let element" style="text-align: center;">
                        {{element.recommendationName ? element.recommendationName : ''}}
                       </td>
                   </ng-container>

                       <!-- Telephone Column -->
                       <ng-container matColumnDef="comments">
                           <th mat-header-cell *matHeaderCellDef style="max-width: 100%;width: 25%;"> Comments </th>
                           <td style="max-width: 200px; word-wrap: break-word;" mat-cell *matCellDef="let element"> {{element.comment}}
                       </ng-container>

                       <ng-container matColumnDef="status">
                        <th mat-header-cell *matHeaderCellDef> Status </th>
                        <td  mat-cell *matCellDef="let element" title="{{element.selectedStatus ? element.selectedStatus.status : ''}}">
                            <mat-form-field style="width:180px !important">                             
                              <mat-select  placeholder="" [(ngModel)]="element.selectedStatus" (selectionChange)="onStatusChanged(element, element.selectedStatus)">
                                <mat-option *ngFor="let status of element.statusList " [value]="status">
                                {{ status.status }}                                   
                                </mat-option>
                              </mat-select>
                            </mat-form-field>
                        </td>
                         </ng-container>

                       <ng-container matColumnDef="subStatus">
                           <th  mat-header-cell *matHeaderCellDef> Sub Status </th>
                           <td  mat-cell *matCellDef="let element" title="{{element.selectedSubStatus ? element.selectedSubStatus.name : ''}}">
                            <mat-form-field style="width:120px !important">
                              <mat-select  placeholder=""[(ngModel)]="element.selectedSubStatus"  class="substatus" required> 
                                <mat-option *ngFor="let status of element.subStatusList " [value]="status" click="onStatusChanged(element)" title="{{status.name}}" >
                                  {{ status.name }}
                                </mat-option>
                              </mat-select>
                            </mat-form-field>
                           </td>
                       </ng-container>

                       <ng-container matColumnDef="actions">
                        <th mat-header-cell *matHeaderCellDef class ="data-header"> Actions </th>
                        <td mat-cell *matCellDef="let element" class="column-container">                      
                              <img class="pdf" src="assets/commentBox.png"(click)="commentDialogBox(element)">  
                              <button class="clear-btn doubtful-btn" (click)="clear(element)">Clear</button>                        
                        </td>
                    </ng-container>
                 
                    
                       <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                       <tr mat-row *matRowDef="let row; columns: displayedColumns;" ></tr>
                   </table>
                   
                   <div class="no-records-text" *ngIf="!dataSource.data || dataSource.data.length <= 0">No Records Found!</div>
                   <!-- <mat-paginator [pageSize]="pageSize" [pageSizeOptions]="[5, 10, 20]" [showFirstLastButtons]="true" 
                   [pageIndex]="currentPage" [length]="totalSize" (page)="getApplicantPaginatorData($event)">
               </mat-paginator> -->
               </mat-card>
              </div>
            
                 
               
            <div class="status-btn" fxLayout="row wrap" fxLayoutGap="10px" fxLayoutAlign="space-between">
               <div>
                 <button class="scrutiny-user-btn doubtful-btn" (click)="submitAdjudicationWorBench()">Submit</button>
               </div>
               </div>
    <app-footer></app-footer>
   
   
   