import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { MatTableDataSource } from '@angular/material/table';
import { ApplicationService } from 'src/services/application.service';
import { StrorageService } from 'src/services/strorage.service';
import { PageEvent } from '@angular/material/paginator';
import { element } from 'protractor';
interface status {
  value: string;
  viewValue: string;
}
@Component({
  selector: 'app-activity-log',
  templateUrl: './activity-log.component.html',
  styleUrls: ['./activity-log.component.scss']
  
})

export class ActivityLogComponent implements OnInit {
  

  public apiURL: string;
  public isDateFilterSelected = false;
  public pageSize =5;
  public currentPage = 0;
  public totalSize: number= 0;
  public show;

  public displayedColumns: string[] = ['sn','applicationNo', 'applicantName', 'recommendationName', 'comment', 'status','notificationType'];
  
  public dataSource = new MatTableDataSource<any>();
  user: any;
  village: any;
  villageId: any;
  statusList: any = [];
  status: any;
  totalRecord: any;
  selectedStatus:any;
  totalApplicants: any;
  status_List:any=[];
  applicationNo=null;
 
  // myGroup: any;
  // options: any[] = [];
  // filteredOptions: Observable<any[]>;
 constructor(private applicationService: ApplicationService, 
    private router: Router,private storageService:StrorageService,private cdr:ChangeDetectorRef) {
      
  }

  ngOnInit(): void {
    this.user=this.storageService.getUser();
    this.getScrutinyStatus();
    }
  ngAfterViewInit() {
    this.fetchApplicantList();
  }
  onClear() {
    this.applicationNo = null;
    this.fetchApplicantList();
  }

  public getApplicantPaginatorData(event: PageEvent) {
    this.currentPage = event.pageIndex;
    this.pageSize = event.pageSize;
    this.fetchApplicantList();
  }
  selectStatus(selectedStatus){
    
     this.fetchApplicantList();
  }

 

  public fetchApplicantList = () => {
    this.applicationService.getActivityLogs(this.currentPage,this.pageSize,this.selectedStatus, this.applicationNo).subscribe(res => {
      this.refreshDataTable(res);
    })
  }
 public getScrutinyStatus =() =>{
   this.applicationService.getApplicantStatus().subscribe(res=>{
    this.statusList = res.data;
    this.statusList.unshift({
      "statusId":"",
      "status":"All"
     
  });
    
    console.log("status",this.statusList);
  })
}
  private refreshDataTable = (res: any) => {
    this.totalSize = res.totalRecord;
    this.dataSource.data = res.data;
    this.dataSource._updateChangeSubscription();
    this.cdr.detectChanges();
  }

  clickBack(){
    window.history.back();
  }

  onPreviewClick(id: any){
    this.router.navigate(['/activity-log-view'], {queryParams:{application_id:id}});
   }


searchApplicant(){
//   this.applicationService.getScrutinyUserApplicants(undefined,undefined,this.currentPage, this.pageSize,this.applicationNo).subscribe(res =>{
//    this.refreshDataTable(res);
//   this.show=true;
//  });
        this.currentPage = 0;
        this.pageSize = 5;
        this.fetchApplicantList(); 
    }  
}
