import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { PageEvent } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { faSync } from '@fortawesome/free-solid-svg-icons';
import { DownloadReport } from 'src/app/community-login/community-view-applicants/download-report';
import { PiUserActivityLogComponent } from 'src/app/pi-user/pi-user-activity-log/pi-user-activity-log.component';
import { ConfirmDialogService } from 'src/app/shared/confirm-dialog/confirm-dialog.service';
import { ApplicationService } from 'src/services/application.service';
import { StrorageService } from 'src/services/strorage.service';

@Component({
  selector: 'app-rc-register-activity-log',
  templateUrl: './rc-register-activity-log.component.html',
  styleUrls: ['./rc-register-activity-log.component.scss']
})
export class RcRegisterActivityLogComponent implements OnInit {
  public selectedStatusOption: any;
  public apiURL: string;
  public statusCode: any;
  public pageSize =5;
  public currentPage = 0;
  public totalSize: number= 0;
  public totalCount: number= 0;
  statusList: any = [];
  selectedStatus:any;
  selectedStatusOptionCode: any;
  totalApplicants: any;
  status_List:any=[];
  statusOptions: any = [];

  public displayedColumns: string[] = [];
   
  public isRefreshing = false;
  public dataSource = new MatTableDataSource<any>();
  user: any;
  village: any;
  villageId: any;
  applicationNo = null;
  statusOption: any;
  statusOptionId:any;
  public totalApplicantCount: any = 0;
  public faSync = faSync;
  reportData: any;
  count: any;
  manipalApplicantsCount: any;
  rcRegisteredApplicantsCount: any;
  selectedStatusCode: any;
  showActionColumn: boolean;

  constructor(private applicationService: ApplicationService,private cdr:ChangeDetectorRef,
    private confirmDialogService: ConfirmDialogService,
    private _snackBar: MatSnackBar,
    private router: Router,private storageService:StrorageService) { }

   ngOnInit(): void {
    this.user=this.storageService.getUser();
    this.fetchRcRegisterActivityLog();
  }

  fetchRcRegisterActivityLog(){
    this.displayedColumns = ['sn','batchName','batchSize', 'receivedBy', 'receivedOn', 'view','action'];
    let statusCode = "sent_to_manipal";
    this.applicationService.fetchRcRegisterActivityLogs(statusCode,this.currentPage,this.pageSize).subscribe(res=>{;
      this.reportData = res;
      this.refreshApplicantDataTable(res);
      this.manipalApplicantsCount = res.data.length;
      console.log(res);
     })
   }

   clickRcRegistered() {
    this.displayedColumns = ['sn','batchName','batchSize', 'receivedBy', 'receivedOn', 'view'];
    let statusCode = "rc_printed_and_received";
    this.showActionColumn = false;
    this.applicationService.fetchRcRegisterActivityLogs(statusCode,this.currentPage,this.pageSize).subscribe(res=>{;
      this.reportData = res;
      this.statusCode = res.data.statusCode;
      this.refreshDataTable(res);
      this.rcRegisteredApplicantsCount = res.data.length;
      console.log(res);
     })
   }

   private refreshDataTable = (res: any) => {
    this.dataSource.data = res.data;
    this.totalSize = res.totalRecord;
    this.currentPage = (res.currentPage - 1);
    this.dataSource._updateChangeSubscription();
    if (!this.cdr['destroyed']) {
      this.cdr.detectChanges();
    }
  }
  private refreshApplicantDataTable = (res: any) => {
    this.dataSource.data = res.data;
    this.totalSize = res.totalRecord;
    this.currentPage = (res.currentPage - 1);
    this.dataSource._updateChangeSubscription();
    if (!this.cdr['destroyed']) {
      this.cdr.detectChanges();
    }
  }
  
  refreshClick(){
    let currentComponent = this;
    this.isRefreshing = true;
    setTimeout(()=>{
      currentComponent.isRefreshing = false;
    }, 3000)
    
  }
  public getApplicantPaginatorData(event: PageEvent, dataSource: any) {
    this.currentPage = event.pageIndex + 1;
    this.pageSize = event.pageSize;
    for(let i=0; i < dataSource.length;i++) {
      this.selectedStatusCode = dataSource[i].statusCode;
    }
    if(this.selectedStatusCode == 'sent_to_manipal') {
      this.fetchRcRegisterActivityLog();
    }else {
      this.clickRcRegistered();
    }
  }

  clickReportFilter(){
  }
  openSnackbar(message: string, action?: string){
    if(!action) {
      action = "Dismiss";
    }
    this._snackBar.open(message, action);
  }

  
  public onOkClicked = () => {
  }

  public rcReceived (data: any) {
    const options = {
      title: 'Confirmation!',
      message: 'Confirm Batch ' +data.batchName+' Batch Size : '+data.batchSize+' RC printed & Received ',
      cancelText:'No',
      confirmText: 'Yes'
    };
    this.confirmDialogService.openDialog(options);
    this.confirmDialogService.confirmed().subscribe(confirmed => {
      if(confirmed) {
        this.applicationService.rcRegisterUpdateActivityLogApplicantStatus(data.reportId).subscribe(res =>{
          this.openSnackbar("RC Printed & Received Successfully", "Dismiss");
          this.fetchRcRegisterActivityLog();
        })
      }
    });
   }

  onClear() {
    this.applicationNo = null;
  }

  clickBack(){
    window.history.back();

  }
 
}
