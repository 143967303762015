import { ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
  import * as moment from 'moment';
  import { Moment } from 'moment';
  import { faCheck, faTimes, faTrash } from '@fortawesome/free-solid-svg-icons';
import { ActivatedRoute, Router } from '@angular/router';
import { ApplicationService } from 'src/services/application.service';
import { StrorageService } from 'src/services/strorage.service';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { DmsPreviewDialogComponent } from '../dms-preview-dialog/dms-preview-dialog.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import { delay } from 'rxjs/operators';
import { DmsConfirmationModalComponent } from '../dms-confirmation-modal/dms-confirmation-modal.component';
  

@Component({
  selector: 'app-dms-workbench',
  templateUrl: './dms-workbench.component.html',
  styleUrls: ['./dms-workbench.component.scss']
})
export class DmsWorkbenchComponent implements OnInit {

  @ViewChild('fileInput') fileInput: ElementRef;
  
  documentCode: any;
  
  documentTypeId : string;
  
  docDate: any;
  
  formattedDate: any;
  
  public faCheck = faCheck;
  
  public faTimes = faTimes;
  
  public faTrash = faTrash;
  
  public todayDate:Moment = moment();

  user: any;

  public documentTypes: any = [] ;

  public documentSubTypes : any = [];

  data: any;

  public selectedDocumentCode: any;

  public uploadedDocument : any;

  public selectedDocumentType: any;

  public selectedDocumentSubType: any;

  public documentSubTypeId : string;

  public documentName = '';
  documentDate: string;
  documentDescription: string = '';
  documentNumber: string = '';

  
    constructor(private applicationService: ApplicationService, 
    private cdr:ChangeDetectorRef,  private storageService:StrorageService, private _snackBar: MatSnackBar,
    public route: ActivatedRoute,  private router: Router, public dialog: MatDialog) { }
  
    ngOnInit(): void {
      this.user=this.storageService.getUser();
      this.getAllDocumentTypes();
      this.getAllDocumentSubTypes();
    }
  
    documentChanged(documentId: any) {
      const documentType = this.documentTypes.find(item => item.id === documentId);
      this.selectedDocumentCode = documentType.documentCode;
      this.selectedDocumentType = documentType.documentType;
      this.generateDocumentName();
    }

    subDocumentChanged(subDocId: any) {
      const documentSubType = this.documentSubTypes.find(item => item.id === subDocId);
      this.selectedDocumentSubType = documentSubType.label;
    }

    getAllDocumentTypes() {
      this.applicationService.fetchAllDocumentTypes().subscribe(res => {
        if (res) {
          this.documentTypes = res;
        }
      })
    }

    getAllDocumentSubTypes() {
      this.applicationService.fetchAllDocumentSubTypes().subscribe(res => {
        if (res) {
          this.documentSubTypes = res;
        }
      })
    }

    generateDocumentName() {
      this.applicationService.generateDocumentName(this.selectedDocumentType, this.documentDate).subscribe(res => {
        console.log(res);
        if (res) {
          this.documentName = res.documentName;
        }
      })
    }

    validateAndConstructDmsPayload(){
      if(!this.selectedDocumentCode){
        throw Error('Document Type required');
      }
      if(!this.documentDate || !this.docDate){
        throw Error('Document Date required');
      }
      if(!this.documentNumber){
        throw Error('Document Number required');
      }
      if(!this.documentSubTypeId){
        throw Error('Document Sub Type required');
      }
      if(!this.uploadedDocument){
        throw Error('Upload Document is required');
      }
      let payload = {};
      payload['documentType'] = this.selectedDocumentType;
      payload['documentSubType'] = this.selectedDocumentSubType;
      payload['documentName'] = this.documentName;
      payload['documentCode'] = this.selectedDocumentCode;
      payload['documentDate'] = this.documentDate;
      payload['description'] = this.documentDescription;
      payload['documentNumber'] = this.documentNumber;
      return payload;
    }

    saveDmsMaster = () => {
      const formData = new FormData();
      formData.append('file', this.uploadedDocument);
      formData.append('documentTypeId', this.documentTypeId);
      formData.append('documentSubTypeId', this.documentSubTypeId);
      formData.append('documentDate', this.documentDate);
      formData.append('documentName', this.documentName);
      formData.append('description', this.documentDescription);
      formData.append('documentNumber', this.documentNumber);
      this.applicationService.saveDmsMaster(formData).subscribe(res => {
        this.openSnackbar("Dms "+this.documentName+" Saved sucessfully!");
        delay(2000);
        this.fetchActivityLogs();
      })
    }

    openSnackbar = (message: string, action?: string) => {
      if(!action) {
        action = "Dismiss";
      }
      this._snackBar.open(message, action);
    }

  onPreviewClick = () => {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = false;
    dialogConfig.autoFocus = true;
    dialogConfig.height = '40vh';
    dialogConfig.width = '70vw';
    this.data = this.validateAndConstructDmsPayload();
    dialogConfig.data = this.data;
    const dialogRef = this.dialog.open(DmsPreviewDialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(res => {
    })
  }
  
  formatDate(date:any){
    let inputDate = new Date(date);
    this.formattedDate = moment(inputDate).format('DDMMYYYY');
    this.documentDate = moment(inputDate).format('DD/MM/YYYY');
    if(this.selectedDocumentType){
      this.generateDocumentName();
    }
  }
  
  openDialog = () => {
    this.validateAndConstructDmsPayload();
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = false;
    dialogConfig.autoFocus = true;
    dialogConfig.height = '165px';
    dialogConfig.width = '580px';
    dialogConfig.data = "Confirm save & submit – Document Name : "+this.documentName+" & Document Type: "+ this.selectedDocumentType+"?";
    const dialogRef = this.dialog.open(DmsConfirmationModalComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(res => {
      if (res) {
        this.saveDmsMaster();
      }
    })
  }
  
    dismissIconClick() {
      this.fileInput.nativeElement.value = null;
      this.uploadedDocument = null;
      console.log('file',this.fileInput);
    }

    onFileChanged(event:any) {
      this.uploadedDocument = event.target.files[0];
    }
  
    fetchActivityLogs() {
      this.router.navigate(['/dms-activity-log']);
    }
  
  }