import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ApplicationService } from 'src/services/application.service';
import { StrorageService } from 'src/services/strorage.service';

@Component({
  selector: 'app-dpa-functional-head-component',
  templateUrl: './dpa-functional-head-component.component.html',
  styleUrls: ['./dpa-functional-head-component.component.scss']
})
export class DpaFunctionalHeadComponentComponent implements OnInit {

  constructor(private storageService:StrorageService, private applicationService: ApplicationService,
    private router:Router) { }

    
  userData: any;

  firstName: string;

  showCivilWorkOptions: boolean = false;

  public title:string = '';

  ngOnInit() {
    window.scroll(0,0);
    this.userData = this.storageService.getUser();
    if(this.userData){
      this.firstName = this.userData['firstName'];
    }
  }
  public prepActivity = () => {
    this.router.navigate(['/dpa-generation-workbench']);
  }

  public civilWork = () => {
    this.showCivilWorkOptions = !this.showCivilWorkOptions;
  }

  public createNewTask = () => {
    this.router.navigate(['/dpa-civil-work'])
  }

  public dpaGeneration = () => {
    this.router.navigate(['/dpa-work-item-workbench'])
  }
  public onClickEEmodule = () => {
    this.router.navigate(['/dpa-ee-observation-dashboard'])
  }
}
