<div class="container">
    <div>
        <app-top-bar selectedTab="home"></app-top-bar>
        <app-header></app-header>
    </div>
    <br>

    <div class="col-sm-12"> 
        <div class="container">
            <div class="rows">
              <div class="col-sm-1 refresh-btn-container">
                <button class="btn btn-primary" (click)="refreshClick()">
                    <fa-icon [icon]="faSync" [spin]="isRefreshing"></fa-icon>
                </button>
              </div>
              <div class="col-sm-2 refresh-btn-container">
                    <button class="btn btn-primary" (click)="goBack()">
                        Back
                    </button>
                </div>
              <div class="col-sm-5">
                <div class="text">
                 CD Info
                </div>
            </div>
        
            <div class="col-sm-4">
                <div class="">
                    <button class="data-btn btn" (click)="onCdData()">
                        CD Data
                    </button>
                </div>
                <div class=" user-details">
                    <div class="value-text">
                        Welcome 
                    </div>
                    <div class="value-text">
                         {{userName}} !
                    </div>
                </div>
                </div>

<div class="col-md-8 offset-md-2">
    <!-- data table -->
    <mat-card>
    
    <table mat-table [dataSource]="cdInfoDataSource">

        <!-- Position Column -->
        <ng-container matColumnDef="siNo">
            <th mat-header-cell *matHeaderCellDef> SI No. </th>
            <td mat-cell *matCellDef="let element ;let i =index;" class="column-container">{{i+1}}
        </ng-container>

         <!-- Name Column
         <ng-container matColumnDef="reportName">
            <th mat-header-cell *matHeaderCellDef> Report Name </th>
            <td mat-cell *matCellDef="let element"> {{element.reportName}} </td>
        </ng-container> -->
        
        <!-- batch name -->
        <ng-container matColumnDef="batchName">
            <th mat-header-cell *matHeaderCellDef> Batch Name </th>
            <td mat-cell *matCellDef="let element"> {{element.batchName}} </td>
        </ng-container>
        
        <!-- Telephone Column -->
        <ng-container matColumnDef="batchSize">
            <th mat-header-cell *matHeaderCellDef> Batch Size </th>
            <td mat-cell *matCellDef="let element"> {{element.batchSize}} </td>
        </ng-container>

          <!-- Preview Column -->
          <ng-container matColumnDef="action">
            <th mat-header-cell *matHeaderCellDef> Actions </th>
            <td mat-cell *matCellDef="let element">
                <a (click)="showCdViewFeederApplicants(element)" class="preview-link">View Applicants</a> <span class="vertical-divider"></span>
        </ng-container>
        
        <tr mat-header-row *matHeaderRowDef="cdInfodisplayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: cdInfodisplayedColumns;" ></tr>
    </table>
    <div class="no-records-text" *ngIf="!cdInfoDataSource.data || cdInfoDataSource.data.length <= 0">No Records Found!</div>
    <mat-paginator [pageSize]="pagesize" [pageSizeOptions]="[5, 10, 20]" [showFirstLastButtons]="true" [length]="totalSize"
        [pageIndex]="currentPage" (page)="getCdInfoPaginatorData($event)">
    </mat-paginator>
    </mat-card>
</div>


