<div class="container">
    <div>
        <app-top-bar selectedTab="home"></app-top-bar>
        <app-header></app-header>
    </div>
    <br>
   
    <div class="tool-bar">
        <div class="refresh-btn">
    
    </div>
    
    <div class="col-sm-1 ">
        <button class="btn btn-primary refresh-btn" (click)="refreshClick()">
            <fa-icon [icon]="faSync" class="close-icon" [spin]="isRefreshing"></fa-icon>
        </button>
    </div>
        <div class="col-sm-3 offset-sm-1 user-details">
            <div class="value-text">
                Welcome  <br>
            {{user['firstName']}} !
            </div>
            </div>
        </div>
           
    <div>
        <div class="col-sm-1 ">
        <div> <button mat-button class=" btn btn-primary" (click)="showActivityLog()">Activity Log</button> </div>
    </div>
    <div class ="col-sm-2 offset-sm-9 village-details">
        <div class ="p1">
            Total: {{applicationsCount}}
        </div>
    </div>

<div class="content-area">
    <div class="container">
        <div class="row">
            <div>
                <div *ngFor="let village of villages; let i = index;" class="village-options">
                    <div class="col-sm-12 offset-sm-3" (click)="onVillageChange(village.village)" 
                    [class.cursor-style]="type == 'village'">
                        <div class="button " [class.village]="type == 'village'"  
                        [style.background-color]=" village.village.id == selectVillage ? colors[i] :'grey'"
                        [style.border-color]=" village.village.id == selectVillage ? 'black' :'grey'"
                        [style.color]=" village.village.id == selectVillage ? 'black' :'#ffffff'">
                        <div>
                            <div class="center-align">
                                {{village.village.name}}
                            </div>
                            <div  class="center-align">
                                Applications count
                                </div>
                                <div class="center-align">
                                    {{village.applicationCount}}
                                </div>
                                </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div> 
</div>
<br><br>
<app-footer></app-footer>
