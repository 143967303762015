import { Component, OnInit } from '@angular/core';
import { ApplicationService } from 'src/services/application.service';

@Component({
  selector: 'app-sector-chart',
  templateUrl: './sector-chart.component.html',
  styleUrls: ['./sector-chart.component.scss']
})
export class SectorChartComponent implements OnInit {
  constructor(private applicationService: ApplicationService) { }

  data : any[] = [];

  title = 'Summary of RoadSide Drain'
  type = 'ColumnChart';
  options = {
  
  };
  width = 550;
  height = 450;
  chartData: any[] = [];
  columnNames: any[] = ['Sector Name', 'Progress count (In Kms)'];
  //  chartData = [
  //   ["Sector 1", 20, '20', 10, '10', 8, '8', 6, '6', 4, '4', 5, '5'],
  //   ["Sector 2", 18, '18', 8, '8', 2, '2', 5, '5', 4, '4', 5, '5'],
  //   ["Sector 3", 20, '20', 12, '12', 2, '2', 4, '4', 2, '2', 6, '6'],
  //   ["Sector 4", 16, '16', 12, '12', 6, '6', 9, '9', 6, '6', 3, '3'],
  //   ["Sector 5", 14, '14', 10, '10', 9, '9', 6, '6', 4, '4', 5, '5'],
  //   ["Sector 6", 18, '18', 8, '8', 6, '6', 2, '2', 4, '4', 2, '2'],
  //   ["Sector 7", 20, '20', 10, '10', 8, '8', 6, '6', 9, '9', 5, '5'],
  //   ["Sector 8", 15, '15', 9, '9', 10, '10', 6, '6', 4, '4', 4, '4'],
  //   ["Sector 9", 14, '14', 8, '8', 5, '5', 4, '4', 2, '2', 6, '6']
  // ];
  
  // columnNames = ['Sector Name', 'Excavation',{ type: 'string', role: 'annotation' },
  //  'Fabrication',{ type: 'string', role: 'annotation' }, 'PCC',{ type: 'string', role: 'annotation' },
  //   'Raft Concrete',{ type: 'string', role: 'annotation' } ,
  //    'Shuttering',{ type: 'string', role: 'annotation' }, 'Sidewalls',{ type: 'string', role: 'annotation' }];

  ngOnInit() {
    this.getSectorChartData();
  }

  getSectorChartData = () => {
    this.applicationService.getLayoutFormationSectorCharts().subscribe(res =>{
      this.chartData = [];
      // this.columnNames = [];
      this.data = res;
      // if(this.columnNames.length == 0) {
      //   this.filterColumnData();
      // }
      this.filterChartData();
      // console.log(this.chartData,'data');
      // this.retryGetSectorChartData();
    })
  }

  retryGetSectorChartData = () => {
    setTimeout(() => {
      this.getSectorChartData();
    }, 2000);
  }


  filterChartData(){
    for(let i = 0; i<this.data.length; i++){
      let chartDataset = [];
        if (chartDataset.length == 0){
          chartDataset.push(this.data[i].sectorName);
          chartDataset.push(this.data[i].progressCompleted);
        }
      this.chartData.push(chartDataset);
    }
  }

  // filterColumnData(){
  //   let columnData = [];
  //   for(let j = 0; j < this.data[0].sectorWorkTypeChartDataList.length; j++){
  //     if(columnData.length == 0){
  //       columnData.push('Sector Name');
  //     }
  //     columnData.push(this.data[0].sectorWorkTypeChartDataList[j].workName);
  //     // columnData.push({ type: 'string', role: 'annotation' });
  //   }
  //   this.columnNames = columnData;
  // }
}
