import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PiUserDashboardComponent } from '../pi-user/pi-user-dashboard/pi-user-dashboard.component';
import { MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { SharedModule } from '../shared/shared.module';
import { BrowserModule } from '@angular/platform-browser';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MaterialModule } from '../material.module';
import { PiuserWorkbenchComponent } from './pi-user-workbench/pi-user-workbench.component';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { PiUserActivityLogComponent } from './pi-user-activity-log/pi-user-activity-log.component';
import { PiUserActivityLogViewComponent } from './pi-user-activity-log-view/pi-user-activity-log-view.component';

const sharedComponents = [PiUserDashboardComponent, PiuserWorkbenchComponent, PiUserActivityLogComponent,PiUserActivityLogViewComponent];

@NgModule({
  declarations: [...sharedComponents, ],
  imports: [
    BrowserModule,
    FontAwesomeModule,
    CommonModule,
    SharedModule,
    MaterialModule,
    FlexLayoutModule,
    FormsModule,
    MatTooltipModule,
    MatDialogModule,
    MatCheckboxModule
  ],
  providers: [
    {
      provide: MatDialogRef,
      useValue: {}
    }
  ],
  entryComponents: [...sharedComponents],
  exports: [...sharedComponents]
})

export class PiUserModule { }
