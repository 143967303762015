import { Injectable } from '@angular/core';
import { url } from 'inspector';
import { Observable } from 'rxjs';
import { ApiService } from './api.service';
import { StrorageService } from './strorage.service';

@Injectable({
  providedIn: 'root'
})
export class ApplicationService {

  constructor(private apiService: ApiService, private storageService: StrorageService) { }

  saveApplication(payload: any): Observable<any> {
    return this.apiService.post('/v1/applicants', payload);
  }

  getApplicants(filters?: any): Observable<any> {
    return this.apiService.post('/v1/get_applicants', filters);
  }
  getActivityLogs(page?: any, size?: any, scrutinyStatusId?: any, applicationNo?: any): Observable<any> {
    let user = this.storageService.getUser();
    if (!user) {
      throw new Error("User not logged in");
    }
    let villageId = user['villageDTO'].id;
    if (!page) {
      page = 0;
    }
    if (!size) {
      size = 10;
    }

    let url = "/v1/scrutiny/" + villageId + "/activity_logs?page=" + page + "&size=" + size;
    if (scrutinyStatusId) {
      url = url + "&scrutinyStatus=" + scrutinyStatusId;
    }
    if (applicationNo) {
      url = url + "&applicationNo=" + applicationNo;
    }
    return this.apiService.get(url);
  }
  getScrutinyUserApplicants(filterType?: any, approvedFilter?: any, page?: any, size?: any, applicationNo?: any): Observable<any> {
    let user = this.storageService.getUser();
    if (!user) {
      throw new Error("User not logged in");
    }
    let villageId = user['villageDTO'].id;
    if (!page) {
      page = 0;
    }
    if (!size) {
      size = 10;
    }
    let url = "/v1/scrutiny_user/" + villageId + "/applicants?page=" + page + "&size=" + size;
    if (applicationNo) {
      url = url + "&applicationNo=" + applicationNo;
    }
    if (filterType) {
      url = url + "&filterType=" + filterType;
    }
    if (approvedFilter) {
      url = url + "&approvedFilter=" + approvedFilter;
    }
    console.log(url);
    return this.apiService.get(url);
  }

  getScrutinyUserDashboardFilterCount(villageId: any): Observable<any> {
    return this.apiService.get("/v1/scrutiny_user/" + villageId + "/record_count");
  }
  saveFieldVisit(id: any, payload: any): Observable<any> {
    return this.apiService.post("/v1/applicants/" + id + "/field_visit", payload);
  }

  //Field Visit User API
  getFieldUserApplicants(page?: any, size?: any, applicationNo?: any): Observable<any> {
    let user = this.storageService.getUser();
    if (!user) {
      throw new Error("User not logged in");
    }
    let villageId = user['villageDTO'].id;
    if (!page) {
      page = 0;
    }
    if (!size) {
      size = 10;
    }
    let url = "/v1/field_visit_user/villages/" + villageId + "/applicants?page=" + page + "&size=" + size;
    if (applicationNo) {
      url = url + "&applicationNo=" + applicationNo;
    }

    return this.apiService.get(url);
  }

  getFieldVisitStatus() {
    return this.apiService.get("/v1/field_visit_user/status");
  }

  getLoginSettings() {
    return this.apiService.get("/v1/login_settings");
  }
  getFieldVisitApplicantsByApplicationNo(searchKey: string): Observable<any[]> {
    if (searchKey) {
      return this.apiService.get('/v1/applicants/field_visit/application?searchQuery=' + searchKey);
    }
    // return this.apiService.get('/v1/application');
  }

  getFieldVisitActivityLog(page?: any, size?: any): Observable<any> {
    let user = this.storageService.getUser();
    if (!user) {
      throw new Error("User not logged in");
    }
    let villageId = user['villageDTO'].id;
    if (!page) {
      page = 0;
    }
    if (!size) {
      size = 10;
    }
    return this.apiService.get('/v1/field_visit_user/' + villageId + '/activity_logs?page=' + page + '&' + 'size=' + size);
  }
  searchFieldVisitApplicant(villageId: any, applicationNo: any): Observable<any[]> {
    return this.apiService.get('/v1/field_visit_user/villages/' + villageId + '/applicants/search?applicationNo=' + applicationNo);
  }
  // field visit user api end
  fetchAllVillages(): Observable<any[]> {
    return this.apiService.get('/v1/villages');
  }


  fetchAllVillagesByPanchayat(id, notificationType?: any, page?: any, pageSize?: any): Observable<any[]> {
    let url = "/v1/villages?panchayat=" + id;
    if (notificationType) {
      url = url + "&notificationType=" + notificationType;
    }
    return this.apiService.get(url);
  }

  fetchAllApplicantByVillageId(id: any, status_ref_code: any): Observable<any[]> {
    return this.apiService.get('/v1/applicants?village=' + id + '&statusRef=' + status_ref_code);
  }

  generateReport(reportStartPageNumber: any, batchSize: any, batchName: any): Observable<any[]> {
    if (!batchSize) {
      batchSize = 20; //default value
    }
    return this.apiService.get('/v1/generateReport?reportStartPageNumber=' + reportStartPageNumber + '&batchSize=' + batchSize + '&batchName=' + batchName);
  }

  generateReportBySelectedApplicants(payload: any): Observable<any[]> {
    return this.apiService.post("/v1/generateReportsByApplicantList", payload);
  }

  previewReportBySelectedApplicants(payload: any): Observable<any[]> {
    return this.apiService.post("/v1/PreviewReportsByApplicantList", payload);
  }


  getAllReports(statusCode: any, page?: any, pageSize?: any): Observable<any[]> {
    if (!page) {
      page = 0;
    }
    page = page + 1;
    if (!pageSize) {
      pageSize = 10;
    }
    return this.apiService.get('/v1/reports/status/' + statusCode + '?page=' + page + "&pageSize=" + pageSize);
  }

  getAllApplicants(statusCode: any, applicationNo?: any, villageId?: any, statusOptionId?: any): Observable<any[]> {

    let url = "/v1/applicants/status/" + statusCode + "/listAll?";

    if (applicationNo && villageId) {
      url = url + "applicationNo=" + applicationNo + "&villageId=" + villageId;
    } else {
      if (applicationNo) {
        url = url + "applicationNo=" + applicationNo;
      }
      if (villageId) {
        url = url + "villageId=" + villageId;
      }
      if (statusOptionId) {
        url = url + "&statusOptionId=" + statusOptionId;
      }
    }
    return this.apiService.get(url);
  }

  fetchAllApplicantByVillage(id): Observable<any[]> {
    return this.apiService.get('/v1/applicants?village=' + id);
  }
  getStatusOption(statusCode) {
    let url = "/v1/statusOption/status/" + statusCode;
    return this.apiService.get(url);
  }
  getApplicantsByGroup(applicationId: any) {
    let url = "/v1/groups/applicants/" + applicationId;
    return this.apiService.get(url);
  }
  getStatusOptions(statusCode) {
    let url = "/v1/statusOption/status/" + statusCode;
    return this.apiService.get(url);
  }
  getReportTitleConfigList() {
    let url = "/v1/reportConfig/reportTitles";
    return this.apiService.get(url);
  }
  getReportNameConfigList() {
    let url = "/v1/reportConfig/reportNames";
    return this.apiService.get(url);
  }
  fetchAllApplicant(notificationType: any): Observable<any[]> {
    let url = "/v1/applicantsCount";

    if (notificationType) {
      url = url + "?notificationType=" + notificationType;
    }
    return this.apiService.get(url);
  }
  fetchApplicantCountByVillage(id, notificationType?: any): Observable<any[]> {
    let url = "/v1/applicantsCount?village=" + id;
    if (notificationType) {
      url = url + "&notificationType=" + notificationType
    }
    return this.apiService.get(url);
  }

  fetchApplicantCountForReadyForReportingByVillage(statusOptionId: any): Observable<any[]> {
    return this.apiService.get('/v1/applicantsGetReadyForReportStatusCount?statusOptionId=' + statusOptionId);
  }
  fetchApplicantCountForOutOfBoundsByVillage(statusOptionId: any): Observable<any[]> {
    return this.apiService.get('/v1/applicantsGetOutOfBoundsStatusCount?statusOptionId=' + statusOptionId);
  }

  fetchApplicantCountForReportGeneratedByVillage(): Observable<any[]> {
    return this.apiService.get('/v1/applicantsGetReportGeneratedCount');
  }
  fetchApplicantCountForReportedOutOfBoundsByVillage(): Observable<any[]> {
    return this.apiService.get('/v1/applicantsGetReportedOutOfBoundsCount');
  }
  fetchAwardMasterApplicantCountByVillage(): Observable<any[]> {
    return this.apiService.get('/v1/awardMaster/awardMasterApplicantsCount');
  }
  fetchAllStatusesList(villageId: any, notificationType?: any): Observable<any[]> {
    let url = "/v1/applicants/status/statusForAllApplicantsAggregation?";

    if (villageId) {
      url = url + "villageId=" + villageId;
    }
    if (notificationType) {
      url = url + "&notificationType=" + notificationType;
    }
    return this.apiService.get(url);
  }

  fetchApplicantCountByPanchayat(id, notificationType: any): Observable<any[]> {
    let url = "/v1/applicantsCount?panchayat=" + id;
    if (notificationType) {
      url = url + "&notificationType=" + notificationType;
    }
    return this.apiService.get(url);
  }
  fetchApplicantByApplicationNo(applicationNo): Observable<any> {
    return this.apiService.get('/v1/application?applicationNo=' + applicationNo);
  }
  saveSiteDetailsWithOneTimeEdit(id: any, payload: any): Observable<any> {
    return this.apiService.put('/v1/applicants/' + id + '/site_details/one_time_edit', payload)
  }
  saveSiteApprovalWithOneTimeEdit(id: any, payload: any): Observable<any> {
    return this.apiService.put('/v1/applicants/' + id + '/site_approval/one_time_edit', payload)
  }
  saveBuildingDetailsWithOneTimeEdit(id: any, payload: any): Observable<any> {
    return this.apiService.put('/v1/applicants/' + id + 'building_details/one_time_edit', payload)
  }
  transferStatus(reportId: any, statusCode: any): Observable<any> {
    return this.apiService.put('/v1//reports/' + reportId + '/transferStatus/' + statusCode)
  }
  fetchLinkedApplicantsList(page?: any, pageSize?: any, applicationNo?: any, villageId?: any, groupNo?: any): Observable<any> {
    if (!page) {
      page = 0;
    }
    page = page + 1;
    if (!pageSize) {
      pageSize = 10;
    }
    let url = "/v1/groups/linkedApplicantsList?page=" + page + '&' + 'pageSize=' + pageSize;
    if (applicationNo) {
      url = url + "&applicationNo=" + applicationNo;
    }
    if (villageId) {
      url = url + "&villageId=" + villageId;
    }
    if (groupNo) {
      url = url + "&groupNo=" + groupNo;
    }
    return this.apiService.get(url);
  }

  fetchGroupApplicantsCount(villageId?: any, applicationNo?: any, groupNo?: any): Observable<any> {
    let url = "/v1/groups/activityLogs/count";
    if (applicationNo) {
      url = url + "&applicationNo=" + applicationNo;
    }
    if (villageId) {
      url = url + "&villageId=" + villageId;
    }
    if (groupNo) {
      url = url + "&groupNo=" + groupNo;
    }
    return this.apiService.get(url);
  }

  fetchApplicationNo(notificationType: any, searchKey?: string): Observable<any[]> {
    let url = "/v1/applicationNo";
    if (searchKey) {
      url = url + '?searchQuery=' + searchKey;
    }
    if (notificationType) {
      url = url + '?notificationType=' + notificationType;
    }
    return this.apiService.get(url);
  }
  fetchApplicants(): Observable<any[]> {
    return this.apiService.get('/v1/applicationNo');
  }
  updateScrutinyStatus(id: any, payload: any): Observable<any> {
    return this.apiService.put('/v1/applicants/' + id + '/scrutiny_status', payload);
  }
  fetchApplicantCount(statusCode: any, villageId: any, applicationNo: any) {
    let url = "/v1/applicants/status/" + statusCode + "/count?";

    if (applicationNo && villageId) {
      url = url + "applicationNo=" + applicationNo + "&villageId=" + villageId;
    } else {
      if (applicationNo) {
        url = url + "applicationNo=" + applicationNo;
      }
      if (villageId) {
        url = url + "villageId=" + villageId;
      }
    }
    return this.apiService.get(url);
  }

  fetchStatusOptionApplicantCount(statusCode: any, applicationNo: any, villageId: any, statusOptionId: any) {
    let url = "/v1/applicants/status/" + statusCode + "/statusOptions/count?";

    if (applicationNo && villageId) {
      url = url + "applicationNo=" + applicationNo + "&villageId=" + villageId;
    } else {
      if (applicationNo) {
        url = url + "applicationNo=" + applicationNo;
      }
      if (villageId) {
        url = url + "villageId=" + villageId;
      }
    }
    if (statusOptionId) {
      url = url + "&statusOptionId=" + statusOptionId;
    }
    return this.apiService.get(url);
  }

  fetchSelfApplicantCountByStatus(statusCode: any, villageId: any, applicationNo: any) {
    let url = "/v1/applicants/self/status/" + statusCode + "/count?";

    if (applicationNo && villageId) {
      url = url + "applicationNo=" + applicationNo + "&villageId=" + villageId;
    } else {
      if (applicationNo) {
        url = url + "applicationNo=" + applicationNo;
      }
      if (villageId) {
        url = url + "villageId=" + villageId;
      }
    }
    return this.apiService.get(url);
  }

  fetchAdjudicationActivityLogCount(statusId: any, applicationNo: any, notificationType?: any) {
    let url = "/v1/applicants/adjudication/activityLog/count?";
    if (applicationNo) {
      url = url + "applicationNo=" + applicationNo;
    }
    if (statusId) {
      url = url + "&statusId=" + statusId;
    }
    if (notificationType) {
      url = url + "&notificationType=" + notificationType;
    }
    return this.apiService.get(url);
  }
  fetchAdjudicationActivityLogCountBySubStatus(statusCode: any, applicationNo: any, statusOptionId?: any, notificationType?: any) {
    let url = "/v1/adjudication/activityLogs/count?";

    if (applicationNo) {
      url = url + "applicationNo=" + applicationNo;
    }
    if (statusCode) {
      url = url + "&statusCode=" + statusCode;
      if (statusOptionId) {
        url = url + "&statusOptionId=" + statusOptionId;
      }
    }
    if (notificationType) {
      url = url + "&notificationType=" + notificationType;
    }
    return this.apiService.get(url);
  }
  fetchPiUserActivityLogCount(statusCode: any, applicationNo: any, statusOptionId?: any) {
    let url = "/v1/piUser/activityLogs/count?";

    if (applicationNo) {
      url = url + "applicationNo=" + applicationNo;
    }
    if (statusCode) {
      url = url + "&statusCode=" + statusCode;
      if (statusOptionId) {
        url = url + "&statusOptionId=" + statusOptionId;
      }
    }
    return this.apiService.get(url);
  }

  fetchSelfApplicantCountBySubStatus(statusCode: any, applicationNo: any, villageId: any, statusOptionId: any) {
    let url = "/v1/applicants/self/status/" + statusCode + "/statusOptions/count?";

    if (applicationNo && villageId) {
      url = url + "applicationNo=" + applicationNo + "&villageId=" + villageId;
    } else {
      if (applicationNo) {
        url = url + "applicationNo=" + applicationNo;
      }
      if (villageId) {
        url = url + "villageId=" + villageId;
      }
    }
    if (statusOptionId) {
      url = url + "&statusOptionId=" + statusOptionId;
    }
    return this.apiService.get(url);
  }

  //start adjudication api
  getApplicantsByStatusList(filterType?: any, page?: any, size?: any, applicationNo?: any, villageId?: any, notificationType?: any, recommendedNameId?: any): Observable<any> {
    if (!page) {
      page = 0;
    }
    if (!size) {
      size = 10;
    }
    let url = "/v1/adjudication/applicants?page=" + page + '&' + 'size=' + size;
    if (applicationNo) {
      url = url + "&applicationNo=" + applicationNo;
    }
    if (filterType) {
      url = url + "&filterType=" + filterType;
    }
    if (villageId) {
      url = url + "&villageId=" + villageId;
    }
    if (notificationType) {
      url = url + "&notificationType=" + notificationType;
    }
    if (recommendedNameId) {
      url = url + "&recommendedNameId=" + recommendedNameId;
    }
    return this.apiService.get(url);
  }


  fetchAdjurictionDashboardCount(villageId: any, recommendedNameId?: any, notificationType?: any) {
    let url = "/v1/adjudication/total_record?";
    if (villageId) {
      url = url + "&villageId=" + villageId;
    }
    if (notificationType) {
      url = url + "&notificationType=" + notificationType;
    }
    if (recommendedNameId) {
      url = url + "&recommendedNameId=" + recommendedNameId;
    }
    return this.apiService.get(url);
  }
  fetchAdjudicationStatus(): Observable<any> {
    return this.apiService.get('/v1/adjudication_user/status_ref');
  }
  updateApplicantStatus(id: any, payload: any): Observable<any> {
    return this.apiService.put('/v1/applicants/' + id + '/status', payload);
  }
  updateMultipleApplicantStatus(eligibleApplicants: any): Observable<any> {
    return this.apiService.put('/v1/applicants/changeMultipleApplicantStatus', eligibleApplicants);
  }
  getAdjudicationActivityLog(page?: any, size?: any, adjudicationStatusId?: any, applicationNo?: any, statusOptionId?: any, notificationType?: any): Observable<any> {
    let user = this.storageService.getUser();
    if (!user) {
      throw new Error("User not logged in");
    }
    if (!page) {
      page = 0;
    }
    if (!size) {
      size = 10;
    }
    let url = '/v1/adjudication/activity_logs?page=' + page + '&' + 'size=' + size;
    if (adjudicationStatusId) {
      url = url + "&adjudicationStatus=" + adjudicationStatusId;
      if (statusOptionId) {
        url = url + "&statusOptionId=" + statusOptionId;
      }
    }
    if (applicationNo) {
      url = url + "&applicationNo=" + applicationNo;
    }
    if (notificationType) {
      url = url + "&notificationType=" + notificationType;
    }
    return this.apiService.get(url);
  }

  getPiUserApplicantsActivityLog(page?: any, size?: any, applicationNo?: any, statusCode?: any, statusOptionId?: any): Observable<any> {
    let user = this.storageService.getUser();
    if (!user) {
      throw new Error("User not logged in");
    }
    if (!page) {
      page = 0;
    }
    page = page + 1;
    if (!size) {
      size = 10;
    }
    let url = '/v1/piUser/activityLogs?page=' + page + '&' + 'pageSize=' + size;
    if (applicationNo) {
      url = url + "&applicationNo=" + applicationNo;
    }
    if (statusCode) {
      url = url + "&statusCode=" + statusCode;
      if (statusOptionId) {
        url = url + "&statusOptionId=" + statusOptionId;
      }
    }
    return this.apiService.get(url);
  }

  getPublicInterviwApplicants(page?: any, pageSize?: any, applicationNo?: any, villageId?: any, notificationType?: any): Observable<any> {
    let user = this.storageService.getUser();
    if (!user) {
      throw new Error("User not logged in");
    }
    if (!page) {
      page = page + 1;
    }
    if (!pageSize) {
      pageSize = 10;
    }
    let url = '/v1/piUser/applicants?page=' + page + '&' + 'pageSize=' + pageSize;
    if (villageId) {
      url = url + "&villageId=" + villageId;
    }
    if (applicationNo) {
      url = url + "&applicationNo=" + applicationNo;
    }
    if (notificationType) {
      url = url + "&notificationType=" + notificationType;
    }
    return this.apiService.get(url);
  }
  getAwardMasterApplicants(villageId?: any, awardSklNumber?: any, surveyNumberId?: any, page?: any, pageSize?: any): Observable<any> {
    let user = this.storageService.getUser();
    if (!user) {
      throw new Error("User not logged in");
    }
    if (!page) {
      page = page + 1;
    }
    if (!pageSize) {
      pageSize = 10;
    }
    let url = '/v1/awardMaster/applicants/' + villageId + '?page=' + page + '&' + 'pageSize=' + pageSize;
    if (villageId) {
      url = url + "&villageId=" + villageId;
    }
    if (awardSklNumber) {
      url = url + "&awardSklNumber=" + awardSklNumber;
    }
    if (surveyNumberId) {
      url = url + "&surveyNumberId=" + surveyNumberId;
    }
    return this.apiService.get(url);
  }
  getAdjudicationStatus(): Observable<any> {
    return this.apiService.get('/v1/adjudication_user/status_ref');
  }
  getPiUserActivityLog(): Observable<any> {
    return this.apiService.get('/v1/');
  }

  getReportingApplicants(page?: any, size?: any, landType?: any, villageId?: any): Observable<any> {
    if (!page) {
      page = 0;
    }
    if (!size) {
      size = 10;
    }
    let url = '/v1/adjudication/reporting?page=' + page + '&' + 'size=' + size;
    if (landType) {
      url = url + '&landType=' + landType;
    }
    if (villageId) {
      url = url + '&villageId=' + villageId;
    }
    return this.apiService.get(url);
  }

  //end of adjudication api


  fetchAllApplicantByPanchayat(id): Observable<any[]> {
    return this.apiService.get('/v1/applicants?panchayat=' + id);
  }

  fetchAllPanchayats(page?: any, pageSize?: any): Observable<any[]> {
    if (!page) {
      page = 0;
    }
    page = page + 1;
    if (!pageSize) {
      pageSize = 10;
    }
    return this.apiService.get('/v1/panchayat?page=' + page + "&pageSize=" + pageSize);
  }

  getApplicantEnclosureList(id: any): Observable<any> {
    return this.apiService.get('/v1/applicants/' + id + "/enclosures");
  }

  fetchApplicantById(id: any): Observable<any> {
    return this.apiService.get('/v1/applicant/' + id);
  }

  saveSiteDetails(id: any, payload: any): Observable<any> {
    return this.apiService.put('/v1/applicants/' + id + '/site_details', payload);
  }

  saveOneTimeEditBasicDetails(id: any, payload: any): Observable<any> {
    return this.apiService.put('/v1/applicants/' + id + '/one_time_edit_basic_details', payload);
  }

  updatePropertyType(id: any, propertyType: any, payload: any, buildingType?: any): Observable<any> {
    return this.apiService.put('/v1/applicants/' + id + '/property_details/' + propertyType + '?building_type=' + buildingType, payload);
  }

  savePropertyType(id: any, propertyType: any, payload: any, buildingType?: any): Observable<any> {
    return this.apiService.put('/v1/applicants/' + id + '/property_details/' + propertyType + '?building_type=' + buildingType, payload);
  }

  updateDetailsOfApproval(id: any, miscellaneousType: any, payload: any): Observable<any> {
    return this.apiService.put('/v1/applicants/' + id + '/miscellaneous/' + miscellaneousType + '/one_time_edit', payload);
  }
  saveDetailsOfApproval(id: any, miscellaneousType: any, payload: any): Observable<any> {
    return this.apiService.put('/v1/applicants/' + id + '/miscellaneous/' + miscellaneousType, payload);
  }


  saveSiteApproval(id: any, payload: any): Observable<any> {
    return this.apiService.put('/v1/applicants/' + id + '/site_approval', payload);
  }

  saveBuildingDetails(id: any, payload: any): Observable<any> {
    return this.apiService.put('/v1/applicants/' + id + '/building_details', payload);
  }

  uploadDocuments(id: any, payload: any): Observable<any> {
    return this.apiService.post('/v1/applicants/' + id + '/upload_document', payload);
  }

  uploadPropertyPicture(id: any, payload: any): Observable<any> {
    return this.apiService.put('/v1/applicants/' + id + '/upload_property_pic', payload);
  }

  getApplicantStatistics(notificationType?: any): Observable<any> {
    let url = "/v1/applicantStatitics";
    if (notificationType) {
      url = url + "?notificationType=" + notificationType;
    }
    return this.apiService.get(url);
  }

  getApplicantCountByDate(notificationType?: any): Observable<any> {
    let url = "/v1/applicantCountByDate";
    if (notificationType) {
      url = url + "?notificationType=" + notificationType;
    }
    return this.apiService.get(url);
  }

  getApplicantCountByDateFilter(fromDate, toDate, notificationType?: any): Observable<any> {
    let url = "/v1/applicantCountByDate?";
    if (fromDate) {
      url = url + "&fromDate=" + fromDate;
    }
    if (toDate) {
      url = url + "&toDate=" + toDate;
    }
    if (notificationType) {
      url = url + "&notificationType=" + notificationType;
    }
    return this.apiService.get(url);
  }

  overrideApplication(applicationNo): Observable<any> {
    return this.apiService.post('/v1/overrideApplication/' + applicationNo);
  }

  auditHelpdeskEntry(payload): Observable<any> {
    return this.apiService.post('/v1/auditHelpdesk', payload);
  }

  getAuditHelpdeskStatistics(): Observable<any> {
    return this.apiService.get('/v1/auditHelpdeskStatitics');
  }

  getAuditHelpdeskStatisticsByDate(fromDate, toDate): Observable<any> {
    return this.apiService.get('/v1/auditHelpdeskStatitics?fromDate=' + fromDate + '&toDate=' + toDate);
  }

  updateLock(id: any, payload: any): Observable<any> {
    return this.apiService.put('/v1/applicants/' + id + '/application_lock_status', payload);
  }

  getAllLandTypes(): Observable<any> {
    return this.apiService.get('/v1/lookup/land_types');
  }
  getApplicantStatus(): Observable<any> {
    return this.apiService.get('/v1/scrutiny_user/status_ref');
  }

  activateApplicantOneTimeEdit(applicantId: any): Observable<any> {
    return this.apiService.put('/v1/applicants/' + applicantId + '/activate_one_time_edit');
  }

  deActivateApplicantOneTimeEdit(applicantId: any): Observable<any> {
    return this.apiService.put('/v1/applicants/' + applicantId + '/deactivate_one_time_edit');
  }

  closeApplicantOneTimeEdit(applicantId: any): Observable<any> {
    return this.apiService.put('/v1/applicants/' + applicantId + '/close_one_time_edit');
  }
  fetchApplicantCountByStatusOption(statusCode: any): Observable<any> {
    return this.apiService.get('/v1/applicants/status/' + statusCode + '/statusOptionAggregation');
  }
  fetchAllGroups(): Observable<any> {
    return this.apiService.get('/v1/groups/allGroupsList');
  }

  fetchReportBatches(page?: any, pageSize?: any): Observable<any> {
    if (!page) {
      page = 0;
    }
    page = page + 1;
    if (!pageSize) {
      pageSize = 10;
    }
    let url = '/v1/reports/batches?page=' + page + '&' + 'pageSize=' + pageSize;
    return this.apiService.get(url);
  }
  fetchCdFeederReportApplicantsByReportId(reportId: any, page?: any, size?: any, searchQuery?: any): Observable<any> {
    if (!page) {
      page = 0;
    }
    page = page + 1;
    if (!size) {
      size = 10;
    }
    let url = '/v1/reports/' + reportId + '/cdFeederReports?page=' + page + '&' + 'pageSize=' + size;
    if (searchQuery) {
      url = url + "&searchQuery=" + searchQuery;
    }
    return this.apiService.get(url)
  }

  fetchCdFeederReportApplicantsWithGroupNo(page?: any, pageSize?: any, applicationNo?: any, villageId?: any, notificationType?: any): Observable<any> {
    let user = this.storageService.getUser();
    if (!user) {
      throw new Error("User not logged in");
    }
    if (!page) {
      page = 0;
    }
    page = page + 1;

    if (!pageSize) {
      pageSize = 10;
    }
    let url = '/v1/cdFeederReport/cdFeederReportApplicants?page=' + page + '&' + 'pageSize=' + pageSize;
    if (applicationNo) {
      url = url + "&applicationNo=" + applicationNo;
    }
    if (villageId) {
      url = url + '&villageId=' + villageId;
    }
    if (notificationType) {
      url = url + '&notificationType=' + notificationType;
    }
    return this.apiService.get(url);
  }
  fetchCdFeederReportAllApplicants(page?: any, pageSize?: any, applicationNo?: any, villageId?: any): Observable<any> {
    let user = this.storageService.getUser();
    if (!user) {
      throw new Error("User not logged in");
    }
    if (!page) {
      page = 0;
    }
    page = page + 1;

    if (!pageSize) {
      pageSize = 10;
    }
    let url = '/v1/cdFeederReport/getAllCdFeederReportApplicants?page=' + page + '&' + 'pageSize=' + pageSize;
    if (applicationNo) {
      url = url + "&applicationNo=" + applicationNo;
    }
    if (villageId) {
      url = url + '&villageId=' + villageId;
    }
    return this.apiService.get(url);
  }
  saveBasicInfo(id: any, payload: any): Observable<any> {
    return this.apiService.put('/v1/awardMaster/' + id + '/updateBasicInfo', payload);
  }
  saveAwardDetails(id: any, payload: any): Observable<any> {
    return this.apiService.put('/v1/awardMaster/' + id + '/updateAwardDetails', payload);
  }
  saveStructureDetails(id: any, payload: any): Observable<any> {
    return this.apiService.put('/v1/awardMaster/' + id + '/structureDetails', payload);
  }
  saveAwardAnalysisDetails(id: any, payload: any): Observable<any> {
    return this.apiService.put('/v1/awardMaster/' + id + '/updateAwardAnalysis', payload);
  }
  fetchSurveyNumberByVillageId(villageId: any): Observable<any[]> {
    return this.apiService.get('/v1/awardSurveyMaster/' + villageId + '/surveyNumber');
  }
  fetchCodeValueByCode(code: any): Observable<any> {
    return this.apiService.get('/v1/codeValue/' + code);
  }
  fetchAwardMasterApplicantsById(id: any): Observable<any> {
    return this.apiService.get('/v1/awardMaster/' + id);
  }
  uploadAwardMasterEnclosure(id: any, payload: any): Observable<any> {
    return this.apiService.put('/v1/awardMaster/' + id + '/upload_document', payload);
  }
  fetchAllSurveyNumbers(): Observable<any[]> {
    return this.apiService.get('/v1/awardSurveyMaster/surveyNumber');
  }
  activateAwardMasterOneTimeEdit(id: any): Observable<any> {
    return this.apiService.put('/v1/awardMaster/' + id + '/activate_one_time_edit');
  }
  closeAwardMasterOneTimeEdit(id: any): Observable<any> {
    return this.apiService.put('/v1/awardMaster/' + id + '/close_one_time_edit');
  }
  updateAwardMasterApplicantStatus(id: any, payload: any): Observable<any> {
    return this.apiService.put('/v1/awardMaster/' + id + '/updateAwardMasterStatus', payload);
  }
  markAsDone(id: any, payload: any): Observable<any> {
    return this.apiService.put('/v1/awardMaster/' + id + '/markAsDone', payload);
  }
  fetchAwardMasterActivityLogs(villageId?: any, awardSklNumber?: any, surveyNumberId?: any, statusCode?: any, page?: any, pageSize?: any): Observable<any> {
    let user = this.storageService.getUser();
    if (!user) {
      throw new Error("User not logged in");
    }
    if (!page) {
      page = page + 1;
    }
    if (!pageSize) {
      pageSize = 10;
    }
    let url = '/v1/awardMaster/activityLogs?page=' + page + '&' + 'pageSize=' + pageSize;
    if (villageId) {
      url = url + "&villageId=" + villageId;
    }
    if (awardSklNumber) {
      url = url + "&awardSklNumber=" + awardSklNumber;
    }
    if (surveyNumberId) {
      url = url + "&surveyNumberId=" + surveyNumberId;
    }
    if (statusCode) {
      url = url + "&status=" + statusCode;
    }
    return this.apiService.get(url);
  }
  fetchAllAwardMasterApplicantCount(): Observable<any[]> {
    return this.apiService.get('/v1/awardMaster/totalAwardMasterApplicantsCount');
  }


  fetchAllRcDisbursement(applicationNo?: any, villageId?: any, page?: any, pageSize?: any): Observable<any> {
    let user = this.storageService.getUser();
    if (!user) {
      throw new Error("User not logged in");
    }
    if (!page) {
      page = page + 1;
    }
    if (!pageSize) {
      pageSize = 10;
    }
    let url = '/v1/rcDisbursement/applicants?page=' + page + '&' + 'pageSize=' + pageSize;

    if (applicationNo) {
      url = url + "&applicationNo=" + applicationNo;
    }
    if (villageId) {
      url = url + "&villageId=" + villageId;
    }
    return this.apiService.get(url);
  }
  submitRcDisbursmentSelectedApplicants(payload: any): Observable<any> {
    return this.apiService.put('/v1/rcDisbursement/changeStatus', payload)
  }

  fetchRcDisbursementActivityLogs(page?: any, pageSize?: any, applicationNo?: any): Observable<any> {
    if (!page) {
      page = page + 1;
    }
    if (!pageSize) {
      pageSize = 10;
    }
    let url = '/v1/rcDisbursement/activityLogs?page=' + page + '&' + 'pageSize=' + pageSize;

    if (applicationNo) {
      url = url + "&applicationNo=" + applicationNo;
    }

    return this.apiService.get(url);
  }

  fetchAwardMasterStatus(villageId: any): Observable<any[]> {
    let url = "/v1/awardMaster/status/statusForAllApplicantsAggregation?";

    if (villageId) {
      url = url + "villageId=" + villageId;
    }
    return this.apiService.get(url);
  }
  fetchRcRegisterDataEnrichmentApplicants(applicationNo?: any, villageId?: any, structureTypeId?: any, annexureName?: any, statusCode?: any, page?: any, pageSize?: any): Observable<any> {
    let user = this.storageService.getUser();
    if (!user) {
      throw new Error("User not logged in");
    }
    if (!page) {
      page = page + 1;
    }
    if (!pageSize) {
      pageSize = 10;
    }
    let url = '/v1/rcRegister/dataEnrichment/applicants?page=' + page + '&' + 'pageSize=' + pageSize;

    if (applicationNo) {
      url = url + "&applicationNo=" + applicationNo;
    }
    if (villageId) {
      url = url + "&villageId=" + villageId;
    }
    if (structureTypeId) {
      url = url + "&structureTypeId=" + structureTypeId;
    }
    if (annexureName) {
      url = url + "&annexureName=" + annexureName;
    }
    if (statusCode) {
      url = url + "&statusCode=" + statusCode;
    }
    return this.apiService.get(url);
  }

  fetchRcSDDataValidationApplicants(applicationNo?: any, villageId?: any, page?: any, pageSize?: any): Observable<any> {
    let user = this.storageService.getUser();
    if (!user) {
      throw new Error("User not logged in");
    }
    if (!page) {
      page = page + 1;
    }
    if (!pageSize) {
      pageSize = 10;
    }
    let url = '/v1/rcRegister/rcSdDataValidation/applicants?page=' + page + '&' + 'pageSize=' + pageSize;

    if (applicationNo) {
      url = url + "&applicationNo=" + applicationNo;
    }
    if (villageId) {
      url = url + "&villageId=" + villageId;
    }
    return this.apiService.get(url);
  }

  fetchRcCDDataValidationApplicants(applicationNo?: any, villageId?: any, page?: any, pageSize?: any): Observable<any> {
    let user = this.storageService.getUser();
    if (!user) {
      throw new Error("User not logged in");
    }
    if (!page) {
      page = page + 1;
    }
    if (!pageSize) {
      pageSize = 10;
    }
    let url = '/v1/rcRegister/rcCdDataValidation/applicants?page=' + page + '&' + 'pageSize=' + pageSize;

    if (applicationNo) {
      url = url + "&applicationNo=" + applicationNo;
    }
    if (villageId) {
      url = url + "&villageId=" + villageId;
    }
    return this.apiService.get(url);
  }

  fetchAllStructureTypes(): Observable<any[]> {
    return this.apiService.get('/v1/structureType');
  }
  fetchRcRegisterDataEnrichmentApplicantsByCdFeederReportId(cdFeederId: any): Observable<any> {
    let user = this.storageService.getUser();
    if (!user) {
      throw new Error("User not logged in");
    }
    let url = '/v1/rcRegister/dataEnrichment/groupApplicants/' + cdFeederId;
    return this.apiService.get(url);
  }

  submitRcRegisterManagementApplicants(payload: any): Observable<any> {
    return this.apiService.post('/v1/rcRegister', payload)
  }
  fetchAllJccRcRegisterMgmtApplicants(applicationNo?: any, villageId?: any): Observable<any> {
    let user = this.storageService.getUser();
    if (!user) {
      throw new Error("User not logged in");
    }
    let url = '/v1/rcRegister/batchProcessing/applicants?';

    if (applicationNo) {
      url = url + "&applicationNo=" + applicationNo;
    }
    if (villageId) {
      url = url + "&villageId=" + villageId;
    }
    return this.apiService.get(url);
  }

  previewRCRegisterReportBySelectedApplicants(payload: any): Observable<any[]> {
    return this.apiService.post("/v1/rcRegister/PreviewReportsByApplicantList", payload);
  }

  generateRCRegisterReportBySelectedApplicants(payload: any): Observable<any[]> {
    return this.apiService.post("/v1/rcRegister/generateReportsByApplicantList", payload);
  }

  fetchRcRegisterDataEnrichmentApplicantsCount(): Observable<any> {
    return this.apiService.get('/v1/rcRegister/dataEnrichmentApplicantsCount');
  }

  fetchRcRegisterBatchProcessingApplicantsCount(): Observable<any> {
    return this.apiService.get('/v1/rcRegister/batchProcessingApplicantsCount');
  }

  fetchRcRegisterActivityLogs(statusCode?: any, page?: any, pageSize?: any): Observable<any> {
    let user = this.storageService.getUser();
    if (!user) {
      throw new Error("User not logged in");
    }
    if (!page) {
      page = page + 1;
    }
    if (!pageSize) {
      pageSize = 10;
    }
    let url = '/v1/rcRegister/activityLog/applicants/' + statusCode + '?page=' + page + '&' + 'pageSize=' + pageSize;
    return this.apiService.get(url);;
  }
  rcRegisterUpdateActivityLogApplicantStatus(reportId: any): Observable<any> {
    return this.apiService.put('/v1/rcRegister/activityLog/changeReportStatus/' + reportId)
  }

  fetchDataEnrichmentApplicantsByApplicantId(applicantId: any): Observable<any> {
    let user = this.storageService.getUser();
    if (!user) {
      throw new Error("User not logged in");
    }
    let url = '/v1/rcRegister/dataEnrichment/applicants/' + applicantId;
    return this.apiService.get(url);
  }


  fetchRcPreparationDataByApplicantId(applicantId: any): Observable<any> {
    let user = this.storageService.getUser();
    if (!user) {
      throw new Error("User not logged in");
    }
    let url = '/v1/rcRegister/rcPreparation/' + applicantId;
    return this.apiService.get(url);
  }
  fetchRcRegisterBatchProcessingApplicantById(rcRegisterId: any): Observable<any> {
    let user = this.storageService.getUser();
    if (!user) {
      throw new Error("User not logged in");
    }
    let url = '/v1/rcRegister/batchProcessing/applicant/rcRegister/' + rcRegisterId;
    return this.apiService.get(url);
  }
  updateRcRegisterBatchProcessingApplicantByList(payload: any): Observable<any> {
    return this.apiService.put('/v1/rcRegister/batchProcessing/applicants/update', payload)
  }
  fetchAllStatus(): Observable<any[]> {
    return this.apiService.get('/v1/status');
  }
  submitSelectedRcRegisterApplicants(payload: any): Observable<any> {
    return this.apiService.put('/v1/rcRegister/dataEnrichment/applicants/statusChange', payload)
  }
  fetchRcRegisterActivityLogApplicants(applicationNo?: any, villageId?: any, page?: any, pageSize?: any): Observable<any> {
    let user = this.storageService.getUser();
    if (!user) {
      throw new Error("User not logged in");
    }
    if (!page) {
      page = page + 1;
    }
    if (!pageSize) {
      pageSize = 10;
    }
    let url = '/v1/rcRegister/rcPrepActivityLog/applicants?page=' + page + '&' + 'pageSize=' + pageSize;

    if (applicationNo) {
      url = url + "&applicationNo=" + applicationNo;
    }
    if (villageId) {
      url = url + "&villageId=" + villageId;
    }
    return this.apiService.get(url);
  }

  fetchSdDataValiationActivityLog(applicationNo?: any, villageId?: any, page?: any, pageSize?: any): Observable<any> {
    let user = this.storageService.getUser();
    if (!user) {
      throw new Error("User not logged in");
    }
    if (!page) {
      page = page + 1;
    }
    if (!pageSize) {
      pageSize = 10;
    }
    let url = '/v1/rcRegister/rcSdDataValidation/activityLog/applicants?page=' + page + '&' + 'pageSize=' + pageSize;

    if (applicationNo) {
      url = url + "&applicationNo=" + applicationNo;
    }
    if (villageId) {
      url = url + "&villageId=" + villageId;
    }
    return this.apiService.get(url);
  }

  fetchCdDataValiationActivityLog(applicationNo?: any, villageId?: any, page?: any, pageSize?: any): Observable<any> {
    let user = this.storageService.getUser();
    if (!user) {
      throw new Error("User not logged in");
    }
    if (!page) {
      page = page + 1;
    }
    if (!pageSize) {
      pageSize = 10;
    }
    let url = '/v1/rcRegister/rcCdDataValidation/activityLog/applicants?page=' + page + '&' + 'pageSize=' + pageSize;

    if (applicationNo) {
      url = url + "&applicationNo=" + applicationNo;
    }
    if (villageId) {
      url = url + "&villageId=" + villageId;
    }
    return this.apiService.get(url);
  }

  fetchRcRegisterDatatables(statusCode?: any, applicationNo?: any, villageId?: any, page?: any, pageSize?: any): Observable<any> {
    let user = this.storageService.getUser();
    if (!user) {
      throw new Error("User not logged in");
    }
    if (!page) {
      page = page + 1;
    }
    if (!pageSize) {
      pageSize = 10;
    }
    let url = '/v1/rcRegister/applicantDataByStatusCode?page=' + page + '&' + 'pageSize=' + pageSize;
    if (statusCode) {
      url = url + "&statusCode=" + statusCode;
    }
    if (applicationNo) {
      url = url + "&applicationNo=" + applicationNo;
    }
    if (villageId) {
      url = url + "&villageId=" + villageId;
    }
    return this.apiService.get(url);
  }
  fetchRcRegisterApplicantsCountByStatusCode(): Observable<any> {
    return this.apiService.get('/v1/rcRegister/applicantCountsByStatusCode');
  }
  fetchRcValidationDataByApplicantId(applicantId: any): Observable<any> {
    return this.apiService.get('/v1/rcRegister/rcValidation/applicant/' + applicantId);
  }
  submitRcRegisterValidationApplicants(payload: any): Observable<any> {
    return this.apiService.post('/v1/rcRegister/saveSdDataValidation', payload);
  }
  updateRcRegisterCdDataValidationByApplicantId(payload: any): Observable<any> {
    return this.apiService.put('/v1/rcRegister/updateRcRegisterSdDataValidation', payload)
  }
  fetchCdValidationDataByApplicantId(applicantId: any): Observable<any> {
    return this.apiService.get('/v1/rcRegister/cdValidation/applicant/' + applicantId);
  }
  fetchCalculatedValues(acre: any, guntas: any, decimalValue: any): Observable<any[]> {
    let url = "/v1/awardMaster/values?";
    if (acre) {
      url = url + "&acre=" + acre;
    }
    if (guntas) {
      url = url + "&guntas=" + guntas;
    }
    if (decimalValue) {
      url = url + "&decimalValue=" + decimalValue;
    }
    return this.apiService.get(url);
  }
  fetchRcDisbursementByApplicantId(applicantId: any): Observable<any> {
    let user = this.storageService.getUser();
    if (!user) {
      throw new Error("User not logged in");
    }
    let url = '/v1/rcRegister/applicant/' + applicantId;
    return this.apiService.get(url);
  }
  fetchRcDisbursementWorkbenchApplicantByApplicantId(applicantId: any): Observable<any> {
    let user = this.storageService.getUser();
    if (!user) {
      throw new Error("User not logged in");
    }
    let url = '/v1/rcDisbursement//applicant/' + applicantId;
    return this.apiService.get(url);
  }
  fetchCountForAwardRegularApplicantsByStatus(statusCode: any): Observable<any> {
    let user = this.storageService.getUser();
    if (!user) {
      throw new Error("User not logged in");
    }
    let url = '/v1/awardMaster/count/awardRegularApplicantsByStatus?status=' + statusCode;
    return this.apiService.get(url);
  }
  fetchCountForAwardRegularApplicantsByVillageId(villageId?: any, statusCode?: any, surveyNumberId?: any, awardSklNumber?: any): Observable<any> {
    let user = this.storageService.getUser();
    if (!user) {
      throw new Error("User not logged in");
    }
    let url = '/v1/awardMaster/count/awardRegularApplicantsByVillageId?';
    if (villageId) {
      url = url + "villageId=" + villageId;
    }
    if (statusCode) {
      url = url + "&status=" + statusCode;
    }
    if (surveyNumberId) {
      url = url + "&surveyNumberId=" + surveyNumberId;
    }
    if (awardSklNumber) {
      url = url + "&awardSklNumber=" + awardSklNumber;
    }
    return this.apiService.get(url);
  }
  fetchAllNotificationTypes(): Observable<any> {
    return this.apiService.get('/v1/applicants/notificationTypes');
  }
  fetchNotificationApplicantsCount(): Observable<any[]> {
    return this.apiService.get('/v1/adjudication/notification_count');
  }
  fetchPersonalInterviewApplicantNotificationCount(): Observable<any[]> {
    return this.apiService.get('/v1/piUser/applicant/notification_count');
  }
  getRecommendation(): Observable<any> {
    return this.apiService.get("/v1/recommendation");
  }

  getRecommendationCount(): Observable<any> {
    return this.apiService.get("/v1/recommendation/count");
  }

  updateAppliantRecommendation(id: any, recommendationId: number): Observable<any> {
    return this.apiService.put("/v1/applicants/" + id + "/recommendation?recommendationId=" + recommendationId);
  }
  getGroupApplicantsByApplicationNo(applicationNo: any): Observable<any> {
    return this.apiService.get("/v1/rcRegister/applicantsByGroupNumber/" + applicationNo);
  }

  fetchSectorFormTemplateData(functionalType: string): Observable<any> {
    return this.apiService.get('/v1/sector/getSectorFormData/' + functionalType);
  }

  getFunctionTypeMainActivities = (functionTypeId: number): Observable<any> => {
    return this.apiService.get('/v1/executionLink/getFunctionTypeMainActivities?functionTypeId=' + functionTypeId);
  }

  getMainActivitiesByFunTypeCode = (functionTypeCode: string): Observable<any> => {
    return this.apiService.get('/v1/executionLink/getMainActivitiesByFunTypeCode?functionTypeCode=' + functionTypeCode);
  }

  getMeasurements = (): Observable<any> => {
    return this.apiService.get('/v1/measurements');
  }

  getMainActivityWorkTypes = (functionTypeId: number, mainActivityId: number): Observable<any> => {
    return this.apiService.get('/v1/executionLink/getMainActivityWorkTypes?functionTypeId=' + functionTypeId + '&mainActivityId=' + mainActivityId);
  }

  getMainActivityTaskWorkTypes = (sectorId: number, functionTypeId: number, mainActivityId: number, taskId: number): Observable<any> => {
    return this.apiService.get('/v1/sector/sectorWorkTypeByTaskId?sectorId=' + sectorId + '&functionTypeId=' + functionTypeId + '&mainActivityId=' + mainActivityId + '&taskId=' + taskId);
  }

  getLayoutFormationSurveyList = (sectorId: number, villageId: number, functionTypeId?: number, mainActivityId?: number, workTypeId?: number): Observable<any[]> => {
    let url = '/v1/sector/sectorSurveyList?';
    if (sectorId) {
      url = url + "sectorId=" + sectorId;
    }
    if (functionTypeId) {
      url = url + "&functionTypeId=" + functionTypeId;
    }
    if (mainActivityId) {
      url = url + "&mainActivityId=" + mainActivityId;
    }
    if (workTypeId) {
      url = url + "&workTypeId=" + workTypeId;
    }
    if (villageId) {
      url = url + "&villageId=" + villageId;
    }
    return this.apiService.get(url);
  }

  getLayoutFormationVillageList = (sectorId: number): Observable<any[]> => {
    return this.apiService.get('/v1/sector/sectorVillageList?sectorId=' + sectorId);
  }

  generateSectorWorkitemReport(functionType: string, payload: any, startDate?: any, endDate?: any): Observable<any> {
    let url = '/v1/layoutFormationReports/generateSectorWorkItemReport?functionType=' + functionType;
    if (startDate) {
      url = url + "&startDate=" + startDate;
    }
    if (endDate) {
      url = url + "&endDate=" + endDate;
    }
    return this.apiService.post(url, payload);
  }

  previewSectorWorkitemReport(functionType: string, payload: any, startDate?: any, endDate?: any): Observable<any> {
    let url = '/v1/layoutFormationReports/previewSectorWorkItemReport?functionType=' + functionType;
    if (startDate) {
      url = url + "&startDate=" + startDate;
    }
    if (endDate) {
      url = url + "&endDate=" + endDate;
    }
    return this.apiService.post(url, payload);
  }

  getDpaActivityLog(villageId?: any, surveyId?: any, dpaNo?: any, page?: any, pageSize?: any): Observable<any> {
    if (!page) {
      page = 1;
    }
    if (!pageSize) {
      pageSize = 10;
    }
    let url = '/v1/sector/activityLogs?page=' + page + '&' + 'pageSize=' + pageSize;
    if (villageId) {
      url = url + "&villageId=" + villageId;
    }
    if (surveyId) {
      url = url + "&surveyId=" + surveyId;
    }
    if (dpaNo) {
      url = url + "&dpaNo=" + dpaNo;
    }
    return this.apiService.get(url);
  }

  getSeconActivityLog(page?: any, pageSize?: any, maskFileName?: any): Observable<any> {
    if (!page) {
      page = page + 1;
    }
    if (!pageSize) {
      pageSize = 10;
    }
    let url = '/v1/lfDailyProgressMapView/activityLogs?page=' + page + '&' + 'pageSize=' + pageSize;
    if (maskFileName) {
      url = url + "&maskFileName=" + maskFileName;
    }
    return this.apiService.get(url);
  }

  getCommitteeDpaActivityLog(page?: any, pageSize?: any, villageId?: any, surveyId?: any): Observable<any> {
    let user = this.storageService.getUser();
    //  if(!user) {
    //    throw new Error("User not logged in");
    //   }
    if (!page) {
      page = 1;
    }
    if (!pageSize) {
      pageSize = 10;
    }
    let url = '/v1/sector/historyActivityLogs?page=' + page + '&pageSize=' + pageSize;
    if (villageId) {
      url = url + '&villageId=' + villageId;
    }
    if (surveyId) {
      url = url + '&surveyId=' + surveyId;
    }
    return this.apiService.get(url);
  }

  getAdVillages(): Observable<any[]> {
    return this.apiService.get('/v1/villageAdditionalInfo');
  }

  getAdSurveyByVillage(villageId: any): Observable<any[]> {
    return this.apiService.get('/v1/sector/surveyByVillage?villageIds=' + villageId);
  }

  uploadSaveSeconPdf(payload: any): Observable<any> {
    return this.apiService.post('/v1/lfDailyProgressMapView/savePdfFile', payload);
  }
  getLatestDapPdf(): Observable<any> {
    return this.apiService.get('/v1/sector/latestDapPdf');
  }
  getLatestDpPdf(): Observable<any> {
    return this.apiService.get('/v1/lfDailyProgressMapView/latestSeconPdf');
  }

  getWorkTaskActivityLog(page?: any, pageSize?: any, sectorId?: any, mainActivityId?: any, taskName?: any): Observable<any> {
    if (!page) {
      page = page + 1;
    }
    if (!pageSize) {
      pageSize = 10;
    }
    let url = '/v1/workTaskItem?page=' + page + '&' + 'pageSize=' + pageSize;
    if (sectorId) {
      url = url + "&sectorId=" + sectorId;
    }
    if (mainActivityId) {
      url = url + "&mainActivityId=" + mainActivityId;
    }
    if (taskName) {
      url = url + "&taskName=" + taskName;

    }
    return this.apiService.get(url);
  }

  getWorkTaskItemActivityLog(page?: any, pageSize?: any, sectorId?: any, mainActivityId?: any, taskName?: any): Observable<any> {
    if (!page) {
      page = page + 1;
    }
    if (!pageSize) {
      pageSize = 10;
    }
    let url = '/v1/workTaskItem/activityLog?page=' + page + '&' + 'pageSize=' + pageSize;
    if (sectorId) {
      url = url + "&sectorId=" + sectorId;
    }
    if (mainActivityId) {
      url = url + "&mainActivityId=" + mainActivityId;
    }
    if (taskName) {
      url = url + "&taskName=" + taskName;
    }
    return this.apiService.get(url);
  }

  getAssignedCivilActivityLog(page?: any, pageSize?: any, sectorId?: any, mainActivityId?: any, dapNo?: any, itemNo?: any): Observable<any> {
    if (!page) {
      page = page + 1;
    }
    if (!pageSize) {
      pageSize = 10;
    }
    let url = '/v1/getAssignedCivilHistory?page=' + page + '&' + 'pageSize=' + pageSize;
    if (sectorId) {
      url = url + "&sectorId=" + sectorId;
    }
    if (mainActivityId) {
      url = url + "&mainActivityId=" + mainActivityId;
    }
    if (dapNo) {
      url = url + "&dapNo=" + dapNo;
    }
    if (itemNo) {
      url = url + "&itemNo=" + itemNo;
    }
    return this.apiService.get(url);
  }

  getSectoryDailyActivityHistory(sectorId?: any, mainActivityId?: any, dapNo?: any, itemNo?: any, status?: any, workTypeId?: any, page?: any, pageSize?: any): Observable<any> {
    if (!page) {
      page = page + 1;
    }
    if (!pageSize) {
      pageSize = 10;
    }
    let url = '/v1/sectorDailyActivityHistory?page=' + page + '&' + 'pageSize=' + pageSize;
    if (sectorId) {
      url = url + "&sectorId=" + sectorId;
    }
    if (mainActivityId) {
      url = url + "&mainActivityId=" + mainActivityId;
    }
    if (dapNo) {
      url = url + "&dapNo=" + dapNo;
    }
    if (itemNo) {
      url = url + "&itemNo=" + itemNo;
    }
    if (status) {
      url = url + "&status=" + status;
    }
    if (workTypeId) {
      url = url + "&workTypeId=" + workTypeId;
    }
    return this.apiService.get(url);
  }

  getWorkTaskItemByMainActivity(sectorId?: any, mainActivityId?: any): Observable<any> {
    let url = '/v1/workTaskItem/getByMainActivityId?sectorId=' + sectorId + '&' + 'mainActivityId=' + mainActivityId;
    return this.apiService.get(url);
  }

  getAllMainActivity(): Observable<any[]> {
    return this.apiService.get('/v1/mainActivity');
  }
  getAllSectors(): Observable<any[]> {
    return this.apiService.get('/v1/sector/getAllSector');
  }
  getAllRoadSizes(): Observable<any[]> {
    return this.apiService.get('/v1/roadSize');
  }

  saveWorkTaskItem(payload: any): Observable<any> {
    return this.apiService.post('/v1/workTaskItem', payload);
  }

  getWorkTaskItemById(id: string): Observable<any> {
    return this.apiService.get('/v1/workTaskItem/' + id);
  }

  updateWorkTaskItem(workTaskItemId: any, payload: any): Observable<any> {
    return this.apiService.put('/v1/workTaskItem/updateWorkTaskItem/' + workTaskItemId, payload);
  }

  getConcernSource(): Observable<any[]> {
    return this.apiService.get('/v1/eeObservation/getAllConcernSource');
  }

  getEEObservationActivityLog(page?: any, pageSize?: any, sectorId?: any, concernSourceId?: any, userId?: any, observationNo?: any): Observable<any> {
    if (!page) {
      page = page + 1;
    }
    if (!pageSize) {
      pageSize = 10;
    }
    let url = '/v1/eeObservation/activityLogs?page=' + page + '&pageSize=' + pageSize;
    if (sectorId) {
      url = url + "&sectorId=" + sectorId;
    }
    if (concernSourceId) {
      url = url + "&concernSourceId=" + concernSourceId;
    }
    if (userId) {
      url = url + "&userId=" + userId;
    }
    if (observationNo) {
      url = url + "&observationNo=" + observationNo;
    }
    return this.apiService.get(url);
  }

  uploadDapEEObservation(payload: any): Observable<any> {
    return this.apiService.post('/v1/eeObservation', payload);
  }

  deleteEEObservation(observationNo: any): Observable<any> {
    return this.apiService.put('/v1/eeObservation/delete/' + observationNo);
  }

  getDpaUsers(): Observable<any[]> {
    return this.apiService.get('/v1//users/fetchDapUsers');
  }

  getEEObservationById(observationId: any): Observable<any> {
    return this.apiService.get('/v1/eeObservation/' + observationId);
  }

  getLayoutFormationWorkCharts(): Observable<any[]> {
    return this.apiService.get('/v1/sectorCharts/workType');
  }

  getLayoutFormationSectorCharts(): Observable<any[]> {
    return this.apiService.get('/v1/sectorCharts/sector');
  }

  getLayoutFormationSurveyNos(villageId: any): Observable<any[]> {
    return this.apiService.get('/v1/surveyAdditionalInfo/' + villageId);
  }


  getAdditionalSurveyNos(villageId?: any, surveyNotificationType?: any): Observable<any[]> {
    let url = '/v1/surveyAdditionalInfo';
    if (villageId && surveyNotificationType) {
      url = url + '?villageId=' + villageId + '&notificationType=' + surveyNotificationType;
    }else  {
      url = url + '?villageId=' + villageId;
    }
    return this.apiService.get(url);
  }


  getVillageSlaoData(villageId: any): Observable<any> {
    let url = '/v1/users/fetchSlao/' + villageId;
    return this.apiService.get(url);
  }

  getSlaoHistoryData(villageId?: any, surveyId?: any, surveyNotiicationType?: any): Observable<any[]> {
    let url = '/v1/lfSlao/fetchHistoryData/surveyAndVillageId';
    if (villageId && surveyId) {
      url = url + '?villageId=' + villageId + '&surveyId=' + surveyId ;
    }
    else {
      if (villageId) {
        url = url + '?villageId=' + villageId;
      }
      if (surveyId) {
        url = url + '?surveyId=' + surveyId;
      }
    }
    if((villageId || surveyId) && surveyNotiicationType) {
      url = url + '&notificationType=' + surveyNotiicationType;
    } else if(surveyNotiicationType) {
      url = url + '?notificationType=' + surveyNotiicationType;
    }
    return this.apiService.get(url);
  }

  getSlaoHistoryList(villageId: any, surveyId: any): Observable<any[]> {
    let url = '/v1/lfSlao/historyActivityLogs';
    if (villageId) {
      url = url + '?villageId=' + villageId;
    }
    if (surveyId) {
      url = url + '&surveyId=' + surveyId;
    }
    return this.apiService.get(url);
  }

  getSlaoHistoryActivity(villageId: any, surveyNo: any): Observable<any> {
    let url = '/v1/lfSlao/fetchHistoryData/surveyAndVillageId';
    if (villageId && surveyNo) {
      url = url + '?villageId=' + villageId + '&surveyNo=' + surveyNo;
    }
    else {
      if (villageId) {
        url = url + '?villageId=' + villageId;
      }
      if (surveyNo) {
        url = url + '?surveyNo=' + surveyNo;
      }
    }
    return this.apiService.get(url);
  }

  getSurveyById(surveyId: any): Observable<any> {
    return this.apiService.get('/v1/surveyAdditionalInfo/surveyData/' + surveyId);
  }

  fetchAllVillageAndProcessedSurveyNos(surveyNotificationType: any): Observable<any> {
    let url = '/v1/lfSlao/getCountOfVillageAndSurveyNo';
    if (surveyNotificationType) {
      url = url + '?notificationType=' + surveyNotificationType;
    }
    return this.apiService.get(url); 
  }

  getReconciliationHistoryExcelsheet(): Observable<any>{
    return this.apiService.post('/v1/lfSlao/downloadReconciliationHistoryXls');
  }

  getHistoryBySurveyReconId(reconciliationCode: any, villageId: any, surveyId: any, surveyNotificationType: any): Observable<any> {
    let url = '/v1/lfSlao/getDashboardViewDataList';
    if (reconciliationCode) {
      url = url + '?reconciliationCode=' + reconciliationCode;
    }
    if (villageId) {
      url = url + '&villageId=' + villageId;
    }
    if (surveyId) {
      url = url + '&surveyMstId=' + surveyId;
    }if(surveyNotificationType){
      url = url + '&surveyNotificationType=' + surveyNotificationType;
    }
    return this.apiService.get(url);
  }

  fetchTypeByReconciliationCode(code:string): Observable<any>{
    return this.apiService.get('/v1/lfSlao/reconciliationTypes/'+code);
  }

  getReconciliationVeiwXls(reconciliationCode: any, villageId: any, surveyId: any, surveyNotificationType: any): Observable<any>{
    let url = '/v1/lfSlao/downloadReconciliationViewXls';
    if (reconciliationCode) {
      url = url + '?reconciliationCode=' + reconciliationCode;
    }
    if (villageId) {
      url = url + '&villageId=' + villageId;
    }
    if (surveyId) {
      url = url + '&surveyMstId=' + surveyId;
    }if(surveyNotificationType){
      url = url + '&surveyNotificationType=' + surveyNotificationType;
    }
    return this.apiService.get(url);
  }

  fetchAllDocumentTypes() : Observable<any>{
    return this.apiService.get('/v1/dms/getAllDocumentTypes');
  }

  generateDocumentName(documentType?: any, documentDate?: any) : Observable<any>{
    let url = '/v1/dms/getDocumentName';

    if (documentType) {
      url = url + '?documentType=' + documentType;
    }
    if (documentDate) {
      url = url + '&documentDate=' + documentDate;
    }
    return this.apiService.get(url);
  }

  getDmsActivityLogs(page?: any, pageSize?: any, searchKeyword?: any, documentTypeId?: any, subDocumentId?:any, docNoKeyword?:any): Observable<any> {
    if (!page) {
      page = page + 1;
    }
    if (!pageSize) {
      pageSize = 10;
    }
    let url = '/v1/dms/activityLogs?page=' + page + '&pageSize=' + pageSize;
    if (searchKeyword) {
      url = url + "&keyword=" + searchKeyword;
    }
    if (docNoKeyword) {
      url = url + "&docNoKeyword=" + docNoKeyword;
    }
    if (documentTypeId) {
      url = url + "&documentTypeId=" + documentTypeId;
    }
    if (subDocumentId) {
      url = url + "&subDocumentTypeId=" + subDocumentId;
    }
    return this.apiService.get(url);
  }

  saveDmsMaster(formData: any) : Observable<any>{
    return this.apiService.post('/v1/dms/saveDmsMaster', formData);
  }

  editDmsMaster(dmsId: any, formData: any) : Observable<any>{
    return this.apiService.put('/v1/dms/editDms/'+dmsId, formData);
  }

  fetchDmsById(dmsId: any) : Observable<any>{
    return this.apiService.get('/v1/dms/'+dmsId);
  }

  deleteDms(dmsId: any) : Observable<any>{
    return this.apiService.put('/v1/dms/deleteDms/'+dmsId);
  }

  fetchAllDocumentSubTypes() : Observable<any>{
    return this.apiService.get('/v1/dms/documentSubTypes');
  }

  fetchExcelSheetUrl(keyword?: string, docTypeId?:number, subDocTypeId?:number) : Observable<any>{
    let url = '/v1/dms/fetchExcelSheetUrl?';
    if (keyword) {
      url = url + "?keyword=" + keyword;
    }
    if (docTypeId) {
      url = url + "&documentTypeId=" + docTypeId;
    }
    if (subDocTypeId) {
      url = url + "&subDocumentTypeId=" + subDocTypeId;
    }
    return this.apiService.get(url);
  }

}