import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { PageEvent } from '@angular/material/paginator';
import { faSync } from '@fortawesome/free-solid-svg-icons';

import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { ApplicationService } from 'src/services/application.service';
import { StrorageService } from 'src/services/strorage.service';
import { MAT_DIALOG_DATA,MatDialogRef,MatDialog } from '@angular/material/dialog';
import { PiUserTrackActivityService } from '../services/pi-user-track-activity.service';

@Component({
  selector: 'app-pi-user-dashboard',
  templateUrl: './pi-user-dashboard.component.html',
  styleUrls: ['./pi-user-dashboard.component.scss']
  
})

export class PiUserDashboardComponent implements OnInit {
  public pageSize =5;
  public currentPage = 0;
  public totalSize: number= 0;
  public faSync = faSync;
  public isRefreshing = false;
  public displayedColumns: string[] = ['sn','applicationNo', 'recommendationName', 'applicantName'];
  
  public dataSource = new MatTableDataSource<any>();
  selectedDateFilter:any;
  user: any;
  village: any;
  villages : any[];
  villageId: any;
  applicationNo = null;
  selectedVillages: number;
  public totalApplicantCount: any = 0;
  show: boolean;
  applicantServiceApiCallObserver: any;
  private piUserLastActivity: any;
  notificationType: string;
  oldNotificationBtn: boolean = true;
  newNotificationApplicantCount: any[];
  oldNotificationApplicantCount: any[];
  // isRefreshing: boolean;

  constructor(private applicationService: ApplicationService,
    private cdr:ChangeDetectorRef, 
    private dialogRef: MatDialogRef<PiUserDashboardComponent>,
    private piUserTrackActivityService: PiUserTrackActivityService, 
    private router: Router,private storageService:StrorageService) { }

    ngOnInit(): void {
      this.piUserLastActivity = this.piUserTrackActivityService.getLastDashboardActivity();
      this.user=this.storageService.getUser();
      this.fetchPiUserApplicants();
      this.fetchVillages();
      this.fetchAllNotificationApplicantsCount();
    
    }
    fetchVillages(){
      this.applicantServiceApiCallObserver = this.applicationService.fetchAllVillages().subscribe(res=>{
        this.villages = res;
        this.villages.unshift({
          "id":"",
          "name":"All"
         });
         this.restoreLastUserActivity();
        })
      }
  

    restoreLastUserActivity(): void {
        if(!this.piUserLastActivity) {
          return;
        }
        let lastActivity = this.piUserLastActivity;
        if(lastActivity.villageId) {
          this.villageId = lastActivity.villageId;
          this.onVillageChange();
        }
        
      }
    public getApplicantPaginatorData(event: PageEvent) {
      this.currentPage = event.pageIndex + 1;
      this.pageSize = event.pageSize;
      this.fetchPiUserApplicants();
    }
    onVillageChange(): void{
      this.show=false;
      this.fetchPiUserApplicants();
    }

    onClear() {
      this.applicationNo = null;
      this.fetchPiUserApplicants();
    }
    
    public showActivityLog = () => {
      this.router.navigate(['/pi-user-activity-log']);
    }
  
    public fetchPiUserApplicants = () => {
      if(this.notificationType == null) {
          this.notificationType = 'OLD';
      }
      this.applicationService.getPublicInterviwApplicants(this.currentPage,this.pageSize, 
       this.applicationNo, this.villageId, this.notificationType).subscribe(res => {
        this.refreshDataTable(res);
        console.log(res);
        
      })
    }
    
    searchApplicant(){
      this.fetchPiUserApplicants();
    }
  
    private refreshDataTable = (res: any) => {
      this.dataSource.data = res.data;
      this.totalSize = res.totalRecord;
      this.currentPage = (res.currentPage - 1);
      this.dataSource._updateChangeSubscription();
      if (!this.cdr['destroyed']) {
        this.cdr.detectChanges();
      }
    }
  
    clickBack(){
      window.history.back();
  
    }
    onPreviewClick(id: any){
      this.piUserTrackActivityService.setLastDashboardActivity(this.villageId);
      this.router.navigate(['/pi-user-workbench'],  {queryParams:{application_id:id}});
     }

     refreshClick(){
      let currentComponent = this;
      this.isRefreshing = true;
      setTimeout(()=>{
        currentComponent.isRefreshing = false;
      }, 3000)
      this.fetchPiUserApplicants();
    }
    notificationBtnClick(notificationType: any) {
      if(notificationType == 'new_notification') {
        this.notificationType = "NEW";
        this.oldNotificationBtn = false;
        this.fetchPiUserApplicants();
        this.fetchAllNotificationApplicantsCount();
      }else {
        this.notificationType = "OLD";
        this.oldNotificationBtn = true;
        this.fetchPiUserApplicants();
        this.fetchAllNotificationApplicantsCount();
      }
    }
    fetchAllNotificationApplicantsCount(){
    this.applicationService.fetchPersonalInterviewApplicantNotificationCount().subscribe(res=>{
        this.oldNotificationApplicantCount = res['oldNotificationCount'];
        this.newNotificationApplicantCount = res['newNotificationCount'];
        })
      }
  }
  
