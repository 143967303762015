import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { PageEvent } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { faSync } from '@fortawesome/free-solid-svg-icons';
import { DownloadReport } from 'src/app/community-login/community-view-applicants/download-report';
import { ConfirmDialogService } from 'src/app/shared/confirm-dialog/confirm-dialog.service';
import { MessageDialogService } from 'src/app/shared/message-dialog/message-dialog.service';
import { ApplicationService } from 'src/services/application.service';
import { StrorageService } from 'src/services/strorage.service';

@Component({
  selector: 'app-rc-register-batch-processing',
  templateUrl: './rc-register-batch-processing.component.html',
  styleUrls: ['./rc-register-batch-processing.component.scss']
})
export class RcRegisterBatchProcessingComponent implements OnInit {
  public pageSize =5;
  public currentPage = 0;
  public totalSize: number= 0;
  public faSync = faSync;
  public isRefreshing = false;
  public displayedColumns: string[] = ['all','rcSlNo','groupNumber','groupSize','jccNo','notificationType','applicantNameEng','applicantNameKann','propAddr','annexure','east', 'west', 'north', 'south','villageEng','villagekan','length','breadth','sitalArea','land','plinthArea','buildUpArea','noOfFloor','structureType','action'];
  public selectedApplicants: any = [];
  public dataSource = new MatTableDataSource();
  selectedDateFilter:any;
  user: any;
  village: any;
  villages : any[];
  villageId: any;
  public applicationNo: any;
  selectedVillages: number;
  public totalApplicantCount: any = 0;
  show: boolean;
  applicantServiceApiCallObserver: any;
  public id: number;
  public groupNumber:number;
  applicationName: any;
  villageName: any;
  selectedStructureType: any;
  rtcRemainingExtent: any;
  data: any;
  isEditable: any;
  finalNotification:any;
  public batchSize: number;
  public batchName: String;
  selectedJccNos: any;
  public rcSerialNo: any;
  public reportStartPageNumber: any;

  constructor(private applicationService: ApplicationService,
    private cdr:ChangeDetectorRef, 
    private confirmDialogService: ConfirmDialogService, 
    private router: Router,private storageService:StrorageService,
    private messageDialogService: MessageDialogService) { }

    ngOnInit(): void {
      this.user=this.storageService.getUser();
      this.fetchVillages();
      this.fetchBatchProcessingApplicants();
    }

    exportTable(){
      DownloadReport.exportToPdf("RCBatchName");
    }

    editForm(){
      this.router.navigate(['/rc-register-batch-processing']);
    }
    fetchBatchProcessingApplicants(){
      this.applicantServiceApiCallObserver = this.applicationService.fetchAllJccRcRegisterMgmtApplicants(this.applicationNo,this.villageId).subscribe(res=>{
        console.log(res);
        this.dataSource.data = res;
        this.refreshDataTable(res);
        })
      }
  
      
    fetchVillages(){
      this.applicantServiceApiCallObserver = this.applicationService.fetchAllVillages().subscribe(res=>{
        this.villages = res;
        this.villages.unshift({
          "id":"",
          "name":"All"
        });
        })
      }

      private refreshDataTable = (res: any) => {
        this.setSelectDefaultValue(res);
        this.dataSource.data = res;
        this.dataSource._updateChangeSubscription();
        this.cdr.detectChanges();
      }

      public getApplicantPaginatorData(event: PageEvent) {
        this.currentPage = event.pageIndex;
        this.pageSize = event.pageSize;
        this.fetchBatchProcessingApplicants();
      }

    onVillageChange(): void{
      this.show=false;
      this.fetchBatchProcessingApplicants();
    }

    searchApplicant(){
      this.fetchBatchProcessingApplicants();
    }

    onClear() {
      this.applicationNo = null;
      this.fetchBatchProcessingApplicants();
    }
  
    clickBack(){
      this.router.navigate(['/rc-register-dashboard'])
  
    }

    showActivityLog() {
      this.router.navigate(['/rc-register-activity-log']);
    }

    editRcRegisterBatchProcessing(rcRegisterId: any) {
      this.router.navigate(['/rc-register-edit-batch-processing-form'],{ queryParams: { rcRegisterId :rcRegisterId} })
    }

     refreshClick(){
      let currentComponent = this;
      this.isRefreshing = true;
      setTimeout(()=>{
        currentComponent.isRefreshing = false;
      }, 3000)
      this.fetchBatchProcessingApplicants();
    }
  
  parseAllSelectedApplicationNo(): any {
    let jccNos = [];
    for(let i = 0;i < this.selectedApplicants.length;i++) {
      jccNos.push(this.selectedApplicants[i].applicationNo);
    }
    return jccNos;
  }


  private setSelectDefaultValue(data: any): void {
    for(let i = 0;i < data.length;i++) {
      data[i].checked = true;
      for(let j = 0;j < this.selectedApplicants.length;j++) {
        if(this.selectedApplicants[j].applicationNo == data[i].applicationNo) {
          data[i].checked = true;
        }
      }
      this.selectOrDeSelectApplicants(null, data[i]);
    }
  }

  selectOrDeSelectApplicants(event:any, element: any) {
    if(this.selectedApplicants.length == 0) {
      if(element.checked) {
        this.selectedApplicants.push(element);
      }
    } else {
      let idx = -1;
      for(let i = 0;i < this.selectedApplicants.length;i++) {
        if(this.selectedApplicants[i].applicationNo == element.applicationNo) {
          idx = i;
          break;
        }
      }
      if(idx == -1) {
        if(element.checked) {
          this.selectedApplicants.push(element);
        }
      } else {
        if(!element.checked) {
          this.selectedApplicants.splice(idx, 1);
        } else {
          this.selectedApplicants[idx].checked = element.checked;
        }
      }
    }
    this.batchSize = this.selectedApplicants.length;
    this.selectedJccNos = this.selectedApplicants.length;
  }

  submitReport(): void {
    let jccNos = this.parseAllSelectedApplicationNo();
    if(jccNos.length == 0) {
      const options = {
        title: 'Alert!',
        message: 'Please select Applicants',
        confirmText: 'OK'
      };
      this.messageDialogService.openDialog(options);
      return;
    }
   
    else{
      const options = {
        title: 'Confirmation!',
        message: 'Confirm RC Successfully Disbursed for ' + jccNos +'.', 
        cancelText:'No',
        confirmText: 'Yes'
      };
      this.confirmDialogService.openDialog(options);
      this.confirmDialogService.confirmed().subscribe(confirmed => {
        if(confirmed) {
          let payload = [];
          for(let i = 0;i< jccNos.length;i++) {
            let payload1 = {};
            payload1['applicationNo'] = jccNos[i];
            payload.push(payload1);
          }
            this.applicationService.submitRcDisbursmentSelectedApplicants(payload).subscribe(res =>{
            return;
          })
        }
      });
    }
}

previewReport(): void {
  let jccNos = this.parseAllSelectedApplicationNo();
  if(jccNos.length == 0) {
    const options = {
      title: 'Alert!',
      message: 'Please select Applicants',
      confirmText: 'OK'
    };
    this.messageDialogService.openDialog(options);
    return;
  }
  else if(!this.batchSize) {
    const options = {
      title: 'Alert!',
      message: 'No Batch size is Entered, Please Enter batch size.',
      confirmText: 'OK'
    };
    this.messageDialogService.openDialog(options);
    return;
  }
   else if(!this.batchName) {
    const options = {
      title: 'Alert!',
      message: 'No Batch name is Entered, Please Enter batch name.',
      confirmText: 'OK'
    };
    this.messageDialogService.openDialog(options);
    return;
  }
  else if(!this.rcSerialNo) {
    const options = {
      title: 'Alert!',
      message: 'No RC running Sl.No is Entered, Please Enter RC running Sl.No.',
      confirmText: 'OK'
    };
    this.messageDialogService.openDialog(options);
    return;
  }
  else if(!this.reportStartPageNumber) {
    const options = {
      title: 'Alert!',
      message: 'No Page Number is Entered, Please Enter RC running Page No.',
      confirmText: 'OK'
    };
    this.messageDialogService.openDialog(options);
    return;
  }
  else{
    const options = {
      title: 'Confirmation!',
      message: 'Confirm Preview '+this.batchName+'.',
      cancelText:'No',
      confirmText: 'Yes'
    };
    this.confirmDialogService.openDialog(options);
    this.confirmDialogService.confirmed().subscribe(confirmed => {
      if(confirmed) {
        let payload = {};
        payload['batchName'] = this.batchName;
        payload['batchSize'] = this.batchSize;
        payload['applicationNos'] = jccNos;
        payload['reportStartPageNumber'] = this.reportStartPageNumber;
        payload['rcSerialNo'] = this.rcSerialNo;
        this.applicationService.previewRCRegisterReportBySelectedApplicants(payload).subscribe(res =>{
          console.log("Report Successfully generated");
          console.log(res);
          let data = res['pdfReportData']['fileURL'];
          let excelData = res['xlsReportData']['fileURL'];
          console.log(res);
          window.open(data, '_blank');
          window.open(excelData, '_blank');
        })
      }
    });
   
  }
}

generateReport(): void {
  let jccNos = this.parseAllSelectedApplicationNo();
  if(jccNos.length == 0) {
    const options = {
      title: 'Alert!',
      message: 'Please select Applicants',
      confirmText: 'OK'
    };
    this.messageDialogService.openDialog(options);
    return;
  }
  else if(!this.batchSize) {
    const options = {
      title: 'Alert!',
      message: 'No Batch size is Entered, Please Enter batch size.',
      confirmText: 'OK'
    };
    this.messageDialogService.openDialog(options);
    return;
  }
   else if(!this.batchName) {
    const options = {
      title: 'Alert!',
      message: 'No Batch name is Entered, Please Enter batch name.',
      confirmText: 'OK'
    };
    this.messageDialogService.openDialog(options);
    return;
  }
  else if(!this.rcSerialNo) {
    const options = {
      title: 'Alert!',
      message: 'No RC running Sl.No is Entered, Please Enter RC running Sl.No.',
      confirmText: 'OK'
    };
    this.messageDialogService.openDialog(options);
    return;
  }
  else if(!this.reportStartPageNumber) {
    const options = {
      title: 'Alert!',
      message: 'No Page Number is Entered, Please Enter RC running Page No.',
      confirmText: 'OK'
    };
    this.messageDialogService.openDialog(options);
    return;
  }
  else{
    const options = {
      title: 'Confirmation!',
      message: 'Confirm Report '+this.batchName+'.',
      cancelText:'No',
      confirmText: 'Yes'
    };
    this.confirmDialogService.openDialog(options);
    this.confirmDialogService.confirmed().subscribe(confirmed => {
      if(confirmed) {
        let payload = {};
        payload['batchName'] = this.batchName;
        payload['batchSize'] = this.batchSize;
        payload['applicationNos'] = jccNos;
        payload['reportStartPageNumber'] = this.reportStartPageNumber;
        payload['rcSerialNo'] = this.rcSerialNo;
        this.applicationService.generateRCRegisterReportBySelectedApplicants(payload).subscribe(res =>{
          console.log("Report Successfully generated");
          const resultOptions = {
            title: 'Message!',
            message: 'Report for Batch '+this.batchName+' generated successfully.',
            confirmText: 'OK'
          };
          this.messageDialogService.openDialog(resultOptions); 
          this.router.navigate(['/rc-register-dashboard']);
          return;
        })
      }
    });
   
  }
}
unSelectAll(): void {
  for(let i = 0;i < this.dataSource.data.length;i++) {
    this.dataSource.data[i]['checked'] = false;
    this.selectOrDeSelectApplicants(null, this.dataSource.data[i]);
  }
}
}