<div class="container ">
    <app-top-bar selectedTab="home" [heading]="'Application Inward – Activity Log'"></app-top-bar>
    <div class="my-3">
        <app-header></app-header>
    </div>
    <div class="row d-flex justify-content-between">
        <div class="col-sm-1 ">
            <button mat-button class="btn btn-primary mb-1" (click)="onClickBack()">Back</button>
            <button class="btn btn-primary refresh-btn mb-2" (click)="refreshClick()">
                <fa-icon [icon]="faSync" class="close-icon" [spin]="isRefreshing"></fa-icon>
            </button>
        </div>
        <div class="offset-sm-2 col-sm-3 d-flex justify-content-center">
            <h1>DMS</h1>
        </div>
        <div class="col-sm-2 user-details">
            <div class="value-text">
                Welcome <br>
                {{user['firstName'] ? user['firstName'] : ''}} !
            </div>
        </div>

    </div>
    <div class="row">
        <div class="col-sm-6">
            <div fxLayout="row" fxLayoutAlign="space-around" class=" w-75">
                <input class="form-control border-end-0 border rounded-pill" style="font-size: 14px !important;"
                    type="text" placeholder="Search by Document Name" [(ngModel)]="searchKeyword" id="searchKeyword">
                <span class="input-group-append"><br>&nbsp;
                    <button mat-button class="btn btn-primary" (click)="getActivityLogs()">Search</button>
                    <button *ngIf="searchKeyword" mat-button mat-icon-button aria-label="Clear" (click)="onClear()">
                        <mat-icon>close</mat-icon>
                    </button>
                </span>
            </div>
            <div fxLayout="row" fxLayoutAlign="space-around" class=" w-75 mt-2 mb-1">
                <input class="form-control border-end-0 border rounded-pill" style="font-size: 14px !important;"
                    type="text" placeholder="Search by Document No" [(ngModel)]="searchDocNo" id="searchKeyword">
                <span class="input-group-append"><br>&nbsp;
                    <button mat-button class="btn btn-primary" (click)="getActivityLogs()">Search</button>
                    <button *ngIf="searchDocNo" mat-button mat-icon-button aria-label="Clear"
                        (click)="onDocNoClear()">
                        <mat-icon>close</mat-icon>
                    </button>
                </span>
            </div>
            <img class="col-sm-2 xls-btn-resize" src="../../../assets/download_xlsx_icon.png"
                (click)="downloadActivityLogAsExcelSheet()" />
        </div>
        <div class="col-sm-6 d-flex justify-content-end">
            <div>
                <mat-form-field class="mr-4">
                    <mat-label>Document Type </mat-label>
                    <mat-select id="Document Type" name="Document Type" [(ngModel)]="selectedDocumentTypeId"
                        (selectionChange)="getActivityLogs()">
                        <mat-option *ngFor="let documentType of documentTypes"
                            [value]="documentType.id">{{documentType.id+' '+documentType.documentType}}</mat-option>
                    </mat-select>
                </mat-form-field>
                <mat-form-field>
                    <mat-label>Document Sub Type </mat-label>
                    <mat-select id="Document Sub Type" name="Document Sub Type" [(ngModel)]="selectedDocumentSubTypeId"
                        (selectionChange)="getActivityLogs()">
                        <mat-option *ngFor="let docSubType of documentSubTypes"
                            [value]="docSubType.id">{{docSubType.id+' '+docSubType.label}}</mat-option>
                    </mat-select>
                </mat-form-field>
                <div class="col-sm-12">
                    <h3 class="d-flex justify-content-end font-times">Total : {{totalSize}}</h3>
                </div>
            </div>
        </div>
    </div>
    <mat-card>

        <table mat-table [dataSource]="dataSource" class="w-100 text-center">
            <ng-container matColumnDef="slNo">
                <th mat-header-cell *matHeaderCellDef> Serial No. </th>
                <td mat-cell *matCellDef="let element ;let i =index;" class="column-container">{{i+1}}</td>
            </ng-container>

            <ng-container matColumnDef="docType">
                <th mat-header-cell *matHeaderCellDef> Doc Type </th>
                <td mat-cell *matCellDef="let element" class="column-container">{{element.documentType}}</td>
            </ng-container>

            <ng-container matColumnDef="docSubType">
                <th mat-header-cell *matHeaderCellDef> Doc Sub Type </th>
                <td mat-cell *matCellDef="let element" class="column-container">{{element.documentSubType}}</td>
            </ng-container>

            <ng-container matColumnDef="docName">
                <th mat-header-cell *matHeaderCellDef> Doc Name </th>
                <td mat-cell *matCellDef="let element" class="column-container">
                    <a href="{{element.documentUrl}}" target="_blank">{{element.documentName}}</a>
                </td>
            </ng-container>

            <ng-container matColumnDef="docNo">
                <th mat-header-cell *matHeaderCellDef> Doc No </th>
                <td mat-cell *matCellDef="let element" class="column-container">{{element.documentNumber}}</td>
            </ng-container>

            <ng-container matColumnDef="docDate">
                <th mat-header-cell *matHeaderCellDef> Doc Date </th>
                <td mat-cell *matCellDef="let element" class="column-container">{{element.documentDate}}</td>
            </ng-container>

            <ng-container matColumnDef="description">
                <th mat-header-cell *matHeaderCellDef> Description </th>
                <td mat-cell *matCellDef="let element" style="word-wrap: break-word;
              max-width: 16rem;" class="column-container">{{element.description ? element.description : '-' }}</td>
            </ng-container>

            <ng-container matColumnDef="uploadedOn">
                <th mat-header-cell *matHeaderCellDef> Uploaded on </th>
                <td mat-cell *matCellDef="let element" class="column-container">{{element.createdAt}}</td>
            </ng-container>

            <ng-container matColumnDef="uploadedBy">
                <th mat-header-cell *matHeaderCellDef> Uploaded by </th>
                <td mat-cell *matCellDef="let element" class="column-container">{{element.createdBy}}</td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>
        <div class="no-records-text" *ngIf="!dataSource.data || dataSource.data.length <= 0">No Records Found!</div>
        <mat-paginator [pageSize]="pageSize" [pageSizeOptions]="[5, 10, 20]" [showFirstLastButtons]="true"
            [length]="totalSize" [pageIndex]="currentPage" (page)="getPaginationData($event)">
        </mat-paginator>
    </mat-card>
</div>