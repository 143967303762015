<div class="content-area">
    <div class="col-sm-10 offset-sm-1">
        <app-top-bar selectedTab="home"></app-top-bar>
        <app-header></app-header>
    </div>
                <div class="col-sm-12"> 
                    <div class="container">
                      <div class=" d-flex  mx-auto mt-2 ">
                        <div class="layout">
                          <button mat-button class="btn w-100 btn-primary" (click)="onLayoutFormationEventHandler('Layout Formation')" type="button">Layout Formation</button>
                        </div>
                        <div class="dms">
                          <button mat-button class="btn w-75 btn-primary" (click)="onDMSEventHandler()" type="button">DMS</button>
                        </div>
                      </div>
                        <div class="row">
                          <div class="col-sm-1">
                            <button class="btn btn-primary refresh-btn" (click)="refreshClick()">
                                <fa-icon [icon]="faSync" class="close-icon"   [spin]="isRefreshing"></fa-icon>
                            </button>
                          </div>

                          <div class="col-sm-2">
                            <button mat-button class="btn btn-primary content-btn btn-width trends-btn" (click)="OnTrendsClick()" 
                            id="selectapp" type="button" [ngStyle]="{'background-color':'#0062cc'}">Trends And Analytics</button>
                          </div>
                          <div class="col-sm-2">
                            <button  mat-button  class="btn btn-primary report-btn btn-width" (click)="onReportingModuleClick()" 
                            type="button" [ngStyle]="{'background-color':'purple'}">Reporting Module</button>
                          </div>
                         
                          <div class="col-sm-2">
                            <button mat-button class="btn btn-primary content-btn btn-width transfer-btn" (click)="OnTransferBdaClick()" 
                            type="button" [ngStyle]="{'background-color':'#F4842B '}">Transfer to BDA</button>
                          </div>

                          <div class="col-sm-2">
                            <button  mat-button  class="btn btn-primary content-btn btn-width cd-btn" (click)="onCdInfoClick()" 
                            type="button" [ngStyle]="{'background-color':'#21CAAO'}">CD Info</button>
                          </div>
                        

                          <div class="col-sm-3">
                            <div class=" user-details">
                                <div class="value-text">
                                    Welcome 
                                </div>
                                <div class="value-text">
                                     {{userName}} !
                                </div>
                            </div> 
                            <div class="col-sm-12"> 
                                <div class="container">
                                    <div class="row">
                              <div class="col-sm-1">
                              <button class="btn  btn-primary content-btn btn-widthh" type="button" (click)="OnGPSClick()" style="color: white;">GPS - Regularized Application</button>`
                              </div>
                              <div class="col-sm-2">
                                <button  mat-button  class="btn btn-primary content-btn btn-widthhh" (click)="onActiveStatusClick()" 
                                type="button" [ngStyle]="{'background-color':'#B30018'}">Active Status</button>
                              </div>
                              <div class="col-sm-2">
                                <button  mat-button  class="btn  btn-primary content-btn award-status-btn" (click)="onAwardStatusClick()" 
                                type="button" [ngStyle]="{'background-color':'#F71563'}">Award Status</button>
                              </div>
                              <div class="col-sm-2">
                                <button  mat-button  class="btn btn-primary content-btn btn-idthhh" (click)="onRCPrepClick()" 
                                type="button" [ngStyle]="{'background-color':'#3AFC9A'}">Journey Map</button>
                              </div>
                                    </div>
                                </div>
                            </div>
                              
                              <!-- <div class="col-sm-4">
                                    <button  mat-button  class="btn btn-primary content-btns btn-width" (click)="onCdInfoClick()" 
                                    type="button" [ngStyle]="{'background-color':'#21CAAO'}">Out Bounds</button>
                                  </div> -->
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="d-flex justify-content-around">
                        <div class="col-sm-4 ">
                            <button mat-button class="btn content-btn btn-width w-75 notifications" 
                            id="allApplicants" type="button" (click)="notificationBtnClick(null)" [ngStyle]="{ 'background-color': allApplicationBtn ? '#ed7d31' : '#a6a6a6;' }">Total</button>
                          </div>  
                        <div class="col-sm-4 d-flex">
                            <button mat-button class="btn content-btn btn-width w-75 oldNotification" 
                            id="selectapp" type="button" (click)="notificationBtnClick('OLD')"  [ngStyle]="{ 'background-color': oldNotificationBtn ? '#ed7d31' : '#a6a6a6;' }">Old Notification</button>
                          </div>  
                    
                          <div class="col-sm-4 d-flex ">
                            <button  mat-button  class="btn content-btn btn-width w-75 newNotification"  
                            type="button" (click)="notificationBtnClick('NEW')"  [ngStyle]="{ 'background-color': newNotificationBtn ? '#ed7d31' : '#a6a6a6;' }">New Notification</button>
                          </div>
                     </div>
<div *ngIf="showCdInfoReportDatatable">
    <app-commitee-cdinfo></app-commitee-cdinfo>
</div>
    <div class="col-sm-10 offset-sm-1">
        <h2 class="statics-header">Key Metrics</h2>
            <app-statics (staticsClick)="onStaticsClick()"></app-statics>
        </div>
    
        <div class="col-sm-10 offset-sm-1">               
        <h2 class="statics-header">Application Statistics</h2>
                <!-- <app-application-statics></app-application-statics> -->
                <app-application-bar-chart></app-application-bar-chart>
                <h2 class="statics-header">Audit Helpdesk Statistics</h2>
                <app-audit-helpdesk-chart></app-audit-helpdesk-chart>
            </div>


    <div class="row">
        <div class="col-sm-10 offset-sm-1" [hidden]="!panchayats || panchayats.length == 0">
            <div class="panchayat-header">
                Choose Panchayat 
            </div>
            <div class="panchayat-options">
                <app-filter-option *ngFor="let panchayat of panchayats; let i = index;"
                 [data]="panchayat" [selectedId]="selectedPanchayat" [notificationType]="notificationType" [color]="colors[i]" (selectOption)="onPanchayatChange($event)" type="panchayat" 
                [hidden]="!panchayat || !panchayat.name" ></app-filter-option>
            </div>
        </div>
    </div>

    <div class="row">
        <div class="col-sm-10 offset-sm-1" [hidden]="!selectedPanchayat">
            <div class="panchayat-header">
            {{this.isCommitteeUser ? 'Villages' : ' Choose Village'}}
            </div>
            <div class="village-options">
                <app-filter-option *ngFor="let village of villages; let i = index;"
                 [data]="village" [color]="colors[i]" [selectedId]="selectVillage" (selectOption)="onVillageChange($event)" type="village"
                 [notificationType]="notificationType"></app-filter-option>
            </div>
        </div>
    </div>

   
<div class="col-md-8 offset-md-2" [hidden]="!selectVillage || isCommitteeUser">
      

    <!-- data table -->
    <mat-card>
    
    <table mat-table [dataSource]="dataSource">

        <!-- Position Column -->
        <ng-container matColumnDef="applicationNo">
            <th mat-header-cell *matHeaderCellDef> Application No. </th>
            <td mat-cell *matCellDef="let element"> {{element.applicationNo}} </td>
        </ng-container>
        
        <!-- Name Column -->
        <ng-container matColumnDef="applicantName">
            <th mat-header-cell *matHeaderCellDef> Name </th>
            <td mat-cell *matCellDef="let element"> {{element.applicantName}} </td>
        </ng-container>s.dataSource.data = res;
        
        <!-- Telephone Column -->
        <ng-container matColumnDef="telephoneNumber">
            <th mat-header-cell *matHeaderCellDef> Mobile </th>
            <td mat-cell *matCellDef="let element"> {{element.mobileNumber}} </td>
        </ng-container>

          <!-- Preview Column -->
          <ng-container matColumnDef="action">
            <th mat-header-cell *matHeaderCellDef> Actions </th>
            <td mat-cell *matCellDef="let element">
                <a (click)="onPreviewClick(element)" class="preview-link">Preview</a> <span class="vertical-divider">|</span>
                <a (click)="goDetailsPage(element)" class="enclosure-link">Enclosure</a> 
        </ng-container>
        
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;" ></tr>
    </table>
    <div class="no-records-text" *ngIf="!dataSource.data || dataSource.data.length <= 0">No Records Found!</div>
</mat-card>
</div>

   
    <div class="spinner" *ngIf="isLoading">
        <mat-spinner strokeWidth="5" diameter="60"></mat-spinner>
        </div>
    <app-footer></app-footer>
</div>