import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ApplicationService } from 'src/services/application.service';

@Component({
  selector: 'app-land-hand-over-status-activity-log',
  templateUrl: './land-hand-over-status-activity-log.component.html',
  styleUrls: ['./land-hand-over-status-activity-log.component.scss']
})
export class LandHandOverStatusActivityLogComponent implements OnInit {


  public landNotifiedInAcres: any;

  awardApprovedStatus: any;

  areaAwardedInAcres: any;

  noOfCashCompensation: any;

  cashCompensationInAcres: any;

  totalExtentInCash: any;

  noOfEcIssued: any;

  ecIssuedInAcres: any;

  ecIssuedInAllotedSite: any;

  historyId: any;

  villageId: any;

  surveyNo: any;

  villageName:any;

  lastUpdated:any;

  historyDataList : any[] = [];
  surveyId: any;
  slaoName: any;
  totalExtentInAcres: any[];
  acres: number;
  gunta: number;

  constructor(private router: Router, private route: ActivatedRoute, private applicationService: ApplicationService) {
    this.surveyId = this.route.snapshot.queryParams["surveyId"];
    this.villageName = this.route.snapshot.queryParams["villageName"];
    this.slaoName = this.route.snapshot.queryParams["slaoName"];
    this.villageId = this.route.snapshot.queryParams["villageId"];
   }

  ngOnInit() {
    this.getSlaoSelectedHistoryData();
    this.getSurveyData();
  }

  getSlaoSelectedHistoryData() {
    this.applicationService.getSlaoHistoryList(this.villageId,this.surveyId).subscribe(res => {
    console.log(res);
    this.historyDataList = res;
    })
  }

  getSurveyData() {
    this.applicationService.getSurveyById(this.surveyId).subscribe(res => {
    console.log(res);
    this.surveyNo = res.surveyNo;
    })
  }



  formattDate = (date:any) =>{
    let pipe = new DatePipe('en-IN');
    return pipe.transform(date, 'dd/MM/yyyy hh:mm');
  }

  clickBack(){
    window.history.back();
  }

}
