<div class="row content-area">
            <div class="col-sm-12 col-md-12">
                <div class="row application-form-row ">
                    <label class="form-label">Survey/Site No / ಸರ್ವೆ ಸಂಖ್ಯೆ / ನಿವೇಶನದ ಸಂಖ್ಯೆ. :</label>
                    <input type="text" [(ngModel)]="siteNumber" class="form-textbox" readonly/>
                </div>
            </div>

            <div class="col-sm-12 col-md-12">
                <div class="row application-form-row ">
                    <label class="form-label">Built up area (in Sq.ft) / ಕಟ್ಟಡದ ವಿಸ್ತೀರ್ಣ :</label>
                    <input type="number" [(ngModel)]="resArea" step="any" class="form-textbox" [readonly] = "(data && data.resArea) && !isEditable" />
                </div>
            </div>
            <div class="col-sm-12 col-md-12">
                <div class="row application-form-row ">
                    <label class="form-label">No.of Floors / ಮಹಡಿಗಳ ಸಂಖ್ಯೆ :</label>
                    <input type="number" [(ngModel)]="resFloors"   class="form-textbox" [readonly] = "(data && data.resFloors) && !isEditable"/>
                </div>
            </div>
            <div class="col-sm-12 col-md-12">
                <div class="row application-form-row ">
                    <label class="form-label">Total area (in Sq.ft) / ಒಟ್ಟು ವಿಸ್ತೀರ್ಣ :</label>
                    <input type="number" [(ngModel)]="resTotalarea" step="any" class="form-textbox" [readonly] = "(data && data.resTotalarea) && !isEditable"/>
                </div>
            </div>
            <div class="col-sm-12 col-md-12">
                <div class="row application-form-row ">
                    <label class="form-label">Address of the property / ಆಸ್ತಿಯ ವಿಳಾಸ :</label>
                    <input type="text" [(ngModel)]="buildingAddress" class="form-textbox" placeholder="Address" readonly/>
                </div>
            </div>
            <!-- <div class="col-sm-12 col-md-12">
                <div class="row application-form-row ">
                    <input type="text" class="form-textbox" placeholder="Address Line 2"/>
                </div>
            </div>
            <div class="col-sm-12 col-md-12">
                <div class="row application-form-row ">
                    <input type="text" class="form-textbox" placeholder="Address Line 3"/>
                </div>
            </div> -->
</div>
        

