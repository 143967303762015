import { Injectable } from '@angular/core';
import * as moment from 'moment';

@Injectable({
  providedIn: 'root'
})
export class StrorageService {

  localStorage: Storage;

  private ACCESS_TOKEN_KEY: string = "access_token_key_";

  private ACCESS_TOKEN_EXPIRE_TIME_KEY: string = "access_token_expired_time_key_";

  private USER_KEY: string = "user";

  private NOTIFICATION_TYPE_KEY: string = "selectedNotificationType";

  private DEFAULT_TOKEN_EXPIRY_MINUTES: number = 180;

  constructor() {
    this.localStorage = window.localStorage;
  }

  saveUser = (user: any) => {
    let currentTime: any = moment();
    let tokenExpiryTime = currentTime.add(this.DEFAULT_TOKEN_EXPIRY_MINUTES, 'minutes');
    this.localStorage.setItem(this.USER_KEY, JSON.stringify(user));
    this.saveAccessToken(user['accessToken']);
    this.saveAccessTokenExpiryTime(tokenExpiryTime);
  }

  saveNotificationType = (notificationType: any) => {
    this.localStorage.setItem(this.NOTIFICATION_TYPE_KEY, notificationType);
  }

  getUser = () => {
    return JSON.parse(this.localStorage.getItem(this.USER_KEY));
  }

  getNotificationType = () => {
    return this.localStorage.getItem(this.NOTIFICATION_TYPE_KEY);
  }

  saveAccessToken = (access_token: any) => {
    this.localStorage.setItem(this.ACCESS_TOKEN_KEY, access_token);
  }

  saveAccessTokenExpiryTime = (expiryTime: any) => {
    this.localStorage.setItem(this.ACCESS_TOKEN_EXPIRE_TIME_KEY, expiryTime);
  }

  getAccessTokenExpiryTime = () => {
    return this.localStorage.getItem(this.ACCESS_TOKEN_EXPIRE_TIME_KEY);
  }

  getAccessToken = () : string => {
    return this.localStorage.getItem(this.ACCESS_TOKEN_KEY);
  }

  clear = () => {
    this.localStorage.clear();
  }

  getItem(key: any): any {
    if (this.isLocalStorageSupported) {
      return this.localStorage.getItem(key);
    }
    return null;
  }

  setItem(value: any, key: any): boolean {
    if (this.isLocalStorageSupported) {
      this.localStorage.setItem(key, value);
      return true;
    }
    return false;
  }

  addItemToLocalStorage(key: any, value: any): void {
    this.localStorage.setItem(key, value);
  }

  getItemFromLocalStorage(key: any) {
    return this.localStorage.getItem(key);
  }

  removeItem(key: any): boolean {
    if (this.isLocalStorageSupported) {
      this.localStorage.removeItem(key);
      return true;
    }
    return false;
  }

  isAlreadyLogin(): boolean {
    return this.localStorage.getItem(this.USER_KEY) ? true : false;
  }

  isLoggedInTokenExpired(): boolean {
    let expiryTime = moment(this.getAccessTokenExpiryTime());
    let expiryTimeMilliSeconds = expiryTime.valueOf();
    let currentTime = moment();
    let currentTimeMilliSeconds = currentTime.valueOf();
    if(currentTimeMilliSeconds < expiryTimeMilliSeconds) {
        let updatedExpiryTime = currentTime.add(this.DEFAULT_TOKEN_EXPIRY_MINUTES, 'minutes');
        this.saveAccessTokenExpiryTime(updatedExpiryTime);
        return false;
    }
    return true;
  }
  
  get isLocalStorageSupported(): boolean {
    return !!this.localStorage
  }

}
