<div  class="head">
	{{data?.message}}
</div>
<div class="col-sm-12">
    <div class="rows application-form-row ">
        <textarea class="form-control" style="height: 125px !important;" [(ngModel)] = "comment" rows="5" placeholder="Enter Comments/Observations/Recommendations(if any)" name="comment"></textarea> 
        </div>
        </div>
<div class="button" >
	<button  class="btn negative-btn" mat-button (click)="onCancelClicked()">Cancel</button>
	<button mat-button (click)="onOkClicked()" class="btn btn-primary" cdkFocusInitial>Ok</button>
  </div>
