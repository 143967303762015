import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { PageEvent } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { PiUserActivityLogComponent } from 'src/app/pi-user/pi-user-activity-log/pi-user-activity-log.component';
import { ApplicationService } from 'src/services/application.service';
import { StrorageService } from 'src/services/strorage.service';

@Component({
  selector: 'app-award-scrutiny-user-activity-log',
  templateUrl: './award-scrutiny-user-activity-log.component.html',
  styleUrls: ['./award-scrutiny-user-activity-log.component.scss']
})
export class AwardScrutinyUserActivityLogComponent implements OnInit {
  surveyNumbers: any;
  selectedSurveyNumber: any;
  public selectedStatusOption: any;
  public apiURL: string;
  public statusCode: any;
  public pageSize =5;
  public currentPage = 0;
  public totalSize: number= 0;
  public totalCount: number= 0;
  awardSklNumber = null;


  public displayedColumns: string[] = ['sn','awardSklNumber', 'awardNumber', 'surveyNumber', 'documentName'];
  
  public dataSource = new MatTableDataSource<any>();
  user: any;
  village: any;
  villageId: any;
  applicationNo = null;
  statusOption: any;
  statusOptionId:any;
  public totalApplicantCount: any = 0;
  public villages: any;
  surveyNumber: any;
  applicantServiceApiCallObserver: any;
  show: boolean;
  selectedVillageId: any;
  statusList: any = [];
  selectedStatus:any;
  statusOptions: any = [];
  statusCount: any;
  villageCount: any;

  constructor(private applicationService: ApplicationService,private cdr:ChangeDetectorRef,private dialogRef: MatDialogRef<PiUserActivityLogComponent>, 
    private router: Router,private storageService:StrorageService,private route: ActivatedRoute) { 
      this.villageId = this.route.snapshot.queryParams['village_id'];
    }

   ngOnInit(): void {
    this.user=this.storageService.getUser();
    this.fetchAwardMasterActivityLogs();
    this.getSurveyNumbers();
    this.fetchVillages();
    this.getAwardRegularStatus();
    this.getCountForAwardRegularApplicants(null);
    this.getCountForAwardRegularApplicantsByVillage("");
  }
 
  private fetchAwardMasterActivityLogs = (villageId?: any, statusCode?: any) => {
    this.applicationService.fetchAwardMasterActivityLogs(this.villageId,this.awardSklNumber,this.selectedSurveyNumber,statusCode,this.currentPage,this.pageSize).subscribe(res =>{
        console.log(res);
        this.refreshDataTable(res);
    })
  }
  private refreshDataTable = (res: any) => {
    this.dataSource.data = res.data;
    this.totalSize = res.totalRecord;
    this.currentPage = (res.currentPage - 1);
    this.dataSource._updateChangeSubscription();
    if (!this.cdr['destroyed']) {
      this.cdr.detectChanges();
    }
  }
  getSurveyNumbers() {
    this.applicationService.fetchAllSurveyNumbers().subscribe(res=>{
      this.surveyNumbers = res;
     console.log(res);
    })
 }
 getSurveyNumbersByVillageId(villageId:any) {
  this.applicationService.fetchSurveyNumberByVillageId(villageId).subscribe(res=>{
    this.surveyNumbers = res;
   console.log(res);
  })
}
  getCountForAwardRegularApplicants(statusCode?: any) {
    this.applicationService.fetchCountForAwardRegularApplicantsByStatus(statusCode).subscribe(res=>{
      this.statusCount = res;
    })
  }
  getCountForAwardRegularApplicantsByVillage(villageId?: any, status?: any, selectedSurveyNumberId?: any, awardSklNumber?: any) {
    this.applicationService.fetchCountForAwardRegularApplicantsByVillageId(villageId, status, selectedSurveyNumberId, awardSklNumber).subscribe(res=>{
      this.villageCount = res;
    })
  }

  onClearSurveyNumber() {
    this.selectedSurveyNumber = null;
    this.selectedVillageId = null;
    this.fetchAwardMasterActivityLogs(this.villageId, this.selectedStatus);
    this.getCountForAwardRegularApplicantsByVillage(this.villageId, this.selectedStatus, this.selectedSurveyNumber);
  }
  onSurveyNumberChange(surveyNumber: any) {
    this.selectedSurveyNumber  = surveyNumber;
    this.applicationService.fetchAwardMasterActivityLogs(this.villageId,this.awardSklNumber,this.selectedSurveyNumber,this.selectedStatus,this.currentPage,this.pageSize).subscribe(res => {
      this.refreshDataTable(res);
      console.log(res);
      
    })
    this.getCountForAwardRegularApplicantsByVillage(this.villageId, this.selectedStatus, this.selectedSurveyNumber);
  }
  fetchVillages(){
    this.applicantServiceApiCallObserver = this.applicationService.fetchAllVillages().subscribe(res=>{
      this.villages = res;
      this.villages.unshift({
        "id":"",
        "name":"All"
       });
      })
    }

    public getAwardRegularStatus=()=> {
      let statuses = [
        {
          "statusCode":"",
          "status":"All"
        },
        {
          "statusCode":"Yes",
          "status":"Regular"
        },
        {
          "statusCode":"No",
          "status":"Irregular"
        },
      ];
      this.statusList = statuses;
      this.selectedStatus = statuses[0].statusCode;
    }
    
    onVillageChange(selectedVillageId: any): void{
      this.selectedVillageId = selectedVillageId;
      this.show=false;
      this.fetchAwardMasterActivityLogs(this.selectedVillageId, this.selectedStatus);
      this.getSurveyNumbersByVillageId(this.selectedVillageId);
      this.getCountForAwardRegularApplicantsByVillage(this.selectedVillageId, this.selectedStatus);
    }

    onStatusSelected(selectedStatus: any){
      this.selectedStatus = selectedStatus;
      this.fetchAwardMasterActivityLogs(null,this.selectedStatus);
      this.getCountForAwardRegularApplicants(this.selectedStatus);
      this.getCountForAwardRegularApplicantsByVillage(this.selectedVillageId, this.selectedStatus);
    }
    public getApplicantPaginatorData(event: PageEvent) {
      this.currentPage = event.pageIndex + 1;
      this.pageSize = event.pageSize;
      this.fetchAwardMasterActivityLogs();
    }
  searchApplicant(){
    this.fetchAwardMasterActivityLogs(this.villageId);
  }
  
  public onOkClicked = () => {
    let data = {};
    data['statusOptionId'] = this.selectedStatusOption;
    data['confirmed'] = true;
    this.dialogRef.close(data);
  }
  onClear() {
    this.awardSklNumber = null;
    this.selectedVillageId = null;
    this.fetchAwardMasterActivityLogs(this.villageId);
  }
  clickBack(){
    window.history.back();

  }

 
}
