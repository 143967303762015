import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute } from '@angular/router';
import { ConfirmationModalComponent } from 'src/app/confirmation-modal/confirmation-modal.component';
import { ApplicationService } from 'src/services/application.service';

@Component({
  selector: 'app-award-analysis',
  templateUrl: './award-analysis.component.html',
  styleUrls: ['./award-analysis.component.scss']
})
export class AwardAnalysisComponent implements OnInit {

  @Input() data: any;

  @Input() isEditable : any;

  isAwardRegular: any;
 

  @Output() newApplicantCallback = new EventEmitter<any>();
  applicantId: any;
  options: any;
  violationOfSupremeCourtA: any;
  awardPassedForConstructionB: any;
  buildingConstructedAfterC: any;
  awardPassedInFavourKatheadarD: any;
  awardPassedInApplicationSubmittedE: any;
  awardPassedInApplicationNotSubmittedF: any;
  awardNotDiscussedMentionedReportG: any;
  violationOfLaqG: any;
  awardPassedGovtLandH: any;
  awardPassedKharabLandI: any;
  awardPassedGomalJ: any;
  awardPassedContravationK: any;
  awardPassedRevenueActL: any;
  awardPassedForRtcM: any;
  extent: any;
  comment: any;
  checkbox: boolean;

  
  constructor(
    private applicationService: ApplicationService,
    public dialog: MatDialog,
    private _snackBar: MatSnackBar,
    private route: ActivatedRoute,
  ) {
    this.checkbox = false;
    this.applicantId = this.route.snapshot.queryParams['application_id'];
   }

  ngOnInit(): void {
    this.fetchApplicantById(this.applicantId);
  }

  private fetchApplicantById = (applicantId: any) => {
    if(!applicantId) {
      return;
    }
    this.applicationService.fetchAwardMasterApplicantsById(applicantId).subscribe(res =>{
        this.data = res;
        console.log(res);
        this.setEditable(res);
        this.setValue(res);
        this.newApplicantCallback.emit(res);
    })
  }

  private setEditable = (data: any): void => {
    if(data['oneTimeEdit'] != null) {
      this.isEditable = data['oneTimeEdit'];
    }
  }

  validateAndConstructPayload = () => {
    if (!this.isAwardRegular) {
      throw new Error("Is the Award regular Field Required");
    }
    let payload = {};
    payload['isAwardRegular'] = this.isAwardRegular;
    if(this.isAwardRegular == "No") {
      payload['violationOfSupremeCourtA'] = this.violationOfSupremeCourtA;
    if(this.violationOfSupremeCourtA) {
      payload['awardPassedForConstructionB'] = this.awardPassedForConstructionB;
      payload['buildingConstructedAfterC'] = this.buildingConstructedAfterC;
      payload['awardPassedInFavourKatheadarD'] = this.awardPassedInFavourKatheadarD;
      payload['awardPassedInApplicationSubmittedE'] = this.awardPassedInApplicationSubmittedE;
      payload['awardPassedInApplicationNotSubmittedF'] = this.awardPassedInApplicationNotSubmittedF;
      payload['awardNotDiscussedMentionedReportG'] = this.awardNotDiscussedMentionedReportG;
    }
      payload['violationOfLaqG'] = this.violationOfLaqG;
      payload['awardPassedGovtLandH'] = this.awardPassedGovtLandH;
      payload['awardPassedKharabLandI'] = this.awardPassedKharabLandI;
      payload['awardPassedGomalJ'] = this.awardPassedGomalJ;
      payload['awardPassedContravationK'] = this.awardPassedContravationK;
      payload['awardPassedRevenueActL'] = this.awardPassedRevenueActL;
      payload['awardPassedForRtcM'] = this.awardPassedForRtcM;
    }
    return payload;
  }

  onSubmit = () => {
    this.openDialog();
  }

  openSnackbar(message: string, action?: string){
    if(!action) {
      action = "Dismiss";
    }
    this._snackBar.open(message, action);
  }

  openDialog = () => {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = false;
    dialogConfig.autoFocus = true;
    dialogConfig.height = '160px';
    dialogConfig.width = '450px';
    dialogConfig.data = "Are you sure want to submit this record ?"
    const dialogRef = this.dialog.open(ConfirmationModalComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(res => {
      if (res) {
        if (this.applicantId) {
          this.updateApplicantStructureDetailsApiCall(this.applicantId);
          return;
        }
        this.updateApplicantStructureDetailsApiCall(this.applicantId);
      }
    })
  }
  updateApplicantStructureDetailsApiCall = (applicantId: any) => {
    try {
      let payload = this.validateAndConstructPayload();
      this.applicationService.saveAwardAnalysisDetails(applicantId, payload).subscribe(res => {
        this.openSnackbar("Successfully Updated", "Dismiss");
        // this.updateUI(res);
      }, err => {
        console.log(err);
        this.openSnackbar(err?.message, "Dismiss");
      })
    } catch (e) {
      this.openSnackbar(e, "Dismiss");
     }
  }

  setValue(data: any): void{
    this.isAwardRegular = data.isAwardRegular;
    this.violationOfSupremeCourtA = data.violationOfSupremeCourtA;
    if(data.awardPassedForConstructionB == 'true') {
    this.awardPassedForConstructionB = data.awardPassedForConstructionB;
    }
    if(data.buildingConstructedAfterC == 'true') {
    this.buildingConstructedAfterC = data.buildingConstructedAfterC;
    }
    if(data.awardPassedInFavourKatheadarD == 'true') {
    this.awardPassedInFavourKatheadarD = data.awardPassedInFavourKatheadarD;
    }
    if(data.awardPassedInApplicationSubmittedE == 'true') {
    this.awardPassedInApplicationSubmittedE = data.awardPassedInApplicationSubmittedE;
    }
    if(data.awardPassedInApplicationNotSubmittedF == 'true') {    
    this.awardPassedInApplicationNotSubmittedF = data.awardPassedInApplicationNotSubmittedF
    }
    this.violationOfLaqG = data.violationOfLaqG;
    if(data.awardPassedGovtLandH == 'true') {
    this.awardPassedGovtLandH = data.awardPassedGovtLandH;
    }
    if(data.awardPassedKharabLandI == 'true') {
    this.awardPassedKharabLandI = data.awardPassedKharabLandI;
    }
    if(data.awardPassedGomalJ == 'true') {
    this.awardPassedGomalJ = data.awardPassedGomalJ;
    }
    if(data.awardPassedRevenueActL == 'true') {
    this.awardPassedRevenueActL = data.awardPassedRevenueActL;
    }
    if(data.awardPassedForRtcM == 'true') {
      this.awardPassedForRtcM = data.awardPassedForRtcM;
    }
    if(data.awardPassedForRtcM == 'true') {
    this.awardPassedContravationK = data.awardPassedContravationK;
    }
    if(data.awardNotDiscussedMentionedReportG == 'true'){
      this.awardNotDiscussedMentionedReportG = data.awardNotDiscussedMentionedReportG;
    }
  }

  onCheckboxChange(violationOfSupremeCourtA: any) {
    if (!violationOfSupremeCourtA) {
      this.violationOfSupremeCourtA = null;
      this.awardPassedForConstructionB =  null;
      this.buildingConstructedAfterC =  null;
      this.awardPassedInFavourKatheadarD =  null;
      this.awardPassedInApplicationSubmittedE =  null;
      this.awardPassedInApplicationNotSubmittedF = null;
    }
  }
  onViolationCheckboxChange(violationOfLaqG: any) {
    if (!violationOfLaqG) {
      this.violationOfLaqG = null;
      this.awardPassedGovtLandH = null;
      this.awardPassedKharabLandI = null;
      this.awardPassedGomalJ = null;
      this.awardPassedContravationK =  null;
      this.awardPassedRevenueActL = null;
      this.awardPassedForRtcM = null;
    }
  }

}