<div class="container text">
  <div>
    <app-top-bar selectedTab="home"></app-top-bar>
    <app-header></app-header>
  </div>
  <div>
    <button (click)="clickBack()" class="back-link" style="margin-top: 12px;">Back</button>
  </div>
  <div>
    <div class="container mt-3">
      <div class="row d-flex justify-content-between">
        <div class="col-sm-8">
          <div class="row">
            <div class="col-sm-6 offset-sm-6">
              <h1>Transaction History</h1>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-6 text-center text">
          <p><span style="font-weight: 500;">Village :</span> {{villageName}}</p>
        </div>
        <div class="col-sm-6 text-center">
          <p><span style="font-weight: 500;">SLAO :</span> {{slaoName}}</p>
        </div>
        <div class="col-sm-6 text-center">           
              <p><span style="font-weight: 500;">Sy. No. :</span> {{surveyNo ? surveyNo : ''}}</p>
        </div>
      </div>
      <div *ngFor="let historyData of historyDataList">
      <div class="row  mt-4">
        <div class="col-sm-6 ">
          <p><span style="font-weight: 500;">Reconciliation Type :</span>  {{historyData.reconciliationType}}</p>
        </div>
        <div class="col-sm-6 text-center">
          <p>Transaction Date : {{historyData.updatedAt}}</p>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-10" *ngIf="historyData.reconciliationCode == 'land_notified_as_per_fn'">
          <p>Updated Value (in acres) : {{historyData.dataCollection.land_notified_data.total_extend_in_acres}}</p>
        </div>
      </div>
      <div class="row" *ngIf="historyData.reconciliationCode == 'awards_approved'">
        <div class="col-sm-7">
          <p>
            Awarded Approved:<span [ngClass]="{'green-text': historyData.lfReconciliationTypeSurveyData.awardsApprovedData.isAwardApproved === 'Yes',
           'red-text': historyData.lfReconciliationTypeSurveyData.awardsApprovedData.isAwardApproved !== 'Yes'}">{{historyData.lfReconciliationTypeSurveyData.awardsApprovedData.isAwardApproved}}</span> 
          </p>
        </div>
                  
      </div>
      <div class="row" *ngIf="historyData.reconciliationCode == 'awards_approved' && historyData.lfReconciliationTypeSurveyData.awardsApprovedData.isAwardApproved== 'Yes'">
        <div class="col-sm-7 ">
          <p>Total Cash Earmarked (in Rs.): {{historyData.lfReconciliationTypeSurveyData.awardsApprovedData.totalCashEarMarkedStr}}</p>
        </div>
      </div>
      <div class="row" *ngIf="historyData.reconciliationCode == 'awards_approved' && historyData.dataCollection.award_approved_data.award_approved == 'Yes'">
        <div class="col-sm-7 ">
          <p> Total extent of Developed Site earmarked (in Sq.Ft.) : {{historyData.lfReconciliationTypeSurveyData.awardsApprovedData.developedSiteEarmarkedStr}}</p>
        </div>
      </div>
      <div class="row" *ngIf="historyData.reconciliationCode == 'awards_approved' && historyData.dataCollection.award_approved_data.award_approved == 'Yes'">
        <div class="col-sm-7 ">
          <p>Area Awarded :{{historyData.lfReconciliationTypeSurveyData.awardsApprovedData.areaAwardedExtentInAcres}}</p>
        </div>
      </div>   
         <div class="row" *ngIf="historyData.reconciliationCode == 'awards_approved' && historyData.dataCollection.award_approved_data.award_approved == 'Yes'">
        <div class="col-sm-7 ">
          <p>Area Exempted by Sc : {{historyData.lfReconciliationTypeSurveyData.awardsApprovedData.scExemptedExtentInAcres}}</p>
        </div>
      </div>  
          <div class="row" *ngIf="historyData.reconciliationCode == 'awards_approved' && historyData.dataCollection.award_approved_data.award_approved == 'Yes'">
        <div class="col-sm-7 ">
          <p>Area Under BDA Approved Layout : {{historyData.lfReconciliationTypeSurveyData.awardsApprovedData.approvedExtentInAcres}}</p>
        </div>
      </div>
      <div *ngIf="historyData.reconciliationCode=='cases_compensation_paid_in_cash' || historyData.reconciliationCode=='ec_issued'" class="row">
        <div class="col-sm-8">
          <p *ngIf="historyData.reconciliationCode=='cases_compensation_paid_in_cash'">No. of Cases : {{historyData.dataCollection.cash_compensation_paid_in_cash ? historyData.dataCollection.cash_compensation_paid_in_cash.delta : ''}}</p>
          <p *ngIf="historyData.reconciliationCode=='cases_compensation_paid_in_cash'">Total Extent paid in Cash : {{historyData.totalExtentInAcres ? historyData.totalExtentInAcres : ''}}</p>
          <p *ngIf="historyData.reconciliationCode=='cases_compensation_paid_in_cash'">Total Cash Dispensed :​  {{historyData.lfReconciliationTypeSurveyData.casesCompensationData ? historyData.lfReconciliationTypeSurveyData.casesCompensationData.cashDispensedStr : ''}}</p>
          <p *ngIf="historyData.reconciliationCode=='cases_compensation_paid_in_cash'">Comments : {{historyData.dataCollection.cash_compensation_paid_in_cash ? historyData.dataCollection.cash_compensation_paid_in_cash.comments : ''}}</p>

          <p *ngIf="historyData.reconciliationCode=='ec_issued'">No. of EC Issued : {{historyData.dataCollection.ec_issued_data ? historyData.dataCollection.ec_issued_data.delta : ''}}</p>
          <p *ngIf="historyData.reconciliationCode=='ec_issued'">Total Awarded Extent for which EC issued ( in Acres) : {{historyData.totalExtentInAcres ? historyData.totalExtentInAcres : ''}}</p>

          <p *ngIf="historyData.reconciliationCode=='ec_issued'">Total Allotted Developed Site ( in Sq. Ft) - Eligible : {{historyData.lfReconciliationTypeSurveyData.ecIssuedData ? historyData.lfReconciliationTypeSurveyData.ecIssuedData.eligibleAllottedSiteStr : ''}}</p>
          <p *ngIf="historyData.reconciliationCode=='ec_issued'">Total Allotted Developed Site ( in Sq. Ft) - Entitled : {{historyData.lfReconciliationTypeSurveyData.ecIssuedData ? historyData.lfReconciliationTypeSurveyData.ecIssuedData.entitledAllottedSiteStr : ''}}</p>
          <p *ngIf="historyData.reconciliationCode=='ec_issued'">Comments : {{historyData.dataCollection.ec_issued_data ? historyData.dataCollection.ec_issued_data.comments : ''}}</p>


        </div>
        <!-- *ngIf="historyData.reconciliationCode=='cases_compensation_paid_in_cash' || 'ec_issued'" -->
      </div>
      <div class="w-100 mt-2" style="background-color: black; height: 2px;"></div>
    </div>
    <app-footer></app-footer>