
<div class="content-area">
    <div class="col-sm-10 offset-sm-1">
        <app-top-bar selectedTab="home"></app-top-bar>
        <app-header></app-header>
    </div>
    <!-- <div class="col-sm-10 offset-sm-1">
        <div class="col-sm-1 offset-sm-1">
            <button class="btn btn-primary refresh-btn" (click)="refreshClick()">
                <fa-icon [icon]="faSync" class="close-icon" [spin]="isRefreshing"></fa-icon>
            </button>
        </div>
    </div>
    <br>
    <div class="tool-bar" >
        <div class="col-sm-9 offset-sm-1">
            <div class="filter-layout" >
                <div  *ngFor ="let dateFilter of dateFilters;let i=index;" >
                    <button class="button trends-button " 
                    [matTooltip]="matTooltipContent"
                    matTooltipClass="allow-cr"
                    (click)="clickDateFilter(dateFilter.tag)"
                    [ngStyle]="{'background-color': dateFilter.color}" >
                    {{dateFilter.label}}
                    <br>
                    {{dateFilter.subtitle}}</button>
                </div>
            </div> 
        </div>
    </div>  -->
    <div class="col-sm-12"> 
        <div class="container">
            <div class="row">
              <div class="col-sm-1">
                <button class="btn btn-primary refresh-btn" (click)="refreshClick()">
                    <fa-icon [icon]="faSync" class="close-icon" [spin]="isRefreshing"></fa-icon>
                </button>
               </div>

               <div class="col-sm-2 ">
                <button class="btn btn-primary back-btn" (click)="back()">
                    Back
                </button>
               </div>

               <div class="col-sm-7">
                <div class="filter-layout" >
                    <div  *ngFor ="let dateFilter of dateFilters;let i=index;" >
                     
                            <button class="btn btn-primary content-btn" 
                            [matTooltip]="matTooltipContent"
                            matTooltipClass="allow-cr"
                            (click)="clickReportFilter(dateFilter.tag)"
                            [ngStyle]="{'background-color': dateFilter.color, 'width':dateFilter.width,
                                'margin-bottom':dateFilter.margin-bottom}">
                            {{dateFilter.label}} ({{dateFilter.count}})
                            <br>
                            {{dateFilter.subtitle}}</button>
                        
                    </div>
                </div> 
               </div>
               <div class="col-sm-2 rightalign">
                <div class=" user-details">
                    <div class="value-text">
                        Welcome
                    </div>
                    <div class="value-text">
                         {{userName}} !
                    </div>
                    </div>
              </div>
            </div>
            <div [hidden]="!showReadyForReportingComponent">
                <app-committe-ready-for-reporting></app-committe-ready-for-reporting>
            </div>
            <div [hidden]="!showReportGeneratedComponent">
                <app-committe-report-generated></app-committe-report-generated>
            </div>
            <div [hidden]="!showOutOfBoundComponent">
                <app-committe-out-of-bound></app-committe-out-of-bound>
            </div>
            <div [hidden]="!showReportedOutOfBoundsComponent">
                <app-committe-reported-out-of-bounds></app-committe-reported-out-of-bounds>
            </div>
        </div>
    </div>
    <app-footer class="col-md-10 offset-sm-3" ></app-footer>
</div> 