<div class="container">
    <div class="col-sm-12">
        <app-top-bar selectedTab="home"></app-top-bar>
    </div>
    <br>

    <div class="tool-bar">
        <div class="refresh-btn ml-5">
            <button mat-button class="btn btn-primary" (click)="clickBack()">Back</button>
        </div>

        <div class="offset-sm-2 title">
            SECON – DP – Activity Log
        </div>

        <div class="col-sm-3 offset-sm-1 user-details">
            <div class="value-text">
                Welcome <br>
                {{user['firstName']}} !
            </div>
        </div>
    </div>
    <div>
    </div>
    </div>
    <app-daily-progress-activitylog-datatable></app-daily-progress-activitylog-datatable>
    <app-footer></app-footer>