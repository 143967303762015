import { Component, Input, OnInit } from '@angular/core';
import { WebcamImage } from 'ngx-webcam';
import { Observable, Subject } from 'rxjs';
import { faTrash} from '@fortawesome/free-solid-svg-icons';
import { ApplicationService } from 'src/services/application.service';

@Component({
  selector: 'app-site-details',
  templateUrl: './site-details.component.html',
  styleUrls: ['./site-details.component.scss']
})
export class SiteDetailsComponent implements OnInit {

  @Input() data : any;

  @Input() isEditable : any;

public pageName: string = "site_details";

public siteNo : string;

public siteLength : number;

public siteBreadth : number;

public siteArea : number;

public address : string;

public siteNorth : string;

public siteEast : string;

public siteWest : string;

public siteSouth : string;

public khataType : string;

public khataNumber : string;

public khataDate : Date;

public maxDate = new Date().getTime();

public cameraChoosen = false;

public selectedFile;

public selectedFileName;

public profileImg : any ;

faTrash = faTrash;

  constructor(private applicationService: ApplicationService) { }

  ngOnInit(): void {
  }

  private trigger: Subject<void> = new Subject<void>(); 
  triggerSnapshot(): void { 
   this.trigger.next(); 
  } 

  uploadPropertyPicture(){
    if((!this.selectedFile && !this.selectedFileName) || (this.data && this.data.propertyImage)){
      return;
    }
    console.log('selected file ',this.selectedFile)
    const formData = new FormData();
    formData.append('property_pic', this.selectedFile, this.selectedFileName);
     this.applicationService.uploadPropertyPicture(this.data.id, formData).subscribe(res =>{
        this.data=res;
        this.profileImg = this.data.propertyImage;
     })
  }


  handleImage(webcamImage: WebcamImage): void { 
    this.cameraChoosen = false;
   this.profileImg = webcamImage.imageAsDataUrl
  //  this.selectedFile =  webcamImage.imageAsDataUrl;
 this.selectedFileName = 'image.jpg';
 const base64 = webcamImage.imageAsBase64;
const imageName = 'name.png';
const imageBlob = this.dataURItoBlob(base64);
this.selectedFile = new File([imageBlob], imageName, { type: 'image/png' });
  } 
  dataURItoBlob(dataURI) {
    const byteString = window.atob(dataURI);
    const arrayBuffer = new ArrayBuffer(byteString.length);
    const int8Array = new Uint8Array(arrayBuffer);
    for (let i = 0; i < byteString.length; i++) {
      int8Array[i] = byteString.charCodeAt(i);
    }
    const blob = new Blob([int8Array], { type: 'image/png' });    
    return blob;
 }
   
chooseCamera(){
  this.cameraChoosen = true;
}

removePicture(){
  this.profileImg = null;
  this.selectedFile = null;
  this.selectedFileName = null;
}

onGalleryImageChoosen($event){
 console.log('event ', $event.target.files[0])
 this.selectedFile =  $event.target.files[0];
 this.selectedFileName = this.selectedFile.name;
 
 let currentComponent = this;
 var reader = new FileReader();
 reader.onload = function (e) {
   console.log('file ',e.target.result);
   currentComponent.profileImg = e.target.result;
 };
 reader.readAsDataURL($event.target.files[0]);
}
fetchFromGallery(){
  document.getElementById("image-gallery").click();
}

  public get triggerObservable(): Observable<void> { 
   return this.trigger.asObservable(); 
  } 

  reset(){
    this.siteNo = null;
    this.siteLength = null;
    this.siteBreadth = null;
    this.siteArea = null;
    this.address = null;
    this.siteNorth = null;
    this.siteEast = null;
    this.siteWest = null;
    this.siteSouth = null;
    this.khataNumber = null;
    this.khataDate = null;
  }

  getPayload(){
    let payload = {};
    payload['siteLength'] = this.siteLength;
    payload['siteBreadth'] = this.siteBreadth;
    payload['siteArea'] = this.siteArea;
    payload['buildingAddress'] = this.address;
    payload['siteNorth'] = this.siteNorth;
    payload['siteEast'] = this.siteEast;
    payload['siteWest'] = this.siteWest;
    payload['siteSouth'] = this.siteSouth;
    payload['khataNumber'] = this.khataNumber;
    payload['khataDate'] = this.khataDate;
    payload['khataBbmp'] = this.khataType == 'bbmp' ? 'Y' : 'N';
    payload['khataGp'] = this.khataType == 'gramPanchayat' ? 'Y' : 'N';
    payload['khataBda'] = this.khataType == 'bda' ? 'Y' : 'N';
    return payload;
   }

   ngOnChanges(data): void{
    if(!this.data){
      return;
    }
    this.siteNo = this.data.surveyNumber;
    this.siteLength = this.data.siteLength ? this.data.siteLength.toFixed(2) : this.data.siteLength;
    this.siteBreadth = this.data.siteBreadth ? this.data.siteBreadth.toFixed(2) : this.data.siteBreadth;
    this.siteArea = this.data.siteArea ? this.data.siteArea.toFixed(2) : this.data.siteArea;
    this.address = this.data.buildingAddress;
    this.siteNorth = this.data.siteNorth;
    this.siteEast = this.data.siteEast;
    this.siteWest = this.data.siteWest;
    this.siteSouth = this.data.siteSouth;
    this.khataNumber = this.data.khataNumber;
    this.khataDate = this.data.khataDate;
    this.khataType = this.data.khataBbmp == 'Y' ? 'bbmp' : this.data.khataGp == 'Y' ?'gramPanchayat' : this.data.khataBda == 'Y' ? 'bda' : '';
    this.profileImg = this.data.propertyImage;
  }

   

}
