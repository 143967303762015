<div class="row content-area">
            <div class="col-sm-12 col-md-12">
                <div class="row application-form-row ">
                    <label class="form-label">Survey/Site No / ಸರ್ವೆ ಸಂಖ್ಯೆ / ನಿವೇಶನದ ಸಂಖ್ಯೆ. :</label>
                    <input type="text" [(ngModel)]="siteNumber" class="form-textbox" readonly/>
                </div>
            </div>

            <div class="col-sm-12 col-md-12">
                <div class="row application-form-row ">
                    <label class="form-label">Purpose/Use / ವಾಣಿಜ್ಯ ಕಟ್ಟಡವಾಗಿದ್ದರೆ ಉದ್ದೇಶ / ಬಳಕೆ -ಉದಾಹರಣೆ - ಅಂಗಡಿ, ಉಗ್ರಾಣ ಇತ್ಯಾದಿ. :</label>
                    <input type="text" [(ngModel)]="commPurpose" class="form-textbox"  [readonly] = "(data && data.commPurpose) && !isEditable"/>
                </div>
            </div>
            <div class="col-sm-12 col-md-12">
                <div class="row application-form-row ">
                    <label class="form-label">Built up area (in Sq.ft) / ಕಟ್ಟಡದ ವಿಸ್ತೀರ್ಣ :</label>
                    <input type="number" [(ngModel)]="commArea" step="any" class="form-textbox" [readonly] = "(data && data.commArea) && !isEditable"/>
                </div>
            </div>
            <div class="col-sm-12 col-md-12">
                <div class="row application-form-row ">
                    <label class="form-label">No.of Floors / ಮಹಡಿಗಳ ಸಂಖ್ಯೆ :</label>
                    <input type="number" [(ngModel)]="commFloors" class="form-textbox" [readonly] = "(data && data.commFloors) && !isEditable"/>
                </div>
            </div>
            <div class="col-sm-12 col-md-12">
                <div class="row application-form-row ">
                    <label class="form-label">Total area (in Sq.ft) / ಒಟ್ಟು ವಿಸ್ತೀರ್ಣ :</label>
                    <input type="number" [(ngModel)]="commTotalarea" step="any" class="form-textbox" [readonly] = "(data && data.commTotalarea) && !isEditable"/>
                </div>
            </div>
            <div class="col-sm-12 col-md-12">
                <div class="row application-form-row ">
                    <label class="form-label">Address of the property / ಆಸ್ತಿಯ ವಿಳಾಸ :</label>
                    <input type="text" [(ngModel)]="buildingAddress" class="form-textbox" placeholder="Address" readonly/>
                </div>
            </div>

</div>
        


