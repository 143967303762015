import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { PageEvent } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { ApplicationService } from 'src/services/application.service';
import { StrorageService } from 'src/services/strorage.service';

@Component({
  selector: 'app-dpa-activity-log-common',
  templateUrl: './dpa-activity-log-common.html',
  styleUrls: ['./dpa-activity-log-common.scss']
})
export class DpaActivityLogCommon implements OnInit {

  isRefreshing: boolean;
  user: any;
  selectedVillageId: number;
  applicationNo: any;
  selectedSurveyId: any;
  villageList : any [] = [];
  syNoList : any [] = [];
  totalSize: number;
  pageSize: number;
  currentPage: number;
  heading:any;
  public villageId: any = '';
  public surveyId: any = '';
  public showCivilWorkOptions: boolean;
  public roleCode:any;
  public displayedColumns: string[] = ['siNo', 'dapNo','village','syNo', 'function', 'mainActivity', 'work' ,'createdOn','contractorName', 'viewImageVideo'];
  public dataSource = new MatTableDataSource<any>();

  constructor(private applicationService: ApplicationService,private router:Router, private storageService:StrorageService) { }

  ngOnInit() {
    this.user=this.storageService.getUser();
    this.roleCode = this.user.roleCode;
    this.getVillageData();
  }

  refreshClick(){
  }

  getVillageData(){
    this.applicationService.getAdVillages().subscribe(res => {
      this.villageList = res;
      this.onVillageChange(this.villageId);
    })
  }

  onSurveyChange = (surveyId: any) => {
    this.surveyId = surveyId;
    this.getActivityLogs()
  }

  formattDate = (date:any) =>{
    let pipe = new DatePipe('en-IN');
    return pipe.transform(date, 'dd/MM/yyyy');
  }


  getActivityLogs(){
    if (this.villageId){
      this.selectedVillageId = this.villageId;
    }
    this.applicationService.getCommitteeDpaActivityLog(this.currentPage, this.pageSize , this.villageId, this.surveyId).subscribe(res => {
      if(res){
        this.refreshDataTable(res);
        console.log(res);
      }
    })
  }

  getAllVillageIdsCommaSeperated = (): String => {
    let result:String='';
    for(let i =0;i < this.villageList.length;i++) {
      result = (result == '') ? this.villageList[i].id : result + this.villageList[i].id;
      if(i != (this.villageList.length - 1)) {
        result = result+',';
      }
    }
    console.log(result);
    return result;
  }

  onVillageChange = (p_villageId: any) => {
    if(!p_villageId) {
      p_villageId = this.getAllVillageIdsCommaSeperated();
    }
    this.applicationService.getAdSurveyByVillage(p_villageId).subscribe(res => {
      console.log("rses", res);
      this.syNoList = [];
      let syNo:any = {};
      syNo.id='';
      syNo.surveyNo = 'All';
      this.syNoList.push(syNo);
      for(let i =0;i < res.length;i++) {
        this.syNoList.push(res[i]);
      }
      this.getActivityLogs();
    })
  }

  private refreshDataTable = (res: any) => {
    this.totalSize = res.totalRecord;
    this.dataSource.data = res.data;
    this.dataSource._updateChangeSubscription();
  }

  onClear() {
    this.applicationNo = null;  
  }

  clickBack(){
    window.history.back();
  }

  public getApplicantPaginatorData(event: PageEvent) {
    this.currentPage = event.pageIndex;
    this.pageSize = event.pageSize;
    this.getActivityLogs();
  }

  
  public prepActivity = () => {
    this.showCivilWorkOptions = !this.showCivilWorkOptions;
  }

  public civilWork = () => {
    if(this.user.roleCode == 'comittee_user'){
      this.heading = 'Committee Dashboard';
    }if(this.user.roleCode == 'secon_user'){
      this.heading = 'SECON Dashboard';
    }
    this.router.navigate(['/dpa-work-progress-datatable'], { queryParams: { heading: this.heading }})
  }
}
