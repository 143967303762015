import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { StrorageService } from 'src/services/strorage.service';
import { ApiService } from 'src/services/api.service';
import { faSync } from '@fortawesome/free-solid-svg-icons';
import { ApplicationService } from 'src/services/application.service';

@Component({
  selector: 'app-status-option-tiles',
  templateUrl: './status-option-tiles.component.html',
  styleUrls: ['./status-option-tiles.component.scss']
})
export class StatusOptionTilesComponent implements OnInit {

  public isRefreshing = false;
  public faSync = faSync;
  public selectedDateFilter: any;
  villages : any[];
  public selectVillage;
  public applicantCount : any;
  public selectedId : any;
  public userName = "Username";
  @Input('statusCode') public statusCode: any;
  user:any;
  applicationData;
  public statusOptions: any = [];
  @Output() onTilesClick = new EventEmitter();
  @Output() onApiLoaded = new EventEmitter();
  public showReportGeneratedComponent: boolean = false;

  constructor(private strorageService:StrorageService, private apiService: ApiService,private applicationService: ApplicationService) { }

  ngOnInit(): void {
    
  }

  fetchApplicantByStatusOption() {
    this.applicationService.fetchApplicantCountByStatusOption(this.statusCode).subscribe(res => {
      console.log(res);
      let statusOptionId = this.strorageService.getItemFromLocalStorage('selected_status_option');
      var data: any;
      for(let i =0;i < res.length;i++) {
        if(statusOptionId) {
          if(res[i].id == parseInt(statusOptionId)) {
            res[i].active = true;
            data = res[i];
          } else {
            res[i].active = false;
          }
        } else {
          res[i].active = (i == 0) ? true : false;
        }
      }
      this.statusOptions = res;
      if(data) {
        this.onApiLoaded.emit(data);
      } else {
        this.onApiLoaded.emit(res[0]);
      }
    })
  }

  onTilesClicked($event: any, data: any): void {
    this.setActive(data);
    this.onTilesClick.emit(data);
  }

  private setActive(data: any) {
    for(let i =0;i < this.statusOptions.length;i++) {
      this.statusOptions[i].active = (this.statusOptions[i].id == data.id) ? true : false;
    }
  }
}
