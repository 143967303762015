import { Component, OnInit} from '@angular/core';
import { ApplicationService } from 'src/services/application.service';
import { AuthService } from 'src/services/auth.service';
import { StrorageService } from 'src/services/strorage.service';
import { JccnoEncodeDecodeService } from 'src/services/jccno-encode-decode/jccno-encode-decode.service';
import { ActivatedRoute, Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { RoleConfigService } from 'src/app/shared/config/role/role.config.service';
import { Location } from '@angular/common';


@Component({
  selector: 'app-award-scrutiny-user-workbench',
  templateUrl: './award-scrutiny-user-workbench.component.html',
  styleUrls: ['./award-scrutiny-user-workbench.component.scss']
})
export class AwardScrutinyUserWorkbenchComponent implements OnInit {
  application_id: any;
  public menus: any = [
    {
      "name": "Basic Info",
      "code": "basic_info",
      "active": true,
      "default": true,
      "showMatCard": true,
      "class":'col-sm-1',
    },
    {
      "name": "Award Details",
      "code": "award_details",
      "active": false,
      "default": false,
      "class":'col-sm-1`',
      "showMatCard": true
    },
    {
      "name": "Structure Details",
      "code": "structure_details",
      "active": false,
      "default": false,
      "class":'col-sm-1',
      "showMatCard": true
    },
    {
      "name": "Award Analysis",
      "code": "award_analysis",
      "active": false,
      "default": false,
      "class":'col-sm-1',
      "showMatCard": true
    },
    {
      "name": "Enclosures",
      "code": "enclosures",
      "showMatCard": false,
      "active": false,
      "class":'col-sm-1',
      "default": false
    },
    {
      "name": "Preview & Submission",
      "code": "preview",
      "showMatCard": false,
      "active": false,
      "class":'col-sm-1',
      "default": false
    },
];

  public id;
  public applicationNo;
  public applicantName;
  public survey_no;
  public award_no;
  // public status;
  public selectedPage = 1;
  public pageOneSelected = 1;
  public pageTwoSelected = 2;
  public pageThreeSelected = 3;
  public pageFourSelected = 4;
  public pageFiveSelected = 5;
  public pageName:string = 'personal';
  public isLoading:boolean = false;
  public isEditable = false;

  public selectedSubForm = 1;
  public selectedHeader;

  public applicationData;
  public award_noOption:any;
  public survey_noOption:any;

  public showAcknowledgementForm: boolean = false;
  
  public showEnclsoureForm: boolean = false;

  public user: any;

  public selectedMenu: any;

  selectedVillage: any;

  villageName: any;

  applicantId: any;

  data: any;

  awardSklNumber: any;

  surveyNumber: any;

  awardNumber: any;

  public showAwardDetails: boolean = false;

  public showStructureDetails: boolean = false;

  public showEnclosureDetails: boolean = false;

  public showPreviewDetails: boolean = false;

  public showAwardAnalysisDetails: boolean = false;


  constructor(private applicationService: ApplicationService, private authService: AuthService,
    private router: Router, private route: ActivatedRoute, private jccEncodeDecoder: JccnoEncodeDecodeService,
    private _snackBar: MatSnackBar, public dialog: MatDialog, private storageService: StrorageService,
    private roleConfig: RoleConfigService ,private _location: Location) {
      this.user = storageService.getUser();
      this.villageName = this.route.snapshot.queryParams['village_name'];
      this.applicantId = this.route.snapshot.queryParams['application_id'];
    }

  ngOnInit(): void {
    this.selectedMenu = this.menus[0];
    this.fetchApplicantById(this.applicantId)
  }

  private fetchApplicantById = (applicantId: any, successCallback?: any) => {
    if(!applicantId) {
      return;
    }
    this.applicationService.fetchAwardMasterApplicantsById(applicantId).subscribe(res =>{
        this.data = res;
        this.awardSklNumber = res.awardSklNumber;
        this.surveyNumber = res.surveyNumber;
        this.awardNumber = res.awardNumber;
        if(successCallback) {
          successCallback();
        }
        console.log(res);
    })
  }
  public updateUI = (data: any): void => {
      this.applicationData = data;
      console.log("current"+data)
      this.id = data.id;
      this.applicantName = data.applicantName;
      this.applicationNo = data.applicationNo;
      if(data.activeStatus) {
        this.survey_no = data.activeStatus.statusVO.status;
        if(data.activeStatus.statusOptionVos.length > 0) {
          this.survey_no = data.activeStatus.statusOptionVos[0].name;
        }
      }
  }

  ngAfterViewInit(){
    window.scroll(0,0);
  }

  openSelectedMenu = (menu: any) => {
    this.fetchApplicantById(this.applicantId, () => {
      this.highlightSelectedMenu(menu);
    });
  }

  highlightSelectedMenu = (menu: any) => {
    for(let i =0;i < this.menus.length;i++) {
      if(menu['code'] == this.menus[i]['code']) {
        this.menus[i]['active'] = true;
      } else {
        this.menus[i]['active'] = false;
      }
    }
    this.selectedMenu = menu;
    if(this.selectedMenu.code == "award_details" && this.data.landRtcTotalAreaValue || this.data.landRtcTotalAreaValue == 0) {
      this.showAwardDetails = true;
    }
    if(this.selectedMenu.code == "structure_details" && this.data.govLandAwardPassed) {
      this.showStructureDetails = true;
    }
    if(this.selectedMenu.code == "award_analysis" && this.data.handOverToDepartment ) {
      this.showAwardAnalysisDetails = true;
    }
    if(this.selectedMenu.code == "enclosures" && this.data.isAwardRegular) {
      this.showEnclosureDetails = true;
    }
    if(this.selectedMenu.code == "preview" && this.data.originalDocumentName) {
      this.showPreviewDetails = true;
    }
    
  }

  openSnackbar(message: string, action?: string){
    if(!action) {
      action = "Dismiss";
    }
    this._snackBar.open(message, action);
  }

  public nextScreenCallback = (menu_code: any): void => {
    let selectedMenu: any;
    for(let i = 0;i < this.menus.length;i++) {
      if(menu_code == this.menus[i]['code']) {
        this.selectedMenu = this.menus[i];
      }
    }
    if(!selectedMenu) {
      return;
    }
    this.openSelectedMenu(selectedMenu);
  }

  encloPage() {
    let fullURL = location.protocol+'//'+location.hostname+(location.port ? ':'+location.port: '');
    window.open(fullURL+'#/preview-enclosure?application_id='+this.application_id, '_blank');
  }

  public oneTimeEditCallback = ($event: any): void => {
    this.openSelectedMenu(this.menus[0]);
  }

  onBack(): void {
        this._location.back();
    }

}