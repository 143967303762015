<div class="container">
  <div>
    <app-top-bar selectedTab="home"></app-top-bar>
    <app-header></app-header>
</div>
<br>
<div class="d-flex justify-content-between">
  <div class="refresh-btn">
    <button mat-button class="btn btn-primary" (click)="clickBack()">Back</button>
</div>
<div class="col-sm title" >
  Layout Formation – EE Module – EE Observations
  <br> <br>Preview
</div>
  <div class="col-sm-2 offset-sm-1 user-details">
    <div class="value-text">
      Welcome <br>
      {{user['firstName']}} !
    </div>
  </div>
</div>
  <div class="row ">
    <div class="col text-center mt-5">
      <div class="row mt-4">
        <div class="col-6 text-start value-text">
          <p style="margin-right: 60%;"><strong>Sector:</strong> {{sectorName}}</p>
        </div>
        <div class="col-6 text-end value-text">
          <p><strong>Concern Source:</strong> {{concernSourceName}}</p>
        </div>
      </div>
    </div>
  </div>
  <div class="col-sm-12 d-flex">
    <div class="col-sm-3">
      <h1 class="value-text" style="margin-left: -59px;">Comments :</h1>
    </div>
    <textarea class="form-control" style="height: 12rem !important;" [(ngModel)]="comments" rows="4"
       name="comment" readonly></textarea>
  </div>
  <div *ngFor="let imageUrl of imageUrls; let i = index" class="click-pc">
    <a target="_blank" [href]="imageUrl" class="preview-link">Click to view Pic</a>

    <!-- <a  target="_blank" href="{{imageUrl}}" class="preview-link" class="preview-link">Click to view Pic</a> -->
  </div>
  <app-footer></app-footer>
</div>
