import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { WorkbenchCommentDialogComponent } from 'src/app/field-visit-user/field-visit-workbench/workbench-comment-dialog/workbench-comment-dialog.component';
import { ConfirmDialogService } from 'src/app/shared/confirm-dialog/confirm-dialog.service';
import { ApplicantHelperService } from 'src/services/applicant-helper/applicant-helper.service';
import { ApplicationService } from 'src/services/application.service';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { AdjudicationOutOfBoundsDialogboxComponent } from '../adjudication-user/adjudication-out-of-bounds-dialogbox/adjudication-out-of-bounds-dialogbox.component';

@Component({
  selector: 'app-applicant-workbench',
  templateUrl: './applicant-workbench.component.html',
  styleUrls: ['./applicant-workbench.component.scss']
})
export class ApplicantWorkbenchComponent implements OnInit {
  application_id: any;
  applicationData;
  selectedPanchayat = '';
  message:string;
  comment:string;
  status:string;
  public faTimes = faTimes;
  public isLoading=false;
  selectedVillages: any;
  public commentTypes: any = [];
  public fielVisitCommentTypes:any =[];
  public statusList: any = [];
    public title: any;
    approved_filter: any;
    public disable:boolean=true;
  createdAt: any;
  date: any;
  fieldVisitStatus: any;
  selectedLandType: any=[];
  dialogRef: MatDialogRef<AdjudicationOutOfBoundsDialogboxComponent>;

  constructor(private applicationService: ApplicationService,private route: ActivatedRoute, 
    private _snackBar: MatSnackBar, private router: Router,
    private workbenchDialogService: WorkbenchCommentDialogComponent,
    private dialog: MatDialog,
    private confirmDialogService: ConfirmDialogService, private applicantHelperService: ApplicantHelperService) {
      this.application_id = this.route.snapshot.queryParams['application_id'];
     }

  ngOnInit(): void {
    this._fetchDataByApplicationNo();
  }
  private _fetchDataByApplicationNo() {
    this.applicationService.fetchApplicantById(this.application_id).subscribe(res=>{
      console.log("app",res)
      this.applicationData = res;
      this.title = this.applicantHelperService.getTitle(res);
      console.log("title", this.title);
      this.fetchAllPanchayats();
      this.fetchAdjudicationStatusList();
      if(this.applicationData.activeStatus) {
        this.selectedLandType=this.applicationData.activeStatus.landTypeVOList;
      }
      this.fetchAllCommentTypes();
      this.fetchAllFieldVisitCommentTypes();
     })
     
}
close() {
  this.selectedVillages=this.route.snapshot.queryParams['village'];
  this.router.navigate(['/adjudication-pre-dashboard']);
   
}
cancelClick(){
  this.close();
}

private fetchAllPanchayats(){
  this.applicationService.fetchAllPanchayats().subscribe(res=>{
    if(this.applicationData &&  this.applicationData.panchayat && res){
      this.selectedPanchayat = res[this.applicationData.panchayat-1]?.name;
    }
  })
}
private fetchAllCommentTypes(){
  this.applicationService.getAllLandTypes().subscribe(res=>{
    this.commentTypes = res.data;
    if(!this.applicationData.scrutinyStatus) {
      return;
    }
    let selectedCommentTypes = this.applicationData.scrutinyStatus.landTypeVOList;
    for(let i = 0;i < this.commentTypes.length;i++) {
      let isChecked = false;
      for(let j = 0;j < selectedCommentTypes.length;j++) {
          if(this.commentTypes[i]['id'] == selectedCommentTypes[j]['id']) {
              isChecked = true;
              break;
          }
      }
      this.commentTypes[i].checked = isChecked;
    }
  })
}
private fetchAllFieldVisitCommentTypes(){
  this.applicationService.getAllLandTypes().subscribe(res=>{
    this.fielVisitCommentTypes = res.data;
    if(this.applicationData.fieldVisitStatus){
    let selectedCommentTypes = this.applicationData.fieldVisitStatus.landTypeVOList;
    for(let i = 0;i < this.fielVisitCommentTypes.length;i++) {
      let isChecked = false;
      for(let j = 0;j < selectedCommentTypes.length;j++) {
          if(this.fielVisitCommentTypes[i]['id'] == selectedCommentTypes[j]['id']) {
              isChecked = true;
              break;
          }
      }
      this.fielVisitCommentTypes[i].checked = isChecked;
    }
  }
  })

}

private fetchAdjudicationStatusList(){
  this.applicationService.fetchAdjudicationStatus().subscribe(res=> {
      for(let i = 0;i < res.length;i++) {
        res[i].checked = false;
        this.statusList.push(res[i]);
        console.log("status",this.statusList);
    }
  })
}

encloPage() {
  let fullURL = location.protocol+'//'+location.hostname+(location.port ? ':'+location.port: '');
  window.open(fullURL+'#/preview-enclosure?application_id='+this.application_id, '_blank');
}

clickBack(){
  window.history.back();
}

updateAdjudicationStatus= (status: string) => {
  let payload = {};
    payload['status_code'] = status;
    payload['comment'] = this.comment;
    let options = {};
      if(status == 'marked_for_personal_interview') {
        options['message'] = 'Application Marked For Interview.';
      } else if(status == 'ready_for_reporting'){
        options['message'] = 'Application is ready for reporting';
      }
      options['title'] = 'Confirm',
      options['cancelText'] = 'CANCEL',
      options['confirmText'] = 'OK'

      this.confirmDialogService.openDialog(options);
      this.confirmDialogService.confirmed().subscribe(confirmed => {
        if(confirmed) {
          this.applicationService.updateApplicantStatus(this.application_id,payload).subscribe(res => {
            this.clickBack();
          })
        }
      });
  }

  

  updateOutOfBoundsStatus= () => {
    this.dialogRef = this.dialog.open(AdjudicationOutOfBoundsDialogboxComponent, {
      data: {
          title: "Confirm",
          message: "Application is out of bound",
          cancelText: "CANCEL",
          confirmText: "OK"
      },
      maxHeight: "100%",
      width: "540px",
      maxWidth: "100%",
      disableClose: true,
      hasBackdrop: true
    });
    this.dialogRef.afterClosed().subscribe(data => {
      let confirmed = data.confirmed
      if(confirmed) {
        let payload = {};
        payload['status_code'] = "out_of_bounds";
        payload['statusOptionId'] = data.statusOptionId;
        let options = {};
        options['message'] = 'Application Is Out Of Bounds.';
        options['title'] = 'Confirm',
        options['cancelText'] = 'CANCEL',
        options['confirmText'] = 'OK'
  
        this.confirmDialogService.openDialog(options);
        this.confirmDialogService.confirmed().subscribe(confirmed => {
          if(confirmed) {
          this.applicationService.updateApplicantStatus(this.application_id,payload).subscribe(res => {
            this.clickBack();
          })
        }
        })
      }
      });
}

checkValue($event:any){
     this.message
}
}
