import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class AdjudicationUserTrackActivityService {

  private lastPreDashboardActivity: any;

  private lastDashboardActivity: any;

  private lastSelectedRecommendedBy: any;

  constructor() { }

  public setLastPreDashboardActivity = (villageId: any, selectedDateFilter: any) => {
    this.lastPreDashboardActivity = {};
    this.lastPreDashboardActivity.villageId = villageId;
    this.lastPreDashboardActivity.selectedDateFilter = selectedDateFilter;
  }

  public saveLastPreDashboardActivity = (lastPreDashboardActivity: any) => {
    this.lastPreDashboardActivity = lastPreDashboardActivity;
  }

  public getLastPreDashboardActivity = () => {
    return this.lastPreDashboardActivity;
  }

  public setLastDashboardActivity = (villageId: any) => {
    this.lastDashboardActivity = {};
    this.lastDashboardActivity.villageId = villageId;
  }

  public getLastDashboardActivity = () => {
    return this.lastDashboardActivity;
  }

  public setSelectedRecommendedBy = (recommendedBy: any) => {
    this.lastSelectedRecommendedBy = recommendedBy;
  }

  public getSelectedRecommendedBy = () => {
    return this.lastSelectedRecommendedBy;
  }
}
