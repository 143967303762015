import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { PageEvent } from '@angular/material/paginator';
import { faSync } from '@fortawesome/free-solid-svg-icons';

import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { ApplicationService } from 'src/services/application.service';
import { StrorageService } from 'src/services/strorage.service';
import { MAT_DIALOG_DATA,MatDialogRef,MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-award-master-dashboard',
  templateUrl: './award-master-dashboard.component.html',
  styleUrls: ['./award-master-dashboard.component.scss']
})
export class AwardMasterDashboardComponent implements OnInit {
  public pageSize =5;
  public currentPage = 0;
  public totalSize: number= 0;
  public faSync = faSync;
  public isRefreshing = false;
  public displayedColumns: string[] = ['sn','awardSklNumber','surveyNumber', 'awardNumber','documentName'];
  
  public dataSource = new MatTableDataSource<any>();
  selectedDateFilter:any;
  user: any;
  village: any;
  villages : any[];
  villageId: any;
  applicationNo = null;
  selectedVillage: number;
  public totalApplicantCount: any = 0;
  show: boolean;
  applicantServiceApiCallObserver: any;
  village_id: any;
  villageName: any;
  surveyNumber: any;
  awardSklNumber = null;
  surveyNumbers: any;
  selectedSurveyNumber: any;
  // isRefreshing: boolean;

  constructor(private applicationService: ApplicationService,
    private cdr:ChangeDetectorRef, 
    private router: Router,private storageService:StrorageService,private route: ActivatedRoute, ) { 
      this.villageId = this.route.snapshot.queryParams['village_id'];
      this.villageName = this.route.snapshot.queryParams['village_name'];
    }

    ngOnInit(): void {
      this.user=this.storageService.getUser();
      this.fetchAwardMasterApplicants();
      this.getSurveyNumbersByVillageId(this.villageId);  
    }  
    getSurveyNumbersByVillageId(villageId) {
      this.applicationService.fetchSurveyNumberByVillageId(villageId).subscribe(res=>{
        this.surveyNumbers = res;
       console.log(res);
      })
   }
    public getApplicantPaginatorData(event: PageEvent) {
      this.currentPage = event.pageIndex + 1;
      this.pageSize = event.pageSize;
      this.fetchAwardMasterApplicants();
    }
    onClear() {
      this.awardSklNumber = null;
      this.fetchAwardMasterApplicants();
    }
    onClearSurveyNumber() {
      this.selectedSurveyNumber = null;
      this.fetchAwardMasterApplicants();
    }
    
    public showActivityLog = () => {
      this.router.navigate(['/award-scrutiny-user-activity-log'], {queryParams:{village_name:this.villageName, village_id:this.villageId}});
    }
  
    public fetchAwardMasterApplicants = () => {
      this.applicationService.getAwardMasterApplicants(this.villageId,this.awardSklNumber,this.surveyNumber,this.currentPage,this.pageSize).subscribe(res => {
        this.refreshDataTable(res);
        console.log(res);
        
      })
    }
    
    searchApplicant(){
      this.fetchAwardMasterApplicants();
    }

    onSurveyNumberChange(surveyNumber: any) {
      this.selectedSurveyNumber  = surveyNumber;
      this.applicationService.getAwardMasterApplicants(this.villageId,this.awardSklNumber,this.selectedSurveyNumber,this.currentPage,this.pageSize).subscribe(res => {
        this.refreshDataTable(res);
        console.log(res);
        
      })
    }
  
    private refreshDataTable = (res: any) => {
      this.dataSource.data = res.data;
      this.totalSize = res.totalRecord;
      this.currentPage = (res.currentPage - 1);
      this.dataSource._updateChangeSubscription();
      if (!this.cdr['destroyed']) {
        this.cdr.detectChanges();
      }
    }
  
    clickBack(){
      window.history.back();
  
    }
    onPreviewClick(id: any){
      this.router.navigate(['/award-scrutiny-user-workbench'], {queryParams:{application_id:id, village_name:this.villageName, village_id:this.villageId}});
      console.log(id)
     }

     refreshClick(){
      let currentComponent = this;
      this.isRefreshing = true;
      setTimeout(()=>{
        currentComponent.isRefreshing = false;
      }, 3000)
      this.fetchAwardMasterApplicants();
    }

}
