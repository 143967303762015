<div class="container">
    <div>
        <app-top-bar selectedTab="home"></app-top-bar>
        <app-header></app-header>
    </div>
    <br>

    <div class="tool-bar">
        <div class="refresh-btn">

        </div>
        <div class="col-sm-1 ">
            <button mat-button class="btn btn-primary" (click)="clickBack()">Back</button>

        </div>
        <div class="col-sm-5 title ">
            {{heading}}
        </div>
    </div><br>
    <div class="tool-bar">
        <div class="refresh-btn">

        </div>
        <div class="col-sm-1 ">
        </div>
       

    </div>
    <div>
        <hr class="new">
        <div class="sub-title">
            <div class="col-sm-8 ">
                <label><b>Common Data Elements</b></label>
            </div>
        </div>
        <div class="row application-form-row col-sm-12">
            <div class="col-sm-2">
                <label class="form-label">Applicant Name: <span class="text-danger"> * </span></label>
            </div>
            <div class="col-sm-10">
                <input type="string" [(ngModel)]="applicantName" class="form-textbox"
                    [readonly]="!isEditable" />
            </div>
        </div>
        <div class="row application-form-row col-sm-12">
            <div class="col-sm-2">
                <label class="form-label">Prop.Address: <span class="text-danger"> * &nbsp;</span></label>
            </div>
            <div class="col-sm-10">
                <input type="string" [(ngModel)]="applicantAddress" class="form-textbox"
                    [readonly]="!isEditable" />
            </div>
        </div>
        <div class="row application-form-row col-sm-12">
            <div class="col-sm-2">
                <label class="form-label">Applicant Name:<br> (Kannada) <span class="text-danger"> *
                        &nbsp;</span></label>
            </div>
            <div class="col-sm-10">
            <input type="string" [(ngModel)]="applicantNameKannada" class="form-textbox"/>
            </div>
        </div>
        <div class="row application-form-row col-sm-12" style="margin-top:2rem ;">
            <div class="col-sm-6">
                <div class="col-sm-3">
                    <label class="form-label">Village Name:<br> (English) <span class="text-danger"> *
                            &nbsp;</span></label>
                </div>
                <div class="col-sm-4 offset-2" style="margin-top: -2rem;">
                    <select class="form-texttbox" [(ngModel)] = "villageId">
                        <option *ngFor="let village of villages"  [value]="village.id" >{{village.id}} {{village.name}}
                        </option>
                    </select>
                </div>
            </div>
            <div class="col-sm-6">
                <div class="col-sm-3">
                    <label class="form-label">Village Name:<br> (Kannada) <span class="text-danger"> *
                            &nbsp;</span></label>
                </div>
                <div class="col-sm-4 offset-2" style="margin-top: -2rem;">
                    <select class="form-texttbox" [(ngModel)] = "villageId" >
                        <option *ngFor="let village of villages"  [value]="village.id" >{{village.id}} {{village.villageNameKannada}}
                        </select>
                </div>
            </div>
        </div>
        <div class="row application-form-row col-sm-12" style="margin-top:2rem;">
            <div class="col-sm-6">
                <div class="col-sm-3">
                    <label class="form-label">Sy No: <span class="text-danger"> *
                            &nbsp;</span></label>
                </div>
                <div class="col-sm-4 offset-2" style="margin-top: -2rem;">
                    <input type="string" [(ngModel)]="surveyNumber" class="form-texttbox"/>
                </div>
            </div>
            <div class="col-sm-6">
                <div class="col-sm-3">
                    <label class="form-label">Site No: </label>
                </div>
                <div class="col-sm-4 offset-2" style="margin-top: -2rem;">
                    <input type="string" [(ngModel)]="siteNumber" class="form-texttbox"/>
                </div>
            </div>
        </div>
    <div>
        <div>
            <hr class="new">
        <!-- <first line> -->
        <div class="sub-title">
            <div class="col-sm-8 ">
                <label><b>Dimensions</b></label>
            </div>
        </div>
        <div class="row application-form-row col-sm-12" style="margin-top: 2rem;">
            <div class="col-sm-4">
                <div class="col-sm-5">
                    <label class="form-label">Length: <span class="text-danger"> <span *ngIf="">*</span>
                            &nbsp;</span></label>
                </div>
                <div class="col-sm-4 offset-3" style="margin-top: -2rem;">
                    <input type="number" [(ngModel)]="length" class="form-texttbox" />
                </div>
            </div>
            <div class="col-sm-4">
                <div class="col-sm-5">
                    <label class="form-label">Breadth: <span class="text-danger"> <span>*</span>
                            &nbsp;</span></label>
                </div>
                <div class="col-sm-4 offset-3" style="margin-top: -2rem;">
                    <input type="number" [(ngModel)]="breadth" class="form-texttbox" />
                </div>
            </div>
            <div class="col-sm-4">
                <div class="col-sm-5">
                    <label class="form-label">No of Floors: <span class="text-danger"> <span>*</span>
                            &nbsp;</span></label>
                </div>
                <div class="col-sm-4 offset-3" style="margin-top: -2rem;">
                    <input type="number" [(ngModel)]="noOfFloors" class="form-texttbox" />
                </div>
            </div>
        </div>
        <div class="row application-form-row col-sm-12" style="margin-top: 3rem;">
            <div class="col-sm-4">
                <div class="col-sm-5">
                    <label class="form-label">Sital Area: <span class="text-danger"> <span>*</span>
                            &nbsp;</span></label>
                </div>
                <div class="col-sm-4 offset-3" style="margin-top: -2rem;">
                    <input type="number" [(ngModel)]="sitalArea" class="form-texttbox" />
                </div>
            </div>
            <div class="col-sm-4">
                <div class="col-sm-5">
                    <label class="form-label">Plinth Area: <span class="text-danger"> <span>*</span>
                            &nbsp;</span> </label>
                </div>
                <div class="col-sm-4 offset-3" style="margin-top: -2rem;">
                    <input type="number" [(ngModel)]="plinthArea" class="form-texttbox"/>
                </div>
            </div>
            <div class="col-sm-4">
                <div class="col-sm-5">
                    <label class="form-label">Build-up Area: <span class="text-danger"> <span>*</span>
                            &nbsp;</span></label>
                </div>
                <div class="col-sm-4 offset-3" style="margin-top: -2rem;">
                    <input type="number" [(ngModel)]="buildUpArea" class="form-texttbox"/>
                </div>
            </div>
        </div>
        <div class="sub-title">
            <div class="col-sm-8 ">
                <label><b>Checkbandi</b></label>
            </div>
        </div>
        <div class="row application-form-row col-sm-12 ">
            <div class="col-sm-6">
                <div class="col-sm-3">
                    <label class="form-label">East: <span class="text-danger"> <span>*</span>
                            &nbsp;</span></label>
                </div>
                <div class="col-sm-4 offset-2" style="margin-top: -2rem;">
                    <input type="string" [(ngModel)]="east"  class="form-texttbox" />
                </div>
            </div>
            <div class="col-sm-6">
                <div class="col-sm-3">
                    <label class="form-label"> North: <span class="text-danger"> <span >*</span>
                            &nbsp;</span></label>
                </div>
                <div class="col-sm-4 offset-2" style="margin-top: -2rem;">
                    <input type="string" [(ngModel)]="north" class="form-texttbox" >
                </div>
            </div>
        </div>

        <div class="row application-form-row col-sm-12" style="margin-top:2rem;">
            <div class="col-sm-6">
                <div class="col-sm-3">
                    <label class="form-label">West: <span class="text-danger"> <span >*</span>
                            &nbsp;</span></label>
                </div>
                <div class="col-sm-4 offset-2" style="margin-top: -2rem;">
                    <input type="string" [(ngModel)]="west" class="form-texttbox" />
                </div>
            </div>
            <div class="col-sm-6">
                <div class="col-sm-3">
                    <label class="form-label">South: <span class="text-danger"> <span>*</span>
                            &nbsp;</span></label>
                </div>
                <div class="col-sm-4 offset-2" style="margin-top: -2rem;">
                    <input type="string" [(ngModel)]="south"  class="form-texttbox" />
                </div>
            </div>
        </div>
    </div>
</div>
    <hr class="new">
    </div>

        <div class="col-sm-12">
            <div class="container">
                <div class="row">
                    <div class="col-sm-4 offset-sm-5">
                        <button style="margin-top: 36px;" class="btn btn-primary refresh-btn" (click)="onSubmit(data)">
                            Submit
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <app-footer></app-footer>
