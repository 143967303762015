<div class="bgcolor-container">
  <div *ngFor="let sector of sectorData ; let i = index;">
    <mat-card class="mb-1 mr-5 ml-5">
      <div class="container">
        <div class="row ml-2">
          <div class="col-sm-8">
            <div class="row">
            <mat-checkbox class="pt-2" labelPosition="after" [(ngModel)]="sector.checked"
            [checked]="sector.checked" (change)='onSectorSelected($event, sector, i)'></mat-checkbox>
            <h2 class="col-md-11">{{sector.sectorName}}</h2>
          </div>
          </div>
          <div class="col-sm-4" *ngIf="sector.contractorData">
            <div class="d-flex">
              <div>
                <h3>Contractor:</h3>
              </div>
              <div class="p-1">
                <p>{{sector.contractorData.contractorName}}</p>
              </div>
            </div>
            <div class="d-flex">
              <div>
                <h3>Site Office:</h3>
              </div>
              <div class="p-1">
                <p>{{sector.contractorData.siteOfficeLocation}}</p>
              </div>
            </div>
            <div class="d-flex">
              <div>
                <h3>BDA Engineer:</h3>
              </div>
              <div class="p-1">
                <p>{{sector.contractorData.engineerInCharge}}</p>
              </div>
            </div>
        </div>
      </div>
        <div class="row my-5">
          <div class="col-sm-4">
            <div class="row">
              <div class="col-sm-4 d-flex justify-content-end p-0">
                <h3>Function: <span class="text-danger">*</span></h3>
              </div>
              <div class="col-sm-8">
                <mat-form-field appearance="fill">
                  <mat-select id="function" name="functionId" [(ngModel)]="sector.selectedFunctionId" [disabled]="!sector.checked">
                    <mat-option *ngFor="let functionType of functionTypeData" [value]="functionType.functionTypeId"
                      (click)="onFunctionTypeChange(i, sector.selectedFunctionId)">
                      {{functionType.functionType}}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
            </div>
          </div>
          <div class="col-sm-4">
            <div class="row">
              <div class="col-sm-5 d-flex justify-content-end p-0">
                <h3>Main Activity: <span class="text-danger">*</span></h3>
              </div>
              <div class="col-sm-7">
                <mat-form-field appearance="fill">
                  <mat-select id="mainActivity" name="mainActivityId" [(ngModel)]="sector.selectedMainActivityId" [disabled]="!sector.checked">
                    <mat-option *ngFor="let mainActivity of sector.mainActivityData" [value]="mainActivity.mainActivityId"
                      (click)="onMainActivityChange(i, sector.selectedFunctionId, sector.selectedMainActivityId)">
                      {{mainActivity.mainActivityName}}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
            </div>
          </div>
          <div class="col-sm-4">
            <div class="row">
              <div class="col-sm-4 d-flex justify-content-end">
                <h3>Work: <span class="text-danger">*</span></h3>
              </div>
              <div class="col-sm-8">
                <mat-form-field appearance="fill">
                  <mat-select id="workType" name="workTypeId" [(ngModel)]="sector.selectedWorkTypeId" [disabled]="!sector.checked">
                    <mat-option *ngFor="let workType of sector.workTypes" [value]="workType.workTypeId">
                      {{workType.workName}}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
            </div>
          </div>
        </div>

      </div>  
      <div class="row">
        <div class="col-sm-10">
          <section>
            <table>
              <tr>
                <th>Village</th>
                <th>Sy Nos.</th>
                <th>Approx Area (in acres)</th>
                <th>Action</th>
              </tr>
              <tr *ngFor="let village of sector.villages;let v = index">
                <td>{{village.selectedVillage.villageName}}</td>
                <td>{{village.surveyNo}}</td>
                <td>{{village.approxArea}}</td>
                <td style="cursor:pointer"><mat-icon (click)="onVillageDelete($event, i, v)">delete</mat-icon> </td>
              </tr>
            </table>
          </section>
        </div>
        <div class="col-sm-2">    
          <div class="button-row">
            <input type="button" class="btn btn-warning" value="Add Village" (click)="onVillageSelect(sector, i)" [disabled]="!sector.checked"/>
          </div>
        </div>
      </div>
      <div class="row mt-5">
        <div class="col-sm-10">
          <mat-form-field appearance="fill" style="width:100%">
            <mat-label>Add Comments</mat-label>
            <textarea [disabled]="!sector.checked" matInput rows="5" cols="40" [(ngModel)]="sector.comments"></textarea>
          </mat-form-field>
        </div>
      </div> 
  </mat-card>
  <div class="d-flex justify-content-center">
    <div class="divider w-100"></div>
  </div>
  </div>
</div>
<div class="container adjust-scroll">
  <div class="row">
    <div class="p-2 mr-3">
      <mat-checkbox [(ngModel)]="agreement1Confirmed" ></mat-checkbox>
    </div>
    <div >
      <label class="checkbox-font">All Survey nos selected in all the Sectors cleared for work by respective
        SLAOs</label>
    </div>
  </div>
  
  <div class="row mt-5">
    <div class="col-sm-6  d-flex justify-content-center">
      <button class="btn btn-primary preview-btn" (click)="previewPdf(sectorData)" [disabled]="!agreement1Confirmed">
        Preview Action Plan
      </button>
    </div>
    <div class="col-sm-6  d-flex justify-content-center">
      <button class="btn btn-primary generate-btn" (click)="generatePdf(sectorData)" [disabled]="!agreement1Confirmed">
        Generate Action Plan
      </button>
    </div>
  </div>
</div>