import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router, ActivatedRoute } from '@angular/router';
import { ConfirmDialogService } from 'src/app/shared/confirm-dialog/confirm-dialog.service';
import { ApplicationService } from 'src/services/application.service';
import { StrorageService } from 'src/services/strorage.service';

@Component({
  selector: 'app-edit-dpa-work-item',
  templateUrl: './edit-dpa-work-item.component.html',
  styleUrls: ['./edit-dpa-work-item.component.scss']
})
export class EditDpaWorkItemComponent implements OnInit {

  mainActivityName: any;
  selectedRoadStr: any;
  public measurements: any[] = [];

  userData: any;

  firstName: string;

  showCivilWorkOptions: boolean = false;

  public title: string = '';

  mainActivityList: any[];

  sectorList: any[];

  roadSizeList: any[]

  sectorShortName: any;

  sectorName: any;

  mainActivityShortName: any;

  selectedRoadSize: any;

  totalRoadSize: any;

  sectorId: any;

  mainActivityId: any;

  roadSizeId: any;

  taskName: any;

  public unitCode: any = 'm';

  public length: any;

  public workTaskItemId: any;
  
  unitId: any;

  selectedMeasurementUnit: any;

  savedMeasurementUnit: any;

  scope: any;

  savedTaskName: any;

  constructor(private storageService: StrorageService, private applicationService: ApplicationService,
    private router: Router, public dialog: MatDialog, private route: ActivatedRoute,
    public confirmDialogService: ConfirmDialogService, private _snackBar: MatSnackBar) {
    this.workTaskItemId = this.route.snapshot.queryParams['workTaskItemId'];
    console.log('workTaskItemId = ' + this.workTaskItemId);
  }

  ngOnInit() {
    window.scroll(0, 0);
    this.userData = this.storageService.getUser();
    if (this.userData) {
      this.firstName = this.userData['firstName'];
    }
    this.getAllSector();
    this.getAllMainActivity();
    this.getAllRoadSize();
    this.getAllMeasurementUnits();
    this.getWorkTaskItemById();
  }

  getWorkTaskItemById = () => {
    if (this.workTaskItemId == null) {
      throw new Error("work task item id must not be null");
    }
    this.applicationService.getWorkTaskItemById(this.workTaskItemId).subscribe(res => {
      this.setWorkTaskValue(res);
      console.log(res);
    })
  }

  setWorkTaskValue(data: any) {
    this.sectorId = data.sectorId;
    this.mainActivityId = data.mainActivityId;
    this.roadSizeId = data.roadSizeId;
    this.sectorName = data.sectorName;
    this.mainActivityName = data.mainActivityName;
    this.selectedRoadStr = data.roadSize + " " + data.roadSizeMeasurementName;
    this.sectorShortName = data.sectorShortName;
    this.mainActivityShortName = data.mainActivityShortName;
    this.selectedRoadSize = data.roadSize;
    this.length = data.length;
    this.unitId = data.unitId;
    this.taskName = data.taskName;
    this.savedTaskName = data.taskName;
    this.scope = data.length;
    this.selectedMeasurementUnit = data.roadSizeUnit;
    this.savedMeasurementUnit = data.roadSizeUnit;
    this.extractValueFromText(data.taskName);
  }

  extractValueFromText(taskName: any) {
    const splitArray = taskName.split('-');
    console.log(splitArray);
    const task = splitArray[3];
    if (task) {
      this.totalRoadSize = task;
      console.log(this.totalRoadSize);
    }
  }


  setRoadSize(roadSize: any) {
    if (this.selectedRoadSize || this.selectedRoadStr) {
      this.selectedRoadSize = null;
      this.selectedRoadStr = null;
    }
    this.selectedRoadSize = roadSize.roadSize;
    this.selectedRoadStr = roadSize.roadSize + " " + roadSize.measurementData.name;
  }

  getAllMainActivity = () => {
    this.applicationService.getMainActivitiesByFunTypeCode('Civil').subscribe(res => {
      console.log("rses", res);
      this.mainActivityList = res;
    })
  }

  getAllMeasurementUnits = () => {
    this.applicationService.getMeasurements().subscribe(res => {
      console.log("rses", res);
      this.measurements = res;
    })
  }

  getAllSector() {
    this.applicationService.getAllSectors().subscribe(res => {
      if (res) {
        this.sectorList = res;
      }
    })
  }

  getAllRoadSize() {
    this.applicationService.getAllRoadSizes().subscribe(res => {
      if (res) {
        this.roadSizeList = res;
      }
    })
  }

  validateAndConstructPayload() {
    if (!this.sectorId) {
      throw new Error("Sector cannot be empty!");
    }
    if (!this.mainActivityId) {
      throw new Error("Main Activity cannot be empty!");
    }
    if (!this.taskName) {
      throw new Error("Task Name cannot be empty!");
    }
    if (!this.roadSizeId) {
      throw new Error("Road Size cannot be empty!");
    }
    if (!this.totalRoadSize) {
      throw new Error("Total Road Size cannot be empty!");
    }
    if (!this.length) {
      throw new Error("Length cannot be empty!");
    }
    if (!this.unitCode) {
      throw new Error("Unit cannot be empty!");
    }
    let payload = {};
    payload['functionTypeId'] = 2; //default for civil work
    payload['sectorId'] = this.sectorId;
    payload['mainActivityId'] = this.mainActivityId;
    payload['taskName'] = this.taskName;
    payload['roadSizeId'] = this.roadSizeId;
    payload['length'] = this.length;
    payload['totalRoadSize'] = this.totalRoadSize;
    payload['unitId'] = this.unitId;
    console.log(payload);
    return payload;
  }

  onUnitChange(event: any){
    for(let i= 0; i < this.measurements.length; i++){
      if(this.measurements[i].id== event.value){
        this.selectedMeasurementUnit = this.measurements[i].name;
      }
    }
  }

  generateTaskName() {
    let taskName = this.sectorShortName + '-' + this.mainActivityShortName + '-' + this.selectedRoadSize + '-' + this.totalRoadSize;
    return taskName;
  }

  openSnackbar(message: string, action: string) {
    this._snackBar.open(message, action);
  }

  clickBack() {
    window.history.back();
  }

  updateWorkTaskItem = () => {
    this.taskName = this.generateTaskName();
    const options = {
      message: 'Confirm Task updation ' + this.taskName + ', of length ' + this.length + ' ' + this.selectedMeasurementUnit + '?',
      cancelText: 'Cancel',
      confirmText: 'Ok'
    };
    let payload = this.validateAndConstructPayload();
    this.confirmDialogService.openDialog(options);
    this.confirmDialogService.confirmed().subscribe(confirmed => {
      if (confirmed) {
        this.applicationService.updateWorkTaskItem(this.workTaskItemId, payload).subscribe(res => {
          console.log("res", res);
          this.openSnackbar('Successfully Updated', 'Dismiss');
          this.router.navigate(['/dpa-work-activity-log']);
        })
      }
    });

  }
}
