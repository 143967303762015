import { DOCUMENT } from '@angular/common';
import { Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { faCheck, faTimes, faTrash } from '@fortawesome/free-solid-svg-icons';
import { ApplicationService } from 'src/services/application.service';
import { JccnoEncodeDecodeService } from 'src/services/jccno-encode-decode/jccno-encode-decode.service';

@Component({
  selector: 'app-award-enclosure',
  templateUrl: './award-enclosure.component.html',
  styleUrls: ['./award-enclosure.component.scss']
})
export class AwardEnclosureComponent implements OnInit {
  private applicantId: any;

  public faCheck = faCheck;

  public faTimes = faTimes;

  public faTrash = faTrash;

  public files: any[];

  public isLoading = false;

  public data: any;

  public enclosure: any;

  @Output() nextScreenCallback = new EventEmitter<any>();
  documentName: any;

  constructor(private applicationService: ApplicationService, private route: ActivatedRoute,
    private jccEncodeDecoder: JccnoEncodeDecodeService, private router: Router, private _snackBar: MatSnackBar, @Inject(DOCUMENT) private document) {
    this.applicantId = this.route.snapshot.queryParams['application_id'];
    this.files = [];
  }

  ngOnInit(): void {
    console.log("enclosure form");
    this.fetchApplicantById(this.applicantId);  
  }

  public fetchApplicationData = (applicantNo: any) => {
    this.applicationService.fetchApplicantByApplicationNo(applicantNo).subscribe(res=>{
        console.log("upload enclosure form details", res);
        this.applicantId = res.id;
        this.data = res;
        this.enclosure = res.enclosure;
    })
  }

  openSnackbar = (message: string, action?: string) => {
    if(!action) {
      action = "Dismiss";
    }
    this._snackBar.open(message, action);
  }

  onFileChanged = (event: any) => {
    const file = {};
    file['name'] = event.target.name;
    file['data'] = event.target.files[0];
    this.files.push(file);
  }

  public onSubmit = (): void => {
    const formData = new FormData();
    for (const file of this.files) {
      formData.append(file.name, file.data,file.data.name);
    }
    this.applicationService.uploadAwardMasterEnclosure(this.applicantId, formData).subscribe(res =>{
      this.data = res;
      console.log(res);
      this.openSnackbar("Successfully Uploaded");
      this.fetchApplicantById(this.applicantId);
    })
  }

  private fetchApplicantById = (applicantId: any) => {
    if(!applicantId) {
      return;
    }
    this.applicationService.fetchAwardMasterApplicantsById(applicantId).subscribe(res =>{
      this.data = res;
      this.documentName = res.documentName;
    })
  }

  dismissIconClick = (id: any) => {
  (<HTMLInputElement> this.document.getElementById(id)).value = null;
     if(!this.files || this.files.length <= 0){
       return;
     }
  this.files.map((row, index) =>{
     if(row.name == id){
      this.files.splice(index, 1);
     }
  })
  }
}