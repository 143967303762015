import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { PageEvent } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { faSync } from '@fortawesome/free-solid-svg-icons';
import { PreviewDialogsComponent } from 'src/app/award-scrutiny-user/preview-dialogs/preview-dialogs.component';
import { DownloadReport } from 'src/app/community-login/community-view-applicants/download-report';
import { ConfirmDialogService } from 'src/app/shared/confirm-dialog/confirm-dialog.service';
import { MessageDialogService } from 'src/app/shared/message-dialog/message-dialog.service';
import { ApplicationService } from 'src/services/application.service';
import { StrorageService } from 'src/services/strorage.service';
import { RcRegisterBatchProcessingComponent } from '../rc-register-batch-processing/rc-register-batch-processing.component';

@Component({
  selector: 'app-rc-register-edit-batch-processing-form',
  templateUrl: './rc-register-edit-batch-processing-form.component.html',
  styleUrls: ['./rc-register-edit-batch-processing-form.component.scss']
})
export class RcRegisterEditBatchProcessingFormComponent implements OnInit {
  public pageSize =5;
  public currentPage = 0;
  public totalSize: number= 0;
  public faSync = faSync;
  public isRefreshing = false;
  public displayedColumns: string[] = ['all','rcSlNo','groupNumber','jccNo','applicantNameEng','applicantNameKann','propAddr','annexure','reportSlNo','east', 'west', 'north', 'south','villageEng','villagekan','length','breadth','sitalArea','plinthArea','buildUpArea','noOfFloor','structureType','action'];
  public selectedApplicants: any = [];
  public dataSource = new MatTableDataSource();
  selectedDateFilter:any;
  @Input() isEditable : any;
  user: any;
  public applicationNo: any;
  selectedVillages: number;
  public totalApplicantCount: any = 0;
  show: boolean;
  applicantServiceApiCallObserver: any;
  public id: number;
  applicationName: any;
  villageName: any;
  public applicantNameKannada: any;
  public buildingAddress: any;
  public groupNumber: any;
  public groupSize: any;
  public structureType: any;
  public villages: any[];
  public villageId: any;
  public siteNumber: any;
  public surveyNumber: any;
  public activeStatus: any;
  public plinthArea: any;
  public cdFeederReportData: any;
  public sitalArea: any;
  public noOfFloors: any;
  public buildUpArea: any;
  public east: any;
  public west: any;
  public north: any;
  public south: any;
  public length: any;
  public breadth: any;
  public batchSize: number;
  public batchName: String;
  applicantId: any;
  applicationData: any;
  processedBy: any;
  applicationNameKannada: any;
  comment: any;
  rcRegisterId: any;
  rcSerialNo:any;
  public annexure: any;
  public scApprovedNo: any;
  public reportDate: any;
  public maxDate: any;
  annexureName: any;
  scApprovalNo: any;
  approvalDate: any;
  statusCode: any;
  sitalAreaDimension: any;
  precedenceSitalArea: any;
  eligibleApplicantCount: any;
  public dataList: any[];

  constructor(private applicationService: ApplicationService,
    private cdr:ChangeDetectorRef, 
    public dialog: MatDialog,
    private confirmDialogService: ConfirmDialogService, 
    private router: Router,private storageService:StrorageService,
    private messageDialogService: MessageDialogService,
    private route:ActivatedRoute) { 
      this.rcRegisterId = this.route.snapshot.queryParams['rcRegisterId'];
    }

    ngOnInit(): void {
      this.user=this.storageService.getUser();
      this.fetchVillages();
      this.fetchBatchProcessingApplicantsByRcRegisterId();
    }

    editForm(){
      this.router.navigate(['/rc-register-batch-processing']);
    }
    fetchBatchProcessingApplicantsByRcRegisterId() {
      this.applicationService.fetchRcRegisterBatchProcessingApplicantById(this.rcRegisterId).subscribe(res=>{
         this.dataList = res;
         for(let i=0; i< res.length;i++) {
          this.groupNumber = res[i].groupNumber;
          this.groupSize = res[i].groupSize;
          this.eligibleApplicantCount = res[i].eligibleApplicantCount;
        }
      })

  }
  
    fetchVillages(){
      this.applicantServiceApiCallObserver = this.applicationService.fetchAllVillages().subscribe(res=>{
        this.villages = res;
        this.villages.unshift({
          "id":"",
          "name":"All"
        });
        })
      }

    onVillageChange(): void{
      this.show=false;
      
    }

    searchApplicant(){
      
    }

    onClear() {
      this.applicationNo = null;
    
    }
  
    clickBack(){
      window.history.back();
  
    }

    editRcRegisterBatchProcessing() {
      this.router.navigate(['/rc-register-batch-processing']);
    }

    precedenceSitalAreaClicked(data:any) {
      this.precedenceSitalArea = data.checked;  
    }

     refreshClick(){
      let currentComponent = this;
      this.isRefreshing = true;
      setTimeout(()=>{
        currentComponent.isRefreshing = false;
      }, 3000)
    
    }
    validateAndConstructPayload = (data :any) => {
      for(let i=0; i < data.length;i++) {
        data[i].applicantNameKannada = data[i].applicantNameKannada;
        data[i].villageId = data[i].villageId;
        data[i].surveyNumber = data[i].surveyNumber;
        data[i].siteNumber = data[i].siteNumber;
        data[i].sitalArea = data[i].sitalArea;
        data[i].lengthAvg =  data[i].lengthAvg;
        data[i].breadthAvg =  data[i].breadthAvg;
        data[i].noOfFloor =  data[i].noOfFloor;
        data[i].sitalAreaDimension =  data[i].sitalAreaDimension;
        data[i].plinthArea =  data[i].plinthArea;
        data[i].totalBuildUpArea =  data[i].totalBuildUpArea;
        data[i].east =  data[i].east;
        data[i].north =  data[i].north;
        data[i].west =  data[i].west;
        data[i].south =  data[i].south;
        data[i].precedenceSitalArea =  data[i].precedenceSitalArea;
        this.validateData(data[i]);
    }
      return data;
    }

    validateData(data: any) {
      if(!data.applicantName){
        throw new Error(data.applicationNo+'-'+'Applicant Name must not be empty');
      }
      if(!data.buildingAddress){
        throw new Error(data.applicationNo+'-'+'Address must not be empty');
      }
      if(!data.applicantNameKannada){
        throw new Error(data.applicationNo+'-'+'Applicant Name Kannada must not be empty');
      }
      if(!data.villageId){
        throw new Error(data.applicationNo+'-'+'Village must not be empty');
      }
      if(!data.surveyNumber){
        throw new Error(data.applicationNo+'-'+'Survey Number must not be empty');
      }
      if(!data.lengthAvg){
        throw new Error(data.applicationNo+'-'+'Length must not be empty');
      }
      if(!data.breadthAvg){
        throw new Error(data.applicationNo+'-'+'Breadth must not be empty');
      }
      if(!data.noOfFloor){
        throw new Error(data.applicationNo+'-'+'No Of Floors must not be empty');
      }
      if(!data.sitalArea){
        throw new Error(data.applicationNo+'-'+'Sital Area field must not be empty');
      }
      if(!data.sitalAreaDimension){
        throw new Error(data.applicationNo+'-'+'Dimension Sital Area field must not be empty');
      }
      if(!data.plinthArea){
        throw new Error(data.applicationNo+'-'+'Plinth Area field must not be empty');
      }
      if(!data.totalBuildUpArea){
        throw new Error(data.applicationNo+'-'+'Total Build Up Area field must not be empty');
      }
      if(!data.east){
        throw new Error(data.applicationNo+'-'+'East field must not be empty');
      }
      if(!data.west){
        throw new Error(data.applicationNo+'-'+'West field must not be empty');
      }
      if(!data.north){
        throw new Error(data.applicationNo+'-'+'North Area field must not be empty');
      }
      if(!data.south){
        throw new Error(data.applicationNo+'-'+'South Area field must not be empty');
      }
  
  }

    onSubmitClick = (data: any) => {
      let array = [];
      for(let i=0; i < data.length;i++) {
        if(data[i].applicationNo){
        array.push(data[i].applicationNo);
        }
      }
      let applicationNo = array.toString();
      let payload = this.validateAndConstructPayload(data);
        const options = {
          title: 'Confirmation!',
          message: 'Are you sure want to submit this JCC Nos : ' + applicationNo,
          cancelText:'No',
          confirmText: 'Yes',
        };
        this.confirmDialogService.openDialog(options);
        this.confirmDialogService.confirmed().subscribe(confirmed => {
          if(confirmed) {
              this.applicationService.updateRcRegisterBatchProcessingApplicantByList(payload).subscribe(res =>{
              this.router.navigate(['/rc-register-batch-processing']);
              return;
            })
          }
        });
      }
  }