<div  class="head">
	<p>Application is out of bound</p>
</div>
 <div class="col-sm-12">
        <div class="row application-form-row ">
            <label class="form-label">Out Of Bounds Status Option: </label>
            <select class="form-textbox" [(ngModel)]="selectedStatusOption">
             <option *ngFor = "let statusOption of statusOptions " [value]="statusOption.id" >{{statusOption.name}}</option>
            </select>
        </div>
    </div>

<div class="button" >
	<button  class="btn negative-btn" mat-button (click)="onCancelClicked()">Cancel</button>
	<button mat-button (click)="onOkClicked()" class="btn btn-primary" cdkFocusInitial>Ok</button>
  </div>
