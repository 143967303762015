<div class="row" *ngIf="statusCode == 'ready_for_reporting'">
    <div>
<div fxLayout="row" fxLayoutAlign="center" fxLayoutGap="25px" style="margin-top: 30px;margin-right: -24%;">
    <div  *ngFor="let statusOption of statusOptions; let i = index;">
        <button class="btn btn-primary content-btn" 
            [matTooltip]="matTooltipContent"
            matTooltipClass="allow-cr"
            (click)="onTilesClicked($event, statusOption)"
            [ngStyle]="{'background-color': statusOption.active ? '#F7583F' : 'grey'}">
            {{statusOption.name}} ({{statusOption.applicationCount}})
        </button>
    </div>
    </div>
</div>
</div>
<div class="row" *ngIf="statusCode == 'out_of_bounds'">
    <div>
        <div *ngFor="let statusOption of statusOptions; let i = index;" class="village-options">
            <div (click)="onTilesClicked($event, statusOption)"
            [class.cursor-style]="type == 'village'">
                <div class="button " [class.village]="type == 'village'"  
                [ngStyle]="{'background-color': statusOption.active ? '#F7583F' : 'grey'}">
                {{statusOption.name}} ({{statusOption.applicationCount}})
                </div>
            </div>
        </div>
    </div>
    </div>
