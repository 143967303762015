import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router, ActivatedRoute } from '@angular/router';
import { ApplicationService } from 'src/services/application.service';
import { faCheck, faTimes, faTrash } from '@fortawesome/free-solid-svg-icons';
import { JccnoEncodeDecodeService } from 'src/services/jccno-encode-decode/jccno-encode-decode.service';

@Component({
  selector: 'app-preview-dialogs',
  templateUrl: './preview-dialogs.component.html',
  styleUrls: ['./preview-dialogs.component.scss']
})
export class PreviewDialogsComponent implements OnInit {
  public faTimes = faTimes;

  public faCheck = faCheck;

  applicantId: any;

  applicationData: any;
  public comment: any;
  
  constructor(public dialogRef: MatDialogRef<PreviewDialogsComponent>,private router: Router,private applicationService: ApplicationService,
     public dialog: MatDialog,
     private _snackBar: MatSnackBar,
     private route: ActivatedRoute,
     private jccEncodeDecoder: JccnoEncodeDecodeService,
     @Inject(MAT_DIALOG_DATA) public data: any ) { 
      this.applicantId = this.route.snapshot.queryParams['application_id'];
      this.comment = data;
      console.log(data);
     }

  ngOnInit(): void {
    this.fetchApplicantById(this.applicantId);
  }
  close() {
    this.dialogRef.close();
  }
  cancelClick(){
    this.close();
  }

  private fetchApplicantById = (applicantId: any) => {
    if(!applicantId) {
      return;
    }
    this.applicationService.fetchAwardMasterApplicantsById(applicantId).subscribe(res =>{
        this.applicationData = res;
        console.log(res);
    })
  }

}
