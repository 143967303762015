import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ApplicationFormComponent } from './application-form/application-form.component';
import { ContactComponent } from './contact/contact.component';
import { HomeComponent } from './home/home.component';
import { ApplicantListComponent } from './applicant-list/applicant-list.component';
import { ApplicantFormComponent } from './applicant-form/applicant-form.component';
import { UploadEnclosureComponent } from './upload-enclosure/upload-enclosure.component';
import { ApplicantEnclosureListComponent } from './applicant-enclosure-list/applicant-enclosure-list.component';
import { HelpDeskLoginComponent } from './help-desk-login/help-desk-login.component';
import { OnlineApplicationLoginComponent } from './online-application-login/online-application-login.component';
import { ApplicationLoginTypeComponent } from './application-login-type/application-login-type.component';
import { CommunityLoginComponent } from './community-login/community-login.component';
import { AdminLoginComponent } from './admin-login/admin-login.component';
import { AdminComponent } from './admin/admin.component';
import { AuditHelpdeskComponent } from './audit-helpdesk/audit-helpdesk.component';
import { AuditHelpdeskLoginComponent } from './audit-helpdesk-login/audit-helpdesk-login.component';
import { PreviewEnclosureComponent } from './preview-enclosure/preview-enclosure.component';
import { ScrutinyLoginComponent } from './scrutiny-user/scrutiny-login/scrutiny-login.component';
import { ScrutinyPreviewComponent } from './scrutiny-user/scrutiny-preview/scrutiny-preview.component';
import { ActivityLogComponent } from './scrutiny-user/activity-log/activity-log.component';
import { ScrutinyDashboardComponent } from './scrutiny-user/scrutiny-dashboard/scrutiny-dashboard.component';
import { ScrutinyAdminDashboardComponent } from './scrutiny-admin/scrutiny-admin-dashboard/scrutiny-admin-dashboard.component';
import { ScrutinyAdminLoginComponent } from './scrutiny-admin/scrutiny-admin-login/scrutiny-admin-login.component';
import { ActivityLogViewComponent } from './scrutiny-user/activity-log-view/activity-log-view.component';
import { FieldVisitUserDashboardComponent } from './field-visit-user/field-visit-user-dashboard/field-visit-user-dashboard.component';
import { FieldVisitUserLoginComponent } from './field-visit-user/field-visit-user-login/field-visit-user-login.component';
import { FieldVisitUserActivityLogComponent } from './field-visit-user/field-visit-user-activity-log/field-visit-user-activity-log.component';
import { FieldVisitWorkbenchComponent } from './field-visit-user/field-visit-workbench/field-visit-workbench.component';
import { FieldVisitActivityLogViewComponent } from './field-visit-user/field-visit-activity-log-view/field-visit-activity-log-view.component';
import { AdjudicationUserLoginComponent } from './adjudication-user/adjudication-login/adjudication-user-login.component';
import { AdjudicationDashboardComponent } from './adjudication-user/adjudication-dashboard/adjudication-dashboard.component';
import { AdjudicationWorkbenchComponent } from './adjudication-user/adjudication-workbench/adjudication-workbench.component';
import { AdjudicationActivityLogComponent } from './adjudication-user/adjudication-activity-log/adjudication-activity-log.component';
import { AdjudicationActivityLogViewComponent } from './adjudication-user/adjudication-activity-log-view/adjudication-activity-log-view.component';
import { AdjudicationReportingComponent } from './adjudication-user/adjudication-reporting/adjudication-reporting.component';
import { AdjudicationPreDashboardComponent } from './adjudication-user/adjudication-pre-dashboard/adjudication-pre-dashboard.component';
import { CommiteeReportingComponent } from './community-login/commitee-reporting/commitee-reporting.component';
import { CommitteViewReportComponent } from './community-login/committe-view-report/committe-view-report.component';
import { AppTransferBdaComponent } from './app-transfer-bda/app-transfer-bda.component';
import { GenericLoginComponent } from './generic-login/generic-login.component';
import { ApplicantReportDashboardComponent } from './applicant-report/applicant-report-dashboard/applicant-report-dashboard.component';
import { ActorLoginComponent } from './actor_login/actor_login.component';
import { CommiteeCdinfoComponent } from './community-login/commitee-cdinfo/commitee-cdinfo.component';
import { CdinfoReportApplicantComponent } from './community-login/cdinfo-report-applicant/cdinfo-report-applicant.component';
import { CommunityViewApplicantsComponent } from './community-login/community-view-applicants/community-view-applicants.component';
import { AdjudicationOutOfBoundsDialogboxComponent } from './adjudication-user/adjudication-out-of-bounds-dialogbox/adjudication-out-of-bounds-dialogbox.component';
import { PiUserDashboardComponent } from './pi-user/pi-user-dashboard/pi-user-dashboard.component';
import { ApplicantWorkbenchComponent } from './applicant-workbench/applicant-workbench.component';
import { PiuserWorkbenchComponent } from './pi-user/pi-user-workbench/pi-user-workbench.component';
import { PiUserActivityLogComponent } from './pi-user/pi-user-activity-log/pi-user-activity-log.component';
import { PiUserActivityLogViewComponent } from './pi-user/pi-user-activity-log-view/pi-user-activity-log-view.component';
import { ReadyForReportingDialogboxComponent } from './ready-for-reporting-dialogbox/ready-for-reporting-dialogbox.component';
import { StatusOptionTilesComponent } from './community-login/status-option-tiles/status-option-tiles.component';
import { CommitteActiveStatusComponent } from './community-login/committe-active-status/committe-active-status.component';
import { GroupApplicantsDialogboxComponent } from './group-applicants-dialogbox/group-applicants-dialogbox.component';
import { CommiteeRcPrepComponent } from './community-login/commitee-rc-prep/commitee-rc-prep.component';
import { CommiteeRcPrepWorkbenchComponent } from './community-login/commitee-rc-prep-workbench/commitee-rc-prep-workbench.component';
import { DecisionMakingFormComponent } from './decision-making-form/decision-making-form.component';
import { DecisionMakingCommentDialogBoxComponent } from './decision-making-form/decision-making-comment-dialog-box/decision-making-comment-dialog-box.component';
import { DecisionMakingWorkbenchComponent } from './decision-making-form/decision-making-workbench/decision-making-workbench.component';
import { CommitteCdDoneDetailsComponent } from './community-login/committe-cd-done-details/committe-cd-done-details.component';
import { AwardScrutinyUserDashboardComponent } from './award-scrutiny-user/award-scrutiny-user-dashboard/award-scrutiny-user-dashboard.component';
import { AwardMasterDashboardComponent } from './award-scrutiny-user/award-master-dashboard/award-master-dashboard.component';
import { AwardScrutinyUserActivityLogComponent } from './award-scrutiny-user/award-scrutiny-user-activity-log/award-scrutiny-user-activity-log.component';
import { AwardScrutinyUserWorkbenchComponent } from './award-scrutiny-user/award-scrutiny-user-workbench/award-scrutiny-user-workbench.component';
import { AwardDetailsComponent } from './award-scrutiny-user/award-details/award-details.component';
import { BasicInfoComponent } from './award-scrutiny-user/basic-info/basic-info.component';
import { PreviewComponent } from './award-scrutiny-user/preview/preview.component';
import { StructureDetailsComponent } from './award-scrutiny-user/structure-details/structure-details.component';
import { PreviewDialogsComponent } from './award-scrutiny-user/preview-dialogs/preview-dialogs.component';
import { AwardEnclosureComponent } from './award-scrutiny-user/award-enclosure/award-enclosure.component';
import { AwardMasterActivityLogViewComponent } from './award-scrutiny-user/award-master-activity-log-view/award-master-activity-log-view.component';
import { RcDisbursementLoginComponent } from './rc-disbursement/rc-disbursement-login/rc-disbursement-login.component';
import { RcDisbursementDashboardComponent } from './rc-disbursement/rc-disbursement-dashboard/rc-disbursement-dashboard.component';
import { RcDisbursementActivityLogComponent } from './rc-disbursement/rc-disbursement-activity-log/rc-disbursement-activity-log.component';
import { AwardActiveStatusComponent } from './award-scrutiny-user/award-active-status/award-active-status.component';
import { AwardAnalysisComponent } from './award-scrutiny-user/award-analysis/award-analysis.component';
import { RcRegisterActivityLogComponent } from './rc-register/rc-register-activity-log/rc-register-activity-log.component';
import { RcRegisterDashboardComponent } from './rc-register/rc-register-dashboard/rc-register-dashboard.component';
import { RcRegisterDataEnrichmentComponent } from './rc-register/rc-register-data-enrichment/rc-register-data-enrichment.component';
import { RcRegisterBatchProcessingComponent } from './rc-register/rc-register-batch-processing/rc-register-batch-processing.component';
import { RcRegisterDataEnrichmentFormComponent } from './rc-register/rc-register-data-enrichment-form/rc-register-data-enrichment-form.component';
import { AppComponent } from './app.component';
import { AuthGuardService } from 'src/services/auth-guard.service';
import { RcRegisterEditBatchProcessingFormComponent } from './rc-register/rc-register-edit-batch-processing-form/rc-register-edit-batch-processing-form.component';
import { RcRegisterDataEnrichemntActivityLogComponent } from './rc-register/rc-register-data-enrichemnt-activity-log/rc-register-data-enrichemnt-activity-log.component';
import { RcRegisterDefaultDatatableComponent } from './rc-register/rc-register-default-datatable/rc-register-default-datatable.component';
import { RcSdDataValidationDashboardComponent } from './rc-register/rc-sd-data-validation/rc-sd-data-validation-dashboard/rc-sd-data-validation-dashboard.component';
import { RcSdDataValidationActivityLogComponent } from './rc-register/rc-sd-data-validation/rc-sd-data-validation-activity-log/rc-sd-data-validation-activity-log.component';
import { RcCdDataValidationDashboardComponent } from './rc-register/rc-cd-data-validation/rc-cd-data-validation-dashboard/rc-cd-data-validation-dashboard.component';
import { RcCdDataValidationActivityLogComponent } from './rc-register/rc-cd-data-validation/rc-cd-data-validation-activity-log/rc-cd-data-validation-activity-log.component';
import { RcSdDataValidationWorkbenchComponent } from './rc-register/rc-sd-data-validation/rc-sd-data-validation-workbench/rc-sd-data-validation-workbench.component';
import { RcCdDataValidationWorkbenchComponent } from './rc-register/rc-cd-data-validation/rc-cd-data-validation-workbench/rc-cd-data-validation-workbench.component';
import { RcRegisterWorkbenchComponent } from './rc-register/rc-register-workbench/rc-register-workbench.component';
import { ApplicantWorkbenchViewComponent } from './applicant-workbench-view/applicant-workbench-view.component';
import { RcRegisterFormViewComponent } from './rc-register/rc-register-form-view/rc-register-form-view.component';
import { RcDisbursementWorkbenchComponent } from './rc-disbursement/rc-disbursement-workbench/rc-disbursement-workbench.component';
import { CommitteeCdDataComponent } from './community-login/committee-cd-data/committee-cd-data.component';
import { CommitteOutOfBoundComponent } from './community-login/committe-out-of-bound/committe-out-of-bound.component';
import { CommitteReportedOutOfBoundsComponent } from './community-login/committe-reported-out-of-bounds/committe-reported-out-of-bounds.component';
import { RcDisbursementActivityLogViewComponent } from './rc-disbursement/rc-disbursement-activity-log-view/rc-disbursement-activity-log-view.component';
import { LayoutFormationDashboardComponent } from './layout-formation/widget/layout-formation-dashboard/layout-formation-dashboard.component';
import { LayoutSectorDashboardComponent } from './layout-formation/widget/layout-sector-dashboard/layout-sector-dashboard.component';
import { LayoutDocumentListComponent } from './layout-formation/widget/layout-document-list/layout-document-list.component';
import { LayoutSectorPackageDashboardComponent } from './layout-formation/widget/layout-sector-package-dashboard/layout-sector-package-dashboard.component';
import { DpaGenerationWorkbenchComponent } from './dpa-generation/dpa-generation-workbench/dpa-generation-workbench.component';
import { DpaActivityLogComponent } from './dpa-generation/dpa-activity-log/dpa-activity-log.component';
import { CommitteeDpaActivityLogComponent } from './community-login/committee-dpa-activity-log/committee-dpa-activity-log.component';
import { DailyProgressMapViewActivityLogComponent } from './secon/daily-progress-map-view-activity-log/daily-progress-map-view-activity-log.component';
import { SeconDpActivitylogComponent } from './secon/secon-dp-activitylog/secon-dp-activitylog.component';
import { DailyProgressActivitylogDatatableComponent } from './common/daily-progress-activitylog-datatable/daily-progress-activitylog-datatable.component';
import { CommitteProgressTackledComponent } from './community-login/committe-progress-tackled/committe-progress-tackled.component';
import { DpaFunctionalHeadComponentComponent } from './dpa-generation/dpa-functional-head-component/dpa-functional-head-component.component';
import { DpaCivilWorkComponent } from './dpa-generation/dpa-work-item/dpa-work-item.component';
import { DpaWorkItemActivityLogComponent } from './dpa-generation/dpa-work-item-activity-log/dpa-work-item-activity-log.component';
import { DpaWorkItemWorkbenchComponent } from './dpa-generation/dpa-work-item-workbench/dpa-work-item-workbench.component';
import { DpaWorkItemDatatableComponent } from './dpa-generation/dpa-work-item-datatable/dpa-work-item-datatable.component';
import { WorkProgressActivityLogComponent } from './secon/work-progress-activity-log/work-progress-activity-log.component';
import { DpaWorkItemPdfActivityLogComponent } from './dpa-generation/dpa-work-item-pdf-activity-log/dpa-work-item-pdf-activity-log.component';
import { EditDpaWorkItemComponent } from './dpa-generation/edit-dpa-work-item/edit-dpa-work-item.component';
import { DpaActivityLogCommon } from './common/dpa-activity-log-common/dpa-activity-log-common';
import { EeObservationWorkbenchComponent } from './dpa-generation/ee-observation-workbench/ee-observation-workbench.component';
import { DpaEeObservationDashboardComponent } from './dpa-generation/dpa-ee-observation-dashboard/dpa-ee-observation-dashboard.component';
import { DpaEeObservationActivityLogComponent } from './dpa-generation/dpa-ee-observation-activity-log/dpa-ee-observation-activity-log.component';
import { DpaEeObservationPreviewDialogboxComponent } from './dpa-generation/dpa-ee-observation-preview-dialogbox/dpa-ee-observation-preview-dialogbox.component';
import { DpaEeObservationActivityLogViewComponent } from './dpa-generation/dpa-ee-observation-activity-log-view/dpa-ee-observation-activity-log-view.component';
import { LFChartComponent } from './layout-formation/charts/component/lf-chart/lf-chart.component';
import { LandHandOverStatusDashboardComponent } from './layout-formation/land-hand-over-status-dashboard/land-hand-over-status-dashboard.component';
import { LandHandOverStatusActivityLogComponent } from './layout-formation/land-hand-over-status-activity-log/land-hand-over-status-activity-log.component';
import { LandHandOverSurveyActivityLogComponent } from './layout-formation/land-hand-over-survey-activity-log/land-hand-over-survey-activity-log.component';
import { HotlineDashboardComponent } from './hotline-user/hotline-dashboard/hotline-dashboard.component';
import { DmsWorkbenchComponent } from './dms-login/dms-workbench/dms-workbench.component';
import { DmsActivityLogComponent } from './dms-login/dms-activity-log/dms-activity-log.component';
import { EditDmsWorkbenchComponent } from './dms-login/edit-dms-workbench/edit-dms-workbench.component';
import { CommiteeDmsDashboardComponent } from './community-login/commitee-dms-dashboard/commitee-dms-dashboard.component';

const routes: Routes = [
  {
    path: 'application_login',
    pathMatch: 'full',
    component: ApplicationFormComponent, canActivate : [AuthGuardService],
  },
  {
    path: 'bda_em',
    pathMatch: 'full',
    component: ActorLoginComponent,
  },
  {
    path: 'application_login',
    pathMatch: 'full',
    component: ApplicationFormComponent,  canActivate : [AuthGuardService],
  },
  {
    path: 'transfer-bda',
    pathMatch: 'full',
    component: AppTransferBdaComponent,  canActivate : [AuthGuardService],
  },
  {
    path: 'commitee-cdinfo',
    pathMatch: 'full',
    component: CommiteeCdinfoComponent,  canActivate : [AuthGuardService],
  },
  {
    path: 'applicant-list',
    pathMatch: 'full',
    component: ApplicantListComponent,  canActivate : [AuthGuardService],
  },
  {
    path: 'rc-default-datatable',
    pathMatch: 'full',
    component: RcRegisterDefaultDatatableComponent,  canActivate : [AuthGuardService],
  },
  {
    path: 'contact',
    pathMatch: 'full',
    component: ContactComponent,
  },
  {
    path: '',
    pathMatch: 'full',
    component: HomeComponent,
  },
  {
    path: 'applicant-form',
    component: ApplicantFormComponent, canActivate : [AuthGuardService],
    children: [
      {path: '**', redirectTo: 'upload-enclosure'},
      {path: 'upload-enclosure', component: UploadEnclosureComponent},
    ]
  },
  {
    path: 'applicant-enclosure-list', 
    pathMatch: 'full',
    component: ApplicantEnclosureListComponent, canActivate : [AuthGuardService],
  },
  {
    path: 'helpdesk-login',
    pathMatch: 'full',
    component: HelpDeskLoginComponent,
  },
  {
    path: 'scrutiny-login',
    pathMatch: 'full',
    component: ScrutinyLoginComponent,
  },
  {
    path: 'structure-details',
    pathMatch: 'full',
    component: StructureDetailsComponent, canActivate : [AuthGuardService],
  },
  {
    path: 'award-details',
    pathMatch: 'full',
    component: AwardDetailsComponent, canActivate : [AuthGuardService],
  },
  {
    path: 'award-analysis',
    pathMatch: 'full',
    component: AwardAnalysisComponent, canActivate : [AuthGuardService],
  },
  {
    path: 'preview',
    pathMatch: 'full',
    component: PreviewComponent, canActivate : [AuthGuardService],
  },
  {
    path:'scrutiny-admin-login',
    pathMatch: 'full',
    component: ScrutinyAdminLoginComponent,
  },
  {
    path:'scrutiny-admin-list',
    pathMatch: 'full',
    component: ScrutinyAdminDashboardComponent, canActivate : [AuthGuardService],
  },
  {
    path: 'basic-info',
    pathMatch: 'full',
    component: BasicInfoComponent, canActivate : [AuthGuardService],
  },
  {
    path: 'scrutiny-preview',
    pathMatch: 'full',
    component: ScrutinyPreviewComponent, canActivate : [AuthGuardService],
  },
  {
    path: 'activity-log-view',
    pathMatch: 'full',
    component: ActivityLogViewComponent, canActivate : [AuthGuardService],
  },
  {
    path: 'preview-enclosure',
    pathMatch: 'full',
    component: PreviewEnclosureComponent, canActivate : [AuthGuardService],
  },
  {
    path: 'online-applicant-login',
    pathMatch: 'full',
    component: OnlineApplicationLoginComponent, 
  },
  {
    path: 'application',
    pathMatch: 'full',
    component: ApplicationLoginTypeComponent, canActivate : [AuthGuardService],
  },
  {
    path: 'committee-login',
    pathMatch: 'full',
    component: CommunityLoginComponent,
  },
  {
    path: 'admin-login',
    pathMatch: 'full',
    component: AdminLoginComponent,
  },
  {
    path: 'admin',
    pathMatch: 'full',
    component: AdminComponent,
  },
  {
    path: 'audit-helpdesk',
    pathMatch: 'full',
    component: AuditHelpdeskComponent, canActivate : [AuthGuardService],
  },
  {
    path: 'scrutiny-user-dashboard',
    pathMatch:'full',
    component: ScrutinyDashboardComponent, canActivate : [AuthGuardService],
  },

  {
    path: 'audit-helpdesk-login',
    pathMatch: 'full',
    component: AuditHelpdeskLoginComponent,
  },
  {
    path: 'activity-log',
    pathMatch:'full',
    component: ActivityLogComponent, canActivate : [AuthGuardService],
  },
  {
    path: 'field-visit-login',
    pathMatch:'full',
    component: FieldVisitUserLoginComponent,
  },
  {
    path: 'field-visit-user-dashboard',
    pathMatch:'full',
    component: FieldVisitUserDashboardComponent, canActivate : [AuthGuardService],
  },
  {
    path: 'field-visit-user-activity-log',
    pathMatch:'full',
    component: FieldVisitUserActivityLogComponent, canActivate : [AuthGuardService],
  },
  {
    path: 'field-visit-workbench',
    pathMatch:'full',
    component: FieldVisitWorkbenchComponent, canActivate : [AuthGuardService],
  },
  {
    path: 'field-visit-activity-log-view',
    pathMatch:'full',
    component: FieldVisitActivityLogViewComponent, canActivate : [AuthGuardService],
  },
  {
    path: 'adjudication-user-login',
    pathMatch:'full',
    component: AdjudicationUserLoginComponent,
  },
  {
    path: 'adjudication-dashboard',
    pathMatch:'full',
    component: AdjudicationDashboardComponent, canActivate : [AuthGuardService],
  },
  {
    path: 'adjudication-pre-dashboard',
    pathMatch:'full',
    component: AdjudicationPreDashboardComponent, canActivate : [AuthGuardService],
  },
  {
    path: 'adjudication-workbench',
    pathMatch:'full',
    component: AdjudicationWorkbenchComponent, canActivate : [AuthGuardService],
  },
  {
    path: 'adjudication-activity-log',
    pathMatch:'full',
    component: AdjudicationActivityLogComponent, canActivate : [AuthGuardService],
  },
  {
    path: 'adjudication-activity-log-view',
    pathMatch:'full',
    component: AdjudicationActivityLogViewComponent, canActivate : [AuthGuardService],
  },
  {
    path: 'adjudication-out-of-bounds-dialogbox',
    pathMatch:'full',
    component: AdjudicationOutOfBoundsDialogboxComponent, canActivate : [AuthGuardService],
  },
  {
    path: 'adjudication-reporting',
    pathMatch:'full',
    component: AdjudicationReportingComponent, canActivate : [AuthGuardService],
  },
  {
    path: 'committee-reporting',
    pathMatch:'full',
    component: CommiteeReportingComponent, canActivate : [AuthGuardService],
  },
  {
    path: 'view-committee-report',
    pathMatch:'full',
    component: CommitteViewReportComponent, canActivate : [AuthGuardService],
  },
  {
    path: 'community-view-applicants',
    pathMatch:'full',
    component: CommunityViewApplicantsComponent, canActivate : [AuthGuardService],
  },
  {
    path: 'generic-login',
    pathMatch: 'full',
    component: GenericLoginComponent,
  },
  {
    path: 'applicant-report-dashboard',
    pathMatch: 'full',
    component: ApplicantReportDashboardComponent, canActivate : [AuthGuardService],
  },
  {
    path: 'pi-user-dashboard',
    pathMatch: 'full',
    component: PiUserDashboardComponent, canActivate : [AuthGuardService],
  },
  {
    path: 'applicant-workbench',
    pathMatch:'full',
    component: ApplicantWorkbenchComponent, canActivate : [AuthGuardService],
  },
  {
    path: 'pi-user-workbench',
    pathMatch:'full',
    component: PiuserWorkbenchComponent, canActivate : [AuthGuardService],
  },
  {
    path: 'commitee-cdinfo-applicants',
    pathMatch: 'full',
    component: CdinfoReportApplicantComponent, canActivate : [AuthGuardService],
  },
  {
    path: 'pi-user-activity-log',
    pathMatch: 'full',
    component: PiUserActivityLogComponent, canActivate : [AuthGuardService],
  },
  {
    path: 'pi-user-activity-log-view',
    pathMatch: 'full',
    component: PiUserActivityLogViewComponent, canActivate : [AuthGuardService],
  },
  {
    path: 'ready-for-reporting-dialogbox',
    pathMatch: 'full',
    component: ReadyForReportingDialogboxComponent, canActivate : [AuthGuardService],
  },
  {
    path: 'status-option-tiles',
    pathMatch: 'full',
    component: StatusOptionTilesComponent, canActivate : [AuthGuardService],
  },
  {
    path: 'committe-active-status',
    pathMatch: 'full',
    component: CommitteActiveStatusComponent, canActivate : [AuthGuardService],
  },

  {
    path: 'group-applicants-dialogbox',
    pathMatch: 'full',
    component: GroupApplicantsDialogboxComponent, canActivate : [AuthGuardService],
  },

  {
    path: 'commitee-rc-prep',
    pathMatch: 'full',
    component: CommiteeRcPrepComponent, canActivate : [AuthGuardService],
  },
  {
    path: 'commitee-rc-prep-workbench',
    pathMatch: 'full',
    component: CommiteeRcPrepWorkbenchComponent, canActivate : [AuthGuardService],
  },
  {
    path: 'decision-making-form',
    pathMatch: 'full',
    component: DecisionMakingFormComponent, canActivate : [AuthGuardService],
  },
  {
    path: 'decision-making-comment-dialog-box',
    pathMatch: 'full',
    component: DecisionMakingCommentDialogBoxComponent, canActivate : [AuthGuardService],
  },
  {
    path: 'decision-making-workbench',
    pathMatch: 'full',
    component: DecisionMakingWorkbenchComponent, canActivate : [AuthGuardService],
  },
  {
    path: 'committe-cd-done-details',
    pathMatch: 'full',
    component: CommitteCdDoneDetailsComponent, canActivate : [AuthGuardService],
  },
  {
    path: 'committe-cd-data',
    pathMatch: 'full',
    component: CommitteeCdDataComponent, canActivate : [AuthGuardService],
  },
  {
    path: 'award-scrutiny-user-dashboard',
    pathMatch: 'full',
    component: AwardScrutinyUserDashboardComponent, canActivate : [AuthGuardService],
  },
  {
    path: 'award-master-dashboard',
    pathMatch: 'full',
    component: AwardMasterDashboardComponent, canActivate : [AuthGuardService],
  },
  {
    path:'award-scrutiny-user-activity-log',
    pathMatch: 'full',
    component: AwardScrutinyUserActivityLogComponent, canActivate : [AuthGuardService],
  },
  {
    path: 'award-scrutiny-user-workbench',
    pathMatch:'full',
    component: AwardScrutinyUserWorkbenchComponent, canActivate : [AuthGuardService],

  },
  {
    path: 'app-preview-dialogs',
    pathMatch:'full',
    component: PreviewDialogsComponent, canActivate : [AuthGuardService],
  },
  {
    path: 'app-upload-enclosure',
    pathMatch:'full',
    component: AwardEnclosureComponent, canActivate : [AuthGuardService],
  },
  
  {
    path: 'award-master-activity-log-view',
    pathMatch:'full',
    component: AwardMasterActivityLogViewComponent, canActivate : [AuthGuardService],
  },

  {
    path: 'rc-disbursement-login',
    pathMatch:'full',
    component: RcDisbursementLoginComponent ,
  },

  {
    path: 'rc-disbursement-dashboard',
    pathMatch:'full',
    component: RcDisbursementDashboardComponent, canActivate : [AuthGuardService],
  },
  {
    path: 'award-active-status',
    pathMatch: 'full',
    component: AwardActiveStatusComponent, canActivate : [AuthGuardService],
  },

  {
    path: 'rc-disbursement-activity-log',
    pathMatch:'full',
    component: RcDisbursementActivityLogComponent, canActivate : [AuthGuardService],
  },
  

  {
    path: 'rc-register-activity-log',
    pathMatch:'full',
    component: RcRegisterActivityLogComponent ,
  },
  {
    path: 'rc-register-dashboard',
    pathMatch:'full',
    component: RcRegisterDashboardComponent ,
  },
  {
    path: 'rc-register-data-enrichment',
    pathMatch:'full',
    component:   RcRegisterDataEnrichmentComponent,
  },
  {
    path: 'rc-register-batch-processing',
    pathMatch:'full',
    component:   RcRegisterBatchProcessingComponent,
  },
  
  {
    path: 'rc-register-data-enrichment-form',
    pathMatch:'full',
    component:   RcRegisterDataEnrichmentFormComponent,
  },
  {
    path: 'rc-register-edit-batch-processing-form',
    pathMatch:'full',
    component:   RcRegisterEditBatchProcessingFormComponent,
  },

  {
    path: 'rc-register-data-enrichemnt-activity-log',
    pathMatch:'full',
    component:   RcRegisterDataEnrichemntActivityLogComponent,
  },
  {
    path: 'rc-sd-data-validation-dashboard',
    pathMatch:'full',
    component:   RcSdDataValidationDashboardComponent,
  },
  {
    path: 'rc-sd-data-validation-activity-log',
    pathMatch:'full',
    component:   RcSdDataValidationActivityLogComponent,
  },
  {
    path: 'rc-cd-data-validation-dashboard',
    pathMatch:'full',
    component:   RcCdDataValidationDashboardComponent,
  },
  {
    path: 'rc-cd-data-validation-activitylog',
    pathMatch:'full',
    component:   RcCdDataValidationActivityLogComponent,
  },
  {
    path: 'rc-sd-data-validation-workbench',
    pathMatch:'full',
    component:   RcSdDataValidationWorkbenchComponent,
  },
  {
    path: 'rc-cd-data-validation-workbench',
    pathMatch:'full',
    component:   RcCdDataValidationWorkbenchComponent,
  },
  {
    path: 'rc-register-workbench',
    pathMatch:'full',
    component:   RcRegisterWorkbenchComponent,
  },
  {
    path: 'applicant-workbench-view',
    pathMatch:'full',
    component:   ApplicantWorkbenchViewComponent,
  },
  {
    path: 'rc-register-form-view',
    pathMatch:'full',
    component:   RcRegisterFormViewComponent,
  },
  {
    path: 'rc-disbursement-workbench',
    pathMatch:'full',
    component:   RcDisbursementWorkbenchComponent,
  },
  {
    path: 'committe-out-of-bound',
    pathMatch:'full',
    component:   CommitteOutOfBoundComponent,
  },
  {
    path: 'committe-reported-out-of-bounds',
    pathMatch:'full',
    component:   CommitteReportedOutOfBoundsComponent,
  },
  {
    path: 'rc-disbursement-activity-log-view',
    pathMatch:'full',
    component:   RcDisbursementActivityLogViewComponent,
  },
  {
    path: 'layout-formation-dashboard',
    pathMatch: 'full',
    component: LayoutFormationDashboardComponent, canActivate : [AuthGuardService],
  },
  {
    path: 'layout-sector-dashboard',
    pathMatch: 'full',
    component: LayoutSectorDashboardComponent, canActivate : [AuthGuardService],
  },
  {
    path: 'layout-sector-dashboard',
    pathMatch: 'full',
    component: LayoutSectorDashboardComponent, canActivate : [AuthGuardService],
  },
  {
    path: 'layout-document-list',
    pathMatch: 'full',
    component: LayoutDocumentListComponent, canActivate : [AuthGuardService],
  },
  {
    path: 'layout-sector-package-dashboard',
    pathMatch: 'full',
    component: LayoutSectorPackageDashboardComponent, canActivate : [AuthGuardService],
  },
  {
    path: 'dpa-generation-workbench',
    pathMatch: 'full',
    component: DpaGenerationWorkbenchComponent, canActivate : [AuthGuardService],
  },
  {
    path: 'dpa-functional-head',
    pathMatch: 'full',
    component: DpaFunctionalHeadComponentComponent, canActivate : [AuthGuardService],
  },
  {
    path: 'dpa-civil-work',
    pathMatch: 'full',
    component: DpaCivilWorkComponent, canActivate : [AuthGuardService],
  },
  {
    path: 'dpa-work-activity-log',
    pathMatch: 'full',
    component: DpaWorkItemActivityLogComponent, canActivate : [AuthGuardService],
  },
  {
    path: 'dpa-activity-log',
    pathMatch: 'full',
    component: DpaActivityLogComponent, canActivate : [AuthGuardService],
  },
  {
    path: 'committee-dpa-activity-log',
    pathMatch: 'full',
    component: CommitteeDpaActivityLogComponent, canActivate : [AuthGuardService],
  },
  {
    path: 'daily-progress-map-activity-log',
    pathMatch: 'full',
    component: DailyProgressMapViewActivityLogComponent, canActivate : [AuthGuardService],
  }, 
  {
    path: 'secon-dp-activitylog',
    pathMatch: 'full',
    component: SeconDpActivitylogComponent, canActivate : [AuthGuardService]
  },
  {
    path: 'daily-progress-activitylog-datatable',
    pathMatch: 'full',
    component: DailyProgressActivitylogDatatableComponent, canActivate : [AuthGuardService]
  },
  {
    path: 'committe-progress-tackled',
    pathMatch: 'full',
    component: CommitteProgressTackledComponent, canActivate : [AuthGuardService]
  },  
  
  {
    path: 'dpa-work-item-workbench',
    pathMatch: 'full',
    component: DpaWorkItemWorkbenchComponent, canActivate : [AuthGuardService]
  },  

  {
    path: 'dpa-work-item-datatable',
    pathMatch: 'full',
    component: DpaWorkItemDatatableComponent, canActivate : [AuthGuardService]
  }, 
  {
    path: 'dpa-work-progress-datatable',
    pathMatch: 'full',
    component: WorkProgressActivityLogComponent, canActivate : [AuthGuardService]
  }, 
  {
    path: 'dpa-work-item-pdf-activity-log',
    pathMatch: 'full',
    component: DpaWorkItemPdfActivityLogComponent, canActivate : [AuthGuardService]
  },
  {
    path: 'edit-dpa-work-item',
    pathMatch: 'full',
    component: EditDpaWorkItemComponent, canActivate : [AuthGuardService]
  },
  {
    path: 'dpa-ee-observation-dashboard',
    pathMatch: 'full',
    component: DpaEeObservationDashboardComponent, canActivate : [AuthGuardService]
  },
  {
    path: 'secon-daily-updates-dashboard',
    pathMatch: 'full',
    component: DpaActivityLogCommon, canActivate : [AuthGuardService]
  },
  {
    path: 'dpa-ee-observation-activity-log',
    pathMatch: 'full',
    component: DpaEeObservationActivityLogComponent, canActivate : [AuthGuardService]
  },
  {
    path: 'dpa-ee-observation-workbench',
    pathMatch: 'full',
    component: EeObservationWorkbenchComponent, canActivate : [AuthGuardService]
  },
  {
    path: 'dpa-ee-observation-preview-dialogbox',
    pathMatch: 'full',
    component: DpaEeObservationPreviewDialogboxComponent, canActivate : [AuthGuardService]
  },
  {
    path: 'dpa-ee-observation-activity-log-view',
    pathMatch: 'full',
    component: DpaEeObservationActivityLogViewComponent, canActivate : [AuthGuardService]
  },
  {
    path: 'lf-work-progress-charts',
    pathMatch: 'full',
    component: LFChartComponent
  },
  {
    path: 'land-hand-over-status-dashboard',
    pathMatch: 'full',
    component: LandHandOverStatusDashboardComponent, canActivate : [AuthGuardService]
  },
  {
    path: 'land-hand-over-status-activity-log',
    pathMatch: 'full',
    component: LandHandOverStatusActivityLogComponent, canActivate : [AuthGuardService]
  },
  {
    path: 'land-hand-over-survey-activity-log',
    pathMatch: 'full',
    component: LandHandOverSurveyActivityLogComponent, canActivate : [AuthGuardService]
  },
  {
    path: 'pu',
    pathMatch: 'full',
    component: GenericLoginComponent
  },
  {
    path: 'hotline-dashboard',
    pathMatch: 'full',
    component: HotlineDashboardComponent
  },
  {
    path: 'dms-workbench',
    pathMatch: 'full',
    component: DmsWorkbenchComponent
  },
  {
    path: 'dms-activity-log',
    pathMatch: 'full',
    component: DmsActivityLogComponent
  },
  {
    path: 'edit-dms-workbench',
    pathMatch: 'full',
    component: EditDmsWorkbenchComponent
  },
  {
    path: 'commitee-dms-dashboard',
    pathMatch: 'full',
    component: CommiteeDmsDashboardComponent
  },
  { path: '**', redirectTo: '' }


];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
