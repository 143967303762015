import { Component, OnInit, Input, ViewChild, ChangeDetectorRef } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { faSync } from '@fortawesome/free-solid-svg-icons';
import { FormGroup, FormControl } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { JccnoEncodeDecodeService } from 'src/services/jccno-encode-decode/jccno-encode-decode.service';
import { UserService } from 'src/services/user-service/user.service';
import { ApplicationService } from 'src/services/application.service';
import { StrorageService } from 'src/services/strorage.service';
import { PageEvent } from '@angular/material/paginator';

@Component({
  selector: 'app-scrutiny-dashboard',
  templateUrl: './scrutiny-dashboard.component.html',
  styleUrls: ['./scrutiny-dashboard.component.scss']
})
export class ScrutinyDashboardComponent implements OnInit {

  @Input() color: string;
  public displayedColumns: string[] = ['sn','applicationNo','recommendationName','notificationType'];

  public dataSource = new MatTableDataSource();

  public pageSize = 5;
  public currentPage = 0;
  public totalSize = 0;

  public dateFilters = [
    {
      "label":'Pre 2009',
      "subtitle":'(Pre 31 Dec 2008)',
      "tag":"pre_2009",
      "color":"orange",
      'active':false,
      'count': 0,
      "id":1
    },
    {
      "label":'2009 - 26 Nov 2014',
      "subtitle":'(1 Jan 2009 - 26 Nov 2014)',
      "tag":"2009_2014",
      "color":"blue",
      "active":false,
      'count': 0,
      "id":2
    },
    {
      "label":'27 Nov 2014 - 3 Aug 2018',
      "subtitle":'(27 Nov 2014 - 3 Aug 2018)',
      "tag":"2015_3_aug_2018",
      "color":"#8a8a5c",
      "active":false,
      'count': 0,
      "id":3
    },
    {
      "label":'Post Aug 4 - 2018\n',
      "subtitle":'(4 Aug 2018 Onwards)',
      "tag":"post_4_aug_2018",
      "color":"brown",
      'count': 0,
      "active":false,
      "id":4
    },
    {
      "label":'No Dates\n',
      "subtitle":'Applications w/o Dates',
      "tag":"no_dates",
      "color":"blue",
      'active':false,
      'count': 0,
      "id":5
    },
  ];

  public approvedFilters = [
    {
      "label":'Plan Approved',
      "tag":"plan_approved",
      "color":"green",
      'active':false
    },
    {
      "label":'Plan Not Approved',
      "tag":"plan_not_approved",
      "color":"red",
      "active":false
    },
    {
      "label":'N/A',
      "tag":"na",
      "color":"lightgray",
      "active":false
    }
  ];
  public userName = "firstName";
  public villageName="Villagename";
  public show;
  public faSync = faSync;
  public isRefreshing = false;
  public isScrutinyUser;
  public isDateFilterSelected = false;
  public isApplicationSelected=false;
  public selectedButton=false;
  
  public applicants:any[]=[];
  public buttonsTexts:Array<string> = ['First button'];
  enableEdit: boolean = false;
  public isLoading = false;
  public colors=['#FF9633','#11C4E8','#F2FE2E','#CE8E05'];

status = 'Enable';
   
   myGroup = new FormGroup({
    applicationNo: new FormControl()
  
  });
  options: any[] = [];
  filters :any[] = [];
  villageId: any;
  village: any;
  selectedVillages: any [];
  selectapp:boolean=false;
  selectedDateFilter=null;
  selectedPlanApproveFilter=null;
  public user:any;
  public title: string;
  applicationNo=null;

  constructor(private router: Router, private route: ActivatedRoute,public dialog: MatDialog, 
    private jccEncodeDecoder: JccnoEncodeDecodeService,private userService: UserService,
    private applicationService: ApplicationService,private strorageService:StrorageService,private changeDetectorRefs: ChangeDetectorRef) { 
  
     
  }

  ngOnInit(): void {
    this.user=this.strorageService.getUser();
    if(!this.user['villageDTO']) {
      this.village=this.user['villageDTO'];
      this.villageName=this.village['name']
      this.strorageService.clear();
      this.router.navigate(['/scrutiny-login']);
      return;
    }
    this.village=this.user['villageDTO'];
    this.villageId=this.village['id'];
    this.getDashboardCount();
  }

  ngAfterViewInit() {
    
  }

  public getApplicantPaginatorData(event: PageEvent) {
    this.currentPage = event.pageIndex;
    this.pageSize = event.pageSize;
    this.getApplicants();
  }

  getDashboardCount() {
    this.applicationService.getScrutinyUserDashboardFilterCount(this.villageId).subscribe(res =>{
      console.log("filter count yet to be process",res.data);
      let data = res.data;
      for(let i =0;i < this.dateFilters.length;i++) {
        for(let j =0;j < data.length;j++) {
            if(this.dateFilters[i].tag == data[j].key) {
              this.dateFilters[i].count = data[j].value;
              break;
            }
        }
      }
    });
}

getApplicants() {
    this.applicationService.getScrutinyUserApplicants(this.selectedDateFilter.value, this.selectedPlanApproveFilter.value,this.currentPage, this.pageSize,this.applicationNo).subscribe(res =>{
      this.refreshDataTable(res);
    });
}

private refreshDataTable = (res: any) => {
  this.totalSize = res.totalRecord;
  this.dataSource.data = res.data;
  this.dataSource._updateChangeSubscription();
}

clickSelectPostField = (event:any,dateFilter:any) => {
      if(!this.isApplicationSelected) {
        this.isApplicationSelected = true;
      }
      dateFilter.active=!dateFilter.active
      
}

clickApplication = (event:any,dateFilter:any) => {
      if(!this.isApplicationSelected) {
        this.isApplicationSelected = true;
      }
      dateFilter.active=!dateFilter.active
      if(this.isApplicationSelected){
        dateFilter.active=this.colors;
        //this.isLoading=true;
        console.log(dateFilter.active)
      }
}
getApplicantByApplicationNo=()=>{
  if(this.selectedDateFilter && this.selectedPlanApproveFilter){
    this.applicationService.getScrutinyUserApplicants(this.selectedDateFilter.value, this.selectedPlanApproveFilter.value,this.currentPage, this.pageSize,this.applicationNo).subscribe(res =>{
      this.refreshDataTable(res);
    });
  }
 
}
searchApplicant(){
   this.applicationService.getScrutinyUserApplicants(undefined,undefined,this.currentPage, this.pageSize,this.applicationNo).subscribe(res =>{
    this.refreshDataTable(res);
   this.show=true;
  });   
  
    
}
onClear(){
  this.applicationNo="";
  this.show=false;
  }
    
clickDateFilter = (event: any, approvedFilter: any) => {
      this.title = "";
      this.title = approvedFilter.label;
    if(!this.isDateFilterSelected) {
      this.isDateFilterSelected = true;
    }
    approvedFilter.active= true;
    let selectedActiveIndex = 0;
    for(let i = 0;i < this.dateFilters.length;i++) {
      if(this.dateFilters[i].id == approvedFilter.id) {
        selectedActiveIndex = i;
        break;
      }
    }
    for(let i = 0;i < this.dateFilters.length;i++) {
      if(i != selectedActiveIndex) {
        this.dateFilters[i].active= false;
      }
    }
   
    if(approvedFilter.tag==this.selectedButton){
           approvedFilter.color;
    }
    this.selectedDateFilter = {
      "field" :"all_date",
      "predicate":"",
      "value":approvedFilter.tag
      
    };
}

   clickApprovedFilter = (event:any,approvedFilter:any) => {
   
     approvedFilter.active = true;
    // this.isLoading=true;
    let selectedActiveIndex = 0;
    for(let i = 0;i < this.approvedFilters.length;i++) {
      if(this.approvedFilters[i].tag == approvedFilter.tag) {
        selectedActiveIndex = i;
        break;
      }
    //  this.isLoading=false;
    }
  
    for(let i = 0;i < this.approvedFilters.length;i++) {
      if(i != selectedActiveIndex) {
        this.approvedFilters[i].active= false;
      }
      console.log(this.approvedFilters);
    }
  
   this.selectedPlanApproveFilter = {
      "field" :"plan_approved_not_approved",
      "predicate":"equal",
      "value":approvedFilter.tag,
      "title":approvedFilter.label
    };
    console.log(approvedFilter.tag);
  this.getApplicants();
  this.show=true;

   }
 onPreviewClick = (id: any) => {
   if(this.title!=null){
    this.title = this.title + " | " +this.selectedPlanApproveFilter.title;
    this.router.navigate(['/scrutiny-preview'], {queryParams:{application_id:id,title: this.title}});
   }else{
    this.router.navigate(['/scrutiny-preview'], {queryParams:{application_id:id}});
   }
  
  console.log("query",this.selectedDateFilter)
 }


 

refreshClick = () => {
  let currentComponent = this;
  this.isRefreshing = true;
  setTimeout(()=>{
    currentComponent.isRefreshing = false;
  }, 3000)
  this.applicationService.fetchApplicants();
 
}

public showActivityLog = () => {
  this.router.navigate(['/activity-log']);
  }
}
