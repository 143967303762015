import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { ApplicationService } from 'src/services/application.service';
import { MatTableDataSource } from '@angular/material/table';
import { Router, ActivatedRoute } from '@angular/router';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { UserService } from 'src/services/user-service/user.service';
import { StrorageService } from 'src/services/strorage.service';
import { RoleConfigService } from 'src/app/shared/config/role/role.config.service';
import { PageEvent } from '@angular/material/paginator';
import { faSync } from '@fortawesome/free-solid-svg-icons';
import { Location } from '@angular/common';

@Component({
  selector: 'app-cdinfo-report-applicant',
  templateUrl: './cdinfo-report-applicant.component.html',
  styleUrls: ['./cdinfo-report-applicant.component.scss']
})
export class CdinfoReportApplicantComponent implements OnInit {

   public dataSource = new MatTableDataSource();

   public displayedColumns: string[] = ['siNo','applicationNo', 'applicantName','l', 'b', 'propertyType','noOfFloor','sitalArea','plinthArea', 'totalBuildUpArea','north','east','west','south','structureType','createdAt','processedByName','action'];

  public faSync = faSync;
  public isRefreshing = false;
  public userName = "Username";
  report_id: any;
  public pagesize = 5;
  public currentPage = 0;
  public show = false;
  public totalSize = 0;
  user: any;
  public cdInfoReportBatchName: any;
  public searchKeyword: any;

  constructor(private router: Router,private cdr:ChangeDetectorRef, public dialog: MatDialog, private userService: UserService,
    private applicationService: ApplicationService, private roleConfig: RoleConfigService,
    private strorageService:StrorageService,private route: ActivatedRoute, private _location: Location) { 
    this.report_id = this.route.snapshot.queryParams['reportId'];
    this.cdInfoReportBatchName = this.route.snapshot.queryParams['batchName']
  }

  ngOnInit(): void {
    this.user = this.strorageService.getUser();
    this.userName=this.user['firstName'];
    this.getCdViewFeederApplicants();
  }

  getCdViewFeederApplicants(): void {
    this.applicationService.fetchCdFeederReportApplicantsByReportId(this.report_id, 
      this.currentPage, this.pagesize, this.searchKeyword).subscribe(res =>{
      this.refreshReportBatchesDataTable(res);
    })
  }

  goBack(): void {
    this._location.back();
  }

  refreshClick(): void {
    this.currentPage = 0;
    this.pagesize = 5;
    this.getCdViewFeederApplicants();
  }
  
  public getCdFeederReportApplicantsPaginatorData(event: PageEvent) {
    this.currentPage = event.pageIndex;
    this.pagesize = event.pageSize;
    this.getCdViewFeederApplicants();
  }
  
  searchApplicant(){
    this.currentPage = 0;
    this.pagesize = 5;
    this.getCdViewFeederApplicants();
  }

  onClear(): void {
    this.searchKeyword = null;
    this.refreshClick();
  }

  private refreshReportBatchesDataTable = (res: any) => {
    this.dataSource.data = res.data;
    this.totalSize = res.totalRecord;
    this.currentPage = (res.currentPage - 1);
    this.dataSource._updateChangeSubscription();
    this.cdr.detectChanges();
  }
}
