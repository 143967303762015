import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-residental-house',
  templateUrl: './residental-house.component.html',
  styleUrls: ['./residental-house.component.scss']
})
export class ResidentalHouseComponent implements OnInit {

  @Input() data : any;

  @Input() isEditable : any;

  public resArea : number;

  public resFloors : number;

  public resTotalarea : number;

  public siteNumber : string;

  public buildingAddress : string;

  constructor() { }

  ngOnInit(): void {
  }

  reset(){
    this.resArea = null;
    this.resFloors = null;
    this.resTotalarea = null;
  }

  getPayload(){
    let payload = {};
    payload['resArea'] = this.resArea;
    payload['resFloors'] = this.resFloors;
    payload['resTotalarea'] = this.resTotalarea;
    return payload;
  }

  ngOnChanges(data): void{
    if(!this.data){
      return;
    }
    this.resArea = this.data.resArea ? this.data.resArea.toFixed(2) : this.data.resArea;
    this.resFloors = this.data.resFloors;
    this.resTotalarea = this.data.resTotalarea? this.data.resTotalarea.toFixed(2) : this.data.resTotalarea;
    this.siteNumber = this.data.surveyNumber;
    this.buildingAddress = this.data.buildingAddress;
   }


}
