import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-sector-document-tile',
  templateUrl: './sector-document-tile.component.html',
  styleUrls: ['./sector-document-tile.component.scss']
})
export class SectorDocumentTileComponent implements OnInit {

  public selectedSectorName:any;

  public selectedSectorCode:any;

  public sectors:any[] = [
    {
      name: 'Construction Program', 
      color: '#FFC000',
      code: 'construction_program'
    },
    {
      name: 'Agreement', 
      color: '#8FAADC',
      code: 'agreement'
    },
    {
      name: 'Notice to Proceed', 
      color: '#A9D18E',
      code: 'notice_to_proceed'
    },
    {
      name: 'Work Order', 
      color: '#F4B183',
      code: 'work_order'
    },
    {
      name: 'Bid Documents', 
      color: '#A6A6A6',
      code: 'bid_documents'
    }
  ];

  constructor(public activatedRoute: ActivatedRoute, private router: Router) { 
    this.selectedSectorName = this.activatedRoute.snapshot.queryParams["selectedSector"];
    this.selectedSectorCode = this.activatedRoute.snapshot.queryParams["sectorCode"];
  }

  ngOnInit(): void {
  }

  onSectorTileClicked = (item: any) => {
    let filePath:any = item.code+"/"+this.selectedSectorCode+"_"+item.code;
    window.open(environment.BASE_URL+'layout_formation/'+filePath+'.pdf');
  }

}
