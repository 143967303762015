<div class="content-area pt-5">
  <div class="container">
    <div class="row pb-2">
      <div class="col-sm-3 d-flex align-items-center justify-content-end">
        <button mat-button class="btn btn-primary" (click)="showActivityLog()">Activity Log</button>
      </div>
      <div class="col-sm-6">
        <h1 class="text-center">PDF Upload</h1>
      </div>
      <div class="w-100"></div>

    </div>
    <div class="row mt-5">
      <div class="col-sm-4 d-flex justify-content-end"><h2 class="mt-2">Upload Progress Map Pdf :</h2></div>
      <div class="col-sm-4 d-flex justify-content-start"><label class="ele-vertical-center font-times">JCC_DP_</label><div class="col-sm-3 ">
        <mat-form-field appearance="outline" >
            <input matInput [(ngModel)]="dpDate" class="h-50" (dateChange)="updateDpDate($event)" [max]="toDate ? toDate :maxDate" [matDatepicker]="fromDatePicker">
            <mat-datepicker-toggle matSuffix [for]="fromDatePicker"></mat-datepicker-toggle>
            <mat-datepicker #fromDatePicker ></mat-datepicker>
          </mat-form-field>
        </div>
  </div>
      <div class="col-sm-4 d-flex justify-content-start">
          <input class="ele-vertical-center w-75" type="file" id="pdf"  name="pdf" (change)="onFileChanged($event)"/>
          <fa-icon [icon]="faTrash" class="delete-btn" (click)="dismissIconClick('pdf')"></fa-icon>
      </div>
    </div>
    <div class="row mt-5 d-flex justify-content-center">
      <div class="col-sm-4 d-flex justify-content-center"><button class="save-btn fw-bold ml-4" (click)="upload()"  type="submit">Upload & Save</button></div>
    </div>
  </div>
</div>