<app-top-bar selectedTab="home"></app-top-bar>
<app-header></app-header><div class="w-100 mt-2"></div>
<div class="heading-color d-flex justify-content-center"><h1 class="bold-heading">{{heading}}</h1></div>
<div class="d-flex justify-content-center"><h1 class="bold-heading">{{subHeading}}</h1></div>
<div class="row">
    <div class="col-sm-8">
        <app-work-type-chart></app-work-type-chart>
    </div>
    <div class="col-sm-4">
        <app-sector-chart></app-sector-chart>
    </div>
</div>