import {
    HttpHandler,
    HttpRequest,
    HttpEvent,
    HttpErrorResponse,
    HttpInterceptor
  } from "@angular/common/http";
import { Observable, throwError } from "rxjs";
import { catchError, finalize } from "rxjs/operators";
import { ErrorDialogService } from "../../shared/error/error-dialog.service";
import { ApiLoaderService } from "../../shared/loading/api-loader.service";
import { Injectable } from "@angular/core";

@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {
  constructor(
    private apiLoaderService: ApiLoaderService
  ) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    console.log(request.url);
    if(!request.url.includes('/sectorCharts')) {
      this.apiLoaderService.show();
    }
    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        this.apiLoaderService.hide();
        let err = new Error();
        if(error.error) {
          err.message = error.error.message;
        }
        return throwError(err);
      }),
      finalize(() => {
        console.log("http-interceptor finalize()");
        this.apiLoaderService.hide();
      })
    ) as Observable<HttpEvent<any>>;
  }
}