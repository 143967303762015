<div class="row content-area">
  <div class="col-sm-11 title">
    <h1 class="text-center heading-align" style="margin-bottom: 7%;">DAP - Generation - Civil Works - Workbench </h1>
  </div>
  <div class=" col-sm-1">
    <fa-icon [icon]="faTimes" class="close-icon" (click)="cancelClick()"></fa-icon>
  </div>
  <div class="col-sm-11 mb-4">
    <h1 class="text-center heading-align mb-4">{{data.heading}}</h1>
  </div>
  <div class="col-6">
    <div class="row">
      <div class="col-sm-11 offset-sm-1">
        <div class="col-sm-4">
          <div class="row">
            <div class="col-sm-8 d-flex justify-content-end">
              <h3>Task Item: <span class="text-danger">*</span></h3>
            </div>
            <div class="col-sm-4">
              <mat-form-field appearance="fill">
                <mat-select id="taskItem" name="taskItem" [(ngModel)]="selectedTaskId" [disabled]="!sector.checked">
                  <mat-option *ngFor="let task of taskList;  let i = index;" [value]="task.taskId"
                    (click)="onTaskChanged(task, selectedTaskId)">
                    {{task.taskName}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </div>
        </div>
        <div class="col-sm-8">
          <h3>Scope: <span class="text-danger">*</span>&nbsp;{{scope +' '+ unit}}</h3>
        </div>        
      </div>
    </div>
  </div>
  <div class="col-6">
    <div class="row">
      <div class="col-sm-4">
        <h3>Work: <span class="text-danger">*</span></h3>
      </div>
      <div class="col0sm-8" >
        <div *ngFor="let workType of workTypeList">
          <mat-checkbox [(ngModel)]="workType.checked" [disabled]="!workType.showWorkType" (change)="onWorkTypeSelected(workType)">{{workType.workName}}</mat-checkbox>
        </div>
      </div>
    </div>
  </div>
</div>
<br>
<br>
<div class="col-md-2 offset-md-5 button-row">
  <input type="button" class="btn btn-primary" value="save" mat-dialog-close (click)="onSave()"/>
</div>