<div class="content-area">
  <div class="col-sm-10 offset-sm-1">
    <app-top-bar selectedTab="home"></app-top-bar>
    <app-header></app-header>
    <app-headline-news></app-headline-news>
    <app-map-image></app-map-image>
    <app-content-header></app-content-header>

    <div class="row">
      <div class="col-sm-10 offset-md-1">
        <div class="row profile-row">

          <div class="col-sm-4" *ngFor="let profile of profileContent; let i = index">
            <app-profile-card [name]="profile.name" [imagePath]="profile.imagePath" [content]="profile.content">
            </app-profile-card>
          </div>

        </div>
        <mat-card class="committee-card">
          <mat-card-content class="para">
            <div class="title">{{ 'about_commitee.title' | translate }}</div>
            <p class="content">
              {{ 'about_commitee.content' | translate }}
            </p>
          </mat-card-content>
        </mat-card>
        <app-supreme-court-judgement></app-supreme-court-judgement>

        <label class="login-title">Please apply only if you have a Structure built; not if it is a vacant site</label>
        <div class="row d-flex justify-content-center " (click)="onDailyUpdatesClick()">
          <div class="mx-5 btn-jcc"> Layout Formation Daily Updates</div>
      </div>
        <div class="row login-row">
          <div class="col-sm-3">
            <app-login-button name="Committee" color="#E95A4C" [loginPath]="'/committee-login'"></app-login-button>
          </div>
          <div class="col-sm-3">
            <app-login-button name="Scrutiny" color="#448BDD" [loginPath]="'/scrutiny-login'"
              [disabled]="!enableScrutinyUser"></app-login-button>
          </div>
          <!-- <div class="col-sm-3">
      <app-login-button name="Scrutiny Admin" color="#3CB371" [loginPath]="'/scrutiny-admin-login'"></app-login-button> 
    </div> -->
          <div class="col-sm-3">
            <app-login-button name="Online Application" color="#34BFB7" [loginPath]="'/online-applicant-login'"></app-login-button>
          </div>

          <div class="col-sm-3">
            <app-login-button name="Helpdesk" color="#F2A36C" [loginPath]="'/helpdesk-login'"
              [disabled]="!enableHelpDeskUser"></app-login-button>
          </div>
        </div>
        <div class="row login-row">
          <div class="col-sm-3">
            <app-login-button name="Field Visit" color="#c68c53" [loginPath]="'/field-visit-login'"
              [disabled]="!enableFieldUser"></app-login-button>
          </div>
          <div class="col-sm-3">
            <app-login-button name="Adjudication" color="#6daf71"
              [loginPath]="'/adjudication-user-login'"></app-login-button>
          </div>
          <div class="col-sm-3">
            <app-login-button name="Personal Interview" color="#FF6333" [loginType]="'pi_user'"
              [loginPath]="'/generic-login'" [heading]="'Personal Interview'"></app-login-button>
          </div>
          <div class="col-sm-3">
            <app-login-button name="BDA - Commissioner" color="#D67EE1" [loginType]="'bda_commr'"
              [loginPath]="'/generic-login'" [heading]="'BDA Commissioner'"></app-login-button>
          </div>
        </div>
        <div class="row login-column ">
          <div class="col-sm-3">
            <app-login-button name="BDA - Secretary" color="#938695" [loginType]="'bda_secretary'"
              [loginPath]="'/generic-login'" [heading]="'BDA Secretary'"></app-login-button>
          </div>
          <div class="col-sm-3">
            <app-login-button name="BDA - DS" color="#59A6EC" [loginType]="'bda_ds'" [loginPath]="'/generic-login'"
              [heading]="'BDA DS'"></app-login-button>
          </div>
          <div class="col-sm-3">
            <app-login-button name="BDA - EM" color="#16058A" [loginType]="'bda_em'" [loginPath]="'/bda_em'"
              [heading]="'BDA-EM'"></app-login-button>
          </div>
          <div class="col-sm-3">
            <app-login-button name="Award Scrutiny User" color="#FC783A" [loginType]="'award_scrutiny_user'"
              [loginPath]="'/generic-login'" [heading]="'Award Scrutiny User'"></app-login-button>
          </div>

        </div>
        <div class="row login-column ">
          <div class="col-sm-3">
            <app-login-button name="RC & Register" color="#F85D8C" [loginType]="'rc_register_user'"
              [loginPath]="'/generic-login'" [heading]="'RC & Register'"></app-login-button>
          </div>
          <div class="col-sm-3">
            <app-login-button name="RC-SD Data" color="#C7BE7A" [loginType]="'rc_sd_data_verifier'"
              [loginPath]="'/generic-login'" [heading]="'RC - SD Data Validation'"></app-login-button>
          </div>
          <div class="col-sm-3">
            <app-login-button name="RC-CD Data" color="#B9A6D1" [loginType]="'rc_cd_data_verifier'"
              [loginPath]="'/generic-login'" [heading]="'RC - CD Data Validation'"></app-login-button>
          </div>
          <div class="col-sm-3">
            <app-login-button name="RC Disbursement" color="#2CEB8B" [loginType]="'rc_disbursement'"
              [loginPath]="'/rc-disbursement-login'" [heading]="'RC Disbursement'"></app-login-button>
          </div>
        </div>
        <div class="row login-column ">
        <div class="col-sm-3">
          <app-login-button name="DAP Generation Module" color="#808080" [loginType]="'dpa_user'"
            [loginPath]="'/generic-login'" [heading]="'DAP Generation'"></app-login-button>
        </div>
        <div class="col-sm-3">
          <app-login-button name="SECON" color="#FF6333" [loginType]="'secon_user'"
            [loginPath]="'/generic-login'" [heading]="'SECON'"></app-login-button>
        </div>
        <div class="col-sm-3">
          <app-login-button name="DMS" color="#204C7C" [loginType]="'dms_user'"
            [loginPath]="'/generic-login'" [heading]="'DMS'"></app-login-button>
        </div>
        </div>
      </div>
    </div>
    <app-footer></app-footer>
  </div>
</div>