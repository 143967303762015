<div class="row content-area">
    <div class="col-sm-11 title">
      Application Details
    </div>
    <div class=" col-sm-1" >
        <fa-icon [icon]="faTimes" class="close-icon"  (click)="cancelClick()"></fa-icon>
    </div>
 
    <div class="label col-sm-12"  *ngIf = "applicationData">
 
       
        <div class="sub-title">
            Basic Info
         </div>
         <div class="row" >
             <div class="col-sm-8 " >
                 <label >1. Name of the Village: </label>
             </div>
             <div class="col-sm-4">
                 <label >{{applicationData.villageName}}</label>
               </div>
         </div>
         <div class="row" >
             <div class="col-sm-8 " >
                 <label >2. Survey No: </label>
             </div>
             <div class="col-sm-4">
                 <label >{{applicationData.surveyNumber}}</label>
               </div>
         </div>
         <div class="row" >
             <div class="col-sm-8 " >
                 <label >3. Extent of land as per RTC: </label>
             </div>
             <div class="col-sm-4">
                 <label >{{applicationData.extendLandPerRTC}}</label>
               </div>
         </div>
         <div class="row" >
             <div class="col-sm-8 " >
                 <label >3a. Total Area : </label>
             </div>
         </div>
         <div class="row" >
            <div class="col-sm-8 " >
                <label style="margin-left: 4%;">Acre :  </label>
            </div>
            <div class="col-sm-4">
                <label >{{applicationData.landRtcTotalAreaAcre}}</label>
            </div>
        </div>
        <div class="row" >
            <div class="col-sm-8 " >
                <label style="margin-left: 4%;">Gunta :  </label>
            </div>
            <div class="col-sm-4">
                <label >{{applicationData.landRtcTotalAreaGunta}}</label>
            </div>
        </div>
        <div class="row" >
            <div class="col-sm-8 " >
                <label style="margin-left: 4%;">Fraction :  </label>
            </div>
            <div class="col-sm-4">
                <label >{{applicationData.landRtcTotalAreaDecimalValue}}</label>
            </div>
        </div>
        <div class="row" >
            <div class="col-sm-8 " >
                <label style="margin-left: 4%;">Total (in Acres) : </label>
            </div>
            <div class="col-sm-4">
                <label >{{applicationData.landRtcTotalAreaValue}}</label>
            </div>
        </div>
         <div class="row" >
             <div class="col-sm-8 " >
                 <label >3b. Kharab Area:  </label>
             </div>
         </div>
         <div class="row" >
            <div class="col-sm-8 " >
                <label style="margin-left: 4%;">Acre :  </label>
            </div>
            <div class="col-sm-4">
                <label >{{applicationData.landRtcKharabAreaAcre}}</label>
            </div>
        </div>
        <div class="row" >
            <div class="col-sm-8 " >
                <label style="margin-left: 4%;">Gunta :  </label>
            </div>
            <div class="col-sm-4">
                <label >{{applicationData.landRtcKharabAreaGunta}}</label>
            </div>
        </div>
        <div class="row" >
            <div class="col-sm-8 " >
                <label style="margin-left: 4%;">Fraction :  </label>
            </div>
            <div class="col-sm-4">
                <label >{{applicationData.landRtcKharabAreaDecimalValue}}</label>
            </div>
        </div>
        <div class="row" >
            <div class="col-sm-8 " >
                <label style="margin-left: 4%;">Total (in Acres) : </label>
            </div>
            <div class="col-sm-4">
                <label >{{applicationData.landRtcKharabAreaValue}}</label>
            </div>
        </div>
         <div class="row" >
             <div class="col-sm-8 " >
                 <label >3c. Remaining Extent : </label>
             </div>
         </div>
         <div class="row" >
            <div class="col-sm-8 " >
                <label style="margin-left: 4%;">Acre :  </label>
            </div>
            <div class="col-sm-4">
                <label >{{applicationData.landRtcRemainingAcre}}</label>
            </div>
        </div>
        <div class="row" >
            <div class="col-sm-8 " >
                <label style="margin-left: 4%;">Gunta :  </label>
            </div>
            <div class="col-sm-4">
                <label >{{applicationData.landRtcRemainingGunta}}</label>
            </div>
        </div>
        <div class="row" >
            <div class="col-sm-8 " >
                <label style="margin-left: 4%;">Fraction :  </label>
            </div>
            <div class="col-sm-4">
                <label >{{applicationData.landRtcRemainingDecimalValue}}</label>
            </div>
        </div>
        <div class="row" >
            <div class="col-sm-8 " >
                <label style="margin-left: 4%;">Total (in Acres) : </label>
            </div>
            <div class="col-sm-4">
                <label >{{applicationData.landRtcRemainingValue}}</label>
            </div>
        </div>
        <div class="row" >
            <div class="col-sm-8 " >
                <label >4. Extent in Final Notification(in acres) : </label>
            </div>
        </div>
        <div class="row" >
           <div class="col-sm-8 " >
               <label style="margin-left: 4%;">Acre :  </label>
           </div>
           <div class="col-sm-4">
               <label >{{applicationData.extentFinalNotificationAcre}}</label>
           </div>
       </div>
       <div class="row" >
           <div class="col-sm-8 " >
               <label style="margin-left: 4%;">Gunta :  </label>
           </div>
           <div class="col-sm-4">
               <label >{{applicationData.extentFinalNotificationGunta}}</label>
           </div>
       </div>
       <div class="row" >
        <div class="col-sm-8 " >
            <label style="margin-left: 4%;">Fraction :  </label>
        </div>
        <div class="col-sm-4">
            <label >{{applicationData.extentFinalNotificationDecimalValue}}</label>
        </div>
    </div>
       <div class="row" >
           <div class="col-sm-8 " >
               <label style="margin-left: 4%;">Total (in Acres) : </label>
           </div>
           <div class="col-sm-4">
               <label >{{applicationData.extentFinalNotificationValue}}</label>
           </div>
       </div>
       <div class="row" >
        <div class="col-sm-8 " >
            <label style="margin-left: 4%;">Comment : </label>
        </div>
        <div class="col-sm-4">
            <label >{{applicationData.finalNotificationComment}}</label>
        </div>
    </div>
       <div class="row" >
        <div class="col-sm-8 " >
            <label >5. Difference in extent in Column No. 3 and 4 (in acres) : </label>
        </div>
    </div>
    <div class="row" >
       <div class="col-sm-8 " >
           <label style="margin-left: 4%;">Acre :  </label>
       </div>
       <div class="col-sm-4">
           <label >{{applicationData.extentDifferenceAcre}}</label>
       </div>
   </div>
   <div class="row" >
       <div class="col-sm-8 " >
           <label style="margin-left: 4%;">Gunta :  </label>
       </div>
       <div class="col-sm-4">
           <label >{{applicationData.extentDifferenceGunta}}</label>
       </div>
   </div>
    <div class="row" >
    <div class="col-sm-8 " >
        <label style="margin-left: 4%;">Fraction :  </label>
    </div>
    <div class="col-sm-4">
        <label >{{applicationData.extentDifferenceDecimalValue}}</label>
    </div>
    </div>
   <div class="row" >
       <div class="col-sm-8 " >
           <label style="margin-left: 4%;">Total (in Acres) : </label>
       </div>
       <div class="col-sm-4">
           <label >{{applicationData.extentDifferenceValue}}</label>
       </div>
   </div>
   <div class="row" >
    <div class="col-sm-8 " >
        <label style="margin-left: 4%;">Comment : </label>
    </div>
    <div class="col-sm-4">
        <label >{{applicationData.extentComment}}</label>
    </div>
</div>
 
         <div class="sub-title">
             Award Details
          </div>
          
         <div class="row" >
             <div class="col-sm-8 " >
                 <label >6. Award Passed on Government Land :</label>
             </div>
             <div class="col-sm-4">
                 <label >{{applicationData.govLandAwardPassed}}</label>
               </div>
         </div>
         <div class="row" >
             <div class="col-sm-8 " >
                 <label >6 a. If Yes</label>
             </div>
             <div class="col-sm-4">
                 <label >{{ applicationData.govLandAwardPassedYesValue}}</label>
               </div>
         </div>
         <div class="row" >
             <div class="col-sm-8 " >
                 <label >7 . Whether Revenue Layout if formed:</label>
             </div>
             <div class="col-sm-4">
                 <label >{{ applicationData.revenueLayoutFormed}}</label>
               </div>
         </div>
         <div class="row" >
             <div class="col-sm-8 " >
                 <label >7 a . If layout formed Award in favor of :</label>
             </div>
             <div class="col-sm-4">
                 <label >{{applicationData.awardInFavorOfValue}}</label>
               </div>
         </div>
         <div class="row" >
            <div class="col-sm-8 " >
                <label >7 b . Whether it is Converted :</label>
            </div>
            <div class="col-sm-4">
                <label >{{applicationData.isConverted}}</label>
              </div>
        </div>
         <div class="row" >
             <div class="col-sm-8 " >
                 <label >7 c . Whether applications have been received for allotment of alternate site :</label>
             </div>
             <div class="col-sm-4">
                 <label >{{applicationData.haveAllotmentOfAlternateSite}}</label>
               </div>
         </div>
         <div class="row" >
             <div class="col-sm-8 " >
                 <label >7 d . If Yes (number of Applications) : </label>
             </div>
             <div class="col-sm-4">
                 <label >{{applicationData.noOfApplications}}</label>
               </div>
         </div>
         <div class="row" >
             <div class="col-sm-8 " >
                 <label >8. Is  Award passed in the Name of Khatedar notified in Final Notification : </label>
             </div>
             <div class="col-sm-4">
                 <label >{{applicationData.khatedarNotifiedInFinalNotification}}</label>
               </div>
         </div>
         <div class="row" >
             <div class="col-sm-8 " >
                 <label >8 a. If No, In whose Name : </label>
             </div>
             <div class="col-sm-4">
                 <label >{{applicationData.khatedarNotifiedInFinalNotificationValueNo}}</label>
                 </div>
         </div>
         <div class="row" >
             <div class="col-sm-8 " >
                 <label >9 . Whether Award has been Approved: </label>
             </div>
             <div class="col-sm-4">
                 <label >{{applicationData.awardHasBeenApproved}}</label>
               </div>
         </div>
         <div class="row" >
             <div class="col-sm-8 " >
                 <label >9 a. If Yes </label>
             </div>
             <div class="col-sm-4">
                 <label >{{applicationData.awardApprovedValueYes}}</label>
                 </div>
         </div>
         <div class="row" >
             <div class="col-sm-8 " >
                 <label >9 (b).  Quantum of Compensation : </label>
             </div>
             <div class="col-sm-4">
                <label >{{applicationData.compensationAmount}}</label>
            </div>
         </div>
         <div class="row" >
             <div class="col-sm-8 " >
                 <label >9 (c).  Extent 60:40 : </label>
             </div>
             <div class="col-sm-4">
                 <label >{{applicationData.extentRatio}}</label>
                 </div>
         </div>
 
         <div class="row" >
             <div class="col-sm-8 " >
                 <label >9(d). Whether 12(2) has been issued to Land Owner:  </label>
             </div>
             <div class="col-sm-4">
                 <label >{{applicationData.issuedToLandOwner}}</label>
                 </div>
         </div>
         <div class="sub-title">
             Structure Details
          </div>
          
         <div class="row" >
             <div class="col-sm-8 " >
                 <label >10. Whether land has been handed over to Engineering department :  </label>
             </div>
             <div class="col-sm-4">
                 <label >{{applicationData.handOverToDepartment}}</label>
                 </div>
         </div>
        
        
         <div class="row" >
             <div class="col-sm-8 " >
                 <label >10(a). Do buildings exist on the land :</label>
             </div>
             <div class="col-sm-4">
                 <label >{{applicationData.landStructureExist}}</label>
                 </div>
         </div>
        
         <div class="row" >
             <div class="col-sm-8 " >
                 <label >10 (b). If Yes, No of buildings as per 4(1) Mahazar:  </label>
             </div>
             <div class="col-sm-4">
                 <label >{{applicationData.noOfStructureYesValue}}</label>
               </div>
         </div>
        
         <div class="row" >
             <div class="col-sm-8 " >
                 <label >10 (c). No. of buildings as per 6(1) Mahazar :</label>
             </div>
             <div class="col-sm-4">
                 <label >{{applicationData.noOfStructureAsPerMahazar}}</label>
               </div>
         </div>
         <div class="row" >
             <div class="col-sm-8 " >
                 <label >10 (d). No. of buildings as per award sketch of surveyor: </label>
             </div>
             <div class="col-sm-4">
                 <label>{{applicationData.noOfStructureAsPerAwardSketch}}</label>
               </div>
         </div>
         <div class="row" >
             <div class="col-sm-8 " >
                 <label >10 (e). No. of buildings discussed in the award :</label>
             </div>
             <div class="col-sm-4">
                 <label>{{applicationData.noOfStructureDiscussedInAward}}</label>
               </div>
         </div>
         <div class="row" >
             <div class="col-sm-8 " >
                 <label>10 (f). Has building valuation been done: </label>
             </div>
             <div class="col-sm-4">
                 <label >{{applicationData.buildingValuationDone}}</label>
             </div>
         </div>
         <div class="row" >
             <div class="col-sm-8 " >
                 <label >10 (g). Whether award includes payment of compensation for buildings :</label>
             </div>
             <div class="col-sm-4">
                 <label >{{applicationData.awardCompensationForStructures}}</label>
               </div>
         </div>
         <div class="row" >
             <div class="col-sm-8 " >
                 <label >10 (h). Whether possession of buildings have been taken :</label>
             </div>
             <div class="col-sm-4">
                 <label >{{applicationData.structureHasBeenTaken}}</label>
               </div>
         </div>
         <div class="row" >
             <div class="col-sm-8 " >
                 <label >10 (i). Whether possession of buildings have been over to Engineering Department : </label>
             </div>
             <div class="col-sm-4">
                 <label >{{applicationData.possessionOfStructureHandToEnggDept}}</label>
               </div>
         </div>
         <div class="row" >
             <div class="col-sm-8 " >
                 <label > 10 (j). Whether any applications filed before JCC  : </label>
             </div>
             <div class="col-sm-4">
                 <label >{{applicationData.applicationsFilled}}</label>
               </div>
         </div>
         <div class="row" >
             <div class="col-sm-8 " >
                 <label >10 (k). If Yes, number: </label>
             </div>
             <div class="col-sm-4">
                 <label >{{applicationData.applicationsFilledYesValue}}</label>
               </div>
         </div>
         <div class="row" >
             <div class="col-sm-8 " >
                 <label >10(l). Whether under 40/60 scheme,compension for the foot print of the structure has been omitted:</label>
             </div>
             <div class="col-sm-4">
                 <label >{{applicationData.compensationForFootprintOmitted}}</label>
               </div>
         </div>
         <div class="row" >
             <div class="col-sm-8 " >
                 <label >11. Whether Entitlement Certificate issued: </label>
             </div>
             <div class="col-sm-4">
                 <label >{{applicationData.entitlementCertificateIssued}}</label>
               </div>
         </div>
         <div class="row" >
             <div class="col-sm-8 " >
                 <label >11 a  . If  Yes :  </label>
             </div>
             <div class="col-sm-4">
                 <label >{{applicationData.entitlementCertificateIssuedValueYes}}</label>
               </div>
         </div>
         <div class="row" >
            <div class="col-sm-8 " >
                <label >11 b . Whether EC issued by SLAO is Regular : </label>
            </div>
            <div class="col-sm-4">
                <label >{{applicationData.ifEcRegular}}</label>
              </div>
        </div>
        <div class="row" >
            <div class="col-sm-8 " >
                <label >11 c  . Irregularity Explanation :  </label>
            </div>
            <div class="col-sm-4">
                <label >{{applicationData.explainIrregularity}}</label>
              </div>
        </div>
         <div class="row" >
            <div class="col-sm-8 " >
                <label >11 d . If so, Extent Indicated in EC : </label>
            </div>
        </div>
             <div class="row" >
                <div class="col-sm-8 " >
                    <label style="margin-left: 4%;">Acre :  </label>
                </div>
                <div class="col-sm-4">
                    <label >{{applicationData.entitlementExtentAcre}}</label>
                </div>
            </div>
            <div class="row" >
                <div class="col-sm-8 " >
                    <label style="margin-left: 4%;">Gunta :  </label>
                </div>
                <div class="col-sm-4">
                    <label >{{applicationData.entitlementExtentGunta}}</label>
                </div>
            </div>
            <div class="row" >
                <div class="col-sm-8 " >
                    <label style="margin-left: 4%;">Fraction :  </label>
                </div>
                <div class="col-sm-4">
                    <label >{{applicationData.entitlementExtentDecimalValue}}</label>
                </div>
                </div>
            <div class="row" >
                <div class="col-sm-8 " >
                    <label style="margin-left: 4%;">Total (in Acres) : </label>
                </div>
                <div class="col-sm-4">
                    <label >{{applicationData.entitlementExtentValue}}</label>
                </div>
            </div>
            <div class="row" >
                <div class="col-sm-8 " >
                    <label style="margin-left: 4%;">Comment : </label>
                </div>
                <div class="col-sm-4">
                    <label >{{applicationData.sdComment}}</label>
                </div>
             </div>
         <div class="sub-title">
            Award Analysis Details
         </div>
         <div class="row" >
            <div class="col-sm-8 " >
                <label >12. Is the Award regular :   </label>
            </div>
            <div class="col-sm-4">
                <fa-icon [icon]="faCheck" class="checked" [hidden]="!applicationData || applicationData.isAwardRegular == 'No'"></fa-icon>
                <fa-icon [icon]="faTimes" class="unchecked" [hidden]="!applicationData || applicationData.isAwardRegular == 'Yes'"></fa-icon>
              </div>
        </div>
        <div class="row" >
            <div class="col-sm-8 " >
                <label >A. Violation of Supreme Court Direction</label>
            </div>
            <div class="col-sm-4">
                <fa-icon [icon]="faCheck" class="checked" [hidden]="!applicationData || applicationData.violationOfSupremeCourtA == null"></fa-icon>
                <fa-icon [icon]="faTimes" class="unchecked" [hidden]="!applicationData || applicationData.violationOfSupremeCourtA == 'true'"></fa-icon>
              </div>
        </div>
        <div class="row" >
            <div class="col-sm-8 " >
                <label > 1.  Award passed for constructions put up after 3-8-2018.Para 6 of Order dt. 3/12/20  </label>
            </div>
            <div class="col-sm-4">
                <fa-icon [icon]="faCheck" class="checked" [hidden]="!applicationData || applicationData.awardPassedForConstructionB == 'false' || !applicationData.awardPassedForConstructionB"></fa-icon>
                <fa-icon [icon]="faTimes" class="unchecked" [hidden]="!applicationData || applicationData.awardPassedForConstructionB == 'true'"></fa-icon>
              </div>
        </div>
        <div class="row" >
            <div class="col-sm-8 " >
                <label > 2. Buildings constructed after 3/8/2018 which are mentioned  
                    in survey & engineer report and sketch,<br> but not discussed  
                    in the award, para of 14-7-2021 Order.</label>
            </div>
            <div class="col-sm-4">
                <fa-icon [icon]="faCheck" class="checked" [hidden]="!applicationData || applicationData.buildingConstructedAfterC == 'false' || !applicationData.buildingConstructedAfterC"></fa-icon>
                <fa-icon [icon]="faTimes" class="unchecked" [hidden]="!applicationData || applicationData.buildingConstructedAfterC == 'true'"></fa-icon>              </div>
        </div>
        <div class="row" >
            <div class="col-sm-8 " >
                <label >3. Award passed in favour of Kathedar/GPA 60:40 scheme  
                    offered to him/her<br> even though revenue layout formed, 
                    Para 10 & 11 of the Order dt.5/5/2021 </label>
            </div>
            <div class="col-sm-4">
                <fa-icon [icon]="faCheck" class="checked" [hidden]="!applicationData || applicationData.awardPassedInFavourKatheadarD == 'false' || !applicationData.awardPassedInFavourKatheadarD"></fa-icon>
                <fa-icon [icon]="faTimes" class="unchecked" [hidden]="!applicationData || applicationData.awardPassedInFavourKatheadarD == 'true'"></fa-icon>
              </div>
        </div>
        <div class="row" >
            <div class="col-sm-8 " >
                <label > 4. Award passed in respect of buildings where application 
                    submitted to JCC.</label>
            </div>
            <div class="col-sm-4">
                <fa-icon [icon]="faCheck" class="checked" [hidden]="!applicationData || applicationData.awardPassedInApplicationSubmittedE == 'false' || !applicationData.awardPassedInApplicationSubmittedE"></fa-icon>
                <fa-icon [icon]="faTimes" class="unchecked" [hidden]="!applicationData || applicationData.awardPassedInApplicationSubmittedE == 'true'"></fa-icon>
              </div>
        </div>
        <div class="row" >
            <div class="col-sm-8 " >
                <label >5. Award passed in respect of buildings where  application not
                    submitted to JCC  </label>
            </div>
            <div class="col-sm-4">
                <fa-icon [icon]="faCheck" class="checked" [hidden]="!applicationData || applicationData.awardPassedInApplicationNotSubmittedF == 'false' || !applicationData.awardPassedInApplicationNotSubmittedF"></fa-icon>
                <fa-icon [icon]="faTimes" class="unchecked" [hidden]="!applicationData || applicationData.awardPassedInApplicationNotSubmittedF == 'true' "></fa-icon>
              </div>
        </div>
        <div class="row" >
            <div class="col-sm-8 " >
                <label >6. Buildings constructed in the acquired area which are mentioned in Surveyor and Engineer
                    <br> report, but not discussed in the Award.</label>
            </div>
            <div class="col-sm-4">
                <fa-icon [icon]="faCheck" class="checked" [hidden]="!applicationData || applicationData.awardNotDiscussedMentionedReportG == 'false' || !applicationData.awardNotDiscussedMentionedReportG"></fa-icon>
                <fa-icon [icon]="faTimes" class="unchecked" [hidden]="!applicationData || applicationData.awardNotDiscussedMentionedReportG == 'true' "></fa-icon>
              </div>
        </div>
        <div class="row" >
            <div class="col-sm-8 " >
                <label >B. Violation of LAQ Act</label>
            </div>
            <div class="col-sm-4">
                <fa-icon [icon]="faCheck" class="checked" [hidden]="!applicationData || applicationData.violationOfLaqG == 'false' || !applicationData.violationOfLaqG"></fa-icon>
                <fa-icon [icon]="faTimes" class="unchecked" [hidden]="!applicationData || applicationData.violationOfLaqG == 'true'"></fa-icon>
              </div>
        </div>
        <div class="row" >
            <div class="col-sm-8 " >
                <label >1.  Award passed for Govt. land   </label>
            </div>
            <div class="col-sm-4">
                <fa-icon [icon]="faCheck" class="checked" [hidden]="!applicationData || applicationData.awardPassedGovtLandH == 'false' || !applicationData.awardPassedGovtLandH"></fa-icon>
                <fa-icon [icon]="faTimes" class="unchecked" [hidden]="!applicationData || applicationData.awardPassedGovtLandH == 'true'"></fa-icon>
              </div>
        </div>
        <div class="row" >
            <div class="col-sm-8 " >
                <label > (a). Award passed for Kharab A & B lands.</label>
            </div>
            <div class="col-sm-4">
                <fa-icon [icon]="faCheck" class="checked" [hidden]="!applicationData || applicationData.awardPassedKharabLandI == 'false' || !applicationData.awardPassedKharabLandI"></fa-icon>
                <fa-icon [icon]="faTimes" class="unchecked" [hidden]="!applicationData || applicationData.awardPassedKharabLandI == 'true'"></fa-icon>
              </div>
        </div>
        <div class="row" >
            <div class="col-sm-8 " >
                <label > (b). Award passed for Gomal/ Muffath kaval/ Inam Land. </label>
            </div>
            <div class="col-sm-4">
                <fa-icon [icon]="faCheck" class="checked" [hidden]="!applicationData || applicationData.awardPassedGomalJ == 'false' || !applicationData.awardPassedGomalJ"></fa-icon>
                <fa-icon [icon]="faTimes" class="unchecked" [hidden]="!applicationData || applicationData.awardPassedGomalJ == 'true'"></fa-icon>
              </div>
        </div>
        <div class="row" >
            <div class="col-sm-8 " >
                <label > (c). Award passed in contravention of section 79 (a) &(b) of  
                    LRF Act, and RTC <br> in the name of Government.</label>
            </div>
            <div class="col-sm-4">
                <fa-icon [icon]="faCheck" class="checked" [hidden]="!applicationData || applicationData.awardPassedContravationK == 'false' || !applicationData.awardPassedContravationK"></fa-icon>
                <fa-icon [icon]="faTimes" class="unchecked" [hidden]="!applicationData || applicationData.awardPassedContravationK == 'true'"></fa-icon>
              </div>
        </div>
        <div class="row" >
            <div class="col-sm-8 " >
                <label >(d). Award passed for the land to Govt. under Land 
                    Revenue Act.</label>
            </div>
            <div class="col-sm-4">
                <fa-icon [icon]="faCheck" class="checked" [hidden]="!applicationData || applicationData.awardPassedRevenueActL == 'false' || !applicationData.awardPassedRevenueActL"></fa-icon>
                <fa-icon [icon]="faTimes" class="unchecked" [hidden]="!applicationData || applicationData.awardPassedRevenueActL == 'true'"></fa-icon>
              </div>
        </div>

        <div class="row" >
            <div class="col-sm-8 " >
                <label > 2. Award passed for lands where there is  in Col (3) and (9) of RTC.</label>
            </div>
            <div class="col-sm-4">
                <fa-icon [icon]="faCheck" class="checked" [hidden]="!applicationData || applicationData.awardPassedForRtcM == 'false' || !applicationData.awardPassedForRtcM"></fa-icon>
                <fa-icon [icon]="faTimes" class="unchecked" [hidden]="!applicationData || applicationData.awardPassedForRtcM == 'true'"></fa-icon>
              </div>
        </div>
            <div class="sub-title">
             Enclosure Details
          </div>
          <div class="row" >
             <div class="col-sm-8 " >
                 <label >Enclosure File Name:</label>
             </div>
             <div class="col-sm-4">
                 <a href="{{applicationData.enclosureURL}}" target="_blank">{{applicationData.documentName}}</a>
               </div>
         </div>
        <div class="sub-title">
            Comment Details
           </div>
           <div class="row" >
              <div class="col-sm-8 " >
                  <label >Comment:</label>
              </div>
              <div class="col-sm-4">
                 {{comment}}
                </div>
                </div>
    </div>
</div>

