<div class="container">
  <div>
      <app-top-bar selectedTab="home"></app-top-bar>
      <app-header></app-header>
  </div>
  <br>
  <div class="container mb-5">
    <div class="row">
      <div class="col-sm-10">
        <h1 class="text-center heading-align">Layout Formation – Civil Work – New Task Workbench</h1>
      </div>
      <div class="col-sm user-details">
        <div class="value-text">
          Welcome <br>
          {{firstName}} !
        </div>
      </div>
      <div class="container-fluid d-flex justify-content-between mt-2">
        <button mat-button class="btn-primary" (click)="clickBack()">Back</button>
        <div class="mr-5 pr-3">
          <button mat-button class="btn-primary" (click)="navigateActivityLog()">Activity Log</button>
        </div>
      </div>
      <div class="col-sm-12 mt-3 d-flex justify-content-between">
          <div>
            <mat-form-field appearance="outline">
            <mat-select id="status" name="selectedStatus" placeholder="Sector" [(ngModel)]="sectorId">
                <mat-option *ngFor="let sector of sectorList" [value]="sector.id" (click)="setSector(sector)">
                  {{sector.sectorName}}</mat-option>
              </mat-select>
              </mat-form-field>
          </div>

          <div>
            <mat-form-field appearance="outline">
              <mat-select id="status" name="selectedStatus" placeholder="Main Activity" [(ngModel)]="mainActivityId">
                <mat-option *ngFor="let mainActivity of mainActivityList" [value]="mainActivity.mainActivityId" (click)="setMainAct(mainActivity)">
                  {{mainActivity.mainActivityName}}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <div>
            <mat-form-field appearance="outline">
              <mat-select id="status" name="selectedStatus" placeholder="Size" [(ngModel)]="roadSizeId">
                <mat-option *ngFor="let roadSize of roadSizeList" [value]="roadSize.id" (click)="setRoadSize(roadSize)">
                  {{roadSize.roadSize+ " "}}{{roadSize.measurementData.name}}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
      </div>

      <div class="col-sm-12">
        <div>
          <h2>Task Item Details : </h2>
        </div>
        <div class="d-flex justify-content-between sector-item">
          <label><b>Sector : </b>{{this.sectorName}} </label>
          <label><b>Main Activity :</b> {{this.mainActivityName}}</label>
          <label><b>Road Size :</b> {{this.selectedRoadStr}}</label>
        </div>
      </div>

      <div class="col-sm-12">
        <div class="d-flex justify-content-between my-5">
          <div class="d-flex align-items-center justify-content-between text-center sector-item">
            <label>Naming Convention :</label>
            <input type="text" [(ngModel)]="sectorShortName" disabled class="form-textbox convention-text mx-3"/> <span> - </span>
            <input type="text" [(ngModel)]="mainActivityShortName" disabled class="form-textbox convention-text mx-3"/> <span> - </span>
            <input type="text" [(ngModel)]="selectedRoadSize" disabled class="form-textbox convention-text mx-3"/> <span> - </span>
            <input type="text" [(ngModel)]="totalRoadSize" class="form-textbox convention-text mx-3"/>
          </div>
          <div class="sector-item d-flex align-items-center">
            <label>Drain Length :</label>
            <input type="text" [(ngModel)]="length" class="form-textbox lenth-text mx-3"/>
          </div>
          <div class="sector-item d-flex align-items-center mr-3">
            <label class="mr-2">Unit :</label>
            <mat-form-field class="wide-convention-text" appearance="outline">
              <mat-select [(ngModel)]="unitCode" id="unit" name="unit">
                <mat-option *ngFor="let measurement of measurements" [value]="measurement.symbol">
                  {{measurement.name}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
      </div>
      <div class="col-sm-12 text-center">
        <button mat-button class="btn-primary" (click)="generatePdf()">Submit</button>
      </div>
    
      <div class="w-100"></div>
    </div>
  </div>
  <div class="d-flex justify-content-center">
    <div class="divider"></div>
  </div>
  <app-footer></app-footer>
</div>