import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { faSync } from '@fortawesome/free-solid-svg-icons';
import { ApplicationService } from 'src/services/application.service';
import { StrorageService } from 'src/services/strorage.service';

@Component({
  selector: 'app-award-scrutiny-user-dashboard',
  templateUrl: './award-scrutiny-user-dashboard.component.html',
  styleUrls: ['./award-scrutiny-user-dashboard.component.scss']
})
export class AwardScrutinyUserDashboardComponent implements OnInit {
  user: any;
  isRefreshing: boolean;
  selectVillageName: any;
  applicationsCount: any[];

  
  constructor(private applicationService: ApplicationService, 
    private cdr:ChangeDetectorRef, private router: Router,private route: ActivatedRoute,
    private storageService:StrorageService) { 
    }
  public faSync = faSync;
  public villages : any = [];
  // public statusCode:any = 'ready_for_reporting' //used to load sub status options
  public selectVillage:any;
  public statusOptionId: any;
  public totalSize: number= 0;

  public colors = ['#E74C3C', '#1ABB9C', '#3498DB', '#F39C12', '#9B59B6', '#50C1CF', '#34495E', '#E9967A', '#FA8072', '#E74C3C','#E74C3C', '#1ABB9C', '#3498DB', '#F39C12', '#9B59B6', '#50C1CF', '#34495E'];

  ngOnInit(): void {
    this.getAllVillagesAndAwardMasterApplicants();
    this.getAllAwardMasterApplicantsCount();
    this.user=this.storageService.getUser();
  }

  public getAllVillagesAndAwardMasterApplicants(){
    this.applicationService.fetchAwardMasterApplicantCountByVillage().subscribe(res =>{
      this.villages = res;
      console.log(res);
    })
  }
  public getAllAwardMasterApplicantsCount(){
    this.applicationService.fetchAllAwardMasterApplicantCount().subscribe(res =>{
      this.applicationsCount = res;
      console.log(res);
    })
  }

  onVillageChange(village:any){
    this.selectVillage=village.id;
    this.selectVillageName=village.name;
    this.router.navigate(['/award-master-dashboard'],{ queryParams: { village_id :this.selectVillage, village_name:this.selectVillageName } });
    console.log(this.selectVillage);
  }
  refreshClick(){
    let currentComponent = this;
    this.isRefreshing = true;
    setTimeout(()=>{
      currentComponent.isRefreshing = false;
    }, 3000)
    
  }

  showActivityLog=() =>{
    this.router.navigate(['/award-scrutiny-user-activity-log'],{ queryParams: { village_id :this.selectVillage} })
  }
}

