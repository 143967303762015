import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { ConfirmDialogService } from 'src/app/shared/confirm-dialog/confirm-dialog.service';
import { MessageDialogService } from 'src/app/shared/message-dialog/message-dialog.service';
import { ApplicantHelperService } from 'src/services/applicant-helper/applicant-helper.service';
import { ApplicationService } from 'src/services/application.service';
import { StrorageService } from 'src/services/strorage.service';

@Component({
  selector: 'app-rc-disbursement-workbench',
  templateUrl: './rc-disbursement-workbench.component.html',
  styleUrls: ['./rc-disbursement-workbench.component.css']
})
export class RcDisbursementWorkbenchComponent implements OnInit {

  applicantId: any;
  user: any;
  public data: any;
  public dataSource = new MatTableDataSource<any>();
  public pageSize =5;
  public currentPage = 0;
  public totalSize: number= 0;
  public applicantName: any;
  public applicantNameKannada: any;
  public buildingAddress: any;
  public groupNumber: any;
  public groupSize: any;
  public structureType: any;
  public villages: any[];
  public villageId: any;
  public siteNumber: any;
  public surveyNumber: any;
  public applicationNo: any;
  public activeStatus: any;
  public plinthArea: any;
  public cdFeederReportData: any;
  public sitalArea: any;
  public noOfFloors: any;
  public totalBuildUpArea: any;
  public east: any;
  public west: any;
  public north: any;
  public south: any;
  @Input() isEditable : any;
  public length: any;
  public breadth: any;
  options:any;
  finalNotification:any;
  rtcRemainingExtent: any;
  submitReport: any;
  public possibileCdError: any;
  public reportDate: Date;
  public maxDate = new Date().getTime();
  fakeArray = new Array(5);
  applicationData: any;
  processingJccNos: any;
  public selectedApplicants: any = [];
  public disableSubmitButton = false;
  annexureName: string;
  public rcSerialNo:any;
  public scApprovedNo: any;
  public processedBy: any;
  public rcPrepData : any;
  annexure: any;
  public approvalDate: any;
  address: any;
  eligibleApplicantCount: any;
  selectedApplicantStatus: any;
  filteredApplicants: number;
  heading: any;
  userType: any;
  cdSitalArea: any;
  sitalAreaDimension: any;
  rcRegisterId: any;
  precedenceSitalArea: any;
  groupNo: any;
  dataList: any;


  constructor(
    private applicationService: ApplicationService,
    private cdr:ChangeDetectorRef, 
      private confirmDialogService: ConfirmDialogService, 
    private router: Router,private storageService:StrorageService,
    private messageDialogService: MessageDialogService,
    private route:ActivatedRoute) {
      this.applicantId = this.route.snapshot.queryParams['applicantId'];
      this.rcRegisterId = this.route.snapshot.queryParams['rcRegisterId']
      this.heading = this.route.snapshot.queryParams['heading'];
      this.userType = this.route.snapshot.queryParams['userType'];
     }

    ngOnInit(): void {
      this.user=this.storageService.getUser();
      this.fetchVillages();
        this.fetchRcDisbursemntApplicantById();
   }

   fetchVillages() {
    this.applicationService.fetchAllVillages().subscribe(res=>{
     this.villages = res;
     this.villages.unshift({
       "id":"",
       "name":"All"
     });
     })
   }
   
    clickBack(){
      window.history.back();
    }
    fetchRcDisbursemntApplicantById(){
        this.applicationService.fetchRcDisbursementWorkbenchApplicantByApplicantId(this.applicantId).subscribe(res=>{
            this.data = res;
            this.updateUI(res);
        })
    }
    
    updateUI(res : any) {
      this.applicantName = res.applicantName;
      this.applicantNameKannada = res.applicantNameKannada;
      this.buildingAddress = res.buildingAddress;
      this.applicationNo = res.applicationNo;
      this.activeStatus = res.status;
      this.plinthArea = res.plinthArea;
      this.sitalArea = res.sitalArea;
      this.sitalAreaDimension = res.sitalAreaDimension;
      this.noOfFloors = res.noOfFloor;
      this.totalBuildUpArea = res.totalBuildUpArea;
      this.east = res.east;
      this.west = res.west;
      this.north = res.north;
      this.south = res.south;
      this.length = res.lengthAvg;
      this.breadth = res.breadthAvg;
      this.villageId = res.villageId;
      this.surveyNumber = res.surveyNumber;
      this.siteNumber = res.siteNumber;
      this.precedenceSitalArea = res.precedenceSitalArea;
      this.groupSize = res.groupSize;
      this.groupNumber = res.groupNumber;
      this.eligibleApplicantCount = res.eligibleApplicantCount;
    }
  encloPage() {
    let fullURL = location.protocol+'//'+location.hostname+(location.port ? ':'+location.port: '');
    window.open(fullURL+'#/preview-enclosure?application_id='+this.applicantId, '_blank');
  }

  precedenceSitalAreaClicked(data: any) {
    this.precedenceSitalArea = data.checked;
    
  }

}
