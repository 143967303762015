import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { PageEvent } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { ApplicationService } from 'src/services/application.service';
import { StrorageService } from 'src/services/strorage.service';

@Component({
  selector: 'app-committee-dpa-activity-log',
  templateUrl: './committee-dpa-activity-log.component.html',
  styleUrls: ['./committee-dpa-activity-log.component.scss']
})
export class CommitteeDpaActivityLogComponent implements OnInit {

  isRefreshing: boolean;
  user: any;
  selectedVillageId: number;
  applicationNo: any;
  selectedSurveyId: any;
  villageList : any [] = [];
  syNoList : any [] = [];
  totalSize: number;
  pageSize: number;
  currentPage: number;
  public displayedColumns: string[] = ['dapNo','village','syNo', 'function', 'mainActivity', 'work' ,'createdOn','contractorName'];
  public dataSource = new MatTableDataSource<any>();

  constructor(private applicationService: ApplicationService, private storageService:StrorageService) { }

  ngOnInit() {
    this.user=this.storageService.getUser();
    this.getActivityLogs();
    this.getVillageData();
  }

  refreshClick(){
      // let currentComponent = this;
      // this.isRefreshing = true;
      // setTimeout(()=>{
      //   currentComponent.isRefreshing = false;
      // }, 3000)
  }

  getVillageData(){
    this.applicationService.getAdVillages().subscribe(res => {
      if(res){
        this.villageList = res;
      }
    })
  }

  onSurveyChange = (surveyId: any) => {
    this.getActivityLogs(this.selectedVillageId,surveyId)
  }

  formattDate = (date:any) =>{
    let pipe = new DatePipe('en-IN');
    return pipe.transform(date, 'dd/MM/yyyy');
  }


  getActivityLogs(page?: number, pageSize? : number, villageId? : any, surveyId? :any){
    if (villageId){
      this.selectedVillageId = villageId;
    }
    this.applicationService.getCommitteeDpaActivityLog(this.currentPage, this.pageSize , villageId, surveyId).subscribe(res => {
      if(res){
        this.refreshDataTable(res);
      }
    })
  }

  onVillageChange = (villageId: number) => {
    this.getActivityLogs(villageId);
    this.applicationService.getAdSurveyByVillage(villageId).subscribe(res => {
      console.log("rses", res);;
      this.syNoList = res;
    })
  }

  private refreshDataTable = (res: any) => {
    this.totalSize = res.totalRecord;
    this.dataSource.data = res.data;
    this.dataSource._updateChangeSubscription();
  }

  onClear() {
    this.applicationNo = null;  
  }

  clickBack(){
    window.history.back();
  }

  public getApplicantPaginatorData(event: PageEvent) {
    this.currentPage = event.pageIndex;
    this.pageSize = event.pageSize;
    this.getActivityLogs();
  }

}
