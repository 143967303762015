import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { Router, ActivatedRoute } from '@angular/router';
import { ConfirmDialogService } from 'src/app/shared/confirm-dialog/confirm-dialog.service';
import { MessageDialogService } from 'src/app/shared/message-dialog/message-dialog.service';
import { ApplicationService } from 'src/services/application.service';
import { StrorageService } from 'src/services/strorage.service';

@Component({
  selector: 'app-rc-cd-data-validation-workbench',
  templateUrl: './rc-cd-data-validation-workbench.component.html',
  styleUrls: ['./rc-cd-data-validation-workbench.component.scss']
})
export class RcCdDataValidationWorkbenchComponent implements OnInit {

  applicantId: any;
  user: any;
  public data: any;
  public dataSource = new MatTableDataSource<any>();
  public pageSize =5;
  public currentPage = 0;
  public totalSize: number= 0;
  public applicantName: any;
  public applicantNameKannada: any;
  public buildingAddress: any;
  public groupNumber: any;
  public groupSize: any;
  public structureType: any;
  public villages: any[];
  public villageId: any;
  public siteNumber: any;
  public surveyNumber: any;
  public applicationNo: any;
  public activeStatus: any;
  public plinthArea: any;
  public cdFeederReportData: any;
  public sitalArea: any;
  public noOfFloors: any;
  public totalBuildUpArea: any;
  public east: any;
  public west: any;
  public north: any;
  public south: any;
  @Input() isEditable : any;
  public length: any;
  public breadth: any;
  options:any;
  finalNotification:any;
  rtcRemainingExtent: any;
  submitReport: any;
  public possibileCdError: any;
  public reportDate: Date;
  public maxDate = new Date().getTime();
  fakeArray = new Array(5);
  applicationData: any;
  processingJccNos: any;
  public selectedApplicants: any = [];
  public disableSubmitButton = false;
  annexureName: string;
  public rcSerialNo:any;
  public scApprovedNo: any;
  public processedBy: any;
  public rcPrepData : any;
  annexure: any;
  public approvalDate: any;
  address: any;
  eligibleApplicantCount: any;
  selectedApplicantStatus: any;
  filteredApplicants: number;
  heading: any;
  userType: any;
  cdSitalArea: any;
  sitalAreaDimension: any;
  rcRegisterId: any;
  precedenceSitalArea: any;
  groupNo: any;
  dataList: any;


  constructor(
    private applicationService: ApplicationService,
    private cdr:ChangeDetectorRef, 
      private confirmDialogService: ConfirmDialogService, 
    private router: Router,private storageService:StrorageService,
    private messageDialogService: MessageDialogService,
    private route:ActivatedRoute) {
      this.applicantId = this.route.snapshot.queryParams['applicantId'];
      this.rcRegisterId = this.route.snapshot.queryParams['rcRegisterId']
      this.heading = this.route.snapshot.queryParams['heading'];
      this.userType = this.route.snapshot.queryParams['userType'];
     }

    ngOnInit(): void {
      window.scrollTo(0, 0);
      this.user=this.storageService.getUser();
      this.fetchVillages();
      if(this.userType == 'rc_disbursement_user') {
        this.fetchRcDisbursemntApplicantById();
      }else {
        this.fetchDataEnrichmentApplicantsByApplicantId();
      }
   }

   fetchVillages() {
    this.applicationService.fetchAllVillages().subscribe(res=>{
     this.villages = res;
     this.villages.unshift({
       "id":"",
       "name":"All"
     });
     })
   }
   
    clickBack(){
      window.history.back();
    }
    fetchRcDisbursemntApplicantById(){
        this.applicationService.fetchRcDisbursementWorkbenchApplicantByApplicantId(this.applicantId).subscribe(res=>{
            this.data = res;
            this.updateUI(res);
        })
    }

    fetchDataEnrichmentApplicantsByApplicantId() {
        this.applicationService.fetchCdValidationDataByApplicantId(this.applicantId).subscribe(res=>{
          this.dataList = res;
          for(let i=0; i< res.length;i++) {
            this.groupNumber = res[i].groupNumber;
            this.groupSize = res[i].groupSize;
            this.eligibleApplicantCount = res[i].eligibleApplicantCount;
          }
        })

    }
    
    updateUI(res : any) {
      this.applicantName = res.applicantName;
      this.applicantNameKannada = res.applicantNameKannada;
      this.buildingAddress = res.buildingAddress;
      this.applicationNo = res.applicationNo;
      this.activeStatus = res.status;
      this.plinthArea = res.plinthArea;
      this.sitalArea = res.sitalArea;
      this.sitalAreaDimension = res.sitalAreaDimension;
      this.noOfFloors = res.noOfFloor;
      this.totalBuildUpArea = res.totalBuildUpArea;
      this.east = res.east;
      this.west = res.west;
      this.north = res.north;
      this.south = res.south;
      this.length = res.lengthAvg;
      this.breadth = res.breadthAvg;
      this.villageId = res.villageId;
      this.surveyNumber = res.surveyNumber;
      this.siteNumber = res.siteNumber;
      this.precedenceSitalArea = res.precedenceSitalArea;
      this.groupSize = res.groupSize;
      this.groupNumber = res.groupNumber;
      this.eligibleApplicantCount = res.eligibleApplicantCount;
    }

    validateAndConstructPayload = (data :any) => {
      for(let i=0; i < data.length;i++) {
        data[i].applicantNameKannada = data[i].applicantNameKannada;
        data[i].villageId = data[i].villageId;
        data[i].surveyNumber = data[i].surveyNumber;
        data[i].siteNumber = data[i].siteNumber;
        data[i].sitalArea = data[i].sitalArea;
        data[i].lengthAvg =  data[i].lengthAvg;
        data[i].breadthAvg =  data[i].breadthAvg;
        data[i].noOfFloor =  data[i].noOfFloor;
        data[i].sitalAreaDimension =  data[i].sitalAreaDimension;
        data[i].plinthArea =  data[i].plinthArea;
        data[i].totalBuildUpArea =  data[i].totalBuildUpArea;
        data[i].east =  data[i].east;
        data[i].north =  data[i].north;
        data[i].west =  data[i].west;
        data[i].south =  data[i].south;
        data[i].precedenceSitalArea =  data[i].precedenceSitalArea;
        this.validateData(data[i]);
    }
      return data;
    }

    validateData(data: any) {
        if(!data.applicantName){
          throw new Error(data.applicationNo+'-'+'Applicant Name must not be empty');
        }
        if(!data.buildingAddress){
          throw new Error(data.applicationNo+'-'+'Address must not be empty');
        }
        if(!data.applicantNameKannada){
          throw new Error(data.applicationNo+'-'+'Applicant Name Kannada must not be empty');
        }
        if(!data.villageId){
          throw new Error(data.applicationNo+'-'+'Village must not be empty');
        }
        if(!data.surveyNumber){
          throw new Error(data.applicationNo+'-'+'Survey Number must not be empty');
        }
        if(!data.lengthAvg){
          throw new Error(data.applicationNo+'-'+'Length must not be empty');
        }
        if(!data.breadthAvg){
          throw new Error(data.applicationNo+'-'+'Breadth must not be empty');
        }
        if(!data.noOfFloor){
          throw new Error(data.applicationNo+'-'+'No Of Floors must not be empty');
        }
        if(!data.sitalArea){
          throw new Error(data.applicationNo+'-'+'Sital Area field must not be empty');
        }
        if(!data.sitalAreaDimension){
          throw new Error(data.applicationNo+'-'+'Dimension Sital Area field must not be empty');
        }
        if(!data.plinthArea){
          throw new Error(data.applicationNo+'-'+'Plinth Area field must not be empty');
        }
        if(!data.totalBuildUpArea){
          throw new Error(data.applicationNo+'-'+'Total Build Up Area field must not be empty');
        }
        if(!data.east){
          throw new Error(data.applicationNo+'-'+'East field must not be empty');
        }
        if(!data.west){
          throw new Error(data.applicationNo+'-'+'West field must not be empty');
        }
        if(!data.north){
          throw new Error(data.applicationNo+'-'+'North Area field must not be empty');
        }
        if(!data.south){
          throw new Error(data.applicationNo+'-'+'South Area field must not be empty');
        }
    
    }


    onSubmit(data: any) {
      let array = [];
      for(let i=0; i < data.length;i++) {
        if(data[i].applicationNo){
        array.push(data[i].applicationNo);
        }
      }
      let applicationNo = array.toString();
      console.log(data);
        const options = {
          title: 'Confirmation!',
          message: 'Confirm SD data of JCC No : ' + applicationNo +' verified & updated ', 
          cancelText:'No',
          confirmText: 'Yes',
        };
        this.confirmDialogService.openDialog(options);
        this.confirmDialogService.confirmed().subscribe(confirmed => {
          if(confirmed) {
            let payload = this.validateAndConstructPayload(data);
              this.applicationService.updateRcRegisterCdDataValidationByApplicantId(payload).subscribe(res =>{
                this.router.navigate(['/rc-cd-data-validation-dashboard']);
            })
          }
        });
  }

  encloPage(applicantId: any) {
    let fullURL = location.protocol+'//'+location.hostname+(location.port ? ':'+location.port: '');
    window.open(fullURL+'#/preview-enclosure?application_id='+applicantId, '_blank');
  }

  precedenceSitalAreaClicked(data: any) {
    this.precedenceSitalArea = data.checked;
    
  }

}
