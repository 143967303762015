<div  class="head">
	<p>Associated Applications Ready for Reporting</p>
</div>
 <div class="col-sm-12">
        <div class="rows application-form-row ">
            <label class="form-label">Group ID: {{groupNo}} </label>
            <div class="row ">
            <label class="form-labels">Group Applicant Count: {{applicantsCount}} </label>
            <div class="row ">
            <label class="label-form">Eligible for Ready for Reporting: {{statusApplicantCount.readyForReportingStatusApplicantCount}} </label>
            <div class="rows ">
            <label class="label-forms">Others Count: {{statusApplicantCount.otherStatusApplicantCount}} </label>
            <select multiple class="form-textbox" [(ngModel)]="selectedApplicationId">
             <option *ngFor = "let applicant of applicants " [value]="applicant.id" >{{applicant.applicationNo}} {{applicant.status}}</option>
             <!-- <option *ngFor = "let applicant of applicants " [value]="applicant.id"   [ngStyle]="{'color': (applicant.applicationNo  === '') ? 'green' : (applicant.applicationNo  === 'statusApplicantCount') ? 'red' : ''}">{{applicant.applicationNo}} {{applicant.status}}</option> -->

            </select>
        </div>
      </div>
    </div>
   </div>
</div>

<div class="button" >
	<button  class="btn negative-btn" mat-button (click)="onCancelClicked()">Cancel</button>
	<button mat-button (click)="onOkClicked()" class="btn btn-primary" cdkFocusInitial>Ok</button>
  </div>