import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { ApplicationService } from 'src/services/application.service';
import { SectorVillageDialogComponent } from '../sector-village-dialog/sector-village-dialog.component';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-dpa-work-item-dialog',
  templateUrl: './dpa-work-item-dialog.component.html',
  styleUrls: ['./dpa-work-item-dialog.component.scss']
})
export class DpaWorkItemDialogComponent implements OnInit {

  public faTimes = faTimes;
  public sector: any;
  public selectedSurveyItems:any [] = [];
  public selectedVillage: any;
  public taskList:any[]=[];
  public surveyNo:any = '';
  public surveyNoDataList:any[] = [];
  public approxArea:any='';
  public pageSize =5;
  public currentPage = 0;
  public totalSize: number= 0;
  selectedTaskId: number;
  filteredTasks: any[];
  public workTypeList: any[] = [];
  public selectedWorkTypeIds:any[] = [];
  public selectedTask: any;
  scope: any = '';
  unit: any = '';


  constructor(private applicationService: ApplicationService,public dialogRef: MatDialogRef<DpaWorkItemDialogComponent> ,
     @Inject(MAT_DIALOG_DATA, ) public data, private _snackBar: MatSnackBar) {
      console.log("sector dataa",data);
      this.sector = data.sectorData;
  }

  ngOnInit() {
    this.getTaskList();
  }

  close() {
    this.dialogRef.close();
  }

  cancelClick(){
    this.close();
  }

  getTaskList = () => {
    this.applicationService.getWorkTaskItemByMainActivity(this.sector.sectorId, this.sector.mainActivityId).subscribe(res => {
      this.taskList = res;
      console.log(this.taskList);
    })
  }

  getWorkTypeByFunctionAndMainActivity = () => {
    this.applicationService.getMainActivityTaskWorkTypes(this.sector.sectorId, this.sector.functionTypeId, this.sector.mainActivityId, this.selectedTaskId).subscribe(res => {
      this.workTypeList = this.disableAlreadySelectedWorkTypes(res);
    })
  }

  disableAlreadySelectedWorkTypes = (workTypes:any[] = []) => {
    if(this.sector.taskItems) {
      let selectedWorkTypes:any[] = [];
      for(let j =0;j < this.sector.taskItems.length;j++) {
        if(this.sector.taskItems[j].selectedTaskId == this.selectedTaskId) {
          selectedWorkTypes = this.sector.taskItems[j].selectedWorkTypeIds;
          break;
        }
      }
      if(selectedWorkTypes.length > 0) {
        for(let i = 0;i < workTypes.length;i++) {
          for(let j = 0;j < selectedWorkTypes.length;j++) {
            if(workTypes[i].workTypeId == selectedWorkTypes[j].workTypeId) {
              workTypes[i].showWorkType = false;
            }
          }
        }
      }
      return workTypes;
    }
    return workTypes;
  }

  onTaskChanged(taskData: any, taskId: number) {
    this.selectedTask= taskData;
    this.selectedTaskId = taskId;
    this.scope = taskData.length;
    this.unit = taskData.roadSizeUnit;
    this.selectedWorkTypeIds = [];
    this.getWorkTypeByFunctionAndMainActivity();
  }

  onWorkTypeSelected(workType: any) {
    if(workType.checked) {
      let workTypeData:any = {};
       workTypeData.workTypeId = workType.workTypeId;
       workTypeData.workName = workType.workName;
       this.selectedWorkTypeIds.push(workTypeData);
    }else {
      for(let i =0;i < this.selectedWorkTypeIds.length;i++) {
        if(this.selectedWorkTypeIds[i].workTypeId == workType.workTypeId) {
          this.selectedWorkTypeIds.splice(i);
        }
      }
    }
  }

  joinAllWorkTypeName = (selectedWorkType:any[] = [], existingWorkTypeName?:any) => {
    let workTypeName = '';
    if(existingWorkTypeName) {
      workTypeName = existingWorkTypeName;
    }
    for(let i =0;i < selectedWorkType.length;i++) {
      if(workTypeName == '') {
        workTypeName = selectedWorkType[i].workName;
      } else {
        workTypeName = workTypeName + ","+selectedWorkType[i].workName;
      }
    }
    return workTypeName;
  }

  onSave() {
    try {
      if(!this.selectedTask) {
        throw new Error('Task must not be empty');
      }
      if(this.selectedWorkTypeIds.length == 0) {
        throw new Error('Work Types must not be empty');
      }
      let result:any = {};
      let isNewTask: boolean = false;
      let taskTableRowIdx:Number;
      if(this.sector.taskItems) {
        let isExistingTaskSelected:boolean = false;
        for(let j =0;j < this.sector.taskItems.length;j++) {
          if(this.sector.taskItems[j].selectedTaskId == this.selectedTaskId) {
            let selectedWorkTypes:any[] = this.sector.taskItems[j].selectedWorkTypeIds;
            selectedWorkTypes = selectedWorkTypes.concat(this.selectedWorkTypeIds);
            result.selectedTask = this.selectedTask;
            result.selectedTaskId = this.selectedTaskId;
            result.selectedWorkTypeIds = selectedWorkTypes;
            result.workTypeName = this.joinAllWorkTypeName(this.selectedWorkTypeIds, this.sector.taskItems[j].workTypeName);
            isNewTask = false;
            result.taskTableRowIdx = j;
            isExistingTaskSelected = true;
            break;
          }
        }
        if(!isExistingTaskSelected) {
          result.selectedWorkTypeIds = this.selectedWorkTypeIds;
          result.workTypeName = this.joinAllWorkTypeName(this.selectedWorkTypeIds);
          result.selectedTask = this.selectedTask;
          result.selectedTaskId = this.selectedTaskId;
          isNewTask = true;
        }
      } else {
        result.selectedWorkTypeIds = this.selectedWorkTypeIds;
        result.workTypeName = this.joinAllWorkTypeName(this.selectedWorkTypeIds);
        result.selectedTask = this.selectedTask;
        result.selectedTaskId = this.selectedTaskId;
        isNewTask = true;
      }
      let data:any = {};
      data.row = result;
      data.isNewTask = isNewTask;
      data.sectorRowindex = this.data.sectorRowIndex;
      this.dialogRef.close(data);
    } catch(error) {
      this._snackBar.open(error.message);
    }
  }
}

