<div class="row mt-2 justify-content-center">
    <div *ngFor="let item of sectors; let i = index" class="col-md-3" style="margin:5px">
        <div class="content-area d-flex align-items-center" style="cursor: pointer;" [ngStyle]="{'background-color': item.color}" (click)="onSectorTileClicked(item)">
            <div class="name col-sm-12">
                <div class="col-md-12 title">{{item.name}}</div>
                <div class="col-md-12 sub-title">{{item.companyName}}</div>
                <div class="col-md-12 sub-title">{{item.location1}}</div>
                <div class="col-md-12 sub-title">{{item.location2}}</div>
                <div class="col-md-12 sub-title">{{item.location3}}</div>
                <div class="col-md-12 sub-title" *ngIf="item.location4">{{item.location4}}</div>
            </div>
        </div>        
    </div>
</div>

