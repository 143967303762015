<div class="container">
  <div>
      <app-top-bar selectedTab="home"></app-top-bar>
      <app-header></app-header>
  </div>
  <br>
<div>
  <div class="d-flex justify-content-between">
    <div class="refresh-btn">
      <button mat-button class="btn btn-primary" (click)="clickBack()">Back</button>
        <div class="col-sm-1 ">
        <button class="btn btn-primary refresh-btn" (click)="refreshClick()">
            <fa-icon [icon]="faSync" class="close-icon" [spin]="isRefreshing"></fa-icon>
        </button>
      </div>
  </div>
  <div class="col-sm title" >
    Layout Formation – EE Module – EE Observations
    <br><br> Activity Log
  </div>

    <div class="col-sm-2 offset-sm-1 user-details">
      <div class="value-text">
        Welcome <br>
        {{user['firstName']}} !
      </div>
    </div>
  </div>

  <div>
    <section>
      <div fxLayout="row" fxLayoutAlign="space-between">
        <div class="col-sm-3 offset-sm-9">
          <div fxLayout="row" fxLayoutAlign="space-around">
            <input class="form-control border-end-0 border rounded-pill" style="font-size: 14px !important;" type="text"
              [(ngModel)]="observationNo" placeholder="Search" id="observationNo">
            <span class="input-group-append"><br>&nbsp;
              <button mat-button class="btn btn-primary " (click)="getActivityLogs()">Search</button>
              <button mat-button *ngIf="observationNo" matSuffix mat-icon-button aria-label="Clear" (click)="onClear()">
                <mat-icon>close</mat-icon>
              </button>
            </span>
          </div>
        </div>
      </div>
      <div class="row justify-content-between">
        <div class="col-sm-4">
          <div class="p1">
            <mat-form-field class="w-50" appearance="fill">
              <mat-label>Sector</mat-label>
              <mat-select id="status" name="selectedStatus" [(ngModel)]="sectorId"
                (selectionChange)="getActivityLogs()">
                <mat-option value="">All</mat-option>
                <mat-option *ngFor="let sector of sectorList" [value]="sector.id">
                  {{sector.sectorName}}</mat-option>
              </mat-select>
            </mat-form-field>

          </div>
        </div>
        <div class="col-sm-4">
          <div class="p1">
            <mat-form-field class="w-50" appearance="fill">
              <mat-label>Concern Source </mat-label>
              <mat-select id="status" name="mainActivity" [(ngModel)]="concernSourceId" (selectionChange)="getActivityLogs()">
                <mat-option value="">All</mat-option>
                <mat-option *ngFor="let concernSource of concernSourceList" [value]="concernSource.id">
                  {{concernSource.name}}
                </mat-option>
              </mat-select>

            </mat-form-field>

          </div>
        </div>
        <div *ngIf="!fullAccessUser" class="col-sm-4">
          <div class="p1">
            <mat-form-field class="w-50" appearance="fill">
              <mat-label>EE Observer </mat-label>
              <mat-select id="status" name="mainActivity" [(ngModel)]="dpaUserId" (selectionChange)="getActivityLogs()">
                <mat-option value="">All</mat-option>
                <mat-option *ngFor="let dpaUser of dpaUsersList" [value]="dpaUser.id">
                  {{dpaUser.firstName}}
                </mat-option>
              </mat-select>

            </mat-form-field>

          </div>
        </div>
      </div>

      <!-- data table -->
      <mat-card>
        <table mat-table [dataSource]="dataSource">

          <!-- Sl No Column -->
          <ng-container matColumnDef="sNo">
            <th mat-header-cell *matHeaderCellDef> Sl No.</th>
            <td mat-cell *matCellDef="let element ;let i =index;" class="column-container">
              {{i+1}}</td>
          </ng-container>

          <!-- Observation No Column -->
          <ng-container matColumnDef="obeservationNo">
            <th mat-header-cell *matHeaderCellDef> Observation No. </th>
            <td mat-cell *matCellDef="let element" class="column-container">
              <a  (click)="onPreviewClick(element.id)" class="preview-link">{{element.observationNo}}</a></td>
          </ng-container>

          <!-- Sector Column -->
          <ng-container matColumnDef="sector">
            <th mat-header-cell *matHeaderCellDef> Sector </th>
            <td mat-cell *matCellDef="let element"> {{element.sectorName}} </td>
          </ng-container>

          <!-- Concern Source Column -->
          <ng-container matColumnDef="concernSource">
            <th mat-header-cell *matHeaderCellDef> Concern Source </th>
            <td mat-cell *matCellDef="let element"> {{element.concernSourceName}} </td>
          </ng-container>

          <!-- Comment Column -->
          <ng-container matColumnDef="comments">
            <th mat-header-cell *matHeaderCellDef> Comments </th>
            <td mat-cell *matCellDef="let element" class="limited-text">{{element.comments}} </td>
          </ng-container>          

          <!-- Observation Date Column -->
          <ng-container matColumnDef="observationDate">
            <th mat-header-cell *matHeaderCellDef> Observation Date </th>
            <td mat-cell *matCellDef="let element">{{element.observationDate}} </td>
          </ng-container>

          <!-- Created By Column -->
          <ng-container matColumnDef="createdBy">
            <th mat-header-cell *matHeaderCellDef> Uploaded By </th>
            <td mat-cell *matCellDef="let element"> {{element.uploadedBy}} </td>
          </ng-container>

        <!-- Village Column -->
        <ng-container matColumnDef="imageUrl">
          <th mat-header-cell *matHeaderCellDef> DAP No.</th>
          <td mat-cell *matCellDef="let element" class="column-container">
            <a target="_blank" href="{{element.imageUrl}}" class="preview-link">View Image</a></td>
        </ng-container>

          <ng-container *ngIf="fullAccessUser" matColumnDef="action">
            <th mat-header-cell *matHeaderCellDef> Action </th>
            <td mat-cell *matCellDef="let element"> 
              <mat-icon style="-webkit-text-fill-color:red;cursor: pointer;" aria-label="Delete" title="Delete" (click)="onDeleteClicked(element.observationNo)">delete</mat-icon>
            </td>
          </ng-container>
        
          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>
        <div class="no-records-text" *ngIf="!dataSource.data || dataSource.data.length <= 0">No Records Found!</div>
        <mat-paginator [pageSize]="pageSize" [pageSizeOptions]="[5, 10, 20]" [showFirstLastButtons]="true"
          [pageIndex]="currentPage" [length]="totalSize" (page)="getObservationPaginationData($event)">
        </mat-paginator>
      </mat-card>
    </section>
  </div>
  <app-footer></app-footer>
</div>
</div>
