<div class="container">
  <div>
      <app-top-bar selectedTab="home"></app-top-bar>
      <app-header></app-header>
  </div>
  <br>
<div>
  <div class="d-flex justify-content-between">
    <div class="refresh-btn">
      <button mat-button class="btn btn-primary" (click)="clickBack()">Back</button>
        <div class="col-sm-1 ">
        <button class="btn btn-primary refresh-btn" (click)="refreshClick()">
            <fa-icon [icon]="faSync" class="close-icon" [spin]="isRefreshing"></fa-icon>
        </button>
      </div>
  </div>

    <div class="col-sm-2 offset-sm-1 user-details">
      <div class="value-text">
        Welcome <br>
        {{user['firstName']}} !
      </div>
    </div>
  </div>


  <div class="col-sm title" >
    Layout Formation – {{ workType }} Work – New Task – Activity Log 
  </div>

  <div>
    <section>
      <div fxLayout="row" fxLayoutAlign="space-between">
        <div class="col-sm-3 offset-sm-9">
          <div fxLayout="row" fxLayoutAlign="space-around">
            <input class="form-control border-end-0 border rounded-pill" style="font-size: 14px !important;" type="text"
              [(ngModel)]="taskName" placeholder="Search" id="taskName">
            <span class="input-group-append"><br>&nbsp;
              <button mat-button class="btn btn-primary " (click)="getActivityLogs()">Search</button>
              <button mat-button *ngIf="taskName" matSuffix mat-icon-button aria-label="Clear" (click)="onClear()">
                <mat-icon>close</mat-icon>
              </button>
            </span>
          </div>
        </div>
      </div>
      <div class="row justify-content-between">
        <div class="col-6 title">
          <div class="p1">
            <mat-form-field class="w-50" appearance="fill">
              <mat-label>Sector</mat-label>
              <mat-select id="status" name="selectedStatus" [(ngModel)]="sectorId"
                (selectionChange)="getActivityLogs()">
                <mat-option value="">All</mat-option>
                <mat-option *ngFor="let sector of sectorList" [value]="sector.id">
                  {{sector.sectorName}}</mat-option>
              </mat-select>
            </mat-form-field>

          </div>
        </div>
        <div class="col-6 title">
          <div class="p1">
            <mat-form-field class="w-50" appearance="fill">
              <mat-label>Main Activity </mat-label>
              <mat-select id="status" name="mainActivity" [(ngModel)]="mainActivityId" (selectionChange)="getActivityLogs()">
                <mat-option value="">All</mat-option>
                <mat-option *ngFor="let mainActivity of mainActivityList" [value]="mainActivity.mainActivityId">
                  {{mainActivity.mainActivityName}}
                </mat-option>
              </mat-select>

            </mat-form-field>

          </div>
        </div>
      </div>

      <!-- data table -->
      <mat-card>
        <table mat-table [dataSource]="dataSource">

          <!-- DAP No Column -->
          <ng-container matColumnDef="sectorNo">
            <th mat-header-cell *matHeaderCellDef> Sector No.</th>
            <td mat-cell *matCellDef="let element ;let i =index;" class="column-container">
              {{element.sectorName}}</td>
          </ng-container>

          <!-- DAP No Column -->
          <ng-container matColumnDef="itemNo">
            <th mat-header-cell *matHeaderCellDef> Item No. </th>
            <td mat-cell *matCellDef="let element ;let i =index;" class="column-container">
              {{element.taskName}}</td>
          </ng-container>

          <!-- Function Column -->
          <ng-container matColumnDef="function">
            <th mat-header-cell *matHeaderCellDef> Function </th>
            <td mat-cell *matCellDef="let element"> {{element.functionTypeName}} </td>
          </ng-container>

          <!-- Main Column -->
          <ng-container matColumnDef="mainActivity">
            <th mat-header-cell *matHeaderCellDef> Main Activity </th>
            <td mat-cell *matCellDef="let element"> {{element.mainActivityName}} </td>
          </ng-container>

          <!-- Work Column -->
          <ng-container matColumnDef="scop">
            <th mat-header-cell *matHeaderCellDef> Scope </th>
            <td mat-cell *matCellDef="let element">{{element.length}} </td>
          </ng-container>

          <!-- Village Column -->
          <ng-container matColumnDef="unit">
            <th mat-header-cell *matHeaderCellDef> Unit  </th>
            <td mat-cell *matCellDef="let element"> {{element.roadSizeUnit}} </td>
          </ng-container>

          <!-- Created On Column -->
          <ng-container matColumnDef="createdOn">
            <th mat-header-cell *matHeaderCellDef> Created On </th>
            <td mat-cell *matCellDef="let element ;let i =index;" class="column-container">{{element.createdAt}}
          </ng-container>

          <!-- Created By Column -->
          <ng-container matColumnDef="createdBy">
            <th mat-header-cell *matHeaderCellDef> Created By </th>
            <td mat-cell *matCellDef="let element ;let i =index;" class="column-container">{{element.firstName}}
          </ng-container>

          <!-- Action By Column -->
          <ng-container matColumnDef="action">
            <th mat-header-cell *matHeaderCellDef class="data-header">   Actions    </th>
            <td mat-cell *matCellDef="let element" class="column-container">
                <img class="edit" src="assets/edit.jpeg" (click)="editWorkTaskItem(element.taskId)">
            </td>
        </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>
        <div class="no-records-text" *ngIf="!dataSource.data || dataSource.data.length <= 0">No Records Found!</div>
        <mat-paginator [pageSize]="pageSize" [pageSizeOptions]="[5, 10, 20]" [showFirstLastButtons]="true"
          [pageIndex]="currentPage" [length]="totalSize" (page)="getApplicantPaginatorData($event)">
        </mat-paginator>
      </mat-card>
    </section>
  </div>
  <app-footer></app-footer>
</div>
</div>
