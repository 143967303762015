<div class ="container">
    <div class="row content-area"><br>
        <br>
        <br>
        <div class="col-sm-12 title">
            <div *ngIf="notificationType == 'OLD'">
                ADJUDICATION WORKBENCH - Old Notification
                </div>
            <div *ngIf="notificationType == 'NEW'">
                ADJUDICATION WORKBENCH - New Notification
            </div>
        </div>
        <div class ="col-sm-12">
        <app-applicant-view [data]=applicationData></app-applicant-view>
        <br>
        <div class="row sub-title-2" *ngIf="applicationData">
            <div class="col-sm-10 " style="font-size: 17px;" >
                <label >Recommended by </label>
            </div>
            <div class="col-sm-2">
                <label class="color">{{applicationData.applicantRecommendation ? applicationData.applicantRecommendation.recommendationName : ''}}</label>
              </div>
        </div>
      
        <div class="label col-sm-11" *ngIf = "applicationData">  
            <div class="sub-title">
                Scrutiny Details
             </div>
        <div class="row"  >
            <div class="col-sm-8 " >
                <label >Scrutinized By:</label>&nbsp;&nbsp;<b> {{applicationData.scrutinyStatus.createdBy}}</b>
            </div>
            <div class="row-sm-8 " >
                <label >Date:</label>&nbsp;&nbsp;<b>{{applicationData.scrutinyStatus.createdAt}}</b>
            </div>
         </div>
            <br>
            <div class="row" >
                <div class="col-sm-8" >
                    <label >Comments</label>
                </div>
                <div class="col-sm-4">
                    <label > {{applicationData.scrutinyStatus.comment}}</label>
                  </div>
            </div>
            <br>
            <div>
            <br>

            <div class="row">
                <div class="col-sm-8 ">
                    <label>Status</label>
                </div>
                <div class="col-sm-4">
                    <label class="color">{{applicationData.scrutinyStatus.statusVO.status}} </label>
                  </div>
            </div>
           <br>
            <div fxLayout="row" fxLayoutGap="30px" fxLayoutAlign="space-around">
                <div *ngFor="let message of scrutinyLandTypes">
                    <mat-checkbox [(ngModel)]="message.checked" [disabled]=disable>{{message.landType}}</mat-checkbox>
                </div>
            </div>
             <br>
             <div  *ngIf="applicationData.fieldVisitStatus">
             <div class="sub-title">
                Field Visit Observations
             </div> 
             <br>
             <div class="row" >
                <div class="col-sm-8 " >
                    <label >Referred By:</label>&nbsp;&nbsp;<b>{{applicationData.fieldVisitStatus.createdBy}}</b>
                </div>
                <div class="row-sm-8 " >
                    <label >Date:</label>&nbsp;&nbsp;<b>{{applicationData.fieldVisitStatus.createdAt}}</b>
                </div>
             </div>
            <br>
             <div class="row">
                <div class="col-sm-8" >
                    <label >Comments</label>
                </div>
                <div class="col-sm-4">
                    <label >{{applicationData.fieldVisitStatus.comment}}</label>
                  </div>
            </div>
            <br>
            <div class="row">
                <div class="col-sm-8" >
                    <label >Status</label>
                </div>
                <div class="col-sm-4">
                    <label class="color">{{applicationData.fieldVisitStatus.statusVO.status}}</label>
                  </div>
            </div>
            <br>
             <div fxLayout="row" fxLayoutAlign="space-around">
                <div *ngFor="let message of commentTypes">
                    <mat-checkbox [(ngModel)]="message.checked"[disabled]="true" >{{message.landType}}</mat-checkbox>
                </div>
            </div>
            </div>
            <div class="sub-title">
                Adjudication Observations
             </div> 
             <br>
             <div class="row" >
                <div class="col-sm-8 " >
                    <label >Referred By:</label>&nbsp;&nbsp;<b>{{applicationData.activeStatus.createdBy}}</b>
                </div>
                <div class="row-sm-8 " >
                    <label >Date:</label>&nbsp;&nbsp;<b>{{applicationData.activeStatus.createdAt}}</b>
                </div>
             </div>
            <br>
             <div class="row">
                <div class="col-sm-8" >
                    <label >Comments</label>
                </div>
                <div class="col-sm-4">
                    <label style="
                    inline-size: auto;
                " >{{applicationData.activeStatus.comment}}</label>
                  </div>
            </div>
            <br>
            <div class="row">
                <div class="col-sm-8" >
                    <label >Status</label>
                </div>
                <div class="col-sm-4">
                    <label class="color">{{applicationData.activeStatus.statusVO.status}}</label>
                  </div>
            </div>
            <br>
            <div class="row" *ngIf="applicationData.activeStatus.statusOptionVos && applicationData.activeStatus.statusOptionVos.length > 0">
                <div class="col-sm-8" >
                    <label >Sub Status</label>
                </div>
                <div class="col-sm-4">
                    <label class="color">{{applicationData.activeStatus.statusOptionVos[0].name}}</label>
                  </div>
            </div>
            </div>
            </div>
            </div>
        </div>
        <app-footer></app-footer>
    </div>
    

    
