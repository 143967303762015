import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-layout-sector-dashboard',
  templateUrl: './layout-sector-dashboard.component.html',
  styleUrls: ['./layout-sector-dashboard.component.scss']
})
export class LayoutSectorDashboardComponent implements OnInit {

  sectorCode: any;

  public sectorMapUrl:any;

  public sectors:any[] = [
    {name: 'Sector 1', color: 'grey', type: "sector_1"},
    {name: 'Sector 2', color: 'grey', type: "sector_2"},
    {name: 'Sector 3', color: 'grey', type: "sector_3"},
    {name: 'Sector 4', color: 'grey', type: "sector_4"},
    {name: 'Sector 5', color: 'grey', type: "sector_5"},
    {name: 'Sector 6', color: 'grey', type: "sector_6"},
    {name: 'Sector 7', color: 'grey', type: "sector_7"},
    {name: 'Sector 8', color: 'grey', type: "sector_8"},
    {name: 'Sector 9', color: 'grey', type: "sector_9"}
  ];
  sectorName: any;
  
  constructor(private activateRoute: ActivatedRoute) {
    this.sectorCode = this.activateRoute.snapshot.queryParams["sectorCode"];
    this.sectorName = this.activateRoute.snapshot.queryParams["sectorName"];
    this.sectorMapUrl = environment.BASE_URL+'layout_formation/layout_map/'+this.sectorCode+'.jpg';
   }

  ngOnInit(): void {
  }

}
