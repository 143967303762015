import { Component,EventEmitter,Input, OnInit, Output } from '@angular/core';
import { MatDialogConfig, MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute } from '@angular/router';
import { ConfirmationModalComponent } from 'src/app/confirmation-modal/confirmation-modal.component';
import { ApplicationService } from 'src/services/application.service';

@Component({
  selector: 'app-award-details',
  templateUrl: './award-details.component.html',
  styleUrls: ['./award-details.component.scss']
})
export class AwardDetailsComponent implements OnInit {
  @Input() data : any;

  @Input() isEditable : any;

  public selectedVillage;

  govLandAwardPassed: any;

  govtLandPassedYesValue: any;

  awardInFavourOf: any;

  supremeCourtOrderDated: any;

  haveAllotmentOfAlternateSite: any;

  noOfApplications: any;

  khatedarNotifiedInFinalNotification: any;

  khatedarNotifiedInFinalNotificationNo: any;

  awardHasBeenApproved: any;

  awardHasBeenApprovedYesValue: any;

  applicantId: any;

  options : any;

  govLandAwardPassedValues: any;

  khatedarFinalNotificationCodeValues: any;

  awardApprovedCodeValues: any;

  revenueLayoutFormed: any;

  compensationAmount:any;

  extentRatio:any;

  isConverted:any;

  compensationAmountDecimalValue: any;


  @Output() newApplicantCallback = new EventEmitter<any>();
  issuedToLandOwner: any;
   

  constructor(
    private applicationService: ApplicationService,
    public dialog: MatDialog,
    private _snackBar: MatSnackBar,
    private route: ActivatedRoute,
  ) {
    this.applicantId = this.route.snapshot.queryParams['application_id'];
   }

  ngOnInit(): void {
    this.fetchApplicantById(this.applicantId);
    this.getAwardPassedOnGovernmentLandByCode();
    this.getIflayoutformedAwardInfavorOf();
    this.getKhatedarNotifiedInFinalNotificationCodeValues();
    this.getAwardApprovedCodeValues();
  }
  getAwardPassedOnGovernmentLandByCode(): void {
    let code = "REF001";
    this.applicationService.fetchCodeValueByCode(code).subscribe(res =>{
      this.options = res;
      console.log(res);
    })
  }
  getIflayoutformedAwardInfavorOf(): void {
    let code = "REF002";
    this.applicationService.fetchCodeValueByCode(code).subscribe(res =>{
      this.govLandAwardPassedValues = res;
      console.log(res);
    })
  }

  getKhatedarNotifiedInFinalNotificationCodeValues(): void {
    let code = "REF003";
    this.applicationService.fetchCodeValueByCode(code).subscribe(res =>{
      this.khatedarFinalNotificationCodeValues = res;
      console.log(res);
    })
  }

  getAwardApprovedCodeValues(): void {
    let code = "REF004";
    this.applicationService.fetchCodeValueByCode(code).subscribe(res =>{
      this.awardApprovedCodeValues = res;
      console.log(res);
    })
  }

  private fetchApplicantById = (applicantId: any) => {
    if(!applicantId) {
      return;
    }
    this.applicationService.fetchAwardMasterApplicantsById(applicantId).subscribe(res =>{
        this.data = res;
        console.log(res);
        this.setEditable(res);
        this.setValue(res);
        this.newApplicantCallback.emit(res);
    })
  }


  onSubmit = () => {
    this.openDialog();
  }
  onOptionChange(govtLandPassedValueYes) {
   
     console.log(govtLandPassedValueYes);
 }

  validateAndConstructPayload = () => {
    if(!this.govLandAwardPassed){
      throw new Error('Award Passed on Government Land field must not be empty');
    }
    if(!this.revenueLayoutFormed) {
      throw new Error('If layout formed Award in favor of field must not be empty');
    }
    if(this.revenueLayoutFormed == "Yes") {
      if(!this.awardInFavourOf){
        throw new Error('If layout formed Award in favor of field must not be empty');
      }
       if(!this.isConverted){
        throw new Error('If layout is converted must not empty');
      }
      if(!this.isConverted){
        throw new Error('Whether applications have been converted field must not be empty');
      }
    }
    if(this.revenueLayoutFormed == "No") {
      if(!this.haveAllotmentOfAlternateSite){
        throw new Error('Whether applications have been received for allotment of alternate site field must not be empty');
      }
    }
    if(this.revenueLayoutFormed){
    if(!this.haveAllotmentOfAlternateSite){
      throw new Error('Whether applications have been received for allotment of alternate site field must not be empty');
    }
    }
    if(!this.khatedarNotifiedInFinalNotification){
      throw new Error('Is Award passed in the Name of Khatedar notified in Final Notification field must not be empty');
    }
    if(!this.awardHasBeenApproved){
      throw new Error('Whether Award has been Approved field must not be empty');
    }
    if(this.awardHasBeenApproved == "Yes") {
    if(!this.issuedToLandOwner) {
      throw new Error('Whether 12(2) has been issued to Land Owne field must not be empty');
    }
    if(!this.compensationAmount) {
      throw new Error(' Quantum of compensation RS field must not be empty');
    }
    }
    let payload = {};
    payload['govLandAwardPassed'] = this.govLandAwardPassed;
    if(this.govLandAwardPassed == "Yes"){
    payload['govtLandPassedYesValue'] = this.govtLandPassedYesValue;
    }
    payload['revenueLayoutFormed'] = this.revenueLayoutFormed;
    if(this.revenueLayoutFormed){
      payload['haveAllotmentOfAlternateSite'] = this.haveAllotmentOfAlternateSite;
      if(this.haveAllotmentOfAlternateSite == "Yes"){
        payload['noOfApplications'] = this.noOfApplications;
      }
    }
    if(this.revenueLayoutFormed == "Yes"){
      payload['awardInFavourOf'] = this.awardInFavourOf;
      payload['isConverted'] = this.isConverted;
    }
    if(this.govLandAwardPassed == "Yes"){
      payload['govtLandPassedYesValue'] = this.govtLandPassedYesValue;
    }
    payload['khatedarNotifiedInFinalNotification'] = this.khatedarNotifiedInFinalNotification;

    if(this.khatedarNotifiedInFinalNotification == "No"){
    payload['khatedarNotifiedInFinalNotificationNo'] = this.khatedarNotifiedInFinalNotificationNo;
    }

    payload['awardHasBeenApproved'] = this.awardHasBeenApproved;
    if(this.awardHasBeenApproved == "Yes"){
    payload['awardHasBeenApprovedYesValue'] = this.awardHasBeenApprovedYesValue;
    payload['compensationAmount'] = this.compensationAmount;
    payload['extentRatio'] = this.extentRatio;
    payload['issuedToLandOwner'] = this.issuedToLandOwner;
    }
    return payload;
}
openDialog = () => {
  const dialogConfig = new MatDialogConfig();
  dialogConfig.disableClose = false;
  dialogConfig.autoFocus = true;
  dialogConfig.height = '160px';
  dialogConfig.width = '360px';
  dialogConfig.data = "Are you sure want to submit this record ?"
  const dialogRef = this.dialog.open(ConfirmationModalComponent,dialogConfig);
  dialogRef.afterClosed().subscribe(res =>{
      if(res){
        if(this.applicantId) {
          this.updateAwardDetailsApiCall(this.applicantId);
          return;
        }
      }
  })
}
updateAwardDetailsApiCall = (applicantId: any) => {
  try{
    let payload  = this.validateAndConstructPayload();
    this.applicationService.saveAwardDetails(applicantId, payload).subscribe(res =>{
      this.data = res;
      this.setEditable(res);
      this.openSnackbar("Successfully Updated", "Dismiss");
    }, err => {
        console.log(err);
        this.openSnackbar(err?.message);
    })
  } catch(e){
    this.openSnackbar(e);
  }
}
private setEditable = (data: any): void => {
  if(data['oneTimeEdit'] != null) {
    this.isEditable = data['oneTimeEdit'];
  }
}
openSnackbar(message: string, action?: string){
  if(!action) {
    action = "Dismiss";
  }
  this._snackBar.open(message, action);
}
setValue(data: any): void{
  this.govLandAwardPassed = data.govLandAwardPassed;
  this.govtLandPassedYesValue = data.govtLandPassedYesValue;
  this.awardInFavourOf = data.awardInFavourOf;
  this.supremeCourtOrderDated = data.supremeCourtOrderDated;
  this.haveAllotmentOfAlternateSite = data.haveAllotmentOfAlternateSite;
  this.noOfApplications = data.noOfApplications;
  this.khatedarNotifiedInFinalNotification = data.khatedarNotifiedInFinalNotification;
  this.khatedarNotifiedInFinalNotificationNo = data.khatedarNotifiedInFinalNotificationNo;
  this.awardHasBeenApproved = data.awardHasBeenApproved;
  this.awardHasBeenApprovedYesValue = data.awardHasBeenApprovedYesValue;
  this.revenueLayoutFormed = data.revenueLayoutFormed;
  this.issuedToLandOwner = data.issuedToLandOwner;
  this.compensationAmount =data.compensationAmount;
  this.extentRatio = data.extentRatio;
  this.isConverted = data.isConverted;
}
}
