<div class="content-area">
    <div class="container">
        <div class="row"> 
    <div class="col-sm-12">
        <app-top-bar selectedTab="home"></app-top-bar>
        <app-header></app-header>
    </div>
    <div class="col-sm-12"> 
        <div class="container">
            <div class="row">
              <div class="col-sm-1">
                <button class="btn btn-primary refresh-btn" (click)="refreshClick()">
                    <fa-icon [icon]="faSync" class="close-icon" [spin]="isRefreshing"></fa-icon>
                </button>
               </div>

               <div class="col-sm-2">
                <button class="btn btn-primary back-btn" (click)="goBack()">Back</button>
            </div>

               <div class="col-sm-4">
                <div class="text">
                 Report Workbench - {{subStatus}}
                </div>
            </div>
               <div class="col-sm-2 offset-sm-3">
                <div class=" user-details">
                    <div class="value-text">
                        Welcome 
                    </div>
                    <div class="value-text">
                         {{userName}} !
                    </div>
                    </div>
              </div>
            </div>
            
        
        </div>
    </div>
    <div class="col-sm-12"> 
        <div class="container">
            <div class="row">
                <div class="col-sm-3">
                    <button  class="btn btn-primary btn-btn" (click)="unSelectAll()">
                    Unselect All
                     </button>
                </div>
                <div class="col-sm-3 offset-sm-6">
                 <mat-form-field style="    width: 250px !important; "appearance="fill" >
                    <mat-label>Village</mat-label>
                    <mat-select id="village" name="villageId" [(ngModel)]="villageId">
                    <mat-option *ngFor="let village of villages" [value]="village.id" (click)="onVillageChange()">
                    {{village.id}} {{village.name}}
                    </mat-option>
                     </mat-select>                           
                    </mat-form-field>
                    
                </div>
                <div class="p1 align">
                    Total: {{totalSize}}
                    </div>
                    <div class="col-sm-12"> 
                        <div class="container">
                            <div class="row">
                <div class="col-sm-4">
                        <div fxLayout="row" fxLayoutAlign="space-around">
                        <input class="form-control border-end-0 border rounded-pill" style="font-size: 14px !important;"type="text" [(ngModel)]="applicationNo" placeholder="Search" id="applicationNo">
                        <span class="input-group-append"><br>&nbsp;
                         <button mat-button class="btn btn-primary Search-btn" (click)="searchApplicant()">Search</button> 
                         <button mat-button *ngIf="applicationNo" class="rounded-pills" matSuffix mat-icon-button aria-label="Clear" (click)="onClear()">
                            <mat-icon>close</mat-icon>
                          </button>
                        </span>
                        </div>
                        
                    </div>
                    <div class="col-sm-2 offset-sm-4">
                    <img class="excel" src="assets/excel.jpg"(click)="exporter.exportTable('csv')">
                </div>
                <div>
                        <img class="pdf" src="assets/pdf.jpg"(click)="exportTable()">
                        </div>
                </div>
                </div>
                </div>
                </div>
            </div>
        </div>
    </div>

    <div class="col-sm-12">
        <div class="container">
            <div class="row"> 
                
               
       
            <div class="application-container mat-elevation-z8 ">
                    <!-- data table -->
                    <mat-card style="margin-top: -25px;">
                        <table mat-table matTableExporter id="ExampleTable" [dataSource]="dataSource" #exporter="matTableExporter" matSort>
                            <ng-container matColumnDef="select_all">
                                <th mat-header-cell *matHeaderCellDef class ="data-header"> Select All </th>
                                <td mat-cell *matCellDef="let element" class="column-container">
                                    <mat-checkbox labelPosition="before" [(ngModel)]="element.checked" 
                                    [checked]="element.checked" (change)='selectOrDeSelectApplicants($event, element)'></mat-checkbox>
                                </td>
                            </ng-container>
                           
                            <ng-container matColumnDef="siNo">
                                    <th mat-header-cell *matHeaderCellDef class ="data-header"> SI No </th>
                                    <td mat-cell *matCellDef="let element; let i = index" class="column-container">
                                    {{i + 1}}
                            </ng-container>

                            <ng-container matColumnDef="applicationNo">
                                <th mat-header-cell *matHeaderCellDef class ="data-header"> JCC No </th>
                                <td mat-cell *matCellDef="let element" class="column-containers">
                                {{element.applicationNo}}
                            </ng-container>
                
                            <ng-container matColumnDef="applicantName">
                                <th mat-header-cell *matHeaderCellDef class ="data-header"> Applicant Name</th>
                                <td mat-cell *matCellDef="let element" class="column-container">
                                {{element.applicantName}}
                            </ng-container>
                            <ng-container matColumnDef="address">
                                    <th mat-header-cell *matHeaderCellDef class ="data-header">Address, Phone No, Email</th>
                                    <td mat-cell *matCellDef="let element" class="column-container">
                                    {{element.buildingAddress}}
                            </ng-container>
                            <ng-container matColumnDef="surveyNumber">
                                <th mat-header-cell *matHeaderCellDef class ="data-header"> Survey Number &<br>
                                     Site Number</th>
                                <td mat-cell *matCellDef="let element" class="column-container">
                                {{element.surveyNumber}}
                            </ng-container>
                            <ng-container matColumnDef="type">
                                <th mat-header-cell *matHeaderCellDef class ="data-header">Type</th>
                                <td mat-cell *matCellDef="let element" class="column-container">
                                {{element.type}}
                            </ng-container>
                            <ng-container matColumnDef="planSanctioned">
                                <th mat-header-cell *matHeaderCellDef class ="data-header">Plan Sanctioned</th>
                                <td mat-cell *matCellDef="let element" class="column-container">
                                {{element.planSanctioned}}
                            </ng-container>
                            <ng-container matColumnDef="builtUpArea">
                                    <th mat-header-cell *matHeaderCellDef class ="data-header">Built-up Area
                                            (in Sq.ft.)&
                                            No. of Flrs</th>
                                    <td mat-cell *matCellDef="let element" class="column-container">
                                    {{element.builtUpArea}}
                                </ng-container>
                                <ng-container matColumnDef="sitalAreas">
                                        <th mat-header-cell *matHeaderCellDef class ="data-header">Sital Area
                                                (in Sq.ft.)</th>
                                        <td mat-cell *matCellDef="let element" class="column-container">
                                        {{element.sitalAreas}}
                                    </ng-container>
                          
                            <tr mat-header-row *matHeaderRowDef="displayedColumns sticky: true"></tr>
                            <tr mat-row *matRowDef="let row; columns: displayedColumns; " ></tr>
                        </table>
                        <div class="no-records-text" *ngIf="!dataSource.data || dataSource.data.length <= 0">No Records Found!</div>
                    </mat-card>
                </div>
            </div>
           </div>
          </div>
     </div>
        <div class="col-sm-12">
        <div class="container">
            <div class="row">
                <div class="col-sm-2 rows">
                    <mat-form-field style="margin-left: 20px">
                        <mat-label>Report Name Options <span class="required">*</span></mat-label>
                        <mat-select id="reportNameConfig" name="selectedReportNameId" [(ngModel)]="selectedReportNameId">
                            <mat-option *ngFor="let reportName of reportNames" [value]="reportName">
                            {{reportName.reportName}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="col-sm-1 offset-sm-6 rows">
                    <mat-form-field style="margin-left: -51px">
                        <mat-label>Report Title Options <span class="required">*</span></mat-label>
                        <mat-select id="reportTitleConfig" name="selectedReportTitleId" [(ngModel)]="selectedReportTitleId">
                            <mat-option *ngFor="let reportTitle of reportTitles" [title]="reportTitle.reportTitle" [value]="reportTitle.id">
                            {{reportTitle.reportTitle}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            </div>
        </div>
    </div>
      <div class="col-sm-12"> 
            <div class="container">
                <div class="row">
                    <div class="col-sm-2">
                        <mat-form-field style="margin-left: 20px">
                        <mat-label>Enter Report Name <span class="required">*</span></mat-label>
                        <input matInput name="batchName" [(ngModel)]="batchName" >
                        </mat-form-field>
                    </div>
                    <div class="col-sm-1 offset-sm-6">
                        <mat-form-field style="margin-left: -51px">
                            <mat-label>Batch Size <span class="required">*</span></mat-label>
                        <input matInput name="batchSize" [(ngModel)]="batchSize" readonly>
                        </mat-form-field> 
                    </div>  
                </div>
            </div>
        </div>
            <div class="col-sm-12 ">
                <div class="container">
                    <div class="row">
                        <div class="col-sm-2">
                    <mat-form-field style="margin-left: 18px">
                        <mat-label>Enter Report Start Page Number <span class="required">*</span></mat-label>
                    <input matInput type="number" name="reportStartPageNumber" [(ngModel)]="reportStartPageNumber" >
                    </mat-form-field>
                </div>
                <div class="col-sm-1 offset-sm-6">
                    <mat-form-field style="margin-left: -51px">
                        <mat-label>Enter Serial Number <span class="required">*</span></mat-label>
                    <input matInput type="number" name="serialNo" [(ngModel)]="serialNo" >
                    </mat-form-field>
                </div>
            </div>
            </div>
        </div>
        <div class="col-sm-12"> 
                <div class="container">
                    <div class="row">
                 
                    <div class="col-sm-4 offset-sm-1">
                        <button style="margin-left: 70px" class="btn  btn-btns" (click)="previewReport()">
                            Preview Report
                        </button>
                    </div> 
                    <div class="col-sm-4 offset-sm-3">
                        <button style="margin-left: 70px" class="btn btn-primary refresh-btn" (click)="generateReport()">
                            Generate Report
                        </button>
                    </div>  
             </div>
            </div> 
        </div>
    </div>
     <app-footer class="col-md-10 offset-sm-3" ></app-footer>