import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTableDataSource } from '@angular/material/table';
import { ApplicationService } from 'src/services/application.service';
import { faSync } from '@fortawesome/free-solid-svg-icons';
import { Router } from '@angular/router';
import { StrorageService } from 'src/services/strorage.service';
import { faTrash} from '@fortawesome/free-solid-svg-icons';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { DmsConfirmationModalComponent } from '../dms-confirmation-modal/dms-confirmation-modal.component';


@Component({
  selector: 'app-dms-activity-log',
  templateUrl: './dms-activity-log.component.html',
  styleUrls: ['./dms-activity-log.component.scss']
})
export class DmsActivityLogComponent implements OnInit {

  public faSync = faSync;

  public dataSource = new MatTableDataSource<any>();

  public documentTypes : any[] = [];

  public documentSubTypes : any = [];

  public pageSize = 5;
  
  faTrash = faTrash;

  public currentPage = 0;

  public totalSize: number = 0;

  public searchKeyword : any;

  public selectedDocumentTypeId : number;

  public selectedDocumentSubTypeId : number;

  isRefreshing: boolean;

  totalApplicantCount: any;

  searchDocNo:string;

  user:any;

  constructor(private applicationService: ApplicationService, private storageService: StrorageService,
    private _snackBar: MatSnackBar,private cdr: ChangeDetectorRef,private router: Router, public dialog: MatDialog) { }

  ngOnInit(): void {
    this.user = this.storageService.getUser();
    this.fetchDocumentTypes();
    this.getAllDocumentSubTypes();
    this.getActivityLogs();
  }
  public displayedColumns: string[] = ['slNo','docType', 'docSubType', 'docName', 'docNo','docDate','description','uploadedOn','uploadedBy', 'edit','delete'];

  onClear() {
    this.searchKeyword = null;
    this.getActivityLogs();
  }

  onDocNoClear() {
    this.searchDocNo = null;
    this.getActivityLogs();
  }

  getActivityLogs() {
    this.applicationService.getDmsActivityLogs(this.currentPage, this.pageSize, this.searchKeyword, this.selectedDocumentTypeId, this.selectedDocumentSubTypeId, this.searchDocNo).subscribe(res => {
      console.log('res', res);
      this.refreshDataTable(res);
    })
  }

  downloadActivityLogAsExcelSheet(){
    this.applicationService.fetchExcelSheetUrl(this.searchKeyword, this.selectedDocumentTypeId, this.selectedDocumentSubTypeId).subscribe(res => {
      console.log('res', res);
      this.downloadFile(res.fileUrl);
    })
  }

  fetchDocumentTypes = () => {
    this.applicationService.fetchAllDocumentTypes().subscribe(res =>{
    this.documentTypes = res;
    this.documentTypes.unshift({
      id: '',
      documentType: 'All'
    });
    })
  }

  getAllDocumentSubTypes() {
    this.applicationService.fetchAllDocumentSubTypes().subscribe(res => {
      if (res) {
        this.documentSubTypes = res;
        this.documentSubTypes.unshift({
          id: '',
          label: 'All'
        });
      }
    })
  }

  getPaginationData(event: any) {
    this.currentPage = event.pageIndex + 1;
    this.pageSize = event.pageSize;
    this.getActivityLogs();
  }

  private refreshDataTable = (res: any) => {
    this.dataSource.data = res.data;
    this.totalSize = res.totalRecord;
    this.totalApplicantCount = res.data[0] ? res.data[0].totalApplicationCount : 0;
    this.currentPage = (res.currentPage - 1);
    this.dataSource._updateChangeSubscription();
    if (!this.cdr['destroyed']) {
      this.cdr.detectChanges();
    }
  }

  refreshClick() {
    let currentComponent = this;
    this.isRefreshing = true;
    setTimeout(() => {
      currentComponent.isRefreshing = false;
    }, 3000)
    this.getActivityLogs();
  }

  openSnackbar = (message: string, action?: string) => {
    if(!action) {
      action = "Dismiss";
    }
    this._snackBar.open(message, action);
  }

  navigateHome() {
    this.router.navigate(['/dms-workbench']);
  }

  editDms(dmsId :any) {
    this.router.navigate(['/edit-dms-workbench'], {queryParams : { dmsId: dmsId }});
  }

  openDialog = (dmsId :any, documentName:any) => {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = false;
    dialogConfig.autoFocus = true;
    dialogConfig.height = '165px';
    dialogConfig.width = '430px';
    dialogConfig.data = "Confirm Delete – Document Name : "+documentName+" ?";
    const dialogRef = this.dialog.open(DmsConfirmationModalComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(res => {
      if (res) {
        this.deleteDms(dmsId, documentName);
      }
    })
  }

  deleteDms(dmsId:number, documentName: string) {
    this.applicationService.deleteDms(dmsId).subscribe(res => {
      this.openSnackbar("Deleted DMS! Document name : "+documentName);
      this.getActivityLogs();
    })
  }

  downloadFile(url: string) {
    let link = document.createElement('a');
    link.href = url;
    link.download = url.substring(url.lastIndexOf('/') + 1);
    link.style.display = 'none';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

}
