<div fxFlex="100%">
        <div fxFlex="25" class="side-menu-container">
            <app-side-menu [menus]="menus" (onMenuClick)="onItemClicked($event)"></app-side-menu>
        </div>
        <div fxFlex="80" style="padding-left: 55px">
            <div class="row application-form-row">
                <label class="form-label">Name of the applicant / ಅರ್ಜಿದಾರನ ಹೆಸರು : <span class="text-danger"> * </span></label>
                <input type="text" [(ngModel)]="applicantName" class="form-textbox" [readonly]="(data && data.applicantName) && !isEditable" />
            </div>
            <div class="row application-form-row ">
                    <label class="form-label">Landline No / ದೂರವಾಣಿ ಸಂಖ್ಯೆ : </label>
                    <input type="number" [(ngModel)]="telephoneNumber" class="form-textbox" [readonly] = "(data) && !isEditable" />
            </div>
            <div class="row application-form-row ">
                    <label class="form-label">Mobile No (10 digits only) / ಮೊಬೈಲ್ ಸಂಖ್ಯೆ : <span class="text-danger"> * </span></label>
                    <input type="number" [(ngModel)]="mobileNumber" class="form-textbox" [readonly] = "(data && data.mobileNumber) && !isEditable" />
            </div>
            <div class="row application-form-row ">
                    <label class="form-label">Email / ಇ-ಮೇಲ್  : </label>
                    <input type="text" [(ngModel)]="email" class="form-textbox" [readonly] = "(data) && !isEditable"/>
            </div>
            <div class="row application-form-row ">
                    <label class="form-label">Survey/Site No / ಸರ್ವೆ ಸಂಖ್ಯೆ /ನಿವೇಶನದ ಸಂಖ್ಯೆ. : <span class="text-danger"> * </span></label>
                    <input type="text" [(ngModel)]="surveyNumber" class="form-textbox" [readonly] = "(data && data.surveyNumber) && !isEditable"  />
                </div>
                <div class="row application-form-row ">
                        <label class="form-label">Name of the Village / ಗ್ರಾಮದ ಹೆಸರು : <span class="text-danger"> * </span></label>
                        <select class="form-textbox" [(ngModel)] = "selectedVillage" [disabled]="data && data.villageId" >
                            <option disabled="true">Select Village</option>
                            <option *ngFor = "let village of villages" [value]="village.id">{{village.id}}. {{village.name}}</option>
                        </select>
                    </div>
                    <div class="row application-form-row ">
                            <label class="form-label">Address of the applicant / ಅರ್ಜಿದಾರನ ಅಂಚೆ ವಿಳಾಸ : <span class="text-danger"> * </span></label>
                            <input type="text" [(ngModel)]="applicantAddress" class="form-textbox" [readonly] = "(data && data.applicantAddress) && !isEditable" />
                        </div>
            <div fxLayout="row" style="margin-top: 20px">
                <button fxFlexOffset="70" class="btn btn-next" (click)="onSubmit()">Save & Continue</button>
            </div>
        </div>
</div>
    