<div class="container">
    <div>
        <app-top-bar selectedTab="home"></app-top-bar>
        <app-header></app-header>
    </div>
    <br>

    <div class="tool-bar">
        <div>
            <button mat-button class=" btn btn-primary" (click)="showActivityLog()" >Activity Log</button>
        </div>
        <div class="col-sm-5 title ">
            RC - SD Data Validation Module
        </div>
                  
                    
        <div class="col-sm-3 offset-sm-1 user-details">
            <div class="value-text">
                Welcome  <br>
            {{user['firstName']}} !
            </div>
            </div>
        </div>
        <div class="tool-bar">
                <div class="col-sm-3" style="margin-left: -23px;margin-bottom: 30px;">
                    <div fxLayout="row" fxLayoutAlign="space-around">
                   <input class="form-control border-end-0 border rounded-pill" style="font-size: 14px !important;"type="text" [(ngModel)]="applicationNo" placeholder="Search" id="applicationNo">
                   <span class="input-group-append"><br>&nbsp;
                    <button mat-button class="btn btn-primary " (click)="searchApplicant()">Search</button>
                    <button mat-button *ngIf="applicationNo" matSuffix mat-icon-button aria-label="Clear" (click)="onClear()">
                       <mat-icon>close</mat-icon>
                     </button>
                   </span>
                   </div>
                </div>
            <div class="col-sm-2 offset-sm-7" style="left: -42px;">
                <mat-form-field appearance="fill">
                <mat-label>Village</mat-label>
                <mat-select id="village" name="villageId" [(ngModel)]="villageId">
                <mat-option *ngFor="let village of villages" [value]="village.id" (click)="onVillageChange()">
                {{village.id}} {{village.name}}
                </mat-option>
                </mat-select>
                </mat-form-field>
                <div class="p1">
                Total: {{totalSize}}
                </div>
            </div>
        <div class="col-sm-1 ">
        </div>
    </div>

            <!-- data table -->
            <mat-card>
                <table mat-table [dataSource]="dataSource">

                      <!-- Group No Column -->
                      <ng-container matColumnDef="groupNo">
                        <th mat-header-cell *matHeaderCellDef> Group No</th>
                        <td mat-cell *matCellDef="let element">
                            {{element.groupNo}}
                        </td>
                    </ng-container>

                    <!-- Group Size Column -->
                    <ng-container matColumnDef="groupSize">
                        <th mat-header-cell *matHeaderCellDef> Group Size</th>
                        <td mat-cell *matCellDef="let element">
                            {{element.groupSize}}
                        </td>
                    </ng-container>
                    <!-- Jcc No Column -->
                    <ng-container matColumnDef="jccNo">
                        <th mat-header-cell *matHeaderCellDef> Jcc No </th>
                        <td mat-cell *matCellDef="let element"> 
                            <a (click)="onPreviewClick(element.applicantId)" class="preview-link">{{element.applicationNo}}</a>
                         </td>
                    </ng-container>

                    <!-- Applicant Name Column -->
                    <ng-container matColumnDef="applicantName">
                        <th mat-header-cell *matHeaderCellDef> Applicant Name </th>
                        <td mat-cell *matCellDef="let element"> {{element.applicantName}} </td>
                    </ng-container>

                    <!-- Active Status Column -->
                    <ng-container matColumnDef="activeStatus">
                        <th mat-header-cell *matHeaderCellDef> Active Status </th>
                        <td mat-cell *matCellDef="let element"> {{element.activeStatus}} </td>
                    </ng-container>

                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns;" ></tr>
                </table>

                <div class="no-records-text" *ngIf="!dataSource.data || dataSource.data.length <= 0">No Records Found!</div>
                <mat-paginator [pageSize]="pageSize" [pageSizeOptions]="[5, 10, 20]" [showFirstLastButtons]="true"
                [pageIndex]="currentPage" [length]="totalSize" (page)="getApplicantPaginatorData($event)">
            </mat-paginator>
            </mat-card>
 <app-footer></app-footer>
 </div>