import { Component, OnInit, Inject, Input, EventEmitter, HostListener,NgModule } from '@angular/core';
import { MAT_DIALOG_DATA,MatDialogRef,MatDialog } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { take, map } from 'rxjs/operators';
import { ApplicationService } from 'src/services/application.service';

@Component({
  selector: 'app-adjudication-out-of-bounds-dialogbox',
  templateUrl: './adjudication-out-of-bounds-dialogbox.component.html',
  styleUrls: ['./adjudication-out-of-bounds-dialogbox.component.scss']
})

export class AdjudicationOutOfBoundsDialogboxComponent implements OnInit {
  
  statusOptions: any = [];
  public statusCode: any = 'out_of_bounds';
  public selectedStatusOption: any;

  constructor(private dialogRef: MatDialogRef<AdjudicationOutOfBoundsDialogboxComponent>,private dialog: MatDialog,
    private applicationService: ApplicationService) { }

  ngOnInit(): void {
    this.getAdjudicationStatusOption();
  }
  getAdjudicationStatusOption(): void {
    this.applicationService.getStatusOption(this.statusCode).subscribe(res =>{
      this.statusOptions = res;
  })
}
  public onOkClicked = () => {
    let data = {};
    data['statusOptionId'] = this.selectedStatusOption;
    data['confirmed'] = true;
    this.dialogRef.close(data);
  }

  public onCancelClicked = () => {
    let data = {};
    data['confirmed'] = false;
    this.dialogRef.close(data);
  }

  @HostListener("keydown.esc") 
  public onEsc() {
    this.dialogRef.close(false);
  }
}

