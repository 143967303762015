import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { PageEvent } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { ConfirmDialogService } from 'src/app/shared/confirm-dialog/confirm-dialog.service';
import { ApplicationService } from 'src/services/application.service';
import { StrorageService } from 'src/services/strorage.service';

@Component({
  selector: 'app-rc-sd-data-validation-dashboard',
  templateUrl: './rc-sd-data-validation-dashboard.component.html',
  styleUrls: ['./rc-sd-data-validation-dashboard.component.scss']
})
export class RcSdDataValidationDashboardComponent implements OnInit {

  public pageSize =5;
  public currentPage = 0;
  public totalSize: number= 0;
  public isRefreshing = false;
  public user: any;
  public displayedColumns: string[] = ['groupNo','groupSize','jccNo','applicantName','activeStatus'];
  public selectedStructureType:any;
  public dataSource = new MatTableDataSource<any>();
  applicationNo: any;
  villageId: any;
  statusId: any;
  statusCode:any;
  villages: any[];
  statuses: any[];
  show: boolean;
  structureTypeId: any;
  structureTypes: any[];
  applicantData: any;
  cdError: any;
  possibleCdError: any;
  groupSize:any;
  groupNo:any;
  public selectedApplicants: any = [];
  public selectedApplicantsSize : any;
  public selectedApplicationNos : any = [];
  constructor(private applicationService: ApplicationService,
    private cdr:ChangeDetectorRef, 
    private confirmDialogService: ConfirmDialogService, 
    private router: Router,private storageService:StrorageService,
   ) { }

    ngOnInit(): void {
      this.user = this.storageService.getUser();
       this.fetchVillages();
       this.fetchRcSDDataEnrichmentApplicants();
    }

    fetchRcSDDataEnrichmentApplicants(){
      this.applicationService.fetchRcSDDataValidationApplicants(this.applicationNo,this.villageId,this.currentPage,this.pageSize).subscribe(res=>{
        this.applicantData = res;
        this.refreshDataTable(res);
      })
    }
  
    fetchVillages(){
       this.applicationService.fetchAllVillages().subscribe(res=>{
        this.villages = res;
        this.villages.unshift({
          "id":"",
          "name":"All"
        });
        })
      }

      private refreshDataTable = (res: any) => {
        this.dataSource.data = res.data;
        this.totalSize = res.totalRecord;
        this.currentPage = (res.currentPage - 1);
        this.dataSource._updateChangeSubscription();
        if (!this.cdr['destroyed']) {
          this.cdr.detectChanges();
        }
      }

      public getApplicantPaginatorData(event: PageEvent) {
        this.currentPage = event.pageIndex + 1;
        this.pageSize = event.pageSize;
        this.fetchRcSDDataEnrichmentApplicants();
      }

    onVillageChange(): void{
      this.resetPagination();
      this.show=false;
      this.fetchRcSDDataEnrichmentApplicants();
    }

    searchApplicant(){
      this.resetPagination();
      this.fetchRcSDDataEnrichmentApplicants();
    }

    resetPagination() {
      this.currentPage = 0;
      this.pageSize = 5;
    }
  
    clickBack(){
      window.history.back();
    }
    onPreviewClick(applicantId: any) {
      this.router.navigate(['/rc-sd-data-validation-workbench'],{ queryParams: { applicantId :applicantId} })
    }

    onClear() {
      this.applicationNo = null;
      this.currentPage = 0;
      this.fetchRcSDDataEnrichmentApplicants();
    }

     updateBtnClick (applicantId: any) {
      this.router.navigate(['/rc-register-data-enrichment-form'],{ queryParams: { applicantId :applicantId} })
     }

     refreshClick(){
      let currentComponent = this;
      this.isRefreshing = true;
      setTimeout(()=>{
        currentComponent.isRefreshing = false;
      }, 3000)
      this.fetchRcSDDataEnrichmentApplicants();
    }


  constructPayload(data: any) {
    let jccNos = [];
    for(let i = 0;i < data.length;i++) {
      jccNos.push(data[i].applicationNo);
    }
    return jccNos;
  }

    onSubmit() {
      let jccNos = this.constructPayload(this.selectedApplicants);
      const options = {
        title: 'Confirmation!',
        message: 'Confirm '+ this.selectedApplicantsSize+ ' Applications move for SD Data Validation', 
        cancelText:'No',
        confirmText: 'Yes'
      };
      this.confirmDialogService.openDialog(options);
      this.confirmDialogService.confirmed().subscribe(confirmed => {
        if(confirmed) {
          let payload = {};
          payload['applicationNos'] = jccNos;
            this.applicationService.submitSelectedRcRegisterApplicants(payload).subscribe(res =>{
            return;
          })
        }
      });
    }
    
    showActivityLog() {
      this.router.navigate(['/rc-sd-data-validation-activity-log']);
    }

}
