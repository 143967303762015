import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ApplicationService } from 'src/services/application.service';
import { StrorageService } from 'src/services/strorage.service';

@Component({
  selector: 'app-dpa-ee-observation-dashboard',
  templateUrl: './dpa-ee-observation-dashboard.component.html',
  styleUrls: ['./dpa-ee-observation-dashboard.component.scss']
})
export class DpaEeObservationDashboardComponent implements OnInit {

  userData: any;
  firstName: string;
  showDu: boolean = false;

  constructor(private storageService:StrorageService, private applicationService: ApplicationService,
    private router:Router) { }

  ngOnInit() {
    this.userData = this.storageService.getUser();
    if(this.userData){
      this.firstName = this.userData['firstName'];
    }
  }
  routeDpaFunctionalHead(){
    this.router.navigate(['/dpa-functional-head'])
  }

onClickEEObservation() {
  this.showDu = false;
  this.router.navigate(['/dpa-ee-observation-workbench'])
  }
  onClickDailyUpdates() {
    this.showDu = !this.showDu;
  }

}

