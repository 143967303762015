<div class="content-area">
    <mat-card>
        <table mat-table style="width:100%" [dataSource]="dataSource">
            <ng-container matColumnDef="reportId">
                <th mat-header-cell *matHeaderCellDef> Si No </th>
                <td mat-cell *matCellDef="let element ;let i =index;" class="column-container">{{i+1}}
            </ng-container>

            <!-- --batchName-- -->
            <ng-container matColumnDef="batchName">
            <th mat-header-cell *matHeaderCellDef class ="data-header"> Batch Name </th>
                <td mat-cell *matCellDef="let element" class="column-container">
                {{element.batchName}}
            </ng-container>

            <!-- --batchSize-- -->
            <ng-container matColumnDef="batchSize">
                <th mat-header-cell *matHeaderCellDef class ="data-header"> Batch Size</th>
                <td mat-cell *matCellDef="let element" class="column-container">
                {{element.batchSize}}
            </ng-container>

            <ng-container matColumnDef="generatedAt">
                <th mat-header-cell *matHeaderCellDef class ="data-header"> Received On </th>
                <td mat-cell *matCellDef="let element" class="column-container">
                    
            </ng-container>

            <ng-container matColumnDef="generatedBy">
                <th mat-header-cell *matHeaderCellDef class ="data-header"> Sent By </th>
                <td mat-cell *matCellDef="let element" class="column-container">
                    {{element.generatedBy}}
            </ng-container>

            <ng-container matColumnDef="actions">
                <th mat-header-cell *matHeaderCellDef class ="data-header"> Actions </th>
                <td mat-cell *matCellDef="let element" class="column-container">
                    <a target="_blank" href="{{element.fileURL}}" class="preview-link pdf-btn">
                        <mat-icon aria-label="picture_as_pdf">picture_as_pdf</mat-icon>
                    </a>
                    <button class="btn btn-primary" *ngIf="roleCode == 'bda_commr' && statusCode == 'sc_ordered_jcc_to_bda_commr'" (click)="transferStatusTo(element, 'commissioner_to_secretary', 'Secretary')">Transfer to Secretary</button>
                    <button class="btn btn-primary" *ngIf="roleCode == 'bda_secretary' && statusCode == 'commissioner_to_secretary'" (click)="transferStatusTo(element, 'secretary_to_DS', 'Deputy Secretary')">Transfer to Deputy Secretary</button>
                    <button class="btn btn-primary" *ngIf="roleCode == 'bda_ds' && statusCode == 'secretary_to_DS'" (click)="transferStatusTo(element, 'ds_to_em', 'EM')">Transfer to EM</button>
                    <button class="btn btn-primary" *ngIf="roleCode == 'bda_em' && statusCode == 'ds_to_em'" (click)="transferStatusTo(element, 'em_to_eo', 'EO')">Transfer to EO</button>
                    <button class="btn btn-primary" *ngIf="roleCode == 'bda_eo' && statusCode == 'em_to_eo'" (click)="transferStatusTo(element, 'eo_to_ee', 'EE')">Transfer to EE</button>
                    <button class="btn btn-primary" *ngIf="roleCode == 'bda_ee' && statusCode == 'eo_to_ee'" (click)="transferStatusTo(element, 'ee_to_aee', 'AEE')">Transfer to AEE</button>
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;" ></tr>
        </table>
        <div class="no-records-text" *ngIf="!dataSource.data || dataSource.data.length <= 0">No Records Found!</div>
        <mat-paginator [pageSize]="pagesize" [pageSizeOptions]="[5, 10, 20]" [showFirstLastButtons]="true" [length]="totalSize"
            [pageIndex]="currentPage" (page)="getApplicantPaginatorData($event)">
        </mat-paginator>
    </mat-card>
</div> 
    