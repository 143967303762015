<div class="content-area">
        <div class="col-sm-10 offset-sm-1">
            <app-top-bar selectedTab="home"></app-top-bar>
            <app-header></app-header>
        </div>
        <div class="col-sm-12"> 
            <div class="container">
                <div class="row">
                    <div class="col-sm-12 heading" *ngIf="heading">
                        <h2>{{heading}}</h2>
                    </div>
                    <div class="col-sm-1">
                        <button class="btn btn-primary refresh-btn" (click)="refreshClick()">
                            <fa-icon [icon]="faSync" class="close-icon"   [spin]="isRefreshing"></fa-icon>
                        </button>
                    </div>
                    
                    <div class="col-sm-7" *ngIf="roleCode == 'bda_commr'">
                        <div class="">
                                <button  mat-button  class="btn btn-primary content-btn"  
                                type="button" [ngStyle]="{'background-color':'DarkOrange'}" (click)="getReportsByStatus('commissioner_to_secretary')">Outgoing to Secretary</button>
                            </div>
                            <div class="">
                            <button mat-button class="btn btn-primary content-btn"  
                            id="selectapp" type="button" [ngStyle]="{'background-color':'#0062cc'}" (click)="getReportsByStatus('sc_ordered_jcc_to_bda_commr')">Incoming from JCC</button>
                        </div>
                       
                    </div>
                    <div class="col-sm-7" *ngIf="roleCode == 'bda_secretary'">
                        <div class="">
                            <button  mat-button  class="btn btn-primary content-btn"  
                            type="button" [ngStyle]="{'background-color':'DarkOrange'}" (click)="getReportsByStatus('secretary_to_DS')">Outgoing to DS</button>
                        </div>
                        <div class="">
                            <button mat-button class="btn btn-primary content-btn"  
                            id="selectapp" type="button" [ngStyle]="{'background-color':'#0062cc'}" (click)="getReportsByStatus('commissioner_to_secretary')">Incoming from Commissioner</button>
                        </div>
                    </div>
                    <div class="col-sm-7" *ngIf="roleCode == 'bda_ds'">
                        <div class="">
                            <button  mat-button  class="btn btn-primary content-btn"  
                            type="button" [ngStyle]="{'background-color':'DarkOrange'}" (click)="getReportsByStatus('ds_to_em')">Outgoing to EM</button>
                        </div>
                        <div class="">
                            <button mat-button class="btn btn-primary content-btn"  
                            id="selectapp" type="button" [ngStyle]="{'background-color':'#0062cc'}" (click)="getReportsByStatus('secretary_to_DS')">Incoming from Secretary</button>
                        </div>
                    </div>
                    <div class="col-sm-7" *ngIf="roleCode == 'bda_em'">
                        <div class="">
                            <button  mat-button  class="btn btn-primary content-btn"  
                            type="button" [ngStyle]="{'background-color':'DarkOrange'}" (click)="getReportsByStatus('em_to_eo')">Outgoing to EO</button>
                        </div>
                        <div class="">
                            <button mat-button class="btn btn-primary content-btn"  
                            id="selectapp" type="button" [ngStyle]="{'background-color':'#0062cc'}" (click)="getReportsByStatus('ds_to_em')">Incoming from BDA-DS</button>
                        </div>
                    </div>
                    <div class="col-sm-7" *ngIf="roleCode == 'bda_eo'">
                        <div class="">
                            <button  mat-button  class="btn btn-primary content-btn"  
                            type="button" [ngStyle]="{'background-color':'DarkOrange'}" (click)="getReportsByStatus('eo_to_ee')">Outgoing to EE</button>
                        </div>
                        <div class="">
                            <button mat-button class="btn btn-primary content-btn"  
                            id="selectapp" type="button" [ngStyle]="{'background-color':'#0062cc'}" (click)="getReportsByStatus('em_to_eo')">Incoming from BDA-EM</button>
                        </div>
                    </div>
                    <div class="col-sm-7" *ngIf="roleCode == 'bda_ee'">
                        <div class="">
                            <button  mat-button  class="btn btn-primary content-btn"  
                            type="button" [ngStyle]="{'background-color':'DarkOrange'}" (click)="getReportsByStatus('ee_to_aee')">Outgoing to AEE</button>
                        </div>
                        <div class="">
                            <button mat-button class="btn btn-primary content-btn"  
                            id="selectapp" type="button" [ngStyle]="{'background-color':'#0062cc'}" (click)="getReportsByStatus('eo_to_ee')">Incoming from BDA - EO</button>
                        </div>
                    </div>
                    
                    <div class="col-sm-2.5 alignment">
                        <div class=" user-details">
                            <div class="value-text">
                                Welcome 
                            </div>
                            <div class="value-text">
                                {{userName}} !
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-md-8 offset-md-2">
            <app-applicant-report-datatable [statusCode]="statusCode"></app-applicant-report-datatable>
        </div>
        <app-footer></app-footer>
    </div>