import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-tile',
  templateUrl: './tile.component.html',
  styleUrls: ['./tile.component.scss']
})
export class TileComponent implements OnInit {

  @Input()
  public tileName: any;

  @Input()
  private tileType: any;

  @Input()
  public backgroundColor:any;

  @Output() 
  private onClickHandler = new EventEmitter<number>();

  constructor() { }

  ngOnInit(): void {
  }

  onTileClicked = () => {
    this.onClickHandler.emit(this.tileType);
  }
}
