import { Component, HostListener, OnInit } from '@angular/core';
import { MatDialogRef, MatDialog } from '@angular/material/dialog';
import { ApplicationService } from 'src/services/application.service';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-ready-for-reporting-dialogbox',
  templateUrl: './ready-for-reporting-dialogbox.component.html',
  styleUrls: ['./ready-for-reporting-dialogbox.component.scss']
})
export class ReadyForReportingDialogboxComponent implements OnInit {

  statusOptions: any = [];
  public statusCode: any = 'ready_for_reporting';
  public selectedStatusOption: any;

  constructor(private dialogRef: MatDialogRef<ReadyForReportingDialogboxComponent>,private dialog: MatDialog,
    private applicationService: ApplicationService,  private _snackBar: MatSnackBar) { }

  ngOnInit(): void {
    this.getAdjudicationStatusOption();
  }
  getAdjudicationStatusOption(): void {
    this.applicationService.getStatusOption(this.statusCode).subscribe(res =>{
      this.statusOptions = res;
  })
}
  public onOkClicked = () => {
    if(!this.selectedStatusOption) {
      this._snackBar.open("Please choose reporting option");
      return;
    }
    let data = {};
    data['statusOptionId'] = this.selectedStatusOption;
    data['confirmed'] = true;
    this.dialogRef.close(data);
  }

  public onCancelClicked = () => {
    let data = {};
    data['confirmed'] = false;
    this.dialogRef.close(data);
  }

  @HostListener("keydown.esc") 
  public onEsc() {
    this.dialogRef.close(false);
  }
}

