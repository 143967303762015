import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { AuthService } from 'src/services/auth.service';
import { ApplicationService} from 'src/services/application.service';
import { UserService } from 'src/services/user-service/user.service';
import { faEye, faEyeSlash} from '@fortawesome/free-solid-svg-icons';
import { ConfirmDialogService } from 'src/app/shared/confirm-dialog/confirm-dialog.service';
import { StrorageService } from 'src/services/strorage.service';
import { RoleConfigService } from 'src/app/shared/config/role/role.config.service';


@Component({
  selector: 'app-actor_login',
  templateUrl: './actor_login.component.html',
  styleUrls: ['./actor_login.component.scss']
})
export class ActorLoginComponent implements OnInit {

  actors : any[] = [
    {
      "id":"bda_em",
      "name":"BDA EM"
    },
    {
      "id":"bda_eo",
      "name":"BDA EO"
    },
    {
      "id":"bda_ee",
      "name":"BDA EE"
    }
  ];
  selectedActor: string;
  password : string;
  mobileNo : number;
  passwordVisibility = 'password';
  faEye = faEye;
  faEyeSlash = faEyeSlash;
  id: any;
  villageName: any;
  public isLoading = false;
  public heading: string;
  constructor(private applicationService:ApplicationService,private _snackBar: MatSnackBar,
    private userService: UserService, private authService: AuthService, private router: Router, 
    private confirmDialogService: ConfirmDialogService, private roleConfig: RoleConfigService,private storageService:StrorageService) { }

  ngOnInit(): void {
  }
  login(){
    if(!this.isValidLogin()){
      return;
    }
    this.isLoading=true;
    let payload = {};
    payload['mobile'] = this.mobileNo;
    payload['password'] = this.password;
    payload['loginType']=this.selectedActor;
    this.authService.login(payload, this.loginResHandler);
    console.log(payload);
    
 }
  
  loginResHandler = (res: any):void => {
    if(res && res.success) {
      if(this.selectedActor == 'em_to_eo') {
        this.heading = "BDA EM Instance";
      }
      if(this.selectedActor == 'eo_to_ee') {
        this.heading = "BDA EO Instance";
      }
      if(this.selectedActor == 'ee_to_aee') {
        this.heading = "BDA EE Instance";
      }
      this.router.navigate(['./applicant-report-dashboard'], { queryParams: { heading: this.heading }});
      this.openSnackbar('Login successful', 'Dismiss');
      return;
   }
   this.openSnackbar('Invalid credentials', 'Dismiss');
  }

  isValidLogin() : boolean{
     if(!this.selectedActor){
       this.openSnackbar('Please select actor', 'Dismiss');
       return false;
     }

    if(!this.mobileNo){
      this.openSnackbar('Please enter mobile number', 'Dismiss');
      return false;
   }
  
    if(!this.password){
      this.openSnackbar('Please enter password', 'Dismiss');
      return false;
   }
  return true;
}

 openSnackbar(message: string, action: string){
  this._snackBar.open(message, action);
}
  showPassword(){
    this.passwordVisibility = 'password'
  }
  hidePassword(){
    this.passwordVisibility = 'text';
  }
}
