import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-gated-community',
  templateUrl: './gated-community.component.html',
  styleUrls: ['./gated-community.component.scss']
})
export class GatedCommunityComponent implements OnInit {

  @Input() data : any;

  @Input() isEditable : any;

  public gcLength : number;

  public gcBreadth : number;

  public gcTotalarea : number;

  public gcSitalarea : number;

  public siteNumber : string;

  public buildingAddress : string;

  constructor() { }

  ngOnInit(): void {
  }

  reset(){
    this.gcLength = null;
    this.gcBreadth = null;
    this.gcTotalarea = null;
    this.gcSitalarea = null;
  }


  getPayload(){
    let payload = {};
    payload['gcLength'] = this.gcLength;
    payload['gcBreadth'] = this.gcBreadth;
    payload['gcTotalarea'] = this.gcTotalarea;
    payload['gcSitalarea'] = this.gcSitalarea;
    return payload;
  }

  ngOnChanges(data): void{
    if(!this.data){
      return;
    }
    this.gcLength = this.data.gcLength  ? this.data.gcLength.toFixed(2) : this.data.gcLength;
    this.gcBreadth = this.data.gcBreadth ? this.data.gcBreadth.toFixed(2) : this.data.gcBreadth;;
    this.gcTotalarea = this.data.gcTotalarea ? this.data.gcTotalarea.toFixed(2) : this.data.gcTotalarea;;
    this.gcSitalarea = this.data.gcSitalarea ? this.data.gcSitalarea.toFixed(2) : this.data.gcSitalarea;;
    this.siteNumber = this.data.surveyNumber;
    this.buildingAddress = this.data.buildingAddress;
   }

}
