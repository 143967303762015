<div class="container">
    <div>
        <app-top-bar selectedTab="home"></app-top-bar>
        <app-header></app-header>
    </div>
    <br>
   
    <div class="tool-bar">
        <div class="refresh-btn">
        <button mat-button class="btn btn-primary"(click)="clickBack()">Back</button>
    </div>

    <div class="offset-sm-2 title">
        RC Disbursement - Activity Log
    </div>
     
        <div class="col-sm-3 offset-sm-1 user-details">
            <div class="value-text">
                Welcome  <br>
            {{user['firstName']}} !
            </div>
            </div>
        </div>

        
    <div>
        <section>
            <div fxLayout="row" fxLayoutAlign="space-between">
        <div class="col-sm-3 offset-sm-9">
            <div fxLayout="row" fxLayoutAlign="space-around">
            <input class="form-control border-end-0 border rounded-pill" style="font-size: 14px !important;"type="text" [(ngModel)]="applicationNo" placeholder="Search" id="applicationNo">
            <span class="input-group-append"><br>&nbsp;
             <button mat-button class="btn btn-primary " (click)="searchApplicant()">Search</button> 
             <button mat-button *ngIf="applicationNo" matSuffix mat-icon-button aria-label="Clear" (click)="onClear()">
                <mat-icon>close</mat-icon>
              </button>
            </span>
            </div>
        </div>
    </div>
            
            <!-- data table -->
            <mat-card>
                <table mat-table [dataSource]="dataSource">
                    <ng-container matColumnDef="sn">
                        <th mat-header-cell *matHeaderCellDef> Serial No. </th>
                        <td mat-cell *matCellDef="let element ;let i =index;" class="column-container">{{i+1}}
                          </ng-container>

                <ng-container matColumnDef="groupNumber">
                    <th mat-header-cell *matHeaderCellDef>Group ID </th>
                    <td mat-cell *matCellDef="let element">
                      {{element.groupNumber}}
                    </td>
                </ng-container>

                    <!-- Position Column -->
                    <ng-container matColumnDef="applicationNo">
                        <th mat-header-cell *matHeaderCellDef> Application No. </th>
                        <td mat-cell *matCellDef="let element">
                            <a (click)="onPreviewClick(element.applicantId)" class="preview-link">{{element.applicationNo}}</a> 
                        </td>
                    </ng-container>
                    
                    <!-- Name Column -->
                    <ng-container matColumnDef="applicantName">
                        <th mat-header-cell *matHeaderCellDef> Name of the Applicant </th>
                        <td mat-cell *matCellDef="let element"> {{element.applicationName}} </td>
                    </ng-container>
                    
                    <!-- Telephone Column -->
                    <ng-container matColumnDef="villageName">
                        <th mat-header-cell *matHeaderCellDef> Village Name </th>
                        <td mat-cell *matCellDef="let element"> {{element.villageName}} </td>
                    </ng-container>

                    <ng-container matColumnDef="processedBy">
                        <th mat-header-cell *matHeaderCellDef> Processed By </th>
                        <td mat-cell *matCellDef="let element"> {{element.processedBy}} </td>
                    </ng-container>

                    <ng-container matColumnDef="processedOn">
                        <th mat-header-cell *matHeaderCellDef> Processed On </th>
                        <td mat-cell *matCellDef="let element"> {{element.processedDate}} </td>
                    </ng-container>
                    
                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns;" ></tr>
                </table>

                <div class="no-records-text" *ngIf="!dataSource.data || dataSource.data.length <= 0">No Records Found!</div>
                <mat-paginator [pageSize]="pageSize" [pageSizeOptions]="[5, 10, 20]" [showFirstLastButtons]="true" 
                [pageIndex]="currentPage" [length]="totalSize" (page)="getApplicantPaginatorData($event)">
            </mat-paginator>
            </mat-card>
            
        </section>
    </div>
 <app-footer></app-footer>
 </div>

