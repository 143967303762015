
import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { PageEvent } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { Router, ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs/internal/Observable';
import { map } from 'rxjs/internal/operators/map';
import { startWith } from 'rxjs/internal/operators/startWith';
import { ApplicationService } from 'src/services/application.service';
import { JccnoEncodeDecodeService } from 'src/services/jccno-encode-decode/jccno-encode-decode.service';
import { StrorageService } from 'src/services/strorage.service';
import { UserService } from 'src/services/user-service/user.service';


@Component({
  selector: 'app-field-visit-user-dashboard',
  templateUrl: './field-visit-user-dashboard.component.html',
  styleUrls: ['./field-visit-user-dashboard.component.scss']
})
export class FieldVisitUserDashboardComponent implements OnInit {

  public village: any;
  public user: any;
  public villageId: any;
  public displayedColumns: string[] = ['sn','applicationNo','status'];
  public pageSize = 5;
  public currentPage = 0;
  public totalSize = 0;
  public dataSource = new MatTableDataSource();
  myGroup = new FormGroup({
    applicationNo: new FormControl()
 });
  options: any[] = [];
  filteredOptions: Observable<any[]>;
  applicationNo=null;
  title: any;

  constructor(private router: Router, private route: ActivatedRoute,public dialog: MatDialog,private userService: UserService,private jccEncodeDecoder: JccnoEncodeDecodeService,
    private applicationService: ApplicationService,private storageService:StrorageService,private cdr:ChangeDetectorRef) {
      this.title=this.route.snapshot.queryParams['title'];
     }
    
  ngOnInit(): void {
    this.user = this.storageService.getUser();
    this.village = this.user['villageDTO'];
    this.villageId = this.village['id'];
    this.router.navigate(['/field-visit-user-dashboard']);
    this.getApplicants();
  }

  public showActivityLog = () => {
    this.router.navigate(['/field-visit-user-activity-log']);
  }
  public getApplicantPaginatorData = (event: PageEvent) => {
    this.currentPage = event.pageIndex;
    this.pageSize = event.pageSize;
    this.getApplicants();
  }

 searchApplicant(){
   this.getApplicants();
 }
 onClear(){
   this.applicationNo="";
   this.getApplicants();
 }
 
  displayFn(user: any): string {
    return user && user.name ? user.name : '';
  }

 
  private getApplicants = () => {
      this.applicationService.getFieldUserApplicants(this.currentPage, this.pageSize, this.applicationNo).subscribe(res =>{
        this.refreshDataTable(res);
        console.log(res);
        
      });
  }

  private _filter(name: string): any[] {
    const filterValue = name.toLowerCase();
    return this.options.filter(option => option.toLowerCase().indexOf(filterValue) === 0);
  }

  private refreshDataTable = (res: any) => {
    this.totalSize = res.totalRecord;
    this.dataSource.data = res.data;
    this.dataSource._updateChangeSubscription();
   this.cdr.detectChanges();
  }
  onPreviewClick = (id: any) => {
    this.router.navigate(['/field-visit-workbench'], {queryParams:{application_id:id}});
   }

}
