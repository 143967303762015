import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class RoleConfigService {

  ROLE_AUDIT_HELP_DESK: string = "audit_helpdesk";

  ROLE_ONLINE_APPLICATION_USER: string = "online_application_user";

  ROLE_SCRUTINY_USER: string = "scrutiny_user";

  ROLE_SCRUTINY_ADMIN: string = "scrutiny_admin";

  ROLE_COMMITTEE: string = "comittee_user";

  ROLE_HELP_DESK_USER: string = "helpdesk_user";

  ROLE_ADMIN_USER: string = "admin_user";

  ROLE_FIELD_USER: string = "field_user";

  ROLE_ADJUDICATION_USER : string = "adjudication_user";

  ROLE_BDA_COMMR : string = "bda_commr";

  ROLE_BDA_SECRETARY : string = "bda_secretary";

  ROLE_BDA_DS : string = "bda_ds";

  ROLE_PI_USER : string = "pi_user";

  ROLE_RC_DISBURSEMENT : String = "rc_disbursement"

  ROLE_RC_REGISTER_USER : String = "rc_register_user"

  ROLE_FOR_SD_DATA_VERIFIER : String = "rc_sd_data_verifier"

  ROLE_FOR_CD_DATA_VERIFIER : String = "rc_cd_data_verifier"

  ROLE_HOT_LINE_USER : String = "hot_line_user"



  constructor() { }
}
