<div class="jumbotron">
<div class="row">
    <div class="col-sm-10 offset-sm-1">
    <div class="row">
        <div class="col-sm-2 offset-md-1"> 
            <hr> 
        </div>
     <div class="col-sm-6">
      <h1 class="display-4 header-text">{{ 'content_headers.commitee_text' | translate }}</h1>
    </div>
    <div class="col-sm-2 ">  
        <hr> 
    </div>
    </div>
</div>
</div>
    <div class="row">
    <div class="col-sm-6 offset-sm-3 content">
        {{ 'content_headers.commitee_name' | translate }}
    </div>
    
    </div>
    </div>
