<div fxFlex="100%">
    <div fxFlex="25" class="side-menu-container">
        <app-side-menu [menus]="menus" (onMenuClick)="onItemClicked($event)"></app-side-menu>
    </div>
    <div fxFlex="80" style="padding-left: 55px">
        <app-site-details [isEditable]="isEditable" [data]="data" [hidden]="selectedMenu['code'] != 'site_details'"></app-site-details>
        <app-residental-house [isEditable]="isEditable" [data]="data" [hidden]="selectedMenu['code'] != 'residential_house'"></app-residental-house>
        <app-commercial-building [isEditable]="isEditable" [data]="data" [hidden]="selectedMenu['code'] != 'commercial_industrial'"></app-commercial-building>
        <app-apartment [isEditable]="isEditable" [data]="data" [hidden]="selectedMenu['code'] != 'apartment'"></app-apartment>
        <app-gated-community [isEditable]="isEditable" [data]="data" [hidden]="selectedMenu['code'] != 'gated_community'"></app-gated-community>
        <app-other-property-details [isEditable]="isEditable" [data]="data" [hidden]="selectedMenu['code'] != 'others'"></app-other-property-details>
        <div fxLayout="row" style="margin-top: 20px">
            <button fxFlexOffset="70" class="btn btn-next" (click)="onSubmit()">Save & Continue</button>
        </div>
    </div>
</div>
    