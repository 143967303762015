import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { faSync } from '@fortawesome/free-solid-svg-icons';
import { ApplicationService } from 'src/services/application.service';
import { StrorageService } from 'src/services/strorage.service';
import { MatTableDataSource } from '@angular/material/table';
import { PageEvent } from '@angular/material/paginator';
import { ActivatedRoute, Router } from '@angular/router';


@Component({
  selector: 'app-rc-register-default-datatable',
  templateUrl: './rc-register-default-datatable.component.html',
  styleUrls: ['./rc-register-default-datatable.component.scss']
})
export class RcRegisterDefaultDatatableComponent implements OnInit {
 

  constructor(private storageService:StrorageService,private cdr:ChangeDetectorRef,
    private applicationService: ApplicationService, private router: Router,
    private route: ActivatedRoute) {
      this.selectedStatusCode = this.route.snapshot.queryParams['appliantStatus'];
      this.pageHeader = this.route.snapshot.queryParams['header'];
     }

  public faSync = faSync;
  
  public isRefreshing = false;

  public displayedColumns: string[] = ['sn','groupNumber','groupSize','jccNo', 'applicantName', 'batchName', 'timeStamp', 'recievedBy'];

  public pageSize =5;

  public currentPage = 0;

  public dataSource = new MatTableDataSource<any>();

  public totalSize: number= 0;

  public pageHeadingData: any;

  public pageHeader: any;

  reportData: any;

  manipalApplicantsCount: any;

  user: any;

  village: any;

  villageId: any;

  villages: any[];

  applicationNo: any;

  selectedStatusCode: any;

  statusCode: string;

  heading: any;

  show: boolean;


  ngOnInit(): void {
  this.user=this.storageService.getUser();
    this.fetchVillages();
    this.fetchRcRegisterActivityLog();
  }

  fetchVillages(){
    this.applicationService.fetchAllVillages().subscribe(res=>{
     this.villages = res;
     this.villages.unshift({
       "id":"",
       "name":"All"
     });
     })
   }

   searchApplicant(){
    this.resetPagination();
    this.fetchRcRegisterActivityLog();
  }
  resetPagination() {
    this.currentPage = 0;
    this.pageSize = 5;
  }

  onVillageChange(): void{
      this.show=false;
      this.fetchRcRegisterActivityLog();
    }
  

  fetchRcRegisterActivityLog(){
    if(this.selectedStatusCode == 'sent_to_manipal') {
      this.statusCode = 'sent_to_manipal';
      this.heading = this.pageHeader;
    }
    if(this.selectedStatusCode == 'rc_printed_and_received') {
      this.statusCode = 'rc_printed_and_received';
      this.heading = this.pageHeader;
    }
    this.applicationService.fetchRcRegisterDatatables(this.statusCode,this.applicationNo,this.villageId,this.currentPage,this.pageSize).subscribe(res=>{;
      this.reportData = res;
      this.refreshApplicantDataTable(res);
      this.manipalApplicantsCount = res.data.length;
      console.log(res);
     })
   }

   private refreshApplicantDataTable = (res: any) => {
    this.dataSource.data = res.data;
    this.totalSize = res.totalRecord;
    this.currentPage = (res.currentPage - 1);
    this.dataSource._updateChangeSubscription();
    if (!this.cdr['destroyed']) {
      this.cdr.detectChanges();
    }
  }

  public getApplicantPaginatorData(event: PageEvent, dataSource: any) {
    this.currentPage = event.pageIndex + 1;
    this.pageSize = event.pageSize;
    for(let i=0; i < dataSource.length;i++) {
      this.selectedStatusCode = dataSource[i].statusCode;
    }
      this.fetchRcRegisterActivityLog();
  }
  onClear() {
    this.applicationNo = null;
    this.fetchRcRegisterActivityLog();
  }
  clickBack(){
    window.history.back();
  }
  onPreviewClick(rcRegisterId: any, applicantId:any) {
    console.log(rcRegisterId);
    this.router.navigate(['/rc-register-form-view'],{ queryParams: { rcRegisterId :rcRegisterId, applicantId:applicantId} })
  }
}