import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { PageEvent } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute } from '@angular/router';
import { faSync } from '@fortawesome/free-solid-svg-icons';
import { ApplicationService } from 'src/services/application.service';
import { StrorageService } from 'src/services/strorage.service';


@Component({
  selector: 'app-land-hand-over-survey-activity-log',
  templateUrl: './land-hand-over-survey-activity-log.component.html',
  styleUrls: ['./land-hand-over-survey-activity-log.component.scss']
})
export class LandHandOverSurveyActivityLogComponent implements OnInit {

  public faSync = faSync;
  public displayedColumns: string[] = [];
  public dataSource = new MatTableDataSource<any>();
  public pageSize =5;
  public totalSize = 0;
  public currentPage = 0;
  reconType:string;
  villageName:number;
  surveyId:number;
  reconTypeId: number;
  surveyNo: string;
  villageId: number;
  surveyType: string;
  downloadUrl: any;

  constructor(private applicationService: ApplicationService, 
    private cdr:ChangeDetectorRef,  private storageService:StrorageService, 
    public route: ActivatedRoute) {
      this.reconType = this.route.snapshot.queryParams['reconciliationCode'];
      this.villageId= this.route.snapshot.queryParams['villageId'];
      this.surveyId = this.route.snapshot.queryParams['surveyId'];
      this.surveyType = this.route.snapshot.queryParams['surveyNotificationType'];
     }

  ngOnInit(): void {
    this.fetchSelectedReconciliationByCode();
    if(this.reconType == 'cases_compensation_paid_in_cash'){
      this.displayedColumns = ['slNo','villageName','surveyNo','extent',  'cashDispensed','awardedDate', 'paymentDate', 'comments'];
    }
    if(this.reconType == 'ec_issued'){
      this.displayedColumns = ['slNo','villageName','surveyNo','extent',  'eligibleSqft','entitledSqft', 'ecIssuedDate', 'comments'];
    }
  }

  fetchSelectedReconciliationByCode () {
    this.applicationService.fetchTypeByReconciliationCode(this.reconType).subscribe(res => {
      this.updateReconType(res);
      this.getHistoryBySurveyReconId();
    });
  }

  updateReconType(res: any) {
    this.reconTypeId = res.id;
  }

  getHistoryBySurveyReconId () {
    this.applicationService.getHistoryBySurveyReconId(this.reconType, this.villageId, this.surveyId,this.surveyType).subscribe(res => {
      this.surveyNo = res.surveyNumber;
      if(this.reconType == 'cases_compensation_paid_in_cash'){
        this.dataSource.data = res;
      }
      if(this.reconType == 'ec_issued'){
        this.dataSource.data = res;
      }
    });
  }
 
  downloadDataAsExcelSheet(){
    try{
      this.applicationService.getReconciliationVeiwXls(this.reconType, this.villageId, this.surveyId,this.surveyType).subscribe(res => {
        this.downloadUrl = res.fileUrl;
        console.log(res);
        this.downloadFile(this.downloadUrl);
      });
    }catch(e){
      console.log(e);
    }
  }
  downloadFile(url: string) {
    let link = document.createElement('a');
    link.href = url;
    link.download = url.substring(url.lastIndexOf('/') + 1);
    link.style.display = 'none';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }
}
