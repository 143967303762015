import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { faSync } from '@fortawesome/free-solid-svg-icons';
import { PageEvent } from '@angular/material/paginator';
import { MessageDialogService } from 'src/app/shared/message-dialog/message-dialog.service';
import { ApplicationService } from 'src/services/application.service';
import { Router } from '@angular/router';
import { StrorageService } from 'src/services/strorage.service';


@Component({
  selector: 'app-rc-register-dashboard',
  templateUrl: './rc-register-dashboard.component.html',
  styleUrls: ['./rc-register-dashboard.component.scss']
})
export class RcRegisterDashboardComponent implements OnInit {
  user: any;
  dataSource: any;
  cdr: any;
  isRefreshing: boolean;
  public faSync = faSync;
  public applicantCount: any;
  public batchProcessingCount: any;
  public batchingReadyApplicantsCount: any;
  public sentToManipalApplicantsCount: any;
  public rcPrintedAndReceivedApplicantsCount: any;
  public forSdDataValidationApplicantsCount: any;
  forRcApplicantsCount: any;

  constructor(private applicationService: ApplicationService,
    private router: Router,private storageService:StrorageService,
    private messageDialogService: MessageDialogService) { }


    ngOnInit(): void {
      this.user=this.storageService.getUser();
      this.fetchApplicantCountByStatus();
    
    }

    fetchApplicantCountByStatus(){
      this.applicationService.fetchRcRegisterApplicantsCountByStatusCode().subscribe(res=>{
       for(let i= 0;i < res.length;i++) {
        if(res[i].label == 'RC Register') {
          this.forRcApplicantsCount = res[i].count;
        }
        if(res[i].label == 'Batching Ready') {
          this.batchingReadyApplicantsCount = res[i].count;
        }
        if(res[i].label == 'Sent to Manipal') {
          this.sentToManipalApplicantsCount = res[i].count;
        }
        if(res[i].label == 'RC Printed & Received') {
          this.rcPrintedAndReceivedApplicantsCount = res[i].count;
        }
        if(res[i].label == 'For Sd Data Validation') {
          this.forSdDataValidationApplicantsCount = res[i].count;
        }
       }

        console.log(res);
       })
     }
   

    public showActivityLog = () => {
      this.router.navigate(['/rc-register-activity-log']);
    }
    
    clickBack(){
      window.history.back();
  
    }

     refreshClick(){
      let currentComponent = this;
      this.isRefreshing = true;
      setTimeout(()=>{
        currentComponent.isRefreshing = false;
      }, 3000)
      
    }
    public OnDataEnrichmentClick = ()=> {
      this.router.navigate(['/rc-register-data-enrichment']);
    }

    public OnBatchProcessingClick = ()=>{
      this.router.navigate(['/rc-register-batch-processing']);
    }

    public onSentToManipalClick = ()=>{
      this.router.navigate(['/rc-default-datatable'], {queryParams:{header:'Sent To Manipal', appliantStatus:'sent_to_manipal'}});
    }

    public onRcReceivedClick = ()=>{
      this.router.navigate(['/rc-default-datatable'], {queryParams:{header:'Rc Printed And Received', appliantStatus:'rc_printed_and_received'}});
    }
  
}