import { Component, OnInit, ChangeDetectorRef, ViewChild } from '@angular/core';
import { ApplicationService } from 'src/services/application.service';
import { faSync } from '@fortawesome/free-solid-svg-icons';
import { MessageDialogService } from 'src/app/shared/message-dialog/message-dialog.service';
import { ConfirmDialogService } from 'src/app/shared/confirm-dialog/confirm-dialog.service';
import { Router } from '@angular/router';
import { StatusOptionTilesComponent } from '../status-option-tiles/status-option-tiles.component';
import { StrorageService } from 'src/services/strorage.service';

@Component({
  selector: 'app-committe-ready-for-reporting',
  templateUrl: './committe-ready-for-reporting.component.html',
  styleUrls: ['./committe-ready-for-reporting.component.scss']
})
export class CommitteReadyForReportingComponent implements OnInit {
  selectedData: any;
  public faSync = faSync;
  public villages: any = [];
  public selectVillage : any;
  public statusOptionId: any;
  public statusCode: any = 'ready_for_reporting';
  public colors = ['#E74C3C', '#1ABB9C', '#3498DB', '#F39C12', '#9B59B6', '#50C1CF', '#34495E', '#E9967A', '#FA8072', '#E74C3C','#E74C3C', '#1ABB9C', '#3498DB', '#F39C12', '#9B59B6', '#50C1CF', '#34495E'];
  @ViewChild(StatusOptionTilesComponent)
  public statusOptionTilesComponent: StatusOptionTilesComponent;
  subStatus: any;

  constructor(private applicationService: ApplicationService,private router: Router, private cdr:ChangeDetectorRef,
    private messageDialogService: MessageDialogService,private confirmDialogService: ConfirmDialogService, 
    private storageService: StrorageService) {
     // this.selectedData = JSON.parse(localStorage.getItem("datas"))
  }

  ngOnInit(): void {
    
  }

  public loadStatusOptionTilesData(): void {
    this.statusOptionTilesComponent.fetchApplicantByStatusOption();
  }

  public onApiLoaded(data: any): void {
    this.onTilesClickedCallback(data);
  }
  
  onTilesClickedCallback(data: any): void {
    this.statusOptionId = data.id;
    this.subStatus = data.name;
    console.log(data);
    this.loadReadyForReportingAggregatedData();
  }

  public loadReadyForReportingAggregatedData(): void{
    this.applicationService.fetchApplicantCountForReadyForReportingByVillage(this.statusOptionId).subscribe(res =>{
      this.villages = res;
    })
  }

  public resetApplicantData(): void {
    this.loadStatusOptionTilesData();
    this.villages = [];
  }

  onVillageChange(village:any) {
    this.selectVillage=village.id;
  }
  
  onViewApplicants(): void {
    this.storageService.addItemToLocalStorage('selected_status_option', this.statusOptionId);
    this.router.navigate(['community-view-applicants'], { queryParams: { statusCode: this.statusCode, statusOptionId: this.statusOptionId , subStatus: this.subStatus}});
  }
}
