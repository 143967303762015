<div class="container">
    <div>
        <app-top-bar selectedTab="home"></app-top-bar>
        <app-header></app-header>
    </div>
    <br>
   
    <div class="tool-bar" style="margin-top: -25px;">
        <div class="refresh-btn">
        <button mat-button class="btn btn-primary"(click)="clickBack()">Back</button>
    </div>
    <div class="col-sm-9 title-header">
        ACTIVITY LOG
    </div>
     
    <div class="col-sm-3 offset-sm-1 user-details">
            <div class="value-text">
                Welcome  <br>
            {{user['firstName']}} !
            </div>
            </div>
    </div>
         
    <div>
        <div class="col-sm-2 offset-sm-9 village-details " >
            <mat-form-field appearance="fill">
                <mat-label>Village</mat-label>
                <mat-select id="village" name="villageId" [(ngModel)]="villageId">
                  <mat-option *ngFor="let village of villages" [value]="village.id" (click)="onVillageChange(village.id)">
                    {{village.id}} {{village.name}}
                  </mat-option>
                </mat-select>
    
              </mat-form-field>
              <div class="p1">
              Total: {{villageCount}}
            </div>
        </div>
        <div class="col-sm-3 offset-sm-1 style" >
            <div fxLayout="row" fxLayoutAlign="space-around">
            <input class="form-control border-end-0 border rounded-pill" style="font-size: 14px !important;"type="text" [(ngModel)]="awardSklNumber" placeholder="Search" id="awardSklNumber">
            <span class="input-group-append"><br>&nbsp;
             <button mat-button class="btn btn-primary " (click)="searchApplicant()">Search</button> 
             <button mat-button *ngIf="awardSklNumber" matSuffix mat-icon-button aria-label="Clear" (click)="onClear()">
                <mat-icon>close</mat-icon>
              </button>
            </span>
            </div>
        </div>
    </div>
    <br>
    <div class="col-sm-3" style="margin-left: -17px;margin-left: -10px;">
        <mat-form-field appearance="fill">
            <mat-label>Status</mat-label>
            <mat-select id="status" name="selectedStatus" [(ngModel)]="selectedStatus">
                <mat-option *ngFor="let status of statusList" [value]="status.statusCode" (click)="onStatusSelected(selectedStatus)">
                  {{status.status}} 
                </mat-option>
            </mat-select>
          </mat-form-field>
          <div class="p1">
          Total: {{statusCount}}
        </div>
    </div>
    <div class="row application-form-row col-sm-5 offset-9">
        <label class="form-label">Survey Filter</label>
        <select class="form-textbox" placeholder="All" [(ngModel)] = "selectedSurveyNumber" (change)="onSurveyNumberChange(selectedSurveyNumber)" >
            <option [ngValue]="null"></option>
            <option *ngFor = "let survey of surveyNumbers" [value]="survey.id">{{survey.surveyNumber}}</option>
        </select>
        <button mat-button *ngIf="selectedSurveyNumber" matSuffix mat-icon-button aria-label="Clear" (click)="onClearSurveyNumber()">
            <mat-icon>close</mat-icon>
          </button>
      </div>
            <!-- data table -->
            <mat-card>
                <table mat-table [dataSource]="dataSource">
                    <ng-container matColumnDef="sn">
                        <th mat-header-cell *matHeaderCellDef> Serial No. </th>
                        <td mat-cell *matCellDef="let element ;let i =index;" class="column-container">{{i+1}}
                          </ng-container>
                    <!-- Position Column -->
                    <ng-container matColumnDef="awardSklNumber">
                        <th mat-header-cell *matHeaderCellDef> Award Skl Number </th>
                        <td mat-cell *matCellDef="let element">
                            <a  target="_blank" [routerLink]="['/award-master-activity-log-view']" [queryParams]="{applicant_id: element.id}" class="preview-link">{{element.awardSklNumber}} </a> 
                        </td>
                    </ng-container>
                    
                    <!-- Name Column -->
                    <ng-container matColumnDef="awardNumber">
                        <th mat-header-cell *matHeaderCellDef> AwardNumber </th>
                        <td mat-cell *matCellDef="let element"> {{element.awardNumber}} </td>
                    </ng-container>
                    
                    <!-- Telephone Column -->
                    <ng-container matColumnDef="surveyNumber">
                        <th mat-header-cell *matHeaderCellDef> Survey Number </th>
                        <td mat-cell *matCellDef="let element"> {{element.surveyNumber}} </td>
                    </ng-container>

                     <!-- Document Column -->
                     <ng-container matColumnDef="documentName">
                        <th mat-header-cell *matHeaderCellDef> Document Name </th>
                        <td mat-cell *matCellDef="let element">
                             <a href="{{element.enclosureURL}}" target="_blank">{{element.documentName}}</a>
                        </td>
                    </ng-container>

                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns;" ></tr>
                </table>
                <div class="no-records-text" *ngIf="!dataSource.data || dataSource.data.length <= 0">No Records Found!</div>
                <mat-paginator [pageSize]="pageSize" [pageSizeOptions]="[5, 10, 20]" [showFirstLastButtons]="true" 
                [pageIndex]="currentPage" [length]="totalSize" (page)="getApplicantPaginatorData($event)">
            </mat-paginator>
            </mat-card>
 <app-footer></app-footer>
 </div>

