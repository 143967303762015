<div class="container">
    <div>
        <app-top-bar selectedTab="home"></app-top-bar>
        <app-header></app-header>
    </div>
    <br>
    <div class="col-sm-1 ">
        <button class="btn btn-primary refresh-btn" (click)="refreshClick()">
            <fa-icon [icon]="faSync" class="close-icon" [spin]="isRefreshing"></fa-icon>
        </button>
    </div>
    <div class="tool-bar">
        <div class="refresh-btn">
        <button mat-button class="btn btn-primary"(click)="clickBack()">Back</button>
    </div>

            <br>
            <div class="col-sm-8 heading">
                Award Scrutiny Dashboard
            </div>
            
        <div class="col-sm-3 offset-sm-1 user-details">
            <div class="value-text">
                Welcome  <br>
            {{user['firstName']}} !
            </div>
            </div>
        </div> 
    <div>
    <div class="col-sm-11 title" style="margin-top: -4rem;">
        {{villageName}}
    </div>
    
        <div class="col-sm-3 offset-sm-12 Search">
            
            <div fxLayout="row" fxLayoutAlign="space-around">
            <input class="form-control border-end-0 border rounded-pill" style="font-size: 14px !important;"type="text" [(ngModel)]="awardSklNumber" placeholder="Search" id="awardSklNumber">
            <span class="input-group-append"><br>&nbsp;
             <button mat-button class="btn btn-primary " (click)="searchApplicant()">Search</button> 
             <button mat-button *ngIf="awardSklNumber" matSuffix mat-icon-button aria-label="Clear" (click)="onClear()">
                <mat-icon>close</mat-icon>
              </button>
            </span>
           
            </div>
        </div>
        <div class="row application-form-row col-sm-5 offset-8">
            <label class="form-label">Survey Filter</label>
            <select class="form-textbox" placeholder="All" [(ngModel)] = "selectedSurveyNumber" (change)="onSurveyNumberChange(selectedSurveyNumber)" >
                <option *ngFor = "let survey of surveyNumbers" [value]="survey.id">{{survey.surveyNumber}}</option>
            </select>
            <button mat-button *ngIf="selectedSurveyNumber" matSuffix mat-icon-button aria-label="Clear" (click)="onClearSurveyNumber()">
                <mat-icon>close</mat-icon>
              </button>
          </div>
    </div>           

            <!-- data table -->
            <mat-card>
                <table mat-table [dataSource]="dataSource">
                    <ng-container matColumnDef="sn">
                        <th mat-header-cell *matHeaderCellDef> Serial No. </th>
                        <td mat-cell *matCellDef="let element ;let i =index;" class="column-container">{{i+1}}
                          </ng-container>
                    <!-- Position Column -->
                    <ng-container matColumnDef="awardSklNumber">
                        <th mat-header-cell *matHeaderCellDef> Award Skl No. </th>
                        <td mat-cell *matCellDef="let element">
                            <a (click)="onPreviewClick(element.id)" class="preview-link">{{element.awardSklNumber}}</a>
                        </td>
                    </ng-container>
                    
                    <ng-container matColumnDef="surveyNumber">
                        <th mat-header-cell *matHeaderCellDef> Survey Number </th>
                        <td mat-cell *matCellDef="let element"> {{element.surveyNumber}} </td>
                    </ng-container>
                    <!-- Name Column -->
                    <ng-container matColumnDef="awardNumber">
                        <th mat-header-cell *matHeaderCellDef> Award Number </th>
                        <td mat-cell *matCellDef="let element"> {{element.awardNumber}} </td>
                    </ng-container>

                    <ng-container matColumnDef="documentName">
                        <th mat-header-cell *matHeaderCellDef> Document Name </th>
                        <td mat-cell *matCellDef="let element"> {{element.documentName}} </td>
                    </ng-container>
            
                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns;" ></tr>
                </table>
                <div class="no-records-text" *ngIf="!dataSource.data || dataSource.data.length <= 0">No Records Found!</div>
                <mat-paginator [pageSize]="pageSize" [pageSizeOptions]="[5, 10, 20]" [showFirstLastButtons]="true" 
                [pageIndex]="currentPage" [length]="totalSize" (page)="getApplicantPaginatorData($event)">
            </mat-paginator>
            </mat-card>
 <app-footer></app-footer>
 </div>

