<div class="container">
  <div>
      <app-top-bar selectedTab="home"></app-top-bar>
      <app-header></app-header>
  </div>
  <br>
<div class="container mb-5">
  <div class="row">
    <div class="refresh-btn col-sm-2">
    </div>
    <div class="col-sm-5 title ">
      <h2 class="font-fam heading-format " *ngIf="roleCode != 'comittee_user'">SECON - Dashboard</h2>
      <h2 class="font-fam heading-format " *ngIf="roleCode == 'comittee_user'">COMMITTE - Dashboard</h2>
    </div>

    <div class="col-sm-2 offset-sm-1 user-details">
      <div class="value-text">
          Welcome  <br>
      {{user['firstName']}} !
      </div>
      </div>
  </div>

<div class="row mt-3">
  <div class="col-sm-6 d-flex justify-content-around">
    <button mat-button class="text-center btn content-btn btn-width w-50 text-white font-fam btn-font btn-border" id="selectapp" type="button"
      (click)="onDUClick()"
      [ngStyle]="{ 'background-color': this.currentTab === 'DU' ?  '#4472c4' : '#9C9C9C;' }">Daily Updates from the
      Field</button>
  </div>

  <div class="col-sm-6 d-flex justify-content-around">
    <button mat-button class="text-center btn content-btn btn-width w-50 text-white font-fam btn-font btn-border" type="button"
      (click)="onPDFClick()"
      [ngStyle]="{ 'background-color': this.currentTab === 'PDF' ?  '#4472c4' : '#9C9C9C;' }">PDF Upload</button>
  </div>
</div>
<div *ngIf="this.currentTab==='DU'">
  <app-dpa-activity-log-common ></app-dpa-activity-log-common>
</div>
<div *ngIf="this.currentTab==='PDF'">
  <app-secon-pdf-upload ></app-secon-pdf-upload>
</div>
<app-footer></app-footer>
</div>