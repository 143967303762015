import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { environment } from 'src/environments/environment';
import { ApplicationService } from 'src/services/application.service';
import { StrorageService } from 'src/services/strorage.service';
import { faSync } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-land-hand-over-status-dashboard',
  templateUrl: './land-hand-over-status-dashboard.component.html',
  styleUrls: ['./land-hand-over-status-dashboard.component.css']
})
export class LandHandOverStatusDashboardComponent implements OnInit {

  public comprehensiveMapUrl: string;
  selectedHeading: any;

  public selectedSectorCode: any;

  public title: any;

  previousHeading: any;

  public villageId: any = '';

  public villages: any[] = [{ id: '', name: 'All' }];

  //surveyNumber: any;

  surveyNumbers: any[] = [];

  selectedSurveyNumber: any;

  public landNotifiedInAcres: any;

  awardApprovedStatus: any;

  areaAwardedInAcres: any;

  noOfCashCompensation: any;

  cashCompensationInAcres: any;

  totalExtentInCash: any;

  noOfEcIssued: any;

  ecIssuedInAcres: any;

  ecIssuedInAllotedSite: any;

  isViewTransactionBtn: boolean = false;
  areaExemptedTotalExtentInAcres: any;

  areaApprovedTotalExtentInAcres: any;

  villageName: any;
  cashDispensed: any;
  entitledAllottedSite: any;
  eligibleAllottedSite: any;
  surveyId: any = '';
  selectedSurveyData: any = '';
  totalCashEarMarked: null;
  developedSiteEarmarked: null;
  slaoName: any;
  user: any;
  public faSync = faSync;

  isRefreshing: boolean;
  totalSurveyNoCount: any;
  processedSurveyCount: any;
  villageAndSurveyData: any;
  totalNotificationType: boolean = true;
  NewNotificationType: boolean = false;
  oldNotificationType: boolean = false;
  RMPNotificationType: boolean = false;
  surveyNotificationType:  any = '';
  ecDateCount: string;
  casesDateCount: string;

  public colors = ['#E74C3C', '#1ABB9C', '#3498DB', '#F39C12', '#9B59B6', '#50C1CF', '#34495E', '#E9967A', '#FA8072', '#4FE6F8','#E74C3C','#E74C3C', '#1ABB9C', '#3498DB', '#F39C12', '#9B59B6', '#50C1CF', '#34495E','#F1544A','#C9AC59','#999384','#ed7d31', '#5b9bd5','#1f4e79','#c378fc', '#0070c0','#18E2AB'];
  downloadUrl: any;


  constructor(private router: Router, private route: ActivatedRoute, private applicationService: ApplicationService, private storageService: StrorageService) {
    this.comprehensiveMapUrl = environment.BASE_URL + 'layout_formation/layout_map/Comphrehensive.pdf';
    this.selectedHeading = this.route.snapshot.queryParams["selectedHeading"];
    this.selectedSectorCode = this.route.snapshot.queryParams["selectedSectorCode"];
    this.title = this.route.snapshot.queryParams["title"];
    this.previousHeading = this.route.snapshot.queryParams["previousHeading"];
    this.user = this.storageService.getUser();
  }

  ngOnInit() {
    this.fetchVillages();
    this.fetchSlaoHistoryDatas();
    this.fetchAllVillageAndProcessedSurveyNos();
  }

  fetchVillages() {
    this.applicationService.fetchAllVillages().subscribe(res => {
      for (let i = 0; i < res.length; i++) {
        this.villages.push(res[i]);
      }
    });
  }
  fetchAllVillageAndProcessedSurveyNos() {
    this.applicationService.fetchAllVillageAndProcessedSurveyNos(this.surveyNotificationType).subscribe(res => {
      this.villageAndSurveyData = res;
      console.log(this.villageAndSurveyData);
    });
  }

  clickBack() {
    window.history.back();

  }
  refreshClick() {
    let currentComponent = this;
    this.isRefreshing = true;
    setTimeout(() => {
      currentComponent.isRefreshing = false;
    }, 3000)
    this.isViewTransactionBtn = false;
    this.fetchSlaoHistoryDatas();
    this.fetchAllVillageAndProcessedSurveyNos();
  }

  getSlaoDataByVillage() {
    this.applicationService.getVillageSlaoData(this.villageId).subscribe(res => {
      if (this.villageId != null && this.villageId != "") {
        this.slaoName = res.firstName;
      }
    })
  }

  getSurveyNumbersByVillageId(villageId: number) {
    this.surveyNumbers = [];
    this.applicationService.getAdditionalSurveyNos(villageId, this.surveyNotificationType).subscribe(res => {
      this.surveyNumbers.push({ id: '', surveyNo: 'All' });
      for (let i = 0; i < res.length; i++) {
        this.surveyNumbers.push(res[i]);
      }
    });
  }

  onVillageChange(village: any) {
    this.villageId = village.id;
    this.villageName = village.name;
    this.fetchSlaoHistoryDatas();
    if (this.villageId != null && this.villageId != "") {
      this.getSlaoDataByVillage();
      this.getSurveyNumbersByVillageId(this.villageId);
    } else {
      this.slaoName = null;
    }
  }

  onSurveyNumberChange(surveyId: any) {
    this.surveyId = surveyId;
    if (this.surveyId == "") {
      this.isViewTransactionBtn = false;
    } else {
      this.isViewTransactionBtn = true;
    }
    this.fetchSlaoHistoryDatas();
  }

  onClearSurveyNumber() {
    this.selectedSurveyData = '';
    this.fetchSlaoHistoryDatas();
  }

  fetchSlaoHistoryDatas() {
    this.applicationService.getSlaoHistoryData(this.villageId, this.surveyId, this.surveyNotificationType).subscribe(res => {
      console.log(res);
      this.resetUI();
      this.updateUI(res);
      if (this.surveyId) {
        this.isViewTransactionBtn = true;
      }
    })
  }

  updateUI(data: any) {
    if (data) {
      this.totalSurveyNoCount = data.totalSurveyNoCount;
      this.processedSurveyCount = data.processedSurveyCount;
      this.ecDateCount = data.ecDateCount;
      this.casesDateCount = data.casesDateCount;
      let landNotifiedData = data.landNotifiedData;
      if (landNotifiedData != null) {
        this.landNotifiedInAcres = landNotifiedData.totalExtentInAcres;
      }

      let awardsApprovedData = data.awardsApprovedData;
      if (awardsApprovedData != null) {
        this.awardApprovedStatus = awardsApprovedData.isAwardApproved;
        this.areaAwardedInAcres = awardsApprovedData.areaAwardedExtentInAcres;
        this.areaExemptedTotalExtentInAcres = awardsApprovedData.scExemptedExtentInAcres;
        this.areaApprovedTotalExtentInAcres = awardsApprovedData.approvedExtentInAcres;
        if(this.isViewTransactionBtn) {
          this.totalCashEarMarked = awardsApprovedData.totalCashEarMarkedStr;
          this.developedSiteEarmarked = awardsApprovedData.developedSiteEarmarkedStr;
        }else {
          this.totalCashEarMarked = awardsApprovedData.totalCashEarMarked;
          this.developedSiteEarmarked = awardsApprovedData.developedSiteEarmarked;
        }
      }

      let casesCompensationData = data.casesCompensationData;
      if(casesCompensationData) {
        if(this.villageId && this.surveyId) {
          this.noOfCashCompensation = casesCompensationData.totalDelta;
          this.cashCompensationInAcres = casesCompensationData.totalExtentInAcres;
          this.cashDispensed = casesCompensationData.totalCashDispensedStr;
        } else {
          this.noOfCashCompensation = casesCompensationData.delta;
          this.cashCompensationInAcres = casesCompensationData.totalExtentInAcres;
          this.cashDispensed = casesCompensationData.cashDispensed;
        }
      }

      let ecIssuedData = data.ecIssuedData;
      if(ecIssuedData) {
        if (this.villageId && this.surveyId) {
          this.noOfEcIssued = ecIssuedData.totalDelta;
          this.ecIssuedInAcres = ecIssuedData.totalExtentInAcres;
          this.eligibleAllottedSite = ecIssuedData.totalEligibleAllottedSiteStr;
          this.entitledAllottedSite = ecIssuedData.totalEntitledAllottedSiteStr;
        } else {
          this.noOfEcIssued = ecIssuedData.delta;
          this.ecIssuedInAcres = ecIssuedData.totalExtentInAcres;
          this.eligibleAllottedSite = ecIssuedData.eligibleAllottedSite;
          this.entitledAllottedSite = ecIssuedData.entitledAllottedSite;
        }
      }
    }
  }
  public resetUI() {
    this.landNotifiedInAcres = null;
    this.awardApprovedStatus = null;
    this.areaAwardedInAcres = null;
    this.cashCompensationInAcres = null;
    this.noOfCashCompensation = null;
    this.noOfEcIssued = null;
    this.ecIssuedInAcres = null;
    this.ecIssuedInAllotedSite = null;
    this.totalCashEarMarked = null;
    this.developedSiteEarmarked = null;
    this.cashDispensed = null;
    this.eligibleAllottedSite = null;
    this.entitledAllottedSite = null;
    this.areaAwardedInAcres = null;
    this.areaExemptedTotalExtentInAcres = null;
    this.areaApprovedTotalExtentInAcres = null;
    this.totalCashEarMarked = null;
    this.developedSiteEarmarked = null;
    this.processedSurveyCount = null;
    this.totalSurveyNoCount = null;
    this.ecDateCount = null;
    this.casesDateCount = null;
  }

  public onViewBtnClicked() {
    this.router.navigate(['land-hand-over-status-activity-log'], { queryParams: { villageId: this.villageId, surveyId: this.surveyId, villageName: this.villageName, slaoName: this.slaoName } });
  }

  viewReconciliationHistory(reconciliationCode: any){
    window.open('#/land-hand-over-survey-activity-log'+this.newTabUrlConstructor(reconciliationCode));
  }

  newTabUrlConstructor(reconciliationCode: any){
    let queryParam:any = "?reconciliationCode="+reconciliationCode+"&villageName="+this.villageName;
    if(this.villageId) {
      queryParam = queryParam + "&villageId="+this.villageId;
    }
    if(this.surveyId) {
      queryParam = queryParam + "&surveyId="+this.surveyId;
    }if(this.surveyNotificationType){
      queryParam = queryParam +"&surveyNotificationType="+this.surveyNotificationType;
    }
    return queryParam;
  }

  downloadAllAsExcelsheet() {
    this.applicationService.getReconciliationHistoryExcelsheet().subscribe(res=>{
      this.downloadUrl = res.errorFileUrl;
      console.log(res);
      this.downloadFile(this.downloadUrl);
    })
  }

  downloadFile(url: string) {
    let link = document.createElement('a');
    link.href = url;
    link.download = url.substring(url.lastIndexOf('/') + 1);
    link.style.display = 'none';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  surveyBtnClicked(surveyType: any) {
    if(surveyType != null) {
      this.surveyNotificationType = surveyType;
      this.fetchAllVillageAndProcessedSurveyNos();
      this.fetchSlaoHistoryDatas();
    }
    if(this.surveyNotificationType == 'NEW') {
      this.NewNotificationType = true;
      this.totalNotificationType = false;
      this.oldNotificationType = false;
      this.RMPNotificationType = false;
      this.clearVillageAndSurveyNumber();
    }else if(this.surveyNotificationType == 'OLD') {
      this.oldNotificationType = true;
      this.NewNotificationType = false;
      this.totalNotificationType = false;
      this.RMPNotificationType = false;
      this.clearVillageAndSurveyNumber();
    }else if(this.surveyNotificationType == 'RMP') {
      this.RMPNotificationType = true;
      this.NewNotificationType = false;
      this.totalNotificationType = false;
      this.oldNotificationType = false;
      this.clearVillageAndSurveyNumber();
    }else{
      this.totalNotificationType = true;
      this.RMPNotificationType = false;
      this.NewNotificationType = false;
      this.oldNotificationType = false;
      this.clearVillageAndSurveyNumber();
    }
  }

  public clearVillageAndSurveyNumber() {
    this.villageId = null;
    this.surveyId = null;
    this.fetchSlaoHistoryDatas();
    this.fetchAllVillageAndProcessedSurveyNos();
  }
  
}
