import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { PageEvent } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { PiUserActivityLogComponent } from 'src/app/pi-user/pi-user-activity-log/pi-user-activity-log.component';
import { ApplicationService } from 'src/services/application.service';
import { StrorageService } from 'src/services/strorage.service';

@Component({
  selector: 'app-rc-disbursement-activity-log',
  templateUrl: './rc-disbursement-activity-log.component.html',
  styleUrls: ['./rc-disbursement-activity-log.component.scss']
})
export class RcDisbursementActivityLogComponent implements OnInit {
  public selectedStatusOption: any;
  public apiURL: string;
  public statusCode: any;
  public pageSize =5;
  public currentPage = 0;
  public totalSize: number= 0;
  public totalCount: number= 0;
  statusList: any = [];
  selectedStatus:any;
  selectedStatusOptionCode: any;
  totalApplicants: any;
  status_List:any=[];
  statusOptions: any = [];

  public displayedColumns: string[] = ['sn','groupNumber','applicationNo','applicantName','villageName', 'processedBy', 'processedOn'];
  
  public dataSource = new MatTableDataSource<any>();
  user: any;
  village: any;
  villageId: any;
  applicationNo = null;
  statusOption: any;
  statusOptionId:any;
  public totalApplicantCount: any = 0;

  constructor(private applicationService: ApplicationService,private cdr:ChangeDetectorRef,private dialogRef: MatDialogRef<PiUserActivityLogComponent>, 
    private router: Router,private storageService:StrorageService) { }

   ngOnInit(): void {
     this.user=this.storageService.getUser();
     this.fetchRcDisbursementActivityLogs();   
  }

  onClear() {
    this.applicationNo = null;
    this.fetchRcDisbursementActivityLogs();
  }

  public fetchRcDisbursementActivityLogs = () => {
    this.applicationService.fetchRcDisbursementActivityLogs(this.currentPage,this.pageSize,this.applicationNo).subscribe(res => {
      this.refreshDataTable(res);
      console.log(res);
    })
  }
    
  private refreshDataTable = (res: any) => {
    this.dataSource.data = res.data;
    this.totalSize = res.totalRecord;
    this.currentPage = (res.currentPage - 1);
    this.dataSource._updateChangeSubscription();
    if (!this.cdr['destroyed']) {
      this.cdr.detectChanges();
    }
  }

  public getApplicantPaginatorData(event: PageEvent) {
    this.currentPage = event.pageIndex + 1;
    this.pageSize = event.pageSize;
    this.fetchRcDisbursementActivityLogs();
  }
  searchApplicant(){
    this.resetPagination();
    this.fetchRcDisbursementActivityLogs();
  }
  resetPagination() {
    this.currentPage = 0;
    this.pageSize = 5;
  }

  clickBack(){
    window.history.back();

  }
  onPreviewClick(applicantId: any) {
    this.router.navigate(['/rc-disbursement-activity-log-view'],{ queryParams: { applicantId :applicantId} })
  }

}