import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { PageEvent } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { faSync } from '@fortawesome/free-solid-svg-icons';
import { AdjudicationUserTrackActivityService } from 'src/app/adjudication-user/services/adjudication-user-track-activity.service';
import { ConfirmDialogService } from 'src/app/shared/confirm-dialog/confirm-dialog.service';
import { MessageDialogService } from 'src/app/shared/message-dialog/message-dialog.service';
import { ApiService } from 'src/services/api.service';
import { ApplicationService } from 'src/services/application.service';
import { StrorageService } from 'src/services/strorage.service';
import { StatusOptionTilesComponent } from '../status-option-tiles/status-option-tiles.component';

@Component({
  selector: 'app-commitee-rc-prep',
  templateUrl: './commitee-rc-prep.component.html',
  styleUrls: ['./commitee-rc-prep.component.scss']
})
export class CommiteeRcPrepComponent implements OnInit {
  selectedData: any;
  public faSync = faSync;
  public statuses: any = [];
  public groups: any = [];
  public selectVillage : any;
  public statusOptionId: any;
  public panchayats : any[] = [];
  public statusCode: any = 'ready_for_reporting';
  public totalSize: number= 0;
  public totalCount: number= 0;
  statusList: any = [];
  public pageSize =5;
  public currentPage = 0;



  village: any;
  villages : any[];
  selectedVillages: number;
  user: any;
  show: boolean;
  villageId: any;
  public colors = ['#E74C3C', '#1ABB9C', '#3498DB', '#F39C12', '#9B59B6', '#50C1CF', '#34495E', '#E9967A', '#FA8072', '#E74C3C','#E74C3C', '#1ABB9C', '#3498DB', '#F39C12', '#9B59B6', '#50C1CF', '#34495E'];
  @ViewChild(StatusOptionTilesComponent)
  public statusOptionTilesComponent: StatusOptionTilesComponent;
  isRefreshing: boolean;
  staticsComponent: any;
  isCommitteeUser: any;
  public totalApplicantCount: number= 0;
  public totalGroupCount: number= 0;
  selectedStatusOption: boolean;
  selectedStatus: any;
  linkedApplicants:any;
  validApplicants:any;
  groupId:any;
  groupNo:any;
  applicationNo:any;
  villageName:any;

  public displayedColumns: string[] = ['sn','groupId', 'groupSize','jccNO', 'villageName', 'activeStatus','notificationType', 'isValid'];

  private adjudicationUserLastActivity: any;

  public dataSource = new MatTableDataSource<any>();
  statusOption: any;
  CommiteeRcPrepWorkbenchComponent: any;
  title: any;
  groupSize:any;


  constructor(private applicationService: ApplicationService,private router: Router, private cdr:ChangeDetectorRef,
    private messageDialogService: MessageDialogService,private confirmDialogService: ConfirmDialogService, private apiService:ApiService,
    private adjudicationUserTrackActivityService: AdjudicationUserTrackActivityService,

    private storageService: StrorageService) {
      
     // this.selectedData = JSON.parse(localStorage.getItem("datas"))
  }

  ngOnInit(): void {
    this.adjudicationUserLastActivity = this.adjudicationUserTrackActivityService.getLastPreDashboardActivity();

    this.user=this.storageService.getUser();
    this.fetchAllGroupsList();
    this.fetchVillages();
    this.fetchLinkedApplicants();
    this.getDashboardCount();
  }
  fetchLinkedApplicants() : void {
    this.applicationService.fetchLinkedApplicantsList(this.currentPage,this.pageSize, 
      this.applicationNo, this.villageId,this.groupNo).subscribe(res =>{
      this.linkedApplicants = res;
      this.refreshDataTable(res);
    })
  }
  
  public fetchAllGroupsList(){
    this.applicationService.fetchAllGroups().subscribe(res =>{
      let total = 0;
      for(let i =0;i < res.length;i++) {
        total = total + parseInt(res[i].applicationCount);
      }
      this.totalApplicantCount = total;
      this.groups = res;
      this.groups.unshift({
        "id":"",
        "name":"All"
       });
       
    });
  }
  private refreshDataTable = (res: any) => {
    this.dataSource.data = res.data;
    this.totalSize = res.totalRecord;
    this.currentPage = (res.currentPage - 1);
    this.dataSource._updateChangeSubscription();
    if (!this.cdr['destroyed']) {
      this.cdr.detectChanges();
    }
  }

  fetchVillages(){
    this.applicationService.fetchAllVillages().subscribe(res=>{
      console.log(res);
      this.villages = res;
      this.villages.unshift({
        "id":"",
        "name":"All"
       });
    })
  }
  onVillageChange() {
    this.show=false;
    this.fetchLinkedApplicants();
  }
  onGroupChange() {
    this.show=false;
    this.fetchLinkedApplicants();
    this.fetchGroupApplicantsCount();
  }
 
  
  onViewApplicants(): void {
    this.storageService.addItemToLocalStorage('selected_status_option', this.statusOptionId);
    this.router.navigate(['community-view-applicants'], { queryParams: { statusCode: this.statusCode, statusOptionId: this.statusOptionId }});
  }
  clickBack(){
    window.history.back();

  }
  onPreviewClick(id:any){
    this.router.navigate (['/commitee-rc-prep-workbench'],{ queryParams: { application_id:id,title:this.title}});
    console.log(id)
  }
  refreshClick(){
    let currentComponent = this;
    this.isRefreshing = true;
    setTimeout(()=>{
      currentComponent.isRefreshing = false;
    }, 3000)
    this.fetchAllGroupsList();
    this.fetchLinkedApplicants();
   if(this.show){
     this.fetchVillages();
   }
  }
  searchApplicant(){
    this.currentPage = 0;
    this.pageSize = 5;
    this.fetchLinkedApplicants();
  }
  searchGroups(){
    this.currentPage = 0;
    this.pageSize = 5;
    this.fetchLinkedApplicants();
  }
  onClear() {
    this.applicationNo = null;
    this.fetchLinkedApplicants();
  }
  onClearGroup() {
    this.groupNo = null;
    this.fetchLinkedApplicants();
  }
  public getApplicantPaginatorData(event: PageEvent) {
    this.currentPage = event.pageIndex;
    this.pageSize = event.pageSize;
    this.fetchLinkedApplicants();
  }
  fetchGroupApplicantsCount() : void {
    this.applicationService.fetchGroupApplicantsCount(this.villageId,
      this.applicationNo, this.groupNo).subscribe(res =>{
      this.refreshDataTable(res);
    })
  }
  getDashboardCount() {
    this.applicationService.fetchGroupApplicantsCount(this.villageId,this.applicationNo,this.groupNo).subscribe(res =>{
      console.log("filter count yet to be process",res.data);
      let data = res.data;
      this.totalSize = data;
      console.log(data);
    });
}
}
