import { Component, OnInit } from '@angular/core';
import { StrorageService } from 'src/services/strorage.service';
import { ApplicationService } from 'src/services/application.service';
import { DatePipe } from '@angular/common';
import { Router } from '@angular/router';

@Component({
  selector: 'app-dpa-generation-workbench',
  templateUrl: './dpa-generation-workbench.component.html',
  styleUrls: ['./dpa-generation-workbench.component.scss']
})
export class DpaGenerationWorkbenchComponent implements OnInit {

  constructor(private storageService:StrorageService, private applicationService: ApplicationService,
    private router:Router) { 
    //this.applicantId = this.route.snapshot.queryParams['applicantId'];
  }

  userData: any;

  firstName: string;

  startDate: any;

  toDate: any;

  startDateFormat: any;

  endDateFormat: any;


  maxDate = new Date();

  public title:string = '';

  ngOnInit() {
    window.scroll(0,0);
    this.userData = this.storageService.getUser();
    if(this.userData){
      this.firstName = this.userData['firstName'];
    }
    console.log(this.startDate);
  }
  templateData: any[];
  
  onStartDateChangeEvent(event: any){
    let startDate = event.value;
    var datePipe = new DatePipe("en-US");
    startDate = datePipe.transform(startDate, 'dd/MM/yyyy');
    this.startDateFormat = startDate;
  }

  public showActivityLog = () => {
    this.router.navigate(['/dpa-activity-log']);
  }
  clickBack(){
    window.history.back();
  }
  onEndDateChangeEvent(event: any){
    let endDate = event.value;
    var datePipe = new DatePipe("en-US");
    endDate = datePipe.transform(endDate, 'dd/MM/yyyy');
    this.endDateFormat = endDate;
  }

}
