import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { faSync } from '@fortawesome/free-solid-svg-icons';
import { ApplicationService } from 'src/services/application.service';
import { PageEvent } from '@angular/material/paginator';
import { Location } from '@angular/common';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-committe-view-report',
  templateUrl: './committe-view-report.component.html',
  styleUrls: ['./committe-view-report.component.scss']
})
export class CommitteViewReportComponent implements OnInit {

  public isRefreshing = false;
  public faSync = faSync;
  public selectedDateFilter: any;
  villages : any[];
  public displayedColumns: string[] = ['reportId','batchName','generatedBy','generatedAt','batchSize','actions'];
  public dataSource = new MatTableDataSource();
  public show = false;
  public pagesize = 5;
  public currentPage = 0;
  public totalSize = 0;
  public selectVillage;
  public applicantCount : any;
  public selectedId : any;
  public colors = ['#E74C3C', '#1ABB9C', '#3498DB', '#F39C12', '#9B59B6', '#50C1CF', '#34495E', '#E9967A', '#FA8072', '#E74C3C','#E74C3C', '#1ABB9C', '#3498DB', '#F39C12', '#9B59B6', '#50C1CF', '#34495E'];
  statusOptionId: any;
  
  constructor(private applicationService: ApplicationService, 
    private location: Location,private route: ActivatedRoute,
    private cdr:ChangeDetectorRef) {
      this.statusOptionId = this.route.snapshot.queryParams['statusOptionId'];
     }

  ngOnInit(): void {
    this.getAllReports();
  }

  public getAllReports(): void {
    this.applicationService.getAllReports( "reported",this.currentPage, this.pagesize).subscribe(res =>{
      this.dataSource.data = res;
      this.refreshReportBatchesDataTable(res);

    })
  }

  public refreshClick(): void {
    this.currentPage = 0;
    this.pagesize = 5;
    this.getAllReports();
  }

  public goBack(): void {
    this.location.back();
  }

  public getApplicantPaginatorData(event: PageEvent) {
    this.currentPage = event.pageIndex;
    this.pagesize = event.pageSize;
    this.getAllReports();
  }

  private refreshReportBatchesDataTable = (res: any) => {
     this.show = true;
     this.dataSource.data = res.data;
     this.totalSize = res.totalRecord;
     this.currentPage = (res.currentPage - 1);
     this.dataSource._updateChangeSubscription();
     this.cdr.detectChanges();
   }
}
