<div class ="container">
    <div class="row content-area"><br>
        <br>
        <br>
        <div class="header">
            JOURNEY MAP CANVAS
        </div>
        
        <div class="label col-sm-10"  *ngIf = "applicationData">
      
            <div class ="col-sm-12 text-title">JCC NO:{{applicationData.applicationNo}}<br>{{title}}</div>
          
            <div >
                <button (click)="clickBack()"class="back-link">Back</button>
             </div>
           <div>
            <button  (click) ="encloPage()" class="enclosure-link">Enclosure </button> 
        </div>   
     <br>
    <br>
    <br>
        <div class="sub-title">
               Personal Details
            </div>
            
            <div class="row" >
                <div class="col-sm-8 " >
                    <label >Name of the applicant</label>
                </div>
                <div class="col-sm-4">
                    <label >{{applicationData.applicantName}}</label>
                  </div>
            </div>
            <div class="row" >
                <div class="col-sm-8 " >
                    <label >Name of the Village</label>
                </div>
                <div class="col-sm-4">
                    <label >{{ applicationData.villageDTO.name}}</label>
                  </div>
            </div>
           
            <div class="row" >
                <div class="col-sm-8 " >
                    <label >Address of the applicant</label>
                </div>
                <div class="col-sm-4">
                    <label >{{applicationData.applicantAddress}}</label>
                  </div>
            </div>
            <div class="row" >
                <div class="col-sm-8 " >
                    <label >Landline Number</label>
                </div>
                <div class="col-sm-4">
                    <label >{{applicationData.telephoneNumber}}</label>
                  </div>
            </div>
            <div class="row" >
                <div class="col-sm-8 " >
                    <label >Mobile Number</label>
                </div>
                <div class="col-sm-4">
                    <label >{{applicationData.mobileNumber}}</label>
                  </div>
            </div>
            <div class="row" >
                <div class="col-sm-8 " >
                    <label >Email</label>
                </div>
                <div class="col-sm-4">
                    <label >{{applicationData.email}}</label>
                  </div>
            </div>
            <div class="row" >
                <div class="col-sm-8 " >
                    <label >Survey Number & Site Number</label>
                </div>
                <div class="col-sm-4">
                    <label >{{applicationData.surveyNumber}}</label>
                  </div>
            </div>
        
            <div class="sub-title">
                Site Details
             </div>
            <div class="row" >
                <div class="col-sm-8 " >
                    <label >Length of the site</label>
                </div>
                <div class="col-sm-4">
                    <label >{{applicationData.siteLength}}</label>
                  </div>
            </div>
            <div class="row" >
                <div class="col-sm-8 " >
                    <label >Width of the site</label>
                </div>
                <div class="col-sm-4">
                    <label >{{applicationData.siteBreadth}}</label>
                  </div>
            </div>
            <div class="row" >
                <div class="col-sm-8 " >
                    <label >Total Area in Sq ft</label>
                </div>
                <div class="col-sm-4">
                    <label >{{applicationData.siteArea}}</label>
                  </div>
            </div>
            <div class="row" >
                <div class="col-sm-8 " >
                    <label >Site North</label>
                </div>
                <div class="col-sm-4">
                    <label >{{applicationData.siteNorth}}</label>
                  </div>
            </div>
            <div class="row" >
                <div class="col-sm-8 " >
                    <label >Side East</label>
                </div>
                <div class="col-sm-4">
                    <label >{{applicationData.siteEast}}</label>
                  </div>
            </div>
            <div class="row" >
                <div class="col-sm-8 " >
                    <label >Site West</label>
                </div>
                <div class="col-sm-4">
                    <label >{{applicationData.siteWest}}</label>
                  </div>
            </div>
            <div class="row" >
                <div class="col-sm-8 " >
                    <label >Site South</label>
                </div>
                <div class="col-sm-4">
                    <label >{{applicationData.siteSouth}}</label>
                  </div>
            </div>
            <div class="row" >
                <div class="col-sm-8 " >
                    <label >Khata Type</label>
                </div>
                <div class="col-sm-4">
                    <label >{{applicationData.khataBbmp == 'Y' ? 'BBMP' : applicationData.khataGp == 'Y' ? 'Gram Panchayat' : applicationData.khataBda ? 'BDA' : '' }}</label>
                  </div>
            </div>
            <div class="row" >
                <div class="col-sm-8 " >
                    <label >Khata Number </label>
                </div>
                <div class="col-sm-4">
                    <label >{{applicationData.khataNumber}}</label>
                  </div>
            </div>
            <div class="row" >
                <div class="col-sm-8 " >
                    <label >Khata Date </label>
                </div>
                <div class="col-sm-4">
                    <label >{{applicationData.khataDate | date:'dd/MM/yyyy'}}</label>
                  </div>
            </div>
            <div class="row" >
                <div class="col-sm-8 " >
                    <label >Address of the building</label>
                </div>
                <div class="col-sm-4">
                    <label >{{ applicationData.buildingAddress}}</label>
                  </div>
            </div>
            <div class="sub-title">
                Residential House Details
             </div>
            <div class="row" >
                <div class="col-sm-8 " >
                    <label >Residential Builtup area </label>
                </div>
                <div class="col-sm-4">
                    <label >{{applicationData.resArea}}</label>
                  </div>
            </div>
            <div class="row" >
                <div class="col-sm-8 " >
                    <label >Residential No of floors </label>
                </div>
                <div class="col-sm-4">
                    <label >{{applicationData.resFloors}}</label>
                  </div>
            </div>
            <div class="row" >
                <div class="col-sm-8 " >
                    <label >Residential Total Area </label>
                </div>
                <div class="col-sm-4">
                    <label >{{applicationData.resTotalarea}}</label>
                  </div>
            </div>
            <div class="sub-title">
                Commercial/Industrial Building Details
             </div>
            <div class="row" >
                <div class="col-sm-8 " >
                    <label >Commercial Build up area </label>
                </div>
                <div class="col-sm-4">
                    <label >{{applicationData.commArea}}</label>
                  </div>
            </div>
            <div class="row" >
                <div class="col-sm-8 " >
                    <label >Commercial No of floors </label>
                </div>
                <div class="col-sm-4">
                    <label >{{applicationData.commFloors}}</label>
                  </div>
            </div>
            <div class="row" >
                <div class="col-sm-8 " >
                    <label >Commercial Total Area </label>
                </div>
                <div class="col-sm-4">
                    <label >{{applicationData.commTotalarea}}</label>
                  </div>
            </div>
            <div class="row" >
                <div class="col-sm-8 " >
                    <label >purpose/use</label>
                </div>
                <div class="col-sm-4">
                    <label >{{applicationData.commPurpose}}</label>
                  </div>
            </div>
            <div class="sub-title">
                Apartment / High-rise / Multi-dwelling  Details
             </div>
             <div class="row" >
                <div class="col-sm-8 " >
                    <label > Area of apartment/Total area of Complex</label>
                </div>
                <div class="col-sm-4">
                    <label >{{applicationData.aptTotalarea}}</label>
                  </div>
            </div>
            <div class="row" >
                <div class="col-sm-8 " >
                    <label > Built up area</label>
                </div>
                <div class="col-sm-4">
                    <label >{{applicationData.aptBuildUpArea}}</label>
                  </div>
            </div>
            <div class="row" >
                <div class="col-sm-8 " >
                    <label > Floors/Blocks</label>
                </div>
                <div class="col-sm-4">
                    <label >{{applicationData.aptFloors}}</label>
                  </div>
            </div>
            <div class="sub-title">
                Gated Community   Details
             </div>
            <div class="row" >
                <div class="col-sm-8 " >
                    <label > Gated Community Length</label>
                </div>
                <div class="col-sm-4">
                    <label >{{applicationData.gcLength}}</label>
                  </div>
            </div>
            <div class="row" >
                <div class="col-sm-8 " >
                    <label > Gated Community Breadth</label>
                </div>
                <div class="col-sm-4">
                    <label >{{applicationData.gcBreadth}}</label>
                  </div>
            </div>
            <div class="row" >
                <div class="col-sm-8 " >
                    <label > Gated Community Total Area</label>
                </div>
                <div class="col-sm-4">
                    <label >{{applicationData.gcTotalarea}}</label>
                  </div>
            </div>
            <div class="row" >
                <div class="col-sm-8 " >
                    <label > Gated Community Sital Area</label>
                </div>
                <div class="col-sm-4">
                    <label >{{applicationData.gcSitalarea}}</label>
                  </div>
            </div>
    
            <div class="sub-title">
                Other Property  Details
             </div>
             <div class="row" >
                <div class="col-sm-8 " >
                    <label >Build up area </label>
                </div>
                <div class="col-sm-4">
                    <label >{{applicationData.otherArea}}</label>
                  </div>
            </div>
            <div class="row" >
                <div class="col-sm-8 " >
                    <label >Total Area </label>
                </div>
                <div class="col-sm-4">
                    <label >{{applicationData.otherTotalarea}}</label>
                  </div>
            </div>
            <div class="row" >
                <div class="col-sm-8 " >
                    <label >purpose/use</label>
                </div>
                <div class="col-sm-4">
                    <label >{{applicationData.otherPurpose}}</label>
                  </div>
            </div>
            <div class="sub-title">
                Constructed with approval 
             </div>
                <div class="row" >
                <div class="col-sm-8 " >
                    <label >Is constructed with approval</label>
                </div>
                <div class="col-sm-4">
                    <label >{{applicationData.constNoAproval ? 'Yes' : applicationData.constNoAproval != null && applicationData.constNoAproval != undefined ? 'No' : ''}}</label>
                  </div>
            </div>
            <div class="sub-title">
                BDA approved layout  Details
             </div>
            <div class="row" >
                <div class="col-sm-8 " >
                    <label > BDA approval Layout LP Number</label>
                </div>
                <div class="col-sm-4">
                    <label >{{applicationData.bdaLayoutAprovalNumber}}</label>
                  </div>
            </div>
            <div class="row" >
                <div class="col-sm-8 " >
                    <label > BDA approval Layout Date </label>
                </div>
                <div class="col-sm-4">
                    <label >{{applicationData.bdaLayoutAprovalDate | date:'dd/MM/yyyy'}}</label>
                  </div>
            </div>
            <div class="sub-title">
                Building Plan approved by BDA Details
             </div>
            <div class="row" >
                <div class="col-sm-8 " >
                    <label >  Building plan approved by BDA LP Number</label>
                </div>
                <div class="col-sm-4">
                    <label >{{applicationData.bdaAprovalNumber}}</label>
                  </div>
            </div>
            <div class="row" >
                <div class="col-sm-8" >
                    <label >  Building plan approved by BDA Date </label>
                </div>
                <div class="col-sm-4">
                    <label >{{applicationData.bdaAprovalDate | date:'dd/MM/yyyy'}}</label>
                  </div>
            </div>
            <div class="sub-title">
                Approval from panchayat Details
             </div>
            <div class="row" >
                <div class="col-sm-8 " >
                    <label > Approval from panchayat LP Number</label>
                </div>
                <div class="col-sm-4">
                    <label >{{applicationData.gpAprovalNumber}}</label>
                  </div>
            </div>
            <div class="row" >
                <div class="col-sm-8 " >
                    <label > Panchayat Date of approval</label>
                </div>
                <div class="col-sm-4">
                    <label >{{applicationData.gpAprovalDate | date:'dd/MM/yyyy'}}</label>
                  </div>
            </div>
            <div class="row" >
                <div class="col-sm-8 " >
                    <label > Approval from Panchayat Name</label>
                </div>
                <div class="col-sm-4">
                    <label >{{selectedPanchayat}}</label>
                  </div>
            </div>
            <div class="sub-title">
                Approval from BBMP Details
             </div>
            <div class="row" >
                <div class="col-sm-8 " >
                    <label > BBMP Approval Number</label>
                </div>
                <div class="col-sm-4">
                    <label >{{applicationData.bbmpAprovalNumber}}</label>
                  </div>
            </div>
            <div class="row" >
                <div class="col-sm-8 " >
                    <label > BBMP Date of approval</label>
                </div>
                <div class="col-sm-4">
                    <label >{{applicationData.bbmpAprovalDate | date:'dd/MM/yyyy'}}</label>
                  </div>
            </div>
            <div class="sub-title">
                Conversion order of DC Details
             </div>
            <div class="row" >
                <div class="col-sm-8" >
                    <label >Conversion order of DC Approval Number</label>
                </div>
                <div class="col-sm-4">
                    <label >{{applicationData.dcAprovalNumber}}</label>
                  </div>
            </div>
            <div class="row" >
                <div class="col-sm-8 " >
                    <label >Conversion order of DC Date of approval</label>
                </div>
                <div class="col-sm-4">
                    <label >{{applicationData.dcAprovalDate | date:'dd/MM/yyyy'}}</label>
                  </div>
            </div>
            <div class="sub-title">
                Commencement Certificate Details
             </div>
            <div class="row" >
                <div class="col-sm-8 " >
                    <label >Commencement Number</label>
                </div>
                <div class="col-sm-4">
                    <label >{{applicationData.commenceNumber}}</label>
                  </div>
            </div>
            <div class="row" >
                <div class="col-sm-8 " >
                    <label >Commencement Date</label>
                </div>
                <div class="col-sm-4">
                    <label >{{applicationData.commenceDate | date:'dd/MM/yyyy'}}</label>
                  </div>
            </div>
            <div class="row" >
                <div class="col-sm-8 " >
                    <label >Commencement Issuing Authority</label>
                </div>
                <div class="col-sm-4">
                    <label >{{applicationData.commenceAuth}}</label>
                  </div>
            </div>
            <div class="sub-title">
                Completion Certificate  Details
             </div>
            <div class="row" >
                <div class="col-sm-8 " >
                    <label >Completion Number</label>
                </div>
                <div class="col-sm-4">
                    <label >{{applicationData.completionNumber}}</label>
                  </div>
            </div>
            <div class="row" >
                <div class="col-sm-8 " >
                    <label >Completion Date</label>
                </div>
                <div class="col-sm-4">
                    <label >{{applicationData.completionDate | date:'dd/MM/yyyy'}}</label>
                  </div>
            </div>
            <div class="row" >
                <div class="col-sm-8 " >
                    <label >Completion Issuing Authority</label>
                </div>
                <div class="col-sm-4">
                    <label >{{applicationData.completionAuth}}</label>
                  </div>
            </div>
            <div class="sub-title">
                Occupancy Certificate Details
             </div>
            <div class="row" >
                <div class="col-sm-8 " >
                    <label >Occupancy Number</label>
                </div>
                <div class="col-sm-4">
                    <label >{{applicationData.occupancyNumber}}</label>
                  </div>
            </div>
            <div class="row" >
                <div class="col-sm-8 " >
                    <label >Occupancy Date</label>
                </div>
                <div class="col-sm-4">
                    <label >{{applicationData.occupancyDate | date:'dd/MM/yyyy'}}</label>
                  </div>
            </div>
            <div class="row" >
                <div class="col-sm-8 " >
                    <label >Occupancy Issuing Authority</label>
                </div>
                <div class="col-sm-4">
                    <label >{{applicationData.occupancyAuth}}</label>
                  </div>
            </div>
        
            <div class="sub-title">
                Sanctions of temporary electricity connection Details
             </div>
            <div class="row" >
                <div class="col-sm-8 " >
                    <label >Electricity Temporary connection RR No</label>
                </div>
                <div class="col-sm-4">
                    <label >{{applicationData.bescomTempNumber}}</label>
                  </div>
            </div>
            <div class="row" >
                <div class="col-sm-8 " >
                    <label >Electricity Temporary connection Date</label>
                </div>
                <div class="col-sm-4">
                    <label >{{applicationData.bescomTempDate | date:'dd/MM/yyyy'}}</label>
                  </div>
            </div>
            <div class="sub-title">
                Sanctions of Permanent electricity connection Details
             </div>
            <div class="row" >
                <div class="col-sm-8 " >
                    <label >Electricity Permanent connection RR No</label>
                </div>
                <div class="col-sm-4">
                    <label >{{applicationData.bescomPermNumber}}</label>
                  </div>
            </div>
            <div class="row" >
                <div class="col-sm-8 " >
                    <label >Electricity Permanent connection Date</label>
                </div>
                <div class="col-sm-4">
                    <label >{{applicationData.bescomPermDate | date:'dd/MM/yyyy'}}</label>
                  </div>
            </div>
            <div class="sub-title">
                Sanctions of temporary water connection Details
             </div>
            <div class="row" >
                <div class="col-sm-8 " >
                    <label >Water Temporary connection RR No</label>
                </div>
                <div class="col-sm-4">
                    <label >{{applicationData.bwssbTempNumber}}</label>
                  </div>
            </div>
            <div class="row" >
                <div class="col-sm-8 " >
                    <label >Water Temporary connection Date</label>
                </div>
                <div class="col-sm-4">
                    <label >{{applicationData.bwssbTempDate | date:'dd/MM/yyyy'}}</label>
                  </div>
            </div>
            <div class="sub-title">
                Sanctions of permanent water connection Details
             </div>
            <div class="row" >
                <div class="col-sm-8 " >
                    <label >Water Permanent connection RR No</label>
                </div>
                <div class="col-sm-4">
                    <label >{{applicationData.bwssbPermNumber}}</label>
                  </div>
            </div>
            <div class="row" >
                <div class="col-sm-8 " >
                    <label >Water Permanent connection Date</label>
                </div>
                <div class="col-sm-4">
                    <label >{{applicationData.bwssbPermDate | date:'dd/MM/yyyy'}}</label>
                  </div>
            </div>
            <div class="sub-title">
                Details of loan availed  Details
             </div>
            <div class="row" >
                <div class="col-sm-8 " >
                    <label >Financier/Financial Institution/Bank</label>
                </div>
                <div class="col-sm-4">
                    <label >{{applicationData.bankName}}</label>
                  </div>
            </div>
            <div class="row" >
                <div class="col-sm-8 " >
                    <label >Amount Sanctioned</label>
                </div>
                <div class="col-sm-4">
                    <label >{{applicationData.vsanctionAmount}}</label>
                  </div>
            </div>
            <div class="row" >
                <div class="col-sm-8 " >
                    <label >Duration of payment</label>
                </div>
                <div class="col-sm-4">
                    <label >{{applicationData.paymentDuration}}</label>
                  </div>
            </div>
            <div class="row" >
                <div class="col-sm-8 " >
                    <label >Single/Joint application</label>
                </div>
                <div class="col-sm-4">
                    <label >{{applicationData.singleApplication == 'Y' ? 'Single Application' : applicationData.jointApplication == 'Y' ? 'Joint Application' : ''}}</label>
                  </div>
            </div>
            <div class="sub-title">
                Last loan paid receipt Details
             </div>
            <div class="row" >
                <div class="col-sm-8 " >
                    <label >Receipt Number</label>
                </div>
                <div class="col-sm-4">
                    <label >{{applicationData.receiptNumber}}</label>
                  </div>
            </div>
            <div class="row" >
                <div class="col-sm-8 " >
                    <label >Receipt Date</label>
                </div>
                <div class="col-sm-4">
                    <label >{{applicationData.receiptDate | date:'dd/MM/yyyy'}}</label>
                  </div>
            </div>
            <div class="row" >
                <div class="col-sm-8 " >
                    <label >Receipt Amount</label>
                </div>
                <div class="col-sm-4">
                    <label >{{applicationData.receiptAmount}}</label>
                  </div>
            </div>
            <div class="sub-title">
                Court case pending/stay order
             </div>
             <div class="row" >
                <div class="col-sm-8 " >
                    <label >Is court case pending</label>
                </div>
                <div class="col-sm-4">
                    <label style="text-transform: capitalize;">{{applicationData.isCasePending}}</label>
                  </div>
            </div>
            <div class="row" >
                <div class="col-sm-8 " >
                    <label > If yes, Name of court</label>
                </div>
                <div class="col-sm-4">
                    <label >{{applicationData.courtName}}</label>
                  </div>
            </div>
            <div class="row" >
                <div class="col-sm-8 " >
                    <label >Case No</label>
                </div>
                <div class="col-sm-4">
                    <label >{{applicationData.caseNumber}}</label>
                  </div>
            </div>
            <div class="row" >
                <div class="col-sm-8 " >
                    <label >Present Status</label>
                </div>
                <div class="col-sm-4">
                    <label >{{applicationData.caseStatus}}</label>
                  </div>
            </div>
            <div class="row sub-title-2" *ngIf="applicationData">
                <div class="col-sm-8 " style="font-size: 17px;" >
                    <label >Recommended by </label>
                </div>
                <div class="col-sm-2">
                    <label class="color">{{applicationData.applicantRecommendation ? applicationData.applicantRecommendation.recommendationName : ''}}</label>
                  </div>
            </div>
            <div  *ngIf="applicationData.scrutinyStatus">
                <div class="sub-title">
                    Scrutiny Details
                 </div>
                 <br>
                 <div class="row" >
                    <div class="col-sm-8 " >
                        <label >Scrutinized By:</label><b>{{applicationData.scrutinyStatus.createdBy}}</b>
                    </div>
                    <div class="row-sm-8 " >
                        <label >Date:</label><b>{{applicationData.scrutinyStatus.createdAt}}</b>
                    </div>
                 </div>
                <br>
                <div class="row">
                    <div class="col-sm-8 " >
                        <label >Comments</label>
                    </div>
                    <div class="col-sm-4">
                        <label >{{applicationData.scrutinyStatus.comment}}</label>
                      </div>
                </div>
                <div class="row">
                    <div class="col-sm-8 mt-3 " >
                        <label >Status</label>
                    </div>
                    <div class="col-sm-4">
                        <label class="color">{{applicationData.scrutinyStatus.statusVO.status}}</label>                      </div>
                </div>
                <br>
                <div>
                <div fxLayout="row" fxLayoutAlign="space-around">
                    <div *ngFor="let message of commentTypes">
                        <mat-checkbox [(ngModel)]="message.checked"  [disabled]="true">{{message.landType}}</mat-checkbox>
                    </div>
                </div>
                </div>
                </div>
                <br>
            <div  *ngIf="applicationData.fieldVisitStatus">
                <div class="sub-title">
                    Field Visit Observations
                 </div> 
                 <br>
                 <div class="row" >
                    <div class="col-sm-8 " >
                        <label >Referred By:</label>&nbsp;&nbsp;<b>{{applicationData.fieldVisitStatus.createdBy}}</b>
                    </div>
                    <div class="row-sm-8 " >
                        <label >Date:</label>&nbsp;&nbsp;<b>{{applicationData.fieldVisitStatus.createdAt}}</b>
                    </div>
                 </div>
                <br>
                 <div class="row">
                    <div class="col-sm-8" >
                        <label >Comments</label>
                    </div>
                    <div class="col-sm-4">
                        <label >{{applicationData.fieldVisitStatus.comment}}</label>
                      </div>
                </div>
                <br>
                <div class="row">
                    <div class="col-sm-8" >
                        <label >Status</label>
                    </div>
                    <div class="col-sm-4">
                        <label class="color">{{applicationData.fieldVisitStatus.statusVO.status}}</label>
                      </div>
                </div>
                <div fxLayout="row" fxLayoutAlign="space-around">
                    <div *ngFor="let message of fielVisitCommentTypes">
                        <mat-checkbox [(ngModel)]="message.checked"  [disabled]="true">{{message.landType}}</mat-checkbox>
                    </div>
                </div>
                </div>
            <br>
            <div  *ngIf="applicationData.adjudicationStatus">
            <div class="sub-title">
                Adjudication Observations
             </div> 
             <br>
             <div class="row">
                <div class="col-sm-8 " >
                    <label >Referred By:</label>&nbsp;&nbsp;<b><span>{{applicationData.adjudicationStatus ? applicationData.adjudicationStatus.createdBy : ""}}</span></b>
                </div>
                <div class="row-sm-8 " >
                    <label >Date:</label>&nbsp;&nbsp;<b><span>{{applicationData.adjudicationStatus ? applicationData.adjudicationStatus.createdAt :""}}</span></b>
                </div>
             </div>
            <br>
             <div class="row" >
                <div class="col-sm-8" >
                    <label >Comments</label>
                </div>
                <div class="col-sm-4" >
                    <label ><span>{{applicationData.adjudicationStatus ? applicationData.adjudicationStatus.comment : ""}} </span></label>
                  </div>
            </div>
            <br>
            <div class="row">
                <div class="col-sm-8" >
                    <label >Status</label>
                </div>
                <div class="col-sm-4">
                    <label class="color">{{applicationData.adjudicationStatus && applicationData.adjudicationStatus.statusVO ? applicationData.adjudicationStatus.statusVO.status : ""}}</label>
                  </div>
            </div>
            <br>
            <div class="row">
                <div class="col-sm-8" >
                    <label >Sub Status</label>
                </div>
                <div class="col-sm-4">
                    <label class="color"><span>{{ (applicationData.adjudicationStatus && applicationData.adjudicationStatus.statusOptionVos && applicationData.adjudicationStatus.statusOptionVos.length > 0) ? applicationData.adjudicationStatus.statusOptionVos[0].name : ""}}</span></label>
                  </div>
            </div>
            </div>

            <div  *ngIf="applicationData.personalInterviewStatus">
                <div class="sub-title">
                    Presonal InterView Observations
                 </div> 
                 <br>
                 <div class="row">
                    <div class="col-sm-8 " >
                        <label >Referred By:</label>&nbsp;&nbsp;<b><span>{{applicationData.personalInterviewStatus ? applicationData.personalInterviewStatus.createdBy : ""}}</span></b>
                    </div>
                    <div class="row-sm-8 " >
                        <label >Date:</label>&nbsp;&nbsp;<b><span>{{applicationData.personalInterviewStatus ? applicationData.personalInterviewStatus.createdAt :""}}</span></b>
                    </div>
                 </div>
                <br>
                 <div class="row" >
                    <div class="col-sm-8" >
                        <label >Comments</label>
                    </div>
                    <div class="col-sm-4" >
                        <label ><span>{{applicationData.personalInterviewStatus ? applicationData.personalInterviewStatus.comment : ""}} </span></label>
                      </div>
                </div>
                <br>
                <div class="row">
                    <div class="col-sm-8" >
                        <label >Status</label>
                    </div>
                    <div class="col-sm-4">
                        <label class="color">{{applicationData.personalInterviewStatus && applicationData.personalInterviewStatus.statusVO ? applicationData.personalInterviewStatus.statusVO.status : ""}}</label>
                      </div>
                </div>
                <br>
                <div class="row">
                    <div class="col-sm-8" >
                        <label >Sub Status</label>
                    </div>
                    <div class="col-sm-4">
                        <label class="color"><span>{{ (applicationData.personalInterviewStatus && applicationData.personalInterviewStatus.statusOptionVos && applicationData.personalInterviewStatus.statusOptionVos.length > 0) ? applicationData.personalInterviewStatus.statusOptionVos[0].name : ""}}</span></label>
                      </div>
                </div>
                </div>

            <div  *ngIf="applicationData.reportedStatus">
                <div class="sub-title">
                   Reported Status Observations
                </div> 
                <br>
                <div class="row" >
                   <div class="col-sm-8 " >
                       <label >Referred By:</label>&nbsp;&nbsp;<b>{{applicationData.reportedStatus.createdBy}}</b>
                   </div>
                   <div class="row-sm-8 " >
                       <label >Date:</label>&nbsp;&nbsp;<b>{{applicationData.reportedStatus.createdAt}}</b>
                   </div>
                </div>
               <br>
                <div class="row">
                   <div class="col-sm-8" >
                       <label >Comments</label>
                   </div>
                   <div class="col-sm-4">
                       <label >{{applicationData.reportedStatus.comment}}</label>
                     </div>
               </div>
               <br>
               <div class="row">
                   <div class="col-sm-8" >
                       <label >Status</label>
                   </div>
                   <div class="col-sm-4">
                       <label class="color">{{applicationData.reportedStatus.statusVO.status}}</label>
                     </div>
               </div>
               <br>
               <div class="row" *ngIf="applicationData.reportedStatus.statusOptionVos && applicationData.reportedStatus.statusOptionVos.length > 0">
                   <div class="col-sm-8" >
                       <label >Sub Status</label>
                   </div>
                   <div class="col-sm-4">
                       <label class="color">{{applicationData.reportedStatus.statusOptionVos[0].name}}</label>
                     </div>
               </div>
               </div>

               <div  *ngIf="applicationData.scOrderedJccToBda">
                <div class="sub-title">
                   Sc Ordered JCC To Bda  Observations
                </div> 
                <br>
                <div class="row" >
                   <div class="col-sm-8 " >
                       <label >Referred By:</label>&nbsp;&nbsp;<b>{{applicationData.scOrderedJccToBda.createdBy}}</b>
                   </div>
                   <div class="row-sm-8 " >
                       <label >Date:</label>&nbsp;&nbsp;<b>{{applicationData.scOrderedJccToBda.createdAt}}</b>
                   </div>
                </div>
               <br>
                <div class="row">
                   <div class="col-sm-8" >
                       <label >Comments</label>
                   </div>
                   <div class="col-sm-4">
                       <label >{{applicationData.scOrderedJccToBda.comment}}</label>
                     </div>
               </div>
               <br>
               <div class="row">
                   <div class="col-sm-8" >
                       <label >Status</label>
                   </div>
                   <div class="col-sm-4">
                       <label class="color">{{applicationData.scOrderedJccToBda.statusVO.status}}</label>
                     </div>
               </div>
               <br>
               <div class="row" *ngIf="applicationData.scOrderedJccToBda.statusOptionVos && applicationData.scOrderedJccToBda.statusOptionVos.length > 0">
                   <div class="col-sm-8" >
                       <label >Sub Status</label>
                   </div>
                   <div class="col-sm-4">
                       <label class="color">{{applicationData.scOrderedJccToBda.statusOptionVos[0].name}}</label>
                     </div>
               </div>
               </div>

               <div  *ngIf="applicationData.commissionerToSecretary">
                <div class="sub-title">
                   Commissioner To Secretary Observations
                </div> 
                <br>
                <div class="row" >
                   <div class="col-sm-8 " >
                       <label >Referred By:</label>&nbsp;&nbsp;<b>{{applicationData.commissionerToSecretary.createdBy}}</b>
                   </div>
                   <div class="row-sm-8 " >
                       <label >Date:</label>&nbsp;&nbsp;<b>{{applicationData.commissionerToSecretary.createdAt}}</b>
                   </div>
                </div>
               <br>
                <div class="row">
                   <div class="col-sm-8" >
                       <label >Comments</label>
                   </div>
                   <div class="col-sm-4">
                       <label >{{applicationData.commissionerToSecretary.comment}}</label>
                     </div>
               </div>
               <br>
               <div class="row">
                   <div class="col-sm-8" >
                       <label >Status</label>
                   </div>
                   <div class="col-sm-4">
                       <label class="color">{{applicationData.commissionerToSecretary.statusVO.status}}</label>
                     </div>
               </div>
               <br>
               <div class="row" *ngIf="applicationData.commissionerToSecretary.statusOptionVos && applicationData.commissionerToSecretary.statusOptionVos.length > 0">
                   <div class="col-sm-8" >
                       <label >Sub Status</label>
                   </div>
                   <div class="col-sm-4">
                       <label class="color">{{applicationData.commissionerToSecretary.statusOptionVos[0].name}}</label>
                     </div>
               </div>
               </div>

               <div  *ngIf="applicationData.secretaryToDs">
                <div class="sub-title">
                   Secretary To Ds  Observations
                </div> 
                <br>
                <div class="row" >
                   <div class="col-sm-8 " >
                       <label >Referred By:</label>&nbsp;&nbsp;<b>{{applicationData.secretaryToDs.createdBy}}</b>
                   </div>
                   <div class="row-sm-8 " >
                       <label >Date:</label>&nbsp;&nbsp;<b>{{applicationData.secretaryToDs.createdAt}}</b>
                   </div>
                </div>
               <br>
                <div class="row">
                   <div class="col-sm-8" >
                       <label >Comments</label>
                   </div>
                   <div class="col-sm-4">
                       <label >{{applicationData.secretaryToDs.comment}}</label>
                     </div>
               </div>
               <br>
               <div class="row">
                   <div class="col-sm-8" >
                       <label >Status</label>
                   </div>
                   <div class="col-sm-4">
                       <label class="color">{{applicationData.secretaryToDs.statusVO.status}}</label>
                     </div>
               </div>
               <br>
               <div class="row" *ngIf="applicationData.secretaryToDs.statusOptionVos && applicationData.secretaryToDs.statusOptionVos.length > 0">
                   <div class="col-sm-8" >
                       <label >Sub Status</label>
                   </div>
                   <div class="col-sm-4">
                       <label class="color">{{applicationData.secretaryToDs.statusOptionVos[0].name}}</label>
                     </div>
               </div>
               </div>

               <div  *ngIf="applicationData.dsToEm">
                <div class="sub-title">
                   Ds To Em Observations
                </div> 
                <br>
                <div class="row" >
                   <div class="col-sm-8 " >
                       <label >Referred By:</label>&nbsp;&nbsp;<b>{{applicationData.dsToEm.createdBy}}</b>
                   </div>
                   <div class="row-sm-8 " >
                       <label >Date:</label>&nbsp;&nbsp;<b>{{applicationData.dsToEm.createdAt}}</b>
                   </div>
                </div>
               <br>
                <div class="row">
                   <div class="col-sm-8" >
                       <label >Comments</label>
                   </div>
                   <div class="col-sm-4">
                       <label >{{applicationData.dsToEm.comment}}</label>
                     </div>
               </div>
               <br>
               <div class="row">
                   <div class="col-sm-8" >
                       <label >Status</label>
                   </div>
                   <div class="col-sm-4">
                       <label class="color">{{applicationData.dsToEm.statusVO.status}}</label>
                     </div>
               </div>
               <br>
               <div class="row" *ngIf="applicationData.dsToEm.statusOptionVos && applicationData.dsToEm.statusOptionVos.length > 0">
                   <div class="col-sm-8" >
                       <label >Sub Status</label>
                   </div>
                   <div class="col-sm-4">
                       <label class="color">{{applicationData.dsToEm.statusOptionVos[0].name}}</label>
                     </div>
               </div>
               </div>
               <div  *ngIf="applicationData.emToEo">
                <div class="sub-title">
                   Em To Eo Observations
                </div> 
                <br>
                <div class="row" >
                   <div class="col-sm-8 " >
                       <label >Referred By:</label>&nbsp;&nbsp;<b>{{applicationData.emToEo.createdBy}}</b>
                   </div>
                   <div class="row-sm-8 " >
                       <label >Date:</label>&nbsp;&nbsp;<b>{{applicationData.emToEo.createdAt}}</b>
                   </div>
                </div>
               <br>
                <div class="row">
                   <div class="col-sm-8" >
                       <label >Comments</label>
                   </div>
                   <div class="col-sm-4">
                       <label >{{applicationData.emToEo.comment}}</label>
                     </div>
               </div>
               <br>
               <div class="row">
                   <div class="col-sm-8" >
                       <label >Status</label>
                   </div>
                   <div class="col-sm-4">
                       <label class="color">{{applicationData.emToEo.statusVO.status}}</label>
                     </div>
               </div>
               <br>
               <div class="row" *ngIf="applicationData.emToEo.statusOptionVos && applicationData.emToEo.statusOptionVos.length > 0">
                   <div class="col-sm-8" >
                       <label >Sub Status</label>
                   </div>
                   <div class="col-sm-4">
                       <label class="color">{{applicationData.emToEo.statusOptionVos[0].name}}</label>
                     </div>
               </div>
               </div>
               <div  *ngIf="applicationData.eoToEe">
                <div class="sub-title">
                   E0 To Ee Observations
                </div> 
                <br>
                <div class="row" >
                   <div class="col-sm-8 " >
                       <label >Referred By:</label>&nbsp;&nbsp;<b>{{applicationData.eoToEe.createdBy}}</b>
                   </div>
                   <div class="row-sm-8 " >
                       <label >Date:</label>&nbsp;&nbsp;<b>{{applicationData.eoToEe.createdAt}}</b>
                   </div>
                </div>
               <br>
                <div class="row">
                   <div class="col-sm-8" >
                       <label >Comments</label>
                   </div>
                   <div class="col-sm-4">
                       <label >{{applicationData.eoToEe.comment}}</label>
                     </div>
               </div>
               <br>
               <div class="row">
                   <div class="col-sm-8" >
                       <label >Status</label>
                   </div>
                   <div class="col-sm-4">
                       <label class="color">{{applicationData.eoToEe.statusVO.status}}</label>
                     </div>
               </div>
               <br>
               <div class="row" *ngIf="applicationData.eoToEe.statusOptionVos && applicationData.eoToEe.statusOptionVos.length > 0">
                   <div class="col-sm-8" >
                       <label >Sub Status</label>
                   </div>
                   <div class="col-sm-4">
                       <label class="color">{{applicationData.eoToEe.statusOptionVos[0].name}}</label>
                     </div>
               </div>
               </div>
            <div  *ngIf="applicationData.eeToAee">
                <div class="sub-title">
                   EE To AEE Observations
                </div> 
                <br>
                <div class="row" >
                   <div class="col-sm-8 " >
                       <label >Referred By:</label>&nbsp;&nbsp;<b>{{applicationData.eeToAee.createdBy}}</b>
                   </div>
                   <div class="row-sm-8 " >
                       <label >Date:</label>&nbsp;&nbsp;<b>{{applicationData.eeToAee.createdAt}}</b>
                   </div>
                </div>
               <br>
                <div class="row">
                   <div class="col-sm-8" >
                       <label >Comments</label>
                   </div>
                   <div class="col-sm-4">
                       <label >{{applicationData.eeToAee.comment}}</label>
                     </div>
               </div>
               <br>
               <div class="row">
                   <div class="col-sm-8" >
                       <label >Status</label>
                   </div>
                   <div class="col-sm-4">
                       <label class="color">{{applicationData.eeToAee.statusVO.status}}</label>
                     </div>
               </div>
               <br>
               <div class="row" *ngIf="applicationData.eeToAee.statusOptionVos && applicationData.eeToAee.statusOptionVos.length > 0">
                   <div class="col-sm-8" >
                       <label >Sub Status</label>
                   </div>
                   <div class="col-sm-4">
                       <label class="color">{{applicationData.eeToAee.statusOptionVos[0].name}}</label>
                     </div>
               </div>
               </div>
          
            <div  *ngIf="applicationData.cdDoneStatus">
             <div class="sub-title">
                CD Done Observations
             </div> 
             <br>
             <div class="row" >
                <div class="col-sm-8 " >
                    <label >Referred By:</label>&nbsp;&nbsp;<b>{{applicationData.cdDoneStatus.createdBy}}</b>
                </div>
                <div class="row-sm-8 " >
                    <label >Date:</label>&nbsp;&nbsp;<b>{{applicationData.cdDoneStatus.createdAt}}</b>
                </div>
             </div>
            <br>
             <div class="row">
                <div class="col-sm-8" >
                    <label >Comments</label>
                </div>
                <div class="col-sm-4">
                    <label >{{applicationData.cdDoneStatus.comment}}</label>
                  </div>
            </div>
            <br>
            <div class="row">
                <div class="col-sm-8" >
                    <label >Status</label>
                </div>
                <div class="col-sm-4">
                    <label class="color">{{applicationData.cdDoneStatus.statusVO.status}}</label>
                  </div>
            </div>
            <br>
            <div class="row" *ngIf="applicationData.cdDoneStatus.statusOptionVos && applicationData.cdDoneStatus.statusOptionVos.length > 0">
                <div class="col-sm-8" >
                    <label >Sub Status</label>
                </div>
                <div class="col-sm-4">
                    <label class="color">{{applicationData.cdDoneStatus.statusOptionVos[0].name}}</label>
                  </div>
            </div>
            </div> 

            <div  *ngIf="applicationData.sdDataValidation">
                <div class="sub-title">
                    RC Register User Observations
                </div> 
                <br>
                <div class="row" >
                   <div class="col-sm-8 " >
                       <label >Referred By:</label>&nbsp;&nbsp;<b>{{applicationData.sdDataValidation.createdBy}}</b>
                   </div>
                   <div class="row-sm-8 " >
                       <label >Date:</label>&nbsp;&nbsp;<b>{{applicationData.sdDataValidation.createdAt}}</b>
                   </div>
                </div>
               <br>
               <div class="row">
                   <div class="col-sm-8" >
                       <label >Status</label>
                   </div>
                   <div class="col-sm-4">
                       <label class="color">{{applicationData.sdDataValidation.statusVO.status}}</label>
                     </div>
               </div>
               <br>
               <div class="row" *ngIf="applicationData.sdDataValidation.statusOptionVos && applicationData.sdDataValidation.statusOptionVos.length > 0">
                   <div class="col-sm-8" >
                       <label >Sub Status</label>
                   </div>
                   <div class="col-sm-4">
                       <label class="color">{{applicationData.sdDataValidation.statusOptionVos[0].name}}</label>
                     </div>
               </div>
               </div> 
               <div  *ngIf="applicationData.cdDataValidation">
                <div class="sub-title">
                    SD Data Validation Observations
                </div> 
                <br>
                <div class="row" >
                   <div class="col-sm-8 " >
                       <label >Referred By:</label>&nbsp;&nbsp;<b>{{applicationData.cdDataValidation.createdBy}}</b>
                   </div>
                   <div class="row-sm-8 " >
                       <label >Date:</label>&nbsp;&nbsp;<b>{{applicationData.cdDataValidation.createdAt}}</b>
                   </div>
                </div>
               <br>
               <div class="row">
                   <div class="col-sm-8" >
                       <label >Status</label>
                   </div>
                   <div class="col-sm-4">
                       <label class="color">{{applicationData.cdDataValidation.statusVO.status}}</label>
                     </div>
               </div>
               <br>
               <div class="row" *ngIf="applicationData.cdDataValidation.statusOptionVos && applicationData.cdDataValidation.statusOptionVos.length > 0">
                   <div class="col-sm-8" >
                       <label >Sub Status</label>
                   </div>
                   <div class="col-sm-4">
                       <label class="color">{{applicationData.cdDataValidation.statusOptionVos[0].name}}</label>
                     </div>
               </div>
               </div> 
               <div  *ngIf="applicationData.batchingReady">
                <div class="sub-title">
                    CD Data Validation Observations
                </div> 
                <br>
                <div class="row" >
                   <div class="col-sm-8 " >
                       <label >Referred By:</label>&nbsp;&nbsp;<b>{{applicationData.batchingReady.createdBy}}</b>
                   </div>
                   <div class="row-sm-8 " >
                       <label >Date:</label>&nbsp;&nbsp;<b>{{applicationData.batchingReady.createdAt}}</b>
                   </div>
                </div>
               <br>
               <div class="row">
                   <div class="col-sm-8" >
                       <label >Status</label>
                   </div>
                   <div class="col-sm-4">
                       <label class="color">{{applicationData.batchingReady.statusVO.status}}</label>
                     </div>
               </div>
               <br>
               <div class="row" *ngIf="applicationData.batchingReady.statusOptionVos && applicationData.batchingReady.statusOptionVos.length > 0">
                   <div class="col-sm-8" >
                       <label >Sub Status</label>
                   </div>
                   <div class="col-sm-4">
                       <label class="color">{{applicationData.batchingReady.statusOptionVos[0].name}}</label>
                     </div>
               </div>
               </div> 
               <div  *ngIf="applicationData.sentToManipal">
                <div class="sub-title">
                    Sent to manipal Observations
                </div> 
                <br>
                <div class="row" >
                   <div class="col-sm-8 " >
                       <label >Referred By:</label>&nbsp;&nbsp;<b>{{applicationData.sentToManipal.createdBy}}</b>
                   </div>
                   <div class="row-sm-8 " >
                       <label >Date:</label>&nbsp;&nbsp;<b>{{applicationData.sentToManipal.createdAt}}</b>
                   </div>
                </div>
               <br>
               <div class="row">
                   <div class="col-sm-8" >
                       <label >Status</label>
                   </div>
                   <div class="col-sm-4">
                       <label class="color">{{applicationData.sentToManipal.statusVO.status}}</label>
                     </div>
               </div>
               <br>
               <div class="row" *ngIf="applicationData.sentToManipal.statusOptionVos && applicationData.sentToManipal.statusOptionVos.length > 0">
                   <div class="col-sm-8" >
                       <label >Sub Status</label>
                   </div>
                   <div class="col-sm-4">
                       <label class="color">{{applicationData.sentToManipal.statusOptionVos[0].name}}</label>
                     </div>
               </div>
               </div> 
               <div  *ngIf="applicationData.rcPrintedAndReceived">
                <div class="sub-title">
                    Rc Printed And Recieved Observations
                </div> 
                <br>
                <div class="row" >
                   <div class="col-sm-8 " >
                       <label >Referred By:</label>&nbsp;&nbsp;<b>{{applicationData.rcPrintedAndReceived.createdBy}}</b>
                   </div>
                   <div class="row-sm-8 " >
                       <label >Date:</label>&nbsp;&nbsp;<b>{{applicationData.rcPrintedAndReceived.createdAt}}</b>
                   </div>
                </div>
               <br>
               <div class="row">
                   <div class="col-sm-8" >
                       <label >Status</label>
                   </div>
                   <div class="col-sm-4">
                       <label class="color">{{applicationData.rcPrintedAndReceived.statusVO.status}}</label>
                     </div>
               </div>
               <br>
               <div class="row" *ngIf="applicationData.rcPrintedAndReceived.statusOptionVos && applicationData.rcPrintedAndReceived.statusOptionVos.length > 0">
                   <div class="col-sm-8" >
                       <label >Sub Status</label>
                   </div>
                   <div class="col-sm-4">
                       <label class="color">{{applicationData.rcPrintedAndReceived.statusOptionVos[0].name}}</label>
                     </div>
               </div>
               </div> 
               <div  *ngIf="applicationData.rcDisbursed">
                <div class="sub-title">
                    Rc Disbursement Observations
                </div> 
                <br>
                <div class="row" >
                   <div class="col-sm-8 " >
                       <label >Referred By:</label>&nbsp;&nbsp;<b>{{applicationData.rcDisbursed.createdBy}}</b>
                   </div>
                   <div class="row-sm-8 " >
                       <label >Date:</label>&nbsp;&nbsp;<b>{{applicationData.rcDisbursed.createdAt}}</b>
                   </div>
                </div>
               <br>
               <div class="row">
                   <div class="col-sm-8" >
                       <label >Status</label>
                   </div>
                   <div class="col-sm-4">
                       <label class="color">{{applicationData.rcDisbursed.statusVO.status}}</label>
                     </div>
               </div>
               <br>
               <div class="row" *ngIf="applicationData.rcDisbursed.statusOptionVos && applicationData.rcDisbursed.statusOptionVos.length > 0">
                   <div class="col-sm-8" >
                       <label >Sub Status</label>
                   </div>
                   <div class="col-sm-4">
                       <label class="color">{{applicationData.rcDisbursed.statusOptionVos[0].name}}</label>
                     </div>
               </div>
               </div> 
               <div  *ngIf="applicationData.reportedOutOfBounds">
                <div class="sub-title">
                    Reported Out Of Bounds Observations
                </div> 
                <br>
                <div class="row" >
                   <div class="col-sm-8 " >
                       <label >Referred By:</label>&nbsp;&nbsp;<b>{{applicationData.reportedOutOfBounds.createdBy}}</b>
                   </div>
                   <div class="row-sm-8 " >
                       <label >Date:</label>&nbsp;&nbsp;<b>{{applicationData.reportedOutOfBounds.createdAt}}</b>
                   </div>
                </div>
               <br>
               <div class="row">
                   <div class="col-sm-8" >
                       <label >Status</label>
                   </div>
                   <div class="col-sm-4">
                       <label class="color">{{applicationData.reportedOutOfBounds.statusVO.status}}</label>
                     </div>
               </div>
               <br>
               <div class="row" *ngIf="applicationData.reportedOutOfBounds.statusOptionVos && applicationData.reportedOutOfBounds.statusOptionVos.length > 0">
                   <div class="col-sm-8" >
                       <label >Sub Status</label>
                   </div>
                   <div class="col-sm-4">
                       <label class="color">{{applicationData.reportedOutOfBounds.statusOptionVos[0].name}}</label>
                     </div>
               </div>
               </div> 

               <div  *ngIf="applicationData.approvedButWithdrawn">
                <div class="sub-title">
                   Approved But Withdrawn Observations
                </div> 
                <br>
                <div class="row" >
                   <div class="col-sm-8 " >
                       <label >Referred By:</label>&nbsp;&nbsp;<b>{{applicationData.approvedButWithdrawn.createdBy}}</b>
                   </div>
                   <div class="row-sm-8 " >
                       <label >Date:</label>&nbsp;&nbsp;<b>{{applicationData.approvedButWithdrawn.createdAt}}</b>
                   </div>
                </div>
               <br>
               <br>
               <div class="row">
                   <div class="col-sm-8" >
                       <label >Status</label>
                   </div>
                   <div class="col-sm-4">
                       <label class="color">{{applicationData.approvedButWithdrawn.statusVO.status}}</label>
                     </div>
               </div>
               <br>
               </div> 

               <div  *ngIf="applicationData.mismatchVacentSiteFound">
                <div class="sub-title">
                   Mismatch Vacant Site Found Observations
                </div> 
                <br>
                <div class="row" >
                   <div class="col-sm-8 " >
                       <label >Referred By:</label>&nbsp;&nbsp;<b>{{applicationData.mismatchVacentSiteFound.createdBy}}</b>
                   </div>
                   <div class="row-sm-8 " >
                       <label >Date:</label>&nbsp;&nbsp;<b>{{applicationData.mismatchVacentSiteFound.createdAt}}</b>
                   </div>
                </div>
               <br>
               <br>
               <div class="row">
                   <div class="col-sm-8" >
                       <label >Status</label>
                   </div>
                   <div class="col-sm-4">
                       <label class="color">{{applicationData.mismatchVacentSiteFound.statusVO.status}}</label>
                     </div>
               </div>
               <br>
               </div> 

               <div  *ngIf="applicationData.mismatchStructure">
                <div class="sub-title">
                   Mismatch Structure Observations
                </div> 
                <br>
                <div class="row" >
                   <div class="col-sm-8 " >
                       <label >Referred By:</label>&nbsp;&nbsp;<b>{{applicationData.mismatchStructure.createdBy}}</b>
                   </div>
                   <div class="row-sm-8 " >
                       <label >Date:</label>&nbsp;&nbsp;<b>{{applicationData.mismatchStructure.createdAt}}</b>
                   </div>
                </div>
               <br>
               <br>
               <div class="row">
                   <div class="col-sm-8" >
                       <label >Status</label>
                   </div>
                   <div class="col-sm-4">
                       <label class="color">{{applicationData.mismatchStructure.statusVO.status}}</label>
                     </div>
               </div>
               <br>
               </div> 

    </div>
    </div>
    <app-footer class="col-md-10 offset-sm-3" ></app-footer>
    