import { Component, OnInit, Input, ViewChild, AfterViewInit, Output, EventEmitter } from '@angular/core';
import { ApplicationService } from 'src/services/application.service';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ConfirmationModalComponent } from 'src/app/confirmation-modal/confirmation-modal.component';
import { SiteDetailsComponent } from '../site-details/site-details.component';
import { OtherPropertyDetailsComponent } from '../building-details/other-property-details/other-property-details.component';
import { ResidentalHouseComponent } from '../building-details/residental-house/residental-house.component';
import { CommercialBuildingComponent } from '../building-details/commercial-building/commercial-building.component';
import { ApartmentComponent } from '../building-details/apartment/apartment.component';
import { GatedCommunityComponent } from '../building-details/gated-community/gated-community.component';
import { ActivatedRoute } from '@angular/router';
import { JccnoEncodeDecodeService } from 'src/services/jccno-encode-decode/jccno-encode-decode.service';

@Component({
  selector: 'app-property-details',
  templateUrl: './property-details.component.html',
  styleUrls: ['./property-details.component.scss']
})
export class PropertyDetailsComponent implements OnInit {

  public data : any;

  public isEditable : any;

  public selectedSubMenu: any ;

  public selectedMenu: any = {};

  public propertyType: any = {};

  @ViewChild(SiteDetailsComponent, { static: false }) siteDetailsComponent: SiteDetailsComponent;
  @ViewChild(ResidentalHouseComponent, { static: false }) residentalHouseComponent: ResidentalHouseComponent;
  @ViewChild(CommercialBuildingComponent, { static: false }) commercialBuildingComponent: CommercialBuildingComponent;
  @ViewChild(ApartmentComponent, { static: false }) apartmentComponent: ApartmentComponent;
  @ViewChild(GatedCommunityComponent, { static: false }) gatedCommunityComponent: GatedCommunityComponent;
  @ViewChild(OtherPropertyDetailsComponent, { static: false }) otherPropertyDetailsComponent: OtherPropertyDetailsComponent;

  public menus: any = [
    {
      "name": "Site Details",
      "code": "site_details",
      "active": true,
      "default": true,
      "sub_menus":[]
    },
    {
      "name": "Building Details",
      "code": "building_details",
      "active": false,
      "default": false,
      "sub_menus": [ 
        {
          name:'Residential House', 
          type:'child',
          code: "residential_house",
          active: false
        },
        {
          name: 'Commercial / Industrial',
          type: 'child',
          code: "commercial_industrial",
          active: false
        }, 
        {
          name:'Apartment / High-rise / Multi-dwelling', 
          type:'child',
          code: "apartment",
          active: false
        },
        {
          name: 'Gated Community',
          code: "gated_community",
          type: 'child',
          active: false
        }, 
        {
          name: 'Others', 
          type: 'child',
          code: "others",
          active: false
        }
      ]
    }
];
  
private applicantNo: any;

private applicantId: any;

private buildingType: any;

private isOneTimeEdit: boolean = false;

@Output() nextScreenCallback = new EventEmitter<any>();

  constructor(private applicationService: ApplicationService, public dialog: MatDialog,
    private _snackBar: MatSnackBar, 
    private route: ActivatedRoute, 
    private jccEncodeDecoder: JccnoEncodeDecodeService) {
      
    }

  ngOnInit(): void {
    this.selectedMenu = this.menus[0];
    this.propertyType = this.menus[0].code;
    let encodedNo = this.route.snapshot.queryParams["applicationNo"];
    if(encodedNo) {
      this.applicantNo = this.jccEncodeDecoder.decode(this.route.snapshot.queryParams["applicationNo"]);
      this.fetchApplicationData();
    } else {
      this.applicantId = window.localStorage.getItem("applicantId");
      this.fetchApplicantById(this.applicantId);
    }
  }

  private fetchApplicantById = (applicantId: any) => {
    if(!applicantId) {
      return;
    }
    this.applicationService.fetchApplicantById(applicantId).subscribe(res =>{
        this.data = res;
        this.setEditable(res);
    })
  }
  openSelectedMenu = (menu: any) => {
    for(let i =0;i < this.menus.length;i++) {
      if(menu['code'] == this.menus[i]['code']) {
        this.menus[i]['active'] = true;
      } else {
        this.menus[i]['active'] = false;
      }
    }
    this.selectedMenu = menu;
  }

  public fetchApplicationData = () => {
    this.applicationService.fetchApplicantByApplicationNo(this.applicantNo).subscribe(res=>{
        console.log("property details", res);
        this.setEditable(res);
        this.applicantId = res.id;
        this.data = res;
    })
  }

  public onItemClicked = (event: any) => {
    this.selectedMenu = event;
    if(event.code == 'site_details') {
      this.propertyType = 'site_details';
    }else {
      this.propertyType = 'building_details';
    }
    this.buildingType = event.code;
    console.log('sub'+this.selectedSubMenu)
  }

  onSubmit = () => {
    this.openDialog();
  }

  openSnackbar(message: string, action?: string){
    if(!action) {
      action = "Dismiss";
    }
    this._snackBar.open(message, action);
  }

  openDialog = () => {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = false;
    dialogConfig.autoFocus = true;
    dialogConfig.height = '160px';
    dialogConfig.width = '360px';
    dialogConfig.data = "Are you sure want to submit this record ?"
    const dialogRef = this.dialog.open(ConfirmationModalComponent,dialogConfig);
    dialogRef.afterClosed().subscribe(res =>{
        if(res){
          if(this.isOneTimeEdit) {
            this.updateApplicantPropertyDetailsApiCall(this.applicantId);
          } else {
            this.saveApplicantPropertyDetailsApiCall(this.applicantId);
          }
        }
    })
  }

  validateAndConstructPayload = () => {
    let sitePayload = this.siteDetailsComponent.getPayload();
    let residentPayload = this.residentalHouseComponent.getPayload();
    let commBuildingPayload = this.commercialBuildingComponent.getPayload();
    let apartmentPayload = this.apartmentComponent.getPayload();
    let gatedCommPayload = this.gatedCommunityComponent.getPayload();
    let otherPropertyPayload = this.otherPropertyDetailsComponent.getPayload();
    let payload = Object.assign({}, sitePayload, residentPayload, commBuildingPayload, apartmentPayload, gatedCommPayload, otherPropertyPayload)
    return payload;
  }

  saveApplicantPropertyDetailsApiCall = (applicantId: any) => {
    if(!this.applicantId) {
      this.openSnackbar("Application not created. please fill Basic Details Form");
      return;
    }
    try{
      let payload  = this.validateAndConstructPayload();
      this.applicationService.savePropertyType(applicantId,this.propertyType,  payload, this.buildingType).subscribe(res =>{
        this.data = res;
        this.setEditable(res);
        this.openSnackbar("Successfully Saved");
      }, err => {
          console.log(err);
          this.openSnackbar(err.message);
      })
    }
    catch(e){
      this.openSnackbar(e);
    }
  }

  private setEditable = (data: any): void => {
    if(data && data['oneTimeEdit'] != null) {
      this.isEditable = data['oneTimeEdit'];
      this.isOneTimeEdit = data['oneTimeEdit'];
    }
  }

  updateApplicantPropertyDetailsApiCall = (applicantId: any) => {
    try{
      let payload  = this.validateAndConstructPayload();
      this.applicationService.updatePropertyType(applicantId, this.propertyType,  payload, this.buildingType).subscribe(res =>{
        this.data = res;
        this.setEditable(res);
        this.openSnackbar("Successfully Updated", "Dismiss");
      }, err => {
          console.log(err);
          this.openSnackbar(err?.message);
      })
    } catch(e){
      this.openSnackbar(e);
    }
  }
}
