import { ChangeDetectorRef, Component, ElementRef, ViewChild, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { PageEvent } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { faSync, faTrash } from '@fortawesome/free-solid-svg-icons';
import { WebcamImage } from 'ngx-webcam';
import { Observable, Subject } from 'rxjs';
import { ConfirmDialogService } from 'src/app/shared/confirm-dialog/confirm-dialog.service';
import { ApplicationService } from 'src/services/application.service';
import { StrorageService } from 'src/services/strorage.service';
import { DpaEeObservationPreviewDialogboxComponent } from '../dpa-ee-observation-preview-dialogbox/dpa-ee-observation-preview-dialogbox.component';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';


@Component({
  selector: 'app-ee-observation-workbench',
  templateUrl: './ee-observation-workbench.component.html',
  styleUrls: ['./ee-observation-workbench.component.scss']
})
export class EeObservationWorkbenchComponent implements OnInit {

  @ViewChild('imageGalleryInput', { static: false }) imageGalleryInput: ElementRef;

  sectorList: any[];

  sectorId: any;

  concernSourceId: any;

  concernSourceList: any;

  public faSync = faSync;

  public pageSize = 5;

  public currentPage = 0;

  public totalSize: number = 0;

  observationNo: any;

  comments: any;

  user: any;

  isRefreshing: boolean;

  cameraChoosen = false;

  selectedFile;

  selectedFileList : any[] = [];

  selectedFileName;

  profileImg: any;

  faTrash = faTrash;

  dialogRef: MatDialogRef<DpaEeObservationPreviewDialogboxComponent>;

  imageUrl: SafeResourceUrl;

  sectorName: any;

  concernSourceName: any;

  private triggerObservables: Subject<void>[] = [];

  noOfImages : any [] = [
    {
      cameraChoosen: false,
      profileImg: null,
      selectedFile : null,
      fileName : ''
    },
    {
      cameraChoosen: false,
      profileImg: null,
      selectedFile : null,
      fileName : ''
    },
    {
      cameraChoosen: false,
      profileImg: null,
      selectedFile : null,
      fileName : ''
    },
    {
      cameraChoosen: false,
      profileImg: null,
      selectedFile : null,
      fileName : ''
    },
    {
      cameraChoosen: false,
      profileImg: null,
      selectedFile : null,
      fileName : ''
    }
  ];

  previewImages : any[] = [];

  constructor(private applicationService: ApplicationService, private _snackBar: MatSnackBar,
    private cdr: ChangeDetectorRef, private storageService: StrorageService,
    public route: ActivatedRoute, private router: Router, private confirmDialogService: ConfirmDialogService,
    private dialog: MatDialog, private sanitizer: DomSanitizer) {
      for (let i = 0; i < this.noOfImages.length; i++) {
        this.triggerObservables[i] = new Subject<void>();
      }
     }

  ngOnInit() {
    this.user = this.storageService.getUser();
    this.getAllSector();
    this.getConcernSource();
    // this.cdr.detectChanges();
  }


  getObservationPaginationData(event: PageEvent) {
    this.currentPage = event.pageIndex + 1;
    this.pageSize = event.pageSize;
  }

  refreshClick() {
    let currentComponent = this;
    this.isRefreshing = true;
    setTimeout(() => {
      currentComponent.isRefreshing = false;
    }, 3000)
  }

  clickBack() {
    window.history.back();
  }

  getAllSector() {
    this.applicationService.getAllSectors().subscribe(res => {
      if (res) {
        this.sectorList = res;
      }
    })
  }

  public onSectorChanged(event: any, sectorName: any) {
    this.sectorName = sectorName;
  }

  public onConcernSourceChanged(event: any, concernSourceName: any) {
    this.concernSourceName = concernSourceName;
  }

  getConcernSource() {
    this.applicationService.getConcernSource().subscribe(res => {
      if (res) {
        this.concernSourceList = res;
      }
    })
  }


  openSnackbar(message: string, action: string) {
    this._snackBar.open(message, action);
  }

  onActivityLogClick() {
    this.router.navigate(['/dpa-ee-observation-activity-log']);
  }

  // private trigger: Subject<void> = new Subject<void>();
  // triggerSnapshot(): void {
  //   this.trigger.next();
  // }

  triggerSnapshot(index: number): void {
    this.triggerObservables[index].next();
  }

  validateAndConstructFormData = () => {
    if (this.sectorId == null) {
      throw new Error("Sector must not be empty");
    }
    if (this.concernSourceId == null) {
      throw new Error("Concern must not be empty");
    }
    if (this.comments == null) {
      throw new Error("Comments must not be empty");
    }
    if (this.comments.length > 500) {
      throw new Error("Comments only 500 characters allowed");
    }
    const formData = new FormData();
      for(let i = 0; i < this.noOfImages.length; i++){
        if(this.noOfImages[i].selectedFile && this.noOfImages[i].fileName){
          formData.append('file[]', this.noOfImages[i].selectedFile);
        }
    }
    formData.append('sectorId', this.sectorId);
    formData.append('concernSourceId', this.concernSourceId);
    formData.append('comment', this.comments);
    return formData;
  }


  chooseCamera(index: number) {
    this.noOfImages[index].cameraChoosen = true;
  }
  
  removePicture(index: number){
    this.noOfImages[index].cameraChoosen = false;
    this.noOfImages[index].profileImg = null;
    this.noOfImages[index].selectedFile = null;
    this.noOfImages[index].fileName = null;
    console.log('uploadedImagesList',this.noOfImages);
  }

  handleImage(webcamImage: WebcamImage, index: number): void {
    this.noOfImages[index].cameraChoosen = false;
    this.noOfImages[index].profileImg = webcamImage.imageAsDataUrl;
    let selectedFileName = 'image_'+index+'.jpg';
    this.noOfImages[index].fileName = selectedFileName;
    // this.cameraChoosen = false;
    // this.profileImg = webcamImage.imageAsDataUrl;
    // this.selectedFile = webcamImage.imageAsDataUrl;
    const base64 = webcamImage.imageAsBase64;
    const imageName = selectedFileName;
    const imageBlob = this.dataURItoBlob(base64);
    this.noOfImages[index].selectedFile = new File([imageBlob], imageName, { type: selectedFileName });
    console.log('uploadedImagesList',this.noOfImages);
  }
  dataURItoBlob(dataURI) {
    const byteString = window.atob(dataURI);
    const arrayBuffer = new ArrayBuffer(byteString.length);
    const int8Array = new Uint8Array(arrayBuffer);
    for (let i = 0; i < byteString.length; i++) {
      int8Array[i] = byteString.charCodeAt(i);
    }
    const blob = new Blob([int8Array], { type: 'image/png' });
    return blob;
  }

  onGalleryImageChoosen($event, index: number) {
    console.log('event ', $event.target.files[0])
    this.noOfImages[index].selectedFile = $event.target.files[0];
    this.noOfImages[index].fileName = this.noOfImages[index].selectedFile.name;

    let currentComponent = this;
    const reader = new FileReader();
    reader.onload = (e: any) => {
      this.noOfImages[index].profileImg = e.target.result;
    };
    reader.readAsDataURL($event.target.files[0]);
  }
  fetchFromGallery(index: number) {
    let elementName = "image-gallery-"+index;
    document.getElementById(elementName).click();

  }



  getTriggerObservable(index: number): Observable<void> {
    return this.triggerObservables[index].asObservable();
  }

  submit = () => {
    let options = {
      message: 'Do you want to submit your observation?',
      cancelText: 'No',
      confirmText: 'Yes'
    };
    this.confirmDialogService.openDialog(options);
    this.confirmDialogService.confirmed().subscribe(confirmed => {
      if (confirmed) {
        let payload = this.validateAndConstructFormData();
        this.applicationService.uploadDapEEObservation(payload).subscribe(res => {
          this.openSnackbar('Successfully Saved', 'Dismiss');
          this.router.navigate(['/dpa-ee-observation-activity-log']);
          return;
        })
      }
    });
  }

  onPreviewClicked() {
    let data = this.validateAndconstructPreviewData();
    this.dialogRef = this.dialog.open(DpaEeObservationPreviewDialogboxComponent, {
      data: {
        title: "Confirm",
        message: "Application is out of bound",
        cancelText: "CANCEL",
        confirmText: "OK",
        eeObservationData: data
      },
      maxHeight: "100%",
      width: "80%",
      maxWidth: "100%",
      disableClose: true,
      hasBackdrop: true
    });
  }
  
  public validateAndconstructPreviewData() {
    if (this.sectorId == null) {
      throw new Error("Sector must not be empty");
    }
    if (this.concernSourceId == null) {
      throw new Error("Concern source must not be empty");
    }
    if (this.comments == null) {
      throw new Error("Comments must not be empty");
    }
    let data = {}
    this.previewImages = [];
    data['sectorName'] = this.sectorName;
    data['concernSourceName'] = this.concernSourceName;
    data['comments'] = this.comments;
    for(let i = 0; i < this.noOfImages.length; i++){
      if(this.noOfImages[i].profileImg){
        this.previewImages.push(this.covertImage(this.noOfImages[i].profileImg));
      }
    }
      // this.covertImage();
    data['imageUrlList'] = this.previewImages;
    return data;
  }

  public covertImage(selectedUrl:any) {
    return this.sanitizer.bypassSecurityTrustResourceUrl(selectedUrl);
  }

}

