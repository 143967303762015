import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AdjudicationUserLoginComponent } from './adjudication-login/adjudication-user-login.component';
import { AdjudicationDashboardComponent } from './adjudication-dashboard/adjudication-dashboard.component';
import { FormsModule } from '@angular/forms';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MaterialModule } from '../material.module';
import { SharedModule } from '../shared/shared.module';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { BrowserModule } from '@angular/platform-browser';
import { MatTooltipModule } from '@angular/material/tooltip';
import { AdjudicationWorkbenchComponent } from './adjudication-workbench/adjudication-workbench.component';
import { AdjudicationActivityLogComponent } from './adjudication-activity-log/adjudication-activity-log.component';
import { AdjudicationActivityLogViewComponent } from './adjudication-activity-log-view/adjudication-activity-log-view.component';
import { AdjudicationReportingComponent } from './adjudication-reporting/adjudication-reporting.component';
import { AdjudicationPreDashboardComponent } from './adjudication-pre-dashboard/adjudication-pre-dashboard.component';
import { MatDialogRef, MatDialogModule } from '@angular/material/dialog';
import { AdjudicationOutOfBoundsDialogboxComponent } from './adjudication-out-of-bounds-dialogbox/adjudication-out-of-bounds-dialogbox.component';
import { MatCheckboxModule } from '@angular/material/checkbox';

const sharedComponents = [AdjudicationUserLoginComponent,
  AdjudicationDashboardComponent,AdjudicationPreDashboardComponent, 
  AdjudicationOutOfBoundsDialogboxComponent
];
@NgModule({
    declarations: [...sharedComponents, AdjudicationWorkbenchComponent, AdjudicationActivityLogComponent, AdjudicationReportingComponent,AdjudicationActivityLogViewComponent],
    imports: [
      BrowserModule,
      FontAwesomeModule,
      CommonModule,
      SharedModule,
      MaterialModule,
      FlexLayoutModule,
      FormsModule,
      MatTooltipModule,
      MatDialogModule,
      MatCheckboxModule
    ],
    providers: [
      {
        provide: MatDialogRef,
        useValue: {}
      }
    ],
    entryComponents: [...sharedComponents],
    exports: [...sharedComponents]
  })
  export class AdjudicationUserModule { }