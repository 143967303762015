<div class="container">
    <!-- <div class="col-md-12">
        <div class="row">
            <div class="col-md-12"><label>Selected Sector: </label><span>{{selectedSectorName}}</span></div>
        </div>
    </div> -->
    <div class="col-md-12">
        <div class="col-md-12 offset-md-3">
            <div class="row">
                <mat-list role="list">
                    <mat-list-item role="listitem" *ngFor="let item of sectors; let i = index">
                        <a href="javascript:void(0)" (click)="onSectorTileClicked(item)">{{item.name}}</a>
                    </mat-list-item>
                </mat-list>
            </div>
        </div>
    </div>
</div>