import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { PageEvent } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { faSync } from '@fortawesome/free-solid-svg-icons';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { MessageDialogService } from 'src/app/shared/message-dialog/message-dialog.service';
import { ApplicationService } from 'src/services/application.service';
import { StrorageService } from 'src/services/strorage.service';

@Component({
  selector: 'app-hotline-dashboard',
  templateUrl: './hotline-dashboard.component.html',
  styleUrls: ['./hotline-dashboard.component.scss']
})
export class HotlineDashboardComponent implements OnInit {

  isRefreshing: boolean;
  
  public faSync = faSync;

  user: any;

  applicationNo : any;

  public displayedColumns: string[] = ['sn','groupId', 'groupSize','jccNO', 'villageName', 'activeStatus','notificationType', 'isValid'];

  public dataSource = new MatTableDataSource<any>();

  public totalSize: number= 0;

  public totalCount: number= 0;

  public pageSize =5;

  public currentPage = 0;

  linkedApplicants:any;

  public isTableActive = false;

  selectedApplicationNo :  any;

  filteredOptions: Observable<string[]>;

  myControl = new FormControl('');

  applicationNos : any [] = [];


  constructor(private applicationService: ApplicationService,
    private router: Router,private storageService:StrorageService,
    private messageDialogService: MessageDialogService,private cdr:ChangeDetectorRef) { }

  ngOnInit() {
    this.user=this.storageService.getUser();
    this.getAllApplicationNos();
    this.filteredOptions = this.myControl.valueChanges.pipe(
      startWith(''),
      map(value => this._filter(value || '')),
    );
  }

  refreshClick(){
    let currentComponent = this;
    this.isRefreshing = true;
    setTimeout(()=>{
      currentComponent.isRefreshing = false;
    }, 3000) 
    if(this.applicationNo) {
      this.fetchLinkedApplicants();
    }
  }

  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();
    return this.applicationNos.filter(option => {
      if (typeof option === 'string') {
        return option.toLowerCase().includes(filterValue);
      }
      return false;
    });
  }

  public getApplicantPaginatorData(event: PageEvent) {
    this.currentPage = event.pageIndex;
    this.pageSize = event.pageSize;
    this.fetchLinkedApplicants();
  }
  
    fetchLinkedApplicants() : void {
    this.applicationService.fetchLinkedApplicantsList(this.currentPage,this.pageSize, 
      this.applicationNo, null, null).subscribe(res =>{
      this.linkedApplicants = res;
      this.applicationNos = res.data;
      this.isTableActive = true;
      this.refreshDataTable(res);
    })
  }

  getAllApplicationNos() {
    this.applicationService.fetchLinkedApplicantsList(this.currentPage,this.pageSize, 
      this.applicationNo, null, null).subscribe(res =>{
        for (let i = 0; i < res.data.length; i++) {
              let applicationNo = res.data[i].applicationNo;
              this.applicationNos.push(applicationNo);
        }
      this.isTableActive = false;
    })
  }

  private refreshDataTable = (res: any) => {
    this.dataSource.data = res.data;
    this.totalSize = res.totalRecord;
    this.currentPage = (res.currentPage - 1);
    this.dataSource._updateChangeSubscription();
    if (!this.cdr['destroyed']) {
      this.cdr.detectChanges();
    }
  }

  onPreviewClick(id:any){
    this.router.navigate (['/commitee-rc-prep-workbench'],{ queryParams: { application_id:id}});
    console.log(id)
  }

  submit() {
    if(!this.applicationNo) {
      this.isTableActive = false;
      throw new Error("Application No must not be empty");
    }
    this.fetchLinkedApplicants();
  }
}
