import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ConfirmationModalComponent } from 'src/app/confirmation-modal/confirmation-modal.component';
import { MatDialogConfig, MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { JccnoEncodeDecodeService } from 'src/services/jccno-encode-decode/jccno-encode-decode.service';
import { ActivatedRoute } from '@angular/router';
import { ApplicationService } from 'src/services/application.service';
@Component({
  selector: 'app-basic-info',
  templateUrl: './basic-info.component.html',
  styleUrls: ['./basic-info.component.scss']
})
export class BasicInfoComponent implements OnInit {

  @Input() data : any;

  @Input() isEditable : any;

  selectedMenu: any = {};

  pageName: string = "site_details";

  extendLandPerRTC: any;

  rtcTotalArea: any ;

  rtcKharabArea: any ;

  rtcRemainingExtent: any ;

  finalNotification: any ;

  columnExtDifference: any ;

  applicantId: any;

  applicantNo: any;

  villages: any[];

  village: any;

  villageId: any;

  selectedVillage: any;

  surveyNumbers: any;

  awardSurveyMasterId: any;

  selectedSurveyMasterId: any;

  selectedTotalArea: any;

  landRtcTotalAreaValue: any;

  public oneTimeEdit = false;
  public showOneTimeEditButton = false;
  public showSaveChangesBtn = false;

  @Output() newApplicantCallback = new EventEmitter<any>();

  @Output() oneTimeEditCallback = new EventEmitter<any>();

  public acres: any[] = new Array;

  public guntas: any[] = new Array;

  public datas: any[] = new Array;

  public selectedAcre: any;

  public selectedGunta: any;

  landRtcKharabAreaValue: any;

  landRtcTotalAreaDecimalValue: any;

  landRtcKharabAreaDecimalValue: any;

  landRtcRemainingValue: any;

  landRtcRemainingDecimalValue: any;

  extentFinalNotificationValue: any;

  extentFinalNotificationDecimalValue: any;

  extentDifferenceValue: any;

  extentDifferenceDecimalValue: any;

  landRtcTotalAreaAcre: any;

  landRtcTotalAreaGunta: any;

  landRtcKharabAreaAcre: any;

  landRtcKharabAreaGunta: any;

  landRtcRemainingAcre: any;

  landRtcRemainingGunta: any;

  extentFinalNotificationAcre: any;

  extentFinalNotificationGunta: any;

  extentDifferenceAcre: any;

  extentDifferenceGunta: any;

  comments: any;

  decimalValue: any;

  differentExtentComment: any;

  extentFinalNotificationComment: any;
  
  constructor(private applicationService: ApplicationService,
    public dialog: MatDialog,
    private _snackBar: MatSnackBar,
    private route: ActivatedRoute,
    private jccEncodeDecoder: JccnoEncodeDecodeService) { 
      this.applicantId = this.route.snapshot.queryParams['application_id'];
      let i = 0;
      Array(201).fill(0).map((x, i) => {
        this.acres.push({ label: `${i}`, value: i })
    });
    Array(40).fill(0).map((x, i) => {
      this.guntas.push({ label: `${i}`, value: i })
  });
}

  ngOnInit(): void {
    this.fetchVillages();
    this.fetchApplicantById(this.applicantId);
    this.decimalValueDatas();
  }

  fetchVillages(){
    this.applicationService.fetchAllVillages().subscribe(res=>{
      this.villages = res;
    })
  }
  onVillageChange(villageId: any, data?:any) {
      this.applicationService.fetchSurveyNumberByVillageId(villageId).subscribe(res=>{
        this.surveyNumbers = res;
        if(data) {
          this.onSurveyNumberChange(data.surveyNumber);
        }
      })
  }

  onSurveyNumberChange(surveyNumber: any) {
      this.selectedSurveyMasterId  = surveyNumber;
  }

  onAcreChange(acres:any, code: any) {
    if(acres == null) {
      throw new Error('Acres field must not be empty');
    }
    if(code == 'total_area') {
      this.landRtcTotalAreaAcre = acres;
    }
    if(code == 'kharab_area') {
      this.landRtcKharabAreaAcre = acres;
    }
    if(code == 'remaining_extent') {
      this.landRtcRemainingAcre = acres;
    }
    if(code == 'final_notification') {
      this.extentFinalNotificationAcre = acres;
    }
    if(code == 'diff_extent') {
      this.extentDifferenceAcre = acres;
    }
  }
  

  onGuntaChange(guntas:any, code: any) {
    if(guntas == null) {
      throw new Error('Gunta field must not be empty');
    }
    if(code == 'total_area') {
      if(!this.landRtcTotalAreaAcre) {
        this.landRtcTotalAreaGunta = null;
        throw new Error('Acres field must not be empty');
      }
      this.landRtcTotalAreaGunta = guntas;
      this.landRtcTotalAreaAcre = this.landRtcTotalAreaAcre;
    }
    if(code == 'kharab_area') {
      if(!this.landRtcKharabAreaAcre) {
        this.landRtcKharabAreaGunta = null;
        throw new Error('Acres field must not be empty');
      }
      this.landRtcKharabAreaGunta = guntas;
    }
    if(code == 'remaining_extent') {
      if(!this.landRtcRemainingAcre) {
        this.landRtcRemainingGunta = null;
        throw new Error('Acres field must not be empty');
      }
      this.landRtcRemainingGunta = guntas;
    }
    if(code == 'final_notification') {
      if(!this.extentFinalNotificationAcre) {
        this.extentFinalNotificationGunta = null;
        throw new Error('Acres field must not be empty');
      }
      this.extentFinalNotificationGunta = guntas;
    }
    if(code == 'diff_extent') {
      if(!this.extentDifferenceAcre) {
        this.extentDifferenceGunta = null;
        throw new Error('Acres field must not be empty');
      }
      this.extentDifferenceGunta = guntas;
    }
  }

  onDecimalValueChange(decimalValue: any, code: any) {
    if(decimalValue == null) {
      throw new Error('Decimal Value field must not be empty');
    }
    if(code == 'total_area') {
      if(this.landRtcTotalAreaAcre == null) {
        throw new Error('Acres field must not be empty');
      }
      if(this.landRtcTotalAreaGunta == null) {
        throw new Error('Guntas field must not be empty');
      }
      this.landRtcTotalAreaDecimalValue = decimalValue;
      this.fetchCalculatedValuesUsingAcreAndGunta(this.landRtcTotalAreaAcre, this.landRtcTotalAreaGunta,this.landRtcTotalAreaDecimalValue, 'total_area');

    }
    if(code == 'kharab_area') {
      if(this.landRtcKharabAreaAcre == null) {
        throw new Error('Acres field must not be empty');
      }
      if(this.landRtcKharabAreaGunta == null) {
        throw new Error('Guntas field must not be empty');
      }
      this.landRtcKharabAreaDecimalValue = decimalValue;
      this.fetchCalculatedValuesUsingAcreAndGunta(this.landRtcKharabAreaAcre, this.landRtcKharabAreaGunta,this.landRtcKharabAreaDecimalValue, 'kharab_area');

    }
    if(code == 'remaining_extent') {
      if(this.landRtcRemainingAcre == null) {
        throw new Error('Acres field must not be empty');
      }
      if(this.landRtcRemainingGunta == null) {
        throw new Error('Guntas field must not be empty');
      }
      this.landRtcRemainingDecimalValue = decimalValue;
      this.fetchCalculatedValuesUsingAcreAndGunta(this.landRtcRemainingAcre, this.landRtcRemainingGunta,this.landRtcRemainingDecimalValue, 'remaining_extent');

    }
    if(code == 'final_notification') {
      if(this.extentFinalNotificationAcre == null) {
        throw new Error('Acres field must not be empty');
      }
      if(this.extentFinalNotificationGunta == null) {
        throw new Error('Guntas field must not be empty');
      }
      this.extentFinalNotificationDecimalValue = decimalValue;
      this.fetchCalculatedValuesUsingAcreAndGunta(this.extentFinalNotificationAcre, this.extentFinalNotificationGunta,this.extentFinalNotificationDecimalValue, 'final_notification');

    }
    if(code == 'diff_extent') {
      if(this.extentDifferenceAcre == null) {
        throw new Error('Acres field must not be empty');
      }
      if(this.extentDifferenceGunta == null) {
        throw new Error('Guntas field must not be empty');
      }
      this.extentDifferenceDecimalValue = decimalValue;
      this.fetchCalculatedValuesUsingAcreAndGunta(this.extentDifferenceAcre, this.extentDifferenceGunta,this.extentDifferenceDecimalValue, 'diff_extent');

    }
      
  }

public fetchCalculatedValuesUsingAcreAndGunta(acre:any, gunta:any, decimalValue: any,code: any) {
  this.applicationService.fetchCalculatedValues(acre, gunta, decimalValue).subscribe(res=>{
    console.log(res);
    if(code == 'total_area') {
      this.landRtcTotalAreaValue = res;
    }
    if(code == 'kharab_area') {
      this.landRtcKharabAreaValue = res;
    }
    if(code == 'remaining_extent') {
      this.landRtcRemainingValue = res;
    }
    if(code == 'final_notification') {
      this.extentFinalNotificationValue = res;
    }
    if(code == 'diff_extent') {
      this.extentDifferenceValue = res;
    }
  })
}

public decimalValueDatas=()=> {
  this.datas = [
    {
      "label":'0.0',
      "value":'0.0'
    },
    {
      "label":'0.25',
      "value":'0.25'
    },
    {
      "label":'0.5',
      "value":'0.5'
    },
    {
      "label":'0.75',
      "value":'0.75'
    },
  ];
}

  public fetchApplicationData = () => {
    this.applicationService.fetchApplicantByApplicationNo(this.applicantNo).subscribe(res=>{
        console.log("basic info", res);
        this.setEditable(res);
        this.applicantId = res.id;
        this.data = res;
    })
  }

  private fetchApplicantById = (applicantId: any) => {
    if(!applicantId) {
      return;
    }
    this.applicationService.fetchAwardMasterApplicantsById(applicantId).subscribe(res =>{
        this.data = res;
        this.onVillageChange(res.villageId,res);
        console.log(res);
        this.setEditable(res);
        this.setValue(res);
        this.newApplicantCallback.emit(res);
    })
  }

  onSubmit = () => {
    this.openDialog();
  }
  validateAndConstructPayload = () => {
    if(!this.selectedVillage){
      throw new Error('Name of the Village field must not be empty');
    }
    if(this.landRtcTotalAreaAcre == null){
      throw new Error('Total Area Acre field must not be empty');
    }
    if(this.landRtcTotalAreaGunta == null){
      throw new Error('Total Area Gunta field must not be empty');
    }
    if(this.landRtcTotalAreaDecimalValue == null){
      throw new Error('Total Area Decimal Value field must not be empty');
    }
    if(this.landRtcTotalAreaValue == null){
      throw new Error('Total Area Value field must not be empty');
    }
    if(this.landRtcKharabAreaAcre == null){
      throw new Error('Kharab Area Acre field must not be empty');
    }
    if(this.landRtcKharabAreaGunta == null){
      throw new Error('Kharab Area Gunta field must not be empty');
    }
    if(this.landRtcKharabAreaDecimalValue == null){
      throw new Error('Kharab Area Decimal Value field must not be empty');
    }
    if(this.landRtcKharabAreaValue == null){
      throw new Error('Kharab Area Value field must not be empty');
    }
    if(this.landRtcRemainingAcre == null){
      throw new Error('Remianing Extent Acre field must not be empty');
    }
    if(this.landRtcRemainingGunta == null){
      throw new Error('Remianing Extent Gunta field must not be empty');
    }
    if(this.landRtcRemainingDecimalValue == null){
      throw new Error('Remaining Area Decimal Value field must not be empty');
    }
    if(this.landRtcRemainingValue == null){
      throw new Error('Remianing Extent Value field must not be empty');
    }
    if(this.extentFinalNotificationAcre == null){
      throw new Error('Final Notification Acre field must not be empty');
    }
    if(this.extentFinalNotificationGunta == null){
      throw new Error('Final Notification Gunta field must not be empty');
    }
    if(this.extentFinalNotificationDecimalValue == null){
      throw new Error('Final Notification Decimal Value field must not be empty');
    }
    if(this.extentFinalNotificationValue == null){
      throw new Error('Final Notification Value field must not be empty');
    }
    if(this.extentDifferenceAcre == null){
      throw new Error('Extent Difference Acre field must not be empty');
    }
    if(this.extentDifferenceGunta == null){
      throw new Error('Extent Difference Gunta field must not be empty');
    }
    if(this.extentDifferenceDecimalValue == null){
      throw new Error('Extent Difference Decimal Value field must not be empty');
    }
    if(this.extentDifferenceValue == null){
      throw new Error('Extent Difference Value field must not be empty');
    }
    let payload = {};
    payload['awardSurveyMasterId'] = this.awardSurveyMasterId;
    payload['landRtcTotalAreaAcre'] = this.landRtcTotalAreaAcre;
    payload['landRtcTotalAreaGunta'] = this.landRtcTotalAreaGunta;
    payload['landRtcTotalAreaDecimalValue'] = this.landRtcTotalAreaDecimalValue;
    payload['landRtcTotalAreaValue'] = this.landRtcTotalAreaValue;
    payload['landRtcKharabAreaAcre'] = this.landRtcKharabAreaAcre;
    payload['landRtcKharabAreaGunta'] = this.landRtcKharabAreaGunta;
    payload['landRtcKharabAreaDecimalValue'] = this.landRtcKharabAreaDecimalValue;
    payload['landRtcKharabAreaValue'] = this.landRtcKharabAreaValue;
    payload['landRtcRemainingAcre'] = this.landRtcRemainingAcre;
    payload['landRtcRemainingGunta'] = this.landRtcRemainingGunta;
    payload['landRtcRemainingDecimalValue'] = this.landRtcRemainingDecimalValue;
    payload['landRtcRemainingValue'] = this.landRtcRemainingValue;
    payload['extentFinalNotificationAcre'] = this.extentFinalNotificationAcre;
    payload['extentFinalNotificationGunta'] = this.extentFinalNotificationGunta;
    payload['extentFinalNotificationDecimalValue'] = this.extentFinalNotificationDecimalValue;
    payload['extentFinalNotificationValue'] = this.extentFinalNotificationValue;
    payload['extentDifferenceAcre'] = this.extentDifferenceAcre;
    payload['extentDifferenceGunta'] = this.extentDifferenceGunta;
    payload['extentDifferenceDecimalValue'] = this.extentDifferenceDecimalValue;
    payload['extentDifferenceValue'] = this.extentDifferenceValue;
    payload['extentFinalNotificationComment'] = this.extentFinalNotificationComment;
    payload['differentExtentComment'] = this.differentExtentComment;
    return payload;
}
openDialog = () => {
  const dialogConfig = new MatDialogConfig();
  dialogConfig.disableClose = false;
  dialogConfig.autoFocus = true;
  dialogConfig.height = '160px';
  dialogConfig.width = '360px';
  dialogConfig.data = "Are you sure want to submit this record ?"
  const dialogRef = this.dialog.open(ConfirmationModalComponent,dialogConfig);
  dialogRef.afterClosed().subscribe(res =>{
      if(res){
        if(this.applicantId) {
          this.updateBasicInfoDetailsApiCall(this.applicantId);
          return;
        }
      }
  })
}
updateBasicInfoDetailsApiCall = (applicantId: any) => {
  try{
    let payload  = this.validateAndConstructPayload();
    this.applicationService.saveBasicInfo(applicantId, payload).subscribe(res =>{
      this.data = res;
      this.setEditable(res);
      this.openSnackbar("Successfully Updated", "Dismiss");
    }, err => {
        console.log(err);
        this.openSnackbar(err?.message);
    })
  } catch(e){
    this.openSnackbar(e);
  }
}
private setEditable = (data: any): void => {
  if(data['oneTimeEdit'] != null) {
    this.isEditable = data['oneTimeEdit'];
    console.log(this.isEditable);
  }
}
openSnackbar(message: string, action?: string){
  if(!action) {
    action = "Dismiss";
  }
  this._snackBar.open(message, action);
}
setValue(data: any): void{
  console.log('data ', data)
  this.selectedVillage = data.villageId;
  this.awardSurveyMasterId = data.surveyMasterId;
  this.landRtcTotalAreaAcre = data.landRtcTotalAreaAcre;
  this.landRtcTotalAreaGunta = data.landRtcTotalAreaGunta;
  this.landRtcTotalAreaDecimalValue = data.landRtcTotalAreaDecimalValue;
  this.landRtcTotalAreaValue = data.landRtcTotalAreaValue;
  this.landRtcKharabAreaAcre = data.landRtcKharabAreaAcre;
  this.landRtcKharabAreaGunta = data.landRtcKharabAreaGunta;
  this.landRtcKharabAreaDecimalValue = data.landRtcKharabAreaDecimalValue;
  this.landRtcKharabAreaValue = data.landRtcKharabAreaValue;
  this.landRtcRemainingAcre = data.landRtcRemainingAcre;
  this.landRtcRemainingGunta = data.landRtcRemainingGunta;
  this.landRtcRemainingDecimalValue = data.landRtcRemainingDecimalValue;
  this.landRtcRemainingValue = data.landRtcRemainingValue;
  this.extentFinalNotificationComment = data.finalNotificationComment;
  this.differentExtentComment = data.extentComment;
  this.extentFinalNotificationAcre = data.extentFinalNotificationAcre;
  this.extentFinalNotificationGunta = data.extentFinalNotificationGunta;
  this.extentFinalNotificationDecimalValue = data.extentFinalNotificationDecimalValue;
  this.extentFinalNotificationValue = data.extentFinalNotificationValue;
  this.extentDifferenceAcre = data.extentDifferenceAcre;
  this.extentDifferenceGunta = data.extentDifferenceGunta;
  this.extentDifferenceDecimalValue = data.extentDifferenceDecimalValue;
  this.extentDifferenceValue = data.extentDifferenceValue;
}
}
