import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { PageEvent } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { faSync } from '@fortawesome/free-solid-svg-icons';
import { ApplicationService } from 'src/services/application.service';
import { StrorageService } from 'src/services/strorage.service';

@Component({
  selector: 'app-rc-register-data-enrichemnt-activity-log',
  templateUrl: './rc-register-data-enrichemnt-activity-log.component.html',
  styleUrls: ['./rc-register-data-enrichemnt-activity-log.component.scss']
})
export class RcRegisterDataEnrichemntActivityLogComponent implements OnInit {

  public faSync = faSync;

  public isRefreshing = false;

  public displayedColumns: string[] = ['sn', 'groupId', 'groupSize', 'jccNo', 'applicantName', 'timeStamp', 'processedBy'];

  public dataSource = new MatTableDataSource<any>();

  public pageSize = 5;

  public currentPage = 0;

  public totalSize: number = 0;

  user: any;

  village: any;

  villageId: any;

  villages: any[];

  applicationNo: any;

  show: boolean;

  constructor(private storageService: StrorageService,
    private applicationService: ApplicationService,
    private cdr: ChangeDetectorRef) { }

  ngOnInit(): void {
    this.user = this.storageService.getUser();
    this.fetchVillages();
    this.fetchForSdDataValidationApplicants();
  }

  fetchVillages() {
    this.applicationService.fetchAllVillages().subscribe(res => {
      this.villages = res;
      this.villages.unshift({
        "id": "",
        "name": "All"
      });
    })
  }

  searchApplicant() {
    this.fetchForSdDataValidationApplicants();
  }

  clickBack() {
    window.history.back();
  }

  onClear() {
    this.applicationNo = null;
    this.fetchForSdDataValidationApplicants();
  }

  onVillageChange(): void {
    this.show = false;
    this.fetchForSdDataValidationApplicants();
  }

  fetchForSdDataValidationApplicants() {
    this.applicationService.fetchRcRegisterActivityLogApplicants(this.applicationNo, this.villageId, this.currentPage, this.pageSize).subscribe(res => {
      console.log(res);
      this.refreshDataTable(res);
    })
  }

  private refreshDataTable = (res: any) => {
    this.dataSource.data = res.data;
    this.totalSize = res.totalRecord;
    this.currentPage = (res.currentPage - 1);
    this.dataSource._updateChangeSubscription();
    if (!this.cdr['destroyed']) {
      this.cdr.detectChanges();
    }
  }

  public getApplicantPaginatorData(event: PageEvent) {
    this.currentPage = event.pageIndex + 1;
    this.pageSize = event.pageSize;
    this.fetchForSdDataValidationApplicants();
  }

  refreshClick() {
    let currentComponent = this;
    this.isRefreshing = true;
    setTimeout(() => {
      currentComponent.isRefreshing = false;
    }, 3000)
    this.fetchForSdDataValidationApplicants();
  }
}
