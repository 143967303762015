<div class="content-area">
    <div class="col-sm-10 offset-sm-1">
        <app-top-bar selectedTab="home"></app-top-bar>
        
        <div class="heading">
            {{selectedHeading}}
        </div>

        <app-layout-map></app-layout-map>
    </div>
    </div>
    <div class="rows" style="margin-left: 124px;">
        <div class="col-sm-2">
            <app-tile tileName="Notification Details" backgroundColor="grey" 
            (onClickHandler)="onNotificationDetailsClicked($event, selectedHeading)" tileType="notification_details"></app-tile>
        </div>
            <div class="col-sm-2">
                <app-tile tileName="Action Plan" backgroundColor="grey" 
                tileType="action_plan" (onClickHandler)="onActionPlanClicked($event, selectedHeading)"></app-tile>
            </div>
            <div class="col-sm-2">
                <app-tile tileName="Progress Tackled" backgroundColor="grey" 
                tileType="progress_tackled" (onClickHandler)="onProgressTackledClicked($event, selectedHeading)"></app-tile>
            </div>
            <div class="col-sm-2">
                <app-tile tileName="Package Sector" backgroundColor="grey" 
                tileType="sector_package" (onClickHandler)="onSectorPackageClicked($event, selectedHeading)"></app-tile>
            </div>
            <div class="col-sm-2">
                <app-tile tileName="Land Handover Status" backgroundColor="grey" 
                tileType="land_hand_over_status" (onClickHandler)="onLandHandOverStatusClicked($event, selectedHeading)"></app-tile>
            </div>
    </div>
    <div class="rows" style="margin-left: 124px;">
        <div class="col-sm-2">
            <app-tile tileName="EE Observations" backgroundColor="grey" 
            (onClickHandler)="onEEObservationsClicked($event, selectedHeading)" tileType="ee_observations"></app-tile>
        </div>
    </div>
