<div class="row content-area">
    <div class="col-sm-11 title">
        DMS Info Preview
    </div>
    <div class=" col-sm-1">
        <fa-icon [icon]="faTimes" class="close-icon" (click)="cancelClick()"></fa-icon>
    </div>

    <div class="label col-sm-12" *ngIf="data">
        <div class="row">
            <div class="col-sm-4 ">
                <label>Document Type</label>
            </div>
            <div class="col-sm-8">
                <label>{{data.documentType}}</label>
            </div>
        </div>
        <div class="row">
            <div class="col-sm-4 ">
                <label>Document Code</label>
            </div>
            <div class="col-sm-8">
                <label>{{data.documentCode}}</label>
            </div>
        </div>
        <div class="row">
            <div class="col-sm-4 ">
                <label>Document Sub Type</label>
            </div>
            <div class="col-sm-8">
                <label>{{data.documentSubType}}</label>
            </div>
        </div>
        <div class="row">
            <div class="col-sm-4 ">
                <label>Document Date</label>
            </div>
            <div class="col-sm-8">
                <label>{{data.documentDate}}</label>
            </div>
        </div>
        <div class="row">
            <div class="col-sm-4 ">
                <label>Document Name</label>
            </div>
            <div class="col-sm-8">
                <label>{{data.documentName}}</label>
            </div>
        </div>
        <div class="row">
            <div class="col-sm-4 ">
                <label>Document Number</label>
            </div>
            <div class="col-sm-8">
                <label>{{data.documentNumber}}</label>
            </div>
        </div>
        <div class="row">
            <div class="col-sm-4 ">
                <label>Document Description</label>
            </div>
            <div class="col-sm-8">
                <label style="word-wrap: break-word;
                max-width: 26rem;">{{data.description}}</label>
            </div>
        </div>
    </div>
</div>