<div class="row content-area">
            <div class="col-sm-12 col-md-12">
                <div class="row application-form-row ">
                    <label class="form-label">Survey/Site No / ಸರ್ವೆ ಸಂಖ್ಯೆ / ನಿವೇಶನದ ಸಂಖ್ಯೆ. :</label>
                    <input type="text" [(ngModel)]="siteNumber" class="form-textbox" readonly/>
                </div>
            </div>

            <div class="col-sm-12 col-md-12">
                <div class="row application-form-row ">
                    <label class="form-label">Total area of Complex (in Sq. ft)/ Area of apartment(in Sq. ft) / ಕಟ್ಟಡ ಸಂಕೀರ್ಣದ ಒಟ್ಟು ವಿಸ್ತೀರ್ಣ / ಅಪಾರ್ಟ್ಮೆಂಟ್ ನ ವಿಸ್ತೀರ್ಣ:</label>
                    <input type="text" [(ngModel)]="aptTotalarea" class="form-textbox" [readonly] = "(data && data.aptTotalarea) && !isEditable"/>
                </div>
            </div>

            <div class="col-sm-12 col-md-12">
                <div class="row application-form-row ">
                    <label class="form-label">Built up area (in Sq.ft) / ಕಟ್ಟಡದ ವಿಸ್ತೀರ್ಣ :</label>
                    <input type="number" [(ngModel)]="aptBuildUpArea" step="any" class="form-textbox" [readonly] = "(data && data.aptBuildUpArea) && !isEditable"/>
                </div>
            </div>
            <div class="col-sm-12 col-md-12">
                <div class="row application-form-row ">
                    <label class="form-label">Floors/Blocks / ಮಹಡಿಗಳು / ಬ್ಲಾಕ್ ಗಳು :</label>
                    <input type="text" [(ngModel)]="aptFloors"  class="form-textbox" [readonly] = "(data && data.aptFloors) && !isEditable"/>
                </div>
            </div>
          
            <div class="col-sm-12 col-md-12">
                <div class="row application-form-row ">
                    <label class="form-label">Address of the property / ಆಸ್ತಿಯ ವಿಳಾಸ :</label>
                    <input type="text" class="form-textbox" [(ngModel)]="buildingAddress" readonly placeholder="Address"/>
                </div>
            </div>
         
        

