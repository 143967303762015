<div class="container">
  <div>
    <app-top-bar selectedTab="home"></app-top-bar>
    <app-header></app-header>
  </div>
  <br>
  <div class="tool-bar">
    <div class="refresh-btn">  
    </div>
    <div class="col-sm-1 ">
      <button class="btn btn-primary refresh-btn" (click)="refreshClick()">
        <fa-icon [icon]="faSync" class="close-icon" [spin]="isRefreshing"></fa-icon>
      </button>
    </div>
    <div class="col-sm-5 title ">
      Power User Module
    </div>
    <div class="col-sm-3 offset-sm-1 user-details">
      <div class="value-text">
        Welcome <br>
        {{user['firstName']}} !
      </div>
    </div>
  </div>
  <div class="d-flex justify-content-center align-items-center mt-2">
    <label class="mr-3 mt-2"> Enter JCC number :</label>
    <!-- <input class="mr-3" [(ngModel)] = "applicationNo" type="text"> -->
    <div class="row application-form-row ">
          <!-- <form class="example-form w-50"> -->
              <mat-form-field class="w-50 mat-form-resize" appearance="outline">
                <input type="text"
                class="form-textbox w-50"
                       placeholder=""
                       matInput
                       [formControl]="myControl"
                       [(ngModel)]="applicationNo"
                       [matAutocomplete]="auto" >
                <mat-autocomplete #auto="matAutocomplete">
                  <mat-option *ngFor="let applicationNo of filteredOptions | async" [value]="applicationNo">
                      {{applicationNo}}
                  </mat-option>
              </mat-autocomplete>
          </mat-form-field>
      <!-- </form> -->
  </div>
    <button class="btn btn-primary" (click) = "submit()" style="margin-left: 49px;">submit</button>
  </div>
  <div class="col-sm-12" *ngIf = "isTableActive">
    <mat-card>
        <table mat-table [dataSource]="dataSource">
            <ng-container matColumnDef="sn">
                <th mat-header-cell *matHeaderCellDef> Serial No. </th>
                <td mat-cell *matCellDef="let element ;let i =index;" class="column-container">{{i+1}}
                  </ng-container>
            <!-- Position Column -->
            <ng-container matColumnDef="jccNO">
                <th mat-header-cell *matHeaderCellDef> JCC No  </th>
                <td mat-cell *matCellDef="let element">
                    <a (click)="onPreviewClick(element.id)" class="preview-link"> {{element.applicationNo}} </a> 
                </td>
            </ng-container>
            
            <!-- Name Column -->
            <ng-container matColumnDef="groupId">
                <th mat-header-cell *matHeaderCellDef>Group Id.</th>
                <td mat-cell *matCellDef="let element"> {{element.groupNo}} </td>                
            </ng-container>
  
            <!-- Group Size Column -->
            <ng-container matColumnDef="groupSize">
                <th mat-header-cell *matHeaderCellDef> Group Size </th>
                <td mat-cell *matCellDef="let element"> {{element.groupSize}} </td>
            </ng-container>
            
            <!-- Telephone Column -->
            <ng-container matColumnDef="villageName">
                <th mat-header-cell *matHeaderCellDef> Villge Name </th>
                <td mat-cell *matCellDef="let element"> {{element.villageName}} </td>
            </ng-container>
  
            <ng-container matColumnDef="activeStatus">
                <th mat-header-cell *matHeaderCellDef> Active Status </th>
                <td mat-cell *matCellDef="let element"> 
                    <span *ngIf="element.status && element.status.length > 0">{{element.status}}</span> 
                </td>
            </ng-container>
  
            <ng-container matColumnDef="notificationType">
                <th mat-header-cell *matHeaderCellDef> Notification Type  </th>
                <td mat-cell *matCellDef="let element">
                    {{element.notificationType}} 
                </td>
            </ng-container>
  
            <ng-container matColumnDef="isValid">
                <th mat-header-cell *matHeaderCellDef> Is Valid </th>
                <td mat-cell *matCellDef="let element"> 
                    <span>{{element.valid ? 'Valid' : 'InValid'}}</span> 
                </td>
            </ng-container>
  
            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;" ></tr>
        </table>
        <div class="no-records-text" *ngIf="!dataSource.data || dataSource.data.length <= 0">No Records Found!</div>
        <mat-paginator [pageSize]="pageSize" [pageSizeOptions]="[5, 10, 20]" [showFirstLastButtons]="true" 
        [pageIndex]="currentPage" [length]="totalSize" (page)="getApplicantPaginatorData($event)">
    </mat-paginator>
    </mat-card>
    </div>
</div>
<app-footer></app-footer>