import { ChangeDetectorRef, Component, EventEmitter, OnInit, Output } from '@angular/core';
import {ScrollingModule} from '@angular/cdk/scrolling';
import { Router, ActivatedRoute } from '@angular/router';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ApplicationService } from 'src/services/application.service';
import { JccnoEncodeDecodeService } from 'src/services/jccno-encode-decode/jccno-encode-decode.service';
import { PreviewDialogsComponent } from '../preview-dialogs/preview-dialogs.component';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { UserService } from 'src/services/user-service/user.service';
import { RoleConfigService } from 'src/app/shared/config/role/role.config.service';
import { StrorageService } from 'src/services/strorage.service';
import { ConfirmDialogComponent } from 'src/app/shared/confirm-dialog/confirm-dialog/confirm-dialog.component';
import { ConfirmDialogService } from 'src/app/shared/confirm-dialog/confirm-dialog.service';
@Component({
  selector: 'app-preview',
  templateUrl: './preview.component.html',
  styleUrls: ['./preview.component.scss']
})
export class PreviewComponent implements OnInit {

  public data:any;
  comment:any;
  applicantId: any;
  public isEditable = false;
  public oneTimeEdit = false;
  public showOneTimeEditButton = false;
  public showSaveChangesBtn = false;
  public showBasicInfo = false;

  public menus: any = [
    {
      "name": "Basic Info",
      "code": "basic_info",
      "active": true,
      "default": true,
      "showMatCard": true,
      "class":'col-sm-1',
    }
  ]

  @Output() oneTimeEditCallback = new EventEmitter<any>();


 @Output() saveChangesCallback = new EventEmitter<any>();

  selectedMenu: any;
  villageName: any;
  villageId: any;

  constructor(private router: Router,private applicationService: ApplicationService,
     public dialog: MatDialog,
     private _snackBar: MatSnackBar,
     private route: ActivatedRoute,
     private jccEncodeDecoder: JccnoEncodeDecodeService,
     public userService: UserService,private roleConfig:RoleConfigService, 
     private cdr:ChangeDetectorRef, private storageService: StrorageService, private confirmDialogService: ConfirmDialogService,  ) { 
      this.villageName = this.route.snapshot.queryParams['village_name'];
      this.villageId = this.route.snapshot.queryParams['village_id'];
      this.applicantId = this.route.snapshot.queryParams['application_id'];
     }

  ngOnInit(): void {
    this.fetchApplicantById(this.applicantId);
  }
  private fetchApplicantById = (applicantId: any) => {
    if(!applicantId) {
      return;
    }
    this.applicationService.fetchAwardMasterApplicantsById(applicantId).subscribe(res =>{
        this.data = res;
        console.log(res);
        this.resetUI(res);
    })
  }
  onSubmit = () => {
    this.openDialog();
  }

  openDialog = () => {

  }
  onPreview = () => {
  }
  onPreviewClick = () => {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = false;
    dialogConfig.autoFocus = true;
    dialogConfig.height = '80vh';
    dialogConfig.width = '50vw';
    dialogConfig.data = this.comment;
    const dialogRef = this.dialog.open(PreviewDialogsComponent,dialogConfig);
    dialogRef.afterClosed().subscribe(res =>{
    })
  }
  onEdit= () => {
    this.openDialog();
  }
  onEnclosure= () => {
    this.openDialog();
  }
  setValue(data: any): void{
    console.log('data ', data)
  }
  openSnackbar = (message: string, action?: string) => {
    if(!action) {
      action = "Dismiss";
    }
    this._snackBar.open(message, action);
  }
  public activateOneTimeEdit = () => {
    this.applicationService.activateAwardMasterOneTimeEdit(this.applicantId).subscribe(res =>{
      this.isEditable = true;
      this.resetUI(res);
      this.openSnackbar("Successfully activated");
      this.oneTimeEditCallback.emit();
    }, err => {
      console.log('activate one time edit error', err);
    })
 }
 public closeOneTimeEdit = (): void => {
  this.applicationService.closeAwardMasterOneTimeEdit(this.applicantId).subscribe(res =>{
    this.openSnackbar("Successfully Closed One Time Edit");
    this.saveChangesCallback.emit();
    this.isEditable = false;
  }, err => {
    console.log('close one time edit error', err);
  })
}

 private resetUI = (applicantData: any) => {
  if(applicantData['oneTimeEdit'] == null) {
    this.showOneTimeEditButton = true;
  }
  if(applicantData['oneTimeEdit']) {
    this.showOneTimeEditButton = false;
    this.showSaveChangesBtn = true;
  }
 }
 onDone = () => {
      let dialogReferance = this.dialog.open(ConfirmDialogComponent, {
        data: {
            title: "Confirm",
            message: "Application is Award Scrutinised",
            cancelText: "CANCEL",
            confirmText: "OK"
        },
        maxHeight: "100%",
        width: "540px",
        maxWidth: "100%",
        disableClose: true,
        hasBackdrop: true
      });
      dialogReferance.afterClosed().subscribe(data => {
        let confirmed = data
          if(confirmed) {
            let payload = {}
             payload['comment'] = this.comment;
              this.applicationService.markAsDone(this.applicantId, payload).subscribe(res => {
                console.log(res);
                this.clickBack();
            })
          }
        });
    }
    clickBack(){
      window.history.back();
    }
}
