import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { Router, ActivatedRoute } from '@angular/router';
import { ConfirmDialogService } from 'src/app/shared/confirm-dialog/confirm-dialog.service';
import { MessageDialogService } from 'src/app/shared/message-dialog/message-dialog.service';
import { ApplicationService } from 'src/services/application.service';
import { StrorageService } from 'src/services/strorage.service';

@Component({
  selector: 'app-rc-sd-data-validation-workbench',
  templateUrl: './rc-sd-data-validation-workbench.component.html',
  styleUrls: ['./rc-sd-data-validation-workbench.component.scss']
})
export class RcSdDataValidationWorkbenchComponent implements OnInit {

  applicantId: any;
  user: any;
  public dataList: any [];
  public dataSource = new MatTableDataSource<any>();
  public pageSize =5;
  public currentPage = 0;
  public totalSize: number= 0;
  public applicantName: any;
  public applicantNameKannada: any;
  public buildingAddress: any;
  public groupNumber: any;
  public groupSize: any;
  public structureType: any;
  public villages: any[];
  public villageId: any;
  public siteNumber: any;
  public surveyNumber: any;
  public applicationNo: any;
  public activeStatus: any;
  public plinthArea: any;
  public cdFeederReportData: any;
  public sitalArea: any;
  public noOfFloors: any;
  public buildUpArea: any;
  public east: any;
  public west: any;
  public north: any;
  public south: any;
  @Input() isEditable : any;
  public length: any;
  public breadth: any;
  options:any;
  finalNotification:any;
  rtcRemainingExtent: any;
  submitReport: any;
  public possibileCdError: any;
  public reportDate: Date;
  public maxDate = new Date().getTime();
  fakeArray = new Array(5);
  applicationData: any;
  processingJccNos: any;
  public selectedApplicants: any = [];
  public disableSubmitButton = false;
  annexureName: string;
  public rcSerialNo:any;
  public scApprovedNo: any;
  public processedBy: any;
  public rcPrepData : any;
  annexure: any;
  public approvalDate: any;
  address: any;
  eligibleApplicantsCount: any;
  selectedApplicantStatus: any;
  filteredApplicants: number;
  heading: any;
  userType: any;
  groupNo: any;
  dataa: any = [];


  constructor(
    private applicationService: ApplicationService,
    private cdr:ChangeDetectorRef, 
      private confirmDialogService: ConfirmDialogService, 
    private router: Router,private storageService:StrorageService,
    private messageDialogService: MessageDialogService,
    private route:ActivatedRoute) {
      this.applicantId = this.route.snapshot.queryParams['applicantId'];
     }

    ngOnInit(): void {
      window.scrollTo(0, 0);
      this.user=this.storageService.getUser();
      this.fetchVillages();
      this.fetchDataEnrichmentApplicantsByApplicantId();
   }

   fetchVillages() {
    this.applicationService.fetchAllVillages().subscribe(res=>{
     this.villages = res;
     this.villages.unshift({
       "id":"",
       "name":"All"
     });
     })
   }
   
    clickBack(){
      window.history.back();
    }

    fetchDataEnrichmentApplicantsByApplicantId() {
        this.applicationService.fetchRcValidationDataByApplicantId(this.applicantId).subscribe(res=>{
          this.dataList = res;
          for(let i=0; i< res.length;i++) {
            this.groupNumber = res[i].groupNumber;
            this.groupSize = res[i].groupSize;
            this.eligibleApplicantsCount = res[i].eligibleApplicantsCount;
          }
        })

    }
    

    validateAndConstructPayload = (data :any) => {
      for(let i=0; i < data.length;i++) {
          data[i].applicantNameKannada = data[i].applicantNameKannada;
          data[i].villageId = data[i].villageId;
          data[i].surveyNumber = data[i].surveyNumber;
          data[i].siteNumber = data[i].siteNumber;
          data[i].sitalArea = data[i].sitalArea;
          this.validateData(data[i]);
      }
      return data;
    }

    validateData(data: any) {
        if(!data.applicantName){
          throw new Error(data.applicationNo+'-'+'Applicant Name must not be empty');
        }
        if(!data.buildingAddress){
          throw new Error(data.applicationNo+'-'+'Address must not be empty');
        }
        if(!data.applicantNameKannada){
          throw new Error(data.applicationNo+'-'+'Applicant Name Kannada must not be empty');
        }
        if(!data.villageId){
          throw new Error(data.applicationNo+'-'+'Village must not be empty');
        }
        if(!data.surveyNumber){
          throw new Error(data.applicationNo+'-'+'Survey Number must not be empty');
        }
        if(!data.sitalArea){
          throw new Error(data.applicationNo+'-'+'Sital Area field must not be empty');
        }
    }


    onSubmit(data: any) {
      let array = [];
      for(let i=0; i < data.length;i++) {
        if(data[i].applicationNo){
        array.push(data[i].applicationNo);
        }
      }
      let applicationNo = array.toString();
      console.log(applicationNo)
        const options = {
          title: 'Confirmation!',
          message: 'Confirm SD data of JCC No : ' + applicationNo +' verified & updated ', 
          cancelText:'No',
          confirmText: 'Yes',
          
        
      };
        this.confirmDialogService.openDialog(options);
        this.confirmDialogService.confirmed().subscribe(confirmed => {
          if(confirmed) {
            let payload = this.validateAndConstructPayload(data);
              this.applicationService.submitRcRegisterValidationApplicants(payload).subscribe(res =>{
                this.router.navigate(['/rc-sd-data-validation-dashboard']);
            })
          }
        });
  }

  encloPage(applicantId: any) {
    let fullURL = location.protocol+'//'+location.hostname+(location.port ? ':'+location.port: '');
    window.open(fullURL+'#/preview-enclosure?application_id='+applicantId, '_blank');
  }

}
