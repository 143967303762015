import { Component, OnInit, ViewChild } from '@angular/core';
import { ApplicationService } from 'src/services/application.service';
import { MatTableDataSource } from '@angular/material/table';
import { Router, ActivatedRoute } from '@angular/router';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { PreviewDialogComponent } from '../../preview-dialog/preview-dialog.component';
import { ApplicantEnclosureListComponent } from '../../applicant-enclosure-list/applicant-enclosure-list.component';
import { UserService } from 'src/services/user-service/user.service';
import { faSync } from '@fortawesome/free-solid-svg-icons';
import { StaticsComponent } from '../../statics/statics.component';
import { RoleConfigService } from '../../shared/config/role/role.config.service';
import { StrorageService } from 'src/services/strorage.service';
import { ApplicantReportDatatableComponent } from '../applicant-report-datatable/applicant-report-datatable.component';

@Component({
  selector: 'app-applicant-report-dashboard',
  templateUrl: './applicant-report-dashboard.component.html',
  styleUrls: ['./applicant-report-dashboard.component.scss']
})
export class ApplicantReportDashboardComponent implements OnInit {

  @ViewChild(StaticsComponent) staticsComponent;

  public displayedColumns: string[] = ['applicationNo', 'applicantName','telephoneNumber','action'];

  public dataSource = new MatTableDataSource();

  public panchayats : any[] = [];

  public villages : any[] = [];

  public isLoading = false;

  public selectedPanchayat;

  public selectVillage;

  public userName = "Username";

  public colors = ['#E74C3C', '#1ABB9C', '#3498DB', '#F39C12', '#9B59B6', '#50C1CF', '#34495E', '#E9967A', '#FA8072', '#E74C3C'];

  public isCommitteeUser;

  public faSync = faSync;

  public isRefreshing = false;

  public roleCode: any;

  public currentPage = 0;

  public pagesize = 5;

  user: any;

  public statusCode: string = "reported";

  public heading:string;

  @ViewChild(ApplicantReportDatatableComponent)
  private applicantReportDatatable: ApplicantReportDatatableComponent;

  constructor(private router: Router, public dialog: MatDialog, private userService: UserService,
    private applicationService: ApplicationService, private roleConfig: RoleConfigService,
    private strorageService:StrorageService, private activeRoute: ActivatedRoute) { }

  ngOnInit(): void {
    this.heading = this.activeRoute.snapshot.queryParamMap.get("heading");
    this.user = this.strorageService.getUser();
    this.userName=this.user['firstName'];
    this.roleCode = this.user['roleCode'];
    if(this.roleCode == 'bda_commr') {
      this.statusCode = 'sc_ordered_jcc_to_bda_commr';
    }
    if(this.roleCode == 'bda_secretary') {
      this.statusCode = 'commissioner_to_secretary'; // see secretary status
    }
    if(this.roleCode == 'bda_ds') {
      this.statusCode = 'secretary_to_DS';
    }
    if(this.roleCode == 'bda_em') {
      this.statusCode = 'ds_to_em';
    }
    if(this.roleCode == 'bda_eo') {
      this.statusCode = 'em_to_eo';
    }
    if(this.roleCode == 'bda_ee') {
      this.statusCode = 'eo_to_ee';
    }
    this.isCommitteeUser = this.roleConfig.ROLE_COMMITTEE;
    if(this.isCommitteeUser)
    this.fetchAllPanchayats();
  }

  onStaticsClick(){
    if(!this.isCommitteeUser)
    this.fetchAllPanchayats();
  }
  OnTrendsClick(){
    let fullURL = location.protocol+'//'+location.hostname+(location.port ? ':'+location.port: '');
  }

  onReportingModuleClick(){
    this.router.navigate(['committee-reporting']);

  }

  onPreviewClick(data){
    this.openPreviewDialog(data);
  }
  openPreviewDialog(data: any) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.height = '80vh';
    dialogConfig.width = '50vw';
    dialogConfig.data = data.applicationNo;
    const dialogRef = this.dialog.open(PreviewDialogComponent,dialogConfig);
    dialogRef.afterClosed().subscribe(res =>{
       
    })
  }

  fetchAllPanchayats() {
     this.applicationService.fetchAllPanchayats().subscribe(res =>{
         this.panchayats = res;
     })
  }

  onPanchayatChange(id){
    this.fetchVillagesByPanchayat(id);
    this.selectedPanchayat = id;
  }

  onVillageChange(id){
    this.fetchApplicantsByVillage(id);
    this.selectVillage = id;
  }

  refreshClick(){
    this.applicantReportDatatable.getAllReports();
  }

  getReportsByStatus(statusCode: any){
    this.applicantReportDatatable.statusCode = statusCode;
    this.applicantReportDatatable.getAllReports();
  }

  public goDetailsPage(data){
    this.openEnclosure(data );
    // this.router.navigate(['/applicant-enclosure-list'], { queryParams: { applicantId: row['id'] } });
  }
  openEnclosure(data) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.height = '80vh';
    dialogConfig.width = '50vw';
    dialogConfig.data =data.id;
    const dialogRef = this.dialog.open(ApplicantEnclosureListComponent,dialogConfig);
    dialogRef.afterClosed().subscribe(res =>{
       
    })
  }
 

  fetchVillagesByPanchayat(id){
   // this.isLoading = true;
    this.applicationService.fetchAllVillagesByPanchayat(id).subscribe(res =>{
      this.villages = res;
    //  this.isLoading = false;
   })
  }

  
  

  

  private fetchApplicantsByVillage(id){
    if(this.isCommitteeUser){
      return;
    }
   // this.isLoading = true;
    this.applicationService.fetchAllApplicantByVillage(id).subscribe(res =>{
      this.dataSource.data = res;
     // this.isLoading = false;
    })
  }

}
