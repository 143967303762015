<div class="col-sm-10 offset-sm-1">
    <app-top-bar selectedTab="home"></app-top-bar>
    <app-header></app-header>

    <div class="col-sm-12 root-container"> 
        <div class="container">
            <div class="row">
                <div class="col-sm-1 refresh-btn-container">
                    <button class="btn btn-primary" (click)="refreshClick()">
                        <fa-icon [icon]="faSync" [spin]="isRefreshing"></fa-icon>
                    </button>
                </div>
                <div class="col-sm-2 refresh-btn-container">
                    <button class="btn btn-primary" (click)="goBack()">
                        Back
                    </button>
                </div>

              <div class="col-sm-5">
                <div class="text">
                 {{cdInfoReportBatchName}}
                </div>
            </div>
        
            <div class="col-sm-4">
                <div class=" user-details">
                    <div class="value-text">
                        Welcome 
                    </div>
                    <div class="value-text">
                         {{userName}} !
                    </div>
                </div>
                </div>

                <div class="col-md-12">
                    <div class="offset-sm-8">
                        <div fxLayout="row" fxLayoutAlign="space-around">
                            <input class="form-control border-end-0 border rounded-pill" style="font-size: 14px !important;"type="text" [(ngModel)]="searchKeyword" placeholder="Search" id="applicationNo">
                            <span class="input-group-append"><br>&nbsp;
                                <button mat-button class="btn btn-primary " (click)="searchApplicant()">Search</button> 
                                <button mat-button *ngIf="searchKeyword" matSuffix mat-icon-button aria-label="Clear" (click)="onClear()">
                                    <mat-icon>close</mat-icon>
                                </button>
                            </span>
                        </div>
                    </div>
                </div>
                <div class="col-md-12">
                    <table mat-table [dataSource]="dataSource">
                    
                        <!-- Position Column -->
                        <ng-container matColumnDef="siNo">
                               <th mat-header-cell *matHeaderCellDef> SI No. </th>
                               <td mat-cell *matCellDef="let element ;let i =index;" class="column-container">{{i+1}}
                           </ng-container>
               
                       <!-- Position Column -->
                       <ng-container matColumnDef="applicationNo">
                           <th mat-header-cell *matHeaderCellDef> Application No. </th>
                           <td mat-cell *matCellDef="let element"> {{element.applicantData.applicationNo}} </td>
                       </ng-container>
                       
                       <!-- Name Column -->
                       <ng-container matColumnDef="applicantName">
                           <th mat-header-cell *matHeaderCellDef> Applicant Name </th>
                           <td mat-cell *matCellDef="let element"> {{element.applicantData.applicantName}} </td>
                       </ng-container>
               
                       <!-- Property Type Column -->
                       <ng-container matColumnDef="l">
                           <th mat-header-cell *matHeaderCellDef> L <br> (in ft)</th>
                           <td mat-cell *matCellDef="let element"> {{element.lengthAvg}} </td>
                       </ng-container>

                        <!-- Property Type Column -->
                        <ng-container matColumnDef="b">
                               <th mat-header-cell *matHeaderCellDef> B <br> (in ft) </th>
                               <td mat-cell *matCellDef="let element"> {{element.breadthAvg}} </td>
                           </ng-container>

                       <!-- Property Type Column -->
                       <ng-container matColumnDef="propertyType">
                           <th mat-header-cell *matHeaderCellDef> PropertyType </th>
                           <td mat-cell *matCellDef="let element"> {{element.propertyTypeName}} </td>
                       </ng-container>
               
                       <!-- No Of Floor Column -->
                       <ng-container matColumnDef="noOfFloor">
                           <th mat-header-cell *matHeaderCellDef> No Of Floor </th>
                           <td mat-cell *matCellDef="let element"> {{element.noOfFloor}} </td>
                       </ng-container>
               
                       <!-- Sital Area Column -->
                       <ng-container matColumnDef="sitalArea">
                           <th mat-header-cell *matHeaderCellDef> Sital area <br> (in Sq.Ft) </th>
                           <td mat-cell *matCellDef="let element"> {{element.sitalArea}} </td>
                       </ng-container>
               
                       <!-- Plinth Area Column -->
                       <ng-container matColumnDef="plinthArea">
                           <th mat-header-cell *matHeaderCellDef> Plinth Area <br> (in Sq.Ft) </th>
                           <td mat-cell *matCellDef="let element"> {{element.plinthArea}} </td>
                       </ng-container>
               
                       <!-- Total Build Up Area Column -->
                       <ng-container matColumnDef="totalBuildUpArea">
                           <th mat-header-cell *matHeaderCellDef> Total Build Up Area <br> (in Sq.Ft) </th>
                           <td mat-cell *matCellDef="let element"> {{element.totalBuildUpArea}} </td>
                       </ng-container>
               
                       <!-- North Column -->
                       <ng-container matColumnDef="north">
                           <th mat-header-cell *matHeaderCellDef> North </th>
                           <td mat-cell *matCellDef="let element"> {{element.north}} </td>
                       </ng-container>
               
                       <!-- East Column -->
                       <ng-container matColumnDef="east">
                           <th mat-header-cell *matHeaderCellDef> East </th>
                           <td mat-cell *matCellDef="let element"> {{element.east}} </td>
                       </ng-container>
               
                       <!-- West Column -->
                       <ng-container matColumnDef="west">
                           <th mat-header-cell *matHeaderCellDef> West </th>
                           <td mat-cell *matCellDef="let element"> {{element.west}} </td>
                       </ng-container>
               
                       <!-- south Column -->
                       <ng-container matColumnDef="south">
                           <th mat-header-cell *matHeaderCellDef> South </th>
                           <td mat-cell *matCellDef="let element"> {{element.south}} </td>
                       </ng-container>

                        <!-- Structure Type Column -->
                        <ng-container matColumnDef="structureType">
                            <th mat-header-cell *matHeaderCellDef> Structure Type </th>
                            <td mat-cell *matCellDef="let element"> {{element.structureType}} </td>
                        </ng-container>

                        <!-- Timestamp Type Column -->
                        <ng-container matColumnDef="createdAt">
                            <th mat-header-cell *matHeaderCellDef> Timestamp </th>
                            <td mat-cell *matCellDef="let element"> {{element.createdAt}} </td>
                       </ng-container>

                        <!-- Timestamp Type Column -->
                        <ng-container matColumnDef="processedByName">
                            <th mat-header-cell *matHeaderCellDef> Processed By </th>
                            <td mat-cell *matCellDef="let element"> {{element.processedByName}} </td>
                       </ng-container>

                       <!-- Preview Column -->
                       <ng-container matColumnDef="action">
                           <th mat-header-cell *matHeaderCellDef> Actions </th>
                           <td mat-cell *matCellDef="let element">
                               <a target="_blank" href="{{element.imageUrl}}" class="preview-link">View Picture</a> <span class="vertical-divider"></span>
                       </ng-container>
                       
                       <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                       <tr mat-row *matRowDef="let row; columns: displayedColumns;" ></tr>
                   </table>
                   <div class="no-records-text" *ngIf="!dataSource.data || dataSource.data.length <= 0">No Records Found!</div>
                   <mat-paginator [pageSize]="pagesize" [pageSizeOptions]="[5, 10, 20]" [showFirstLastButtons]="true" [length]="totalSize"
                       [pageIndex]="currentPage" (page)="getCdFeederReportApplicantsPaginatorData($event)">
                   </mat-paginator>
                </div>
            </div>