
<div class="content-area">
    <div class="col-sm-10 offset-sm-1">
        <app-top-bar selectedTab="home"></app-top-bar>
        <app-header></app-header>
        
    </div>
    <div class="d-flex justify-content-around">
        <button class="btn btn-primary refresh-btn" style="margin-left: 86px; height: 42px; margin-top: 19px;" (click)="refreshClick()">
            <fa-icon [icon]="faSync" class="close-icon" [spin]="isRefreshing"></fa-icon>
        </button>
        <div class="col-sm-4 d-flex justify-content-end">
            <button mat-button class="btn content-btn btn-width w-75 oldNotification" 
            id="selectapp" type="button" (click)="notificationBtnClick('OLD')" [ngStyle]="{ 'background-color': oldNotificationBtn ?  '#ff0000' : '#a6a6a6;' }">Old Notification ({{oldNotificationApplicantCount}})</button>
          </div>  
    
          <div class="col-sm-4 d-flex justify-content-start">
            <button  mat-button  class="btn content-btn btn-width w-75 newNotification"  
            type="button" (click)="notificationBtnClick('NEW')" [ngStyle]="{ 'background-color': newNotificationBtn ?  '#ff0000' : '#a6a6a6;' }">New Notification ({{newNotificationApplicantCount}})</button>
          </div>
     </div> 
        <div class=" row ">
            <div class="col-sm-1"></div>
            <div class="col-sm-2 m-3">
           <button mat-button class="btn btn-primary" (click)="showActivityLog()">Activity Log</button>

            </div>
            <div class="col-sm-4" *ngIf="notificationType == 'OLD'">
              <div class="filter-layout" >
                <div  *ngFor ="let recommendation of recommendations;let i=index;" >
                    <button class="button" 
                    [matTooltip]="matTooltipContent"
                    matTooltipClass="allow-cr"
                    (click)="clickRecommendedBy(recommendation.id, recommendation)" 
                    [ngStyle]="{'background-color': recommendation.active ? '#F7583F' : 'grey'}">
                    {{recommendation.recommendationName}} ({{recommendation.totalCount}})</button>
                </div>
            </div> 

                    </div>
                    <div class="col-sm-4" *ngIf="notificationType != 'OLD'">
                      <div class="filter-layout" >
                        
                    </div> 
        
                            </div>
                        <div class="col-sm-2"></div>
                    <div class="col-sm-2">
                        <div class=" user-details">
                            <div class="value-text">
                                Welcome <br> 
                            {{user['firstName']}}!  
                            </div>
                            </div>
                      </div>

                    </div>
                
                
               
 <div class="col-sm-6 offset-sm-1"><a (click)="clickReportingLink()" class="preview-link">Click here to list the Applications meant for Reporting</a></div>
 <br>
 <div fxLayout="row" fxLayoutAlign="space-around">
     <div>
    <div class="searchAlign" fxLayout="row" fxLayoutAlign="space-around">
    <input class="form-control border-end-0 border rounded-pill" style="font-size: 14px !important;"type="text" [(ngModel)]="applicationNo" placeholder="Search" id="applicationNo">
    <span class="input-group"><br>&nbsp;
     <button mat-button class="btn btn-primary " (click)="searchApplicant()">Search</button> 
     <button mat-button *ngIf="applicationNo" matSuffix mat-icon-button aria-label="Clear" (click)="onClear()">
        <mat-icon>close</mat-icon>
      </button>
    </span>
    </div>
    
</div>
<div >
    <mat-form-field appearance="fill">
        <mat-label>Village</mat-label>
        <mat-select id="village" name="villageId" [(ngModel)]="villageId">
          <mat-option *ngFor="let village of villages" [value]="village.id" (click)="onVillageChange()">
            {{village.id}} {{village.name}}
          </mat-option>
        </mat-select>
      
      </mat-form-field>
      <div class="p1">
      Total: {{totalSize}}
    </div>
</div>
</div>


 <div class="tool-bar" >
    <div class="col-sm-9">
  
     <div class="filter-layout" >
        <div  *ngFor ="let dateFilter of dateFilters;let i=index;" >
            <button class="button" 
            [matTooltip]="matTooltipContent"
            matTooltipClass="allow-cr"
            (click)="clickDateFilter(dateFilter.tag)"
            [ngStyle]="{'background-color':dateFilter.color, 'border': dateFilter.active ? '2px solid black' : ''}" >
            {{dateFilter.label}} -({{dateFilter.count}})
            <br>
            {{dateFilter.subtitle}}</button>
        </div>
    </div> 
        <div class="col-md-8 offset-sm-1"   [hidden]="!show">
               <!-- data table -->
               <mat-card> 
            
            <table mat-table [dataSource]="dataSource">
                <ng-container matColumnDef="sn">
                    <th mat-header-cell *matHeaderCellDef> Serial No. </th>
                    <td mat-cell *matCellDef="let element ;let i =index;" class="column-container">{{i+1}}
                </ng-container>
                <ng-container matColumnDef="applicationNo">
                    <th mat-header-cell *matHeaderCellDef> Application No. </th>
                    <td mat-cell *matCellDef="let element" class="column-container">
                    <a (click)="onPreviewClick(element.id)" class="preview-link">{{element.applicationNo}}</a>
                </ng-container>
                <ng-container matColumnDef="applicantName">
                    <th mat-header-cell *matHeaderCellDef> Applicant Name </th>
                    <td mat-cell *matCellDef="let element" class="column-container">{{element.applicantName}}</td>
                </ng-container>
                <ng-container matColumnDef="recommendationName">
                    <th mat-header-cell *matHeaderCellDef> Recommended By </th>
                    <td mat-cell *matCellDef="let element" class="column-container">{{element.recommendationName ? element.recommendationName : ''}}</td>
                </ng-container>
                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;" ></tr>
            </table>
            <div class="no-records-text" *ngIf="!dataSource.data || dataSource.data.length <= 0">No Records Found!</div>
             <mat-paginator [pageSize]="pageSize" [pageSizeOptions]="[5, 10, 20]" [showFirstLastButtons]="true" [length]="totalSize"
                [pageIndex]="currentPage" (page)="getApplicantPaginatorData($event)">
            </mat-paginator> 
         </mat-card>
        </div>
        
       
   
</div>
</div> 
<app-footer class="col-md-10 offset-sm-3" ></app-footer>
</div> 