import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { RoleConfigService } from 'src/app/shared/config/role/role.config.service';
import { ApplicationService } from 'src/services/application.service';
import { AuthService } from 'src/services/auth.service';
import { UserService } from 'src/services/user-service/user.service';
// import { RoleConfigService } from '../shared/config/role/role.config.service';

@Component({
  selector: 'app-adjudication-user-login',
  templateUrl: './adjudication-user-login.component.html',
  styleUrls: ['./adjudication-user-login.component.scss']
})
export class AdjudicationUserLoginComponent implements OnInit {

  password : string;
  mobileNo : number;
  passwordFieldType = 'password';
  showOtpScreen = false;
  isLoading = false;
  resendEnabled = false;
  otpResendTime = 120;
  faEye = faEye;

  faEyeSlash = faEyeSlash;

  passwordVisibility = 'password';


  constructor(private applicationService: ApplicationService, private authService: AuthService,
    private _snackBar: MatSnackBar, private router: Router, private userService: UserService, 
    private roleConfig:RoleConfigService
    ) { }

  ngOnInit(): void {
  }

  login(){
     if(!this.isValidLogin()){
       return;
     }
      let payload = {};
      payload['mobile'] = this.mobileNo;
      payload['password'] = this.password;
      payload['loginType'] = this.roleConfig.ROLE_ADJUDICATION_USER;
      this.authService.login(payload, this.loginSuccess);
  }

  loginSuccess = (res: any): void => {
    if(res && res.success) {
      this.router.navigate(['/adjudication-pre-dashboard']);
    } else {
      this.openSnackbar('Invalid credentials', 'Dismiss');
    }
  }

  changePasswordVisibility($event){
     if($event.target.checked){
        return this.passwordFieldType = 'text';
     }  
     return this.passwordFieldType = 'password';
  }

  isValidLogin() : boolean{
      if(!this.mobileNo){
        this.openSnackbar('Please enter mobile number', 'Dismiss');
        return false;
     }
      if(!this.password){
        this.openSnackbar('Please enter password', 'Dismiss');
        return false;
     }
    return true;
  }

  openSnackbar(message: string, action: string){
    this._snackBar.open(message, action);
  }
  showPassword(){
    this.passwordVisibility = 'password'
  }

  hidePassword(){
    this.passwordVisibility = 'text';
  }

  ngOnDestroy() {
    
  }
}
