import { BrowserModule } from '@angular/platform-browser';
import { CUSTOM_ELEMENTS_SCHEMA, ModuleWithProviders, NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import {MatCardModule} from '@angular/material/card';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatSliderModule } from '@angular/material/slider';
import { TopBarComponent } from './top-bar/top-bar.component';
import { HeaderComponent } from './header/header.component';
import { ContentHeaderComponent } from './content-header/content-header.component';
import { MapComponent } from './map/map.component';
import { ProfileCardComponent } from './profile-card/profile-card.component';
import { NewsComponent } from './news/news.component';
import { LoginButtonComponent } from './login-button/login-button.component';
import { StaticsComponent } from './statics/statics.component';
import { FooterComponent } from './footer/footer.component';
import { AgmCoreModule } from '@agm/core';
import {MapsAPILoader} from '@agm/core';
import { ApplicationFormComponent } from './application-form/application-form.component';
import { HomeComponent } from './home/home.component';
import { PersonalDetailsComponent } from './application-form/personal-details/personal-details.component';
import { ApplicantAddressComponent } from './application-form/applicant-address/applicant-address.component';
import { ProgressDialogComponent } from './widgets/progress-dialog/progress-dialog.component';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import { PropertyDetailsComponent } from './application-form/property-details/property-details.component';
import { ChooseFormComponent } from './application-form/choose-form/choose-form.component';
import { SiteDetailsComponent } from './application-form/site-details/site-details.component';
import { BuildingDetailsComponent } from './application-form/building-details/building-details.component';
import { ResidentalHouseComponent } from './application-form/building-details/residental-house/residental-house.component';
import { CommercialBuildingComponent } from './application-form/building-details/commercial-building/commercial-building.component';
import { ApartmentComponent } from './application-form/building-details/apartment/apartment.component';
import { GatedCommunityComponent } from './application-form/building-details/gated-community/gated-community.component';
import { DetailsOfApprovalComponent } from './application-form/details-of-approval/details-of-approval.component';
import { BbdApprovedComponent } from './application-form/details-of-approval/bbd-approved/bbd-approved.component';
import { ApprovalFromPanchayatComponent } from './application-form/details-of-approval/approval-from-panchayat/approval-from-panchayat.component';
import { ApprovalOfBbmpComponent } from './application-form/details-of-approval/approval-of-bbmp/approval-of-bbmp.component';
import { ConversionOfOrderComponent } from './application-form/details-of-approval/conversion-of-order/conversion-of-order.component'
import {  MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { ProfileInfoModalComponent } from './profile-card/profile-info-modal/profile-info-modal.component';
import { ContactComponent } from './contact/contact.component';
import { MapImageComponent } from './map-image/map-image.component';
import { FormsModule } from '@angular/forms';
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { ApplicantListComponent } from './applicant-list/applicant-list.component';
import { MatTableModule } from '@angular/material/table';
import { ApplicantFormComponent } from './applicant-form/applicant-form.component';
import { UploadEnclosureComponent } from './upload-enclosure/upload-enclosure.component';
import { ApplicantEnclosureListComponent } from './applicant-enclosure-list/applicant-enclosure-list.component';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatSnackBarModule, MAT_SNACK_BAR_DEFAULT_OPTIONS} from '@angular/material/snack-bar';
import {MatSelectModule} from '@angular/material/select';
import {MatFormFieldModule} from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { ConfirmationModalComponent } from './confirmation-modal/confirmation-modal.component';
import { MatButtonModule } from '@angular/material/button';
import { FilterOptionComponent } from './filter-option/filter-option.component';
import { GoogleChartsModule } from 'angular-google-charts';
import { AdminLoginComponent } from './admin-login/admin-login.component';
import { ApplicationLoginTypeComponent } from './application-login-type/application-login-type.component';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import { ReactiveFormsModule } from '@angular/forms';
import { AcknowledgementComponent } from './application-form/acknowledgement/acknowledgement.component';
import {MatCheckboxModule} from '@angular/material/checkbox';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { SupremeCourtJudgementComponent } from './supreme-court-judgement/supreme-court-judgement.component';
import { FlexLayoutModule } from '@angular/flex-layout';
import { CommencementCertificateComponent } from './application-form/miscellaneous/commencement-certificate/commencement-certificate.component';
import { CompletionCertificateComponent } from './application-form/miscellaneous/completion-certificate/completion-certificate.component';
import { OccupancyCertificateComponent } from './application-form/miscellaneous/occupancy-certificate/occupancy-certificate.component';
import { ElectricityConnectionComponent } from './application-form/miscellaneous/electricity-connection/electricity-connection.component';
import { WaterConnectionComponent } from './application-form/miscellaneous/water-connection/water-connection.component';
import { DetailsOfLoanAvailedComponent } from './application-form/miscellaneous/details-of-loan-availed/details-of-loan-availed.component';
import { CopyLastPaidReceiptComponent } from './application-form/miscellaneous/copy-last-paid-receipt/copy-last-paid-receipt.component';
import { CourtCasePendingComponent } from './application-form/miscellaneous/court-case-pending/court-case-pending.component';
import { ConstructWithoutApprovalComponent } from './application-form/details-of-approval/construct-without-approval/construct-without-approval.component';
import { PreviewDialogComponent } from './preview-dialog/preview-dialog.component';
import { WebcamModule } from 'ngx-webcam';
import { OtherPropertyDetailsComponent } from './application-form/building-details/other-property-details/other-property-details.component';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import { HelpDeskLoginComponent } from './help-desk-login/help-desk-login.component';
import { OnlineApplicationLoginComponent } from './online-application-login/online-application-login.component';
import { TokenInterceptorService } from 'src/interceptors/token-interceptor.service';
import { CommunityLoginComponent } from './community-login/community-login.component';
import { ApplicationStaticsComponent } from './application-statics/application-statics.component';
import { ApplicationBarChartComponent } from './application-bar-chart/application-bar-chart.component';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatNativeDateModule, MAT_DATE_LOCALE} from '@angular/material/core';
import { HighchartsChartModule } from 'highcharts-angular';
import { InfoDialogComponent } from './application-form/info-dialog/info-dialog.component';
import { AdminComponent } from './admin/admin.component';
import { HashLocationStrategy, LocationStrategy } from '@angular/common';
import { AuditHelpdeskComponent } from './audit-helpdesk/audit-helpdesk.component';
import { AuditHelpdeskLoginComponent } from './audit-helpdesk-login/audit-helpdesk-login.component';
import { AuditHelpdeskChartComponent } from './audit-helpdesk-chart/audit-helpdesk-chart.component';
import { PreviewEnclosureComponent } from './preview-enclosure/preview-enclosure.component';

import { HeadlineNewsComponent } from './headline-news/headline-news.component';
import { NgMarqueeModule } from 'ng-marquee';
import { CoreModule } from './core/core.module';
import { SharedModule } from './shared/shared.module';
import { ScrutinyUserModule } from './scrutiny-user/scrutiny-user.module';
import { ScrutinyAdminModule } from './scrutiny-admin/scrutiny-admin.module';
import { SideMenuComponent } from './application-form/side-menu/side-menu.component';
import { FieldVisitUserModule } from './field-visit-user/field-visit-user.module';
import { AdjudicationUserModule } from './adjudication-user/adjudication-user.module';
import { CommiteeReportingComponent } from './community-login/commitee-reporting/commitee-reporting.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MaterialModule } from './material.module';
import { CommitteReportGeneratedComponent } from './community-login/committe-report-generated/committe-report-generated.component';
import { CommitteViewReportComponent } from './community-login/committe-view-report/committe-view-report.component';
import { CommitteReadyForReportingComponent } from './community-login/committe-ready-for-reporting/committe-ready-for-reporting.component';
import { MessageDialogComponent } from './shared/message-dialog/message-dialog/message-dialog.component';
import { AppTransferBdaComponent } from './app-transfer-bda/app-transfer-bda.component';
import { GenericLoginComponent } from './generic-login/generic-login.component';
import { ApplicantReportDashboardComponent } from './applicant-report/applicant-report-dashboard/applicant-report-dashboard.component';
import { ApplicantReportDatatableComponent } from './applicant-report/applicant-report-datatable/applicant-report-datatable.component';
import { ActorLoginComponent } from './actor_login/actor_login.component';
import { CommiteeCdinfoComponent } from './community-login/commitee-cdinfo/commitee-cdinfo.component'
import { CdinfoReportApplicantComponent } from './community-login/cdinfo-report-applicant/cdinfo-report-applicant.component';
import { CommunityViewApplicantsComponent } from './community-login/community-view-applicants/community-view-applicants.component';
import { PiUserModule } from './pi-user/pi-user.module';
import { MatTableExporterModule } from 'mat-table-exporter';
import { ReadyForReportingDialogboxComponent } from './ready-for-reporting-dialogbox/ready-for-reporting-dialogbox.component';
import { StatusOptionTilesComponent } from './community-login/status-option-tiles/status-option-tiles.component';
import { CommitteActiveStatusComponent } from './community-login/committe-active-status/committe-active-status.component';
import { GroupApplicantsDialogboxComponent } from './group-applicants-dialogbox/group-applicants-dialogbox.component';
import { CommiteeRcPrepComponent } from './community-login/commitee-rc-prep/commitee-rc-prep.component';
import { CommiteeRcPrepWorkbenchComponent } from './community-login/commitee-rc-prep-workbench/commitee-rc-prep-workbench.component';
import { DecisionMakingFormComponent } from './decision-making-form/decision-making-form.component';
import { DecisionMakingCommentDialogBoxComponent } from './decision-making-form/decision-making-comment-dialog-box/decision-making-comment-dialog-box.component';
import { DecisionMakingWorkbenchComponent } from './decision-making-form/decision-making-workbench/decision-making-workbench.component';
import { CommitteCdDoneDetailsComponent } from './community-login/committe-cd-done-details/committe-cd-done-details.component';
import { AwardScrutinyUserDashboardComponent } from './award-scrutiny-user/award-scrutiny-user-dashboard/award-scrutiny-user-dashboard.component';
import { AwardMasterDashboardComponent } from './award-scrutiny-user/award-master-dashboard/award-master-dashboard.component';
import { AwardScrutinyUserActivityLogComponent } from './award-scrutiny-user/award-scrutiny-user-activity-log/award-scrutiny-user-activity-log.component';
import { AwardScrutinyUserWorkbenchComponent } from './award-scrutiny-user/award-scrutiny-user-workbench/award-scrutiny-user-workbench.component';
import { BasicInfoComponent } from './award-scrutiny-user/basic-info/basic-info.component';
import { PreviewComponent } from './award-scrutiny-user/preview/preview.component';
import { AwardDetailsComponent } from './award-scrutiny-user/award-details/award-details.component';
import { StructureDetailsComponent } from './award-scrutiny-user/structure-details/structure-details.component';
import { PreviewDialogsComponent } from './award-scrutiny-user/preview-dialogs/preview-dialogs.component';
import { AwardEnclosureComponent } from './award-scrutiny-user/award-enclosure/award-enclosure.component';
import { AwardMasterActivityLogViewComponent } from './award-scrutiny-user/award-master-activity-log-view/award-master-activity-log-view.component';
import { RcDisbursementLoginComponent } from './rc-disbursement/rc-disbursement-login/rc-disbursement-login.component';
import { RcDisbursementDashboardComponent } from './rc-disbursement/rc-disbursement-dashboard/rc-disbursement-dashboard.component';
import { AwardActiveStatusComponent } from './award-scrutiny-user/award-active-status/award-active-status.component';
import { RcDisbursementActivityLogComponent } from './rc-disbursement/rc-disbursement-activity-log/rc-disbursement-activity-log.component';
import { AwardAnalysisComponent } from './award-scrutiny-user/award-analysis/award-analysis.component';
import { RcRegisterActivityLogComponent } from './rc-register/rc-register-activity-log/rc-register-activity-log.component';
import { RcRegisterDashboardComponent } from './rc-register/rc-register-dashboard/rc-register-dashboard.component';
import { RcRegisterDataEnrichmentComponent } from './rc-register/rc-register-data-enrichment/rc-register-data-enrichment.component';
import { RcRegisterBatchProcessingComponent } from './rc-register/rc-register-batch-processing/rc-register-batch-processing.component';
import { RcRegisterDataEnrichmentFormComponent } from './rc-register/rc-register-data-enrichment-form/rc-register-data-enrichment-form.component';
import { AuthGuardService } from 'src/services/auth-guard.service';
import { AlertDialogComponent } from './shared/alert/alert-dialog/alert-dialog.component';
import { AlertDialogService } from './shared/alert/alert-dialog.service';
import { RcRegisterEditBatchProcessingFormComponent } from './rc-register/rc-register-edit-batch-processing-form/rc-register-edit-batch-processing-form.component';
import { RcRegisterDataEnrichemntActivityLogComponent } from './rc-register/rc-register-data-enrichemnt-activity-log/rc-register-data-enrichemnt-activity-log.component';
import { RcRegisterDefaultDatatableComponent } from './rc-register/rc-register-default-datatable/rc-register-default-datatable.component';
import { RcCdDataValidationActivityLogComponent } from './rc-register/rc-cd-data-validation/rc-cd-data-validation-activity-log/rc-cd-data-validation-activity-log.component';
import { RcCdDataValidationDashboardComponent } from './rc-register/rc-cd-data-validation/rc-cd-data-validation-dashboard/rc-cd-data-validation-dashboard.component';
import { RcSdDataValidationActivityLogComponent } from './rc-register/rc-sd-data-validation/rc-sd-data-validation-activity-log/rc-sd-data-validation-activity-log.component';
import { RcSdDataValidationDashboardComponent } from './rc-register/rc-sd-data-validation/rc-sd-data-validation-dashboard/rc-sd-data-validation-dashboard.component';
import { RcSdDataValidationWorkbenchComponent } from './rc-register/rc-sd-data-validation/rc-sd-data-validation-workbench/rc-sd-data-validation-workbench.component';
import { RcCdDataValidationWorkbenchComponent } from './rc-register/rc-cd-data-validation/rc-cd-data-validation-workbench/rc-cd-data-validation-workbench.component';
import { RcRegisterWorkbenchComponent } from './rc-register/rc-register-workbench/rc-register-workbench.component';
import { ApplicantWorkbenchViewComponent } from './applicant-workbench-view/applicant-workbench-view.component';
import { RcRegisterFormViewComponent } from './rc-register/rc-register-form-view/rc-register-form-view.component';
import { RcDisbursementWorkbenchComponent } from './rc-disbursement/rc-disbursement-workbench/rc-disbursement-workbench.component';
import { CommitteeCdDataComponent } from './community-login/committee-cd-data/committee-cd-data.component';
import { CommitteOutOfBoundComponent } from './community-login/committe-out-of-bound/committe-out-of-bound.component';
import { CommitteReportedOutOfBoundsComponent } from './community-login/committe-reported-out-of-bounds/committe-reported-out-of-bounds.component';
import { RcDisbursementActivityLogViewComponent } from './rc-disbursement/rc-disbursement-activity-log-view/rc-disbursement-activity-log-view.component';
import { LayoutFormationDashboardComponent } from './layout-formation/widget/layout-formation-dashboard/layout-formation-dashboard.component';
import { TileComponent } from './common/tile/tile.component';
import { LayoutSectorDashboardComponent } from './layout-formation/widget/layout-sector-dashboard/layout-sector-dashboard.component';
import { SectorTileComponent } from './layout-formation/components/sector-tile/sector-tile.component';
import { SectorDocumentTileComponent } from './layout-formation/components/sector-document-tile/sector-document-tile.component';
import { LayoutMapComponent } from './layout-formation/components/layout-map/layout-map.component';
import { LayoutDocumentListComponent } from './layout-formation/widget/layout-document-list/layout-document-list.component';
import { LayoutSectorPackageDashboardComponent } from './layout-formation/widget/layout-sector-package-dashboard/layout-sector-package-dashboard.component';
import { DpaGenerationWorkbenchComponent } from './dpa-generation/dpa-generation-workbench/dpa-generation-workbench.component';
import { SectorContainerComponent } from './dpa-generation/sector-container/sector-container.component';
import { SectorVillageDialogComponent } from './dpa-generation/sector-village-dialog/sector-village-dialog.component';
import { DpaActivityLogComponent } from './dpa-generation/dpa-activity-log/dpa-activity-log.component';
import { CommitteeDpaActivityLogComponent } from './community-login/committee-dpa-activity-log/committee-dpa-activity-log.component';
import { DailyProgressMapViewActivityLogComponent } from './secon/daily-progress-map-view-activity-log/daily-progress-map-view-activity-log.component';
import { DpaActivityLogCommon } from './common/dpa-activity-log-common/dpa-activity-log-common';
import { SeconDpActivitylogComponent } from './secon/secon-dp-activitylog/secon-dp-activitylog.component';
import { DailyProgressActivitylogDatatableComponent } from './common/daily-progress-activitylog-datatable/daily-progress-activitylog-datatable.component';
import { SeconPdfUploadComponent } from './secon/secon-pdf-upload/secon-pdf-upload.component';
import { CommitteProgressTackledComponent } from './community-login/committe-progress-tackled/committe-progress-tackled.component';
import { DpaFunctionalHeadComponentComponent } from './dpa-generation/dpa-functional-head-component/dpa-functional-head-component.component';
import { DpaCivilWorkComponent } from './dpa-generation/dpa-work-item/dpa-work-item.component';
import { DpaWorkItemActivityLogComponent } from './dpa-generation/dpa-work-item-activity-log/dpa-work-item-activity-log.component';
import { DpaWorkItemWorkbenchComponent } from './dpa-generation/dpa-work-item-workbench/dpa-work-item-workbench.component';
import { SectorWorkItemContainerComponent } from './dpa-generation/sector-work-item-container/sector-work-item-container.component';
import { DpaWorkItemDialogComponent } from './dpa-generation/dpa-work-item-dialog/dpa-work-item-dialog.component';
import { DpaWorkItemDatatableComponent } from './dpa-generation/dpa-work-item-datatable/dpa-work-item-datatable.component';
import { WorkProgressActivityLogComponent } from './secon/work-progress-activity-log/work-progress-activity-log.component';
import { DpaWorkItemPdfActivityLogComponent } from './dpa-generation/dpa-work-item-pdf-activity-log/dpa-work-item-pdf-activity-log.component';
import { EditDpaWorkItemComponent } from './dpa-generation/edit-dpa-work-item/edit-dpa-work-item.component';
import { EeObservationWorkbenchComponent } from './dpa-generation/ee-observation-workbench/ee-observation-workbench.component';
import { DpaEeObservationDashboardComponent } from './dpa-generation/dpa-ee-observation-dashboard/dpa-ee-observation-dashboard.component';
import { DpaEeObservationActivityLogComponent } from './dpa-generation/dpa-ee-observation-activity-log/dpa-ee-observation-activity-log.component';
import { DpaEeObservationPreviewDialogboxComponent } from './dpa-generation/dpa-ee-observation-preview-dialogbox/dpa-ee-observation-preview-dialogbox.component';
import { DpaEeObservationActivityLogViewComponent } from './dpa-generation/dpa-ee-observation-activity-log-view/dpa-ee-observation-activity-log-view.component';
import { LFChartComponent } from './layout-formation/charts/component/lf-chart/lf-chart.component';
import { SectorChartComponent } from './layout-formation/charts/component/sector-chart/sector-chart.component';
import { WorkTypeChartComponent } from './layout-formation/charts/component/work-type-chart/work-type-chart.component';
import { LandHandOverStatusDashboardComponent } from './layout-formation/land-hand-over-status-dashboard/land-hand-over-status-dashboard.component';
import { LandHandOverStatusActivityLogComponent } from './layout-formation/land-hand-over-status-activity-log/land-hand-over-status-activity-log.component';
import { LandHandOverSurveyActivityLogComponent } from './layout-formation/land-hand-over-survey-activity-log/land-hand-over-survey-activity-log.component';
import { HotlineDashboardComponent } from './hotline-user/hotline-dashboard/hotline-dashboard.component';
import { DmsPreviewDialogComponent } from './dms-login/dms-preview-dialog/dms-preview-dialog.component';
import { DmsWorkbenchComponent } from './dms-login/dms-workbench/dms-workbench.component';
import { DmsActivityLogComponent } from './dms-login/dms-activity-log/dms-activity-log.component';
import { EditDmsWorkbenchComponent } from './dms-login/edit-dms-workbench/edit-dms-workbench.component';
import { CommiteeDmsDashboardComponent } from './community-login/commitee-dms-dashboard/commitee-dms-dashboard.component';
import { DmsConfirmationModalComponent } from './dms-login/dms-confirmation-modal/dms-confirmation-modal.component';

@NgModule({
  declarations: [		
    AppComponent,
    AppTransferBdaComponent,
    ContentHeaderComponent,
    MapComponent,
    ProfileCardComponent,
    NewsComponent,
    LoginButtonComponent,
    StaticsComponent,
    ApplicationFormComponent,
    HomeComponent,
    PersonalDetailsComponent,
    ApplicantAddressComponent,
    ProgressDialogComponent,
    PropertyDetailsComponent,
    ChooseFormComponent,
    SiteDetailsComponent,
    BuildingDetailsComponent,
    ResidentalHouseComponent,
    CommercialBuildingComponent,
    ApartmentComponent,
    GatedCommunityComponent,
    DetailsOfApprovalComponent,
    BbdApprovedComponent,
    ApprovalFromPanchayatComponent,
    ApprovalOfBbmpComponent,
    ConversionOfOrderComponent,
    ProfileInfoModalComponent,
    ContactComponent,
    MapImageComponent,
    ApplicantListComponent,
    ApplicantFormComponent,
    UploadEnclosureComponent,
    ApplicantEnclosureListComponent,
    ConfirmationModalComponent,
    FilterOptionComponent,
    AdminLoginComponent,
    ApplicationLoginTypeComponent,
    AcknowledgementComponent,
    SupremeCourtJudgementComponent,
    CommencementCertificateComponent,
    CompletionCertificateComponent,
    OccupancyCertificateComponent,
    ElectricityConnectionComponent,
    WaterConnectionComponent,
    DetailsOfLoanAvailedComponent,
    CopyLastPaidReceiptComponent,
    CourtCasePendingComponent,
    ConstructWithoutApprovalComponent,
    PreviewDialogComponent,
    OtherPropertyDetailsComponent,
    HelpDeskLoginComponent,
    OnlineApplicationLoginComponent,
    CommunityLoginComponent,
    ApplicationStaticsComponent,
    ApplicationBarChartComponent,
    InfoDialogComponent,
    AdminComponent,
    AuditHelpdeskComponent,
    AuditHelpdeskLoginComponent,
    AuditHelpdeskChartComponent,
    PreviewEnclosureComponent,
    HeadlineNewsComponent,
    SideMenuComponent,
    CommiteeReportingComponent,
    CommitteReportGeneratedComponent,
    CommitteViewReportComponent,
    CommitteReadyForReportingComponent,
    MessageDialogComponent,
    GenericLoginComponent,
    ApplicantReportDashboardComponent,
    ApplicantReportDatatableComponent,
    ActorLoginComponent,
    CommiteeCdinfoComponent,
    CommunityViewApplicantsComponent,
    CdinfoReportApplicantComponent,
    ReadyForReportingDialogboxComponent,
    StatusOptionTilesComponent,
    CommitteActiveStatusComponent,
    GroupApplicantsDialogboxComponent,
    CommiteeRcPrepComponent,
    CommiteeRcPrepWorkbenchComponent,
    DecisionMakingFormComponent,
    DecisionMakingCommentDialogBoxComponent,
    DecisionMakingWorkbenchComponent,
    CommitteCdDoneDetailsComponent,
    AwardScrutinyUserDashboardComponent,
    AwardMasterDashboardComponent,
    AwardScrutinyUserActivityLogComponent,
    AwardScrutinyUserWorkbenchComponent,
    BasicInfoComponent,
    PreviewComponent,
    AwardDetailsComponent,
    StructureDetailsComponent,
    PreviewDialogsComponent,
    AwardEnclosureComponent,
    AwardMasterActivityLogViewComponent,
    RcDisbursementLoginComponent,
    RcDisbursementDashboardComponent,
    AwardActiveStatusComponent,
    RcDisbursementActivityLogComponent,
    AwardAnalysisComponent,
    RcRegisterActivityLogComponent,
    RcRegisterDashboardComponent,
    RcRegisterDataEnrichmentComponent,
    RcRegisterBatchProcessingComponent,
    RcRegisterDataEnrichmentFormComponent, 
    AlertDialogComponent,
    RcRegisterEditBatchProcessingFormComponent,
    RcRegisterDataEnrichemntActivityLogComponent,
    RcRegisterDefaultDatatableComponent,
    RcSdDataValidationDashboardComponent,
    RcSdDataValidationActivityLogComponent,
    RcCdDataValidationDashboardComponent,
    RcCdDataValidationActivityLogComponent,
    RcSdDataValidationWorkbenchComponent,
    RcCdDataValidationWorkbenchComponent,
    RcRegisterWorkbenchComponent,
    ApplicantWorkbenchViewComponent,
    RcRegisterFormViewComponent,
    RcDisbursementWorkbenchComponent,
    CommitteeCdDataComponent,
    CommitteOutOfBoundComponent,
    CommitteReportedOutOfBoundsComponent,
    RcDisbursementActivityLogViewComponent,
    LayoutFormationDashboardComponent,
    TileComponent,
    LayoutSectorDashboardComponent,
    SectorTileComponent,
    SectorDocumentTileComponent,
    LayoutMapComponent,
    LayoutDocumentListComponent,
    LayoutSectorPackageDashboardComponent,
    DpaGenerationWorkbenchComponent,
    DpaFunctionalHeadComponentComponent,
    DpaWorkItemActivityLogComponent,
    DpaCivilWorkComponent,
    SectorContainerComponent,
    SectorVillageDialogComponent,
    DpaActivityLogComponent,
    CommitteeDpaActivityLogComponent,
    DailyProgressMapViewActivityLogComponent,
    DpaActivityLogCommon,
    SeconDpActivitylogComponent,
    DailyProgressActivitylogDatatableComponent,
    SeconPdfUploadComponent,
    CommitteProgressTackledComponent,
    DpaWorkItemWorkbenchComponent,
    SectorWorkItemContainerComponent,
    DpaWorkItemDialogComponent,
    DpaWorkItemDatatableComponent,
    WorkProgressActivityLogComponent,
    DpaWorkItemPdfActivityLogComponent,
    EditDpaWorkItemComponent,
    EeObservationWorkbenchComponent,
    DpaEeObservationDashboardComponent,
    DpaEeObservationActivityLogComponent,
    DpaEeObservationPreviewDialogboxComponent,
    DpaEeObservationActivityLogViewComponent,
    LFChartComponent,
    SectorChartComponent,
    WorkTypeChartComponent,
    LandHandOverStatusDashboardComponent,
    LandHandOverStatusActivityLogComponent,
    LandHandOverSurveyActivityLogComponent,
    HotlineDashboardComponent,
    DmsPreviewDialogComponent,
    DmsWorkbenchComponent,
    DmsActivityLogComponent,
    EditDmsWorkbenchComponent,
    CommiteeDmsDashboardComponent,
    DmsConfirmationModalComponent
   ],
  imports: [
    BrowserModule,
    ScrutinyUserModule,
    FieldVisitUserModule,
    ScrutinyAdminModule,
    AdjudicationUserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MatSliderModule,
    MatProgressBarModule,
    MatCardModule,
    MatDialogModule,
    FormsModule,
    HttpClientModule,
    MatTableModule,
    MatProgressSpinnerModule,
    MatSnackBarModule,
    MatSelectModule,
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
    GoogleChartsModule,
    MatAutocompleteModule,
    ReactiveFormsModule,
    MatCheckboxModule,
    FontAwesomeModule,
    FlexLayoutModule,
    WebcamModule,
    MatDatepickerModule,
    MatNativeDateModule,
    HighchartsChartModule,
    NgMarqueeModule,
    CoreModule,
    SharedModule,
    MatTooltipModule,
    MaterialModule,
    MatTableExporterModule,
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyCgcTmaffzfOLa9FPlpVYryFIww5jh8aZ8',
      libraries: ['places', 'geometry', 'drawing']
    }),
    TranslateModule.forRoot({
      loader: {
          provide: TranslateLoader,
          useFactory: HttpLoaderFactory,
          deps: [HttpClient]
      }
  }),
    PiUserModule
  ],
  exports: [ CommiteeReportingComponent, CommitteReportGeneratedComponent],
  entryComponents: [ConfirmationModalComponent, DmsConfirmationModalComponent, ApplicantEnclosureListComponent, PreviewDialogComponent,SectorVillageDialogComponent,
      MessageDialogComponent, DpaWorkItemDialogComponent,AlertDialogComponent, DmsPreviewDialogComponent],
  providers: [AuthGuardService, AlertDialogService,
      {provide: MAT_SNACK_BAR_DEFAULT_OPTIONS, useValue: {duration: 2500}},
      {
        provide: HTTP_INTERCEPTORS,
        useClass: TokenInterceptorService,
        multi: true
      },{ provide: MAT_DATE_LOCALE, useValue: 'en-IN' },
      {provide: LocationStrategy, useClass: HashLocationStrategy}
      ],
      schemas: [
        CUSTOM_ELEMENTS_SCHEMA
      ],
  bootstrap: [AppComponent]
})
export class AppModule {
  }

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}