import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-court-case-pending',
  templateUrl: './court-case-pending.component.html',
  styleUrls: ['./court-case-pending.component.scss']
})
export class CourtCasePendingComponent implements OnInit {

  @Input() data : any;
  @Input() selectedSubForm: number;
  @Input() selectedPage: number;
  @Input() isEditable : any;

  public courtName : string;

  public caseNo : string;

  public presentStatus : string;

  public isCasePending : any = null;

  constructor() { }

  ngOnInit(): void {
  }

  reset(){
    this.courtName = null;
    this.caseNo = null;
    this.presentStatus = null;
  }

  getPayload(){
    let payload = {};
      payload['courtName'] = this.courtName;
      payload['caseNumber'] = this.caseNo;
      payload['caseStatus'] = this.presentStatus;
      payload['isCasePending'] = this.isCasePending;
    return payload;
  }

  ngOnChanges(data): void{
    if(!this.data){
      return;
    }
    this.courtName = this.data.courtName;
    this.caseNo = this.data.caseNumber;
    this.presentStatus = this.data.caseStatus;
    if(this.data.isCasePending){
      this.isCasePending = this.data.isCasePending;
    }
 
   }

}
