import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { faSync } from '@fortawesome/free-solid-svg-icons';
import { ApplicationService } from 'src/services/application.service';
import { StrorageService } from 'src/services/strorage.service';

@Component({
  selector: 'app-dpa-work-item-workbench',
  templateUrl: './dpa-work-item-workbench.component.html',
  styleUrls: ['./dpa-work-item-workbench.component.scss']
})
export class DpaWorkItemWorkbenchComponent implements OnInit {

  constructor(private storageService:StrorageService, private applicationService: ApplicationService,
    private router:Router) { 
    //this.applicantId = this.route.snapshot.queryParams['applicantId'];
  }

  userData: any;

  firstName: string;

  startDate: any;

  toDate: any;

  startDateFormat: any;

  endDateFormat: any;

  public title:string = '';

  maxDate = new Date();

  public faSync = faSync;

  public isRefreshing = false;

  ngOnInit() {
    window.scroll(0,0);
    this.userData = this.storageService.getUser();
    if(this.userData){
      this.firstName = this.userData['firstName'];
    }
    console.log(this.startDate);
  }
  templateData: any[];
  
  refreshClick(){
    let currentComponent = this;
    this.isRefreshing = true;
    setTimeout(()=>{
      currentComponent.isRefreshing = false;
    }, 2000)
    window.location.reload();
  }

  onStartDateChangeEvent(event: any){
    let startDate = event.value;
    var datePipe = new DatePipe("en-US");
    startDate = datePipe.transform(startDate, 'dd/MM/yyyy');
    this.startDateFormat = startDate;
  }

  public showActivityLog = () => {
    this.router.navigate(['/dpa-work-item-pdf-activity-log']);
  }
  clickBack(){
    window.history.back();
  }
  onEndDateChangeEvent(event: any){
    let endDate = event.value;
    var datePipe = new DatePipe("en-US");
    endDate = datePipe.transform(endDate, 'dd/MM/yyyy');
    this.endDateFormat = endDate;
    this.validateDate(this.startDateFormat, this.endDateFormat);
  }
  validateDate(startDate: any, endDate: any) {
    if(startDate > endDate) {
      throw new Error(" end date must greater than start date ");
    }
  }

}