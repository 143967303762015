import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-sector-tile',
  templateUrl: './sector-tile.component.html',
  styleUrls: ['./sector-tile.component.scss']
})
export class SectorTileComponent implements OnInit {

  public sectors:any[] = [
    {
      name: 'Sector-1', 
      color: '#FFC000', 
      sectorCode: "sector_1", 
      companyName:'Ms.NCC Limited',
      location1: 'Avalahalli',
      location2: 'Ramagondanahalli',
      location3: 'Harohalli'
    },
    {
      name: 'Sector-2', 
      color: '#8FAADC', 
      sectorCode: "sector_2",
      companyName:'Ms.Amrutha Constructions',
      location1: 'Kempanahalli',
      location2: 'Veerasagara',
      location3: 'Jarakbandekaval'
    },
    {
      name: 'Sector-3', 
      color: '#A9D18E', 
      sectorCode: "sector_3",
      companyName:'Ms.Start Infratech',
      location1: 'Veerasagara',
      location2: 'Shyamarajapura',
      location3: 'Doddabettahalli'
    },
    {
      name: 'Sector-4', 
      color: '#F4B183', 
      sectorCode: "sector_4",
      companyName:'Ms.RNS Infrastructure',
      location1: 'Byalakere',
      location2: 'Mediagrahara',
      location3: 'Vaderahalli',
      location4: 'Shyamarajapura'
    },
    {
      name: 'Sector-5', 
      color: '#A6A6A6', 
      sectorCode: "sector_5",
      companyName:'Ms.BSCPL Infrastructure',
      location1: 'Byalakere'
    },
    {
      name: 'Sector-6', 
      color: '#BDD7EE', 
      sectorCode: "sector_6",
      companyName: 'Ms.RMN Infrastructure',
      location1: 'Byalakere',
      location2: 'Guniagrahara',
      location3: 'Mediagrahara',
      location4: 'Vaderahalli'
    },
    {
      name: 'Sector-7', 
      color: '#7AB850', 
      sectorCode: "sector_7",
      companyName: 'Ms.SRC Infrastructure',
      location1: 'Byalakere',
      location2: 'Guniagrahara',
      location3: 'Mediagrahara'
    },
    {
      name: 'Sector-8', 
      color: '#DA92DC', 
      sectorCode: "sector_8",
      companyName: 'Ms.Ramalingam Construction',
      location1: 'Somashettihalli',
      location2: 'Lakshmipura',
      location3: 'Guniagrahara'
    },
    {
      name: 'Sector-9', 
      color: '#FFC000', 
      sectorCode: "sector_9",
      companyName: 'Ms.BSR Infratech',
      location1: 'Kalathammanhalli',
      location2: 'Guniagrahara',
      location3: 'Kempapura'
    }
  ];

  constructor(private router: Router) { }

  ngOnInit(): void {
  }

  onSectorTileClicked = (item: any) => {
    this.router.navigate(['layout-sector-dashboard'], 
    { queryParams: { selectedSector: item.name, sectorCode:item.sectorCode, sectorName : item.name } });
  }
}
