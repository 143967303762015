
import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { PageEvent } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { ApplicationService } from 'src/services/application.service';
import { StrorageService } from 'src/services/strorage.service';
import { MAT_DIALOG_DATA,MatDialogRef,MatDialog } from '@angular/material/dialog';


@Component({
  selector: 'app-pi-user-activity-log',
  templateUrl: './pi-user-activity-log.component.html',
  styleUrls: ['./pi-user-activity-log.component.scss']
})
export class PiUserActivityLogComponent implements OnInit {
  public selectedStatusOption: any;
  public apiURL: string;
  public statusCode: any;
  public pageSize =5;
  public currentPage = 0;
  public totalSize: number= 0;
  public totalCount: number= 0;
  statusList: any = [];
  selectedStatus:any;
  selectedStatusOptionCode: any;
  totalApplicants: any;
  status_List:any=[];
  statusOptions: any = [];

  public displayedColumns: string[] = ['sn','applicationNo', 'applicantName', 'status', 'subStatus','notificationType'];
  
  public dataSource = new MatTableDataSource<any>();
  user: any;
  village: any;
  villageId: any;
  applicationNo = null;
  statusOption: any;
  statusOptionId:any;
  public totalApplicantCount: any = 0;

  constructor(private applicationService: ApplicationService,private cdr:ChangeDetectorRef,private dialogRef: MatDialogRef<PiUserActivityLogComponent>, 
    private router: Router,private storageService:StrorageService) { }

   ngOnInit(): void {
    this.user=this.storageService.getUser();
    this.getPiUserStatus();
  }

  getStatusOptions(): void {
    this.statusOptions = [];
    if(!this.selectedStatus) {
      return;
    }
    if(this.selectedStatus.statusCode != 'out_of_bounds') {
      if(this.selectedStatus.statusCode != 'ready_for_reporting') {
      this.fetchApplicantList();
      return;
    }
  }
    this.applicationService.getStatusOption(this.selectedStatus.statusCode).subscribe(res =>{
      let statusOptionList = [{"id":"0", "name":"All"}];
      for(let i =0;i < res.length;i++) {
        statusOptionList.push(res[i]);
      }
      this.statusOptions = statusOptionList;
      this.selectedStatusOption = this.statusOptions[0];
      this.onStatusOptionSelected();
  })
}
  ngAfterViewInit() {
    this.fetchTotalApplicantByStatus();
    this.fetchApplicantList();
  }

  public getApplicantPaginatorData(event: PageEvent) {
    this.currentPage = event.pageIndex;
    this.pageSize = event.pageSize;
    this.fetchApplicantList();
  }
  
  public onOkClicked = () => {
    let data = {};
    data['statusOptionId'] = this.selectedStatusOption;
    data['confirmed'] = true;
    this.dialogRef.close(data);
  }

  onStatusSelected() {
    this.selectedStatusOption = undefined;
    this.getStatusOptions();
    this.fetchTotalApplicantByStatus();
  }

  onStatusOptionSelected() {
    this.fetchApplicantList();
    this.fetchTotalApplicantByStatusOption();
  }

  public fetchTotalApplicantByStatus(): void {
    let statusCode = undefined;
    if(this.selectedStatus && this.selectedStatus.statusCode != '') {
      statusCode = this.selectedStatus.statusCode;
    }
    this.applicationService.fetchPiUserActivityLogCount(statusCode, this.applicationNo).subscribe(res =>{
      this.totalApplicantCount = res.data;
    })
  }

  public fetchTotalApplicantByStatusOption(): void {
    if(!this.selectedStatusOption) {
      return;
    }
    let selectedStatusOptionId = undefined;
    if(this.selectedStatusOption.id > 0) {
      selectedStatusOptionId = this.selectedStatusOption.id;
    }
    this.applicationService.fetchPiUserActivityLogCount(this.selectedStatus.statusCode,this.applicationNo,selectedStatusOptionId).subscribe(res =>{
      this.totalCount = res.data;
    })
  }


  onClear() {
    this.applicationNo = null;
    this.fetchApplicantList();
  }

  public fetchApplicantList = () => {
    let selectedStatusOptionId = undefined;
    if(this.selectedStatusOption && this.selectedStatusOption.id > 0) {
      selectedStatusOptionId = this.selectedStatusOption.id;
    }
    let statusCode: any;
    if(this.selectedStatus) {
      statusCode = this.selectedStatus.statusCode;
    }
    this.applicationService.getPiUserApplicantsActivityLog(this.currentPage,this.pageSize, 
      this.applicationNo, statusCode, selectedStatusOptionId).subscribe(res => {
      this.refreshReportBatchesDataTable(res);
      console.log(res);
    })
  }
  
  public getPiUserStatus=()=> {
    let statuses = [
      {
        "statusCode":"",
        "status":"All"
      },
      {
        "statusCode":"ready_for_reporting",
        "status":"Ready For Reporting"
      },
      {
        "statusCode":"out_of_bounds",
        "status":"Out of Bounds"
      },
    ];
    this.statusList = statuses;
    this.selectedStatus = statuses[0];
  }
  searchApplicant(){
    this.fetchApplicantList();
  }

  private refreshReportBatchesDataTable = (res: any) => {
    this.dataSource.data = res.data;
    this.totalSize = res.totalRecord;
    this.currentPage = (res.currentPage - 1);
    this.dataSource._updateChangeSubscription();
    this.cdr.detectChanges();
  }

  clickBack(){
    window.history.back();

  }
  onPreviewClick(applicantId: any){
    this.router.navigate(['/pi-user-activity-log-view'], {queryParams:{application_id:applicantId}});
   }
 
}
