
<div class="content-area">
    <div class="col-sm-10 offset-sm-1">
        <app-top-bar selectedTab="home"></app-top-bar>
        <app-header></app-header>
        
    </div>

     <div class="row tool-bar">
         <div >
            <div class="refresh-btn"> <button mat-button class="btn btn-primary" (click)="showActivityLog()">Activity Log</button></div>
                </div> 
                <div class="col-sm-2 offset-sm-5 user-details">
                    <div class="value-text">
                        Welcome <br> 
                    {{user['firstName']}}! 
                    </div>
                    </div>
 </div>
 <div class="col-sm-8 offset-sm-1"><a (click)="clickReportingLink()" class="preview-link">Click here to list the Applications meant for Reporting</a></div>

 <div class="col-sm-2 offset-sm-8 title">
    <div class="p1">
    <mat-form-field appearance="fill">
        <mat-label>Village</mat-label>
        <mat-select id="village" name="villageId" [(ngModel)]="villageId">
          <mat-option *ngFor="let village of villages" [value]="village.id" (click)="onVillageChange()">
            {{village.id}} {{village.name}}
          </mat-option>
        </mat-select>
      
      </mat-form-field>
      <div class="p1">
      Total: {{totalSize}}
    </div>
</div>
</div>
<div class="col-sm-3 offset-sm-1">
    <div fxLayout="row" fxLayoutAlign="space-around">
    <input class="form-control border-end-0 border rounded-pill" style="font-size: 14px !important;"type="text" [(ngModel)]="applicationNo" placeholder="Search" id="applicationNo">
    <span class="input-group-append"><br>&nbsp;
     <button mat-button class="btn btn-primary " (click)="searchApplicant()">Search</button> 
     <button mat-button *ngIf="applicationNo" matSuffix mat-icon-button aria-label="Clear" (click)="onClear()">
        <mat-icon>close</mat-icon>
      </button>
    </span>
    </div>
</div>

 <div class="tool-bar" >
    <div class="col-sm-9 offset-sm-1">
  
    <div class="filter-layout" >
        <div  *ngFor ="let dateFilter of preDateFilters;let i=index;" >
            <button class="button" 
            [matTooltip]="matTooltipContent"
            matTooltipClass="allow-cr"
            [ngStyle]="{'background-color':dateFilter.color,'display':dateFilter.display}" >
            {{dateFilter.label}} -({{dateFilter.count}})
            <br>
            {{dateFilter.subtitle}}</button>
        </div>
    </div> 
    <br>

     <!-- <div class="filter-layout" >
        <div  *ngFor ="let dateFilter of dateFilters;let i=index;" >
            <button class="button" 
            [matTooltip]="matTooltipContent"
            matTooltipClass="allow-cr"
            (click)="clickDateFilter($event, dateFilter)"
            [ngStyle]="{'background-color':dateFilter.active?dateFilter.color:'grey'}" >
            {{dateFilter.label}} -({{dateFilter.count}})
            <br>
            {{dateFilter.subtitle}}</button>
        </div>
    </div>  -->
    <br>

    <div class="filter-layout col-md-9 offset-sm-1"  [hidden]="!show">
    <div class="col-md-10 offset-sm-3"   [hidden]="!show">
           <!-- data table -->
           <mat-card> 
        
        <table mat-table [dataSource]="dataSource">
            <ng-container matColumnDef="sn">
                <th mat-header-cell *matHeaderCellDef> Serial No. </th>
                <td mat-cell *matCellDef="let element ;let i =index;" class="column-container">{{i+1}}
            </ng-container>
            <ng-container matColumnDef="applicationNo">
                <th mat-header-cell *matHeaderCellDef> Application No. </th>
                <td mat-cell *matCellDef="let element" class="column-container">
                <a (click)="onPreviewClick(element.id)" class="preview-link">{{element.applicationNo}}</a>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;" ></tr>
        </table>
        <div class="no-records-text" *ngIf="!dataSource.data || dataSource.data.length <= 0">No Records Found!</div>
         <mat-paginator [pageSize]="pageSize" [pageSizeOptions]="[5, 10, 20]" [showFirstLastButtons]="true" [length]="totalSize"
            [pageIndex]="currentPage" (page)="getApplicantPaginatorData($event)">
        </mat-paginator> 
     </mat-card>
    </div>
    
   
</div>
</div>
</div> 
<app-footer class="col-md-10 offset-sm-3" ></app-footer>
</div> 