import { Component, OnInit } from '@angular/core';
import { StrorageService } from 'src/services/strorage.service';
import { RoleConfigService } from '../shared/config/role/role.config.service';
import { Router } from '@angular/router';
import { AuthService } from 'src/services/auth.service';
import { JccnoEncodeDecodeService } from 'src/services/jccno-encode-decode/jccno-encode-decode.service';
import { ApplicationService } from 'src/services/application.service';
import { AlertDialogService } from '../shared/alert/alert-dialog.service';
import { MessageConstantService } from '../shared/services/messageconstant/message-constant.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

 public newsContent: any[] = [];

 public profileContent: any[] = [];

 public shownNewsNumber = 1;

 public enableHelpDeskUser: any;

 public enableScrutinyUser: any;
 public  enableFieldUser: any;


  constructor(private storageService:StrorageService, 
    private router: Router ,
    private roleConfigService: RoleConfigService,
    private authService: AuthService, 
    private jccEncodeDecoder: JccnoEncodeDecodeService,
    private applicationService: ApplicationService, 
    private alertDialogService: AlertDialogService,
    private messageConsantService: MessageConstantService) { }

  ngOnInit(): void {
    if(this.storageService.isAlreadyLogin() 
        && this.storageService.isLoggedInTokenExpired()) {
        this.storageService.clear();
        this.alertDialogService.openDialog(this.messageConsantService.SESSION_EXPIRY_MESSAGE, () => {
            this.router.navigate(['/']);
        });
    } else {
      this.addNewsPaperContent();
      this.addProfileContent();
      this.redirectHomeScreen();
    }
  }

  private checkLoginSettings(){
    this.applicationService.getLoginSettings().subscribe(res=> {
      for(let i = 0;i < res.length;i++) {
          let loginSettings = res[i];
          if(loginSettings['loginUserType'] == 'help_desk_user') {
            this.enableHelpDeskUser = loginSettings['enabled'];
          }
          if(loginSettings['loginUserType'] == 'scrutiny_user') {
            this.enableScrutinyUser = loginSettings['enabled'];
          }
          if(loginSettings['loginUserType'] == 'field_visit_user') {
            this.enableFieldUser = loginSettings['enabled'];
          }
      }
    })
  }

  redirectHomeScreen(): void {
    if(!this.storageService.isAlreadyLogin()){
      this.checkLoginSettings();
      return;
    }
    let user = this.storageService.getUser();
    let roleCode = user['roleCode'];
    if(roleCode == this.roleConfigService.ROLE_HELP_DESK_USER) {
      this.router.navigate(['/application']);
    }
    if(roleCode == this.roleConfigService.ROLE_SCRUTINY_USER) {
      this.router.navigate(['/scrutiny-user-dashboard']);
    }
    if(roleCode == this.roleConfigService.ROLE_SCRUTINY_ADMIN) {
      this.router.navigate(['/applicant-list']);
    }
    if(roleCode == this.roleConfigService.ROLE_COMMITTEE) {
      this.router.navigate(['/applicant-list']);
    }
    if(roleCode == this.roleConfigService.ROLE_FIELD_USER) {
      this.router.navigate(['/field-viisit-user-dashboard']);
    }
    if(roleCode == this.roleConfigService.ROLE_ONLINE_APPLICATION_USER) {
      this.fetchOnlineSavedApplication();
    }
    if(roleCode == this.roleConfigService.ROLE_RC_REGISTER_USER) {
      this.router.navigate(['/rc-register-dashboard']);
    }
    if(roleCode == this.roleConfigService.ROLE_FOR_SD_DATA_VERIFIER) {
      this.router.navigate(['/rc-sd-data-dashboard']);
    }
    if(roleCode == this.roleConfigService.ROLE_HOT_LINE_USER) {
      this.router.navigate(['/hotline-dashboard']);
    }
  }



  fetchOnlineSavedApplication(){
    this.authService.fetchOnlineApplicantNo().subscribe(res =>{
      if(!res){
        this.router.navigate(['/application_login']);    
      } else {
        let docodeNo = this.jccEncodeDecoder.encode(res.jccNo)
        this.router.navigate(['/application_login'], { queryParams: { applicationNo: docodeNo } });
      }
    })
 }

 onDpaClick(){
  this.router.navigate(['/dpa-generation-workbench']);
 }

  onPrevNewsClick(){
    if(this.shownNewsNumber == 1){
      return;
    }
    this.shownNewsNumber--;
  }

  onNextNewsClick(){
    if(this.shownNewsNumber == this.newsContent.length-2){
      return;
    }
    this.shownNewsNumber++;
  }

  onDailyUpdatesClick(){
    this.router.navigate(['/lf-work-progress-charts']);
  }

  addNewsPaperContent(){

      let news1 = {};
      news1['date'] = "Dec 3, 2020";
      news1['title'] = "Supreme Court committee formed, headed by Justice. A V Chandrashekar";
      news1['news'] = "Supreme Court committee formed, headed by Justice. A V Chandrashekar";

      let news2 = {};
      news2['date'] = "Jan 16, 2021";
      news2['title'] =  "The committee decides to use data and technology to enable the work. \
                        It brings in NavigemData as the Consultants for building the solution.";
      news2['news'] =  "The committee decides to use data and technology to enable the work. \
                        It brings in NavigemData as the Consultants for building the solution.";

     let news3 = {};
      news3['date'] = "Jan 20, 2021";
      news3['title'] = "The committee decides to use law students from CRM Law College, University \
                       in Banglore India, as Interms for their work at 17 villages ";
      news3['news'] = "The committee decides to use law students from CRM Law College, University in \
                       Banglore India, as Interms for their work at 17 villages ";

      let news4 = {};
      news4['date'] = "Jan 31, 2021";
      news4['title'] = "A tentative date for the commencement of the Mock drill \
                       for the pilot at Vaderahalli panchayat.";
      news4['news'] = "A tentative date for the commencement of the Mock drill \
                       for the pilot at Vaderahalli panchayat.";

  

      this.newsContent.push(news1);
      this.newsContent.push(news2);
      this.newsContent.push(news3);
      this.newsContent.push(news4);
   
  }

  addProfileContent(){

    let profile1 = {};
    profile1['name'] = "Dr. S T Ramesh IPS (Retd)" ; 
    profile1['imagePath'] = "../../assets/ramesh.jpeg";
    profile1['content'] = "Dr S T Ramesh, IPS, served the Karnataka police and the Government of India \
    for three and half decades in several sensitive positions with innovation, empathy and a spirit of \
     public service. A champion of Police Reforms and Human Rights, he stood for transparency and integrity. \
     He introduced several reforms within the prison and Police departments both of which he headed with \
     distinction. He was awarded the degree of D Litt by the Tumkur University for his work, “Reflections \
     on Law Enforcement”. The President of India was pleased to confer on him the President's medal for \
     Meritorious Service and Distinguished Service. ";

    let profile2 = {};
    profile2['name'] = "Justice. A V Chandrashekar" ; 
    profile2['imagePath'] = "../../assets/jus.jpeg";
    profile2['content'] = "Justice A V Chandrashekar joined District judiciary as a direct district judge \
    in May 1996 and worked as principal district judge in 4 districts and also worked as Member Secretary of \
     Karnataka State Legal services Authority, as Director of Karnataka Judicial Academy, Director of Bangalore \
      Mediation centre and as principal judge of Family courts at Bangalore. Elevated as  judge of  Karnataka  High court \
        and sworn  on 24th  October 2013 and retired  on 29th  may 2016.;appointed as judicial  member  of Karnataka state \
         Administrative  Tribunal  and retired  on 29th may 2019. Is known for qualitative and \
         quantitative disposal of cases. Has handled many sensitive issues effectively. Is a known Arbitrator and an \
          effective resource person of Karnataka Judicial Academy a wing of Karnataka High court in providing judicial \
           education to the judicial officers in the state. ";

    let profile3 = {};
    profile3['name'] = "Mr. Jayakar Jerome IAS (Retd)" ; 
    profile3['imagePath'] = "../../assets/jayakar.jpeg";
    profile3['content'] = "Jayakar Jerome is a retd IAS Officer of the Karnataka Cadre. He has held various \
    senior and sensitive posts in his career. He is best remembered for his tenure as BDA Commissioner during \
     the Chief Ministership of Sri. S.M. Krishna. He has the unique distinction of being a non-career diplomat \
      when he served as Ambassador to the Republic of Slovenia.";

    this.profileContent.push(profile2);
    this.profileContent.push(profile3);
    this.profileContent.push(profile1);
 
  }
}
