import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { ApplicationService } from 'src/services/application.service';


@Component({
  selector: 'app-pi-user-activity-log-view',
  templateUrl: './pi-user-activity-log-view.component.html',
  styleUrls: ['./pi-user-activity-log-view.component.scss']
})
export class PiUserActivityLogViewComponent implements OnInit {
  application_id: any;
  applicationData;
  selectedPanchayat = '';
  message:string;
  comment:string;
  status:string;
  
  public isLoading=false;
  selectedVillages: any;
  public commentTypes: any = [];
  public scrutinyLandTypes:any=[];
  public fieldVisitStatusList: any = [];
    public title: any;
    approved_filter: any;
    public disable:boolean=true;
  createdAt: any;
  date: any;
  refreshDataTable: any;
  currentPage: any;
  pageSize: any;
  fieldVisitData: any;
  fieldVisitDetails: any=[];
  selectedLandType: any=[];
  selectedScrutinyLandTypes:any=[];
  notificationType: any;
   
  constructor(private applicationService: ApplicationService,private route: ActivatedRoute, 
    private _snackBar: MatSnackBar, private router: Router) { 
      this.application_id = this.route.snapshot.queryParams['application_id'];
      console.log("jcc", this.application_id)}

      ngOnInit(): void {
        this.getAdjudicationActivityLogDetails();
      }
    
      getAdjudicationActivityLogDetails(){
       this.applicationService.fetchApplicantById(this.application_id).subscribe(res=>{
         console.log("field",res);
         this.applicationData = res;
         this.notificationType = res.notificationType;
         if(this.applicationData.fieldVisitStatus) {
            this.selectedLandType=this.applicationData.fieldVisitStatus.landTypeVOList;
            console.log(this.selectedLandType);
         }
         if(this.applicationData.scrutinyStatus) {
            this.selectedScrutinyLandTypes = this.applicationData.scrutinyStatus.landTypeVOList;
         }
         this.fetchAllFieldVistLandTypes(this.selectedLandType);
         this.fetchAllScrutinyLandTypes();
      })
    }
    private fetchAllFieldVistLandTypes(selectedCommentTypes: any){
     this.applicationService.getAllLandTypes().subscribe(response=>{
       let res = response.data;
       for(let i = 0;i < res.length;i++) {
         res[i].checked = false;
         for(let idx = 0;idx < selectedCommentTypes.length;idx++) {
           if(selectedCommentTypes[idx]['id'] == res[i]['id']) {
             res[i].checked = true;
             break;
           }
         }
         this.commentTypes.push(res[i]);
       }
     })
   }
   private fetchAllScrutinyLandTypes(){
    this.applicationService.getAllLandTypes().subscribe(response=>{
      let res = response.data;
      for(let i = 0;i < res.length;i++) {
        res[i].checked = false;
        for(let idx = 0;idx < this.selectedScrutinyLandTypes.length;idx++) {
          if(this.selectedScrutinyLandTypes[idx]['id'] == res[i]['id']) {
            res[i].checked = true;
            break;
          }
        }
        this.scrutinyLandTypes.push(res[i]);
      }
    })
  }
 
      clickBack(){
        window.history.back();
      }

}
