import { Component, Input, OnInit } from '@angular/core';
import { ApplicationService } from 'src/services/application.service';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { SectorVillageDialogComponent } from '../sector-village-dialog/sector-village-dialog.component';
import { MatTableDataSource } from '@angular/material/table';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { ConfirmDialogService } from 'src/app/shared/confirm-dialog/confirm-dialog.service';
import { MatSnackBar } from '@angular/material/snack-bar';



@Component({
  selector: 'app-sector-container',
  templateUrl: './sector-container.component.html',
  styleUrls: ['./sector-container.component.scss']
})
export class SectorContainerComponent implements OnInit {

  public functionTypeData: any[] = [];
  public sectorData: any[] = [];
  selectedSurveyItems: any[] = [];
  public agreement1Confirmed: boolean = false;
  public agreement2Confirmed: boolean = false;
  public faTimes = faTimes;
  public displayedColumns: string[] = ['village', 'syNos', 'approxArea', 'actions'];

  dataSource = new MatTableDataSource();
  @Input() startDate: any;

  @Input() endDate: any;

  constructor(private applicationService: ApplicationService, public dialog: MatDialog,
     public confirmDialogService: ConfirmDialogService, private _snackBar: MatSnackBar) { }

  ngOnInit() {
    this.fetchTemplateData();
    console.log(this.startDate);
  }

  fetchTemplateData() {
    this.applicationService.fetchSectorFormTemplateData('Prep').subscribe(res => {
      this.functionTypeData = res.functionTypeDataList;
      this.sectorData = res.sectors;
      for (let i = 0; i < this.sectorData.length; i++) {
        this.sectorData[i].surveyNos = '';
        this.sectorData[i].selectedSurveyItems = [];
      }
    })
  }

  onVillageChange = (index: number, sectorId: number, villageId: number) => {
    this.applicationService.getLayoutFormationSurveyList(sectorId,null, null,null,  villageId).subscribe(res => {
      console.log("rses", res);;
      this.sectorData[index].surveyAdditionalInfoData = res;
      this.sectorData[index].surveyNos = '';
    })
  }

  onFunctionTypeChange = (index: number, functionTypeId: number) => {
    this.applicationService.getFunctionTypeMainActivities(functionTypeId).subscribe(res => {
      console.log("rses", res);
      this.sectorData[index].mainActivityData = res;
    })
  }

  onMainActivityChange = (index: number, functionTypeId: number, mainActivityId: number) => {
    this.applicationService.getMainActivityWorkTypes(functionTypeId, mainActivityId).subscribe(res => {
      console.log("rses", res);
      this.sectorData[index].workTypes = res;
    })
  }

  generatePdf = (sectorData: any) => {
    const options = {
      message: 'Confirm Daily Action Plan Generation',
      cancelText:'Cancel',
      confirmText: 'Ok'
    };
    this.confirmDialogService.openDialog(options);
    this.confirmDialogService.confirmed().subscribe(confirmed => {
      if(confirmed){
      let payload: any = this.constructPayload(sectorData);
      this.applicationService.generateSectorWorkitemReport('prep', payload, this.startDate, this.endDate).subscribe(res => {
       window.open(res.fileURL);
      })
      }
    });
  }

  payloadValidation = (data: any, index: any) => {
    if (data.checked) {
      index = index + 1;
      if (!data.selectedFunctionId) {
        throw new Error('Sector -' + index + ' Function type must not be empty');
      }
      if (!data.selectedMainActivityId) {
        throw new Error('Sector -' + index + ' Main activity must not be empty');
      }
      if (!data.selectedWorkTypeId) {
        throw new Error('Sector -' + index + ' Work type must not be empty');
      }
      if (!data.villages) {
        throw new Error('Sector -' + index + ' Village must not be empty');
      }
    }
  }

  constructPayload = (sectorData: any) => {
        let payload: any[] = [];
        if(!this.startDate) {
          throw new Error('PDF From date must not be empty');
        }
        if(!this.endDate) {
          throw new Error('PDF To date must not be empty');
        }
        for (let i = 0; i < this.sectorData.length; i++) {
          if (sectorData[i].checked) {
            this.payloadValidation(sectorData[i], i);
            let item: any = {};
            item.sectorId = this.sectorData[i].sectorId;
            item.functionTypeId = this.sectorData[i].selectedFunctionId;
            item.mainActivityId = this.sectorData[i].selectedMainActivityId;
            item.workTypeId = this.sectorData[i].selectedWorkTypeId;
            item.comments = this.sectorData[i].comments;
            let villages: any[] = [];
            let sectorVillages: any[] = this.sectorData[i].villages;
            for (let j = 0; j < sectorVillages.length; j++) {
              let village: any = {};
              village.villageId = sectorVillages[j].selectedVillage.villageId;
              village.surveyIds = sectorVillages[j].selectedSurveyItems;
              village.approxArea = sectorVillages[j].approxArea;
              villages.push(village);
            }
            item.villages = villages;
            payload.push(item);
          }
        }
      if (payload.length == 0) {
        throw new Error("select any sector");
      } else {
        return payload;
      }
  }

  public previewPdf = (sectorData: any) => {
    let payload: any = this.constructPayload(sectorData);
    this.applicationService.previewSectorWorkitemReport('prep', payload, this.startDate, this.endDate).subscribe(res => {
      console.log("rses", res);
      window.open(res.fileURL);
    })
  }

  public onVillageSelect(sector: any, index: number) {
    let data = {};
    data['sectorRowIndex'] = index;
    data['sectorData'] = sector;
    this.openAddVillageDialog(data);
  }

  public openAddVillageDialog(data: any) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.height = '80vh';
    dialogConfig.width = '80vw';
    dialogConfig.data = data;
    const dialogRef = this.dialog.open(SectorVillageDialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(res => {
      console.log(res);
      if (res) {
        if (!this.sectorData[res.sectorRowindex].villages) {
          this.sectorData[res.sectorRowindex].villages = [];
        }
        this.sectorData[res.sectorRowindex].villages.push(res.row);
      }
    })
  }

  onVillageDelete(e: any, sectorIdx: number, villageIdx: number) {
    this.sectorData[sectorIdx].villages.splice(villageIdx, 1);
  }

  clearSelectedSectorData(sectorData: any) {
    sectorData.selectedFunctionId = null;
    sectorData.selectedMainActivityId = null;
    sectorData.selectedWorkTypeId = null;
    sectorData.villages = [];

  }

  onSectorSelected(event: any, data: any, index: number) {
    if (!event.checked) {
      if (data) {
        this.clearSelectedSectorData(data);
      }
    }
  }
}
