<div class="content-area">
  <div class="col-sm-10 offset-sm-1">
    <app-top-bar selectedTab="home"></app-top-bar>
    <div class="heading">
      {{previousHeading}} / {{title}}
    </div>
    <app-layout-map></app-layout-map>
  </div>
  <div class="container my-5">
    <div class=" row d-flex">
      <div lass="col-4">
        <button class="btn btn-primary refresh-btn" (click)="refreshClick()">
          <fa-icon [icon]="faSync" class="close-icon" [spin]="isRefreshing"></fa-icon>
        </button>
      </div>
      <div class="col-2">
        <button mat-button class="btn btn-primary  refresh-btn" (click)="clickBack()">Back</button>
      </div>
      <div class="col-6" style="text-align: center;">
        <h1 class="fw-bold">SLAO – Land Handover Status</h1>
      </div>
      <div class="col-sm-3 user-details" style="margin-top: -14px;margin-left: 25px;">
        <div class="value-text">
          Welcome <br>
          {{user['firstName']}} !
        </div>
      </div>
    </div>
    <div class="d-flex justify-content-around mt-5">
      <div class="col-sm-3">
          <button mat-button class="btn content-btn btn-width w-75 notifications" 
          id="allApplicants" type="button" (click)="surveyBtnClicked('')" [ngStyle]="{ 'background-color': totalNotificationType ? '#ed7d31' : '#a6a6a6;' }">Total </button>
        </div>  
      <div class="col-sm-3 d-flex">
          <button mat-button class="btn content-btn btn-width w-75 oldNotification" 
          id="selectapp" type="button" (click)="surveyBtnClicked('OLD')"  [ngStyle]="{ 'background-color': oldNotificationType ? '#ed7d31' : '#a6a6a6;' }">Old Sy.Nos.</button>
        </div>  
  
        <div class="col-sm-3 d-flex ">
          <button  mat-button  class="btn content-btn btn-width w-75 newNotification"  
          type="button" (click)="surveyBtnClicked('NEW')"  [ngStyle]="{ 'background-color': NewNotificationType ? '#ed7d31' : '#a6a6a6;' }">New Sy.Nos.</button>
        </div>
        <div class="col-sm-3 d-flex ">
          <button  mat-button  class="btn content-btn btn-width w-75 newNotification"  
          type="button" (click)="surveyBtnClicked('RMP')"  [ngStyle]="{ 'background-color': RMPNotificationType ? '#ed7d31' : '#a6a6a6;' }">RMP Sy.Nos.</button>
        </div>
   </div>
    <div class="content-area">
      <div class="container">
        <div class="row">
          <div>
            <div *ngFor="let data of villageAndSurveyData; let i = index;"
              class="col-sm-3 village-options     width: 320px;">
              <div class="col-sm-12" [class.cursor-style]="type == 'data'">
                <button class="button " [class.village]="type == 'data'"
                  [style.background-color]=" data[i] == selectStatus ? colors[i] :'grey'"
                  [style.border-color]=" data[i] == selectStatus ? 'black' :'grey'"
                  [style.color]=" data[i] == selectStatus ? 'black' :'#ffffff'">
                  <div>
                    <div class="center-align">
                      {{data.villageName}}
                    </div>
                    <div class="center-align">
                      Survey Nos - {{data.totalSurveyNo}}
                    </div>
                    <div class="center-align">
                      Processed Sy.No - {{data.totalProcessedSurveyNo}}
                    </div>
                  </div>
                </button>
              </div>
            </div>
          </div>
        </div>
        <div class="row d-flex justify-content-end">
          <img class="col-sm-1 " src="../../../assets/download_xlsx_icon.png" (click)="downloadAllAsExcelsheet()" />
        </div>
      </div>
    </div>
    <div class="w-100 my-3" style="background-color: black; height: 2px;"></div>
    <div class="container">
      <div class=" row d-flex justify-content-between ">
        <div class="col-4 ">
          <mat-form-field appearance="fill">
            <mat-label>Village</mat-label>
            <mat-select id="village" name="villageId" [(ngModel)]="villageId">
              <mat-option *ngFor="let village of villages" [value]="village.id" (click)="onVillageChange(village)">
                {{ village.id }} {{ village.name }}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <br>
          <div class="text">
            <p class="text-primary">Total Survey Nos : {{totalSurveyNoCount}}</p>
            <p><span style="color: #18A071;">Total Processed Survey Nos : {{processedSurveyCount}} </span></p>
          </div>
        </div>
        <div class="col-5 d-flex align-items-start justify-content-center ">
          <h1 class="fw-bold">Village - Sy - Search Module - {{surveyNotificationType ? surveyNotificationType : 'All'}}</h1>
        </div>
        <div class="col-3">
          <div class="row">
            <div class="col-sm-3"></div>
            <div class="col-sm-7 w-100">
              <label class="form-label">Survey Filter</label>
              <select class="form-textbox" [(ngModel)]="surveyId" (change)="onSurveyNumberChange(surveyId)">
                <option *ngFor="let survey of surveyNumbers" [ngValue]="survey.id">{{survey.surveyNo}}</option>
              </select>
            </div>

            <div class="col-sm-2 mt-4">
              <button mat-button *ngIf="selectedSurveyNumber" matSuffix mat-icon-button aria-label="Clear"
                (click)="onClearSurveyNumber()">
                <mat-icon>close</mat-icon>
              </button>
            </div>
          </div>

        </div>
      </div>
    </div>
    <div class="w-100 my-3" style="background-color: black; height: 2px;"></div>
    <div class="container text">
      <div class="row">
        <div class="col-sm-6 ">
          <div class="row">
            <div class="col-sm-11 text">
              <p>Land Notified as per FN (in acres) : {{landNotifiedInAcres}}</p>
            </div>
            <div class="col-sm-10" *ngIf="isViewTransactionBtn">
              <h2>Award Approved Status : {{awardApprovedStatus}} </h2>
            </div>
            <div class="col-sm-10">
              <p>Area Awarded : &nbsp;&nbsp; {{areaAwardedInAcres}}</p>
            </div>
            <div class="col-sm-10 ">
              <p>Total Cash Earmarked (in Rs.):​ {{totalCashEarMarked}}</p>
            </div>
            <div _ngcontent-bvh-c39="" class="col-sm-12">
              <p _ngcontent-bvh-c39="" style="white-space: nowrap;">Total extent of Developed Site earmarked (in Sq.Ft.)
                :​ {{developedSiteEarmarked}}</p>
            </div>

          </div>
          <div class="row">
            <div class="col-sm-12 ">
              <div class="row">
                <div class="col-sm-10">
                  <p>Area Exempted by SC :​ {{areaExemptedTotalExtentInAcres}}</p>
                </div>
                <div class="col-sm-10">
                  <p>Area under BDA Approved Layout :​ {{areaApprovedTotalExtentInAcres}} </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-sm-6 text-center">
          <p><span style="margin-left: 232px;">SLAO : {{slaoName}} </span></p>
        </div>
        <div class="w-100 mt-2" style="background-color: black; height: 2px;"></div>
        <div class="container">
          <div class="row d-flex justify-content-between">
            <h2 class="col-sm-6">Cases Compensation paid in Cash</h2> <h2 class="col-sm-6 cases-date">No. of Payment Dt: {{' '+casesDateCount}}</h2>
          </div>
          <div class="row">
            <div class="col-sm-8 ">
              <div class="row ">
                <div class="col-sm-1"></div>
                <div class="col-sm-11">
                  <p>No. of Cases : {{noOfCashCompensation}}</p>
                </div>
                <div class="col-sm-1"></div>
                <div class="col-sm-11">
                  <p>Total Extent paid in Cash : {{cashCompensationInAcres}}</p>
                </div>
                <div class="col-sm-1"></div>
                <div class="col-sm-11">
                  <p> Total Cash Dispensed :​ {{cashDispensed}}</p>
                </div>
              </div>
            </div>
            <div class="col-sm-4">
              <button class="view-btn" (click)="viewReconciliationHistory('cases_compensation_paid_in_cash')" type="button">View</button>
            </div>
          </div>
        </div>
        <div class="w-100 mt-2" style="background-color: black; height: 2px;"></div>
        <div class="container">
          <div class="row d-flex justify-content-between">
            <h2 class="col-sm-6">EC Issued</h2><h2 class="col-sm-6 cases-date">No. of EC Issued Dt: {{' '+ecDateCount}}</h2>
          </div>
          <div class="row">
            <div class="col-sm-8 d-flex justify-content-center">
              <div class="row d-flex justify-content-center">
                <div class="col-sm-10">
                  <p>No. of EC Issued : {{noOfEcIssued}}</p>
                </div>
                <div class="col-sm-10">
                  <p>Total Awarded Extent for which EC issued ( in Acres) : {{ecIssuedInAcres}}</p>
                </div>
                <div class="col-sm-10">
                  <p>Total Allotted Developed Site ( in Sq. Ft) - Eligible : {{eligibleAllottedSite}}</p>
                </div>
                <div class="col-sm-10">
                  <p>Total Allotted Developed Site ( in Sq. Ft) - Entitled : {{entitledAllottedSite}}</p>
                </div>
              </div>
            </div>
            <div class="col-sm-4">
              <button class="view-btn" (click)="viewReconciliationHistory('ec_issued')" type="button">View</button>
            </div>
          </div>
        </div>
        <div class="w-100 mt-2" style="background-color: black; height: 2px;"></div>
        <div class="container mt-4" *ngIf="isViewTransactionBtn == true">
          <button class="offset-9 btn btn-primary" (click)="onViewBtnClicked()">
            View Transaction History
          </button>
        </div>
      </div>
      <app-footer></app-footer>
    </div>