import { Component, OnInit, Inject, Input, EventEmitter, HostListener } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';


@Component({
  selector: 'app-message-dialog',
  templateUrl: './message-dialog.component.html',
  styleUrls: ['./message-dialog.component.scss']
})
export class MessageDialogComponent implements OnInit {

  constructor(private dialogRef: MatDialogRef<MessageDialogComponent>, 
    @Inject(MAT_DIALOG_DATA) public data: { message: string }) { }

  ngOnInit(): void {
  }

  public onOkClicked = () => {
    this.dialogRef.close(true);
  }

  @HostListener("keydown.esc") 
  public onEsc() {
    this.dialogRef.close(false);
  }

}
