import { Component, OnInit, ChangeDetectorRef } from "@angular/core";
import { PageEvent } from "@angular/material/paginator";
import { MatTableDataSource } from "@angular/material/table";
import { ActivatedRoute, Router } from "@angular/router";
import { faSync } from "@fortawesome/free-solid-svg-icons";
import { ConfirmDialogService } from "src/app/shared/confirm-dialog/confirm-dialog.service";
import { MessageDialogService } from "src/app/shared/message-dialog/message-dialog.service";
import { ApiService } from "src/services/api.service";
import { ApplicationService } from "src/services/application.service";
import { StrorageService } from "src/services/strorage.service";

@Component({
  selector: 'app-committe-cd-done-details',
  templateUrl: './committe-cd-done-details.component.html',
  styleUrls: ['./committe-cd-done-details.component.scss']
})
export class CommitteCdDoneDetailsComponent implements OnInit {

  public dataSource = new MatTableDataSource();
 
  public displayedColumns: string[] = ['siNo','groupId','applicationNo', 'applicantName','l', 'b', 'propertyType','structureType','noOfFloor','sitalArea','plinthArea', 'totalBuildUpArea','north','east','west','south', 'processedBy','processedByName','notificationType','action'];

  public faSync = faSync;
  public isRefreshing = false;
  public userName = "Username";
  public currentPage = 0;
  public show = false;
  public totalSize = 0;
  user: any;
  public pageSize =5;
  public cdInfoReportBatchName: any;
  public searchKeyword: any;
  applicationNo = null;
  village: any;
  villages : any[];
  villageId: any;
  selectedVillages: number;
  public totalApplicantCount: any = 0;
  applicantServiceApiCallObserver: any;
  private piUserLastActivity: any;
  notificationType: any;


 constructor(private cdr:ChangeDetectorRef,private applicationService: ApplicationService,private router: Router,
  private messageDialogService: MessageDialogService,private confirmDialogService: ConfirmDialogService, private apiService:ApiService,
  private storageService: StrorageService, private route:ActivatedRoute) { 
    this.notificationType = this.route.snapshot.queryParams['notificationType'];
}

 ngOnInit(): void {
  this.user = this.storageService.getUser();
  this.userName=this.user['firstName']
   this.getCdFeederReportApplicants();
   this.fetchVillages();
 
 }
 getCdFeederReportApplicants(): void {
  this.applicationService.fetchCdFeederReportApplicantsWithGroupNo(this.currentPage,this.pageSize, 
    this.applicationNo,this.villageId, this.notificationType).subscribe(res =>{
    this.refreshReportBatchesDataTable(res);
  })
}

fetchVillages(){
  this.applicantServiceApiCallObserver = this.applicationService.fetchAllVillages().subscribe(res=>{
    this.villages = res;
    this.villages.unshift({
      "id":"",
      "name":"All"
     });
    })
  }

  onVillageChange(): void{
    this.show=false;
    this.getCdFeederReportApplicants();
  }

 refreshClick(): void {
  this.currentPage = 0;
  this.pageSize = 5;
  this.getCdFeederReportApplicants();

}
searchApplicant(){
  this.currentPage = 0;
  this.pageSize = 5;
  this.getCdFeederReportApplicants();

}
public getCdFeederReportApplicantsPaginatorData(event: PageEvent) {
  this.currentPage = event.pageIndex;
  this.pageSize = event.pageSize;
  this.getCdFeederReportApplicants();
}

 goBack(): void {
  window.history.back();
 }

 onClear() {
  this.applicationNo = null;
  this.getCdFeederReportApplicants();
}

 private refreshReportBatchesDataTable = (res: any) => {
  this.dataSource.data = res.data;
  this.totalSize = res.totalRecord;
  this.currentPage = (res.currentPage - 1);
  this.dataSource._updateChangeSubscription();
  this.cdr.detectChanges();
}
 
}

