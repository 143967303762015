import { Component, OnInit } from '@angular/core';
import { ApplicationService } from 'src/services/application.service';

@Component({
  selector: 'app-work-type-chart',
  templateUrl: './work-type-chart.component.html',
  styleUrls: ['./work-type-chart.component.scss']
})
export class WorkTypeChartComponent implements OnInit {

  constructor(private applicationService: ApplicationService) { }

  data : any[] = [];
  title = 'Summary of RoadSide Drain'
  type = 'ColumnChart';
  options = {
    chartArea: {  width: "90%", height: "68%" },
    legend: {
      position:'top',
      maxLines: 1,
      textStyle: {
        fontSize: 15
      },
      animation: {
        startup: false,
        duration: 0, // Set the duration to 0 to disable the animation
        easing: 'out'
      }
    },
    titlePosition: 'in', axisTitlesPosition: 'none',
        hAxis: { textPosition: 'out'}, 
        vAxis: {      textPosition: 'none',
        gridlines: {
          count: 0
        }
      },
    animation: {
      startup: true,
      duration: 1000,
      easing: 'out'
    },
    // annotations: {
    //   alwaysOutside: true,
    //   style:'line',
    //   textStyle: {
    //     fontSize: 14,
    //     color: '#000',
    //     auraColor: 'none'
    //   },
    //   stem: {
    //     length: 4 // Set the stem length to 0 to disable the lines
    //   }
    // },
    bar: {
      groupWidth: '90%', // Adjust the percentage as needed
      width: '100%' // Adjust the width as needed
    }
  };
  width = 850;
  height = 450;
  chartData: any[] = [];
  columnNames: any[] = [];
  //  chartData = [
  //   ["Sector 1", 20, '20', 10, '10', 8, '8', 6, '6', 4, '4', 5, '5'],
  //   ["Sector 2", 18, '18', 8, '8', 2, '2', 5, '5', 4, '4', 5, '5'],
  //   ["Sector 3", 20, '20', 12, '12', 2, '2', 4, '4', 2, '2', 6, '6'],
  //   ["Sector 4", 16, '16', 12, '12', 6, '6', 9, '9', 6, '6', 3, '3'],
  //   ["Sector 5", 14, '14', 10, '10', 9, '9', 6, '6', 4, '4', 5, '5'],
  //   ["Sector 6", 18, '18', 8, '8', 6, '6', 2, '2', 4, '4', 2, '2'],
  //   ["Sector 7", 20, '20', 10, '10', 8, '8', 6, '6', 9, '9', 5, '5'],
  //   ["Sector 8", 15, '15', 9, '9', 10, '10', 6, '6', 4, '4', 4, '4'],
  //   ["Sector 9", 14, '14', 8, '8', 5, '5', 4, '4', 2, '2', 6, '6']
  // ];
  
  // columnNames = ['Sector Name', 'Excavation',{ type: 'string', role: 'annotation' },
  //  'Fabrication',{ type: 'string', role: 'annotation' }, 'PCC',{ type: 'string', role: 'annotation' },
  //   'Raft Concrete',{ type: 'string', role: 'annotation' } ,
  //    'Shuttering',{ type: 'string', role: 'annotation' }, 'Sidewalls',{ type: 'string', role: 'annotation' }];

  ngOnInit() {
    this.getSectorChartData();
  }

  getSectorChartData = () => {
    this.applicationService.getLayoutFormationWorkCharts().subscribe(res =>{
      this.chartData = [];
      //this.columnNames = [];
      this.data = res;
      if(this.columnNames.length == 0) {
        this.filterColumnData();
      }
      this.filterChartData();
      console.log(this.chartData,'data');
      // this.retryGetSectorChartData();
    })
  }

  retryGetSectorChartData = () => {
    setTimeout(() => {
      this.getSectorChartData();
    }, 2000);
  }


  filterChartData(){
    for(let i = 0; i<this.data.length; i++){
      let chartDataset = [];
      for(let j = 0; j < this.data[i].workTypeChartDataList.length; j++){
        if (chartDataset.length == 0){
          chartDataset.push(this.data[i].sectorName);
        }
        let chartData = this.data[i].workTypeChartDataList[j];
        let totalWorkCompleted = chartData ? chartData.totalWorkCompleted : null;
        chartDataset.push(totalWorkCompleted || totalWorkCompleted === '' ? parseFloat(totalWorkCompleted) : 0.0);
        // chartDataset.push(totalWorkCompleted !== null && totalWorkCompleted !== '' ? totalWorkCompleted.toString() : '0.0');
      
      }
      this.chartData.push(chartDataset);
    }
  }

  filterColumnData(){
    let columnData = [];
    for(let j = 0; j < this.data[0].workTypeChartDataList.length; j++){
      if(columnData.length == 0){
        columnData.push('Sector Name');
      }
      columnData.push(this.data[0].workTypeChartDataList[j].workName);
      // columnData.push({ type: 'string', role: 'annotation' });
    }
    this.columnNames = columnData;
  }

}
