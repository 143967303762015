import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { PageEvent } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ApplicantHelperService } from 'src/services/applicant-helper/applicant-helper.service';
import { ApplicationService } from 'src/services/application.service';
import { StrorageService } from 'src/services/strorage.service';
import { RoleConfigService } from '../shared/config/role/role.config.service';
import { ConfirmDialogService } from '../shared/confirm-dialog/confirm-dialog.service';
import { ConfirmDialogComponent } from '../shared/confirm-dialog/confirm-dialog/confirm-dialog.component';
import { DecisionMakingCommentDialogBoxComponent } from './decision-making-comment-dialog-box/decision-making-comment-dialog-box.component';
@Component({
  selector: 'app-decision-making-form',
  templateUrl: './decision-making-form.component.html',
  styleUrls: ['./decision-making-form.component.scss']
})
export class DecisionMakingFormComponent implements OnInit {

  public selectedStatusOption: any;
  public apiURL: string;
  public statusCode: any = 'out_of_bounds';
  public pageSize =5;
  public currentPage = 0;
  public totalSize: number= 0;
  public totalCount: number= 0;
  public title: any;
  public fielVisitCommentTypes: any = [];
  public commentTypes: any = [];

  applicationData;
  selectedPanchayat = '';
  selectedLandType: any = [];
  application_id: any;
  groupNo: any;
  statusList: any = [];
  selectedStatus:any;
  selectedStatusOptionCode: any;
  totalApplicants: any;
  status_List:any=[];
  statusOptions: any = [];
  isEligible: any;

  public displayedColumns: string[] = ['select_all','sn','applicationNo','currentStatus','notificationType', 'recommendationName', 'comments', 'status', 'subStatus','actions'];

  public dataSource = new MatTableDataSource();
  user: any;
  village: any;
  villageId: any;
  applicationNo = null;
  statusOption: any;
  statusOptionId:any;
  public totalApplicantCount: any = 0;
  applicants: any;
  applicantsCount: any;
  statusApplicantCount: any;
  data: any;
  show: boolean;
  groups: any;
  groupApplicantCount: any;
  dialogRef: MatDialogRef<ConfirmDialogComponent>;
  dialogReference: MatDialogRef<DecisionMakingCommentDialogBoxComponent>;
  readyForReportingCount: any;
  public selectedApplicants: any = [];
  public eligibleApplicants: any = [];
  heading: any;
  comment:string;
  constructor(private applicationService: ApplicationService, private cdr:ChangeDetectorRef,    private dialog: MatDialog,
    private _snackBar: MatSnackBar,private location: Location,private confirmDialogService: ConfirmDialogService,private roleConfigService: RoleConfigService, private route: ActivatedRoute, private router: Router,private storageService:StrorageService,private applicantHelperService: ApplicantHelperService) {
      this.application_id = this.route.snapshot.queryParams['application_id'];
      this.title=this.route.snapshot.queryParams['title'];
      this.applicationNo=this.route.snapshot.queryParams['application_no'];
      console.log(this.applicationNo);
     }

  ngOnInit(): void {
    this.user=this.storageService.getUser();
    this.groupApplicants(this.application_id);
      let user = this.storageService.getUser();
      let roleCode = user['roleCode'];
      if(roleCode == this.roleConfigService.ROLE_ADJUDICATION_USER) {
        this.heading = "ADJUDICATION";
      }
      if(roleCode == this.roleConfigService.ROLE_PI_USER) {
        this.heading = "PI USER";
      }

  }

  // onPreviewClick = (id:any) => {
  //   this.router.navigate(['/decision-making-workbench'], {queryParams:{application_id:id,title: this.title,application_no:this.applicationNo}});
  //   console.log("query",this.application_id)
  // }

  onPreviewClick (id:any){
    const host: string =  location.origin;
    const url: string = host + '/#/' + String(this.router.createUrlTree(['/decision-making-workbench'], { queryParams: { application_id:id,title: this.title,application_no:this.applicationNo } }));
    window.open(url, '_blank');

}

  public groupApplicants = (application_id: any) => {
    this.applicationService.getApplicantsByGroup(this.application_id).subscribe(res =>{
      this.refreshDataTable(res);
      this.groups =res.group;
      this.data = res;
      console.log("groups"+this.groups)
      console.log("data"+this.data)
      this.readyForReportingCount = res.readyForReportingStatusApplicantCount;
      this.groupApplicantCount =res.applicants.length;
      console.log(this.readyForReportingCount);
    })
  }
 
  public getApplicantPaginatorData(event: PageEvent) {
    this.currentPage = event.pageIndex + 1;
    this.pageSize = event.pageSize;
    this.groupApplicants(this.application_id);
  }

  subStatus = [
    {
      "statusCode":"ready_for_reporting",
      "subStatus":[
        {
          "code":"Pre - 2014",
          "name":"Pre - 2014"
        },
        {
          "code":"2014 - 2018",
          "name":"2014 - 2018"
        },
        {
          "code":"Spillover",
          "name":"Spillover"
        },
        {
          "code":"Post - 2018",
          "name":"Post - 2018"
        },
        {
          "code":"Conditional Approval",
          "name":"Conditional Approval"
        },
        {
          "code":"NN",
          "name":"NN"
        }
      ]
    },
    {
      "statusCode":"out_of_bounds",
      "subStatus":[
         {
          "code":"Buffer Zone A-Lake",
          "name":"Buffer Zone A-Lake"
        },
              {
          "code":"Buffer Zone B-Nala",
          "name":"Buffer Zone B-Nala"
        },
        {
          "code":"B-Kharab",
          "name":"B-Kharab"
        },
        {
          "code":"RMP Roads",
          "name":"RMP Roads"
        },
        {
          "code":"Layout Roads",
          "name":"Layout Roads"
        },
        {
          "code":"Parks",
          "name":"Parks"
        },
        {
          "code":"Tank Bed",
          "name":"Tank Bed"
        },
        {
          "code":"Gas Pipeline",
          "name":"Gas Pipeline"
        },
        {
          "code":"Not Notified Land",
          "name":"Not Notified Land"
        },
        {
          "code":"Vacant Sites",
          "name":"Vacant Sites"
        },
        {
          "code":"Duplicate Applications",
          "name":"Duplicate Applications"
        },
        {
          "code":"No Enclosures",
          "name":"No Enclosures"
        },
        {
          "code":"Approved Layout",
          "name":"Approved Layout"
        },
        {
          "code":"Suspected Fraud",
          "name":"Suspected Fraud"
        },
        {
          "code":"OC Pending",
          "name":"OC Pending"
        },
        {
          "code":"Institutions - Approved by SC",
          "name":"Institutions - Approved by SC"
        },
        {
          "code":"Vacant sites purchased post 3 Aug 2018",
          "name":"Vacant sites purchased post 3 Aug 2018"
        },
        {
          "code":"Insufficient Docs",
          "name":"Insufficient Docs"
        },
        {
          "code":"All docs Post-2018",
          "name":"All docs Post-2018"
        },
        {
          "code":"Buffer Zone A-Lake – NN",
          "name":"Buffer Zone A-Lake – NN"
        },
        {
          "code":"B-Kharab - NN",
          "name":"B-Kharab - NN"
        },
        {
          "code":"RMP Roads - NN",
          "name":"RMP Roads - NN"
        },
        {
          "code":"Layout Roads - NN",
          "name":"Layout Roads - NN"
        },
        {
          "code":"Parks - NN",
          "name":"Parks - NN"
        },
        {
          "code":"Tank Bed - NN",
          "name":"Tank Bed - NN"
        },
        {
          "code":"Gas Pipeline - NN",
          "name":"Gas Pipeline - NN"
        },
        {
          "code":"Not Notified Land - NN",
          "name":"Not Notified Land - NN"
        },
        {
          "code":"Vacant Sites - NN",
          "name":"Vacant Sites - NN"
        },
        {
          "code":"Duplicate Applications - NN",
          "name":"Duplicate Applications - NN"
        },
        {
          "code":"No Enclosures - NN",
          "name":"No Enclosures - NN"
        },
        {
          "code":"Approved Layout - NN",
          "name":"Approved Layout - NN"
        },
        {
          "code":"Suspected Fraud - NN",
          "name":"Suspected Fraud - NN"
        },
        {
          "code":"OC Pending - NN",
          "name":"OC Pending - NN"
        },
        {
          "code":"Institutions - Approved by SC - NN",
          "name":"Institutions - Approved by SC - NN"
        },
        {
          "code":"Insufficient Docs - NN",
          "name":"Insufficient Docs - NN"
        },
        {
          "code":"Approved Apartments",
          "name":"Approved Apartments"
        }

      ]
    },
  ]



  private refreshDataTable = (res: any) => {
    for(var i =0;i < res.applicants.length;i++) {
      var status = [
        {
          "statusCode":"marked_for_personal_interview",
          "status":"Marked for Doc Verification/ Personal Interview"
        },
        {
          "statusCode":"ready_for_reporting",
          "status":"Ready For Reporting"
        },
        {
          "statusCode":"out_of_bounds",
          "status":"Out Of Bounds"
        }
      ];
      res.applicants[i].statusList = status;
    }
     this.dataSource.data = res.applicants;
     this.dataSource._updateChangeSubscription();
     this.cdr.detectChanges();
   }

   public onStatusChanged(element: any, selectedStatus: any) {
     element.selectedSubStatus = undefined;
     element.subStatusList = [];
      for(var i =0;i < this.subStatus.length;i++) {
        if(selectedStatus.statusCode == this.subStatus[i].statusCode) {
          element.subStatusList = this.subStatus[i].subStatus;
          break;
        }
      }
  
   }

  clickBack() {
    let user = this.storageService.getUser();
    let roleCode = user['roleCode'];
    if(roleCode == this.roleConfigService.ROLE_ADJUDICATION_USER) {
      this.router.navigate(['adjudication-pre-dashboard']);

    }
    if(roleCode == this.roleConfigService.ROLE_PI_USER) {
      this.router.navigate(['pi-user-dashboard']);
    }
    

  }
  public goBack(): void {
    this.location.back();
  }

  addFinalSelectedApplicants = (row: any) => {
    let applicant = row;
    console.log(applicant);
    applicant['applicantId'] = row['id'];
    this.eligibleApplicants.push(applicant);
  }

  submitAdjudicationWorBench() {
    this.eligibleApplicants = [];
    let dataSource = this.dataSource.data;
    for(let i =0;i < dataSource.length;i++) {
      if(!dataSource[i]['checked']) {
        continue;
      }
      if(!dataSource[i]['selectedStatus']) {
        this.openSnackbar("Please choose the status for application "+dataSource[i]['applicationNo'], 'Dismiss');
        return;
      }
      if(!dataSource[i]['selectedSubStatus']) {
          if(dataSource[i]['selectedStatus']['statusCode'] == 'marked_for_personal_interview') {
            this.addFinalSelectedApplicants(dataSource[i]);
            continue;
          }
          this.openSnackbar("Please choose the sub status for application "+dataSource[i]['applicationNo'], 'Dismiss');
          return;
      }
      this.addFinalSelectedApplicants(dataSource[i]);
    }
    if(this.eligibleApplicants.length == 0) {
      this.openSnackbar("Please select applicants", 'Dismiss');
      return;
    }
    this.dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: {
          title: "Confirm",
          message: "Adjudicate & Status update " +this.eligibleApplicants.length+ " Applications",
          cancelText: "CANCEL",
          confirmText: "OK"
      },
      maxHeight: "100%",
      width: "540px",
      maxWidth: "100%",
      disableClose: true,
      hasBackdrop: true
    });
    this.dialogRef.afterClosed().subscribe(data => {
      console.log(data);
      let confirmed = data;
      if(confirmed) {
        this.applicationService.updateMultipleApplicantStatus(this.eligibleApplicants).subscribe(res => {
          this.clickBack();
        })
        }
        err => {
          console.log(err);
        }
    });
  
}

commentDialogBox(element: any) {
  this.dialogReference = this.dialog.open(DecisionMakingCommentDialogBoxComponent, {
    data: {
        title: "Confirm",
        message: "Comments",
        cancelText: "CANCEL",
        confirmText: "OK"
    },
    maxHeight: "100%",
    width: "540px",
    maxWidth: "100%",
    disableClose: true,
    hasBackdrop: true
  });
  this.dialogReference.afterClosed().subscribe(data => {
      if(data.confirmed) {
        let payload = {};
      element.comment = data.comment;
      }
    });
  
}
  clear(element) {
    element.selectedStatus = null;
    element.selectedSubStatus = null;
    element.comment = null;
  }
  openSnackbar(message: string, action: string){
    this._snackBar.open(message, action);
  }
}
