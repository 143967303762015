import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-layout-formation-dashboard',
  templateUrl: './layout-formation-dashboard.component.html',
  styleUrls: ['./layout-formation-dashboard.component.scss']
})
export class LayoutFormationDashboardComponent implements OnInit {

  public comprehensiveMapUrl:string;
  selectedHeading: any;

  constructor(private router: Router, private route: ActivatedRoute) { 
    this.comprehensiveMapUrl = environment.BASE_URL+'layout_formation/layout_map/Comphrehensive.pdf';
    this.selectedHeading =  this.route.snapshot.queryParams["selectedHeading"];
  }

  ngOnInit(): void {
  }

  onNotificationDetailsClicked = ($event:any, previousHeading: any) => {
    this.router.navigate(['layout-document-list'], { queryParams: { selectedSectorCode: 'notification_details', title:'Notification Details', previousHeading: previousHeading } });
  }

  onActionPlanClicked = ($event:any, previousHeading: any) => {
    this.router.navigate(['layout-document-list'], { queryParams: { selectedSectorCode: 'action_plan', title: 'Action Plan' , previousHeading: previousHeading} });
  }
  
  onProgressTackledClicked = ($event:any, previousHeading: any) => {
    this.router.navigate(['committe-progress-tackled'], { queryParams: { selectedSectorCode: 'progress_tackled', title: 'Progress Tackled' , previousHeading: previousHeading } });
  }
  
  onSectorPackageClicked = ($event:any, previousHeading: any) => {
      this.router.navigate(['layout-sector-package-dashboard'], { queryParams: { selectedSectorCode: 'package_sector', title: 'Package Sector' , previousHeading: previousHeading} });
    }
  
    onLandHandOverStatusClicked = ($event:any, previousHeading: any) => {
      this.router.navigate(['land-hand-over-status-dashboard'], { queryParams: { selectedSectorCode: 'land_hand_over_status', title: 'Land Handover Status' , previousHeading: previousHeading} });
    }

    // onLandHandOverStatusClicked = ($event:any, previousHeading: any) => {
    //   this.router.navigate(['layout-document-list'], { queryParams: { selectedSectorCode: 'land_hand_over_status', title: 'Land Handover Status' , previousHeading: previousHeading} });
    // }

    onEEObservationsClicked = ($event:any, previousHeading: any) => {
      this.router.navigate(['dpa-ee-observation-activity-log'], { queryParams: { selectedSectorCode: 'ee_observations', title: 'EE Observations' , previousHeading: previousHeading} });
    }

}
