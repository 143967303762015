import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-other-property-details',
  templateUrl: './other-property-details.component.html',
  styleUrls: ['./other-property-details.component.scss']
})
export class OtherPropertyDetailsComponent implements OnInit {

  @Input() data : any;

  @Input() isEditable : any;

  public otherArea : number;

  public otherTotalarea : number;

  public otherPurpose : string;
  
  public siteNumber : string;

  public buildingAddress : string;

  constructor() { }

  ngOnInit(): void {
  }

  reset(){
    this.otherArea = null;
    this.otherTotalarea = null;
    this.otherPurpose = null;
  }

  getPayload(){
    let payload = {};
    payload['otherArea'] = this.otherArea;
    payload['otherTotalarea'] = this.otherTotalarea;
    payload['otherPurpose'] = this.otherPurpose;
    return payload;
  }

  ngOnChanges(data): void{
    if(!this.data){
      return;
    }
    this.otherArea = this.data.otherArea ? this.data.otherArea.toFixed(2) : this.data.otherArea;
    this.otherTotalarea = this.data.otherTotalarea ? this.data.otherTotalarea.toFixed(2) : this.data.otherTotalarea;
    this.otherPurpose = this.data.otherPurpose;
    this.siteNumber = this.data.surveyNumber;
    this.buildingAddress = this.data.buildingAddress;
   }

}
