import { DatePipe } from '@angular/common';
import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { PageEvent } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute } from '@angular/router';
import { faSync } from '@fortawesome/free-solid-svg-icons';
import { ApplicationService } from 'src/services/application.service';
import { StrorageService } from 'src/services/strorage.service';

@Component({
  selector: 'app-work-progress-activity-log',
  templateUrl: './work-progress-activity-log.component.html',
  styleUrls: ['./work-progress-activity-log.component.scss']
})
export class WorkProgressActivityLogComponent implements OnInit {

  isRefreshing: boolean;
  user: any;
  applicationNo: any;
  selectedVillageId: any;
  selectedSurveyId: any;
  sectorList : any [] = [];
  sectorId : any = '';
  mainActivityList : any [] = [];
  public pageSize =5;
  public currentPage = 0;
  status : any;
  statusList : any [] = ['ASSIGNED', 'IN_PROGRESS','TACKLED_AND_COMPLETED'];
  workTypeId : any;
  workTypeList : any [] = [];
  public totalSize: number= 0;
  public villageId: any = '';
  mainActivityId: any;
  public dapNo: any;
  public itemNo: any;
  public displayedColumns: string[] = ['sectorNo','dpaNo','itemNo',  'function','mainActivity', 'work', 'scope','extentCompletedToday',  'progressTillDate', 'unit','status','processedOn','processedBy','viewPictureVideo'];
  public dataSource = new MatTableDataSource<any>();
  public heading:string;
  public title:string = '';
  public faSync = faSync;
  public roleCode : any;


  constructor(private applicationService: ApplicationService, private storageService:StrorageService, private activeRoute: ActivatedRoute, public route: ActivatedRoute,
    private cdr:ChangeDetectorRef ) {
    this.heading = this.route.snapshot.queryParamMap.get("heading");
   }

  ngOnInit() {
    this.user=this.storageService.getUser();
    this.roleCode = this.user['roleCode'];
    this.getAllSector();
    this.getAllMainActivity();
    this.getActivityLogs();
  }

  refreshClick(){
    let currentComponent = this;
    this.isRefreshing = true;
    setTimeout(()=>{
      currentComponent.isRefreshing = false;
    }, 3000)
    this.getActivityLogs();
  }

  sectorChange = (sectorId: number) => {
    this.getActivityLogs();
  }

  getActivityLogs() {
    this.applicationService.getSectoryDailyActivityHistory(this.sectorId, this.mainActivityId, this.dapNo, this.itemNo, this.status, this.workTypeId, this.currentPage, this.pageSize).subscribe(res => {
        this.refreshDataTable(res);
    })
  }

  onMainActivityChange(){
    if(this.mainActivityId){
      this.getWorkTypeByMainActivityId();
    }
    this.getActivityLogs();
  }

  private refreshDataTable = (res: any) => {
    this.dataSource.data = res.data;
        this.totalSize = res.totalRecord;
        this.pageSize = res.recordPerPage;
        this.currentPage = (res.currentPage - 1);
        this.dataSource._updateChangeSubscription();
        if (!this.cdr['destroyed']) {
          this.cdr.detectChanges();
        }
  }

  onClear() {
    this.dapNo = null;
    this.getActivityLogs();  
  }

  onClearItemNo() {
    this.itemNo = null;
    this.getActivityLogs(); 
  }

  clickBack(){
    window.history.back();
  }

  public getApplicantPaginatorData(event: PageEvent) {
    this.currentPage = event.pageIndex + 1;
    this.pageSize = event.pageSize;
    this.getActivityLogs();
  }

  getAllSector() {
    this.applicationService.getAllSectors().subscribe(res => {
      if(res){
        this.sectorList = res;
      }
    })
  }

  getWorkTypeByMainActivityId() {
    this.applicationService.getMainActivityWorkTypes(2, this.mainActivityId).subscribe(res => {
      if(res){
        this.workTypeList = res;
      }
    })
  }


  getAllMainActivity() {
    this.applicationService.getFunctionTypeMainActivities(2).subscribe(res => {
      console.log("rses", res);
      this.mainActivityList = res;
    })
  
  }

  searchDAPNo(){
    this.resetPagination();
    this.getActivityLogs();
  }

  searchItemNo() {
    this.resetPagination();
    this.getActivityLogs();
  }

  resetPagination() {
    this.currentPage = 0;
    this.pageSize = 5;
  }

  public onGraphicalRepClicked() {
    window.open("https://public.tableau.com/views/Dr_SHIVARAMAKARANTHLAYOUT-PROGRESSMONITORINGSYSTEM/Civilwork_rsd_cumulative?:language=en-GB&publish=yes&:display_count=n&:origin=viz_share_link", "_blank");
  };
  

}
