<div fxFlex="100%" >

  <div fxLayout="row">
    <button   class="btn btn-One "  (click)="onPreviewClick()">Preview</button></div>
   <div fxLayout="row">
    <button class="btn pre-btn" (click)="activateOneTimeEdit()" *ngIf="showOneTimeEditButton" >One-Time Edit</button>
   </div>
   <div fxLayout="row">
   <button class="btn pre-btn save-changes" (click)="closeOneTimeEdit()" *ngIf="showSaveChangesBtn">Save Changes</button>
   </div>
  <div>
    <div class="col-sm-4">
      <textarea  class="form-control" 
      style=" min-width: 297%; max-width: 200%; min-height: 142px;" placeholder="Enter Comments"
      [(ngModel)]="comment"></textarea> 
  </div>
  <div class="row btn-row">
    <div class="col-sm-2 offset-sm-9">
        <button class="btn btn-next" (click)="onDone()">Done</button>
    </div>
</div>
</div>
</div>
