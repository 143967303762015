<div fxLayout="row">
    <mat-card *ngIf="data">
        <div *ngIf="showRecommendation" class="w-25 application-form-row ">
            <label class="form-label">Recommended by: </label><span class="text-danger">*</span>
            <select class="form-textbox" [disabled] = "(data && data.applicantRecommendation?.id) && !isEditable" [(ngModel)]="selectedRecommendation">
             <option *ngFor = "let recommendation of recommendations" [value]="recommendation.id">{{recommendation.recommendationName}}</option>
            </select>
        </div>
    <div class="row pre" [class.helpdesk-preview-row]="isHelpdeskUser" [class.non-helpdesk-preview-row]="!isHelpdeskUser">
    <button class="btn pre-btn" (click)="onPreviewClick()">Preview</button>
    <button class="btn pre-btn" (click)="goDetailsPage(data)" *ngIf="isEnclosureShown">Enclosure</button>
    <button class="btn pre-btn one-time-editable" (click)="activateOneTimeEdit()" *ngIf="showOneTimeEditButton">One-Time Edit</button>
    <button class="btn pre-btn save-changes" (click)="closeOneTimeEdit()" *ngIf="showSaveChangesBtn">Save Changes</button>
    </div>
        <div class="row details-area">
            <div>
                <label class="info-key">JCC No :</label>
                <label class="info-value">{{data ? data.applicationNo : ''}}</label>
            </div>
            <div>
                <label class="info-key">Name :</label> 
                <label class="info-value">{{data ? data.applicantName : ''}}</label> 
            </div>
            <div>
                <label class="info-key">Mobile No :</label> 
                <label class="info-value">{{data ? data.mobileNumber : ''}}</label> 
            </div>
            <div>
                <label class="info-key">Survey/Site No :</label> 
                <label class="info-value">{{data ? data.surveyNumber : ''}}</label> 
            </div>
            <div>
                <label class="info-key">Village Panchayat :</label>  
                <label class="info-value">{{panchayat}}</label>
            </div>
            <div>
                <label class="info-key">Village Name :</label> 
                <label class="info-value">{{village}}</label> 
            </div>
        </div>
        <div class="row">
            <div class="checkbox-grid">
                <div *ngFor="let enclosure of enclosures; let i = index;" class="checkbox">
                    <mat-checkbox [checked]="enclosure.link != null" disabled><span class="acko-name">{{i+1}}. {{enclosure.documentName}}</span></mat-checkbox>
                </div>
            </div>    
        </div>
        <div class="row btn-row">
                <div class="col-sm-2 offset-sm-9">
                    <button class="btn btn-next" (click)="onDone()">Done</button>
                </div>
        </div>
    </mat-card>
</div>


