import { Component, HostListener, ViewChild } from '@angular/core';
import { TopBarComponent } from './top-bar/top-bar.component';
import { TranslateService } from '@ngx-translate/core';
import { StrorageService } from 'src/services/strorage.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {

  title = 'supreme-court-committee';

  constructor() {

  }

}
