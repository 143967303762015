<div class="container">
    <div>
        <app-top-bar selectedTab="home"></app-top-bar>
        <app-header></app-header>
    </div>
    <br>

    <div class="tool-bar">
    <div>
        <button mat-button class="btn btn-primary"(click)="clickBack()">Back</button>
    </div>
        <div class="col-sm-5 title ">
            RC Data Management Module - RC Prep
        </div>
    </div>
        <div class="tool-bar">
            <div>
                <button mat-button class=" btn btn-primary" (click)="showActivityLog()" style="margin-top: 30px;">Activity Log</button>
            </div>
            <div class="col-sm-2 offset-sm-8">
                <mat-form-field appearance="fill">
                <mat-label>Village</mat-label>
                <mat-select id="village" name="villageId" [(ngModel)]="villageId">
                <mat-option *ngFor="let village of villages" [value]="village.id" (click)="onVillageChange()">
                {{village.id}} {{village.name}}
                </mat-option>
                </mat-select>
                </mat-form-field>
                <div class="p1">
                Total: {{totalSize}}
                </div>
            </div>
        <div class="col-sm-1 ">
        </div>
    </div>
    <div class="tool-bar" style="margin-top: 15px;">
        <div class="col-sm-3" style="margin-left: -23px;">
            <div fxLayout="row" fxLayoutAlign="space-around">
           <input class="form-control border-end-0 border rounded-pill" style="font-size: 14px !important;"type="text" [(ngModel)]="applicationNo" placeholder="Search" id="applicationNo">
           <span class="input-group-append"><br>&nbsp;
            <button mat-button class="btn btn-primary " (click)="searchApplicant()">Search</button>
            <button mat-button *ngIf="applicationNo" matSuffix mat-icon-button aria-label="Clear" (click)="onClear()">
               <mat-icon>close</mat-icon>
             </button>
           </span>
           </div>
        </div>
        <div class="col-sm-3" style="margin-top: 6px;">
            <label class="form-label">Structure Type:</label>
            <select class="form-textbox" [(ngModel)] = "selectedStructureType"(change)="onStructureTypeChange(selectedStructureType)">
                <option *ngFor = "let structureType of structureTypes" [value]="structureType.id" >{{structureType.structureType}}</option>
           </select>
        </div>
    </div>
    <div class="tool-bar" style="margin-top: 15px;">
        <div class="p2">
            <mat-form-field appearance="fill">
                <mat-label>Status</mat-label>
                <mat-select id="status" name="statusId" [(ngModel)]="statusCode">
                  <mat-option *ngFor="let status of statuses" [value]="status.statusCode" (click)="onStatusChange()">
                  {{status.sortOrder}} {{status.status}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
        </div>
        <div class="col-sm-3" style="margin-top: 6px;">
            <label class="form-label">Annexure : </label>
            <select class="form-textbox" [(ngModel)] = "selectedAnnexureName"(change)="onAnnexureNameChange(selectedAnnexureName)">
                <option *ngFor = "let annexure of annexureNames" [value]="annexure.annexureName" >{{annexure.label}}</option>
           </select>
        </div>
    </div>
            <!-- data table -->
            <mat-card>
                <table mat-table [dataSource]="dataSource">           
                <ng-container  matColumnDef="all">
                    <th mat-header-cell *matHeaderCellDef  > Select All </th>
                    <td mat-cell *matCellDef="let element" class="column-container">
                        <mat-checkbox labelPosition="before" [(ngModel)]="element.checked" 
                        [disabled]="element.activeStatus != 'cd_done'" (change)='selectOrDeSelectApplicants($event, element)'></mat-checkbox>
                    </td>
                </ng-container>

                <ng-container matColumnDef="groupNumber">
                    <th mat-header-cell *matHeaderCellDef>Group Number </th>
                    <td mat-cell *matCellDef="let element">
                      {{element.groupNumber}}
                    </td>
                </ng-container>

                    <!-- Group Size Column -->
                    <ng-container matColumnDef="groupSize">
                        <th mat-header-cell *matHeaderCellDef> Group Size </th>
                        <td mat-cell *matCellDef="let element">
                            {{element.groupSize}}
                        </td>
                    </ng-container>

                    <!-- Jcc No Column -->
                    <ng-container matColumnDef="jccNo">
                        <th mat-header-cell *matHeaderCellDef> Jcc No </th>
                        <td mat-cell *matCellDef="let element"> {{element.applicationNo}} </td>
                    </ng-container>

                    <ng-container matColumnDef="notificationType">
                        <th mat-header-cell *matHeaderCellDef> Notification Type </th>
                        <td mat-cell *matCellDef="let element"> 
                            <div  [ngClass]="{
                                'positive' : element.notificationType == 'NEW',
                                'negative' : element.notificationType == 'OLD' }">
                            {{element.notificationType}} 
                        </div>
                         </td>
                    </ng-container>

                    <!-- Applicant Name Column -->
                    <ng-container matColumnDef="applicantName">
                        <th mat-header-cell *matHeaderCellDef> Applicant Name </th>
                        <td mat-cell *matCellDef="let element"> {{element.applicantName}} </td>
                    </ng-container>

                     <!-- Possible CD Error Column -->
                     <ng-container matColumnDef="possibleCdError">
                        <th mat-header-cell *matHeaderCellDef> Possible CD Error </th>
                        <td mat-cell *matCellDef="let element" >
                            <div  *ngIf="element.activeStatus == 'cd_done'"  [ngClass]="{
                                'positive' : element.possibleCdError == false,
                                'negative' : element.possibleCdError == true }">
                            {{element.possibleCdError ? "Yes" :"No"}} 
                        </div>
                        <div  *ngIf="!element.possibleCdError" >
                            {{''}} 
                        </div>
                        </td>
                    </ng-container>

                    <!-- Active Status Column -->
                    <ng-container matColumnDef="activeStatus">
                        <th mat-header-cell *matHeaderCellDef> Active Status </th>
                        <td mat-cell *matCellDef="let element"> {{element.status}} </td>
                    </ng-container>

                    <ng-container matColumnDef="annexure">
                        <th mat-header-cell *matHeaderCellDef> Annexure </th>
                        <td mat-cell *matCellDef="let element"> {{element.annexureName ? element.annexureName : "N/A"}} </td>
                    </ng-container>

                    <ng-container matColumnDef="slNo">
                        <th mat-header-cell *matHeaderCellDef> Sl.No (SC Approved List) </th>
                        <td mat-cell *matCellDef="let element"> {{element.scApprovalNo ? element.scApprovalNo : "N/A"}} </td>
                    </ng-container>

                    <ng-container matColumnDef="reportDate">
                        <th mat-header-cell *matHeaderCellDef> Report Date </th>
                        <td mat-cell *matCellDef="let element"> {{element.approvalDate ? element.approvalDate : "N/A"}} </td>
                    </ng-container>

                    <!-- Structure Type Column -->
                    <ng-container matColumnDef="structureType">
                        <th mat-header-cell *matHeaderCellDef> Structure Type </th>
                        <td mat-cell *matCellDef="let element"> {{element.structureType ? element.structureType : "N/A"}} </td>
                    </ng-container>

                     <!-- CD Processed By Type Column -->
                    <ng-container matColumnDef="cdProcessedBy">
                        <th mat-header-cell *matHeaderCellDef> CD Processed By </th>
                        <td mat-cell *matCellDef="let element"> {{element.cdProcessedBy ? element.cdProcessedBy : "N/A"}} </td>
                    </ng-container>

                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns;" ></tr>
                </table>

                <div class="no-records-text" *ngIf="!dataSource.data || dataSource.data.length <= 0">No Records Found!</div>
                <mat-paginator [pageSize]="pageSize" [pageSizeOptions]="[5, 10, 20]" [showFirstLastButtons]="true"
                [pageIndex]="currentPage" [length]="totalSize" (page)="getApplicantPaginatorData($event)">
            </mat-paginator>
            </mat-card>
        <div class="row application-form-row ">
            <label class="form-label label">No of Applications to be moved to Data Validation : </label>
            <input type="number" [(ngModel)]="selectedApplicantsSize" class="form-textboxx box-align" readonly/>
        </div>
        <div class="col-sm-4 offset-sm-5">
            <button class="btn btn-primary submit-btn" (click)="onSubmit()">
                Submit
            </button>
        </div>
 <app-footer></app-footer>
 </div>

