import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { ApplicationService } from 'src/services/application.service';


@Component({
  selector: 'app-sector-village-dialog',
  templateUrl: './sector-village-dialog.component.html',
  styleUrls: ['./sector-village-dialog.component.scss']
})
export class SectorVillageDialogComponent implements OnInit {

  public faTimes = faTimes;
  public sector: any;
  public selectedSurveyItems:any [] = [];
  public selectedVillage: any;
  public villages:any[]=[];
  public surveyNo:any = '';
  public surveyNoDataList:any[] = [];
  public approxArea:any='';

  constructor(private applicationService: ApplicationService,public dialogRef: MatDialogRef<SectorVillageDialogComponent> ,
     @Inject(MAT_DIALOG_DATA, ) public data) {
      console.log("sector dataa",data);
      this.sector = data.sectorData;
  }

  ngOnInit() {
    this.getVillageList();
  }

  close() {
    this.dialogRef.close();
  }

  cancelClick(){
    this.close();
  }

  getVillageList = () => {
    this.applicationService.getLayoutFormationVillageList(this.sector.sectorId).subscribe(res => {
      console.log("rses", res);
      this.villages = res;
    })
  }

  filterVillages = (res:any[]) => {
    let lVillages:any[] = [];
    for(let i =0;i < res.length;i++) {
      if(this.sector.villages) {
        let isNewVillage:boolean = true;
        for(let j =0;j < this.sector.villages.length;j++) {
          if(this.sector.villages[j].selectedVillage.villageId == res[i].villageId) {
            isNewVillage = false;
            break;
          }
        }
        if(isNewVillage) {
          lVillages.push(res[i]);
        }
      } else {
        lVillages.push(res[i]);
      }
    }
    return lVillages;
  }

  onVillageChange = (villageId: number) => {
    this.applicationService.getLayoutFormationSurveyList(this.sector.sectorId, villageId, this.sector.selectedFunctionId, this.sector.selectedMainActivityId, this.sector.selectedWorkTypeId).subscribe(res => {
      this.surveyNoDataList = res;
    })
  }

  onSurveyNumberChange = (e:any, item: any) => {
    if(e.checked) {
      if(this.selectedSurveyItems.length == 0) {
        this.selectedSurveyItems.push(item);
      } else {
        let alreadyExist:boolean = false;
        for(let i =0;i < this.selectedSurveyItems.length;i++) {
          if(this.selectedSurveyItems[i].surveyId == item.surveyId) {
            alreadyExist = true;
            break;
          }
        }
        if(!alreadyExist) {
          this.selectedSurveyItems.push(item);
        }
      }
    } else {
      let alreadyExistIndex:number = -1;
      for(let i =0;i < this.selectedSurveyItems.length;i++) {
        if(this.selectedSurveyItems[i].surveyId == item.surveyId) {
          alreadyExistIndex = i;
          break;
        }
      }
      if(alreadyExistIndex != -1) {
        this.selectedSurveyItems.splice(alreadyExistIndex, 1);
      }
    }
    let surveyNos:string='';
    for(let i =0;i < this.selectedSurveyItems.length;i++) {
      if(surveyNos == '') {
        surveyNos = this.selectedSurveyItems[i].surveyNo;
      } else {
        surveyNos = surveyNos + "," + this.selectedSurveyItems[i].surveyNo;
      }
    }
    this.surveyNo = surveyNos;
  }

  onSave() {
    let result:any = {};
    result.selectedSurveyItems = this.selectedSurveyItems;
    result.approxArea = this.approxArea;
    result.selectedVillage = this.selectedVillage;
    result.surveyNo = this.surveyNo;
    this.payloadValidation(result);
    let data:any = {};
    data.row = result;
    data.sectorRowindex = this.data.sectorRowIndex;
    this.dialogRef.close(data);
  }

  payloadValidation(data:any) {
    console.log(data);
    if(!data.selectedVillage) {
      throw new Error('Village must not be empty');
    }
    if(data.selectedSurveyItems.length == 0) {
      throw new Error('Survey Nos must not be empty');
    }
    if(!data.approxArea) {
      throw new Error('Approx Area must not be empty');
    }
  }

}
