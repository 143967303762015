<div class="container">
  <section>
    <div class="title" *ngIf="roleCode == 'comittee_user'">
      <h1 class="heading-format font-fam">Daily Updates from the field – COMMITEE Dashboard</h1>
    </div>
    <div class="title" *ngIf="roleCode == 'secon_user'">
      <h1 class="heading-format font-fam">Daily Updates from the field – SECON Dashboard</h1>
    </div>
    <div class="col-sm-12 my-5">
      <div class="d-flex justify-content-around mb-3">
        <div class="col-sm-6 d-flex justify-content-around">
          <div *ngIf="roleCode == 'dpa_user'" style="width: 75%;">
            <button mat-button class="w-50 prep-activities" (click)="prepActivity()">Prep Activities</button>
          </div>
          <div *ngIf="roleCode != 'dpa_user'">
            <button mat-button class="prep-activities" (click)="prepActivity()">Prep Activities</button>
          </div>
          <div *ngIf="roleCode == 'dpa_user'" style="width: 75%;">
            <button mat-button class="w-50 civil-work" (click)="civilWork()">Civil Work</button>
          </div>
        </div>
        <div class="col-sm-6 d-flex justify-content-around">
          <div *ngIf="roleCode != 'dpa_user'">
            <button mat-button class="civil-work" (click)="civilWork()">Civil Work</button>
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="showCivilWorkOptions">
      <div class="row justify-content-between mt-2">
        <div class="col-6 title">
          <div class="p1">
            <mat-form-field class="w-50" appearance="fill">
              <mat-label>Village</mat-label>
              <mat-select id="status" name="selectedStatus" [(ngModel)]="villageId"
                (selectionChange)="onVillageChange($event.value)">
                <mat-option value="">All</mat-option>
                <mat-option *ngFor="let village of villageList" [value]="village.id">
                  {{village.villageName}}</mat-option>
              </mat-select>
            </mat-form-field>

          </div>
        </div>
        <div class="col-6 title">
          <div class="p1">
            <mat-form-field class="w-50" appearance="fill">
              <mat-label>Sy. No</mat-label>
              <mat-select id="status" name="selectedStatus" [(ngModel)]="surveyId"
                (selectionChange)="onSurveyChange($event.value)">
                <mat-option *ngFor="let syNo of syNoList" [value]="syNo.id">
                  {{syNo.surveyNo}}</mat-option>
              </mat-select>

            </mat-form-field>

          </div>
        </div>
      </div>



      <div class="col-sm-12 title">
        Tackled & Completed
      </div>



      <!-- data table -->
      <mat-card class="dp-table">
        <table mat-table [dataSource]="dataSource">
          <!-- SI No Column -->
          <ng-container matColumnDef="siNo">
            <th mat-header-cell *matHeaderCellDef> SI.No </th>
            <td mat-cell *matCellDef="let element ;let i =index;" class="column-container">
              {{i+1}}
            </td>
          </ng-container>

          <!-- DAP No Column -->
          <ng-container matColumnDef="dapNo">
            <th mat-header-cell *matHeaderCellDef> DAP No </th>
            <td mat-cell *matCellDef="let element ;let i =index;" class="column-container">
              {{element.dapNo}}</td>
          </ng-container>

          <!-- Village Column -->
          <ng-container matColumnDef="village">
            <th mat-header-cell *matHeaderCellDef> Village </th>
            <td mat-cell *matCellDef="let element"> {{element.village}} </td>
          </ng-container>

          <!-- Sy. No Column -->
          <ng-container matColumnDef="syNo">
            <th mat-header-cell *matHeaderCellDef> Sy. No </th>
            <td mat-cell *matCellDef="let element ;let i =index;" class="column-container">{{element.syNo}}</td>
          </ng-container>

          <!-- Main Column -->
          <ng-container matColumnDef="function">
            <th mat-header-cell *matHeaderCellDef> Main Activity </th>
            <td mat-cell *matCellDef="let element"> {{element.mainActivity}} </td>
          </ng-container>

          <!-- Function Column -->
          <ng-container matColumnDef="mainActivity">
            <th mat-header-cell *matHeaderCellDef> Function </th>
            <td mat-cell *matCellDef="let element"> {{element.function}} </td>
          </ng-container>

          <!-- Work Column -->
          <ng-container matColumnDef="work">
            <th mat-header-cell *matHeaderCellDef> Work </th>
            <td mat-cell *matCellDef="let element">{{element.work}} </td>
          </ng-container>

          <!-- Created On Column -->
          <ng-container matColumnDef="createdOn">
            <th mat-header-cell *matHeaderCellDef> Execution Date </th>
            <td mat-cell *matCellDef="let element" class="column-container">{{element.createdOn}}</td>
          </ng-container>

          <!-- Created By Column -->
          <ng-container matColumnDef="contractorName">
            <th mat-header-cell *matHeaderCellDef> Contractor Name </th>
            <td mat-cell *matCellDef="let element" class="column-container">{{element.contractorName}}</td>
          </ng-container>

          <!-- Created By Column -->
          <ng-container matColumnDef="viewImageVideo">
            <th mat-header-cell *matHeaderCellDef> View Image/Video </th>
            <td mat-cell *matCellDef="let element" class="column-container">
              <a href='{{element.filePath}}' target="_blank">
                <span *ngIf="element.fileType =='image'">View Image</span>
                <span *ngIf="element.fileType =='video'">View Video</span>
              </a>
            </td>
          </ng-container>
          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>
        <div class="no-records-text" *ngIf="!dataSource.data || dataSource.data.length <= 0">No Records Found!</div>
        <mat-paginator #paginatorRef [pageSize]="pageSize" [pageSizeOptions]="[5, 10, 20]" [showFirstLastButtons]="true"
          [pageIndex]="currentPage" [length]="totalSize" (page)="getApplicantPaginatorData($event)">
        </mat-paginator>
      </mat-card>
    </div>
  </section>
</div>