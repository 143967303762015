import { Component, Input, OnInit, Output, EventEmitter, ChangeDetectorRef } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { Router, ActivatedRoute } from '@angular/router';
import { ApplicantEnclosureListComponent } from 'src/app/applicant-enclosure-list/applicant-enclosure-list.component';
import { PreviewDialogComponent } from 'src/app/preview-dialog/preview-dialog.component';
import { RoleConfigService } from 'src/app/shared/config/role/role.config.service';
import { ApplicationService } from 'src/services/application.service';
import { UserService } from 'src/services/user-service/user.service';
import { forkJoin } from 'rxjs';
import { map } from 'rxjs/operators';
import { JccnoEncodeDecodeService } from 'src/services/jccno-encode-decode/jccno-encode-decode.service';
import { StrorageService } from 'src/services/strorage.service';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-acknowledgement',
  templateUrl: './acknowledgement.component.html',
  styleUrls: ['./acknowledgement.component.scss']
})
export class AcknowledgementComponent implements OnInit {

public enclosures= [];
public recommendations: any [] = [];
public selectedRecommendation: any;
showRecommendation: boolean = false;
public data: any = undefined;
public village: any;
public panchayat: any;
public isEnclosureShown :any = true;
public isHelpdeskUser: any;
public isEditable = false;
public oneTimeEdit = false;
public showOneTimeEditButton = false;
public showSaveChangesBtn = false;
private applicantId: any;
private applicantNumber: any;
  notificationType: string;

@Output() oneTimeEditCallback = new EventEmitter<any>();

@Output() saveChangesCallback = new EventEmitter<any>();

@Output() doneCallback = new EventEmitter<any>();

recommendationName: any;

  constructor(private applicationService : ApplicationService, 
    private route: ActivatedRoute, private jccEncodeDecoder: JccnoEncodeDecodeService,
    private router : Router, public dialog: MatDialog, 
    public userService: UserService,private roleConfig:RoleConfigService, 
    private cdr:ChangeDetectorRef, private storageService: StrorageService, 
    private _snackBar: MatSnackBar) {
      this.notificationType = this.jccEncodeDecoder.decode(this.storageService.getNotificationType());
      if (this.notificationType == 'OLD'){
        this.showRecommendation = true;
      }
     }

  ngOnInit(): void {
    let user = this.storageService.getUser();
    //this.isEnclosureShown = (user['roleCode'] == this.roleConfig.ROLE_ONLINE_APPLICATION_USER);
    this.isHelpdeskUser = (user['roleCode'] == this.roleConfig.ROLE_HELP_DESK_USER);
    this.fetchRecommendations();
   let encodedNo = this.route.snapshot.queryParams["applicationNo"];
    if(encodedNo) {
      this.applicantNumber = this.jccEncodeDecoder.decode(this.route.snapshot.queryParams["applicationNo"]);
      this.fetchApplicantData(this.applicantNumber);
    } else {
      this.applicantId = window.localStorage.getItem("applicantId");
      this.fetchApplicantById(this.applicantId);
    }
  }

  private resetUI = (applicantData: any) => {
    if(applicantData['oneTimeEdit'] == null) {
      this.showOneTimeEditButton = true;
    }
    if(applicantData['oneTimeEdit']) {
      this.showOneTimeEditButton = false;
      this.showSaveChangesBtn = true;
    }
  }

  private fetchApplicantById = (applicantId: any) => {
    if(!applicantId) {
      return;
    }
    this.applicationService.fetchApplicantById(applicantId).subscribe(res =>{
        this.applicantNumber = res.applicationNo;
        this.applicantId = res.id;
        this.data = res;
        this.selectedRecommendation = res.recommendationName;
        this.updateUI(this.data);
        this.setEditable(res);
        this.fetchEnclosureData(res.id);
        this.resetUI(res);
    })
  }


  private fetchRecommendations = () => {
    this.applicationService.getRecommendation().subscribe(res =>{
        this.recommendations = res;
    })
  }
  
  fetchEnclosureData = (applicationId: any) => {
    this.applicationService.getApplicantEnclosureList(applicationId).subscribe(res =>{
      const documents = [];
      for (const [key, value] of Object.entries(res)) {
        let document = {};
        document['documentName'] = key;
        document['link'] = value;
        documents.push(document);
      }
       this.enclosures  = documents;
       console.log(' t', this.enclosures)
    })
  }

  private updateUI = (data: any) => {
    if(data && data.villageDTO){
      this.village = data.villageDTO.name;
    }
    if(data && data.villageDTO && data.villageDTO.panchayatDTO){
      this.village = data.villageDTO.name;
      this.panchayat = data.villageDTO.panchayatDTO.name;

    }
    if(data && data.applicantRecommendation){
      this.selectedRecommendation = data.applicantRecommendation.id;
    }else{
      this.selectedRecommendation = "";
    }
  }

  fetchApplicantData(applicationNo: any){
     this.applicationService.fetchApplicantByApplicationNo(applicationNo).subscribe(res =>{
        this.applicantId = res.id;
        this.data = res;
        this.updateUI(this.data);
        this.setEditable(res);
        this.fetchEnclosureData(res.id);
        this.resetUI(res);
     })
  }

  public goDetailsPage(data: any) {
    this.openEnclosure(data);
  }

  openEnclosure = (data: any): void => {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = false;
    dialogConfig.autoFocus = true;
    dialogConfig.height = '80vh';
    dialogConfig.width = '50vw';
    dialogConfig.data =data.id;
    const dialogRef = this.dialog.open(ApplicantEnclosureListComponent,dialogConfig);
    dialogRef.afterClosed().subscribe(res =>{
       
    })
  }

  onPreviewClick = () => {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = false;
    dialogConfig.autoFocus = true;
    dialogConfig.height = '80vh';
    dialogConfig.width = '50vw';
    dialogConfig.data = this.applicantNumber;
    const dialogRef = this.dialog.open(PreviewDialogComponent,dialogConfig);
    dialogRef.afterClosed().subscribe(res =>{
    })
  }

  goToBasicDetailsScreen = () => {
    this.doneCallback.emit('basic_details');
  }

  onDone = () => {
      if(this.data['oneTimeEdit']) {
      this.deActivateOneTimeEdit();
    } else {
      if(this.notificationType == 'NEW') {
        this.routeToHomePage();
      } else {
        this.updateOldApplicantRecommandation();
      }
    }
  }


  public updateOldApplicantRecommandation = () => {
    if(this.notificationType == 'OLD' && !this.isEditable) {
      if(this.selectedRecommendation == "" || this.selectedRecommendation == null) {
         this.openSnackbar("Choose recommended by");
      }else {
      this.applicationService.updateAppliantRecommendation(this.applicantId, this.selectedRecommendation).subscribe(res =>{
        this.routeToHomePage();
      });
    }
    } else {
      this.routeToHomePage();
    }
  }

  private routeToHomePage = (): void => {
    let user = this.storageService.getUser();
    if(user['roleCode'] == this.roleConfig.ROLE_ONLINE_APPLICATION_USER) {
      this.router.navigate(['/application']);
    } else {
      window.scroll(0,0);
      if(user['roleCode'] == this.roleConfig.ROLE_ADMIN_USER) {
        this.router.navigate(['/admin']);
      } else {
        //this.router.navigate(['/application']);
        this.goToBasicDetailsScreen();
      }
    }
  }

  public closeOneTimeEdit = (): void => {
    if(this.notificationType == 'OLD'){
      this.applicationService.updateAppliantRecommendation(this.applicantId, this.selectedRecommendation).subscribe(res =>{
        this.closeApplicantOneTimeEdit();
      });
    } else {
      this.closeApplicantOneTimeEdit();
    }
  }

  closeApplicantOneTimeEdit = () => {
    this.applicationService.closeApplicantOneTimeEdit(this.applicantId).subscribe(res =>{
      this.openSnackbar("Successfully closed one time edit");
      this.saveChangesCallback.emit('basic_details');
      this.isEditable = false;
    }, err => {
      console.log('close one time edit error', err);
    })
  }

  openSnackbar = (message: string, action?: string) => {
    if(!action) {
      action = "Dismiss";
    }
    this._snackBar.open(message, action);
  }

  private setEditable = (data: any): void => {
    if(data['oneTimeEdit'] != null) {
      this.isEditable = data['oneTimeEdit'];
    }
  }

  public activateOneTimeEdit = () => {
    this.applicationService.activateApplicantOneTimeEdit(this.applicantId).subscribe(res =>{
      this.isEditable = true;
      this.resetUI(res);
      this.openSnackbar("Successfully activated");
      this.oneTimeEditCallback.emit();
    }, err => {
      console.log('activate one time edit error', err);
    })
 }

 public deActivateOneTimeEdit = () => {
    this.applicationService.deActivateApplicantOneTimeEdit(this.applicantId).subscribe(res =>{
      this.isEditable = false;
      this.openSnackbar("Successfully deactivated");
      this.routeToHomePage();
    }, err => {
      console.log('activate one time edit error', err);
    })
  }
}

