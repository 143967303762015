import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { ApplicationService } from 'src/services/application.service';
import { Subscription } from 'rxjs';
import { ConfirmationModalComponent } from 'src/app/confirmation-modal/confirmation-modal.component';
import { MatDialogConfig, MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { JccnoEncodeDecodeService } from 'src/services/jccno-encode-decode/jccno-encode-decode.service';
import { ActivatedRoute } from '@angular/router';
import { StrorageService } from 'src/services/strorage.service';

@Component({
  selector: 'app-personal-details',
  templateUrl: './personal-details.component.html',
  styleUrls: ['./personal-details.component.scss']
})
export class PersonalDetailsComponent implements OnInit {

  public data : any;

  public isEditable: any;

  public menus: any = [
    {
      name:'Personal Details', 
      type: 'link',
      active: true,
      code: 'personal_details'
    }
  ];

  public applicantName : string; 

  public telephoneNumber : number;

  public mobileNumber : number;

  public email : string;

  public surveyNumber : string;

  public aadharNumber : number;

  public villages : any[];

  public selectedVillage;

  public applicantAddress : string;
  
  public selectedMenu: any = {};

  private applicantId: any;

  private isOneTimeEdit: boolean = false;

  @Output() newApplicantCallback = new EventEmitter<any>();

  @Output() nextScreenCallback = new EventEmitter<any>();
  notificationType: any;
  
  constructor(private applicationService: ApplicationService,
     public dialog: MatDialog,
     private _snackBar: MatSnackBar, 
     private route: ActivatedRoute, 
     private jccEncodeDecoder: JccnoEncodeDecodeService,
     private storageService: StrorageService) { }
  
  ngOnInit(): void {
    this.selectedMenu = this.menus[0];
    this.getAllVillages();
    let notificationType = this.storageService.getNotificationType();
    this.notificationType = this.jccEncodeDecoder.decode(notificationType);
    let encodedNo = this.route.snapshot.queryParams["applicationNo"];
    if(encodedNo) {
      let applicantNo = this.jccEncodeDecoder.decode(this.route.snapshot.queryParams["applicationNo"]);
      this.fetchApplicationData(applicantNo);
    } else {
      this.applicantId = window.localStorage.getItem("applicantId");
      this.fetchApplicantById(this.applicantId);
    }
  }

  private fetchApplicantById = (applicantId: any) => {
    if(!applicantId) {
      return;
    }
    this.applicationService.fetchApplicantById(applicantId).subscribe(res =>{
      this.applicantId = res.id;
      this.setEditable(res);
      this.data = res;
      this.setValue(res);
      this.newApplicantCallback.emit(res);
    })
  }

  getAllVillages(){
    this.applicationService.fetchAllVillages().subscribe(res =>{
      this.villages = res;
    }, err=> {
      console.log("personal details component getVillages() err", err);
    }, () => {
        console.log("personal details component getVillages() completed");
    })
  }

  fetchApplicationData = (applicantNo: any) => {
    this.applicationService.fetchApplicantByApplicationNo(applicantNo).subscribe(res=>{
        console.log("basic personal details", res);
        this.applicantId = res.id;
        this.setEditable(res);
        this.data = res;
        this.setValue(res);
        this.newApplicantCallback.emit(res);
    })
   }

  reset(){
    this.applicantName = null;
    this.telephoneNumber = null;
    this.surveyNumber = null;
    this.aadharNumber = null;
    this.selectedVillage = null;
    this.applicantAddress = null;
    this.mobileNumber = null;
    this.email = null;
  }

  validateAndConstructPayload = () => {
      if(!this.applicantName){
        throw new Error('Applicant name field must not be empty');
      }
      if(!this.mobileNumber){
        throw new Error('Mobile number field must not be empty');
      }
      if(this.mobileNumber> 9999999999 || this.mobileNumber<1000000000){
        throw new Error('Please enter a valid mobile number');
      }
      if(!this.surveyNumber){
        throw new Error('Survey Number field must not be empty');
      }
      if(!this.selectedVillage){
        throw new Error('Village field must not be empty');
      }
      if(!this.applicantAddress){
        throw new Error('Applicant address field must not be empty');
      }
      if(!this.notificationType){
        throw new Error('Notification type must not be empty');
      }
      let payload = {};
      payload['applicantName'] = this.applicantName;
      payload['telephoneNumber'] = this.telephoneNumber;
      payload['surveyNumber'] = this.surveyNumber;
      payload['aadharNumber'] = this.aadharNumber;
      payload['villageId'] = this.selectedVillage ? Number(this.selectedVillage) : 10;
      payload['applicantAddress'] = this.applicantAddress;
      payload['mobileNumber'] = this.mobileNumber;
      payload['email'] = this.email;
      payload['notificationType'] = this.notificationType;
      return payload;
  }

  setValue(data: any): void{
    console.log('data ', data)
    this.applicantName = data.applicantName;
    this.telephoneNumber = data.telephoneNumber;
    this.surveyNumber = data.surveyNumber;
    this.aadharNumber = data.aadharNumber;
    this.selectedVillage = data.villageId;
    this.applicantAddress = data.applicantAddress;
    this.mobileNumber = data.mobileNumber;
    this.email = data.email;
  }

  public onItemClicked = (event: any) => {
    this.selectedMenu = event;
  }

  onSubmit = () => {
    this.openDialog();
  }

  openSnackbar(message: string, action?: string){
    if(!action) {
      action = "Dismiss";
    }
    this._snackBar.open(message, action);
  }

  openDialog = () => {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = false;
    dialogConfig.autoFocus = true;
    dialogConfig.height = '160px';
    dialogConfig.width = '360px';
    dialogConfig.data = "Are you sure want to submit this record ?"
    const dialogRef = this.dialog.open(ConfirmationModalComponent,dialogConfig);
    dialogRef.afterClosed().subscribe(res =>{
        if(res){
          if(this.isOneTimeEdit) {
            this.updateApplicantBasicDetailsApiCall(this.applicantId);
            return;
          }
            this.saveApplicantBasicDetailsApiCall();
        }
    })
  }

  saveApplicantBasicDetailsApiCall = () => {
    try{
      let payload  = this.validateAndConstructPayload();
      this.applicationService.saveApplication(payload).subscribe(res =>{
        window.localStorage.setItem("applicantId", res.id);
        this.applicantId = res.id; // same time they can fill missing field without moving next screen
        this.data = res; // for one time edit
        this.setEditable(res);
        this.openSnackbar("Successfully Saved", "Dismiss");
        this.newApplicantCallback.emit(res);
      }, err=>{
          console.log(err);
          this.openSnackbar(err?.message, "Dismiss");
      })
    } catch(e){
      this.openSnackbar(e, "Dismiss");
    }
  }

  private setEditable = (data: any): void => {
    if(data['oneTimeEdit'] != null) {
      this.isEditable = data['oneTimeEdit'];
      this.isOneTimeEdit = data['oneTimeEdit'];
    }
  }

  updateApplicantBasicDetailsApiCall = (applicantId: any) => {
    try{
      let payload  = this.validateAndConstructPayload();
      this.applicationService.saveOneTimeEditBasicDetails(applicantId, payload).subscribe(res =>{
        this.data = res; // for one time edit
        this.setEditable(res);
        this.openSnackbar("Successfully Updated", "Dismiss");
        this.newApplicantCallback.emit(res);
      }, err => {
          console.log(err.error.message);
          this.openSnackbar(err.error.message, "Dismiss");
      })
    } catch(e){
      this.openSnackbar(e, "Dismiss");
    }
  }
}
