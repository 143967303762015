<div class="label col-sm-10"  *ngIf = "data">
      
    <div class ="col-sm-11 text-title"  >JCC NO:{{data.applicationNo}} </div>
    <div >
        <button (click)="clickBack()"class="back-link">Back</button>
     </div>
   <div>
    <button  (click) ="encloPage()" class="enclosure-link">Enclosure </button> 
</div> 
<br>
<br>
<br>
<div class="sub-title">
       Personal Details
    </div>
    
    <div class="row" >
        <div class="col-sm-8 " >
            <label >Name of the applicant</label>
        </div>
        <div class="col-sm-4">
            <label >{{data.applicantName}}</label>
          </div>
    </div>
    <div class="row" >
        <div class="col-sm-8 " >
            <label >Name of the Village</label>
        </div>
        <div class="col-sm-4">
            <label >{{data.villageDTO.name}}</label>
          </div>
    </div>
   
    <div class="row" >
        <div class="col-sm-8 " >
            <label >Address of the applicant</label>
        </div>
        <div class="col-sm-4">
            <label >{{data.applicantAddress}}</label>
          </div>
    </div>
    <div class="row" >
        <div class="col-sm-8 " >
            <label >Landline Number</label>
        </div>
        <div class="col-sm-4">
            <label >{{data.telephoneNumber}}</label>
          </div>
    </div>
    <div class="row" >
        <div class="col-sm-8 " >
            <label >Mobile Number</label>
        </div>
        <div class="col-sm-4">
            <label >{{data.mobileNumber}}</label>
          </div>
    </div>
    <div class="row" >
        <div class="col-sm-8 " >
            <label >Email</label>
        </div>
        <div class="col-sm-4">
            <label >{{data.email}}</label>
          </div>
    </div>
    <div class="row" >
        <div class="col-sm-8 " >
            <label >Survey Number & Site Number</label>
        </div>
        <div class="col-sm-4">
            <label >{{data.surveyNumber}}</label>
          </div>
    </div>
    <div class="sub-title">
        Site Details
     </div>
    <div class="row" >
        <div class="col-sm-8 " >
            <label >Length of the site</label>
        </div>
        <div class="col-sm-4">
            <label >{{data.siteLength}}</label>
          </div>
    </div>
    <div class="row" >
        <div class="col-sm-8 " >
            <label >Width of the site</label>
        </div>
        <div class="col-sm-4">
            <label >{{data.siteBreadth}}</label>
          </div>
    </div>
    <div class="row" >
        <div class="col-sm-8 " >
            <label >Total Area in Sq ft</label>
        </div>
        <div class="col-sm-4">
            <label >{{data.siteArea}}</label>
          </div>
    </div>
    <div class="row" >
        <div class="col-sm-8 " >
            <label >Site North</label>
        </div>
        <div class="col-sm-4">
            <label >{{data.siteNorth}}</label>
          </div>
    </div>
    <div class="row" >
        <div class="col-sm-8 " >
            <label >Side East</label>
        </div>
        <div class="col-sm-4">
            <label >{{data.siteEast}}</label>
          </div>
    </div>
    <div class="row" >
        <div class="col-sm-8 " >
            <label >Site West</label>
        </div>
        <div class="col-sm-4">
            <label >{{data.siteWest}}</label>
          </div>
    </div>
    <div class="row" >
        <div class="col-sm-8 " >
            <label >Site South</label>
        </div>
        <div class="col-sm-4">
            <label >{{data.siteSouth}}</label>
          </div>
    </div>
    <div class="row" >
        <div class="col-sm-8 " >
            <label >Khata Type</label>
        </div>
        <div class="col-sm-4">
            <label >{{data.khataBbmp == 'Y' ? 'BBMP' : data.khataGp == 'Y' ? 'Gram Panchayat' : data.khataBda ? 'BDA' : '' }}</label>
          </div>
    </div>
    <div class="row" >
        <div class="col-sm-8 " >
            <label >Khata Number </label>
        </div>
        <div class="col-sm-4">
            <label >{{data.khataNumber}}</label>
          </div>
    </div>
    <div class="row" >
        <div class="col-sm-8 " >
            <label >Khata Date </label>
        </div>
        <div class="col-sm-4">
            <label >{{data.khataDate | date:'dd/MM/yyyy'}}</label>
          </div>
    </div>
    <div class="row" >
        <div class="col-sm-8 " >
            <label >Address of the building</label>
        </div>
        <div class="col-sm-4">
            <label >{{ data.buildingAddress}}</label>
          </div>
    </div>
    <div class="sub-title">
        Residential House Details
     </div>
    <div class="row" >
        <div class="col-sm-8 " >
            <label >Residential Builtup area </label>
        </div>
        <div class="col-sm-4">
            <label >{{data.resArea}}</label>
          </div>
    </div>
    <div class="row" >
        <div class="col-sm-8 " >
            <label >Residential No of floors </label>
        </div>
        <div class="col-sm-4">
            <label >{{data.resFloors}}</label>
          </div>
    </div>
    <div class="row" >
        <div class="col-sm-8 " >
            <label >Residential Total Area </label>
        </div>
        <div class="col-sm-4">
            <label >{{data.resTotalarea}}</label>
          </div>
    </div>
    <div class="sub-title">
        Commercial/Industrial Building Details
     </div>
    <div class="row" >
        <div class="col-sm-8 " >
            <label >Commercial Build up area </label>
        </div>
        <div class="col-sm-4">
            <label >{{data.commArea}}</label>
          </div>
    </div>
    <div class="row" >
        <div class="col-sm-8 " >
            <label >Commercial No of floors </label>
        </div>
        <div class="col-sm-4">
            <label >{{data.commFloors}}</label>
          </div>
    </div>
    <div class="row" >
        <div class="col-sm-8 " >
            <label >Commercial Total Area </label>
        </div>
        <div class="col-sm-4">
            <label >{{data.commTotalarea}}</label>
          </div>
    </div>
    <div class="row" >
        <div class="col-sm-8 " >
            <label >purpose/use</label>
        </div>
        <div class="col-sm-4">
            <label >{{data.commPurpose}}</label>
          </div>
    </div>
    <div class="sub-title">
        Apartment / High-rise / Multi-dwelling  Details
     </div>
     <div class="row" >
        <div class="col-sm-8 " >
            <label > Area of apartment/Total area of Complex</label>
        </div>
        <div class="col-sm-4">
            <label >{{data.aptTotalarea}}</label>
          </div>
    </div>
    <div class="row" >
        <div class="col-sm-8 " >
            <label > Built up area</label>
        </div>
        <div class="col-sm-4">
            <label >{{data.aptBuildUpArea}}</label>
          </div>
    </div>
    <div class="row" >
        <div class="col-sm-8 " >
            <label > Floors/Blocks</label>
        </div>
        <div class="col-sm-4">
            <label >{{data.aptFloors}}</label>
          </div>
    </div>
    <div class="sub-title">
        Gated Community   Details
     </div>
    <div class="row" >
        <div class="col-sm-8 " >
            <label > Gated Community Length</label>
        </div>
        <div class="col-sm-4">
            <label >{{data.gcLength}}</label>
          </div>
    </div>
    <div class="row" >
        <div class="col-sm-8 " >
            <label > Gated Community Breadth</label>
        </div>
        <div class="col-sm-4">
            <label >{{data.gcBreadth}}</label>
          </div>
    </div>
    <div class="row" >
        <div class="col-sm-8 " >
            <label > Gated Community Total Area</label>
        </div>
        <div class="col-sm-4">
            <label >{{data.gcTotalarea}}</label>
          </div>
    </div>
    <div class="row" >
        <div class="col-sm-8 " >
            <label > Gated Community Sital Area</label>
        </div>
        <div class="col-sm-4">
            <label >{{data.gcSitalarea}}</label>
          </div>
    </div>

    <div class="sub-title">
        Other Property  Details
     </div>
     <div class="row" >
        <div class="col-sm-8 " >
            <label >Build up area </label>
        </div>
        <div class="col-sm-4">
            <label >{{data.otherArea}}</label>
          </div>
    </div>
    <div class="row" >
        <div class="col-sm-8 " >
            <label >Total Area </label>
        </div>
        <div class="col-sm-4">
            <label >{{data.otherTotalarea}}</label>
          </div>
    </div>
    <div class="row" >
        <div class="col-sm-8 " >
            <label >purpose/use</label>
        </div>
        <div class="col-sm-4">
            <label >{{data.otherPurpose}}</label>
          </div>
    </div>
    <div class="sub-title">
        Constructed with approval 
     </div>
        <div class="row" >
        <div class="col-sm-8 " >
            <label >Is constructed with approval</label>
        </div>
        <div class="col-sm-4">
            <label >{{data.constNoAproval ? 'Yes' : data.constNoAproval != null && data.constNoAproval != undefined ? 'No' : ''}}</label>
          </div>
    </div>
    <div class="sub-title">
        BDA approved layout  Details
     </div>
    <div class="row" >
        <div class="col-sm-8 " >
            <label > BDA approval Layout LP Number</label>
        </div>
        <div class="col-sm-4">
            <label >{{data.bdaLayoutAprovalNumber}}</label>
          </div>
    </div>
    <div class="row" >
        <div class="col-sm-8 " >
            <label > BDA approval Layout Date </label>
        </div>
        <div class="col-sm-4">
            <label >{{data.bdaLayoutAprovalDate | date:'dd/MM/yyyy'}}</label>
          </div>
    </div>
    <div class="sub-title">
        Building Plan approved by BDA Details
     </div>
    <div class="row" >
        <div class="col-sm-8 " >
            <label >  Building plan approved by BDA LP Number</label>
        </div>
        <div class="col-sm-4">
            <label >{{data.bdaAprovalNumber}}</label>
          </div>
    </div>
    <div class="row" >
        <div class="col-sm-8" >
            <label >  Building plan approved by BDA Date </label>
        </div>
        <div class="col-sm-4">
            <label >{{data.bdaAprovalDate | date:'dd/MM/yyyy'}}</label>
          </div>
    </div>
    <div class="sub-title">
        Approval from panchayat Details
     </div>
    <div class="row" >
        <div class="col-sm-8 " >
            <label > Approval from panchayat LP Number</label>
        </div>
        <div class="col-sm-4">
            <label >{{data.gpAprovalNumber}}</label>
          </div>
    </div>
    <div class="row" >
        <div class="col-sm-8 " >
            <label > Panchayat Date of approval</label>
        </div>
        <div class="col-sm-4">
            <label >{{data.gpAprovalDate | date:'dd/MM/yyyy'}}</label>
          </div>
    </div>
    <div class="row" >
        <div class="col-sm-8 " >
            <label > Approval from Panchayat Name</label>
        </div>
        <div class="col-sm-4">
            <label >{{selectedPanchayat}}</label>
          </div>
    </div>
    <div class="sub-title">
        Approval from BBMP Details
     </div>
    <div class="row" >
        <div class="col-sm-8 " >
            <label > BBMP Approval Number</label>
        </div>
        <div class="col-sm-4">
            <label >{{data.bbmpAprovalNumber}}</label>
          </div>
    </div>
    <div class="row" >
        <div class="col-sm-8 " >
            <label > BBMP Date of approval</label>
        </div>
        <div class="col-sm-4">
            <label >{{data.bbmpAprovalDate | date:'dd/MM/yyyy'}}</label>
          </div>
    </div>
    <div class="sub-title">
        Conversion order of DC Details
     </div>
    <div class="row" >
        <div class="col-sm-8" >
            <label >Conversion order of DC Approval Number</label>
        </div>
        <div class="col-sm-4">
            <label >{{data.dcAprovalNumber}}</label>
          </div>
    </div>
    <div class="row" >
        <div class="col-sm-8 " >
            <label >Conversion order of DC Date of approval</label>
        </div>
        <div class="col-sm-4">
            <label >{{data.dcAprovalDate | date:'dd/MM/yyyy'}}</label>
          </div>
    </div>
    <div class="sub-title">
        Commencement Certificate Details
     </div>
    <div class="row" >
        <div class="col-sm-8 " >
            <label >Commencement Number</label>
        </div>
        <div class="col-sm-4">
            <label >{{data.commenceNumber}}</label>
          </div>
    </div>
    <div class="row" >
        <div class="col-sm-8 " >
            <label >Commencement Date</label>
        </div>
        <div class="col-sm-4">
            <label >{{data.commenceDate | date:'dd/MM/yyyy'}}</label>
          </div>
    </div>
    <div class="row" >
        <div class="col-sm-8 " >
            <label >Commencement Issuing Authority</label>
        </div>
        <div class="col-sm-4">
            <label >{{data.commenceAuth}}</label>
          </div>
    </div>
    <div class="sub-title">
        Completion Certificate  Details
     </div>
    <div class="row" >
        <div class="col-sm-8 " >
            <label >Completion Number</label>
        </div>
        <div class="col-sm-4">
            <label >{{data.completionNumber}}</label>
          </div>
    </div>
    <div class="row" >
        <div class="col-sm-8 " >
            <label >Completion Date</label>
        </div>
        <div class="col-sm-4">
            <label >{{data.completionDate | date:'dd/MM/yyyy'}}</label>
          </div>
    </div>
    <div class="row" >
        <div class="col-sm-8 " >
            <label >Completion Issuing Authority</label>
        </div>
        <div class="col-sm-4">
            <label >{{data.completionAuth}}</label>
          </div>
    </div>
    <div class="sub-title">
        Occupancy Certificate Details
     </div>
    <div class="row" >
        <div class="col-sm-8 " >
            <label >Occupancy Number</label>
        </div>
        <div class="col-sm-4">
            <label >{{data.occupancyNumber}}</label>
          </div>
    </div>
    <div class="row" >
        <div class="col-sm-8 " >
            <label >Occupancy Date</label>
        </div>
        <div class="col-sm-4">
            <label >{{data.occupancyDate | date:'dd/MM/yyyy'}}</label>
          </div>
    </div>
    <div class="row" >
        <div class="col-sm-8 " >
            <label >Occupancy Issuing Authority</label>
        </div>
        <div class="col-sm-4">
            <label >{{data.occupancyAuth}}</label>
          </div>
    </div>

    <div class="sub-title">
        Sanctions of temporary electricity connection Details
     </div>
    <div class="row" >
        <div class="col-sm-8 " >
            <label >Electricity Temporary connection RR No</label>
        </div>
        <div class="col-sm-4">
            <label >{{data.bescomTempNumber}}</label>
          </div>
    </div>
    <div class="row" >
        <div class="col-sm-8 " >
            <label >Electricity Temporary connection Date</label>
        </div>
        <div class="col-sm-4">
            <label >{{data.bescomTempDate | date:'dd/MM/yyyy'}}</label>
          </div>
    </div>
    <div class="sub-title">
        Sanctions of Permanent electricity connection Details
     </div>
    <div class="row" >
        <div class="col-sm-8 " >
            <label >Electricity Permanent connection RR No</label>
        </div>
        <div class="col-sm-4">
            <label >{{data.bescomPermNumber}}</label>
          </div>
    </div>
    <div class="row" >
        <div class="col-sm-8 " >
            <label >Electricity Permanent connection Date</label>
        </div>
        <div class="col-sm-4">
            <label >{{data.bescomPermDate | date:'dd/MM/yyyy'}}</label>
          </div>
    </div>
    <div class="sub-title">
        Sanctions of temporary water connection Details
     </div>
    <div class="row" >
        <div class="col-sm-8 " >
            <label >Water Temporary connection RR No</label>
        </div>
        <div class="col-sm-4">
            <label >{{data.bwssbTempNumber}}</label>
          </div>
    </div>
    <div class="row" >
        <div class="col-sm-8 " >
            <label >Water Temporary connection Date</label>
        </div>
        <div class="col-sm-4">
            <label >{{data.bwssbTempDate | date:'dd/MM/yyyy'}}</label>
          </div>
    </div>
    <div class="sub-title">
        Sanctions of permanent water connection Details
     </div>
    <div class="row" >
        <div class="col-sm-8 " >
            <label >Water Permanent connection RR No</label>
        </div>
        <div class="col-sm-4">
            <label >{{data.bwssbPermNumber}}</label>
          </div>
    </div>
    <div class="row" >
        <div class="col-sm-8 " >
            <label >Water Permanent connection Date</label>
        </div>
        <div class="col-sm-4">
            <label >{{data.bwssbPermDate | date:'dd/MM/yyyy'}}</label>
          </div>
    </div>
    <div class="sub-title">
        Details of loan availed  Details
     </div>
    <div class="row" >
        <div class="col-sm-8 " >
            <label >Financier/Financial Institution/Bank</label>
        </div>
        <div class="col-sm-4">
            <label >{{data.bankName}}</label>
          </div>
    </div>
    <div class="row" >
        <div class="col-sm-8 " >
            <label >Amount Sanctioned</label>
        </div>
        <div class="col-sm-4">
            <label >{{data.vsanctionAmount}}</label>
          </div>
    </div>
    <div class="row" >
        <div class="col-sm-8 " >
            <label >Duration of payment</label>
        </div>
        <div class="col-sm-4">
            <label >{{data.paymentDuration}}</label>
          </div>
    </div>
    <div class="row" >
        <div class="col-sm-8 " >
            <label >Single/Joint application</label>
        </div>
        <div class="col-sm-4">
            <label >{{data.singleApplication == 'Y' ? 'Single Application' : data.jointApplication == 'Y' ? 'Joint Application' : ''}}</label>
          </div>
    </div>
    <div class="sub-title">
        Last loan paid receipt Details
     </div>
    <div class="row" >
        <div class="col-sm-8 " >
            <label >Receipt Number</label>
        </div>
        <div class="col-sm-4">
            <label >{{data.receiptNumber}}</label>
          </div>
    </div>
    <div class="row" >
        <div class="col-sm-8 " >
            <label >Receipt Date</label>
        </div>
        <div class="col-sm-4">
            <label >{{data.receiptDate | date:'dd/MM/yyyy'}}</label>
          </div>
    </div>
    <div class="row" >
        <div class="col-sm-8 " >
            <label >Receipt Amount</label>
        </div>
        <div class="col-sm-4">
            <label >{{data.receiptAmount}}</label>
          </div>
    </div>
    <div class="sub-title">
        Court case pending/stay order
     </div>
     <div class="row" >
        <div class="col-sm-8 " >
            <label >Is court case pending</label>
        </div>
        <div class="col-sm-4">
            <label style="text-transform: capitalize;">{{data.isCasePending}}</label>
          </div>
    </div>
    <div class="row" >
        <div class="col-sm-8 " >
            <label > If yes, Name of court</label>
        </div>
        <div class="col-sm-4">
            <label >{{data.courtName}}</label>
          </div>
    </div>
    <div class="row" >
        <div class="col-sm-8 " >
            <label >Case No</label>
        </div>
        <div class="col-sm-4">
            <label >{{data.caseNumber}}</label>
          </div>
    </div>
    <div class="row" >
        <div class="col-sm-8 " >
            <label >Present Status</label>
        </div>
        <div class="col-sm-4">
            <label >{{data.caseStatus}}</label>
          </div>
    </div>
    <div class="row sub-title-2" *ngIf="applicationData">
        <div class="col-sm-10 " style="font-size: 17px;" >
            <label >Recommended by </label>
        </div>
        <div class="col-sm-2">
            <label class="color">{{applicationData.applicantRecommendation ? applicationData.applicantRecommendation.recommendationName : ''}}</label>
          </div>
    </div>