import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfirmDialogService } from 'src/app/shared/confirm-dialog/confirm-dialog.service';
import { ApplicationService } from 'src/services/application.service';

@Component({
  selector: 'app-activity-log-view',
  templateUrl: './activity-log-view.component.html',
  styleUrls: ['./activity-log-view.component.scss']
})
export class ActivityLogViewComponent implements OnInit {
  
  public application_id : any;
  applicationData;
  selectedPanchayat: any;
  status: any;
  commentTypes: any;
  public disable:boolean=true;
  notificationType: any;

  constructor(private applicationService: ApplicationService,private route: ActivatedRoute, 
    private _snackBar: MatSnackBar, private router: Router,
    private confirmDialogService: ConfirmDialogService) { 
    this.application_id = this.route.snapshot.queryParams['application_id'];
    console.log("jcc", this.application_id)
  }

  ngOnInit(): void {
    this.fetchDataByApplicationNo();
  }


  private fetchDataByApplicationNo() {
    this.applicationService.fetchApplicantById(this.application_id).subscribe(res=>{
      console.log("app",res)
      this.applicationData = res;
      this.notificationType = res.notificationType;
      this.status=this.applicationData.scrutinyUserStatus;
      console.log("status",this.status);
      this.fetchAllPanchayats();
      this.fetchAllCommentTypes();
      
     })
  }

  private fetchAllCommentTypes(){
    this.applicationService.getAllLandTypes().subscribe(res=>{
      this.commentTypes = res.data;
      let selectedCommentTypes = this.applicationData.landTypeVOS;
      for(let i = 0;i < this.commentTypes.length;i++) {
        let isChecked = false;
        for(let j = 0;j < selectedCommentTypes.length;j++) {
            if(this.commentTypes[i]['id'] == selectedCommentTypes[j]['id']) {
                isChecked = true;
                break;
            }
        }
        this.commentTypes[i].checked = isChecked;
      }
    })
  }
  private fetchAllPanchayats(){
    this.applicationService.fetchAllPanchayats().subscribe(res=>{
      if(this.applicationData &&  this.applicationData.panchayat && res){
        this.selectedPanchayat = res[this.applicationData.panchayat-1]?.name;
      }
    })
  }
  
}
