<div class="content-area">
<div class="col-sm-12">
   <div class="row">
        <div class="col-md-8">
            <fa-icon [icon]="faCheck" class="checked" [hidden]="!enclosure || !enclosure.saleDeedCopy"></fa-icon>
            <fa-icon [icon]="faTimes" class="unchecked" [hidden]="!enclosure || enclosure.saleDeedCopy"></fa-icon>
            <label>1. Copy of the sale deed(Absolute/LSD/GPA registration/Rectification deed/ Amalamation/Mutation etc.,) / 
                ಹಕ್ಕು ಹೊಂದಿದ ಬಗ್ಗೆ ದಾಖಲೆ
            </label>
        </div>
        <div class="col-md-4">
            <input type="file" id="sdeed_cpy" [disabled]="!enclosure || enclosure.saleDeedCopy"  name="sdeed_cpy" (change)="onFileChanged($event)"/>
            <fa-icon [icon]="faTrash" class="dismiss-icon" (click)="dismissIconClick('sdeed_cpy')"></fa-icon>
        </div>
        
    </div>

    <div class="row">
        <div class="col-md-8">
            <fa-icon [icon]="faCheck" class="checked" [hidden]="!enclosure || !enclosure.propertyPicture"></fa-icon>
            <fa-icon [icon]="faTimes" class="unchecked" [hidden]="!enclosure || enclosure.propertyPicture"></fa-icon>
            <label> 2. Photo of the site with building / ನಿವೇಶನದೊಂದಿಗೆ ಕಟ್ಟಡದ ಫೋಟೋ</label>
        </div>
        <div class="col-md-4">
            <input type="file" id="prop_picture" [disabled]="!enclosure || enclosure.propertyPicture" name="prop_picture" (change)="onFileChanged($event)"/>
            <fa-icon [icon]="faTrash" class="dismiss-icon" (click)="dismissIconClick('prop_picture')"></fa-icon>
        </div>
    </div>

    <div class="row">
        <div class="col-md-8">
            <fa-icon [icon]="faCheck" class="checked" [hidden]="!enclosure || !enclosure.taxPaidCopy"></fa-icon>
            <fa-icon [icon]="faTimes" class="unchecked" [hidden]="!enclosure || enclosure.taxPaidCopy"></fa-icon>
            <label>3. Copy of tax paid receipt for the financial year 2018-19. / 2018-19ರ ಹಣಕಾಸು ವರ್ಷಕ್ಕೆ ತೆರಿಗೆ ಪಾವತಿಸಿದ ರಶೀದಿಯ ಪ್ರತಿ.</label>
        </div>
        <div class="col-md-4">
            <input type="file" id="txpd_cpy" [disabled]="!enclosure || enclosure.taxPaidCopy" name="txpd_cpy" (change)="onFileChanged($event)"/>
            <fa-icon [icon]="faTrash" class="dismiss-icon" (click)="dismissIconClick('txpd_cpy')"></fa-icon>
        </div>
    </div>

    <div class="row">
        <div class="col-md-8">
            <fa-icon [icon]="faCheck" class="checked" [hidden]="!enclosure || !enclosure.khataCopy"></fa-icon>
            <fa-icon [icon]="faTimes" class="unchecked" [hidden]="!enclosure || enclosure.khataCopy"></fa-icon>
            <label>4. Copy of Khatha / ಖಾತಾದ ಪ್ರತಿ</label>
        </div>
        <div class="col-md-4">
            <input type="file" id="khata_cpy" [disabled]="!enclosure || enclosure.khataCopy" name="khata_cpy" (change)="onFileChanged($event)"/>
            <fa-icon [icon]="faTrash" class="dismiss-icon" (click)="dismissIconClick('khata_cpy')"></fa-icon>
        </div>
    </div>

    <div class="row">
        <div class="col-md-8">
            <fa-icon [icon]="faCheck" class="checked" [hidden]="!enclosure || !enclosure.addressProof"></fa-icon>
            <fa-icon [icon]="faTimes" class="unchecked" [hidden]="!enclosure || enclosure.addressProof"></fa-icon>
            <label>5. Address proof (Aadhaar / Voter-id / Ration Card) / ವಿಳಾಸ ಪುರಾವೆ (ಆಧಾರ್ / ಮತದಾರರ ಗುರುತಿನ ಚೀಟಿ / ಪಡಿತರ ಚೀಟಿ)</label>
        </div>
        <div class="col-md-4">
            <input type="file" id="addr_proof" name="addr_proof" [disabled]="!enclosure || enclosure.addressProof" (change)="onFileChanged($event)"/>
            <fa-icon [icon]="faTrash" class="dismiss-icon" (click)="dismissIconClick('addr_proof')"></fa-icon>
        </div>
    </div>
    
    <div class="row">
        <div class="col-md-8">
            <fa-icon [icon]="faCheck" class="checked" [hidden]="!enclosure || !enclosure.encumbranceCopy"></fa-icon>
            <fa-icon [icon]="faTimes" class="unchecked" [hidden]="!enclosure || enclosure.encumbranceCopy"></fa-icon>
            <label>6. Up-to-date Encumbrance Certificate from the date of purchase / ಖರೀದಿಸಿದ ದಿನಾಂಕದಿಂದ ಇತ್ತೀಚಿನ ಋಣಭಾರ ಪ್ರಮಾಣಪತ್ರ</label>
        </div>
        <div class="col-md-4">
            <input type="file" id="encum_cpy" name="encum_cpy" [disabled]="!enclosure || enclosure.encumbranceCopy" (change)="onFileChanged($event)"/>
            <fa-icon [icon]="faTrash" class="dismiss-icon" (click)="dismissIconClick('encum_cpy')"></fa-icon>
        </div>
    </div>

    <div class="row">
        <div class="col-md-8">
            <fa-icon [icon]="faCheck" class="checked" [hidden]="!enclosure || !enclosure.sanctionedPlanCopy"></fa-icon>
            <fa-icon [icon]="faTimes" class="unchecked" [hidden]="!enclosure || enclosure.sanctionedPlanCopy"></fa-icon>
            <label>7. Copy of sanctioned plan / ಮಂಜೂರಾದ ನಕ್ಷೆಯ ಪ್ರತಿ</label>
        </div>
        <div class="col-md-4">
            <input type="file" id="sanc_cpy" name="sanc_cpy" [disabled]="!enclosure || enclosure.sanctionedPlanCopy" (change)="onFileChanged($event)"/>
            <fa-icon [icon]="faTrash" class="dismiss-icon" (click)="dismissIconClick('sanc_cpy')"></fa-icon>
        </div>
    </div>

    <div class="row">
        <div class="col-md-8">
            <fa-icon [icon]="faCheck" class="checked" [hidden]="!enclosure || !enclosure.commenceCopy"></fa-icon>
            <fa-icon [icon]="faTimes" class="unchecked" [hidden]="!enclosure || enclosure.commenceCopy"></fa-icon>
            <label>8. Copy of  Commencement Certificate / ಕಟ್ಟಡ ಪ್ರಾರಂಭ ಪ್ರಮಾಣಪತ್ರದ ಪ್ರತಿ</label>
        </div>
        <div class="col-md-4">
            <input type="file" id="commence_cpy" name="commence_cpy" [disabled]="!enclosure || enclosure.commenceCopy" (change)="onFileChanged($event)"/>
            <fa-icon [icon]="faTrash" class="dismiss-icon" (click)="dismissIconClick('commence_cpy')"></fa-icon>
        </div>
    </div>

    <div class="row">
        <div class="col-md-8">
            <fa-icon [icon]="faCheck" class="checked" [hidden]="!enclosure || !enclosure.completionCopy"></fa-icon>
            <fa-icon [icon]="faTimes" class="unchecked" [hidden]="!enclosure || enclosure.completionCopy"></fa-icon>
            <label> 9. Copy of Completion Certificate / ಕಟ್ಟಡ ಪೂರ್ಣಗೊಂಡ ಪ್ರಮಾಣಪತ್ರದ  
                ಪ್ರತಿ</label>
        </div>
        <div class="col-md-4">
            <input type="file" id="completion_cpy" name="completion_cpy" [disabled]="!enclosure || enclosure.completionCopy" (change)="onFileChanged($event)"/>
            <fa-icon [icon]="faTrash" class="dismiss-icon" (click)="dismissIconClick('completion_cpy')"></fa-icon>
        </div>
    </div>


    <div class="row">
        <div class="col-md-8">
            <fa-icon [icon]="faCheck" class="checked" [hidden]="!enclosure || !enclosure.occupancyCopy"></fa-icon>
            <fa-icon [icon]="faTimes" class="unchecked" [hidden]="!enclosure || enclosure.occupancyCopy"></fa-icon>
            <label>10. Copy of Occupancy Certificate / ವಾಸದ ಪ್ರಮಾಣಪತ್ರದ ಪ್ರತಿ</label>
        </div>
        <div class="col-md-4">
            <input type="file" id="occupancy_cpy" name="occupancy_cpy" [disabled]="!enclosure || enclosure.occupancyCopy" (change)="onFileChanged($event)"/>
            <fa-icon [icon]="faTrash" class="dismiss-icon" (click)="dismissIconClick('occupancy_cpy')"></fa-icon>
        </div>
    </div>


    <div class="row">
        <div class="col-md-8">
            <fa-icon [icon]="faCheck" class="checked" [hidden]="!enclosure || !enclosure.bescomTempCopy"></fa-icon>
            <fa-icon [icon]="faTimes" class="unchecked" [hidden]="!enclosure || enclosure.bescomTempCopy"></fa-icon>
            <label>11. Copy of sanction order of temporary connection for electricity / ವಿದ್ಯುತ್ ತಾತ್ಕಾಲಿಕ ಸಂಪರ್ಕದ ಮಂಜೂರಾತಿ ಆದೇಶದ ಪ್ರತಿ</label>
        </div>
        <div class="col-md-4">
            <input type="file" id="bescom_tempcpy" name="bescom_tempcpy"  [disabled]="!enclosure || enclosure.bescomTempCopy"  (change)="onFileChanged($event)"/>
            <fa-icon [icon]="faTrash" class="dismiss-icon" (click)="dismissIconClick('bescom_tempcpy')"></fa-icon>
        </div>
    </div>

    <div class="row">
        <div class="col-md-8">
            <fa-icon [icon]="faCheck" class="checked" [hidden]="!enclosure || !enclosure.bescomPermCopy"></fa-icon>
            <fa-icon [icon]="faTimes" class="unchecked" [hidden]="!enclosure || enclosure.bescomPermCopy"></fa-icon>
            <label>12. Copy of sanction order of permanent connection for electricity / ವಿದ್ಯುತ್ ಖಾಯಂ ಸಂಪರ್ಕದ ಮಂಜೂರಾತಿ ಆದೇಶದ ಪ್ರತಿ</label>
        </div>
        <div class="col-md-4">
            <input type="file" id="bescom_permcpy" name="bescom_permcpy" [disabled]="!enclosure || enclosure.bescomPermCopy" (change)="onFileChanged($event)"/>
            <fa-icon [icon]="faTrash" class="dismiss-icon" (click)="dismissIconClick('bescom_permcpy')"></fa-icon>
        </div>
    </div>


    <div class="row">
        <div class="col-md-8">
            <fa-icon [icon]="faCheck" class="checked"  [hidden]="!enclosure || !enclosure.bwssbTempCopy"></fa-icon>
            <fa-icon [icon]="faTimes" class="unchecked" [hidden]="!enclosure || enclosure.bwssbTempCopy"></fa-icon>
            <label>13. Copy of sanction order of temporary connection for water / ನೀರಿನ ತಾತ್ಕಾಲಿಕ ಸಂಪರ್ಕದ ಮಂಜೂರಾತಿ ಆದೇಶದ ಪ್ರತಿ</label>
        </div>
        <div class="col-md-4">
            <input type="file" id="bwssb_tempcpy" name="bwssb_tempcpy"  [disabled]="!enclosure || enclosure.bwssbTempCopy" (change)="onFileChanged($event)"/>
            <fa-icon [icon]="faTrash" class="dismiss-icon" (click)="dismissIconClick('bwssb_tempcpy')"></fa-icon>
        </div>
    </div>

    <div class="row">
        <div class="col-md-8">
            <fa-icon [icon]="faCheck" class="checked" [hidden]="!enclosure || !enclosure.bwssbPermCopy"></fa-icon>
            <fa-icon [icon]="faTimes" class="unchecked" [hidden]="!enclosure || enclosure.bwssbPermCopy"></fa-icon>
            <label>14. Copy of sanction order of permanent connection for water / ನೀರಿನ ಖಾಯಂ ಸಂಪರ್ಕದ ಮಂಜೂರಾತಿ ಆದೇಶದ ಪ್ರತಿ</label>
        </div>
        <div class="col-md-4">
            <input type="file" id="bwssb_permcpy" name="bwssb_permcpy" [disabled]="!enclosure || enclosure.bwssbPermCopy" (change)="onFileChanged($event)"/>
            <fa-icon [icon]="faTrash" class="dismiss-icon" (click)="dismissIconClick('bwssb_permcpy')"></fa-icon>
        </div>
    </div>


    <div class="row">
        <div class="col-md-8">
            <fa-icon [icon]="faCheck" class="checked" [hidden]="!enclosure || !enclosure.loanSanctionCopy"></fa-icon>
            <fa-icon [icon]="faTimes" class="unchecked" [hidden]="!enclosure || enclosure.loanSanctionCopy"></fa-icon>
            <label>15. Copy of loan sanction order / ಸಾಲ ಮಂಜೂರಾತಿ ಆದೇಶದ ಪ್ರತಿ</label>
        </div>
        <div class="col-md-4">
            <input type="file" id="lnsanc_cpy" name="lnsanc_cpy" [disabled]="!enclosure || enclosure.loanSanctionCopy"  (change)="onFileChanged($event)"/>
            <fa-icon [icon]="faTrash" class="dismiss-icon" (click)="dismissIconClick('lnsanc_cpy')"></fa-icon>
        </div>
    </div>

    <div class="row">
        <div class="col-md-8">
            <fa-icon [icon]="faCheck" class="checked" [hidden]="!enclosure || !enclosure.loanReceiptCopy"></fa-icon>
            <fa-icon [icon]="faTimes" class="unchecked" [hidden]="!enclosure || enclosure.loanReceiptCopy"></fa-icon>
            <label>16. Copy of latest loan installment paid receipt / ಇತ್ತೀಚಿನ ಸಾಲದ ಕಂತು ಪಾವತಿಸಿದ ರಶೀದಿಯ ಪ್ರತಿ</label>
        </div>
        <div class="col-md-4">
            <input type="file" id="lnrecpt_cpy" name="lnrecpt_cpy" [disabled]="!enclosure || enclosure.loanReceiptCopy" (change)="onFileChanged($event)"/>
            <fa-icon [icon]="faTrash" class="dismiss-icon" (click)="dismissIconClick('lnrecpt_cpy')"></fa-icon>
        </div>
    </div>


    <div class="row">
        <div class="col-md-8">
            <fa-icon [icon]="faCheck" class="checked" [hidden]="!enclosure || !enclosure.deedOfDeclarationCopy"></fa-icon>
            <fa-icon [icon]="faTimes" class="unchecked" [hidden]="!enclosure || enclosure.deedOfDeclarationCopy"></fa-icon>
            <label>17. Deed of declaration in respect of Apartments / ಅಪಾರ್ಟ್‍ಮೆಂಟ್ ಗೆ  ಸಂಬಂಧಿಸಿದಂತೆ ಘೋಷಣೆಯ ಪತ್ರ</label>
        </div>
        <div class="col-md-4">
            <input type="file" id="dod_cpy" name="dod_cpy"  [disabled]="!enclosure || enclosure.deedOfDeclarationCopy" (change)="onFileChanged($event)"/>
            <fa-icon [icon]="faTrash" class="dismiss-icon" (click)="dismissIconClick('dod_cpy')"></fa-icon>
        </div>
    </div>

        <div class="col-md-2 offset-md-5 button-row">
            <input type="button" class="btn btn-primary" value="Save" (click)="onSubmit()"/>
        </div>
    <div class="spinner" *ngIf="isLoading">
        <mat-spinner strokeWidth="5" diameter="60"></mat-spinner>
        </div>
    </div>
</div>