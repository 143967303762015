<div class="content-area col-sm-10">
  <div class="col-sm-12">
     <div class="row">
          <div class="col-md-8">
              <fa-icon [icon]="faCheck" class="checked" [hidden]="!data || !data.originalDocumentName"></fa-icon>
              <fa-icon [icon]="faTimes" class="unchecked" [hidden]="!data || data.originalDocumentName"></fa-icon>
              <label>1.{{documentName}}
              </label>
          </div>
          <div class="col-md-4">
              <input type="file" id="award_master_enclosure"  [disabled]="!data || data.originalDocumentName" name="award_master_enclosure" (change)="onFileChanged($event)"/>
              <fa-icon [icon]="faTrash" class="dismiss-icon" (click)="dismissIconClick('award_master_enclosure')"></fa-icon>
          </div>
      </div>
  </div>
  <div class="col-md-2 offset-md-5 button-row">
    <input type="button" class="btn btn-primary" value="Save" (click)="onSubmit()"/>
</div>
</div>

