<div class="content-area">
    <div class="col-sm-10 offset-sm-1">
        <app-top-bar selectedTab="home"></app-top-bar>
        <div class="heading">
            {{previousHeading}} / {{title}}
        </div>
        <app-layout-map></app-layout-map>
    </div>
    <div class="col-md-6 offset-md-3">
    </div>
    <div>
        <div class="col-md-8 offset-md-3">
            <div class="row">
                <mat-list role="list">
                    <mat-list-item role="listitem" *ngFor="let item of documents; let i = index">
                        <a href="javascript:void(0)" (click)="onSectorTileClicked(item)">{{item.documentName}}</a>
                    </mat-list-item>
                </mat-list>
            </div>
        </div>
    </div>
</div>
