import { Injectable } from '@angular/core';
import { MatDialog } from "@angular/material/dialog";
import { MessageDialogComponent } from "./message-dialog/message-dialog.component";

@Injectable({
  providedIn: 'root'
})
export class MessageDialogService {
  private opened = false;

  constructor(private dialog : MatDialog) { }

  
  openDialog(content: any, status?: number): void {
    if (!this.opened) {
      this.opened = true;
      let message = content['message'];
      const dialogRef = this.dialog.open(MessageDialogComponent, {
        data: { message, status },
        maxHeight: "100%",
        width: "540px",
        maxWidth: "100%",
        disableClose: true,
        hasBackdrop: true
      });

      dialogRef.afterClosed().subscribe(() => {
        this.opened = false;
      });
    }
  }
}
