import { Injectable } from '@angular/core';
import { title } from 'process';
import { filter } from 'rxjs/operators';
import { ApplicationService } from '../application.service';
import { StrorageService } from '../strorage.service';

@Injectable({
  providedIn: 'root'
})
export class ApplicantHelperService {

    public dateFields: string[]=[
      'bbmpAprovalDate','bdaAprovalDate','bdaLayoutAprovalDate','bescomPermDate','bescomTempDate',
      'bwssbPermDate','bwssbTempDate','commenceDate','dcAprovalDate','gpAprovalDate','khataDate','occupancyDate','completionDate',
      'receiptDate'
    ]

    private filterDates = [
      {
        "condition":"2008-12-31",
        "title":"Pre 2009",
        "type":"single"
      },
      {
        "condition":"2009-01-01, 2014-12-31",
        "type": "between",
        "title": "2009 - 26 Nov 2014"
      },
      {
        "condition":"2015-01-01, 2018-08-03",
        "type":"between",
        "title":"27 Nov 2014- 3 Aug 2018"
      },
      {
        "condition":"2018-08-04",
        "type":"after",
        "title":"Post Aug 4-2018"
      }
    ]
  
  constructor() { }
 
 getLeastMinimumDate(applicant: any) {
    let leastMinDate: any;
    for(let i = 0;i < this.dateFields.length;i++) {
      for(const key of Object.keys(applicant)) {
        if(this.dateFields[i] == key && applicant[key]) {
          let applicantDate = this.getDateFromStr(applicant[key]);
          if(!leastMinDate) {
            leastMinDate = applicantDate;
          }
          if(leastMinDate > applicantDate) {
            leastMinDate = applicantDate;
          }
        }
      }
    }
    return leastMinDate;
  }
  
  getTitle(applicant: any) {
    let minDate = this.getLeastMinimumDate(applicant);
    return this.getTitleByApplyFilterCondition(minDate, applicant);
  }

  private getDateFromStr(dateStr: any) {
    return new Date(dateStr);
  }
 
  private getTitleByApplyFilterCondition = (minDate: any, applicant: any) => {
    let title: any;
    for(let i = 0;i < this.filterDates.length;i++) {
      if(title) {
        break;
      }
      let filterDate: any = this.filterDates[i];
      switch(filterDate.type){
        case "between":
          let dates: any[] = filterDate.condition.split(",");
          let date1 = this.getDateFromStr(dates[0]);
          let date2 = this.getDateFromStr(dates[1]);
          if(minDate >= date1 && minDate <= date2){
            title = filterDate.title;
          }
          break;
        case "after":
          if(minDate >= this.getDateFromStr(filterDate.condition)){
            title = filterDate.title;
          }
          break;  
        default:
          if(minDate <= this.getDateFromStr(filterDate.condition)) {
            title = filterDate.title;
          }
          break;
      }
    }
    if(title) {
      if(applicant.constNoAproval == null) {
        title = title+"|"+'N/A';
      } else if(applicant.constNoAproval) {
        title = title+"|"+'Plan Approved';
      } else {
        title = title+"|"+'Plan Not Approved';
      }
    }
    return title;
  }
}
