import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ApplicationService } from 'src/services/application.service';
import { StrorageService } from 'src/services/strorage.service';
import { faCheck, faTimes, faTrash } from '@fortawesome/free-solid-svg-icons';


@Component({
  selector: 'app-award-master-activity-log-view',
  templateUrl: './award-master-activity-log-view.component.html',
  styleUrls: ['./award-master-activity-log-view.component.scss']
})
export class AwardMasterActivityLogViewComponent implements OnInit {
  
  applicationData : any;
  villageId: any;
  user: any;
  applicantId: any;
  public faTimes = faTimes;

  public faCheck = faCheck;
  awardSklNumber: any;
  surveyNumber: any;
  awardNumber: any;
  villageName: any;
  public survey_no;
  public survey_noOption:any;


  constructor(private route: ActivatedRoute,private storageService:StrorageService,private applicationService:ApplicationService) { 
    this.villageId = this.route.snapshot.queryParams['village_id'];
    this.applicantId = this.route.snapshot.queryParams['applicant_id'];
    this.villageName = this.route.snapshot.queryParams['village_name'];
  }

  ngOnInit(): void {
    this.user=this.storageService.getUser();
    this.fetchApplicantById(this.applicantId);
  }
  
  private fetchApplicantById = (applicantId: any) => {
    if(!applicantId) {
      return;
    }
    this.applicationService.fetchAwardMasterApplicantsById(applicantId).subscribe(res =>{
      this.applicationData = res;
      this.awardSklNumber = res.awardSklNumber;
      this.surveyNumber = res.surveyNumber;
      this.awardNumber = res.awardNumber;
        console.log(res);
    })
  }
  onBack(): void {
     window.history.back();
}
}
