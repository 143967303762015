<div class="row content-area">
  <div class="col-sm-11 title">
    <h1 class="text-center heading-align" style="margin-bottom: 7%;">Sector Village Selection</h1>
  </div>
  <div class=" col-sm-1">
    <fa-icon [icon]="faTimes" class="close-icon" (click)="cancelClick()"></fa-icon>
  </div>
  <div class="col-4">
    <div class="row">
      <div class="col-sm-11 offset-sm-1">
        <h3>Village : <span class="text-danger">*</span></h3>
      </div>
      <div class="col-sm-11 offset-sm-1">
        <mat-form-field appearance="fill">
          <mat-select id="village" name="villageId"  [(ngModel)]="selectedVillage">
            <mat-option *ngFor="let villageData of villages;" [value]="villageData"
              (click)="onVillageChange(villageData.villageId)">
              {{villageData.villageName}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>
  </div>
  <div class="col-4">
    <div class="row">
      <div class="col-sm-12">
        <h3>Sy Nos: <span class="text-danger">*</span></h3>
      </div>
      <div class="col-sm-12">
        <div class="row">
          <div class="col-sm-6">
            <mat-form-field appearance="fill">
              <textarea matInput [value]="surveyNo" readonly="true"></textarea>
            </mat-form-field>
          </div>
          <div class="col-sm-6">
            <ul style="list-style-type: none;">
              <li *ngFor="let surveyNo of surveyNoDataList">
                <mat-checkbox (change)="onSurveyNumberChange($event, surveyNo)" >{{surveyNo.surveyNo}}</mat-checkbox>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="col-md-4">
    <div class="row">
      <div class="col-sm-12">
        <h3>Approx Area (in acres): <span class="text-danger">*</span></h3>
      </div>
      <div class="col-sm-4">
        <mat-form-field appearance="fill">
          <input matInput type="text" [(ngModel)]="approxArea" autocomplete="off">
        </mat-form-field>
      </div>
    </div>
  </div>
</div>
<br>
<br>
<div class="col-md-2 offset-md-5 button-row">
  <input type="button" class="btn btn-primary" value="save" mat-dialog-close (click)="onSave()"/>
</div>