<div class="w-100 row">
    <div class="col-sm-8">
        <label class="offset-sm-8 heading-text" *ngIf="reconType == 'ec_issued'"> {{'Ec Issued: '+this.dataSource.data.length}}</label>
        <label class="offset-sm-7 heading-text" *ngIf="reconType == 'cases_compensation_paid_in_cash'"> {{'Cases Compensation Paid in Cash: '+this.dataSource.data.length}}</label>
    </div>
    <div>
    <div class="col-sm-4">
        <img class="icon-size" (click)="downloadDataAsExcelSheet()" src="../../../assets/download_xlsx_icon.png"/>
    </div>
</div>
<div class="w-100 d-flex justify-content-center">
    <mat-card>
        <table mat-table [dataSource]="dataSource">

            <!-- Sy No Column -->
            <ng-container matColumnDef="slNo">
                <th mat-header-cell *matHeaderCellDef> Sl No.</th>
                <td mat-cell *matCellDef="let element ;let i =index;" class="column-container">
                    {{i+1}}</td>
            </ng-container>

            <ng-container matColumnDef="villageName">
                <th mat-header-cell *matHeaderCellDef> Village Name</th>
                <td mat-cell *matCellDef="let element ;" class="column-container">
                    {{element.casesCompensationSurveyData ? element.casesCompensationSurveyData.villageName : (element.ecIssuedSurveyData ? element.ecIssuedSurveyData.villageName : null)}}</td>
            </ng-container>

            <ng-container matColumnDef="surveyNo">
                <th mat-header-cell *matHeaderCellDef> Sy No.</th>
                <td mat-cell *matCellDef="let element ;" class="column-container">
                    {{element.casesCompensationSurveyData ? element.casesCompensationSurveyData.surveyNumber : (element.ecIssuedSurveyData ? element.ecIssuedSurveyData.surveyNumber : null)}}</td>
            </ng-container>

            <!-- Extent Column -->
            <ng-container matColumnDef="extent">
                <th mat-header-cell *matHeaderCellDef> Extent </th>
                <td mat-cell *matCellDef="let element" class="column-container">
                    {{element.casesCompensationSurveyData ? element.casesCompensationSurveyData.totalExtent : (element.ecIssuedSurveyData ? element.ecIssuedSurveyData.totalExtent : null)}}</td>
            </ng-container>

            <!-- Cash Dispensed Column -->
            <ng-container *ngIf="reconType == 'cases_compensation_paid_in_cash'" matColumnDef="cashDispensed">
                <th mat-header-cell *matHeaderCellDef> Cash Dispensed </th>
                <td mat-cell *matCellDef="let element"> {{element.casesCompensationSurveyData.cashDispensed}} </td>
            </ng-container>

            <!-- Awarded Date -->
            <ng-container *ngIf="reconType == 'cases_compensation_paid_in_cash'" matColumnDef="awardedDate">
                <th mat-header-cell *matHeaderCellDef> Awarded Dt </th>
                <td mat-cell *matCellDef="let element"> {{element.casesCompensationSurveyData.awardedDate}} </td>
            </ng-container>

            <!-- Payment Date -->
            <ng-container *ngIf="reconType == 'cases_compensation_paid_in_cash'" matColumnDef="paymentDate">
                <th mat-header-cell *matHeaderCellDef> Payment Dt </th>
                <td mat-cell *matCellDef="let element">{{element.casesCompensationSurveyData.paymentDate}} </td>
            </ng-container>

            <!-- Eligible SqFt Column -->
            <ng-container *ngIf="reconType == 'ec_issued'" matColumnDef="eligibleSqft">
                <th mat-header-cell *matHeaderCellDef> Eligible (in Sq.Ft) </th>
                <td mat-cell *matCellDef="let element"> {{element.ecIssuedSurveyData.eligibleSqft}} </td>
            </ng-container>

            <ng-container *ngIf="reconType == 'ec_issued'" matColumnDef="entitledSqft">
                <th mat-header-cell *matHeaderCellDef> Entitled (in Sq.Ft) </th>
                <td mat-cell *matCellDef="let element"> {{element.ecIssuedSurveyData.entitledSqft}} </td>
            </ng-container>

            <ng-container *ngIf="reconType == 'ec_issued'" matColumnDef="ecIssuedDate">
                <th mat-header-cell *matHeaderCellDef> EC Issue Dt </th>
                <td mat-cell *matCellDef="let element">{{element.ecIssuedSurveyData.ecIssuedDate}} </td>
            </ng-container>

            <ng-container matColumnDef="comments">
                <th mat-header-cell *matHeaderCellDef> Comments</th>
                <td mat-cell *matCellDef="let element ;" class="column-container">
                    {{element.casesCompensationSurveyData ? element.casesCompensationSurveyData.comments : (element.ecIssuedSurveyData ? element.ecIssuedSurveyData.comments : null)}}</td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>
        <div class="no-records-text" *ngIf="!dataSource.data || dataSource.data.length <= 0">No Records Found!</div>
        <!-- <mat-paginator [pageSize]="pageSize" [pageSizeOptions]="[5, 10, 20]" [showFirstLastButtons]="true"
            [pageIndex]="currentPage" [length]="totalSize" (page)="getApplicantPaginatorData($event)">
        </mat-paginator> -->
    </mat-card>
</div>