<div class="row application-form-row ">
  <label class="form-label">1. Name of the Village: <span class="text-danger"> * </span></label>
  <select class="form-textbox" [(ngModel)] = "selectedVillage" (change)="onVillageChange(selectedVillage)">
      <option disabled="true">Select Village</option>
      <option *ngFor = "let village of villages" [value]="village.id" >{{village.id}}. {{village.name}}</option>
  </select>
</div>
<div class="row application-form-row ">
  <label class="form-label">2. Survey No: <span class="text-danger"> * </span></label>
  <select class="form-textbox" [(ngModel)] = "awardSurveyMasterId" (change)="onSurveyNumberChange(awardSurveyMasterId)" >
      <option *ngFor = "let survey of surveyNumbers" [value]="survey.id">{{survey.surveyNumber}}</option>
  </select>
</div>

<div class="row application-form-row ">
  <label class="form-label">3. Extent of land as per RTC: <span class="text-danger"> </span></label>
</div>
<div class="row application-form-row">
    <label class="form-label">3a. Total Area (in acres):<span class="text-danger"> *&nbsp;&nbsp;</span></label> 
    </div>
  <div class="row application-form-row ">
    <label class="acres-label">Acres</label>
    <select class="acres" placeholder="All" [(ngModel)] = "landRtcTotalAreaAcre" (change)= "onAcreChange(landRtcTotalAreaAcre, 'total_area')" [disabled] = "!isEditable && (data && data.landRtcTotalAreaAcre || data && data.landRtcTotalAreaAcre == 0) ">
      <option *ngFor= "let acre of acres" [value]= "acre.value">{{acre.label}}</option>
    </select>
    <span> &nbsp;&nbsp;</span><label class="guntas-label">Guntas</label>
    <select class="guntas" placeholder="All" [(ngModel)] = "landRtcTotalAreaGunta" (change)= "onGuntaChange(landRtcTotalAreaGunta,'total_area')" [disabled] = "!isEditable && (data && data.landRtcTotalAreaGunta || data && data.landRtcTotalAreaGunta == 0) ">
      <option *ngFor= "let gunta of guntas" [value]= "gunta.value">{{gunta.label}}</option>
    </select>
    <select class="drop-down" placeholder="All" [(ngModel)] = "landRtcTotalAreaDecimalValue" (change)= "onDecimalValueChange(landRtcTotalAreaDecimalValue, 'total_area')" [disabled] = "!isEditable && (data && data.landRtcTotalAreaDecimalValue) ">
      <option *ngFor= "let data of datas" [value]= "data.value">{{data.label}}</option>
    </select>
    <label class="symbol"> = <span class="text-danger">  &nbsp;</span></label>
    <input type="number" [(ngModel)]= "landRtcTotalAreaValue" class="calc-box" [readonly] = "!isEditable"/>
</div>

<div class="row application-form-row">
  <label class="form-label">3b. Kharab Area (in acres):<span class="text-danger"> *&nbsp;&nbsp;</span></label> 
</div>
<div class="row application-form-row ">
  <label class="acres-label">Acres</label>
  <select class="acres" placeholder="All" [(ngModel)] = "landRtcKharabAreaAcre" (change)= "onAcreChange(landRtcKharabAreaAcre, 'kharab_area')" [disabled] = "!isEditable && (data && data.landRtcTotalAreaAcre || data && data.landRtcTotalAreaAcre == 0) ">
    <option *ngFor= "let acre of acres" [value]= "acre.value">{{acre.label}}</option>
  </select>
  <span> &nbsp;&nbsp;</span><label class="guntas-label">Guntas</label>
  <select class="guntas" placeholder="All" [(ngModel)] = "landRtcKharabAreaGunta" (change)= "onGuntaChange(landRtcKharabAreaGunta,'kharab_area')" [disabled] = "!isEditable && (data && data.landRtcTotalAreaGunta || data && data.landRtcTotalAreaGunta == 0) ">
    <option *ngFor= "let gunta of guntas" [value]= "gunta.value">{{gunta.label}}</option>
  </select>
  <select class="drop-down" placeholder="All" [(ngModel)] = "landRtcKharabAreaDecimalValue" (change)= "onDecimalValueChange(landRtcKharabAreaDecimalValue, 'kharab_area')" [disabled] = "!isEditable && (data && data.landRtcKharabAreaDecimalValue || data && data.landRtcKharabAreaDecimalValue == 0) ">
    <option *ngFor= "let data of datas" [value]= "data.value">{{data.label}}</option>
  </select>
  <label class="symbol"> = <span class="text-danger">  &nbsp;</span></label>
  <input type="number" [(ngModel)]= "landRtcKharabAreaValue" class="calc-box" [readonly] = "!isEditable"/>
</div>

<div class="row application-form-row">
  <label class="form-label">3c. Remaining Extent (in acres):<span class="text-danger"> *&nbsp;&nbsp;</span></label> 
</div>
  <div class="row application-form-row ">
    <label class="acres-label">Acres</label>
    <select class="acres" placeholder="All" [(ngModel)] = "landRtcRemainingAcre" (change)= "onAcreChange(landRtcRemainingAcre, 'remaining_extent')" [disabled] = "!isEditable && (data && data.landRtcTotalAreaAcre || data && data.landRtcTotalAreaAcre == 0) ">
      <option *ngFor= "let acre of acres" [value]= "acre.value">{{acre.label}}</option>
    </select>
    <span> &nbsp;&nbsp;</span><label class="guntas-label">Guntas</label>
    <select class="guntas" placeholder="All" [(ngModel)] = "landRtcRemainingGunta" (change)= "onGuntaChange(landRtcRemainingGunta,'remaining_extent')" [disabled] = "!isEditable && (data && data.landRtcTotalAreaGunta || data && data.landRtcTotalAreaGunta == 0) ">
      <option *ngFor= "let gunta of guntas" [value]= "gunta.value">{{gunta.label}}</option>
    </select>
    <select class="drop-down" placeholder="All" [(ngModel)] = "landRtcRemainingDecimalValue" (change)= "onDecimalValueChange(landRtcRemainingDecimalValue, 'remaining_extent')" [disabled] = "!isEditable && (data && data.landRtcRemainingDecimalValue || data && data.landRtcRemainingDecimalValue == 0) ">
      <option *ngFor= "let data of datas" [value]= "data.value">{{data.label}}</option>
    </select>
    <label class="symbol"> = <span class="text-danger">  &nbsp;</span></label>
    <input type="number" [(ngModel)]= "landRtcRemainingValue" class="calc-box" [readonly] = "!isEditable"/>
</div>
<div class="row application-form-row ">
  <label class="form-label">4. Extent in Final Notification(in acres):<span class="text-danger"> * </span></label>
</div>
<div class="row application-form-row">
  <label class="label-row">Acres</label>
  <select class="acres" placeholder="All" [(ngModel)] = "extentFinalNotificationAcre" (change)= "onAcreChange(extentFinalNotificationAcre,'final_notification')" [disabled] = "!isEditable && (data && data.extentFinalNotificationAcre || data && data.extentFinalNotificationAcre == 0) ">
    <option *ngFor= "let acre of acres" [value]= "acre.value">{{acre.label}}</option>
  </select>
  <span> &nbsp;&nbsp;</span><label class="guntas-label">Guntas</label>
  <select class="guntas" placeholder="All" [(ngModel)] = "extentFinalNotificationGunta" (change)= "onGuntaChange(extentFinalNotificationGunta,'final_notification')" [disabled] = "!isEditable && (data && data.extentFinalNotificationGunta || data && data.extentFinalNotificationGunta == 0)">
    <option *ngFor= "let gunta of guntas" [value]= "gunta.value">{{gunta.label}}</option>
  </select>
  <select class="drop-down" placeholder="All" [(ngModel)] = "extentFinalNotificationDecimalValue" (change)= "onDecimalValueChange(extentFinalNotificationDecimalValue,'final_notification')" [disabled] = "!isEditable && (data && data.extentFinalNotificationDecimalValue) ">
    <option *ngFor= "let data of datas" [value]= "data.value">{{data.label}}</option>
  </select>
  <label class="symbol"> = <span class="text-danger">  &nbsp;</span></label>
  <input type="number" [(ngModel)]= "extentFinalNotificationValue" class="label-box" [readonly] = "!isEditable"/>
</div>

<div class="col-sm-12">
  <div class="col-sm-4">
    <textarea  class="form-control comment-box" placeholder="Enter Comments"[(ngModel)]="extentFinalNotificationComment" [disabled] = "!isEditable && (data && data.finalNotificationComment) "></textarea> 
  </div>
  </div>

<div class="row application-form-row ">
  <label class="form-label">5. Difference in extent in Column No. 3 and 4 (in acres):<span class="text-danger"> * </span></label>
</div>
<div class="row application-form-row">
  <label class="label-row">Acres</label>
  <select class="acres" placeholder="All" [(ngModel)] = "extentDifferenceAcre" (change)= "onAcreChange(extentDifferenceAcre,'diff_extent')" [disabled] = "!isEditable && (data && data.extentDifferenceAcre || data && data.extentDifferenceAcre == 0)">
    <option *ngFor= "let acre of acres" [value]= "acre.value">{{acre.label}}</option>
  </select>
  <span> &nbsp;&nbsp;</span><label class="guntas-label">Guntas</label>
  <select class="guntas" placeholder="All" [(ngModel)] = "extentDifferenceGunta" (change)= "onGuntaChange(extentDifferenceGunta,'diff_extent')" [disabled] = "!isEditable && (data && data.extentDifferenceGunta || data && data.extentDifferenceGunta == 0)">
    <option *ngFor= "let gunta of guntas" [value]= "gunta.value">{{gunta.label}}</option>
  </select>
  <select class="drop-down" placeholder="All" [(ngModel)] = "extentDifferenceDecimalValue" (change)= "onDecimalValueChange(extentDifferenceDecimalValue,'diff_extent')" [disabled] = "!isEditable && (data && data.extentDifferenceDecimalValue || data && data.extentDifferenceDecimalValue == 0) ">
    <option *ngFor= "let data of datas" [value]= "data.value">{{data.label}}</option>
  </select>
  <label class="symbol"> = <span class="text-danger">  &nbsp;</span></label>
  <input type="number" [(ngModel)]= "extentDifferenceValue" class="label-box" [readonly] = "!isEditable"/>
</div>

<div class="col-sm-12">
<div class="col-sm-4">
  <textarea  class="form-control comment-box" placeholder="Enter Comments"[(ngModel)]="differentExtentComment" [disabled] = "!isEditable && (data && data.extentComment) "></textarea> 
</div>
</div>

      <div fxLayout="row" style="margin-top: 20px">
          <button fxFlexOffset="70" class="btn btn-next" (click)="onSubmit()">Save & Continue</button>
      </div>
