import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-commercial-building',
  templateUrl: './commercial-building.component.html',
  styleUrls: ['./commercial-building.component.scss']
})
export class CommercialBuildingComponent implements OnInit {

  @Input() data : any;

  @Input() isEditable : any;

  public commArea : number;

  public commFloors : number;

  public commTotalarea : number;

  public commPurpose : string;
  
  public siteNumber : string;

  public buildingAddress : string;

  constructor() { }

  ngOnInit(): void {
  }

  reset(){
    this.commArea = null;
    this.commFloors = null;
    this.commTotalarea = null;
    this.commPurpose = null;
  }

  getPayload(){
    let payload = {};
    payload['commArea'] = this.commArea;
    payload['commFloors'] = this.commFloors;
    payload['commTotalarea'] = this.commTotalarea;
    payload['commPurpose'] = this.commPurpose;
    return payload;
  }

  ngOnChanges(data): void{
    if(!this.data){
      return;
    }
    this.commArea = this.data.commArea ? this.data.commArea.toFixed(2) : this.data.commArea;
    this.commFloors = this.data.commFloors;
    this.commTotalarea = this.data.commTotalarea ? this.data.commTotalarea.toFixed(2) : this.data.commTotalarea;
    this.commPurpose = this.data.commPurpose;
    this.siteNumber = this.data.surveyNumber;
    this.buildingAddress = this.data.buildingAddress;
   }

}
