<div class="container">
  <div>
      <app-top-bar selectedTab="home"></app-top-bar>
      <app-header></app-header>
  </div>
  <br>
  <div class="tool-bar">
      <div class="col-sm-1 ">
          <button class="btn btn-primary" >
              <fa-icon [icon]="faSync" class="close-icon" [spin]="isRefreshing"  (click) ="refreshClick()"></fa-icon>
          </button>
      </div>
      <div style="margin-left: -85px;">
        <button mat-button class="btn btn-primary"(click)="clickBack()">Back</button>
    </div>
      <div class="col-sm-5 title" style="margin-right: -15%;">
          RC & Register Management Module
      </div>

      <br>
       <div class="col-sm-3 offset-sm-1 user-details">
          <div class="value-text">
              Welcome <br>
              {{user['firstName']}} !
          </div>
      </div>
  </div>
  <div>
      <section>
          <div fxLayout="row" fxLayoutAlign="space-between">
              <div class="row justify-content-between">
                  <div class="col-4 title">
                  </div>
              </div>
              <div class="col-sm-3 offset-sm-1">
              </div>
          </div>
          <div class="col-sm-11 title" style="margin-top: -3rem;">
              Activity Log - SD data to CD Data workflow
          </div>
          <div class="tool-bar" style="margin-top: 36px;">
              <div class="col-sm-3" style="margin-top: -3px;">
                  <div fxLayout="row" fxLayoutAlign="space-around">
                 <input class="form-control border-end-0 border rounded-pill" style="font-size: 14px !important;"type="text" [(ngModel)]="applicationNo" placeholder="Search By JCC No" id="applicationNo">
                 <span class="input-group-append"><br>&nbsp;
                  <button mat-button class="btn btn-primary " (click)="searchApplicant()">Search</button>
                  <button mat-button *ngIf="applicationNo" matSuffix mat-icon-button aria-label="Clear" (click)="onClear()">
                     <mat-icon>close</mat-icon>
                   </button>
                 </span>
                 </div>
              </div>
              <div class="col-sm-2 offset-sm-5" style="margin-top: -15px;">
                  <mat-form-field appearance="fill">
                  <mat-label>Village</mat-label>
                  <mat-select id="village" name="villageId" [(ngModel)]="villageId">
                  <mat-option *ngFor="let village of villages" [value]="village.id" (click)="onVillageChange()">
                  {{village.id}} {{village.name}}
                  </mat-option>
                  </mat-select>
                  </mat-form-field>
                  <div class="p1">
                  Total: {{totalSize}}
                  </div>
              </div>
          <div class="col-sm-1 ">
          </div>
      </div>
          <mat-card>
              <table mat-table [dataSource]="dataSource">
                  <ng-container matColumnDef="sn">
                      <th mat-header-cell *matHeaderCellDef> Sl. No </th>
                      <td mat-cell *matCellDef="let element ;let i =index;" class="column-container">{{i+1}}
                  </ng-container>

                  <ng-container matColumnDef="groupNumber">
                    <th mat-header-cell *matHeaderCellDef> Group No </th>
                    <td mat-cell *matCellDef="let element"> 
                      {{element.groupNo}}
                  </td>
                </ng-container>

                <ng-container matColumnDef="groupSize">
                    <th mat-header-cell *matHeaderCellDef> Group Size </th>
                    <td mat-cell *matCellDef="let element"> 
                     {{element.groupSize}}
                  </td>
                </ng-container>

                  <ng-container matColumnDef="jccNo">
                      <th mat-header-cell *matHeaderCellDef> JCC No </th>
                      <td mat-cell *matCellDef="let element"> 
                        <a (click)="onPreviewClick(element.applicantId)" class="preview-link">{{element.applicationNo}}</a> 
                    </td>
                  </ng-container>

                  <!-- Position Column -->
                  <ng-container matColumnDef="applicantName">
                      <th mat-header-cell *matHeaderCellDef> Applicant Name </th>
                      <td mat-cell *matCellDef="let element">
                          {{element.applicantName}}
                      </td>
                  </ng-container>

                  <!-- Name Column -->
                  <ng-container matColumnDef="timeStamp">
                      <th mat-header-cell *matHeaderCellDef> Time Stamp </th>
                      <td mat-cell *matCellDef="let element"> {{element.timeStamp}} </td>
                  </ng-container>
        
                  <ng-container matColumnDef="processedBy">
                      <th mat-header-cell *matHeaderCellDef> Processed By </th>
                      <td mat-cell *matCellDef="let element"> {{element.processedBy}} </td>
                  </ng-container>

                  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                  <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
              </table>
              <div class="no-records-text" *ngIf="!dataSource.data || dataSource.data.length <= 0">No Records Found!
              </div>
              <mat-paginator [pageSize]="pageSize" [pageSizeOptions]="[5, 10, 20]" [showFirstLastButtons]="true"
                  [pageIndex]="currentPage" [length]="totalSize" (page)="getApplicantPaginatorData($event)">
              </mat-paginator>
          </mat-card>
      </section>
  </div>
  <app-footer></app-footer>
