import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { faSync } from '@fortawesome/free-solid-svg-icons';
import { ApplicationService } from 'src/services/application.service';

@Component({
  selector: 'app-committe-reported-out-of-bounds',
  templateUrl: './committe-reported-out-of-bounds.component.html',
  styleUrls: ['./committe-reported-out-of-bounds.component.css']
})
export class CommitteReportedOutOfBoundsComponent  implements OnInit {

  constructor(private applicationService: ApplicationService, 
    private cdr:ChangeDetectorRef, private router: Router,private route: ActivatedRoute,) { 
    }
  public faSync = faSync;
  public villages : any = [];
  // public statusCode:any = 'ready_for_reporting' //used to load sub status options
  public selectVillage:any;
  public statusOptionId: any;
  public colors = ['#E74C3C', '#1ABB9C', '#3498DB', '#F39C12', '#9B59B6', '#50C1CF', '#34495E', '#E9967A', '#FA8072', '#E74C3C','#E74C3C', '#1ABB9C', '#3498DB', '#F39C12', '#9B59B6', '#50C1CF', '#34495E'];

  ngOnInit(): void {
    this.loadReportedOutOfBoundsAggregatedData();
  }

  public loadReportedOutOfBoundsAggregatedData(){
    this.applicationService.fetchApplicantCountForReportedOutOfBoundsByVillage().subscribe(res =>{
      this.villages = res;
    })
  }

  viewReport(): void{
    this.router.navigate(['/view-committee-report']);
  }

  onVillageChange(villageId:any){
    this.selectVillage=villageId;
  }
}
