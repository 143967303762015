import { Component, OnInit, ChangeDetectorRef, Input } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { faSync } from '@fortawesome/free-solid-svg-icons';
import { ApplicationService } from 'src/services/application.service';
import { PageEvent } from '@angular/material/paginator';
import { Location } from '@angular/common';
import { ConfirmDialogService } from 'src/app/shared/confirm-dialog/confirm-dialog.service';
import { MessageDialogService } from 'src/app/shared/message-dialog/message-dialog.service';
import { Router } from '@angular/router';
import { StrorageService } from 'src/services/strorage.service';

@Component({
  selector: 'app-applicant-report-datatable',
  templateUrl: './applicant-report-datatable.component.html',
  styleUrls: ['./applicant-report-datatable.component.scss']
})
export class ApplicantReportDatatableComponent implements OnInit {

  public isRefreshing = false;
  public faSync = faSync;
  public selectedDateFilter: any;
  villages : any[];
  public displayedColumns: string[] = ['reportId','batchName','batchSize','generatedAt','generatedBy','actions'];
  public dataSource = new MatTableDataSource();
  public show = false;
  public pagesize = 5;
  public currentPage = 0;
  public totalSize = 0;
  public selectVillage;
  public applicantCount : any;
  public selectedId : any;
  @Input() statusCode: any;
  public roleCode: any;
  public colors = ['#E74C3C', '#1ABB9C', '#3498DB', '#F39C12', '#9B59B6', '#50C1CF', '#34495E', '#E9967A', '#FA8072', '#E74C3C','#E74C3C', '#1ABB9C', '#3498DB', '#F39C12', '#9B59B6', '#50C1CF', '#34495E'];
  
  constructor(private applicationService: ApplicationService, 
    private location: Location,
    private cdr:ChangeDetectorRef, 
    private confirmDialogService: ConfirmDialogService,
    private router: Router,
    private strorageService: StrorageService,
    private messageDialogService: MessageDialogService) { }

  ngOnInit(): void {
    this.getAllReports();
    let user = this.strorageService.getUser();
    this.roleCode = user['roleCode'];
  }

  public getAllReports(): void {
    this.applicationService.getAllReports(this.statusCode, this.currentPage, this.pagesize).subscribe(res =>{
      this.dataSource.data = res;
      console.log("getAllReports()===============", res);
      this.refreshDataTable(res);

    })
  }

  public refreshClick(): void {
    this.getAllReports();
  }

  public goBack(): void {
    this.location.back();
  }

  public getApplicantPaginatorData(event: PageEvent) {
    this.currentPage = event.pageIndex;
    this.pagesize = event.pageSize;
    this.getAllReports();
  }

  private refreshDataTable = (res: any) => {
     this.show = true;
     this.dataSource.data = res.data;
     this.totalSize = res.totalRecord;
     this.dataSource._updateChangeSubscription();
     this.cdr.detectChanges();
   }

   public transferStatusTo = (row: any, status: any, title: any): void => {

    const options = {
      title: 'Confirm',
      message: 'Do you want to transfer the Batch: '+ row.batchName +' to '+title,
      cancelText:'No',
      confirmText: 'Yes'
    };
    this.confirmDialogService.openDialog(options);
    this.confirmDialogService.confirmed().subscribe(confirmed => {
      if(confirmed) {
        this.applicationService.transferStatus(row.reportId, status).subscribe(res =>{
          console.log("Report Successfully generated");
          this.getAllReports();
        })
      }
    });
   }
  }
