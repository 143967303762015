import { Component, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { faSync } from '@fortawesome/free-solid-svg-icons';
import { ApplicationService } from 'src/services/application.service';
import { MatTableDataSource } from '@angular/material/table';
import { PageEvent } from '@angular/material/paginator';
import { ChangeDetectorRef } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { StrorageService } from 'src/services/strorage.service';
import { ApplicantService } from 'src/app/shared/services/applicant/applicant.service';
import { ApiService } from 'src/services/api.service';
import { forkJoin } from 'rxjs';
import { CommitteReadyForReportingComponent } from '../committe-ready-for-reporting/committe-ready-for-reporting.component';
import { CommitteReportGeneratedComponent } from '../committe-report-generated/committe-report-generated.component';
import { CommitteOutOfBoundComponent } from '../committe-out-of-bound/committe-out-of-bound.component';
import { CommitteReportedOutOfBoundsComponent } from '../committe-reported-out-of-bounds/committe-reported-out-of-bounds.component';

@Component({
  selector: 'app-commitee-reporting',
  templateUrl: './commitee-reporting.component.html',
  styleUrls: ['./commitee-reporting.component.scss']
})
export class CommiteeReportingComponent implements OnInit, AfterViewInit {
  
  public isRefreshing = false;
  public faSync = faSync;
  public selectedDateFilter: any;
  villages : any[];
  public selectVillage;
  public applicantCount : any;
  public selectedId : any;
  public userName = "Username";
  user:any;
  public dateFilters = [
    {
      "label":'Ready for reporting',
      "subtitle":'',
      "tag":"ready_for_reporting",
      "color":"#0062cc", //#4fa65f
      'active':false,
      'count': 0,
      "id":1,
      "class": 'trends-details',
      "width": '33%'
    },
    {
      "label":'Report Generated',
      "subtitle":'',
      "tag":"report_generated",
      "color":"purple",
      'active':false,
      'count': 0,
      "id":2,
      "class": 'trends-details',
      "width": '37%'
    },
    {
      "label":'Out Of Bounds',
      "subtitle":'',
      "tag":"out_of_bounds",
      "color":"#ff2929",
      'active':false,
      'count': 0,
      "id":3,
      "class": 'out-of-bounds',
      "width": '33%',
      "margin-bottom": '25px'
    },
    {
      "label":'Reported - Out Of Bounds',
      "subtitle":'',
      "tag":"reported_out_of_bounds",
      "color":"#0bb470",
      'active':false,
      'count': 0,
      "id":4,
      "class": 'trends-details',
      "width": '37%',
      "margin-bottom": '4%'
    }
  ];
  public showReportGeneratedComponent: boolean = false;
  public showStatusOptionTilesComponent: boolean = false;
  public showReadyForReportingComponent: boolean = false;
  public showOutOfBoundComponent: boolean = false;
  public showReportedOutOfBoundsComponent: boolean = false;
  public readyForReportingApplicantCount: number = 0;
  public reportedApplicantCount: number = 0;
  public selectedTiles: any = 'ready_for_reporting';

  @ViewChild(CommitteReadyForReportingComponent, { static: false })
  public readyForReportingComponent: CommitteReadyForReportingComponent;

  @ViewChild(CommitteReportGeneratedComponent, { static: false })
  public reportGeneratedComponent: CommitteReportGeneratedComponent;

  @ViewChild(CommitteOutOfBoundComponent, { static: false })
  public outOfBoundComponent: CommitteOutOfBoundComponent;

  @ViewChild(CommitteReportedOutOfBoundsComponent, { static: false })
  public reportedOutOfBoundsGeneratedComponent: CommitteReportedOutOfBoundsComponent;


  constructor(private strorageService:StrorageService, private apiService: ApiService, 
    private changeDetector: ChangeDetectorRef) { }

  ngOnInit(): void {
    this.user = this.strorageService.getUser();
    this.userName=this.user['firstName'];
    this.requestCountCall();
  }

  ngAfterViewInit(): void {
    setTimeout (() => {
        this.clickReportFilter(this.selectedTiles);
    }, 500);
  }

  requestCountCall(): void {
    let requests = [];
    requests.push(this.apiService.get('/v1/applicants/status/reported/aggregation'));
    requests.push(this.apiService.get('/v1/applicants/status/ready_for_reporting/aggregation'));
    requests.push(this.apiService.get('/v1/applicants/status/out_of_bounds/aggregation'));
    requests.push(this.apiService.get('/v1/applicants/status/reported_out_of_bounds/aggregation'));
    forkJoin(requests).subscribe(results => {
      this.updateApplicantCount(results[0], results[1], results[2], results[3]);
    });
  }

  updateApplicantCount(reportedData: any, readyForReportingData: any, outOfBoundsData: any, reportedOutOfBoundsData: any): void {
    for(let i =0;i < this.dateFilters.length;i++) {
      if(this.dateFilters[i].tag == 'report_generated') {
        this.dateFilters[i].count = reportedData.applicant_count;
      }
      if(this.dateFilters[i].tag == 'ready_for_reporting') {
        this.dateFilters[i].count = readyForReportingData.applicant_count;
      }
      if(this.dateFilters[i].tag == 'out_of_bounds') {
        this.dateFilters[i].count = outOfBoundsData.applicant_count;
      }
      if(this.dateFilters[i].tag == 'reported_out_of_bounds') {
        this.dateFilters[i].count = reportedOutOfBoundsData.applicant_count;
      }
    }
  }

  refreshClick(): void {
    this.requestCountCall();
    if(this.selectedTiles == 'ready_for_reporting') {
      this.readyForReportingComponent.resetApplicantData();
    }
    if(this.selectedTiles == 'report_generated') {
      this.reportGeneratedComponent.loadReportGeneratedAggregatedData();
    }
     if(this.selectedTiles == 'reported_out_of_bounds') {
      this.reportedOutOfBoundsGeneratedComponent.loadReportedOutOfBoundsAggregatedData();
    }
    if(this.selectedTiles == 'out_of_bounds') {
      this.outOfBoundComponent.loadStatusOptionTilesData();
    }
  }

  clickReportFilter(tag: any){
    this.selectedTiles = tag;
    this.highlightSelectedTiles();
  }

  public highlightSelectedTiles() {
    if(this.selectedTiles == 'report_generated') {
      this.showReportGeneratedComponent = true;
      this.showReadyForReportingComponent = false;
      this.showOutOfBoundComponent = false
      this.showReportedOutOfBoundsComponent = false;
    }  
    if(this.selectedTiles == 'ready_for_reporting') {
      this.showReadyForReportingComponent = true;
      this.showReportGeneratedComponent = false;
      this.showOutOfBoundComponent = false;
      this.showReportedOutOfBoundsComponent = false;
      this.readyForReportingComponent.loadStatusOptionTilesData();
    }
    if(this.selectedTiles == 'out_of_bounds') {
      this.showOutOfBoundComponent = true;
      this.showReadyForReportingComponent = false;
      this.showReportGeneratedComponent = false;
      this.showReportedOutOfBoundsComponent = false;
      this.outOfBoundComponent.loadStatusOptionTilesData();
    }
    if(this.selectedTiles == 'reported_out_of_bounds') {
      this.showReportedOutOfBoundsComponent = true;
      this.showOutOfBoundComponent = false;
      this.showReadyForReportingComponent = false;
      this.showReportGeneratedComponent = false;
      this.reportedOutOfBoundsGeneratedComponent.loadReportedOutOfBoundsAggregatedData();
    }
  }

  back(){
    if(this.showReportGeneratedComponent == true || this.showReadyForReportingComponent == true)
    {
      this.showReportGeneratedComponent = false;
      this.showReadyForReportingComponent = false;
    }
    else{
      history.back();
    }
    
  }
}
