<div class="container">
    <div>
        <app-top-bar selectedTab="home"></app-top-bar>
        <app-header></app-header>
    </div>
    <br>
   
    <div class="tool-bar">
        <div class="refresh-btn">
    
    </div>
    
    <div class="col-sm-1 ">
        <button class="btn btn-primary refresh-btn" (click)="refreshClick()">
            <fa-icon [icon]="faSync" class="close-icon" [spin]="isRefreshing"></fa-icon>
        </button>
     
            </div>
            <div class="col-sm-5 title ">
                RC & Register Management Module
        </div>
                  
                    
        <div class="col-sm-3 offset-sm-1 user-details">
            <div class="value-text">
                Welcome  <br>
            {{user['firstName']}} !
            </div>
            </div>
        </div>

       
    </div> 
    <div class="col-sm-12" style="margin-top: 25px;"> 
        <div class="container">
            <div class="row">
                    <div class="col-sm-3">
                        <button mat-button class="btn btn-primary content-btn btn-width width" (click)="OnDataEnrichmentClick()"
                        id="selectapp" type="button" [ngStyle]="{'background-color':'#0062cc'}">For RC<br>({{forRcApplicantsCount}})</button>
                      </div>
                      <div class="col-sm-3">
                        <button  mat-button  class="btn btn-primary report-btn btn-width width" (click)="OnBatchProcessingClick()"
                        type="button" [ngStyle]="{'background-color':'purple'}">Batch Processing<br>({{batchingReadyApplicantsCount}})</button>
                      </div>
                      <div class="col-sm-3">
                        <button mat-button class="btn btn-primary content-btn btn-width transfer-btn width" (click)="onSentToManipalClick()"
                        type="button" [ngStyle]="{'background-color':'#F4842B '}">Sent to Manipal<br>({{sentToManipalApplicantsCount}})</button>
                      </div>
                      <div class="col-sm-3">
                        <button  mat-button  class="btn btn-primary content-btn btn-width cd-btn width" (click)="onRcReceivedClick()"
                        type="button" [ngStyle]="{'background-color':'#21CAAO'}">Rc Printed & Received<br>({{rcPrintedAndReceivedApplicantsCount}})</button>
                      </div>
              </div>
 </div>
 <app-footer></app-footer>
</div>

