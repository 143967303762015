import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { ApplicationService } from 'src/services/application.service';
import { faTimes} from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-dms-preview-dialog',
  templateUrl: './dms-preview-dialog.component.html',
  styleUrls: ['./dms-preview-dialog.component.scss']
})
export class DmsPreviewDialogComponent implements OnInit {
  public faTimes = faTimes;
  
  constructor(public dialogRef: MatDialogRef<DmsPreviewDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data) {
      console.log('Preview Data', data);
   }

  ngOnInit(): void {
  }

  cancelClick(){
    this.dialogRef.close();
  }
  
}
