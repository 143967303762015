<div class="bgcolor-container">
  <div *ngFor="let sector of sectorData ; let i = index;">
    <mat-card class="mb-1 mr-5 ml-5">
      <div class="container">
        <div class="row ml-2">
          <div class="col-sm-8">
            <div class="row">
            <mat-checkbox class="pt-2" labelPosition="after" [(ngModel)]="sector.checked"
            [checked]="sector.checked" (change)='onSectorSelected($event, sector, i)'></mat-checkbox>
            <h2 class="col-md-11">{{sector.sectorName}}</h2>
          </div>
          </div>
          <div class="col-sm-4" *ngIf="sector.contractorData">
            <div class="d-flex">
              <div>
                <h3>Contractor:</h3>
              </div>
              <div class="p-1">
                <p>{{sector.contractorData.contractorName}}</p>
              </div>
            </div>
            <div class="d-flex">
              <div>
                <h3>Site Office:</h3>
              </div>
              <div class="p-1">
                <p>{{sector.contractorData.siteOfficeLocation}}</p>
              </div>
            </div>
            <div class="d-flex">
              <div>
                <h3>BDA Engineer:</h3>
              </div>
              <div class="p-1">
                <p>{{sector.contractorData.engineerInCharge}}</p>
              </div>
            </div>
        </div>
      </div>
        <div class="row my-5">
          
          <div class="col-sm-4">
            <div class="row">
              <div class="col-sm-6 d-flex justify-content-end">
                <h3>Main Activity: <span class="text-danger">*</span></h3>
              </div>
              <div class="col-sm-6">
                <mat-form-field appearance="fill">
                  <mat-select id="mainActivity" name="mainActivityId" [(ngModel)]="sector.mainActivityId" [disabled]="!sector.checked">
                    <mat-option *ngFor="let mainActivity of mainActivityData" [value]="mainActivity.mainActivityId"
                      (click)="onMainActivityChange(i, sector.mainActivityId, mainActivity.mainActivityName)">
                      {{mainActivity.mainActivityName}}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
            </div>
          </div>
          
        </div>

      </div>  
      <div class="row">
        <div class="col-sm-10">
          <section>
            <table>
              <tr>
                <th>SI.No</th>
                <th>Main Activity</th>
                <th>Task Item</th>
                <th>Work</th>
                <th>Scope</th>
                <th>Units</th>
                <th>Action</th>
              </tr>
              <tr *ngFor="let tasks of sector.taskItems;let v = index">
                <td>{{v + 1}}</td>
                <td>{{tasks.selectedTask.mainActivityName}}</td>
                <td>{{tasks.selectedTask.taskName}}</td>
                <td>{{tasks.workTypeName}}</td>
                <td>{{tasks.selectedTask.length}}</td>
                <td>{{tasks.selectedTask.roadSizeUnit}}</td>
                <td style="cursor:pointer"><mat-icon (click)="onTaskDelete($event, i, v)">delete</mat-icon> </td>
              </tr>
            </table>
          </section>
        </div>
        <div class="col-sm-2">    
          <div class="button-row">
            <input type="button" class="btn btn-warning" value="Add Task" (click)="onAddTaskSelect(sector, i)" [disabled]="!sector.checked"/>
          </div>
        </div>
      </div>
  </mat-card>
  <div class="d-flex justify-content-center">
    <div class="divider w-100"></div>
  </div>
  </div>
</div>
<div class="container adjust-scroll">
  <div class="row">
    <div class="p-2 mr-3">
      <mat-checkbox [(ngModel)]="agreement1Confirmed" ></mat-checkbox>
    </div>
    <div >
      <label class="checkbox-font">All Survey nos selected in all the Sectors cleared for work by respective
        SLAOs</label>
    </div>
  </div>
  
  <div class="row mt-5">
    <div class="col-sm-6  d-flex justify-content-center">
      <button class="btn btn-primary preview-btn" (click)="previewPdf(sectorData)" [disabled]="!agreement1Confirmed">
        Preview Action Plan
      </button>
    </div>
    <div class="col-sm-6  d-flex justify-content-center">
      <button class="btn btn-primary generate-btn" (click)="generatePdf(sectorData)" [disabled]="!agreement1Confirmed">
        Generate Action Plan
      </button>
    </div>
  </div>
</div>