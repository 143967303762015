<div class="container">
    <div>
        <app-top-bar selectedTab="home"></app-top-bar>
        <app-header></app-header>
    </div>
    <br>
   
    <div class="tool-bar">
        <div class="refresh-btn">
            <button class="btn btn-primary refresh-btn" (click)="refreshClick()">
                <fa-icon [icon]="faSync" class="close-icon" [spin]="isRefreshing"></fa-icon>
            </button>
    </div>
    <div class="offset-sm-2">
        <button mat-button class="btn content-btn btn-width w-75 oldNotification" 
        id="selectapp" type="button" (click)="notificationBtnClick('old_notification')" [ngStyle]="{ 'background-color': oldNotificationBtn ?  '#ff0000' : '#a6a6a6;' }">Old Notification ({{oldNotificationApplicantCount}})</button>
    </div>
    <div>
        <button  mat-button  class="btn content-btn btn-width w-75 newNotification"  
        type="button" (click)="notificationBtnClick('new_notification')">New Notification ({{newNotificationApplicantCount}})</button>
    </div>       

        <div class="col-sm-3 user-details">
            <div class="value-text">
                Welcome  <br>
            {{user['firstName']}} !
            </div>
            </div>
        </div>

        
    <div>
        <div class="col-sm-1 ">
        <div> <button mat-button class=" btn btn-primary" (click)="showActivityLog()">Activity Log</button> </div>
    </div>
        <div class="col-sm-3 offset-sm-9">
            
            <div fxLayout="row" fxLayoutAlign="space-around">
            <input class="form-control border-end-0 border rounded-pill" style="font-size: 14px !important;"type="text" [(ngModel)]="applicationNo" placeholder="Search" id="applicationNo">
            <span class="input-group-append"><br>&nbsp;
             <button mat-button class="btn btn-primary " (click)="searchApplicant()">Search</button> 
             <button mat-button *ngIf="applicationNo" matSuffix mat-icon-button aria-label="Clear" (click)="onClear()">
                <mat-icon>close</mat-icon>
              </button>
            </span>
           
            </div>
        </div>
    </div>
    <div class="col-sm-2 offset-sm-9 village-details" >
        <mat-form-field appearance="fill">
            <mat-label>Village</mat-label>
            <mat-select id="village" name="villageId" [(ngModel)]="villageId">
              <mat-option *ngFor="let village of villages" [value]="village.id" (click)="onVillageChange()">
                {{village.id}} {{village.name}}
              </mat-option>
            </mat-select>

          </mat-form-field>
          <div class="p1">
          Total: {{totalSize}}
        </div>
    </div>
<!--            
                <div class="col-sm-11 title">
                    ACTIVITY LOG
                </div> -->
              
            
           
            <!-- data table -->
            <mat-card>
                <table mat-table [dataSource]="dataSource">
                    <ng-container matColumnDef="sn">
                        <th mat-header-cell *matHeaderCellDef> Serial No. </th>
                        <td mat-cell *matCellDef="let element ;let i =index;" class="column-container">{{i+1}}
                          </ng-container>
                    <!-- Position Column -->
                    <ng-container matColumnDef="applicationNo">
                        <th mat-header-cell *matHeaderCellDef> Application No. </th>
                        <td mat-cell *matCellDef="let element">
                            <a (click)="onPreviewClick(element.id)" class="preview-link">{{element.applicationNo}}</a>
                        </td>
                    </ng-container>
                    
                    <!-- Recommendation Name -->
                    <ng-container style=" max-width: 100%;
                    width: 9%;"  matColumnDef="recommendationName">
                     <th mat-header-cell *matHeaderCellDef> Recommended By </th>
                     <td mat-cell *matCellDef="let element" style="text-align: center;">
                        {{element.recommendationName ? element.recommendationName : ''}}
                       </td>
                   </ng-container>
                    
                    <!-- Name Column -->
                    <ng-container matColumnDef="applicantName">
                        <th mat-header-cell *matHeaderCellDef> Name </th>
                        <td mat-cell *matCellDef="let element"> {{element.applicantName}} </td>
                    </ng-container>
                    
                    <!-- Telephone Column -->
                    <ng-container matColumnDef="status">
                        <th mat-header-cell *matHeaderCellDef> Status </th>
                        <td mat-cell *matCellDef="let element"> {{element.status.status}} </td>
                    </ng-container>
                    <ng-container matColumnDef="subStatus">
                        <th mat-header-cell *matHeaderCellDef> Sub Status </th>
                        <td mat-cell *matCellDef="let element"> 
                            <span *ngIf="element.subStatus && element.subStatus.length > 0">{{element.subStatus[0].name}}</span> 
                        </td>
                    </ng-container>
                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns;" ></tr>
                </table>
                <div class="no-records-text" *ngIf="!dataSource.data || dataSource.data.length <= 0">No Records Found!</div>
                <mat-paginator [pageSize]="pageSize" [pageSizeOptions]="[5, 10, 20]" [showFirstLastButtons]="true" 
                [pageIndex]="currentPage" [length]="totalSize" (page)="getApplicantPaginatorData($event)">
            </mat-paginator>
            </mat-card>
 <app-footer></app-footer>
 </div>

