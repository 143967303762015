import { Component, HostListener, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ApplicationService } from 'src/services/application.service';
import { ReadyForReportingDialogboxComponent } from '../ready-for-reporting-dialogbox/ready-for-reporting-dialogbox.component';

@Component({
  selector: 'app-group-applicants-dialogbox',
  templateUrl: './group-applicants-dialogbox.component.html',
  styleUrls: ['./group-applicants-dialogbox.component.scss']
})
export class GroupApplicantsDialogboxComponent implements OnInit {

  applicationId: any;
  public groupNo : any;
  public applicants : any;
  public applicantsCount : any;
  public selectedApplicationId: any;
  public statusApplicantCount: any;

  constructor(private dialogRef: MatDialogRef<GroupApplicantsDialogboxComponent>,private dialog: MatDialog,
    private applicationService: ApplicationService,@Inject(MAT_DIALOG_DATA) 
    public data) { }

  ngOnInit(): void {
    this.groupNo = this.data.groupData.group.groupNo;
    this.applicants = this.data.groupData.applicants;
    this.applicantsCount = this.data.groupData.applicants.length;
    this.statusApplicantCount = this.data.groupData;
    console.log(this.statusApplicantCount);
  }
  
  public onOkClicked = () => {
    let data = {};
    data['applicationId'] = this.selectedApplicationId;
    data['confirmed'] = true;
    this.dialogRef.close(data);
  }

  public onCancelClicked = () => {
    let data = {};
    data['confirmed'] = false;
    this.dialogRef.close(data);
  }

  @HostListener("keydown.esc") 
  public onEsc() {
    this.dialogRef.close(false);
  }
}

