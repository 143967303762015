import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-layout-document-list',
  templateUrl: './layout-document-list.component.html',
  styleUrls: ['./layout-document-list.component.scss']
})
export class LayoutDocumentListComponent implements OnInit {

  public selectedSectorCode:any;

  public title:any;

  public documents:any[] = [];

  public documentsDetails:any = 
    {
      "notification_details":[
        {
          "documentName":"DSKL Final Notification FN Dated  01-11-2018",
          "fileName":"DSKL Final Notification FN Dated  01-11-2018.pdf"
        },
        {
          "documentName":"Recent FN Dated 08-10-2022",
          "fileName":"Recent FN Dated 08-10-2022.pdf"
        }
      ],
      "action_plan": [
        {
          "documentName":"DSKL - Plan of Action for Execution - 25.02.23 _ 26.02.23",
          "fileName":"DSKL - Plan of Action for Execution - 25.02.23 _ 26.02.23.pdf"
        },
        {
          "documentName":"DSKL - Revised Plan of Action for Execution - 24.02.2023",
          "fileName":"DSKL - Revised Plan of Action for Execution - 24.02.2023.pdf"
        },
        {
          "documentName":"DSKL - Work Plan for Execution - 01.03.2023",
          "fileName":"DSKL - Work Plan for Execution - 01.03.2023.pdf"
        },
        {
          "documentName":"DSKL - Work Plan for Execution - 02.03.2023",
          "fileName":"DSKL - Work Plan for Execution - 02.03.2023.pdf"
        },
        {
          "documentName":"DSKL - Work Plan for Execution - 03.03.2023",
          "fileName":"DSKL - Work Plan for Execution - 03.03.2023.pdf"
        },
        {
          "documentName":"DSKL - Work Plan for Execution - 04.03.2023",
          "fileName":"DSKL - Work Plan for Execution - 04.03.2023.pdf"
        },
        {
          "documentName":"DSKL - Work Plan for Execution - 06.03.2023",
          "fileName":"DSKL - Work Plan for Execution - 06.03.2023.pdf"
        },
        {
          "documentName":"DSKL - Work Plan for Execution - 07.03.2023",
          "fileName":"DSKL - Work Plan for Execution - 07.03.2023.pdf"
        },
        {
          "documentName":"DSKL - Work Plan for Execution - 09.03.2023",
          "fileName":"DSKL - Work Plan for Execution - 09.03.2023.pdf"
        },
        {
          "documentName":"DSKL - Work Plan for Execution - 10.03.2023",
          "fileName":"DSKL - Work Plan for Execution - 10.03.2023.pdf"
        },
        {
          "documentName":"DSKL - Work Plan for Execution - 11.03.2023",
          "fileName":"DSKL - Work Plan for Execution - 11.03.2023.pdf"
        },
        {
          "documentName":"DSKL - Work Plan for Execution - 13.03.2023",
          "fileName":"DSKL - Work Plan for Execution - 13.03.2023.pdf"
        },
        {
          "documentName":"DSKL - Work Plan for Execution - 14.03.2023",
          "fileName":"DSKL - Work Plan for Execution - 14.03.2023.pdf"
        },
        {
          "documentName":"DSKL - Work Plan for Execution - 15.03.2023",
          "fileName":"DSKL - Work Plan for Execution - 15.03.2023.pdf"
        },
        {
          "documentName":"DSKL - Work Plan for Execution - 16.03.2023",
          "fileName":"DSKL - Work Plan for Execution - 16.03.2023.pdf"
        },
        {
          "documentName":"DSKL - Work Plan for Execution - 17.03.2023",
          "fileName":"DSKL - Work Plan for Execution - 17.03.2023.pdf"
        },
        {
          "documentName":"DSKL - Work Plan for Execution - 27.02.2023",
          "fileName":"DSKL - Work Plan for Execution - 27.02.2023.pdf"
        },
        {
          "documentName":"DSKL - Work Plan for Execution - 28.02.2023",
          "fileName":"DSKL - Work Plan for Execution - 28.02.2023.pdf"
        }
      ],
      "progress_tackled":[
        {
          "documentName":"Area Tackled - 16-03-2023",
          "fileName":"Area Tackled - 16-03-2023.pdf"
        },
        {
          "documentName":"DSKL Work Progress Dated 15-03-2023 - Village wise",
          "fileName":"DSKL Work Progress Dated 15-03-2023 - Village wise.xlsx"
        },
        {
          "documentName":"Progress Report as on 08.03.2023",
          "fileName":"Progress Report as on 08.03.2023.xlsx"
        }
      ],
      "land_hand_over_status":[
        {
          "documentName":"SLAO - Dr. Sahana",
          "fileName":"SLAO - Dr. Sahana.pdf"
        },
        {
          "documentName":"SLAO - Praveen",
          "fileName":"SLAO - Praveen.pdf"
        },
        {
          "documentName":"SLAO - Dr. Harish Naik",
          "fileName":"SLAO - Dr. Harish Naik.pdf"
        },
        {
          "documentName":"SLAO - Nandini",
          "fileName":"SLAO - Nandini.pdf"
        },
        {
          "documentName":"SLAO - Dr. Basanthi",
          "fileName":"SLAO - Dr. Basanthi.pdf"
        },
        {
          "documentName":"SLAO - Dr. Kavitha Yogappanavar",
          "fileName":"SLAO - Dr. Kavitha Yogappanavar.pdf"
        },
        {
          "documentName":"SKL - Land Status - Comprehensive",
          "fileName":"SKL - Land Status - Comprehensive.pdf"
        },
        {
          "documentName":"SLAO - Nikhita",
          "fileName":"SLAO - Nikhita.pdf"
        }
      ],
    }
  previousHeading: any;

  constructor(public activatedRoute: ActivatedRoute,private router: Router) {
    this.selectedSectorCode = this.activatedRoute.snapshot.queryParams["selectedSectorCode"];
    this.title = this.activatedRoute.snapshot.queryParams["title"];
    this.previousHeading = this.activatedRoute.snapshot.queryParams["previousHeading"]
  }

  ngOnInit(): void {
    this.documents = this.documentsDetails[this.selectedSectorCode];
  }

  onSectorTileClicked = (item: any) => {
    let filePath:any = this.selectedSectorCode+"/"+item.fileName;
    window.open(environment.BASE_URL+'layout_formation/'+filePath);
  }
}
