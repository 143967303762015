<div class="content-area">
    <div class="container">
        <div class="row"> 
    <div class="col-sm-10 offset-sm-1">
        <app-top-bar selectedTab="home"></app-top-bar>
        <app-header></app-header>
    </div>
    <div class="col-sm-10 offset-sm-1"> 
        <div class="container">
            <div class="row">
                <div class="col-sm-1 leftalign">
                    <button class="btn btn-primary refresh-btn" (click)="refreshClick()">
                        <fa-icon [icon]="faSync" class="close-icon" [spin]="isRefreshing"></fa-icon>
                    </button>
                </div>
                <div class="col-sm-3 leftalign">
                    <button class="btn btn-primary back-btn" (click)="goBack()">Back</button>
                </div>
            </div>
            <div class="row">
                <div class="col-md-12"> 
                    <!-- data table -->
                    <mat-card>
                        <table mat-table style="width:100%" [dataSource]="dataSource">
                            <ng-container matColumnDef="reportId">
                                <th mat-header-cell *matHeaderCellDef> Serial No </th>
                                <td mat-cell *matCellDef="let element ;let i =index;" class="column-container">{{i+1}}
                            </ng-container>
                            
                            <!-- --batchName-- -->
                            <ng-container matColumnDef="batchName">
                                 <th mat-header-cell *matHeaderCellDef class ="data-header"> Report Name </th>
                                 <td mat-cell *matCellDef="let element" class="column-container">
                                 {{element.batchName}}
                             </ng-container>

                            <!-- <ng-container matColumnDef="reportName">
                                <th mat-header-cell *matHeaderCellDef class ="data-header"> Report Name </th>
                                <td mat-cell *matCellDef="let element" class="column-container">
                                {{element.reportName}}
                            </ng-container> -->
                
                            <ng-container matColumnDef="generatedBy">
                                <th mat-header-cell *matHeaderCellDef class ="data-header"> Generated By </th>
                                <td mat-cell *matCellDef="let element" class="column-container">
                                {{element.generatedBy}}
                            </ng-container>
                
                            <ng-container matColumnDef="generatedAt">
                                <th mat-header-cell *matHeaderCellDef class ="data-header"> Generated At </th>
                                <td mat-cell *matCellDef="let element" class="column-container">
                                {{element.generatedAt}}
                            </ng-container>

                            <!-- --batchSize-- -->
                            <ng-container matColumnDef="batchSize">
                                <th mat-header-cell *matHeaderCellDef class ="data-header"> Batch Size</th>
                                <td mat-cell *matCellDef="let element" class="column-container">
                                {{element.batchSize}}
                            </ng-container>
                
                            <ng-container matColumnDef="actions">
                                <th mat-header-cell *matHeaderCellDef class ="data-header"> Actions </th>
                                <td mat-cell *matCellDef="let element" class="column-container">
                                    <a target="_blank" href="{{element.fileURL}}" class="preview-link pdf-btn">
                                        <mat-icon aria-label="picture_as_pdf">picture_as_pdf</mat-icon>
                                    </a>
                                </td>
                            </ng-container>
                
                            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                            <tr mat-row *matRowDef="let row; columns: displayedColumns;" ></tr>
                        </table>
                        <div class="no-records-text" *ngIf="!dataSource.data || dataSource.data.length <= 0">No Records Found!</div>
                        <mat-paginator [pageSize]="pagesize" [pageSizeOptions]="[5, 10, 20]" [showFirstLastButtons]="true" [length]="totalSize"
                            [pageIndex]="currentPage" (page)="getApplicantPaginatorData($event)">
                        </mat-paginator>
                    </mat-card>
                </div>
            </div>
        </div>
    </div>
    </div>
    </div>
    <app-footer class="col-md-10 offset-sm-3" ></app-footer>
</div> 
    