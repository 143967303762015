<div fxFlex="100%">
  <div class="row application-form-row col-sm-12 col-md-12">
    <label class="form-label"> 10. Whether land has been handed over to Engineering department :  <span class="text-danger"> * </span></label>
    <div class="row radio-rows col-sm-6 offset-7">
        <input id="handOverToDepartment" type="radio" [disabled] = "(data && data.handOverToDepartment) && !isEditable" [(ngModel)]="handOverToDepartment" value="Yes" name="handOverToDepartment" />
            <label for="single" class="radio-text">Yes </label>
        <input id="handOverToDepartment" type="radio" [disabled] = "(data && data.handOverToDepartment) && !isEditable" [(ngModel)]="handOverToDepartment" value="No" name="handOverToDepartment" />
            <label for="joint" class="radio-text">No</label>
    </div>
  </div>
  <!-- 10. Do structure exist on the land :   -->
  <div class="row application-form-row col-sm-12 col-md-12" >
    <label class="form-label">10(a). Do buildings exist on the land : <span class="text-danger"> * </span></label>
    <div class="row radio-row6 col-sm-6 offset-7">
        <input id="landStructureExist" type="radio" [disabled] = "(data && data.landStructureExist) && !isEditable" [(ngModel)]="landStructureExist" value="Yes" name="landStructureExist" />
            <label for="single" class="radio-text">Yes </label>
        <input id="landStructureExist" type="radio" [disabled] = "(data && data.landStructureExist) && !isEditable" [(ngModel)]="landStructureExist" value="No" name="landStructureExist"/>
            <label for="joint" class="radio-text">No</label>
    </div>
    </div>
  <!-- 10 (b). If Yes, No of buildings as per 4(1) Mahazar:-->
  <div class="row application-form-row col-sm-12 col-md-12" *ngIf="landStructureExist == 'Yes'  ">
    <div class="col-sm-6 offset-12">
    <label class="form-label" >10 (b). If Yes, No of buildings as per 4(1) Mahazar: </label>
    </div>
    <div class="col-sm-6">
    <input type="string"  [(ngModel)]="noOfStructureYesValue" class="form-textbox" [readonly] = "!isEditable && (data && data.noOfStructureYesValue) " />
      </div>
  </div>

    <!-- 10 (c). No. of buildings as per 6(1) Mahazar : -->
    <div class="row application-form-row col-sm-12 col-md-12" *ngIf="landStructureExist == 'Yes'">
      <div class="col-sm-6 offset-12">
      <label class="form-label" >10 (c). No. of buildings as per 6(1) Mahazar : </label>
      </div>
      <div class="col-sm-6">
      <input type="string"  [(ngModel)]="noOfStructureAsPerMahazar" class="form-textbox" [readonly] = "!isEditable && (data && data.noOfStructureAsPerMahazar)" />
        </div>
    </div>

      <!--10 (d). No. of buildings as per award sketch of surveyor:  -->
  <div class="row application-form-row col-sm-12 col-md-12" *ngIf="landStructureExist == 'Yes'">
    <div class="col-sm-6 offset-12">
    <label class="form-label" >10 (d). No. of buildings as per award sketch of surveyor:  </label>
    </div>
    <div class="col-sm-6">
    <input type="string" [(ngModel)]="noOfStructureAsPerAwardSketch" class="form-textbox" [readonly] = "!isEditable && (data && data.noOfStructureAsPerAwardSketch)"  />
      </div>
  </div>

    <!-- 10 (e). No. of buildings discussed in the award :-->
    <div class="row application-form-row col-sm-12 col-md-12" *ngIf="landStructureExist == 'Yes'">
      <div class="col-sm-6 offset-12">
      <label class="form-label" >10 (e). No. of buildings discussed in the award : </label>
      </div>
      <div class="col-sm-6">
      <input type="string" [(ngModel)]="noOfStructureDiscussedInAward" class="form-textbox" [readonly] = "!isEditable && (data && data.noOfStructureDiscussedInAward) " />
        </div>
    </div>

  <!--  10 (f). Has building valuation been done:   -->
  <div class="row application-form-row col-sm-12 col-md-12" *ngIf="landStructureExist == 'Yes'">
    <div class="col-sm-6 offset-12">
    <label class="form-label"> 10 (f). Has building valuation been done:   </label>
    </div>
    <div class="row radio-row1 col-sm-6 offset-7">
        <input id="buildingValuationDone" type="radio"  [disabled] = "(data && data.buildingValuationDone) && !isEditable" [(ngModel)]="buildingValuationDone" value="Yes" name="buildingValuationDone" />
            <label for="single" class="radio-text">Yes </label>
        <input id="buildingValuationDone" type="radio" [disabled] = "(data && data.buildingValuationDone) && !isEditable" [(ngModel)]="buildingValuationDone" value="No" name="buildingValuationDone"/>
            <label for="joint" class="radio-text">No</label>
    </div>
  </div>
<!-- 10 (g). Whether award includes payment of compensation for buildings : -->
<div class="row application-form-row col-sm-12 col-md-12" *ngIf="landStructureExist == 'Yes'">
  <div class="col-sm-6 offset-12">
  <label class="form-label"> 10 (g). Whether award includes payment of compensation for buildings :</label>
  </div>
  <div class="row radio-row2 col-sm-6 offset-7">
      <input id="awardCompensationForStructures" type="radio"  [disabled] = "(data && data.awardCompensationForStructures) && !isEditable" [(ngModel)]="awardCompensationForStructures" value="Yes" name="awardCompensationForStructures" />
          <label for="single" class="radio-text">Yes </label>
      <input id="awardCompensationForStructures" type="radio" [disabled] = "(data && data.awardCompensationForStructures) && !isEditable" [(ngModel)]="awardCompensationForStructures" value="No" name="awardCompensationForStructures"/>
          <label for="joint" class="radio-text">No</label>
  </div>
</div>
<!-- 10 (h). Whether possession of buildings have been taken : -->

<div class="row application-form-row col-sm-12 col-md-12" *ngIf="landStructureExist == 'Yes'">
<div class="col-sm-6 offset-12">
<label class="form-label"> 10 (h). Whether possession of buildings have been taken :</label>
</div>
<div class="row radio-row3 col-sm-6 offset-7">
    <input id="structureHasBeenTaken" type="radio"  [disabled] = "(data && data.structureHasBeenTaken) && !isEditable" [(ngModel)]="structureHasBeenTaken" value="Yes" name="structureHasBeenTaken" />
        <label for="single" class="radio-text">Yes </label>
    <input id="structureHasBeenTaken" type="radio" [disabled] = "(data && data.structureHasBeenTaken) && !isEditable" [(ngModel)]="structureHasBeenTaken" value="No" name="structureHasBeenTaken"/>
        <label for="joint" class="radio-text">No</label>
</div>
</div>

<!-- 10 e. Whether structure have been handed over to Engineering Department: -->
<div class="row application-form-row col-sm-12 col-md-12" *ngIf="landStructureExist == 'Yes'">
<div class="col-sm-6 offset-12">
<label class="form-label"> 10 (i). Whether possession of buildings have been over to Engineering Department : </label>
</div>
<div class="row radio-row9 col-sm-6 offset-7">
    <input id="handOverToDepartment" type="radio"  [disabled] = "(data && data.possessionOfStructureHandToEnggDept) && !isEditable" [(ngModel)]="possessionOfStructureHandToEnggDept" value="Yes" name="possessionOfStructureHandToEnggDept"/>
        <label for="single" class="radio-text">Yes </label>

    <input id="handOverToDepartment" type="radio" [disabled] = "(data && data.possessionOfStructureHandToEnggDept) && !isEditable" [(ngModel)]="possessionOfStructureHandToEnggDept" value="No" name="possessionOfStructureHandToEnggDept"/>
        <label for="joint" class="radio-text">No</label>
</div>
</div>

<!-- 10 (j). Whether any applications filed before JCC  : -->
<div class="row application-form-row col-sm-12 col-md-12" *ngIf="landStructureExist == 'Yes'">
  <div class="col-sm-6 offset-12">
  <label class="form-label"> 10 (j). Whether any applications filed before JCC  : </label>
  </div>
  <div class="row radio-row4 col-sm-6 offset-7">
      <input id="applicationsFilled" type="radio" [disabled] = "(data && data.applicationsFilled) && !isEditable" [(ngModel)]="applicationsFilled" value="Yes" name="applicationsFilled" />
          <label for="single" class="radio-text">Yes </label>
      <input id="applicationsFilled" type="radio" [disabled] = "(data && data.applicationsFilled) && !isEditable" [(ngModel)]="applicationsFilled" value="No" name="applicationsFilled"/>
          <label for="joint" class="radio-text">No</label>
  </div>
</div>

<!-- 10 (k). If Yes, number: -->
<div class="row application-form-row col-sm-12 col-md-12" *ngIf="applicationsFilled == 'Yes' && landStructureExist == 'Yes'">
  <div class="col-sm-6 offset-12">
  <label class="form-label" >10 (k). If Yes, number:  </label>
  </div>
  <div class="col-sm-6">
    <input type="string" [(ngModel)]="applicationsFilledYesValue" class="form-textbox" [readonly] = "(data && data.applicationsFilledYesValue) && !isEditable" />
  </div>
</div>

<!-- 10 h. Whether under 40/60 scheme,compension for the foot print of the structure has been omitted: -->
<div class="row application-form-row col-sm-12 col-md-12" *ngIf="landStructureExist == 'Yes'">
<div class="col-sm-6 offset-12">
<label class="form-label"> 10(l). Whether under 40/60 scheme,compension for the foot print of the structure has been omitted: </label>
</div>
<div class="row radio-row7 col-sm-6 offset-7">
    <input id="compensationForFootprintOmitted" type="radio"  [disabled] = "(data && data.compensationForFootprintOmitted) && !isEditable" [(ngModel)]="compensationForFootprintOmitted" value="Yes" name="compensationForFootprintOmitted" />
        <label for="single" class="radio-text">Yes </label>
    <input id="compensationForFootprintOmitted" type="radio" [disabled] = "(data && data.compensationForFootprintOmitted) && !isEditable" [(ngModel)]="compensationForFootprintOmitted" value="No" name="compensationForFootprintOmitted"/>
        <label for="joint" class="radio-text">No</label>
</div>
</div>

<!--  11. Whether Entitlement Certificate issued:  -->
<div class="row application-form-row col-sm-12 col-md-12">
<label class="form-label"> 11. Whether Entitlement Certificate issued : <span class="text-danger"> * </span></label>
<div class="row radio-row6 col-sm-6 offset-7">
    <input id="entitlementCertificateIssued" type="radio" [disabled] = "(data && data.entitlementCertificateIssued) && !isEditable" [(ngModel)]="entitlementCertificateIssued" value="Yes" name="entitlementCertificateIssued" />
        <label for="single" class="radio-text">Yes </label>
    <input id="entitlementCertificateIssued" type="radio" [disabled] = "(data && data.entitlementCertificateIssued) && !isEditable" [(ngModel)]="entitlementCertificateIssued" value="No" name="entitlementCertificateIssued"/>
        <label for="joint" class="radio-text">No</label>
</div>
</div>

<!-- 11 a. If  Yes :  -->
<div class="row application-form-row col-sm-12 col-md-12" *ngIf="entitlementCertificateIssued == 'Yes'">
<div class="col-sm-6 offset-12">
<label class="form-label" >11(a). If Yes : </label>
</div>
<div class="col-sm-6">
<select class="form-textbox"  [disabled] = "(data && data.entitlementCertificateIssuedYesValue) && !isEditable" [(ngModel)] = "entitlementCertificateIssuedYesValue" [disabled]="data && data.entitlementCertificateIssuedYesValue && !isEditable" >
  <option *ngFor = "let option of options" [value]="option.id">{{option.codeValue}}</option>
</select>
</div>
</div>

  <!-- 11(b). Whether EC issued by SLAO is Regular  -->
<div class="row application-form-row col-sm-12 col-md-12" *ngIf="entitlementCertificateIssued == 'Yes'">
  <div class="col-sm-6 offset-12">
    <label class="form-label">11(b). Whether EC issued by SLAO is Regular : </label>
  </div>
  <div class="row radio-row6 col-sm-6 offset-7">
    <input id="ifEcRegular" type="radio" [disabled] = "(data && data.ifEcRegular) && !isEditable" [(ngModel)]="ifEcRegular" value="Yes" name="ifEcRegular" />
        <label for="single" class="radio-text">Yes</label>
    <input id="ifEcRegular" type="radio" [disabled] = "(data && data.ifEcRegular) && !isEditable" [(ngModel)]="ifEcRegular" value="No" name="ifEcRegular"/>
        <label for="joint" class="radio-text">No</label>
</div>
</div>

  <!-- 11(c). If No (EC Issued by SLAO is Irregular), Explain the Irregularity​  -->
<div class="col-sm-12" *ngIf="entitlementCertificateIssued == 'Yes' && ifEcRegular == 'No'">
  <div class="col-sm-6 offset-12 alignFormLabel">
    <label class="form-label">11(c). If No (EC Issued by SLAO is Irregular), Explain the Irregularity​</label>
  </div>
  <div class="col-sm-4">
    <textarea class="form-control comment-box" placeholder="Nature of Irregularity" [(ngModel)]="explainIrregularity"
      [disabled]="!isEditable && (data && data.explainIrregularity) "></textarea>
  </div>
</div>

<!-- 11 b. Extent: -->
<div class="row application-form-row" *ngIf="entitlementCertificateIssued == 'Yes'">
  <label class="form-label" style="margin-left: 31px;">11(d). If so, Extent Indicated in EC <span class="text-danger"> *&nbsp;&nbsp;</span></label> 
</div>
<div class="row application-form-row" *ngIf="entitlementCertificateIssued == 'Yes'">
  <label class="acres-label">Acres</label>
  <select class="acres" placeholder="All" [(ngModel)] = "entitlementExtentAcre" (change)= "onAcreChange(entitlementExtentAcre)" [disabled] = "!isEditable && (data && data.entitlementExtentAcre || data && data.entitlementExtentAcre == 0)">
    <option *ngFor= "let acre of acres" [value]= "acre.value">{{acre.label}}</option>
  </select>
  <span> &nbsp;&nbsp;</span><label class="guntas-label">Guntas</label>
  <select class="guntas" placeholder="All" [(ngModel)] = "entitlementExtentGunta" (change)= "onGuntaChange(entitlementExtentGunta)" [disabled] = "!isEditable && (data && data.entitlementExtentGunta || data && data.entitlementExtentGunta == 0)">
    <option *ngFor= "let gunta of guntas" [value]= "gunta.value">{{gunta.label}}</option>
  </select>
  <select class="drop-down" placeholder="All" [(ngModel)] = "entitlementExtentDecimalValue" (change)= "onDecimalValueChange(entitlementExtentDecimalValue)" [disabled] = "!isEditable && (data && data.entitlementExtentDecimalValue || data && data.entitlementExtentDecimalValue == 0) ">
    <option *ngFor= "let data of datas" [value]= "data.value">{{data.label}}</option>
  </select>
  <label class="symbol"> = <span class="text-danger">  &nbsp;</span></label>
  <input type="number" [(ngModel)]= "entitlementExtentValue" class="calc-box" [readonly] = "!isEditable"/>
</div>

<div class="col-sm-12" *ngIf="entitlementCertificateIssued == 'Yes'">
  <div class="col-sm-4">
    <textarea  class="form-control comment-box" placeholder="Enter Comments"[(ngModel)]="comment" [disabled] = "!isEditable && (data && data.sdComment) "></textarea> 
  </div>
  </div>


<!-- Save & Continue -->
<div fxLayout="row" style="margin-top: 20px">
  <button fxFlexOffset="70" class="btn btn-next" (click)="onSubmit()">Save & Continue</button>
</div>
</div>
