<div class="row">
    <div class="col-sm-12">
<div class="content-area">
<div class="profile-area">
    <img [src]="imagePath" class="image"/>
</div>
<div class="text-area">
<div class="row">
    <div class="name col-sm-11">
{{name}}
    </div>
</div>

</div>
<div class="row content">
    <div class="text col-sm-11" >
       <span [class.description-less]="!isReadMore"  [class.description-more]="isReadMore">{{content}}</span> 
      <span class="underline" (click)="onReadMoreClick()">{{isReadMore ? 'Read Less' : 'Read More'}}</span>..
    </div>
</div>
</div>
</div>
</div>