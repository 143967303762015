<div class="container">
  <div>
      <app-top-bar selectedTab="home"></app-top-bar>
      <app-header></app-header>
  </div>
  <br>
  <div class="">
    <div class="row">
      <div class="col-sm-3 d-flex align-items-center justify-content-between">
        <button mat-button class="btn btn-primary" (click)="clickBack()">Back</button>
        <button mat-button class="btn btn-primary" (click)="showActivityLog()">Activity Log</button>
      </div>
      <div class="col-sm-7">
        <h1 class="text-center heading-align">DAP - Generation - Workbench</h1>
      </div>
      <div class="col-sm user-details">
        <div class="value-text">
          Welcome <br>
          {{firstName}} !
        </div>
      </div>
      <div class="w-100"></div>
      <div class="mb-2 row w-100">
        <div class="col-sm-6">
          <div class="d-flex justify-content-center">
            <mat-label class="min-height-lbl my-4">From date<span class="text-danger">*</span></mat-label>
            <mat-form-field appearance="fill">
              <input matInput [(ngModel)]="startDate" class="h-50" [max]="toDate ? toDate : maxDate"  (dateChange)="onStartDateChangeEvent($event)"  [matDatepicker]="fromDatePicker" readonly>
              <mat-datepicker-toggle matSuffix [for]="fromDatePicker"></mat-datepicker-toggle>
              <mat-datepicker #fromDatePicker ></mat-datepicker>
            </mat-form-field>
          </div>
        </div>
        <div class="col-sm-6">
          <div class="d-flex justify-content-start">
            <mat-label class="min-height-lbl mt-4">To date<span class="text-danger">*</span></mat-label>
            <mat-form-field appearance="fill">
              <input matInput [(ngModel)]="toDate" class="h-50" [min]="startDate" [max]="toDate ? toDate : maxDate" (dateChange)="onEndDateChangeEvent($event)"  [matDatepicker]="toDatePicker" readonly>
              <mat-datepicker-toggle matSuffix [for]="toDatePicker"></mat-datepicker-toggle>
              <mat-datepicker #toDatePicker ></mat-datepicker>
            </mat-form-field>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="d-flex justify-content-center">
    <div class="divider"></div>
  </div>
  <app-sector-container [startDate]="startDateFormat" [endDate] = "endDateFormat"></app-sector-container>
  <app-footer></app-footer>
</div>