    <div class="col-sm-10 offset-sm-1">
        <app-top-bar selectedTab="home"></app-top-bar>
        <app-header></app-header>

        <div class="col-sm-12 root-container"> 
            <div class="container">
                <div class="row">
                  <div class="col-sm-1 refresh-btn-container">
                    <button class="btn btn-primary" (click)="refreshClick()">
                        <fa-icon [icon]="faSync" [spin]="isRefreshing"></fa-icon>
                    </button>
                  </div>

                  <div class="col-sm-7">
                        <div class="text">
                          Transfer to BDA
                        </div>
                    </div>
                
         <div class="col-sm-4">
            <div class=" user-details">
                <div class="value-text">
                    Welcome 
                </div>
                <div class="value-text">
                     {{userName}} !
                </div>
            </div>
            </div>
               
        
            <div class="container">
                <div class="row">
       
          <!-- --<Post SC,Received by JCC>--   -->
      <div class="col-sm-6">
           <button  mat-button  class="btn btn-primary content-btn btns-width" (click)="onPostscReceivedbyJccClick()" 
           id ="selectedapp"type="button" [ngStyle]="{'background-color':'primary'}">Post SC,Received by JCC</button>
    </div>
         <!-- --<JCC to BDA>--   -->  
        <div class="col-sm-6">
            <button  mat-button  class="btn btn-primary content-btn btns-width" (click)="onJCCtoBDAClick()" 
            id ="selectedapp"type="button" [ngStyle]="{'background-color':'DarkOrange'}">JCC to BDA</button>         
</div>


            <!-- <table> -->
           <div class="row">
            <div class="col-md-12"> 
             <mat-card>
            <table mat-table style="width:100%" [dataSource]="dataSource">
          <ng-container matColumnDef="reportId">
                <th mat-header-cell *matHeaderCellDef> SI No </th>
                <td mat-cell *matCellDef="let element ;let i =index;" class="column-container">{{i+1}}
             </ng-container> 
                      
                       
            <ng-container matColumnDef="batchName">
                <th mat-header-cell *matHeaderCellDef class ="data-header" > Batch Name </th>
                <td mat-cell *matCellDef="let element"class="column-container">
                 {{element.batchName}} 
             </ng-container> 
                    
                     
            <ng-container matColumnDef="batchSize">
                <th mat-header-cell *matHeaderCellDef  class ="data-header"> Batch Size </th>
                <td mat-cell *matCellDef="let element" class="column-container"> 
                 {{element.batchSize}}
            </ng-container>  
                       
                    
           <ng-container matColumnDef="receivedOn">
                <th mat-header-cell *matHeaderCellDef class ="data-header" > Received On  </th>
                <td mat-cell *matCellDef="let element" class="column-container">
                 
            </ng-container> 
    
                       
            <ng-container matColumnDef="generatedBy">
                <th mat-header-cell *matHeaderCellDef class ="data-header" > Sent by </th>
                <td mat-cell *matCellDef="let element" class="column-container">
                {{element.generatedBy}} 
            </ng-container>  
    
                      
            <ng-container matColumnDef="generatedAt">
                            <th mat-header-cell *matHeaderCellDef class ="data-header" > Sent On </th>
                            <td mat-cell *matCellDef="let element"class="column-container">
                                 {{element.generatedAt}} 
            </ng-container>
            
            <ng-container matColumnDef="actions">
                <th mat-header-cell *matHeaderCellDef class ="data-header"> Actions </th>
                <td mat-cell *matCellDef="let element" class="column-container">
                    <a target="_blank" href="{{element.fileURL}}" class="preview-link pdf-btn">
                        <mat-icon aria-label="picture_as_pdf">picture_as_pdf</mat-icon>
                    </a>
                    <button *ngIf="statusCode == 'reported'" class="btn btn-primary" (click)="transferStatusTo(element, 'sc_ordered_jcc_to_bda_commr', 'BDA')">Transfer to BDA</button>
                   </td>
            </ng-container>
                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
             </table>
             <div class="no-records-text" *ngIf="!dataSource.data || dataSource.data.length <= 0">No Records Found!</div>
                        <mat-paginator [pageSize]="pagesize" [pageSizeOptions]="[5, 10, 20]" [showFirstLastButtons]="true" [length]="totalSize"
                            [pageIndex]="currentPage" (page)="getApplicantPaginatorData($event)">
                        </mat-paginator>
            </mat-card> </div>
</div> 

 </div>
 </div>
</div>
</div><app-footer class="col-md-10 offset-sm-3" ></app-footer>



