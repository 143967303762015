import { Component, OnInit } from '@angular/core';
import { ApplicationService } from 'src/services/application.service';

@Component({
  selector: 'app-lf-chart',
  templateUrl: './lf-chart.component.html',
  styleUrls: ['./lf-chart.component.scss']
})
export class LFChartComponent implements OnInit {

  constructor(private applicationService: ApplicationService) { }

  heading = 'Dr. K. Shivarama Karantha Layout (DSKL), Bengaluru';
  subHeading = 'Short Term Activity Execution of RoadSide Drain (RSD) in All Sectors';
  
  ngOnInit() {
  }

}
