<div class="row content-area">
<div fxFlex="40" fxFlexOffset="60">
    <div [hidden]="cameraChoosen">
        <div class="snapshot"><img [src]="profileImg" id="profile-pic"/> </div>
    </div>
    <div [hidden]="!cameraChoosen || (data && data.propertyImage)">
        <webcam [height]="250"  
        [trigger]="triggerObservable" 
            (imageCapture)="handleImage($event)"> 
        </webcam>
    </div>
</div>
<div fxFlex="40" fxFlexOffset="60">
    <div fxLayout="row" fxLayoutAlign="space-around center">
        <div [hidden]="cameraChoosen || (data && data.propertyImage)">
                <button class="btn btn-primary actionBtn" (click)="chooseCamera()">Take Snap</button> 
            </div>
        <div [hidden]="!cameraChoosen || (data && data.propertyImage)">
            <button class="btn btn-primary actionBtn" (click)="triggerSnapshot()">Capture</button> 
        </div>
        <div [hidden]="data && data.propertyImage">
            <button class="btn btn-primary actionBtn" (click)="fetchFromGallery()"> Gallery</button> 
        </div>
        <div [hidden]="data && data.propertyImage">
            <button class="btn btn-primary actionBtn">  
                <fa-icon [icon]="faTrash" class="close-icon" (click)="removePicture()"></fa-icon>
            </button> 
        </div>
    </div>
</div>
<input type="file" id="image-gallery" accept="image/png, image/jpeg" (change)="onGalleryImageChoosen($event)" [hidden]="true">

            <div class="col-sm-12 col-md-12">
                <div class="row application-form-row ">
                    <label class="form-label">Survey/Site No / ಸರ್ವೆ ಸಂಖ್ಯೆ/ನಿವೇಶನದ ಸಂಖ್ಯೆ. :</label>
                    <input type="text" [(ngModel)]="siteNo" readonly class="form-textbox" />
                </div>
            </div>

            <div class="col-sm-12 col-md-12">
                <div class="row application-form-row ">
                    <label class="form-label">Length of the site  (in ft.) / ಉದ್ದ :</label>
                    <input type="number" [(ngModel)]="siteLength" step="any" class="form-textbox" [readonly] = "(data && data.siteLength) && !isEditable"/>
                </div>
            </div>
            <div class="col-sm-12 col-md-12">
                <div class="row application-form-row ">
                    <label class="form-label">Width of the site (in ft.) / ಅಗಲ :</label>
                    <input type="number" [(ngModel)]="siteBreadth" step="any" class="form-textbox" [readonly] = "(data && data.siteBreadth) && !isEditable"/>
                </div>
            </div>
            <div class="col-sm-12 col-md-12">
                <div class="row application-form-row ">
                    <label class="form-label">Total area (in Sq.ft) / ಒಟ್ಟು ವಿಸ್ತೀರ್ಣ ಚದರ  
                        ಅಡಿಗಳಲ್ಲಿ :</label>
                    <input type="number" [(ngModel)]="siteArea" step="any" class="form-textbox" [readonly] = "(data && data.siteArea) && !isEditable"/>
                </div>
            </div>

            <div class="title">Schedule of the Property as per Deed / ದಸ್ತಾವೇಜಿನ ಪ್ರಕಾರ ಆಸ್ತಿಯ ಚಕ್ಕುಬಂದಿ </div>
            <div class="col-sm-12">
                <div class="row">
                    <div class="col-sm-5">
                        <div class="row application-form-row ">
                            <label class="form-label">North / ಉತ್ತರ :</label>
                            <input type="text" [(ngModel)]="siteNorth" class="form-textbox" [readonly] = "(data && data.siteNorth) && !isEditable"/>
                        </div>
                    </div>
                    <div class="col-sm-5 offset-sm-2">
                        <div class="row application-form-row ">
                            <label class="form-label">East / ಪೂರ್ವ :</label>
                            <input type="text" [(ngModel)]="siteEast" class="form-textbox" [readonly] = "(data && data.siteEast) && !isEditable"/>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-sm-12">
                <div class="row">
                    <div class="col-sm-5">
                        <div class="row application-form-row ">
                            <label class="form-label">West / ಪಶ್ಚಿಮ :</label>
                            <input type="text" [(ngModel)]="siteWest" class="form-textbox" [readonly] = "(data && data.siteWest) && !isEditable"/>
                        </div>
                    </div>
                    <div class="col-sm-5 offset-sm-2">
                        <div class="row application-form-row ">
                            <label class="form-label">South /  ದಕ್ಷಿಣ :</label>
                            <input type="text" [(ngModel)]="siteSouth" class="form-textbox" [readonly] = "(data && data.siteSouth) && !isEditable"/>
                        </div>
                    </div>
                </div>
            </div>

            <div class="title">Khatha allotted by / ಖಾತಾ ನೀಡಲಾದ ಕಛೇರಿ </div>

            <div class="col-sm-12 col-md-12">
                <div class="row radio-row">
                    <input id="bbmp" type="radio" [(ngModel)]="khataType" value="bbmp" name="khataType" [disabled] = "(data && (data.khataBbmp == 'Y' || data.khataGp == 'Y' || data.khataBda == 'Y')) && !isEditable"/>
                    <label for="bbmp" class="radio-text">BBMP / ಬಿಬಿಎಂಪಿ</label>
                </div>
            </div>
        
            <div class="col-sm-12 col-md-12">
                <div class="row radio-row">
                    <input id="gramPanchayath" type="radio" [(ngModel)]="khataType" value="gramPanchayat" name="khataType" [disabled] = "(data && (data.khataBbmp == 'Y' || data.khataGp == 'Y' || data.khataBda == 'Y'))&& !isEditable"/>
                    <label for="gramPanchayath" class="radio-text">Gram Panchayath / ಗ್ರಾಮ ಪಂಚಾಯತ್</label>
                </div>
            </div>

            <div class="col-sm-12 col-md-12">
                <div class="row radio-row">
                    <input id="bda" type="radio" [(ngModel)]="khataType" value="bda" name="khataType" [disabled] = "(data && (data.khataBbmp == 'Y' || data.khataGp == 'Y' || data.khataBda == 'Y')) && !isEditable"/>
                    <label for="bda" class="radio-text">BDA / ಬಿಡಿಎ</label>
                </div>
            </div>

            <div class="col-sm-12 col-md-12">
                <div class="row application-form-row ">
                    <label class="form-label">Khata Number / ಖಾತಾ ಸಂಖ್ಯೆ :</label>
                    <input type="text" [(ngModel)]="khataNumber" class="form-textbox" [readonly] = "(data && data.khataNumber) && !isEditable"/>
                </div>
            </div>

            <div class="col-sm-12 col-md-12">
                <div class="row application-form-row ">
                    <label class="form-label">Khata Date / ಖಾತಾ ದಿನಾಂಕ :</label>
                    <input type="date" max="{{ maxDate |date:'yyyy-MM-dd'}}" onkeydown="return false" [(ngModel)]="khataDate" class="form-textbox" [readonly] = "(data && data.khataDate) && !isEditable"/>
                </div>
            </div>

            <div class="col-sm-12 col-md-12">
                <div class="row application-form-row ">
                    <label class="form-label">Address of the property / ಆಸ್ತಿಯ ವಿಳಾಸ :</label>
                    <input type="text" [(ngModel)]="address" class="form-textbox" placeholder="Address"  [readonly] = "(data && data.buildingAddress) && !isEditable"/>
                </div>
            </div>
</div>
        
