<div class="container">
  <div>
      <app-top-bar selectedTab="home"></app-top-bar>
      <app-header></app-header>
  </div>
  <br>
  <div class="container mb-5">
    <div class="row">
      <div class="col-sm-10">
        <h1 class="text-center heading-align">Layout Formation – Civil Work – Edit Task Workbench</h1>
        <h1 class="text-center heading-align">{{savedTaskName+' '}}&nbsp;&nbsp;Scope: {{'  '+ scope+' '+savedMeasurementUnit}}</h1>
      </div>
      <div class="col-sm user-details">
        <div class="value-text">
          Welcome <br>
          {{firstName}} !
        </div>
      </div>
      <div class="container-fluid d-flex justify-content-between mt-2">
        <button mat-button class="btn-primary" (click)="clickBack()">Back</button>
      </div>
      <div class="col-sm-12 mt-3 d-flex justify-content-between">
          <div>
            <mat-form-field appearance="outline">
            <mat-select id="status" name="selectedStatus" placeholder="Sector" [(ngModel)]="sectorId" [disabled]="true">
                <mat-option *ngFor="let sector of sectorList" [value]="sector.id">
                  {{sector.sectorName}}</mat-option>
              </mat-select>
              </mat-form-field>
          </div>

          <div>
            <mat-form-field appearance="outline">
              <mat-select id="status" name="selectedStatus" placeholder="Main Activity" [(ngModel)]="mainActivityId" [disabled]="true">
                <mat-option *ngFor="let mainActivity of mainActivityList" [value]="mainActivity.mainActivityId">
                  {{mainActivity.mainActivityName}}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <div>
            <mat-form-field appearance="outline">
              <mat-select id="status" name="selectedStatus" placeholder="Size" [(ngModel)]="roadSizeId">
                <mat-option *ngFor="let roadSize of roadSizeList" [value]="roadSize.id" (click)="setRoadSize(roadSize)">
                  {{roadSize.roadSize+ " "}}{{roadSize.measurementData.name}}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
      </div>

      <div class="col-sm-12">
        <div>
          <h2>Task Item Details : </h2>
        </div>
        <div class="d-flex justify-content-between sector-item">
          <label><b>Sector : </b>{{sectorName}} </label>
          <label><b>Main Activity :</b> {{mainActivityName}}</label>
          <label><b>Road Size :</b> {{selectedRoadStr}}</label>
        </div>
      </div>

      <div class="col-sm-12">
        <div class="d-flex justify-content-between my-5">
          <div class="d-flex align-items-center justify-content-between text-center sector-item">
            <label>Naming Convention :</label>
            <input type="text" [(ngModel)]="sectorShortName" disabled class="form-textbox convention-text mx-3"/> <span> - </span>
            <input type="text" [(ngModel)]="mainActivityShortName" disabled class="form-textbox convention-text mx-3"/> <span> - </span>
            <input type="text" [(ngModel)]="selectedRoadSize" class="form-textbox convention-text mx-3" /> <span> - </span>
            <input type="text" style="width: 100px;" [(ngModel)]="totalRoadSize" class="form-textbox convention-text mx-3"/>
          </div>
          <div class="sector-item d-flex align-items-center">
            <label>Drain Length :</label>
            <input type="text" [(ngModel)]="length" class="form-textbox lenth-text mx-3"/>
          </div>
          <div class="sector-item d-flex align-items-center mr-3">
            <label class="mr-2">Unit :</label>
            <mat-form-field class="wide-convention-text" appearance="outline">
              <mat-select [(ngModel)]="unitId" id="unit" name="unit" (selectionChange)="onUnitChange($event)">
                <mat-option *ngFor="let measurement of measurements"  [value]="measurement.id">
                  {{measurement.name}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
      </div>
      <div class="col-sm-12 text-center">
        <button mat-button class="btn-primary" (click)="updateWorkTaskItem()">Submit</button>
      </div>
    
      <div class="w-100"></div>
    </div>
  </div>
  <div class="d-flex justify-content-center">
    <div class="divider"></div>
  </div>
  <app-footer></app-footer>
</div>