import { Component, OnInit,ChangeDetectorRef,ViewChild } from '@angular/core';
import { ApplicationService } from 'src/services/application.service';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { UserService } from 'src/services/user-service/user.service';
import { StrorageService } from 'src/services/strorage.service';
import { RoleConfigService } from 'src/app/shared/config/role/role.config.service';
import { StaticsComponent } from 'src/app/statics/statics.component';
import { faSync } from '@fortawesome/free-solid-svg-icons';
import { PageEvent } from '@angular/material/paginator';
import { Location } from '@angular/common';

@Component({
  selector: 'app-commitee-cdinfo',
  templateUrl: './commitee-cdinfo.component.html',
  styleUrls: ['./commitee-cdinfo.component.scss']
})
export class CommiteeCdinfoComponent implements OnInit {

  @ViewChild(StaticsComponent) staticsComponent;

  public cdFeederApplicantDisplayedColumns: string[] = ['siNo','applicationNo', 'applicantName','telephoneNumber','breadth1','breadth2','propertyType','noOffloor','sitalArea','plinthArea','totalBuildUpArea','lat','lng','north','east','west','south'];

  public cdInfoDataSource = new MatTableDataSource();
  public cdViewApplicantDatatable = new MatTableDataSource();
  public showCdInfoReportDatatable:any = false;
  public showApplicantsDataTable:any = false;
  public userName = "Username";
  public panchayats : any[] = [];
  public isCommitteeUser;
  public faSync = faSync;
  public isRefreshing = false;
  public batchSize: number;
  public batchName: String;
  public reportName: String;
  public pagesize = 5;
  public currentPage = 0;
  public show = false;
  public totalSize = 0;

  public statusCode: any = "reported";

  public dataSource = new MatTableDataSource();

  public cdInfodisplayedColumns: string[] = ['siNo','batchName','batchSize','action'];

  user: any;


  constructor(private router: Router,private cdr:ChangeDetectorRef, public dialog: MatDialog, private userService: UserService,
    private applicationService: ApplicationService, private roleConfig: RoleConfigService,
    private strorageService:StrorageService, private _location: Location) { }

  ngOnInit(): void {
    this.user = this.strorageService.getUser();
    this.userName=this.user['firstName'];
    this.onCdInfoClick();
  }

  refreshClick(): void {
    this.currentPage = 0;
    this.pagesize = 5;
    this.onCdInfoClick();
  }
 onCdInfoClick(): void {
  this.showCdInfoReportDatatable = true;
  this.applicationService.fetchReportBatches(this.currentPage, this.pagesize).subscribe(res =>{
    this.refreshReportBatchesDataTable(res);
 })
}

goBack(): void {
  this._location.back();
}

showCdViewFeederApplicants(row:any): void {
  this.router.navigate(['commitee-cdinfo-applicants'],{ queryParams: { reportId: row.reportId, batchName: row.batchName } });
}

public getCdInfoPaginatorData(event: PageEvent) {
  this.currentPage = event.pageIndex;
  this.pagesize = event.pageSize;
  this.onCdInfoClick();
}

  onCdData() {
    this.router.navigate(['/committe-cd-data']);
  }

private refreshReportBatchesDataTable = (res: any) => {
  this.cdInfoDataSource.data = res.data;
  this.totalSize = res.totalRecord;
  this.currentPage = (res.currentPage - 1);
  this.cdInfoDataSource._updateChangeSubscription();
  this.cdr.detectChanges();
}

}
