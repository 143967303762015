import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { faSync } from '@fortawesome/free-solid-svg-icons';
import { StatusOptionTilesComponent } from 'src/app/community-login/status-option-tiles/status-option-tiles.component';
import { ConfirmDialogService } from 'src/app/shared/confirm-dialog/confirm-dialog.service';
import { MessageDialogService } from 'src/app/shared/message-dialog/message-dialog.service';
import { ApiService } from 'src/services/api.service';
import { ApplicationService } from 'src/services/application.service';
import { StrorageService } from 'src/services/strorage.service';

@Component({
  selector: 'app-award-active-status',
  templateUrl: './award-active-status.component.html',
  styleUrls: ['./award-active-status.component.scss']
})

export class AwardActiveStatusComponent implements OnInit {

  selectedData: any;
  public faSync = faSync;
  public statuses: any = [];
  public selectVillage : any;
  public statusOptionId: any;
  public panchayats : any[] = [];
  public statusCode: any = 'ready_for_reporting';
  village: any;
  villages : any[];
  selectedVillages: number;
  user: any;
  show: boolean;
  villageId: any;
  public colors = ['#E74C3C', '#1ABB9C', '#3498DB', '#F39C12', '#9B59B6', '#50C1CF', '#34495E', '#E9967A', '#FA8072', '#E74C3C','#E74C3C', '#1ABB9C', '#3498DB', '#F39C12', '#9B59B6', '#50C1CF', '#34495E'];
  @ViewChild(StatusOptionTilesComponent)
  public statusOptionTilesComponent: StatusOptionTilesComponent;
  isRefreshing: boolean;
  staticsComponent: any;
  isCommitteeUser: any;
  public totalApplicantCount: number= 0;
  
  constructor(private applicationService: ApplicationService,private router: Router, private cdr:ChangeDetectorRef,
    private messageDialogService: MessageDialogService,private confirmDialogService: ConfirmDialogService, private apiService:ApiService,
    private storageService: StrorageService, private _snackBar: MatSnackBar) {
     // this.selectedData = JSON.parse(localStorage.getItem("datas"))
  }
  

  ngOnInit(): void {
    this.user=this.storageService.getUser();
    this.fetchAllStatuses();
    this.fetchVillages();
  }
  public fetchAllStatuses(){
    this.applicationService.fetchAwardMasterStatus(this.villageId).subscribe(res =>{
      console.log(res);
      let total = 0;
      for(let i =0;i < res.length;i++) {
        total = total + parseInt(res[i].applicationCount);
      }
      this.totalApplicantCount = total;
      console.log(total)
      this.statuses = res;
    });
  }

  fetchVillages(){
    this.applicationService.fetchAllVillages().subscribe(res=>{
      this.villages = res;
      this.villages.unshift({
        "id":"",
        "name":"All"
       });
    })
  }
  public loadStatusOptionTilesData(): void {
    this.statusOptionTilesComponent.fetchApplicantByStatusOption();
  }

  public onApiLoaded(data: any): void {
    this.onTilesClickedCallback(data);
  }
  
  onTilesClickedCallback(data: any): void {
    this.statusOptionId = data.id;
    console.log(data);
    this.loadReadyForReportingAggregatedData();
  }

  public loadReadyForReportingAggregatedData(): void{
    this.applicationService.fetchApplicantCountForReadyForReportingByVillage(this.statusOptionId).subscribe(res =>{
      this.statuses = res;
    })
  }

  public resetApplicantData(): void {
    this.loadStatusOptionTilesData();
    this.statuses = [];
  }

  onVillageChange() {
    this.show=false;
    this.fetchAllStatuses();
  }
  
  onViewApplicants(): void {
    this.storageService.addItemToLocalStorage('selected_status_option', this.statusOptionId);
    this.router.navigate(['community-view-applicants'], { queryParams: { statusCode: this.statusCode, statusOptionId: this.statusOptionId }});
  }
  clickBack(){
    window.history.back();

  }
  refreshClick(){
    let currentComponent = this;
    this.isRefreshing = true;
    setTimeout(()=>{
      currentComponent.isRefreshing = false;
    }, 3000)
    this.fetchAllStatuses();
   if(this.show){
     this.fetchVillages();
   }
  }
  
}
