<div class="container">
    <div class="tool-bar">
        <div>
            <button mat-button class="btn btn-primary"(click)="clickBack()">Back</button>
        </div>

        <div class="col-sm-5 m-auto title ">
            {{heading}}
        </div>
           
        
    </div>
  <br>
  <div class="d-flex justify-content-center applnum-bold">
    <label>Group No: {{groupNumber}}</label>
    </div>
  <div class="col-sm-4 on-header-left">
    Group Size : {{groupSize}}
   </div>
   <div class="col-sm-4 on-header-right">
    Eligible for SD data validation : {{eligibleApplicantCount}}
   </div>
   <div *ngFor="let data of dataList; let i=index">
    <hr class="new mt-4">
    <div class="jcc-no">
      <label><b>{{i+1}}. {{data.applicationNo}}</b></label>
      <div class="offset-sm-1 enclose-btn">
        <button mat-button class="btn btn-primary" (click)="encloPage(data.applicantId)">Enclosure</button>
    </div>
      </div>
  <div>
      <div class="sub-title">
          <div class="col-sm-8 ">
              <label><b>Common Data Elements</b></label>
          </div>
      </div>
      <div class="row application-form-row col-sm-12">
          <div class="col-sm-2">
              <label class="form-label">Applicant Name: <span class="text-danger"> * </span></label>
          </div>
          <div class="col-sm-10">
              <input type="string" [(ngModel)]="data.applicantName" class="form-textbox"
                  [readonly]="!isEditable" />
          </div>
      </div>
      <div class="row application-form-row col-sm-12">
          <div class="col-sm-2">
              <label class="form-label">Prop.Address: <span class="text-danger"> * &nbsp;</span></label>
          </div>
          <div class="col-sm-10">
              <input type="string" [(ngModel)]="data.buildingAddress" class="form-textbox"
                  [readonly]="!isEditable" />
          </div>
      </div>
      <div class="row application-form-row col-sm-12">
          <div class="col-sm-2">
              <label class="form-label">Applicant Name:<br> (Kannada) <span class="text-danger"> *
                      &nbsp;</span></label>
          </div>
          <div class="col-sm-10">
          <input type="string" [(ngModel)]="data.applicantNameKannada" class="form-textbox" [readonly]="!isEditable"/>
          </div>
      </div>
      <div class="row application-form-row col-sm-12" style="margin-top:2rem ;">
          <div class="col-sm-6">
              <div class="col-sm-3">
                  <label class="form-label">Village Name:<br> (English) <span class="text-danger"> *
                          &nbsp;</span></label>
              </div>
              <div class="col-sm-4 offset-2" style="margin-top: -2rem;">
                  <select class="form-texttbox" [(ngModel)] = "data.villageId" [disabled]="true">
                      <option *ngFor="let village of villages"  [value]="village.id">{{village.id}} {{village.name}}
                      </option>
                  </select>
              </div>
          </div>
          <div class="col-sm-6">
              <div class="col-sm-3">
                  <label class="form-label">Village Name:<br> (Kannada) <span class="text-danger"> *
                          &nbsp;</span></label>
              </div>
              <div class="col-sm-4 offset-2" style="margin-top: -2rem;">
                  <select class="form-texttbox" [(ngModel)] = "data.villageId" [disabled]="true">
                      <option *ngFor="let village of villages"  [value]="village.id" >{{village.id}} {{village.villageNameKannada}}
                      </select>
              </div>
          </div>
      </div>
      <div class="row application-form-row col-sm-12" style="margin-top:2rem;">
          <div class="col-sm-6">
              <div class="col-sm-3">
                  <label class="form-label">Sy No: <span class="text-danger"> *
                          &nbsp;</span></label>
              </div>
              <div class="col-sm-4 offset-2" style="margin-top: -2rem;">
                  <input type="string" [(ngModel)]="data.surveyNumber" class="form-texttbox" [disabled]="true" />
              </div>
          </div>
          <div class="col-sm-6">
              <div class="col-sm-3">
                  <label class="form-label">Site No: </label>
              </div>
              <div class="col-sm-4 offset-2" style="margin-top: -2rem;">
                  <input type="string" [(ngModel)]="data.siteNumber"  class="form-texttbox" [disabled]="true" />
              </div>
          </div>
      </div>
      <div class="row application-form-row col-sm-12" style="margin-top:2rem;">
        <div class="col-sm-6">
            <div class="col-sm-3">
                <label class="form-label">Sital Area: <span class="text-danger"> *
                        &nbsp;</span></label>
            </div>
            <div class="col-sm-4 offset-2" style="margin-top: -2rem;">
                <input type="string" [(ngModel)]="data.sitalArea" class="form-texttbox" [disabled]="true"/>
            </div>
        </div>
        </div>
  <div>
      <div>
      <!-- <first line> -->
      <div class="sub-title row application-form-row col-sm-12">
        <div class="col-sm-2">
              <label><b>Dimensions</b></label>
          </div>
          <div class="col-sm-4 offset-6">
            <div *ngIf ="userType != 'rc_disbursement_user'">
                <mat-checkbox [(ngModel)]="data.precedenceSitalArea" (change)='precedenceSitalAreaClicked($event)' [disabled] ="userType == 'rc_disbursement_user'"> <span  style="color:red;"> Sital Area – CD data takes Precedence </span></mat-checkbox>
            </div>
            <div *ngIf ="userType == 'rc_disbursement_user'">
                <mat-checkbox [checked] ="data.precedenceSitalArea == true" [disabled] = "!isEditable"> <span  style="color:red;"> Sital Area – CD data takes Precedence </span></mat-checkbox>
            </div>
        </div>
      </div>
      <div class="row application-form-row col-sm-12" style="margin-top: 2rem;">
          <div class="col-sm-4">
              <div class="col-sm-5">
                  <label class="form-label">Length: <span class="text-danger"> <span>*</span>
                          &nbsp;</span></label>
              </div>
              <div class="col-sm-4 offset-3" style="margin-top: -2rem;">
                  <input type="number" [(ngModel)]="data.lengthAvg" class="form-texttbox" [readonly]="userType == 'rc_disbursement_user'"/>
              </div>
          </div>
          <div class="col-sm-4">
              <div class="col-sm-5">
                  <label class="form-label">Breadth: <span class="text-danger"> <span>*</span>
                          &nbsp;</span></label>
              </div>
              <div class="col-sm-4 offset-3" style="margin-top: -2rem;">
                  <input type="number" [(ngModel)]="data.breadthAvg" class="form-texttbox" [readonly]="userType == 'rc_disbursement_user'"/>
              </div>
          </div>
          <div class="col-sm-4">
              <div class="col-sm-5">
                  <label class="form-label">No of Floors: <span class="text-danger"> <span>*</span>
                          &nbsp;</span></label>
              </div>
              <div class="col-sm-4 offset-3" style="margin-top: -2rem;">
                  <input type="number" [(ngModel)]="data.noOfFloor" class="form-texttbox" [readonly]="userType == 'rc_disbursement_user'"/>
              </div>
          </div>
      </div>
      <div class="row application-form-row col-sm-12" style="margin-top: 3rem;">
          <div class="col-sm-4">
              <div class="col-sm-5">
                  <label class="form-label">Sital Area: <span class="text-danger"> <span>*</span>
                          &nbsp;</span></label>
              </div>
              <div class="col-sm-4 offset-3" style="margin-top: -2rem;">
                  <input type="string" [(ngModel)]="data.sitalAreaDimension" class="form-texttbox" [readonly]="userType == 'rc_disbursement_user'"/>
              </div>
          </div>
          <div class="col-sm-4">
              <div class="col-sm-5">
                  <label class="form-label">Plinth Area: <span class="text-danger"> <span>*</span>
                          &nbsp;</span> </label>
              </div>
              <div class="col-sm-4 offset-3" style="margin-top: -2rem;">
                  <input type="string" [(ngModel)]="data.plinthArea" class="form-texttbox" [readonly]="userType == 'rc_disbursement_user'"/>
              </div>
          </div>
          <div class="col-sm-4">
              <div class="col-sm-5">
                  <label class="form-label">Build-up Area: <span class="text-danger"> <span>*</span>
                          &nbsp;</span></label>
              </div>
              <div class="col-sm-4 offset-3" style="margin-top: -2rem;">
                  <input type="string" [(ngModel)]="data.totalBuildUpArea" class="form-texttbox" [readonly]="userType == 'rc_disbursement_user'"/>
              </div>
          </div>
      </div>
      <div class="sub-title">
          <div class="col-sm-8 ">
              <label><b>Checkbandi</b></label>
          </div>
      </div>
      <div class="row application-form-row col-sm-12 ">
          <div class="col-sm-6">
              <div class="col-sm-3">
                  <label class="form-label">East: <span class="text-danger"> <span>*</span>
                          &nbsp;</span></label>
              </div>
              <div class="col-sm-4 offset-2" style="margin-top: -2rem;">
                  <input type="string" [(ngModel)]="data.east"  class="form-texttbox" [readonly]="userType == 'rc_disbursement_user'"/>
              </div>
          </div>
          <div class="col-sm-6">
              <div class="col-sm-3">
                  <label class="form-label"> North: <span class="text-danger"> <span >*</span>
                          &nbsp;</span></label>
              </div>
              <div class="col-sm-4 offset-2" style="margin-top: -2rem;">
                  <input type="string" [(ngModel)]="data.north" class="form-texttbox" [readonly]="userType == 'rc_disbursement_user'">
              </div>
          </div>
      </div>

      <div class="row application-form-row col-sm-12" style="margin-top:2rem;">
          <div class="col-sm-6">
              <div class="col-sm-3">
                  <label class="form-label">West: <span class="text-danger"> <span >*</span>
                          &nbsp;</span></label>
              </div>
              <div class="col-sm-4 offset-2" style="margin-top: -2rem;">
                  <input type="string" [(ngModel)]="data.west" class="form-texttbox" [readonly]="userType == 'rc_disbursement_user'"/>
              </div>
          </div>
          <div class="col-sm-6">
              <div class="col-sm-3">
                  <label class="form-label">South: <span class="text-danger"> <span>*</span>
                          &nbsp;</span></label>
              </div>
              <div class="col-sm-4 offset-2" style="margin-top: -2rem;">
                  <input type="string" [(ngModel)]="data.south"  class="form-texttbox" [readonly]="userType == 'rc_disbursement_user'"/>
              </div>
          </div>
      </div>
  </div>
</div>
</div>
  </div>

      <div class="col-sm-12">
          <div class="container">
              <div class="row">
                  <div class="col-sm-4 offset-sm-5" *ngIf="userType != 'rc_disbursement_user'">
                      <button style="margin-top: 36px;" class="btn btn-primary refresh-btn" (click)="onSubmit(dataList)">
                          Submit
                      </button>
                  </div>
              </div>
          </div>
      </div>
  </div>
  <app-footer></app-footer>
