<div class="container">
  <div>
    <app-top-bar selectedTab="home"></app-top-bar>
    <app-header></app-header>
  </div>
  <br>
  <div>
    <div class="d-flex justify-content-between">
      <div class="refresh-btn">
        <button mat-button class="btn btn-primary" (click)="clickBack()">Back</button>
        <div class="col-sm-1 ">
          <button class="btn btn-primary refresh-btn" (click)="refreshClick()">
            <fa-icon [icon]="faSync" class="close-icon" [spin]="isRefreshing"></fa-icon>
          </button>
        </div>
      </div>

      <div class="col-sm title">
        Layout Formation – EE Module – EE Observations
      </div>

      <div class="col-sm-2 offset-sm-1 user-details">
        <div class="value-text">
          Welcome <br>
          {{user['firstName']}} !
        </div>
      </div>
    </div>

    <div>
      <section>
        <div class="row justify-content-between">
          <div class="col-sm-4">
            <div class="p1">
              <mat-form-field class="w-50" appearance="fill">
                <mat-label>Sector</mat-label>
                <mat-select id="status" name="selectedStatus" [(ngModel)]="sectorId">
                  <!-- <mat-option value="">All</mat-option> -->
                  <mat-option *ngFor="let sector of sectorList" [value]="sector.id"
                    (onSelectionChange)="onSectorChanged($event, sector.sectorName)">
                    {{sector.sectorName}}</mat-option>
                </mat-select>
              </mat-form-field>

            </div>
          </div>
          <div class="offset-sm-1 col-sm-3">
            <div class="p1">
              <mat-form-field class="w-50" appearance="fill">
                <mat-label>Concern Source </mat-label>
                <mat-select id="status" name="mainActivity" [(ngModel)]="concernSourceId">
                  <!-- <mat-option value="">All</mat-option> -->
                  <mat-option *ngFor="let concernSource of concernSourceList" [value]="concernSource.id"
                    (onSelectionChange)="onConcernSourceChanged($event, concernSource.name)">
                    {{concernSource.name}}
                  </mat-option>
                </mat-select>

              </mat-form-field>

            </div>
          </div>
          <div class="offset-sm-2 col-sm-2">
            <div class="p1">
              <button mat-button class="btn btn-primary" (click)="onActivityLogClick()">Activity Log</button>
            </div>
          </div>
        </div>
        <div class="col-sm-12 my-5">
          <textarea class="form-control" style="height: 12rem !important;" [(ngModel)]="comments" rows="5"
            placeholder="Enter Comments * (Max 1000 characters)" onkeypress="if(this.value.length==1000) return false;"
            name="comment"></textarea>
        </div>
        <div class="row d-flex justify-content-around w-100">
          <div *ngFor="let images of noOfImages; let i = index">
            <div class="col-sm-12" style="height: 16rem;">
              <div class="col-sm-2 pic-adjust">
                <div [hidden]="images.cameraChoosen">
                  <div class="snapshot">
                    <img [src]="images.profileImg" id="profile-pic" class="custom-image" />
                  </div>
                </div>
                <div *ngIf="images.cameraChoosen; else false">
                  <webcam [trigger]="getTriggerObservable(i)" [height]="125" [width]="280"
                    (imageCapture)="handleImage($event, i)"></webcam>
                </div>
              </div>
              <div class="row d-flex justify-content-start image-handler-container">
                <div class="col-sm-4 p-0" [hidden]="images.cameraChoosen">
                  <button class="btn btn-primary actionBtn" (click)="chooseCamera(i)">Take Snap</button>
                </div>
                <div class="col-sm-3" [hidden]="!images.cameraChoosen">
                  <button class="btn btn-primary actionBtn" (click)="triggerSnapshot(i)">Capture</button>
                </div>
                <div class="col-sm-3" [hidden]="images.cameraChoosen">
                  <button class="btn btn-primary actionBtn" (click)="fetchFromGallery(i)">Gallery</button>
                </div>
                <div class="col-sm-2">
                  <button class="btn btn-primary actionBtn" (click)="removePicture(i)">
                    <fa-icon [icon]="faTrash" class="close-icon"></fa-icon>
                  </button>
                </div>
              </div>
            </div>
            <input type="file" [id]="'image-gallery-'+i" accept="image/png, image/jpeg"
              (change)="onGalleryImageChoosen($event, i)" [hidden]="true">
          </div>
        </div>
        <div style="margin-bottom: 20px;">
          <div class="col-sm-10 mx-5 my-4">
            <div class="d-flex justify-content-around align-items-center" style="height: 2.8rem; margin-bottom: 20px;">
              <button mat-button class="preview-butn" (click)="onPreviewClicked()">Preview</button>
              <button mat-button class="submit-butn" (click)="submit()">Submit</button>
            </div>
          </div>
          <div style="margin-bottom: 20px;">&nbsp;</div>
        </div>
      </section>
    </div>
  </div>
</div>
<app-footer></app-footer>