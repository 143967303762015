import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { faSync } from '@fortawesome/free-solid-svg-icons';
import { ConfirmDialogService } from 'src/app/shared/confirm-dialog/confirm-dialog.service';
import { ApplicationService } from 'src/services/application.service';
import { StrorageService } from 'src/services/strorage.service';

@Component({
  selector: 'app-dpa-ee-observation-activity-log',
  templateUrl: './dpa-ee-observation-activity-log.component.html',
  styleUrls: ['./dpa-ee-observation-activity-log.component.scss']
})
export class DpaEeObservationActivityLogComponent implements OnInit {

  sectorList: any[];

  sectorId: any[];

  concernSourceId: any[];

  concernSourceList: any[];

  dpaUsersList: any[];

  dpaUserId: any;

  fullAccessUser: boolean;

  userRole: string;

  public faSync = faSync;

  public pageSize = 5;

  public currentPage = 0;

  public totalSize: number = 0;

  observationNo: any;

  user: any;

  public displayedColumns: string[] = [];

  public dataSource = new MatTableDataSource<any>();

  isRefreshing: boolean;

  constructor(private applicationService: ApplicationService, private confirmDialogService: ConfirmDialogService,
    private cdr: ChangeDetectorRef, private storageService: StrorageService,
    public route: ActivatedRoute, private router: Router,private _snackBar: MatSnackBar) { }

  ngOnInit() {
    this.user = this.storageService.getUser();
    if(this.user['roleCode'] == 'dpa_user'){
      this.fullAccessUser = true;
      this.displayedColumns =['sNo', 'obeservationNo', 'sector', 'concernSource', 'comments', 'observationDate', 'createdBy','action'];
    }else{
      this.fullAccessUser = false;
      this.getAllDpaUsers();
      this.displayedColumns =['sNo', 'obeservationNo', 'sector', 'concernSource', 'comments', 'observationDate', 'createdBy'];
    }
    this.getAllSector();
    this.getConcernSource();
    this.getActivityLogs();
  }

  getActivityLogs() {
    this.applicationService.getEEObservationActivityLog(this.currentPage, this.pageSize, this.sectorId, this.concernSourceId, this.dpaUserId, this.observationNo, ).subscribe(res => {
      console.log('res', res);
      this.refreshDataTable(res);
    })
  }


  getObservationPaginationData(event: any) {
    this.currentPage = event.pageIndex + 1;
    this.pageSize = event.pageSize;
    this.getActivityLogs();
  }

  refreshClick() {
    let currentComponent = this;
    this.isRefreshing = true;
    setTimeout(() => {
      currentComponent.isRefreshing = false;
    }, 3000)
    this.getActivityLogs();
  }

  clickBack() {
    window.history.back();
  }

  getAllSector() {
    this.applicationService.getAllSectors().subscribe(res => {
      if (res) {
        this.sectorList = res;
      }
    })
  }

  getAllDpaUsers() {
    this.applicationService.getDpaUsers().subscribe(res => {
      if (res) {
        this.dpaUsersList = res;
      }
    })
  }

  getConcernSource() {
    this.applicationService.getConcernSource().subscribe(res => {
      if (res) {
        this.concernSourceList = res;
      }
    })
  }

  onClear() {
    this.observationNo = null;
    this.getActivityLogs();
  }

  onDeleteClicked(observationNo: any) {
    let options = {
      message: 'Do you want to Delete your observation?' + observationNo,
      cancelText:'No',
      confirmText: 'Yes'
    };
    this.confirmDialogService.openDialog(options);
    this.confirmDialogService.confirmed().subscribe(confirmed => {
    if(confirmed) {
      this.applicationService.deleteEEObservation(observationNo).subscribe(res => {
        if(res) {
          this.openSnackbar('Successfully Deleted', 'Dismiss');
          this.getActivityLogs();
          return;
        }
    })
  }
  })
}

  openSnackbar(message: string, action: string){
    this._snackBar.open(message, action);
  }

  public onPreviewClick(observationId: any) {
    this.router.navigate(['/dpa-ee-observation-activity-log-view'],  {queryParams:{observationId:observationId}});

  }

  private refreshDataTable = (res: any) => {
    this.dataSource.data = res.data;
    this.totalSize = res.totalRecord;
    this.currentPage = (res.currentPage - 1);
    this.dataSource._updateChangeSubscription();
    if (!this.cdr['destroyed']) {
      this.cdr.detectChanges();
    }
  }

}

