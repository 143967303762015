<div class="d-flex justify-content-center">
    <google-chart #chart
      [title]="title"
      [type]="'PieChart'"
      [data]="chartData"
      [columns]="columnNames"
      [options]="options"
      [width]="width"
      [height]="height">
    </google-chart>
  </div>