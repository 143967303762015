<div class="container content-area">
    <div class="row">
        <div class="col-sm-9">
<nav class="navbar navbar-expand-sm bg-light">
    <ul class="navbar-nav">
      <li class="nav-item" (click)="selectHome()">
        <a class="nav-link" [class.nav-link-selected]="selectedTab == homeTab" 
         [class.nav-link-not-select]="selectedTab != homeTab"
           >{{ 'top_bar.home' | translate }}</a>
      </li>
      <li class="nav-item"  (click)="selectPublicInfo()">
        <a class="nav-link"
        [class.nav-link-selected]="selectedTab == publicInfoTab" 
         [class.nav-link-not-select]="selectedTab != publicInfoTab"
      >{{ 'top_bar.public_info' | translate }}</a>
      <mat-option (click)="onRequiredDocumentsClick()" [hidden]="!isPublicInfoChoosed">REQUIRED DOCUMENTS</mat-option>
      <mat-option (click)="onFAQClick()" [hidden]="!isPublicInfoChoosed">{{ 'top_bar.know_your_faq' | translate }}</mat-option>
      <mat-option (click)="onInstructionsClick()" [hidden]="!isPublicInfoChoosed">ONLINE APPLICATION - GUIDELINES</mat-option>
      <mat-option (click)="onlineSop()" [hidden]="!isPublicInfoChoosed">ONLINE APPLICATION -SOP</mat-option>
      </li>
      <li class="nav-item" (click)="selectNews()">
        <a class="nav-link"
        [class.nav-link-selected]="selectedTab == newsTab" 
        [class.nav-link-not-select]="selectedTab != newsTab"
     >{{ 'top_bar.news' | translate }}</a>
     <div class = "scroll">
        <div  *ngIf="isNewsMenuActive">
          <mat-option (click)="onRegularisedList20Clicked()">REGULARISED LIST 20</mat-option>
          <mat-option (click)="onRegularisedList19Clicked()">REGULARISED LIST 19</mat-option>
          <mat-option (click)="onRegularisedList18Clicked()">REGULARISED LIST 18</mat-option>
          <mat-option (click)="onRegularisedList17Clicked()">REGULARISED LIST 17</mat-option>
          <mat-option (click)="onRegularisedList15And16Clicked()">REGULARISED LIST 15 & 16</mat-option>
          <mat-option (click)="onRegularisedList14Clicked()">REGULARISED LIST 14</mat-option>
          <mat-option (click)="onRegularisedList13Clicked()">REGULARISED LIST 13</mat-option>
          <mat-option (click)="onRegularisedList12Clicked()">REGULARISED LIST 12</mat-option>
          <mat-option (click)="onRegularisedList10And11Clicked()">REGULARISED LIST 10 & 11</mat-option>
          <mat-option (click)="onRegularisedList9Clicked()">REGULARISED LIST 9</mat-option>
          <mat-option (click)="onRegularisedList8Clicked()">REGULARISED LIST 8</mat-option>
          <mat-option (click)="onRegularisedList7Clicked()">REGULARISED LIST 7</mat-option>
          <mat-option (click)="onRegularisedList6Clicked()">REGULARISED LIST 6</mat-option>
          <mat-option (click)="onRegularisedList5Clicked()">REGULARISED LIST 5</mat-option>
          <mat-option (click)="onRegularisedList4Clicked()">REGULARISED LIST 4</mat-option>
          <mat-option (click)="onRegularisedList3Clicked()">REGULARISED LIST 3</mat-option>
          <mat-option (click)="onRegularisedList2Clicked()">REGULARISED LIST 2</mat-option>
          <mat-option (click)="onRegularisedList1Clicked()">REGULARISED LIST 1</mat-option>        
        </div>
      </div>
      </li>
      <li class="nav-item" (click)="selectAudit()">
        <a class="nav-link"
        [class.nav-link-selected]="selectedTab == auditTab" 
        [class.nav-link-not-select]="selectedTab != auditTab"
     >AUDIT HELPDESK</a>
      </li>
      <li class="nav-item" (click)="selectContact()">
        <a class="nav-link"
        [class.nav-link-selected]="selectedTab == contactTab" 
        [class.nav-link-not-select]="selectedTab != contactTab"
       >{{ 'top_bar.contact' | translate }}</a>
      </li>
      <li class="nav-item nav-link-not-select" (click)="selectDSKLDAP()">
        <a class="nav-link"> DSKL - DAP</a>
      </li>
      <li class="nav-item nav-link-not-select" (click)="selectDSKLDP()">
        <a class="nav-link"> DSKL - DP </a>
      </li>
    </ul>
  </nav>
</div>
<div class="col-sm-3">
    <div class="row">
        <div class="col-sm-5 ">
    <button (click)="chooseEnglish()" class="btn" [class.selected-btn]="choosenLanguage == 'en'" [class.non-selected-btn]="choosenLanguage == 'kn'">English</button>
</div>
<div class="col-sm-5">
    <button (click)="chooseKannada()" class="btn kannada-btn"  [class.selected-btn]="choosenLanguage == 'kn'" [class.non-selected-btn]="choosenLanguage == 'en'">ಕನ್ನಡ</button>
    </div>
    <div class="col-sm-2" [hidden]="!isLoggedIn">
      <img src="../../assets//logout.jpeg" (click)="openLogoutDialog()" class="logout">
    </div>
</div>
</div>
</div>
</div>
