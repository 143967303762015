import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { ApplicationService } from 'src/services/application.service';
import { StrorageService } from 'src/services/strorage.service';

@Component({
  selector: 'app-secon-dp-activitylog',
  templateUrl: './secon-dp-activitylog.component.html',
  styleUrls: ['./secon-dp-activitylog.component.scss']
})
export class SeconDpActivitylogComponent implements OnInit {
  user: any;
  currentPage: number;
  pageSize: number;
  applicationNo: any;
  totalSize: any;
  public dataSource = new MatTableDataSource<any>();

  constructor(private applicationService: ApplicationService,private storageService:StrorageService) { }

  ngOnInit() {
    this.user=this.storageService.getUser();
  }

  clickBack(){
    window.history.back();
  }
 
}
