<div class ="container">
    <div class="row content-area"><br>
        <br>
        <br>
        <div class="col-sm-12 title">
                FIELD VISIT WORKBENCH
        </div>
        <div class ="col-sm-12">
        <app-applicant-view [data]=applicationData></app-applicant-view>
        <br>
      
        <div class="label col-sm-11" *ngIf = "applicationData">  
            <div class="sub-title">
                Scrutiny Details
             </div>
        <div class="row" >
            <div class="col-sm-8 " >
                <label >Scrutinized By:</label>&nbsp;&nbsp;<b>{{applicationData.scrutinyStatus.createdBy}}</b>
            </div>
            <div class="row-sm-8 " >
                <label >Date:</label>&nbsp;&nbsp;<b>{{applicationData.scrutinyStatus.createdAt}}</b>
            </div>
         </div>
            <br>
            <div class="row">
                <div class="col-sm-8" >
                    <label >Comments</label>
                </div>
                <div class="col-sm-4">
                    <label >{{applicationData.scrutinyStatus.comment}}</label>
                  </div>
            </div>
            <br>
            <div>
            <br>

            <div class="row" >
                <div class="col-sm-8 ">
                    <label>Status</label>
                </div>
                <div class="col-sm-4">
                    <label class="color">{{applicationData.scrutinyStatus.statusVO.status}}</label>
                  </div>
            </div>
           <br>
            <div fxLayout="row" fxLayoutGap="30px" fxLayoutAlign="space-around">
                <div *ngFor="let message of scrutinyComment">
                    <mat-checkbox [(ngModel)]="message.checked" [disabled]=disable>{{message.landType}}</mat-checkbox>
                </div>
            </div>
             <br>

             <div class="sub-title">
                Field Visit Observations
             </div> 
             <br>
             <div class="row" >
                <div class="col-sm-8 " >
                    <label >Referred By:</label>&nbsp;&nbsp;<b>{{applicationData.fieldVisitStatus.createdBy}}</b>
                </div>
                <div class="row-sm-8 " >
                    <label >Date:</label>&nbsp;&nbsp;<b>{{applicationData.fieldVisitStatus.createdAt}}</b>
                </div>
             </div>
            <br>
             <div class="row">
                <div class="col-sm-8" >
                    <label >Comments</label>
                </div>
                <div class="col-sm-4">
                    <label >{{applicationData.fieldVisitStatus.comment}}</label>
                  </div>
            </div>
            <br>
            <div class="row">
                <div class="col-sm-8" >
                    <label >Status</label>
                </div>
                <div class="col-sm-4">
                    <label class="color">{{applicationData.fieldVisitStatus.statusVO.status}}</label>
                  </div>
            </div>
            <br>
             <div fxLayout="row" fxLayoutAlign="space-around">
                <div *ngFor="let message of commentTypes">
                    <mat-checkbox [(ngModel)]="message.checked"[disabled]="true" >{{message.landType}}</mat-checkbox>
                </div>
            </div>
            </div>
            </div>
            </div>
        </div>
        <app-footer></app-footer>
    </div>
    

    