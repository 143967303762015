
<div>
    <div>
        <div class="row application-form-row col-sm-12 col-md-12">
            <div class="col-sm-6 offset-12">
            <label class="form-label">6. Award Passed on Government Land : <span class="text-danger"> * </span></label>
            </div>
            <div class="row radio-row col-sm-6 offset-7">
                <input id="govLandAwardPassed" type="radio"  [disabled] = "(data && data.govLandAwardPassed) && !isEditable" [(ngModel)]="govLandAwardPassed" value="Yes" name="govLandAwardPassed" />
                    <label for="single" class="radio-text">Yes </label>
                <input id="govLandAwardPassed" type="radio" [disabled] = "(data && data.govLandAwardPassed) && !isEditable" [(ngModel)]="govLandAwardPassed" value="No" name="govLandAwardPassed"/>
                    <label for="joint" class="radio-text">No</label>
            </div>
        </div>
        <div class="row application-form-row col-sm-12 col-md-12" *ngIf="govLandAwardPassed == 'Yes'">
            <div class="col-sm-4 offset-12" style="margin-left: 2rem;">
            <label class="form-label">6(a).If Yes :</label>
            </div>
            <div class="col-sm-6 offset-6" style="margin-top: -2rem;">
            <select class="form-textbox" [(ngModel)] = "govtLandPassedYesValue"  [disabled]="data && data.govtLandPassedYesValue && !isEditable" >
                <option *ngFor = "let option of options" [value]="option.id">{{option.codeValue}}</option>
            </select>
            </div>
        </div>
        </div>
        <div class="row application-form-row col-sm-12 col-md-12">
            <div class="col-sm-6 offset-12">
            <label class="form-label">7.Whether Revenue Layout is formed :   <span class="text-danger"> * </span></label>
            </div>
            <div class="row radio-row1 col-sm-6 offset-7">
            <input id="revenueLayoutFormed" type="radio"  [disabled] = "(data && data.revenueLayoutFormed) && !isEditable" [(ngModel)]="revenueLayoutFormed" value="Yes" name="revenueLayoutFormed" />
                <label for="single" class="radio-text">Yes </label>

            <input id="revenueLayoutFormed" type="radio" [disabled] = "(data && data.revenueLayoutFormed) && !isEditable" [(ngModel)]="revenueLayoutFormed" value="No" name="revenueLayoutFormed"/>
                <label for="joint" class="radio-text">No</label>
            </div>
        </div>
        <div class="row application-form-row col-sm-12 col-md-12" *ngIf="revenueLayoutFormed == 'Yes'">
            <div class="col-sm-6 offset-12" style="margin-left: 2rem;">
            <label class="form-label">7(a).If layout formed Award in favor of : <span class="text-danger"> * </span></label>
            </div>
            <div class="col-sm-5" style="margin-left: -2rem;">
            <select class="form-textbox" [(ngModel)] = "awardInFavourOf" [disabled]="data && data.awardInFavourOf && !isEditable" >
                <option *ngFor = "let govLandAwardPassedValue of govLandAwardPassedValues" [value]="govLandAwardPassedValue.id">
                    {{govLandAwardPassedValue.codeValue}}</option>
            </select>
            </div>
        </div>

        <div class="row application-form-row col-sm-12 col-md-12" *ngIf="revenueLayoutFormed == 'Yes'">
            <div class="col-sm-6 offset-12" style="margin-left: 2rem;">
            <label class="form-label">7 (b). Whether it is Converted : <span class="text-danger"> * </span></label>
            </div>
            <div class="row radio-row4 col-sm-6 offset-7">
                <input id="isConverted" type="radio"  [disabled] = "(data && data.isConverted) && !isEditable" [(ngModel)]="isConverted" value="Yes" name="isConverted" />
                <label for="single" class="radio-text">Yes</label>

                <input id="isConverted" type="radio" [disabled] = "(data && data.isConverted) && !isEditable" [(ngModel)]="isConverted" value="No" name="isConverted"/>
                <label for="joint" class="radio-text">No</label>

                <input id="isConverted" type="radio" [disabled] = "(data && data.isConverted) && !isEditable" [(ngModel)]="isConverted" value="INA" name="isConverted"/>
                <label for="joint" class="radio-text">INA (Information not available)</label>
            </div>
        </div>

        <div class="row application-form-row col-sm-12 col-md-12" *ngIf="revenueLayoutFormed">
            <div class="col-sm-6 offset-12" style="margin-left: 2rem;">
            <label class="form-label">7(c). Whether applications have been received for allotment of alternate site : <span class="text-danger"> * </span></label>
            </div>
            <div class="row radio-row2 col-sm-6 offset-7">

                <input id="haveAllotmentOfAlternateSite" type="radio"  [disabled] = "(data && data.haveAllotmentOfAlternateSite) && !isEditable" [(ngModel)]="haveAllotmentOfAlternateSite" value="Yes" name="haveAllotmentOfAlternateSite" />
                <label for="single" class="radio-text">Yes</label>

                <input id="haveAllotmentOfAlternateSite" type="radio" [disabled] = "(data && data.haveAllotmentOfAlternateSite) && !isEditable" [(ngModel)]="haveAllotmentOfAlternateSite" value="No" name="haveAllotmentOfAlternateSite"/>
                <label for="joint" class="radio-text">No</label>

                <input id="haveAllotmentOfAlternateSite" type="radio" [disabled] = "(data && data.haveAllotmentOfAlternateSite) && !isEditable" [(ngModel)]="haveAllotmentOfAlternateSite" value="INA" name="haveAllotmentOfAlternateSite"/>
                <label for="joint" class="radio-text">INA (Information not available)</label>
            </div>
        </div>

        <div class="row application-form-row col-sm-12 col-md-12" *ngIf="haveAllotmentOfAlternateSite =='Yes' ">
            <div class="col-sm-4 offset-12" style="margin-left: 2rem;">
            <label class="form-label">7(d). If Yes (number of Applications) : </label>
            </div>
            <div class="col-sm-5">
            <input type="string"  style="margin-left: 10rem;" [(ngModel)]="noOfApplications" class="form-textbox" [readonly] = "(data && data.noOfApplications) && !isEditable" />
            </div>
        </div>

        <div class="row application-form-row col-sm-12 col-md-12">
            <div class="col-sm-6 offset-12">
            <label class="form-label">8. Is  Award passed in the Name of Khatedar notified in Final Notification : <span class="text-danger"> * </span></label>
            </div>
            <div class="row radio-row3 col-sm-6 offset-7">
                <input id="khatedarNotifiedInFinalNotification" type="radio" [disabled] = "(data && data.khatedarNotifiedInFinalNotification) && !isEditable" [(ngModel)]="khatedarNotifiedInFinalNotification" value="Yes" name="khatedarNotifiedInFinalNotification" />
                    <label for="single" class="radio-text">Yes </label>

                <input id="isCase" type="radio" [disabled] = "(data && data.khatedarNotifiedInFinalNotification) && !isEditable" [(ngModel)]="khatedarNotifiedInFinalNotification" value="No" name="khatedarNotifiedInFinalNotification"/>
                    <label for="joint" class="radio-text">No</label>
            </div>
        </div>

        <div class="row application-form-row col-sm-12 col-md-12" *ngIf="khatedarNotifiedInFinalNotification == 'No' ">
            <div class="col-sm-4 offset-12" style="margin-left: 2rem;">
            <label class="form-label">8(a). If No, In whose Name :  </label>
            </div>
            <div class="col-sm-5" style="margin-left: 9rem;">
            <select class="form-textbox" [(ngModel)] = "khatedarNotifiedInFinalNotificationNo" [disabled]="data && data.khatedarNotifiedInFinalNotificationNo && !isEditable" >
                <option *ngFor = "let khatedarFinalNotificationCodeValue of khatedarFinalNotificationCodeValues" [value]="khatedarFinalNotificationCodeValue.id">
                     {{khatedarFinalNotificationCodeValue.codeValue}}</option>
            </select>
            </div>
        </div>

        <div class="row application-form-row col-sm-12 col-md-12">
            <div class="col-sm-6 offset-12">
            <label class="form-label">9 . Whether Award has been Approved: <span class="text-danger"> * </span></label>
            </div>
            <div class="row radio-row col-sm-6 offset-7">
                <input id="awardHasBeenApproved" type="radio" [disabled] = "(data && data.awardHasBeenApproved) && !isEditable" [(ngModel)]="awardHasBeenApproved" value="Yes" name="awardHasBeenApproved" />
                    <label for="single" class="radio-text">Yes </label>

                <input id="awardHasBeenApproved" type="radio" [disabled] = "(data && data.awardHasBeenApproved) && !isEditable" [(ngModel)]="awardHasBeenApproved" value="No" name="awardHasBeenApproved"/>
                    <label for="joint" class="radio-text">No</label>
            </div>
        </div>
        <div class="row application-form-row col-sm-12 col-md-12" *ngIf="awardHasBeenApproved == 'Yes' ">
            <div class="col-sm-4 offset-12" style="margin-left: 2rem;">
            <label class="form-label" >9(a). If Yes :</label>
            </div>
            <div class="col-sm-5" style="margin-left: 7rem;">
            <select class="form-textbox"   [(ngModel)] = "awardHasBeenApprovedYesValue" [disabled]="data && data.awardHasBeenApprovedYesValue && !isEditable" >
                <option *ngFor = "let awardApprovedCodeValue of awardApprovedCodeValues" [value]="awardApprovedCodeValue.id">
                    {{awardApprovedCodeValue.codeValue}}</option>            </select>
        </div>
        </div>

        <div class="row application-form-row" *ngIf=" awardHasBeenApproved == 'Yes' ">
            <div  style="margin-left: 2rem;">
            <label class="form-label" style="margin-left: 29px;">9(b). Quantum of compensation RS.</label>
            <label class="form-label" style="margin-left: 13rem;font-weight: bold;"> RS.</label>
            <input type="number" [disabled]="data && data.compensationAmount && !isEditable" [(ngModel)]="compensationAmount" class="rs-textbox" [readonly] = "(data && data.compensationAmount) && !isEditable" />
            </div>
        </div>

        <div class="row application-form-row col-sm-12 col-md-12" *ngIf=" awardHasBeenApproved == 'Yes' ">
            <div class="col-sm-4 offset-12" style="margin-left: 2rem;">
            <label class="form-label">9(c). Extent 60:40: </label>
            </div>
            <div class="col-sm-5">
            <input type="string"  style="margin-left: 10rem;" [disabled]="data && data.extentRatio && !isEditable" [(ngModel)]="extentRatio" class="form-textbox" [readonly] = "(data && data.extentRatio) && !isEditable" />
            </div>
        </div>


        <div class="row application-form-row col-sm-12 col-md-12" *ngIf="awardHasBeenApproved == 'Yes' ">
            <div class="col-sm-4 offset-12" style="margin-left: 2rem;">
            <label class="form-label">9(d). Whether 12(2) has been issued to Land Owner: <span class="text-danger"> * </span></label>
            </div>
            <div class="row radio-row col-sm-6 offset-7">
                <input id="issuedToLandOwner" type="radio" [disabled] = "(data && data.issuedToLandOwner) && !isEditable" [(ngModel)]="issuedToLandOwner" value="Yes" name="issuedToLandOwner" />
                    <label for="single" class="radio-text">Yes </label>

                <input id="issuedToLandOwner" type="radio" [disabled] = "(data && data.issuedToLandOwner) && !isEditable" [(ngModel)]="issuedToLandOwner" value="No" name="issuedToLandOwner"/>
                    <label for="joint" class="radio-text">No</label>
            </div>
        </div>
        <div fxLayout="row" style="margin-top: 20px">
            <button fxFlexOffset="70" class="btn btn-next" (click)="onSubmit()" >Save & Continue</button>
        </div>
    </div>
