<div class="container">
    <div>
        <app-top-bar selectedTab="home"></app-top-bar>
        <app-header></app-header>
    </div>
    <br>

    <div class="row d-flex justify-content-between">
        <div class="refresh-btn col-sm-4 w-50">
            <button mat-button class="btn btn-primary" (click)="fetchActivityLogs()">Activity Log</button>
        </div>

        <h1 class="col-sm-5"> DMS – Workbench </h1>

        <div class="col-sm-2 user-details">
            <div class="value-text">
                Welcome <br>
                {{user['firstName'] ? user['firstName'] : ''}} !
            </div>
        </div>
    </div>
    <div class="row d-flex justify-content-between mt-2">
        <mat-form-field class="col-sm-4" appearance="outline">
            <mat-label>Select Document Type <span class="text-danger">*</span></mat-label>
            <mat-select id="documents" name="documents" [(ngModel)]="documentTypeId"
                (selectionChange)="documentChanged(documentTypeId)">
                <mat-option *ngFor="let documentType of documentTypes"
                    [value]="documentType.id">{{documentType.documentType}}</mat-option>
            </mat-select>
        </mat-form-field>

        <div class="col-sm-4">
            <label class="form-label w-25">Doc Code : </label>
            <mat-form-field appearance="outline" class="mat-input-resize w-75">
                <input matInput type="text" [(ngModel)]="selectedDocumentCode" />
            </mat-form-field>
        </div>

        <div class="col-sm-4 ">
            <label class="form-label w-25">Doc Date
                <span class="text-danger"> * </span> :</label>
            <mat-form-field class="w-75 mat-form-resize h-25" appearance="outline">
                <input matInput [matDatepicker]="datePicker" (dateChange)="formatDate($event.target.value)"
                    [(ngModel)]="docDate" [max]="todayDate" class="mat-date-default w-75" readonly>
                <mat-datepicker-toggle matIconSuffix [for]="datePicker"></mat-datepicker-toggle>
                <mat-datepicker #datePicker></mat-datepicker>
            </mat-form-field>
        </div>

    </div>
    <div class="row d-flex justify-content-start mt-2">
        <mat-form-field class="col-sm-4" appearance="outline">
            <mat-label>Select Sub Document Category <span class="text-danger">*</span></mat-label>
            <mat-select id="subDocument" name="subDocument" [(ngModel)]="documentSubTypeId"
                (selectionChange)="subDocumentChanged(documentSubTypeId)">
                <mat-option *ngFor="let docSubType of documentSubTypes"
                    [value]="docSubType.id">{{docSubType.label}}</mat-option>
            </mat-select>
        </mat-form-field>

        <div class="col-sm-4">
            <label class="form-label w-25">Doc No : <span class="text-danger">*</span></label>
            <mat-form-field appearance="outline" class="mat-input-resize w-75">
                <input matInput type="text" [(ngModel)]="documentNumber" />
            </mat-form-field>
        </div>
    </div>

    <div class="row mt-5">
        <div class="col-sm-5">
            <label class="form-label">Document Name : &nbsp; </label> <b>{{documentName}}</b>
        </div>

        <div class="row mt-5 mx-3">
            <label class="form-label">Document description : </label>
            <textarea  class="form-control" style=" min-width:500px; max-width:100%;min-height:50px;height:100%;width:100%;" placeholder="500 Characters"
                [(ngModel)]="documentDescription" cols="100" maxlength="500"></textarea> 
        </div>
    </div>
    <div class="row mt-5 d-flex justify-content-center">
        <div class="offset-sm-1 col-sm-3">
            <label class="ml-5"> Select Document to upload <span class="text-danger">*</span> :
            </label>
        </div>
        <div class="col-sm-4 d-flex justify-content-start">
            <input class="file-holder" type="file" #fileInput id="upload_document" name="upload_document"
                (change)="onFileChanged($event)" />
            <fa-icon [icon]="faTrash" class="dismiss-icon fa-custom-size" (click)="dismissIconClick()"></fa-icon>
        </div>
    </div>

    <div class="row mt-5 mb-5 d-flex justify-content-around">
        <div class="col-sm-3">
            <button class="btn preview-btn" type="button" (click)="onPreviewClick()">Preview</button>
        </div>
        <div class="col-sm-3 mb-4">
            <button class="btn submit-btn" type="button" (click)="openDialog()">Submit</button>
        </div>
    </div>
</div>