import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router, ActivatedRoute } from '@angular/router';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { ApplicationService } from 'src/services/application.service';
import { AuthService } from 'src/services/auth.service';
import { UserService } from 'src/services/user-service/user.service';
import { RoleConfigService } from '../shared/config/role/role.config.service';
import { StrorageService } from 'src/services/strorage.service';

@Component({
  selector: 'app-generic-login',
  templateUrl: './generic-login.component.html',
  styleUrls: ['./generic-login.component.scss']
})
export class GenericLoginComponent implements OnInit {

  public title:string = '';

  password : string;
  mobileNo : number;
  passwordFieldType = 'password';
  showOtpScreen = false;
  isLoading = false;
  resendEnabled = false;
  otpResendTime = 120;
  faEye = faEye;
  faEyeSlash = faEyeSlash;
  passwordVisibility = 'password';
  loginType: string;
  public user: any;

  pathName : any;

  constructor(private applicationService: ApplicationService, private authService: AuthService,
     private _snackBar: MatSnackBar, private router: Router, private userService: UserService, 
     private roleConfig: RoleConfigService, private activeRoute: ActivatedRoute, private storageService:StrorageService) { 
      this.pathName = this.activeRoute.snapshot.url[0].path;
     }

  ngOnInit(): void {
    this.user = this.storageService.getUser();
    console.log(this.user);
    this.loginType = this.activeRoute.snapshot.queryParamMap.get('loginType');
    console.log(this.loginType);
    let heading = this.activeRoute.snapshot.queryParamMap.get("heading");
    if (this.pathName === 'pu') {
      this.loginType = 'power_user';
      heading = 'Power User';
    }
    this.title = 'Welcome to JCC-SKL '+heading +' Login';
    this.isUserAlreadyLoggedIn();
  }

  isUserAlreadyLoggedIn():void {
    let user = this.storageService.getUser();
    console.log(user);
    if(!user) {
      return;
    }
    if(user['roleCode'] == 'comittee_user') {
      this.router.navigate(['/applicant-list']);
    } else if(user['roleCode'] == "pi_user") {
      this.router.navigate(['/pi-user-dashboard']);
    }  else if(user['roleCode'] == "award_scrutiny_user") {
      this.router.navigate(['/award-scrutiny-user-dashboard']);
    }else if(user['roleCode'] == "rc_sd_data_verifier") {
      this.router.navigate(['/rc-sd-data-validation-dashboard']);
    }else if(user['roleCode'] == "rc_cd_data_verifier") {
      this.router.navigate(['/rc-cd-data-validation-dashboard']);
    }else if(this.loginType == "dms_user") {
      this.router.navigate(['/dms-workbench']);
    }
  }

  login() {
     if(!this.isValidLogin()){
       return;
     }
      let payload = {};
      payload['mobile'] = this.mobileNo;
      payload['password'] = this.password;
      payload['loginType'] = this.loginType;
      this.authService.login(payload, this.loginSuccess);
  }

  loginSuccess = (res: any): void => {
    if(res && res.success) {
      if(this.loginType == "pi_user") {
        this.router.navigate(['/pi-user-dashboard']);
      }else if(this.loginType == "bda_commr" || this.loginType == "bda_secretary" || this.loginType == "bda_ds"
      || this.loginType == "bda_ds") {
        this.router.navigate(['/applicant-report-dashboard']);
      }else if(this.loginType == "award_scrutiny_user") {
        this.router.navigate(['/award-scrutiny-user-dashboard']);
      }else if(this.loginType == "rc_register_user") {
        this.router.navigate(['/rc-register-dashboard']);
      }else if(this.loginType == "rc_sd_data_verifier") {
        this.router.navigate(['/rc-sd-data-validation-dashboard']);
      } else if(this.loginType == "rc_cd_data_verifier") {
        this.router.navigate(['/rc-cd-data-validation-dashboard']);
      }
      else if(this.loginType == "dpa_user") {
        this.router.navigate(['/dpa-functional-head']);
      }else if(this.loginType == "secon_user") {
        this.router.navigate(['/daily-progress-map-activity-log']);
      }else if(this.loginType == "power_user") {
        this.router.navigate(['/hotline-dashboard']);
      }else if(this.loginType == "dms_user") {
        this.router.navigate(['/dms-workbench']);
      }
      else{
        this.router.navigate(['/applicant-report-dashboard']);
      }
    }  else{
      this.openSnackbar('Invalid credentials', 'Dismiss');
   }
  }

  isValidLogin() : boolean{
      if(!this.mobileNo){
        this.openSnackbar('Please enter mobile number', 'Dismiss');
        return false;
     }
      if(!this.password){
        this.openSnackbar('Please enter password', 'Dismiss');
        return false;
     }
    return true;
  }

  openSnackbar(message: string, action: string){
    this._snackBar.open(message, action);
  }
  showPassword(){
    this.passwordVisibility = 'password'
  }

  hidePassword(){
    this.passwordVisibility = 'text';
  }

  ngOnDestroy() {
    
  }

}
