import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { ConfirmDialogService } from 'src/app/shared/confirm-dialog/confirm-dialog.service';
import { ApplicationService } from 'src/services/application.service';
import { StrorageService } from 'src/services/strorage.service';

@Component({
  selector: 'app-dpa-civil-work',
  templateUrl: './dpa-work-item.component.html',
  styleUrls: ['./dpa-work-item.component.scss']
})
export class DpaCivilWorkComponent implements OnInit {
  mainActivityName: any;
  selectedRoadStr: any;
  public measurements:any[] = [];


  constructor(private storageService:StrorageService, private applicationService: ApplicationService,
    private router:Router, public dialog: MatDialog,
    public confirmDialogService: ConfirmDialogService, private _snackBar: MatSnackBar) { }
    
  userData: any;

  firstName: string;

  showCivilWorkOptions: boolean = false;

  public title:string = '';

  mainActivityList: any[];

  sectorList:any[];

  roadSizeList:any[]

  sectorShortName: any;

  sectorName: any;

  mainActivityShortName: any;

  selectedRoadSize: any;

  totalRoadSize: any;

  sectorId: any;

  mainActivityId: any;

  roadSizeId: any;

  taskName: any;
  
  public unitCode: any = 'm';

  public length: any;

  ngOnInit() {
    window.scroll(0,0);
    this.userData = this.storageService.getUser();
    if(this.userData){
      this.firstName = this.userData['firstName'];
    }
    this.getAllSector();
    this.getAllMainActivity();
    this.getAllRoadSize();
    this.getAllMeasurementUnits();
  }

  public navigateActivityLog = () => {
    this.router.navigate(['/dpa-work-activity-log']);
  }

  setSector(sector:any){
    if(this.sectorShortName || this.sectorName){
      this.sectorShortName = null;
      this.sectorName = null;
    }
    this.sectorShortName = sector.shortName;
    this.sectorName = sector.sectorName;
  }

  setMainAct(mainActivity:any){
    if(this.mainActivityShortName || this.mainActivityName){
      this.mainActivityShortName = null;
      this.mainActivityName = null;
    }
    this.mainActivityShortName = mainActivity.shortName;
    this.mainActivityName = mainActivity.mainActivityName;
  }

  setRoadSize(roadSize: any){
    if(this.selectedRoadSize || this.selectedRoadStr){
      this.selectedRoadSize = null;
      this.selectedRoadStr = null;
    }
    this.selectedRoadSize = roadSize.roadSize;
    this.selectedRoadStr = roadSize.roadSize +" "+ roadSize.measurementData.name;
    }

  getAllMainActivity = () => {
    this.applicationService.getMainActivitiesByFunTypeCode('Civil').subscribe(res => {
      console.log("rses", res);
      this.mainActivityList = res;
    })
  }

  getAllMeasurementUnits = () => {
    this.applicationService.getMeasurements().subscribe(res => {
      console.log("rses", res);
      this.measurements = res;
    })
  }

  getAllSector() {
    this.applicationService.getAllSectors().subscribe(res => {
      if(res){
        this.sectorList = res;
      }
    })
  }

  getAllRoadSize() {
    this.applicationService.getAllRoadSizes().subscribe(res => {
      if(res){
        this.roadSizeList = res;
      }
    })
  }

  navigateOnSave(response: any) {
    if(response && response.id){
      this.navigateActivityLog();
    }
  }

  validateAndConstructPayload() {
    if(!this.sectorId){
      throw new Error("Sector cannot be empty!");
    }
    if(!this.mainActivityId){
      throw new Error("Main Activity cannot be empty!");
    }
    if(!this.taskName){
      throw new Error("Task Name cannot be empty!");
    }
    if(!this.roadSizeId){
      throw new Error("Road Size cannot be empty!");
    }
    if(!this.totalRoadSize){
      throw new Error("Total Road Size cannot be empty!");
    }
    if(!this.length){
      throw new Error("Length cannot be empty!");
    }
    if(!this.unitCode){
      throw new Error("Unit cannot be empty!");
    }
    let payload = {};
    payload['functionTypeId'] = 2; //default for civil work
    payload['sectorId'] = this.sectorId;
    payload['mainActivityId']=this.mainActivityId;
    payload['taskName'] = this.taskName;
    payload['roadSizeId'] = this.roadSizeId;
    payload['length']=this.length;
    payload['totalRoadSize']=this.totalRoadSize;
    payload['unitCode']=this.unitCode;
    this.applicationService.saveWorkTaskItem(payload);
    console.log(payload);
    return payload;
 }

  generateTaskName(){
    let taskName = this.sectorShortName + '-' + this.mainActivityShortName + '-' + this.selectedRoadSize + '-' + this.totalRoadSize;
    return taskName;
  }

  openSnackbar(message: string, action: string){
    this._snackBar.open(message, action);
  }

  clickBack(){
    window.history.back();
  }

  generatePdf = () => {
    this.taskName = this.generateTaskName();
    const options = {
      message: 'Confirm Task creation '+ this.taskName+', of length '+ this.length +' '+this.unitCode+'?',
      cancelText:'Cancel',
      confirmText: 'Ok'
    };
    let payload = this.validateAndConstructPayload();
    this.confirmDialogService.openDialog(options);
    this.confirmDialogService.confirmed().subscribe(confirmed => {
      if(confirmed){
        this.applicationService.saveWorkTaskItem(payload).subscribe(res => {
        console.log("res", res);
        this.openSnackbar('Successfully Saved', 'Dismiss');
        this.navigateOnSave(res);
        })
      }
    });

  }


}
