import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
  import * as moment from 'moment';
  import { Moment } from 'moment';
  import { faCheck, faTimes, faTrash } from '@fortawesome/free-solid-svg-icons';
import { ActivatedRoute, Router } from '@angular/router';
import { ApplicationService } from 'src/services/application.service';
import { StrorageService } from 'src/services/strorage.service';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { DmsPreviewDialogComponent } from '../dms-preview-dialog/dms-preview-dialog.component';
import { ConfirmationModalComponent } from 'src/app/confirmation-modal/confirmation-modal.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import { delay } from 'rxjs/operators';
import { DmsConfirmationModalComponent } from '../dms-confirmation-modal/dms-confirmation-modal.component';
  

@Component({
  selector: 'app-dms-workbench',
  templateUrl: './edit-dms-workbench.component.html',
  styleUrls: ['./edit-dms-workbench.component.scss']
})
export class EditDmsWorkbenchComponent implements OnInit {
  
  documentCode: any;
  
  documentTypeId : number;
  
  docDate: any;
  
  formattedDate: any;
  
  public faCheck = faCheck;
  
  public faTimes = faTimes;
  
  public faTrash = faTrash;
  
  public todayDate:Moment = moment();

  user: any;

  public documentTypes: any = [] ;

  public documentSubTypes : any = [];

  data: any;

  public selectedDocumentCode: any;

  public uploadedDocument : any;

  public selectedDocumentType: string;

  public documentName = '';

  documentNumber:string = '';

  uploadedDocumentUrl = '';

  documentDate: any;

  documentDescription: any;

  dmsId: number;

  fileName: string;

  public selectedDocumentSubType: any;

  public documentSubTypeId : string;

    constructor(private applicationService: ApplicationService, 
    private cdr:ChangeDetectorRef,  private storageService:StrorageService, private _snackBar: MatSnackBar,
    public route: ActivatedRoute,  private router: Router, public dialog: MatDialog) { }
  
    ngOnInit(): void {
      this.dmsId = this.route.snapshot.queryParams['dmsId'];
      this.getAllDocumentTypes();
      if(!this.dmsId){
        window.alert("DMS Id not recieved!");
        window.history.back();
      }
      this.user=this.storageService.getUser();
    }


    getAllDocumentTypes = () => {
      this.applicationService.fetchAllDocumentTypes().subscribe(res => {
        if (res) {
          this.documentTypes = res;
          this.getAllDocumentSubTypes();
        }
      })
    }

    getAllDocumentSubTypes() {
      this.applicationService.fetchAllDocumentSubTypes().subscribe(res => {
        if (res) {
          this.documentSubTypes = res;
          this.fetchDmsById();
        }
      })
    }

    subDocumentChanged(subDocId: any) {
      const documentSubType = this.documentSubTypes.find(item => item.id === subDocId);
      this.selectedDocumentSubType = documentSubType.label;
    }

    fetchDmsById() {
      this.applicationService.fetchDmsById(this.dmsId).subscribe(res => {
        if (res) {
          this.updateUI(res);
        }
      })
    }

    updateUI(data:any){
      this.documentName = data.documentName;
      this.documentDate = data.documentDate;
      this.uploadedDocumentUrl = data.documentUrl;
      this.documentCode = data.documentCode;
      this.documentDescription = data.description ? data.description : "";
      this.selectedDocumentType = data.documentType;
      this.documentTypeId = data.documentTypeId;
      this.documentSubTypeId = data.documentSubTypeId;
      this.documentNumber = data.documentNumber;
      this.subDocumentChanged(this.documentSubTypeId);
      this.fileName = data.fileName;
      this.setFileFromURL(data.fileName);
    }

    validateAndConstructDmsPayload(){
      if(!this.documentCode){
        throw Error('Document Type required');
      }
      if(!this.documentDate){
        throw Error('Document Date required');
      }
      if(!this.documentNumber){
        throw Error('Document Number required');
      }
      if(!this.documentSubTypeId){
        throw Error('Document Sub Type required');
      }
      if(!this.selectedDocumentType){
        throw Error('Document Type required');
      }
      let payload = {};
      payload['documentType'] = this.selectedDocumentType;
      payload['documentSubType'] = this.selectedDocumentSubType;
      payload['documentName'] = this.documentName;
      payload['documentCode'] = this.documentCode;
      payload['documentDate'] = this.documentDate;
      payload['description'] = this.documentDescription;
      payload['documentNumber']  = this.documentNumber;
      return payload;
    }

    editDmsMaster = () => {
      const formData = new FormData();
      formData.append('description', this.documentDescription);
      formData.append('docNo', this.documentNumber);
      formData.append('documentSubTypeId', this.documentSubTypeId);
      this.applicationService.editDmsMaster(this.dmsId, formData).subscribe(res => {
        this.openSnackbar("Dms "+this.documentName+" Saved sucessfully!");
        this.router.navigate(['/dms-activity-log']);
      })
    }

    openSnackbar = (message: string, action?: string) => {
      if(!action) {
        action = "Dismiss";
      }
      this._snackBar.open(message, action);
    }

  onPreviewClick = () => {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = false;
    dialogConfig.autoFocus = true;
    dialogConfig.height = '40vh';
    dialogConfig.width = '55vw';
    this.data = this.validateAndConstructDmsPayload();
    dialogConfig.data = this.data;
    const dialogRef = this.dialog.open(DmsPreviewDialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(res => {
    })
  }
  
  openDialog = () => {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = false;
    dialogConfig.autoFocus = true;
    dialogConfig.height = '165px';
    dialogConfig.width = '540px';
    dialogConfig.data = "Confirm save & submit – Document Name : "+this.documentName+" & Document Type: "+ this.documentCode+"?";
    const dialogRef = this.dialog.open(DmsConfirmationModalComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(res => {
      if (res) {
        this.editDmsMaster();
      }
    })
  }
  
    dismissIconClick() {
      this.uploadedDocument = null;
    }

    onFileChanged(event:any) {
      this.uploadedDocument = event.target.files[0];
    }
  
    fetchActivityLogs() {
      this.router.navigate(['/dms-activity-log']);
    }
  
    setFileFromURL(fileName: any) {
      fetch(fileName)
      .then(response => {
        console.log('Fetch successful');
        return response.blob();
      })
      .then(blob => {
        // Create a File object from the Blob.
        const file = new File([blob], fileName);
    
        // Create a FileList containing the File object.
        const fileInput = document.getElementById('upload_document') as HTMLInputElement;
        const filesList = new DataTransfer();
        filesList.items.add(file);
        fileInput.files = filesList.files;
    
        console.log('File added to input');
      })
      .catch(error => {
        console.error('Error fetching the file:', error);
      });
    }

  }
  