import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute } from '@angular/router';
import { ConfirmationModalComponent } from 'src/app/confirmation-modal/confirmation-modal.component';
import { ApplicationService } from 'src/services/application.service';
@Component({
  selector: 'app-structure-details',
  templateUrl: './structure-details.component.html',
  styleUrls: ['./structure-details.component.scss']
})
export class StructureDetailsComponent implements OnInit {
  
  @Input() data: any;

  @Input() isEditable : any;

  landStructureExist: any;

  noOfStructureYesValue: any;

  buildingValuationDone: any;

  entitlementCertificateIssuedYesValue: any;

  ifEcRegular: any;

  explainIrregularity: any;

  applicationsFilledYesValue: any;

  compensationForFootprintOmitted: any;

  entitlementCertificateIssued: any;

  extent: any;

  applicationsFilled: any;

  handOverToDepartment: any;

  structureHasBeenTaken: any;

  awardCompensationForStructures: any;

  applicantId: any;

  comment :any ;

  options: any;

  entitlementExtentAcre: any;

  entitlementExtentGunta: any;

  entitlementExtentValue: any;

  selectedAcre: any;

  selectedGunta: any;

  public acres: any[] = new Array;

  public guntas: any[] = new Array;

  public datas: any[] = new Array;

  @Output() newApplicantCallback = new EventEmitter<any>();
  noOfStructureAsPerMahazar: any;

  noOfStructureAsPerAwardSketch: any;

  noOfStructureDiscussedInAward: any;

  possessionOfStructureHandToEnggDept: any;

  selectedDecimalValue: any;

  entitlementExtentDecimalValue: any;

  comments: any;
  
  
  constructor(
    private applicationService: ApplicationService,
    public dialog: MatDialog,
    private _snackBar: MatSnackBar,
    private route: ActivatedRoute,
  ) {
    this.applicantId = this.route.snapshot.queryParams['application_id'];
    Array(201).fill(0).map((x, i) => {
        this.acres.push({ label: `${i}`, value: i  })
    });
    Array(40).fill(0).map((x, i) => {
      this.guntas.push({ label: `${i}`, value: i  })
  });
   }

  ngOnInit(): void {
    this.fetchApplicantById(this.applicantId);
    this.getCodeValueByCode();
    this.dropDownDatas();
  }

  getCodeValueByCode(): void {
    let code = "REF005";
    this.applicationService.fetchCodeValueByCode(code).subscribe(res =>{
      this.options = res;
      console.log(res);
    })
  }

  private fetchApplicantById = (applicantId: any) => {
    if(!applicantId) {
      return;
    }
    this.applicationService.fetchAwardMasterApplicantsById(applicantId).subscribe(res =>{
        this.data = res;
        console.log(res);
        this.setEditable(res);
        this.setValue(res);
        this.newApplicantCallback.emit(res);
    })
  }

  private setEditable = (data: any): void => {
    if(data['oneTimeEdit'] != null) {
      this.isEditable = data['oneTimeEdit'];
    }
  }

onAcreChange(acres:any) {
  if(acres == null) {
    throw new Error('Acres field must not be empty');
  }
    this.entitlementExtentAcre = acres;
}
onGuntaChange(guntas:any) {
  if(guntas == null) {
    throw new Error('Gunta field must not be empty');
  }
  if(!this.entitlementExtentAcre) {
    throw new Error('Acre field must not be empty');
  }
  this.entitlementExtentGunta = guntas;
}

onDecimalValueChange(decimalValue: any) {
  if(decimalValue == null) {
    throw new Error('Decimal Value field must not be empty');
  }
    if(this.entitlementExtentAcre == null) {
      throw new Error('Acres field must not be empty');
    }
    if(this.entitlementExtentGunta == null) {
      throw new Error('Guntas field must not be empty');
    }
    this.entitlementExtentDecimalValue = decimalValue;
    this.applicationService.fetchCalculatedValues(this.entitlementExtentAcre, this.entitlementExtentGunta, this.entitlementExtentDecimalValue).subscribe(res=>{
      this.entitlementExtentValue = res;
    })
  }

public dropDownDatas=()=> {
  this.datas = [
    {
      "label":'0.0',
      "value":'0.0'
    },
    {
      "label":'0.25',
      "value":'0.25'
    },
    {
      "label":'0.5',
      "value":'0.5'
    },
    {
      "label":'0.75',
      "value":'0.75'
    },
  ];
}

  validateAndConstructPayload = () => {
    if (!this.handOverToDepartment) {
      throw new Error("Whether land has been handed over to Engineering department Field Required");
    }
    if (this.handOverToDepartment == "Yes") {
      if(this.landStructureExist == null) {
        throw new Error("Do buildings exist on the land Fiels Required");
      }
    }
    if (!this.entitlementCertificateIssued) {
      throw new Error("Whether Entitlement Certificate issued Field Required");
    }
    let payload = {};
    payload['landStructureExist'] = this.landStructureExist;
    if(this.landStructureExist == "Yes") {
      payload['noOfStructureYesValue'] = this.noOfStructureYesValue;
      payload['noOfStructureAsPerMahazar'] = this.noOfStructureAsPerMahazar;
      payload['noOfStructureAsPerAwardSketch'] = this.noOfStructureAsPerAwardSketch;
      payload['noOfStructureDiscussedInAward'] = this.noOfStructureDiscussedInAward;
      payload['buildingValuationDone'] = this.buildingValuationDone;
      payload['awardCompensationForStructures'] = this.awardCompensationForStructures;
      payload['structureHasBeenTaken'] = this.structureHasBeenTaken;
      payload['possessionOfStructureHandToEnggDept'] = this.possessionOfStructureHandToEnggDept;
      payload['applicationsFilled'] = this.applicationsFilled;
      payload['applicationsFilledYesValue'] = this.applicationsFilledYesValue;
      payload['compensationForFootprintOmitted'] = this.compensationForFootprintOmitted;
    }
    payload['handOverToDepartment'] = this.handOverToDepartment;
    payload['entitlementCertificateIssued'] = this.entitlementCertificateIssued;
    if(this.entitlementCertificateIssued == "Yes") {
      if(this.entitlementExtentAcre == null) {
        throw new Error('Extent Acre Value field must not be empty');
      }
      if(this.entitlementExtentGunta == null) {
        throw new Error('Extent Gunta Value field must not be empty');
      }
      if(this.entitlementExtentDecimalValue == null) {
        throw new Error('Extent Decimal Value field must not be empty');
      }
      if(this.entitlementExtentValue == null) {
        throw new Error('Extent  Value field must not be empty');
      }
      payload['entitlementCertificateIssuedYesValue'] = this.entitlementCertificateIssuedYesValue;
      payload['ifEcRegular'] = this.ifEcRegular;
      if(this.ifEcRegular == "No"){
        payload['explainIrregularity'] = this.explainIrregularity;
      }
      payload['entitlementCertificateIssuedYesValue'] = this.entitlementCertificateIssuedYesValue;
      payload['entitlementExtentAcre'] = this.entitlementExtentAcre;
      payload['entitlementExtentGunta'] = this.entitlementExtentGunta;
      payload['entitlementExtentDecimalValue'] = this.entitlementExtentDecimalValue;
      payload['entitlementExtentValue'] = this.entitlementExtentValue;
      payload['comment'] = this.comment;
      }
    return payload;
  }

  onSubmit = () => {
    this.openDialog();
  }

  openSnackbar(message: string, action?: string){
    if(!action) {
      action = "Dismiss";
    }
    this._snackBar.open(message, action);
  }

  openDialog = () => {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = false;
    dialogConfig.autoFocus = true;
    dialogConfig.height = '160px';
    dialogConfig.width = '450px';
    dialogConfig.data = "Are you sure want to submit this record ?"
    const dialogRef = this.dialog.open(ConfirmationModalComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(res => {
      if (res) {
        if (this.applicantId) {
          this.updateApplicantStructureDetailsApiCall(this.applicantId);
          return;
        }
        this.updateApplicantStructureDetailsApiCall(this.applicantId);
      }
    })
  }
  updateApplicantStructureDetailsApiCall = (applicantId: any) => {
    try {
      let payload = this.validateAndConstructPayload();
      this.applicationService.saveStructureDetails(applicantId, payload).subscribe(res => {
        this.openSnackbar("Successfully Updated", "Dismiss");
         this.setValue(res);
      }, err => {
        console.log(err);
        this.openSnackbar(err?.message, "Dismiss");
      })
    } catch (e) {
      this.openSnackbar(e, "Dismiss");
    }
  }

  setValue(data: any): void{
    this.handOverToDepartment = data.handOverToDepartment;
    this.landStructureExist = data.landStructureExist;
    this.noOfStructureYesValue = data.noOfStructureYesValue;
    this.noOfStructureAsPerMahazar = data.noOfStructureAsPerMahazar;
    this.noOfStructureAsPerAwardSketch = data.noOfStructureAsPerAwardSketch;
    this.noOfStructureDiscussedInAward = data.noOfStructureDiscussedInAward;    
    this.buildingValuationDone = data.buildingValuationDone;
    this.awardCompensationForStructures = data.awardCompensationForStructures;
    this.structureHasBeenTaken = data.structureHasBeenTaken;
    this.possessionOfStructureHandToEnggDept = data.possessionOfStructureHandToEnggDept;
    this.applicationsFilled = data.applicationsFilled;
    this.applicationsFilledYesValue = data.applicationsFilledYesValue;
    this.compensationForFootprintOmitted = data.compensationForFootprintOmitted;
    this.entitlementCertificateIssued = data.entitlementCertificateIssued;
    this.entitlementCertificateIssuedYesValue = data.entitlementCertificateIssuedYesValue;
    this.entitlementExtentAcre = data.entitlementExtentAcre;
    this.entitlementExtentGunta = data.entitlementExtentGunta;
    this.entitlementExtentDecimalValue = data.entitlementExtentDecimalValue;
    this.entitlementExtentValue = data.entitlementExtentValue;
    this.comment = data.sdComment;
    this.ifEcRegular = data.ifEcRegular;
    this.explainIrregularity = data.explainIrregularity;
  }
}
