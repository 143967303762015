import { Component, OnInit, ViewChild } from '@angular/core';
import { ApplicationService } from 'src/services/application.service';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { PreviewDialogComponent } from '../preview-dialog/preview-dialog.component';
import { ApplicantEnclosureListComponent } from '../applicant-enclosure-list/applicant-enclosure-list.component';
import { UserService } from 'src/services/user-service/user.service';
import { faSync } from '@fortawesome/free-solid-svg-icons';
import { StaticsComponent } from '../statics/statics.component';
import { RoleConfigService } from '../shared/config/role/role.config.service';
import { StrorageService } from 'src/services/strorage.service';
import { ApplicationBarChartComponent } from '../application-bar-chart/application-bar-chart.component';

@Component({
  selector: 'app-applicant-list',
  templateUrl: './applicant-list.component.html',
  styleUrls: ['./applicant-list.component.scss']
})
export class ApplicantListComponent implements OnInit {

  @ViewChild(StaticsComponent) staticsComponent;

  @ViewChild(ApplicationBarChartComponent) barchartComponent;
  
  public displayedColumns: string[] = ['applicationNo', 'applicantName','telephoneNumber','action'];

  public dataSource = new MatTableDataSource();

  public cdInfoDataSource = new MatTableDataSource();

  public panchayats : any[] = [];

  public villages : any[] = [];

  public isLoading = false;

  public selectedPanchayat;

  public selectVillage;

  public userName = "Username";

  public colors = ['#E74C3C', '#1ABB9C', '#3498DB', '#F39C12', '#9B59B6', '#50C1CF', '#34495E', '#E9967A', '#FA8072', '#E74C3C'];

  public isCommitteeUser;

  public faSync = faSync;

  public isRefreshing = false;

  public showCdInfoReportDatatable:any = false;

  // public batchSize: number;

  // public batchName: String;

  // public reportName: String;

  // public cdInfodisplayedColumns: string[] = ['siNo','reportName','batchName','batchSize','action'];

  user: any;
  public notificationType =  null;
  allApplicationBtn: boolean = true;
  newNotificationBtn: boolean = false;
  oldNotificationBtn: boolean = false;

  constructor(private router: Router, public dialog: MatDialog, private userService: UserService,
    private applicationService: ApplicationService, private roleConfig: RoleConfigService,private strorageService:StrorageService) { }

  ngOnInit(): void {
    // let userType = this.userService.getUserType();
    // if(userType != this.userService.COMITTEE_USER || userType != this.userService.SCRUTINY_USER){
    //     this.router.navigate(['/'])
    // }
    this.user = this.strorageService.getUser();
    this.userName=this.user['firstName'];
    this.isCommitteeUser = this.roleConfig.ROLE_COMMITTEE;
    if(this.isCommitteeUser)
    this.fetchAllPanchayats();
  }

  onStaticsClick(){
    if(!this.isCommitteeUser)
    this.fetchAllPanchayats();
  }
  OnTrendsClick(){
    let fullURL = location.protocol+'//'+location.hostname+(location.port ? ':'+location.port: '');
    window.open("https://public.tableau.com/app/profile/navigem.data.private.limited/viz/JusticeChandrashekaraCommitteeJCCSKL-Dashboard2_0/JusticeChandrashekaraCommitteeJCCSKL-Dashboard2_0","_blank")
  }

  onReportingModuleClick(){
    this.router.navigate(['committee-reporting']);

  }
  OnTransferBdaClick()
  {
    this.router.navigate(['transfer-bda']);
  }
  onCdInfoClick()
  {
    this.router.navigate(['commitee-cdinfo']);
  }
  OnGPSClick()
  {
    window.open("http://navigem.infinityfreeapp.com/map2.html","_blank")
  }
  onActiveStatusClick()
  {
    this.router.navigate(['committe-active-status']);
  }
  onAwardStatusClick()
  {
    this.router.navigate(['award-active-status']);
  }
  onRCPrepClick()
  {
    this.router.navigate(['commitee-rc-prep']);
  }
  // onCdInfoClick(): void {
  //   this.showCdInfoReportDatatable = true;
  //   this.applicationService.fetchReportBatches().subscribe(res =>{
  //     this.cdInfoDataSource.data = res;
  //  })
  // }

  onPreviewClick(data){
    this.openPreviewDialog(data);
  }
  openPreviewDialog(data: any) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.height = '80vh';
    dialogConfig.width = '50vw';
    dialogConfig.data = data.applicationNo;
    const dialogRef = this.dialog.open(PreviewDialogComponent,dialogConfig);
    dialogRef.afterClosed().subscribe(res =>{
       
    })
  }

  fetchAllPanchayats() {
     this.applicationService.fetchAllPanchayats().subscribe(res =>{
         this.panchayats = res;
     })
  }

  onPanchayatChange(id){
    this.fetchVillagesByPanchayat(id);
    this.selectedPanchayat = id;
  }

  onVillageChange(id){
    this.fetchApplicantsByVillage(id);
    this.selectVillage = id;
  }

  refreshClick(){
    let currentComponent = this;
    this.isRefreshing = true;
    setTimeout(()=>{
      currentComponent.isRefreshing = false;
    }, 3000)
    this.newNotificationBtn = false;
    this.oldNotificationBtn = false;
    this.allApplicationBtn = true;
    this.notificationType = null;
    this.staticsComponent.fetchAllApplicant(this.notificationType);
    this.barchartComponent.fetchApplicantData(this.notificationType);         
    this.fetchAllPanchayats();
  }
 


  public goDetailsPage(data){
    this.openEnclosure(data );
    // this.router.navigate(['/applicant-enclosure-list'], { queryParams: { applicantId: row['id'] } });
  }
  openEnclosure(data) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.height = '80vh';
    dialogConfig.width = '50vw';
    dialogConfig.data =data.id;
    const dialogRef = this.dialog.open(ApplicantEnclosureListComponent,dialogConfig);
    dialogRef.afterClosed().subscribe(res =>{
       
    })
  }
 

  fetchVillagesByPanchayat(id){
   // this.isLoading = true;
    this.applicationService.fetchAllVillagesByPanchayat(id, this.notificationType).subscribe(res =>{
      this.villages = res;
    //  this.isLoading = false;
   })
  }

  
  private fetchAllApplicants(): void {
   // this.isLoading = true;
    this.applicationService.getApplicants().subscribe(res =>{
      this.dataSource.data = res;
     // this.isLoading = false;
    })
  }

  showCdViewFeederApplicants(row:any): void {
    this.applicationService.fetchCdFeederReportApplicantsByReportId(row.reportId).subscribe(res =>{
     console.log(res)
    })
  }
  

  private fetchApplicantsByVillage(id){
    if(this.isCommitteeUser){
      return;
    }
   // this.isLoading = true;
    this.applicationService.fetchAllApplicantByVillage(id).subscribe(res =>{
      this.dataSource.data = res;
     // this.isLoading = false;
    })
  }

  notificationBtnClick(notificationType: any) {
    this.notificationType = notificationType;
    this.allApplicationBtn = false;
    if(this.notificationType == 'NEW') {
      this.newNotificationBtn = true;
      this.oldNotificationBtn = false;
      this.allApplicationBtn = false;
    }
    if(this.notificationType == 'OLD') {
      this.newNotificationBtn = false;
      this.oldNotificationBtn = true;
      this.allApplicationBtn = false;
    }
    if(this.notificationType == null) {
      this.newNotificationBtn = false;
      this.oldNotificationBtn = false;
      this.allApplicationBtn = true;
    }
    this.staticsComponent.fetchAllApplicant(this.notificationType); 
    this.barchartComponent.fetchApplicantData(this.notificationType);  
    this.fetchAllPanchayats();
 }

  onLayoutFormationEventHandler = (selectedHeading: any) => {
    this.router.navigate(['layout-formation-dashboard'], { queryParams: { selectedHeading: selectedHeading} });
  }

  onDMSEventHandler = () => {
    this.router.navigate(['commitee-dms-dashboard']);
  }

}
