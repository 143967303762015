import { ChangeDetectorRef, Component, Inject, Input, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { PageEvent } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfirmationModalComponent } from 'src/app/confirmation-modal/confirmation-modal.component';
import { PiUserDashboardComponent } from 'src/app/pi-user/pi-user-dashboard/pi-user-dashboard.component';
import { PiUserTrackActivityService } from 'src/app/pi-user/services/pi-user-track-activity.service';
import { ConfirmDialogService } from 'src/app/shared/confirm-dialog/confirm-dialog.service';
import { MessageDialogService } from 'src/app/shared/message-dialog/message-dialog.service';
import { ApplicationService } from 'src/services/application.service';
import { StrorageService } from 'src/services/strorage.service';

@Component({
  selector: 'app-rc-register-data-enrichment-form',
  templateUrl: './rc-register-data-enrichment-form.component.html',
  styleUrls: ['./rc-register-data-enrichment-form.component.scss']
})
export class RcRegisterDataEnrichmentFormComponent implements OnInit {
  applicantId: any;
  user: any;
  public data: any;
  public dataSource = new MatTableDataSource<any>();
  public pageSize =5;
  public currentPage = 0;
  public totalSize: number= 0;
  public applicantName: any;
  public applicantNameKannada: any;
  public applicantAddress: any;
  public groupNumber: any;
  public groupSize: any;
  public structureType: any;
  public villages: any[];
  public villageId: any;
  public siteNumber: any;
  public surveyNumber: any;
  public applicationNo: any;
  public activeStatus: any;
  public plinthArea: any;
  public cdFeederReportData: any;
  public sitalArea: any;
  public noOfFloors: any;
  public buildUpArea: any;
  public east: any;
  public west: any;
  public north: any;
  public south: any;
  @Input() isEditable : any;
  public length: any;
  public breadth: any;
  options:any;
  finalNotification:any;
  rtcRemainingExtent: any;
  submitReport: any;
  public possibileCdError: any;
  public reportDate: Date;
  public maxDate = new Date().getTime();
  fakeArray = new Array(5);
  applicationData: any;
  processingJccNos: any;
  public selectedApplicants: any = [];
  public disableSubmitButton = false;
  annexureName: string;
  public rcSerialNo:any;
  public scApprovedNo: any;
  public processedBy: any;
  public rcPrepData : any;
  annexure: any;
  public approvalDate: any;
  address: any;
  eligibleApplicantsSize: any;
  selectedApplicantStatus: any;
  filteredApplicants: number;
  heading: any;
  userType: any;


  constructor(
    private applicationService: ApplicationService,
    private cdr:ChangeDetectorRef, 
      private confirmDialogService: ConfirmDialogService, 
    private router: Router,private storageService:StrorageService,
    private messageDialogService: MessageDialogService,
    private route:ActivatedRoute) {
      this.applicantId = this.route.snapshot.queryParams['applicantId'];
      this.heading = this.route.snapshot.queryParams['heading'];
      this.userType = this.route.snapshot.queryParams['userType'];
     }

    ngOnInit(): void {
      this.user=this.storageService.getUser();
      this.fetchVillages();
      this.fetchDataEnrichmentApplicantsByApplicantId();
   }

   fetchVillages() {
    this.applicationService.fetchAllVillages().subscribe(res=>{
     this.villages = res;
     this.villages.unshift({
       "id":"",
       "name":"All"
     });
     })
   }
   
    clickBack(){
      window.history.back();
    }

    fetchDataEnrichmentApplicantsByApplicantId() {
        this.applicationService.fetchRcValidationDataByApplicantId(this.applicantId).subscribe(res=>{
          this.data = res;
          this.updateUI(res);
          console.log(res);
        })

    }
    
    updateUI(res : any) {
      this.applicantName = res.applicantName;
      this.applicantAddress = res.applicantAddress;
      this.applicationNo = res.applicationNo;
      this.activeStatus = res.status;
      this.plinthArea = res.cdFeederReportData.plinthArea;
      this.sitalArea = res.cdFeederReportData.sitalArea;
      this.noOfFloors = res.cdFeederReportData.noOfFloor;
      this.buildUpArea = res.cdFeederReportData.totalBuildUpArea;
      this.east = res.cdFeederReportData.east;
      this.west = res.cdFeederReportData.west;
      this.north = res.cdFeederReportData.north;
      this.south = res.cdFeederReportData.south;
      this.length = res.cdFeederReportData.lengthAvg;
      this.breadth = res.cdFeederReportData.breadthAvg;
      this.villageId = res.villageId;
      this.surveyNumber = res.surveyNumber;
      this.siteNumber = res.siteNumber;
    }

    validateAndConstructPayload = (data :any) => {
          data.applicantNameKannada = this.applicantNameKannada;
          data.villageId = this.villageId;
          data.surveyNumber = this.surveyNumber;
          data.siteNumber = this.siteNumber;
          this.validateData(data);
      return data;
    }

    validateData(data: any) {
        if(data.applicantName == null){
          throw new Error('Applicant Name must not be empty');
        }
        if(data.applicantAddress == null){
          throw new Error('Address must not be empty');
        }
        if(data.applicantNameKannada == null){
          throw new Error('Applicant Name Kannada must not be empty');
        }
        if(data.villageId == null){
          throw new Error('Village must not be empty');
        }
        if(data.surveyNumber == null){
          throw new Error('Survey Number must not be empty');
        }
        if(data.cdFeederReportData.lengthAvg == null){
          throw new Error('Length must not be empty');
        }
        if(data.cdFeederReportData.breadthAvg == null){
          throw new Error('Breadth must not be empty');
        }
        if(data.cdFeederReportData.noOfFloor == null){
          throw new Error('No Of Floors must not be empty');
        }
        if(data.cdFeederReportData.sitalArea == null){
          throw new Error('Sital Area field must not be empty');
        }
        if(data.cdFeederReportData.plinthArea == null){
          throw new Error('Plinth Area field must not be empty');
        }
        if(data.cdFeederReportData.totalBuildUpArea == null){
          throw new Error('Total Build Up Area field must not be empty');
        }
        if(data.cdFeederReportData.east == null){
          throw new Error('East field must not be empty');
        }
        if(data.cdFeederReportData.west == null){
          throw new Error('West field must not be empty');
        }
        if(data.cdFeederReportData.north == null){
          throw new Error('North Area field must not be empty');
        }
        if(data.cdFeederReportData.south == null){
          throw new Error('South Area field must not be empty');
        }
    
    }


    onSubmit(data: any) {
      console.log(data);
        const options = {
          title: 'Confirmation!',
          message: 'Confirm SD data of JCC No : ' + data.applicationNo +' verified & updated ', 
          cancelText:'No',
          confirmText: 'Yes',
        };
        this.confirmDialogService.openDialog(options);
        this.confirmDialogService.confirmed().subscribe(confirmed => {
          if(confirmed) {
            let payload = this.validateAndConstructPayload(data);
            if(this.userType == 'rc_sd_data_verifier') {
              this.applicationService.submitRcRegisterValidationApplicants(payload).subscribe(res =>{
                this.router.navigate(['/rc-sd-data-validation-dashboard']);
            })
            }
            if(this.userType == 'rc_cd_data_verifier') {
              this.applicationService.updateRcRegisterCdDataValidationByApplicantId(payload).subscribe(res =>{
                this.router.navigate(['/rc-cd-data-validation-dashboard']);
            })
            }
          }
        });
  }

}
