<div class="container">
  <div>
      <app-top-bar selectedTab="home"></app-top-bar>
      <app-header></app-header>
  </div>
  <br>
<div class="container">
  <div class="container d-flex justify-content-between">
    <div class="refresh-btn ml-5">
      <button mat-button class="btn btn-primary" (click)="clickBack()">Back</button>
      <div class="col-sm-1 ">
        <button class="btn btn-primary refresh-btn" (click)="refreshClick()">
            <fa-icon [icon]="faSync" class="close-icon" [spin]="isRefreshing"></fa-icon>
        </button>
      </div>
    </div>

    <div class="col-sm-3 offset-sm-1 user-details">
      <div class="value-text">
        Welcome <br>
        {{user['firstName']}} !
      </div>
    </div>
  </div>


  <div class="col-sm title">
    DPA– Generation – Activity Log
  </div>

  <div>
    <section>
      <div fxLayout="row" fxLayoutAlign="space-between">
        <div class="col-sm-3 offset-sm-9">
          <div fxLayout="row" fxLayoutAlign="space-around">
            <input class="form-control border-end-0 border rounded-pill" style="font-size: 14px !important;" type="text"
              [(ngModel)]="dapNo" placeholder="Search" id="dapNo">
            <span class="input-group-append"><br>&nbsp;
              <button mat-button class="btn btn-primary " (click)="searchApplicant()">Search</button>
              <button mat-button *ngIf="dapNo" matSuffix mat-icon-button aria-label="Clear" (click)="onClear()">
                <mat-icon>close</mat-icon>
              </button>
            </span>
          </div>
        </div>
      </div>
      <div class="row justify-content-between">
        <div class="col-6 title">
          <div class="p1">
            <mat-form-field class="w-50" appearance="fill">
              <mat-label>Village</mat-label>
              <mat-select id="status" name="selectedStatus" [(ngModel)]="villageId"
                (selectionChange)="onVillageChange($event.value)">
                <mat-option value="">All</mat-option>
                <mat-option *ngFor="let village of villageList" [value]="village.id">
                  {{village.villageName}}</mat-option>
              </mat-select>
            </mat-form-field>

          </div>
        </div>
        <div class="col-6 title">
          <div class="p1">
            <mat-form-field class="w-50" appearance="fill">
              <mat-label>Sy. No</mat-label>
              <mat-select id="status" name="selectedSurvey" [(ngModel)]="surveyId"
                (selectionChange)="onSurveyChange($event.value)">
                <mat-option *ngFor="let syNo of syNoList" [value]="syNo.id">
                  {{syNo.surveyNo}}
                </mat-option>
              </mat-select>

            </mat-form-field>

          </div>
        </div>
      </div>

      <!-- data table -->
      <mat-card>
        <table mat-table [dataSource]="dataSource">

          <!-- DAP No Column -->
          <ng-container matColumnDef="sectorNo">
            <th mat-header-cell *matHeaderCellDef> Sector No </th>
            <td mat-cell *matCellDef="let element ;let i =index;" class="column-container">
              {{element.sectorName}}</td>
          </ng-container>

          <!-- DAP No Column -->
          <ng-container matColumnDef="dapNo">
            <th mat-header-cell *matHeaderCellDef> DAP No </th>
            <td mat-cell *matCellDef="let element ;let i =index;" class="column-container">
              <a target="_blank" href="{{element.reportUrl}}" class="preview-link">{{element.dapNo}}</a></td>
          </ng-container>

          <!-- Function Column -->
          <ng-container matColumnDef="mainActivity">
            <th mat-header-cell *matHeaderCellDef> Function </th>
            <td mat-cell *matCellDef="let element"> {{element.function}} </td>
          </ng-container>

          <!-- Main Column -->
          <ng-container matColumnDef="function">
            <th mat-header-cell *matHeaderCellDef> Main Activity </th>
            <td mat-cell *matCellDef="let element"> {{element.mainActivity}} </td>
          </ng-container>

          <!-- Work Column -->
          <ng-container matColumnDef="work">
            <th mat-header-cell *matHeaderCellDef> Work </th>
            <td mat-cell *matCellDef="let element">{{element.work}} </td>
          </ng-container>

          <!-- Village Column -->
          <ng-container matColumnDef="village">
            <th mat-header-cell *matHeaderCellDef> Village </th>
            <td mat-cell *matCellDef="let element"> {{element.village}} </td>
          </ng-container>

          <!-- Sy. No Column -->
          <ng-container matColumnDef="syNo">
            <th mat-header-cell *matHeaderCellDef> Sy. No </th>
            <td mat-cell *matCellDef="let element ;let i =index;" class="column-container">{{element.syNo}}
          </ng-container>

          <!-- Created On Column -->
          <ng-container matColumnDef="createdOn">
            <th mat-header-cell *matHeaderCellDef> Created On </th>
            <td mat-cell *matCellDef="let element ;let i =index;" class="column-container">{{element.createdOn}}
          </ng-container>

          <!-- Created By Column -->
          <ng-container matColumnDef="createdBy">
            <th mat-header-cell *matHeaderCellDef> Created By </th>
            <td mat-cell *matCellDef="let element ;let i =index;" class="column-container">{{element.createdBy}}
          </ng-container>

            <!-- Comments Column -->
            <ng-container matColumnDef="comments">
              <th mat-header-cell *matHeaderCellDef> Comments </th>
              <td mat-cell *matCellDef="let element ;let i =index;" class="column-container">{{element.comments}}
            </ng-container>


          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>
        <div class="no-records-text" *ngIf="!dataSource.data || dataSource.data.length <= 0">No Records Found!</div>
        <mat-paginator [pageSize]="pageSize" [pageSizeOptions]="[5, 10, 20]" [showFirstLastButtons]="true"
          [pageIndex]="currentPage" [length]="totalSize" (page)="getApplicantPaginatorData($event)">
        </mat-paginator>
      </mat-card>
    </section>
  </div>
  <app-footer></app-footer>
</div>
</div>