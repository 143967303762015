import { Component, OnInit } from '@angular/core';
import { PageEvent } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { faSync } from '@fortawesome/free-solid-svg-icons';
import { ApplicationService } from 'src/services/application.service';
import { StrorageService } from 'src/services/strorage.service';

@Component({
  selector: 'app-dpa-activity-log',
  templateUrl: './dpa-activity-log.component.html',
  styleUrls: ['./dpa-activity-log.component.scss']
})
export class DpaActivityLogComponent implements OnInit {
  isRefreshing: boolean;
  user: any;
  applicationNo: any;
  selectedVillageId: any;
  selectedSurveyId: any;
  villageList : any [] = [];
  syNoList : any [] = [];
  public pageSize =5;
  public currentPage = 0;
  public totalSize: number= 0;
  public villageId: any = '';
  public surveyId: any = '';
  public dapNo: any;
  public displayedColumns: string[] = ['sectorNo','dapNo', 'mainActivity', 'function', 'work', 'village','syNo','createdOn','createdBy','comments'];
  public dataSource = new MatTableDataSource<any>();
  public title:string = '';
  public faSync = faSync;

  constructor(private applicationService: ApplicationService, private storageService:StrorageService) { }

  ngOnInit() {
    this.user=this.storageService.getUser();
    this.getVillageData();
  }

  refreshClick(){
    let currentComponent = this;
    this.isRefreshing = true;
    setTimeout(()=>{
      currentComponent.isRefreshing = false;
    }, 3000)
    this.getActivityLogs();
  }

  getVillageData(){
    this.applicationService.getAdVillages().subscribe(res => {
      this.villageList = res;
      this.onVillageChange(this.villageId);
    })
  }

  getAllVillageIdsCommaSeperated = (): String => {
    let result:String='';
    for(let i =0;i < this.villageList.length;i++) {
      result = (result == '') ? this.villageList[i].id : result + this.villageList[i].id;
      if(i != (this.villageList.length - 1)) {
        result = result+',';
      }
    }
    console.log(result);
    return result;
  }

  onVillageChange = (villageId: any) => {
    console.log(villageId);
    if(!villageId) {
      villageId = this.getAllVillageIdsCommaSeperated();
    }
    this.applicationService.getAdSurveyByVillage(villageId).subscribe(res => {
      console.log("rses", res);
      this.syNoList = [];
      let syNo:any = {};
      syNo.id='';
      syNo.surveyNo = 'All';
      this.syNoList.push(syNo);
      for(let i =0;i < res.length;i++) {
        this.syNoList.push(res[i]);
      }
      this.getActivityLogs();
    })
  }

  onSurveyChange = (surveyId: number) => {
    this.getActivityLogs();
  }

  getActivityLogs(){
    this.applicationService.getDpaActivityLog(this.villageId, this.surveyId,this.dapNo, this.currentPage, this.pageSize).subscribe(res => {
      console.log('res',res);
      if(res){
        this.refreshDataTable(res);
      }
    })
  }

  private refreshDataTable = (res: any) => {
    this.totalSize = res.totalRecord;
    this.dataSource.data = res.data;
    this.dataSource._updateChangeSubscription();
  }

  onClear() {
    this.applicationNo = null;  
  }

  clickBack(){
    window.history.back();
  }

  public getApplicantPaginatorData(event: PageEvent) {
    this.currentPage = event.pageIndex;
    this.pageSize = event.pageSize;
    this.getActivityLogs();
  }

  searchApplicant(){
    this.resetPagination();
    this.getActivityLogs();
  }
  resetPagination() {
    this.currentPage = 0;
    this.pageSize = 5;
  }

}
