<div mat-dialog-content>
  <div class="row ">
    <div class="col-sm-11 title">
      <h1 class="heading-format">Layout Formation - EE Module - EE Observations <br>Preview</h1>
    </div>
    <div class=" col-sm-1">
      <fa-icon [icon]="faTimes" class="close-icon" (click)="onCancelClicked()"></fa-icon>
    </div>
    <div class="col text-center mt-5">
      <div class="row mt-4">
        <div class="col-6 text-start value-text">
          <p><strong>Sector:</strong>{{sectorName}}</p>
        </div>
        <div class="col-6 text-end value-text">
          <p><strong>Concern Source:</strong>{{concernSourceName}}</p>
        </div>
      </div>
    </div>
  </div>
  <div class="col-sm-8 mx-auto my-5 d-flex">
    <div class="col-sm-3">
      <h1 class="value-text">Comments :</h1>
    </div>
    <textarea class="form-control" style="height: 12rem !important;" [(ngModel)]="comments" rows="4"
       name="comment" readonly></textarea>
  </div>
  <div class="click-pc">
    <label style="cursor:pointer" (click)="showImageOnClick()">Click to view Pic</label>
    <div *ngIf="showImage">
      <div *ngFor="let imageUrl of imageUrlList">
        <img [src]="imageUrl" alt="Image" class="custom-image mt-1"/>
      </div>
    </div>
  </div>
</div>