import { DatePipe } from '@angular/common';
import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { PageEvent } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute } from '@angular/router';
import { ApplicationService } from 'src/services/application.service';
import { StrorageService } from 'src/services/strorage.service';

@Component({
  selector: 'app-dpa-work-item-datatable',
  templateUrl: './dpa-work-item-datatable.component.html',
  styleUrls: ['./dpa-work-item-datatable.component.scss']
})
export class DpaWorkItemDatatableComponent implements OnInit {

  isRefreshing: boolean;
  user: any;
  applicationNo: any;
  selectedVillageId: any;
  selectedSurveyId: any;
  sectorList : any [] = [];
  sectorId : any = '';
  mainActivityList : any [] = [];
  public pageSize =5;
  public currentPage = 0;
  public totalSize: number= 0;
  public villageId: any = '';
  public mainActivityId: any = '';
  public dapNo: any;
  public displayedColumns: string[] = ['sectorNo','dpaNo','itemNo',  'function','mainActivity', 'work', 'scope', 'progressTillDate', 'unit','status','processedOn','processedBy','viewPictureVideo'];
  public dataSource = new MatTableDataSource<any>();
  public workType: string;
  public heading:string;
  public title:string = '';

  constructor(private applicationService: ApplicationService, private storageService:StrorageService, private activeRoute: ActivatedRoute, public route: ActivatedRoute,
    private cdr:ChangeDetectorRef ) {
    this.workType = this.route.snapshot.queryParamMap.get("workType");
   }

  ngOnInit() {
    this.heading = this.activeRoute.snapshot.queryParamMap.get("heading");
    this.user=this.storageService.getUser();
    this.getAllSector();
    this.getAllMainActivity();
    this.getActivityLogs();
  }

  sectorChange = (sectorId: number) => {
    this.getActivityLogs();
  }

  getActivityLogs() {
    this.applicationService.getSectoryDailyActivityHistory(this.currentPage, this.pageSize, this.sectorId, this.mainActivityId).subscribe(res => {
        this.refreshDataTable(res);
    })
  }

  private refreshDataTable = (res: any) => {
    this.dataSource.data = res.data;
        this.totalSize = res.totalRecord;
        this.currentPage = (res.currentPage - 1);
        this.dataSource._updateChangeSubscription();
        if (!this.cdr['destroyed']) {
          this.cdr.detectChanges();
        }
  }

  onClear() {
    this.applicationNo = null;  
  }

  clickBack(){
    window.history.back();
  }

  public getApplicantPaginatorData(event: PageEvent) {
    this.currentPage = event.pageIndex + 1;
    this.pageSize = event.pageSize;
    this.getActivityLogs();
  }

  getAllSector() {
    this.applicationService.getAllSectors().subscribe(res => {
      if(res){
        this.sectorList = res;
      }
    })
  }

  getAllMainActivity() {
    this.applicationService.getFunctionTypeMainActivities(2).subscribe(res => {
      console.log("rses", res);
      this.mainActivityList = res;
    })
  
  }

  searchApplicant(){
    this.resetPagination();
    this.getActivityLogs();
  }
  resetPagination() {
    this.currentPage = 0;
    this.pageSize = 5;
  }
  formattDate = (date:any) =>{
    let pipe = new DatePipe('en-IN');
    return pipe.transform(date, 'dd/MM/yyyy');
  }
}

