<div class="container">
    <div>
        <app-top-bar selectedTab="home"></app-top-bar>
        <app-header></app-header>
    </div>
    <br>
   
    <div class="tool-bar">
    
                <button class="btn btn-primary refresh-btn" (click)="refreshClick()">
                    <fa-icon [icon]="faSync" class="close-icon"   [spin]="isRefreshing"></fa-icon>
                </button>
            
        <button mat-button class="btn btn-primary  refresh-btn"(click)="clickBack()">Back</button>
    
    <label class="login-title">Journey Map</label>

     
        <div class="col-sm-3 offset-sm-1 user-details">
            <div class="value-text">
                Welcome  <br>
            {{user['firstName']}} !
            </div>
            </div>
        </div>
    </div>
    <div class="col-sm-12"> 
        <div class="container">
            <div class="row">
                <div class="col-sm-3 ">
                        <div fxLayout="row" fxLayoutAlign="space-around">
                        <input class="form-control border-end-0 border rounded-pill" style="font-size: 14px !important;"type="text" [(ngModel)]="groupNo" placeholder="Search by Group no" id="groupNo">
                        <span class="input-group-append"><br>&nbsp;
                         <button mat-button class="btn btn-primary " (click)="searchGroups()">Search</button> 
                         <button mat-button *ngIf="groupNo" matSuffix mat-icon-button aria-label="Clear" (click)="onClearGroup()">
                            <mat-icon>close</mat-icon>
                          </button>
                        </span>
                        </div>
                    </div>
                <div class="col-sm-2 offset-sm-5 title">
    <div class="p1">
    <mat-form-field appearance="fill">
        <mat-label>Village</mat-label>
        <mat-select id="village" name="villageId" [(ngModel)]="villageId">
          <mat-option *ngFor="let village of villages" [value]="village.id" (click)="onVillageChange()">
            {{village.id}} {{village.name}}
          </mat-option>
        </mat-select>
      
      </mat-form-field>
      <div class="p2">
      Total: {{totalSize}}
    </div>
</div>
</div>

            </div>
        </div>
    </div>
    <div class="col-sm-12"> 
        <div class="container">
            <div class="row">
    <div class="col-sm-3  ">
        <div fxLayout="row" fxLayoutAlign="space-around">
        <input class="form-control border-end-0 border rounded-pill" style="font-size: 14px !important;"type="text" [(ngModel)]="applicationNo" placeholder="Search by JCC no" id="applicationNo">
        <span class="input-group-append"><br>&nbsp;
         <button mat-button class="btn btn-primary " (click)="searchApplicant()">Search</button> 
         <button mat-button *ngIf="applicationNo" matSuffix mat-icon-button aria-label="Clear" (click)="onClear()">
            <mat-icon>close</mat-icon>
          </button>
        </span>
        </div>
    </div>
            </div>
        </div>
    </div>
    <div class="col-sm-10">

    <mat-card>
        <table mat-table [dataSource]="dataSource">
            <ng-container matColumnDef="sn">
                <th mat-header-cell *matHeaderCellDef> Serial No. </th>
                <td mat-cell *matCellDef="let element ;let i =index;" class="column-container">{{i+1}}
                  </ng-container>
            <!-- Position Column -->
            <ng-container matColumnDef="jccNO">
                <th mat-header-cell *matHeaderCellDef> JCC No  </th>
                <td mat-cell *matCellDef="let element">
                    <a (click)="onPreviewClick(element.id)" class="preview-link"> {{element.applicationNo}} </a> 
                </td>
            </ng-container>
            
            <!-- Name Column -->
            <ng-container matColumnDef="groupId">
                <th mat-header-cell *matHeaderCellDef>Group Id.</th>
                <td mat-cell *matCellDef="let element"> {{element.groupNo}} </td>                
            </ng-container>

            <!-- Group Size Column -->
            <ng-container matColumnDef="groupSize">
                <th mat-header-cell *matHeaderCellDef> Group Size </th>
                <td mat-cell *matCellDef="let element"> {{element.groupSize}} </td>
            </ng-container>
            
            <!-- Telephone Column -->
            <ng-container matColumnDef="villageName">
                <th mat-header-cell *matHeaderCellDef> Villge Name </th>
                <td mat-cell *matCellDef="let element"> {{element.villageName}} </td>
            </ng-container>

            <ng-container matColumnDef="activeStatus">
                <th mat-header-cell *matHeaderCellDef> Active Status </th>
                <td mat-cell *matCellDef="let element"> 
                    <span *ngIf="element.status && element.status.length > 0">{{element.status}}</span> 
                </td>
            </ng-container>

            <ng-container matColumnDef="notificationType">
                <th mat-header-cell *matHeaderCellDef> Notification Type  </th>
                <td mat-cell *matCellDef="let element">
                    {{element.notificationType}} 
                </td>
            </ng-container>

            <ng-container matColumnDef="isValid">
                <th mat-header-cell *matHeaderCellDef> Is Valid </th>
                <td mat-cell *matCellDef="let element"> 
                    <span>{{element.valid ? 'Valid' : 'InValid'}}</span> 
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;" ></tr>
        </table>
        <div class="no-records-text" *ngIf="!dataSource.data || dataSource.data.length <= 0">No Records Found!</div>
        <mat-paginator [pageSize]="pageSize" [pageSizeOptions]="[5, 10, 20]" [showFirstLastButtons]="true" 
        [pageIndex]="currentPage" [length]="totalSize" (page)="getApplicantPaginatorData($event)">
    </mat-paginator>
    </mat-card>
    </div>
<br><br>
<app-footer></app-footer>