import { Component, HostListener, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { faSync, faTimes } from '@fortawesome/free-solid-svg-icons';
import { ApplicationService } from 'src/services/application.service';
import { StrorageService } from 'src/services/strorage.service';

@Component({
  selector: 'app-dpa-ee-observation-activity-log-view',
  templateUrl: './dpa-ee-observation-activity-log-view.component.html',
  styleUrls: ['./dpa-ee-observation-activity-log-view.component.scss']
})
export class DpaEeObservationActivityLogViewComponent implements OnInit {

  public faTimes = faTimes;

  public comments: any;

  eeObservationData: any;

  observationId: any;

  public sectorName: any;

  public concernSourceName: any;

  imageUrls: any [] = [];
  
  showImage: boolean = false;

  isRefreshing: boolean;

  public faSync = faSync;
  
  user: any;

  constructor(public storageService:StrorageService, private applicationService: ApplicationService,
    public route: ActivatedRoute) {
      this.observationId = this.route.snapshot.queryParamMap.get("observationId");

     }

  ngOnInit() {
    this.user = this.storageService.getUser();
    this.getObservationById();
  }

  refreshClick() {
    let currentComponent = this;
    this.isRefreshing = true;
    setTimeout(() => {
      currentComponent.isRefreshing = false;
    }, 3000)
  }

  clickBack() {
    window.history.back();
  }

  getObservationById() {
    this.applicationService.getEEObservationById(this.observationId).subscribe(res => {
      if (res) {
        this.eeObservationData = res;
        this.setValue(res);
      }
    })
  }

  public setValue(data: any) {
    this.sectorName = data.sectorName;
    this.concernSourceName = data.concernSourceName;
    this.comments = data.comments;
    if(data.eeObservationDocuments) {
      for(let i = 0 ; i < data.eeObservationDocuments.length; i++) {
        let imageUrl = data.eeObservationDocuments[i].imageUrl;
        this.imageUrls.push(imageUrl);
      }
    }
    console.log(this.imageUrls);
  }

  showImageOnClick() {
    this.showImage = true;
  }

  getByObservationId = () => {

  }

}
