<div class="container">
    <div>
        <app-top-bar selectedTab="home"></app-top-bar>
        <app-header></app-header>
    </div>
    <br>
    <div class="tool-bar">
        <div>
            <button class="btn btn-primary" (click)="refreshClick()">
                <fa-icon [icon]="faSync" class="close-icon" [spin]="isRefreshing"></fa-icon>
            </button>
        </div>
        <div>
            <button mat-button class=" btn btn-primary" (click)="showActivityLog()" style="right: 74px;">Activity
                Log</button>
        </div>
        <div class="col-sm-5 heading" style="margin-left: 44px;margin-left: -50px;">
            RC & Register Management Module
        </div>

        <br>
        <div>
            <button mat-button class="btn btn-primary" (click)="clickBack()">Back</button>
        </div>
    </div>
    <div>
        <div class="tool-bar">
            <div class="col-sm-3 " style="margin-top: 25px;">
                <div fxLayout="row" fxLayoutAlign="space-around">
                    <input class="form-control border-end-0 border rounded-pill" style="font-size: 14px !important;"
                        type="text" [(ngModel)]="applicationNo" placeholder="Search by jcc no" id="applicationNo">
                    <span class="input-group-append"><br>&nbsp;
                        <button mat-button class="btn btn-primary " (click)="searchApplicant()">Search</button>
                        <button mat-button *ngIf="applicationNo" matSuffix mat-icon-button aria-label="Clear"
                            (click)="onClear()">
                            <mat-icon>close</mat-icon>
                        </button>
                    </span>

                </div>
            </div>
            <div class="col-sm-5 heading" style="text-align:center">
                Batch Processing
            </div>

            <div>

                <mat-form-field appearance="fill">
                    <mat-label>Village</mat-label>
                    <mat-select id="village" name="villageId" [(ngModel)]="villageId">
                        <mat-option *ngFor="let village of villages" [value]="village.id" (click)="onVillageChange()">
                            {{village.id}} {{village.name}}
                        </mat-option>
                    </mat-select>

                </mat-form-field>
            </div>
        </div>
    </div>

    <div class="col-sm-3">
        <button class="btn unselect" (click)="unSelectAll()">
            Unselect All
        </button>
    </div>
    <!-- data table -->
    <div class="col-sm-12">
        <div class="container">
            <div class="row">
                <div class="application-container mat-elevation-z8" tabindex="0">
                    <mat-card style="margin-right: -127%;margin-top: -12px;">
                        <table mat-table matTableExporter id="ExampleTable" [dataSource]="dataSource"
                            #exporter="matTableExporter" matSort>
                            <ng-container style="width:7px" matColumnDef="all">
                                <th mat-header-cell *matHeaderCellDef [ngStyle]="{'display': 'none'}"> Select All </th>
                                <td mat-cell *matCellDef="let element" class="column-container">
                                    <mat-checkbox labelPosition="before" [(ngModel)]="element.checked"
                                        [checked]="element.checked"
                                        (change)='selectOrDeSelectApplicants($event, element)'></mat-checkbox>
                                </td>
                            </ng-container>

                            <ng-container matColumnDef="No">
                                <th mat-header-cell *matHeaderCellDef [attr.rowspan]="2">Select all</th>
                            </ng-container>

                            <ng-container matColumnDef="rcSlNo">
                                <th mat-header-cell *matHeaderCellDef [attr.rowspan]="2"> SI No </th>
                                <td mat-cell *matCellDef="let element ;let i =index;" class="column-container">{{i+1}}
                            </ng-container>

                            <ng-container matColumnDef="groupNumber">
                                <th mat-header-cell *matHeaderCellDef>Group No. </th>
                                <td mat-cell *matCellDef="let element">
                                    {{element.groupNumber}}
                                </td>
                            </ng-container>

                            <!-- Group Size Column -->
                            <ng-container matColumnDef="groupSize">
                                <th mat-header-cell *matHeaderCellDef> Group Size </th>
                                <td mat-cell *matCellDef="let element">
                                    {{element.groupSize}}
                                </td>
                            </ng-container>

                            <!-- Group Size Column -->
                            <ng-container matColumnDef="jccNo">
                                <th mat-header-cell *matHeaderCellDef> JCC No. </th>
                                <td mat-cell *matCellDef="let element">
                                    {{element.applicationNo}}
                                </td>
                            </ng-container>

                            <ng-container matColumnDef="notificationType">
                                <th mat-header-cell *matHeaderCellDef> Notification Type </th>
                                <td mat-cell *matCellDef="let element">
                                    <div *ngIf="element.notificationType == 'OLD'">
                                        <span style="color:#EC5F2E;"> {{element.notificationType}} </span>
                                    </div>
                                    <div *ngIf="element.notificationType == 'NEW'">
                                        <span style="color:#1BBEAA;"> {{element.notificationType}} </span>
                                    </div>
                                </td>
                            </ng-container>

                            <!-- Jcc No Column -->
                            <ng-container matColumnDef="applicantNameEng">
                                <th mat-header-cell *matHeaderCellDef> Applicant Name <br>(English) </th>
                                <td mat-cell *matCellDef="let element"> {{element.applicantName}} </td>
                            </ng-container>

                            <!-- Applicant Name Column -->
                            <ng-container matColumnDef="applicantNameKann">
                                <th mat-header-cell *matHeaderCellDef> Applicant Name <br>(Kannada) </th>
                                <td mat-cell *matCellDef="let element"> {{element.applicantNameKannada}} </td>
                            </ng-container>

                            <!-- Possible CD Error Column -->
                            <ng-container matColumnDef="propAddr">
                                <th mat-header-cell *matHeaderCellDef> Property Address. </th>
                                <td mat-cell *matCellDef="let element">
                                    {{element.buildingAddress}}
                                </td>
                            </ng-container>

                            <!-- Active Status Column -->
                            <ng-container matColumnDef="annexure">
                                <th mat-header-cell *matHeaderCellDef> Annexure. </th>
                                <td mat-cell *matCellDef="let element"> {{element.annexureName}} </td>
                            </ng-container>


                            <!-- CD Processed By Type Column -->
                            <ng-container matColumnDef="east">
                                <th mat-header-cell *matHeaderCellDef> East. </th>
                                <td mat-cell *matCellDef="let element"> {{element.east}} </td>
                            </ng-container>

                            <!-- Name Column -->
                            <ng-container matColumnDef="west">
                                <th mat-header-cell *matHeaderCellDef>West.</th>
                                <td mat-cell *matCellDef="let element">{{element.west}}</td>
                            </ng-container>

                            <!-- Weight Column -->
                            <ng-container matColumnDef="north">
                                <th mat-header-cell *matHeaderCellDef>North</th>
                                <td mat-cell *matCellDef="let element">{{element.north}}</td>
                            </ng-container>

                            <!-- Symbol Column -->
                            <ng-container matColumnDef="south">
                                <th mat-header-cell *matHeaderCellDef>South</th>
                                <td mat-cell *matCellDef="let element">{{element.south}}</td>
                            </ng-container>

                            <ng-container matColumnDef="header-row-second-group">
                                <th mat-header-cell *matHeaderCellDef [attr.colspan]="7"></th>
                            </ng-container>

                            <ng-container matColumnDef="checkbandii">
                                <th mat-header-cell *matHeaderCellDef [attr.colspan]="4">Checkbandi</th>
                            </ng-container>

                            <ng-container matColumnDef="header-row-second-groups">
                                <th mat-header-cell *matHeaderCellDef [attr.colspan]="10"></th>
                            </ng-container>

                            <tr mat-header-row
                                *matHeaderRowDef="[ 'No','header-row-second-group','checkbandii','header-row-second-groups']">
                            </tr>

                            <ng-container matColumnDef="villageEng">
                                <th mat-header-cell *matHeaderCellDef> Village <br>(English) </th>
                                <td mat-cell *matCellDef="let element"> {{element.villageName}} </td>
                            </ng-container>

                            <!-- Applicant Name Column -->
                            <ng-container matColumnDef="villagekan">
                                <th mat-header-cell *matHeaderCellDef> Village <br>(Kannada) </th>
                                <td mat-cell *matCellDef="let element"> {{element.villageNameKannada}} </td>
                            </ng-container>

                            <!-- Possible CD Error Column -->
                            <ng-container matColumnDef="length">
                                <th mat-header-cell *matHeaderCellDef> L. </th>
                                <td mat-cell *matCellDef="let element">
                                    {{element.lengthAvg}}
                                </td>
                            </ng-container>

                            <!-- Active Status Column -->
                            <ng-container matColumnDef="breadth">
                                <th mat-header-cell *matHeaderCellDef> B. </th>
                                <td mat-cell *matCellDef="let element"> {{element.breadthAvg}} </td>
                            </ng-container>

                            <!-- Structure Type Column -->
                            <ng-container matColumnDef="sitalArea">
                                <th mat-header-cell *matHeaderCellDef> Sital Area. </th>
                                <td mat-cell *matCellDef="let element">
                                    <div *ngIf="element.precedenceSitalArea == true">
                                        {{element.sitalAreaDimension}}
                                    </div>
                                    <div *ngIf="!element.precedenceSitalArea">
                                        {{element.sitalArea}}
                                    </div>
                                </td>
                            </ng-container>

                            <ng-container matColumnDef="land">
                                <th mat-header-cell *matHeaderCellDef> Land </th>
                                <td mat-cell *matCellDef="let element"> {{element.precedenceSitalAreaValue}} </td>
                            </ng-container>

                            <!-- CD Processed By Type Column -->
                            <ng-container matColumnDef="plinthArea">
                                <th mat-header-cell *matHeaderCellDef> Plinth Area. </th>
                                <td mat-cell *matCellDef="let element"> {{element.plinthArea}} </td>
                            </ng-container>

                            <!-- Possible CD Error Column -->
                            <ng-container matColumnDef="buildUpArea">
                                <th mat-header-cell *matHeaderCellDef> Build Up Area. </th>
                                <td mat-cell *matCellDef="let element">
                                    {{element.totalBuildUpArea}}
                                </td>
                            </ng-container>

                            <!-- Active Status Column -->
                            <ng-container matColumnDef="noOfFloor">
                                <th mat-header-cell *matHeaderCellDef> NO Of Floor. </th>
                                <td mat-cell *matCellDef="let element"> {{element.noOfFloor}} </td>
                            </ng-container>

                            <!-- Structure Type Column -->
                            <ng-container matColumnDef="structureType">
                                <th mat-header-cell *matHeaderCellDef> Structure Type Area. </th>
                                <td mat-cell *matCellDef="let element"> {{element.structureType}} </td>
                            </ng-container>

                            <!-- Actions Column -->

                            <ng-container matColumnDef="action">
                                <th mat-header-cell *matHeaderCellDef class="data-header"> Actions </th>
                                <td mat-cell *matCellDef="let element" class="column-container">
                                    <img class="edit" src="assets/edit.jpeg"
                                        (click)="editRcRegisterBatchProcessing(element.id)">
                                </td>
                            </ng-container>

                            <tr mat-header-row *matHeaderRowDef="displayedColumns sticky: true"></tr>
                            <tr mat-row *matRowDef="let row; columns: displayedColumns; "></tr>
                        </table>

                        <div class="no-records-text" *ngIf="!dataSource.data || dataSource.data.length <= 0">No Records
                            Found!</div>
                    </mat-card>
                </div>
            </div>
        </div>
    </div>
    <div class="col-sm-12" style="margin-top: 20px">
        <div class="container">
            <div class="rows">
                <div class="col-sm-2">
                    <mat-form-field style="margin-left: 18px">
                        <mat-label>Batch Name <span class="required">*</span></mat-label>
                        <input matInput type="string" name="batchName" [(ngModel)]="batchName" />
                    </mat-form-field>
                </div>
                <div class="col-sm-1 offset-sm-6">
                    <mat-form-field style="margin-left: -51px">
                        <mat-label>Batch Size (Group Size) <span class="required"></span></mat-label>
                        <input matInput type="number" name="batchSize" [(ngModel)]="batchSize" readonly />
                    </mat-form-field>
                </div>
            </div>
        </div>
    </div>

    <div class="col-sm-12 ">
        <div class="container">
            <div class="rows">
                <div class="col-sm-2">
                    <mat-form-field style="margin-left: 18px">
                        <mat-label>Enter RC running Sl.No <span class="required">*</span></mat-label>
                        <input matInput type="number" name="rcSerialNo" [(ngModel)]="rcSerialNo">
                    </mat-form-field>
                </div>
                <div class="col-sm-1 offset-sm-6">
                    <mat-form-field style="margin-left: -51px">
                        <mat-label>Enter RC running Page No <span class="required">*</span></mat-label>
                        <input matInput type="number" name="reportStartPageNumber" [(ngModel)]="reportStartPageNumber">
                    </mat-form-field>
                </div>
            </div>
        </div>
    </div>

    <div class="row application-form-row col-sm-12" style="margin-top:2rem;">
        <div class="col-sm-4 offset-sm-8">
            <mat-form-field style="margin-left: -35px;">
                <mat-label>JCC Nos <span class="required"></span></mat-label>
                <input matInput type="number" name="selectedJccNos" [(ngModel)]="selectedJccNos" readonly />
            </mat-form-field>
        </div>
    </div>
    <div class="row col-sm-12">

        <div class="col-sm-6">
            <div class="col-sm-4 offset-sm-3">
                <button class="btn btn-primary batch-btn" style=" background-color: #00008B;" (click)="previewReport()">
                    Preview Batch
                </button>
            </div>
        </div>
        <div class="col-sm-6">
            <div class="col-sm-4 offset-sm-3">
                <button class="btn btn-primary batch-btn" style=" background-color: #FFBF00;"
                    (click)="generateReport()">
                    Generate Batch
                </button>
            </div>
        </div>
    </div>
</div>
<div>
    <app-footer></app-footer>
</div>