import { Component, Input, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTableDataSource } from '@angular/material/table';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { ConfirmDialogService } from 'src/app/shared/confirm-dialog/confirm-dialog.service';
import { ApplicationService } from 'src/services/application.service';
import { SectorVillageDialogComponent } from '../sector-village-dialog/sector-village-dialog.component';
import { DpaWorkItemDialogComponent } from '../dpa-work-item-dialog/dpa-work-item-dialog.component';

@Component({
  selector: 'app-sector-work-item-container',
  templateUrl: './sector-work-item-container.component.html',
  styleUrls: ['./sector-work-item-container.component.scss']
})
export class SectorWorkItemContainerComponent implements OnInit {
  public functionTypeData: any[] = [];
  public sectorData: any[] = [];
  selectedSurveyItems: any[] = [];
  public agreement1Confirmed: boolean = false;
  public agreement2Confirmed: boolean = false;
  public faTimes = faTimes;
  public displayedColumns: string[] = ['siNo', 'mainActivity', 'taskItem','work','scope','unit', 'actions'];

  dataSource = new MatTableDataSource();
  @Input() startDate: any;

  @Input() endDate: any;

  public mainActivityData: any[] = [];
  selectedMainActivityId: number;
  taskData: any;


  constructor(private applicationService: ApplicationService, public dialog: MatDialog,
     public confirmDialogService: ConfirmDialogService, private _snackBar: MatSnackBar) { }

  ngOnInit() {
    this.fetchTemplateData();
    console.log(this.startDate);
  }

  fetchTemplateData() {
    this.applicationService.fetchSectorFormTemplateData('Civil').subscribe(res => {
      this.mainActivityData = res.mainActivityDataList;
      this.sectorData = res.sectors;
    })
  }

  onMainActivityChange = (index: number, mainActivityId: number, dialogHeading: any) => {
   this.sectorData[index].mainActivityId = mainActivityId;
   this.sectorData[index].dialogHeading = dialogHeading;
   this.sectorData[index].functionTypeId = 2;
  }

  generatePdf = (sectorData: any) => {
    const options = {
      message: 'Confirm Daily Action Plan Generation',
      cancelText:'Cancel',
      confirmText: 'Ok'
    };
    this.confirmDialogService.openDialog(options);
    this.confirmDialogService.confirmed().subscribe(confirmed => {
      if(confirmed){
        let payload: any = this.constructPayload(sectorData);
        this.applicationService.generateSectorWorkitemReport('civil', payload, this.startDate, this.endDate).subscribe(res => {
        window.open(res.fileURL);
        })
      }
    });
  }

  payloadValidation = (data: any, index: any) => {
    if (data.checked) {
      index = index + 1;
      if (!data.mainActivityId) {
        throw new Error('Sector -' + index + ' Main activity must not be empty');
      }
      if (!data.taskItems) {
        throw new Error('Sector -' + index + ' Task must not be empty');
      }
    }
  }

  constructPayload = (sectorData: any) => {
        let payload: any[] = [];
        if(!this.startDate) {
          throw new Error('PDF From date must not be empty');
        }
        if(!this.endDate) {
          throw new Error('PDF To date must not be empty');
        }
        for (let i = 0; i < this.sectorData.length; i++) {
          if (sectorData[i].checked) {
            this.payloadValidation(sectorData[i], i);
            let item: any = {};
            item.sectorId = this.sectorData[i].sectorId;
            item.functionTypeId = this.sectorData[i].functionTypeId;
            item.mainActivityId = this.sectorData[i].mainActivityId;
            let taskItems: any[] = [];
            let sectorTaskItems: any[] = this.sectorData[i].taskItems;
            for (let j = 0; j < sectorTaskItems.length; j++) {
              let taskItem: any = {};
              taskItem.workTaskId = sectorTaskItems[j].selectedTaskId;
              let workTypes: any[] = [];
              let workTypeItems: any[] = sectorTaskItems[j].selectedWorkTypeIds;
              for (let k = 0; k < workTypeItems.length; k++) {
              let workType: any = {};
              workType.workTypeId = workTypeItems[k].workTypeId;
              workTypes.push(workType)
              }
              taskItem.workTypeIds = workTypes;
              taskItems.push(taskItem);
            }
            item.taskItems = taskItems;
            payload.push(item);
          }
        }
      if (payload.length == 0) {
        throw new Error("select any sector");
      } else {
        return payload;
      }
  }

  public previewPdf = (sectorData: any) => {
    let payload: any = this.constructPayload(sectorData);
    this.applicationService.previewSectorWorkitemReport('civil', payload, this.startDate, this.endDate).subscribe(res => {
      console.log("rses", res);
      window.open(res.fileURL);
    })
  }

  public onAddTaskSelect(sector: any, index: number) {
    if(!sector.mainActivityId) {
      throw new Error("Main activity must not empty");
    }else {
      let data = {};
      data['sectorRowIndex'] = index;
      data['sectorData'] = sector;
      data['heading'] = this.sectorData[index].dialogHeading;
      this.openAddVillageDialog(data);
    }
  }

  public openAddVillageDialog(data: any) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.height = '80vh';
    dialogConfig.width = '80vw';
    dialogConfig.data = data;
    const dialogRef = this.dialog.open(DpaWorkItemDialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(res => {
      console.log(res);
      if (res) {
        if (!this.sectorData[res.sectorRowindex].taskItems) {
          this.sectorData[res.sectorRowindex].taskItems = [];
        }
        if(res.isNewTask) {
          this.sectorData[res.sectorRowindex].taskItems.push(res.row);
        } else {
          this.sectorData[res.sectorRowindex].taskItems[res.row.taskTableRowIdx] = res.row;
        }
        console.log(this.sectorData)
      }
    })
  }

  onTaskDelete(e: any, sectorIdx: number, villageIdx: number) {
    this.sectorData[sectorIdx].taskItems.splice(villageIdx, 1);
  }

  clearSelectedSectorData(sectorData: any) {
    sectorData.functionTypeId = null;
    sectorData.mainActivityId = null;
    sectorData.workTypeId = null;
    sectorData.taskItems = [];

  }

  onSectorSelected(event: any, data: any, index: number) {
    if (!event.checked) {
      if (data) {
        this.clearSelectedSectorData(data);
      }
    }
  }
}

