<div class="content-area">
  <div class="col-sm-10 offset-sm-1">
      <app-top-bar selectedTab="home"></app-top-bar>
      <div class="heading">
        {{previousHeading}} / {{title}}
    </div>
      <app-layout-map></app-layout-map>
  </div>
 <app-sector-tile></app-sector-tile>
 </div>
