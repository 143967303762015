<div class="container">
  <div>
      <app-top-bar selectedTab="home"></app-top-bar>
      <app-header></app-header>
  </div>
  <br>
  <div class="container">
  <div class="d-flex justify-content-between">
    <div class="refresh-btn">
      <button mat-button class="btn btn-primary" (click)="clickBack()">Back</button>
      <div class="col-sm-1 ">
        <button class="btn btn-primary refresh-btn" (click)="refreshClick()">
            <fa-icon [icon]="faSync" class="close-icon" [spin]="isRefreshing"></fa-icon>
        </button>
      </div>
    </div>
    <div class="col-sm text-center ml-5 my-auto">
      <h2 class="pl-5 title">{{heading}}</h2>
    </div>
    <div class="col-sm-2 offset-sm-1 user-details">
      <div class="value-text">
        Welcome <br>
        {{user['firstName']}} !
      </div>
    </div>
  </div>
  <div class="col-sm title" >
    Daily Updates from the field – Civil Work
  </div>
  <div *ngIf="roleCode != 'secon_user'">
    <button mat-button class="btn btn-primary" style="  background-color: #FC6600;" (click)="onGraphicalRepClicked()">Graphical Representation</button>
  </div>
  <div>
    <section>
      <div fxLayout="row" fxLayoutAlign="space-between">
        <div class="col-sm-4 offset-sm-8">
          <div fxLayout="row" fxLayoutAlign="space-around">
            <input class="form-control border-end-0 border rounded-pill" style="font-size: 14px !important; width: 14rem;" type="text"
              [(ngModel)]="dapNo" placeholder="Search by DAP No" id="dapNo">
            <span class="input-group-append"><br>&nbsp;
              <button mat-button class="btn btn-primary " (click)="searchDAPNo()">Search</button>
              <button mat-button *ngIf="dapNo" matSuffix mat-icon-button aria-label="Clear" (click)="onClear()">
                <mat-icon>close</mat-icon>
              </button>
            </span>
          </div>
        </div>
      </div>
      <div fxLayout="row" fxLayoutAlign="space-between" style="margin-top: 20px;">
        <div class="col-sm-4 offset-sm-8">
          <div fxLayout="row" fxLayoutAlign="space-around">
            <input class="form-control border-end-0 border rounded-pill" style="font-size: 14px !important; width: 14rem;" type="text"
              [(ngModel)]="itemNo" placeholder="Search by Item No" id="itemNo">
            <span class="input-group-append"><br>&nbsp;
              <button mat-button class="btn btn-primary " (click)="searchItemNo()">Search</button>
              <button mat-button *ngIf="itemNo" matSuffix mat-icon-button aria-label="Clear" (click)="onClearItemNo()">
                <mat-icon>close</mat-icon>
              </button>
            </span>
          </div>
        </div>
      </div>
      <div class="row justify-content-between">
        <div class="col-6 title">
          <div class="p1">
            <mat-form-field class="w-50" appearance="fill">
              <mat-label>Sector</mat-label>
              <mat-select id="sector" name="selectedSector" [(ngModel)]="sectorId"
                (selectionChange)="getActivityLogs()">
                <mat-option value="">All</mat-option>
                <mat-option *ngFor="let sector of sectorList" [value]="sector.id">
                  {{sector.sectorName}}</mat-option>
              </mat-select>
            </mat-form-field>

          </div>
        </div>
        <div class="col-6 title">
          <div class="p1">
            <mat-form-field class="w-50" appearance="fill">
              <mat-label>Main Activity </mat-label>
              <mat-select id="status" name="mainActivity" [(ngModel)]="mainActivityId" (selectionChange)="onMainActivityChange()">
                <mat-option value="">All</mat-option>
                <mat-option *ngFor="let mainActivity of mainActivityList" [value]="mainActivity.mainActivityId">
                  {{mainActivity.mainActivityName}}
                </mat-option>
              </mat-select>

            </mat-form-field>

          </div>
        </div>
      </div>
      <div class="row justify-content-between">
        <div class="col-6 title">
          <div class="p1">
            <mat-form-field class="w-50" appearance="fill">
              <mat-label>Status</mat-label>
              <mat-select id="status" name="status" [(ngModel)]="status"
                (selectionChange)="getActivityLogs()">
                <mat-option value="">All</mat-option>
                <mat-option *ngFor="let status of statusList" [value]="status">
                  {{status}}</mat-option>
              </mat-select>
            </mat-form-field>

          </div>
        </div>
        <div class="col-6 title">
          <div class="p1">
            <mat-form-field class="w-50" appearance="fill">
              <mat-label>Work Type </mat-label>
              <mat-select id="workTypeId" name="workTypeId" [(ngModel)]="workTypeId" (selectionChange)="getActivityLogs()">
                <mat-option value="">All</mat-option>
                <mat-option *ngFor="let workType of workTypeList" [value]="workType.workTypeId">
                  {{workType.workName}}
                </mat-option>
              </mat-select>

            </mat-form-field>

          </div>
        </div>
      </div>

      <!-- data table -->
      <mat-card style="width: 126%; margin-left: -103px;">
        <table mat-table [dataSource]="dataSource">

          <!-- DAP No Column -->
          <ng-container matColumnDef="sectorNo">
            <th mat-header-cell *matHeaderCellDef> Sector No.</th>
            <td mat-cell *matCellDef="let element ;let i =index;" class="column-container">
              {{element.sectorName}}</td>
          </ng-container>

          <!-- DAP No Column -->
          <ng-container matColumnDef="dpaNo">
            <th mat-header-cell *matHeaderCellDef> DAP No.</th>
            <td mat-cell *matCellDef="let element ;let i =index;" class="column-container">
              <a target="_blank" href="{{element.pdfUrl}}" class="preview-link">{{element.dapNo}}</a></td>
          </ng-container>

          <!-- DAP No Column -->
          <ng-container matColumnDef="itemNo">
            <th mat-header-cell *matHeaderCellDef> Item No. </th>
            <td mat-cell *matCellDef="let element ;let i =index;" class="column-container">
              {{element.taskName}}</td>
          </ng-container>

          <!-- Function Column -->
          <ng-container matColumnDef="function">
            <th mat-header-cell *matHeaderCellDef> Function </th>
            <td mat-cell *matCellDef="let element"> {{element.functionName}} </td>
          </ng-container>

          <!-- Main Column -->
          <ng-container matColumnDef="mainActivity">
            <th mat-header-cell *matHeaderCellDef> Main Activity </th>
            <td mat-cell *matCellDef="let element"> {{element.mainActivityName}} </td>
          </ng-container>

          <!-- Work Column -->
          <ng-container matColumnDef="work">
            <th mat-header-cell *matHeaderCellDef> Work </th>
            <td mat-cell *matCellDef="let element">{{element.work}} </td>
          </ng-container>

          <!-- Work Column -->
          <ng-container matColumnDef="scope">
            <th mat-header-cell *matHeaderCellDef> Scope </th>
            <td mat-cell *matCellDef="let element">{{element.scope}} </td>
          </ng-container>

          <!-- Extent Completed Column -->
          <ng-container matColumnDef="extentCompletedToday">
            <th mat-header-cell *matHeaderCellDef> Extent Completed Today </th>
            <td mat-cell *matCellDef="let element">{{element.extentCompletedToday}} </td>
          </ng-container>

          <!-- progressTillDate Column -->
          <ng-container matColumnDef="progressTillDate">
            <th mat-header-cell *matHeaderCellDef> Progress Till Date </th>
            <td mat-cell *matCellDef="let element"> {{element.progressTillDate}} </td>
          </ng-container>

          <!-- Village Column -->
          <ng-container matColumnDef="unit">
            <th mat-header-cell *matHeaderCellDef> Unit  </th>
            <td mat-cell *matCellDef="let element"> {{element.unit}} </td>
          </ng-container>

          <!-- status Column -->
          <ng-container matColumnDef="status">
            <th mat-header-cell *matHeaderCellDef> Status </th>
            <td [ngStyle]="{ 'color': element.status === 'IN_PROGRESS' ?  '#FF5733' : '#098C0D;' }" mat-cell *matCellDef="let element"> {{element.status}} </td>
          </ng-container>

          <!-- Created On Column -->
          <ng-container matColumnDef="processedOn">
            <th mat-header-cell *matHeaderCellDef> Processed On </th>
            <td mat-cell *matCellDef="let element ;let i =index;" class="column-container">{{element.updatedAt}}
          </ng-container>

          <!-- Created By Column -->
          <ng-container matColumnDef="processedBy">
            <th mat-header-cell *matHeaderCellDef> Processed By </th>
            <td mat-cell *matCellDef="let element ;let i =index;" class="column-container">{{element.engineerInCharge}}
          </ng-container>

          <!-- Created By Column -->
          <ng-container matColumnDef="viewPictureVideo">
            <th mat-header-cell *matHeaderCellDef> Picture/Video </th>
            <td mat-cell *matCellDef="let element ;let i =index;" class="column-container"><a href="{{element.filePath}}" target="_blank">View</a>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>
        <div class="no-records-text" *ngIf="!dataSource.data || dataSource.data.length <= 0">No Records Found!</div>
        <mat-paginator [pageSize]="pageSize" [pageSizeOptions]="[5, 10, 20]" [showFirstLastButtons]="true"
        [pageIndex]="currentPage" [length]="totalSize" (page)="getApplicantPaginatorData($event)">
    </mat-paginator>
      </mat-card>
    </section>
  </div>
  <app-footer></app-footer>
  </div>
</div>