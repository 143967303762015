import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { PageEvent } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { ApplicationService } from 'src/services/application.service';
import { StrorageService } from 'src/services/strorage.service';

@Component({
  selector: 'app-daily-progress-activitylog-datatable',
  templateUrl: './daily-progress-activitylog-datatable.component.html',
  styleUrls: ['./daily-progress-activitylog-datatable.component.scss']
})
export class DailyProgressActivitylogDatatableComponent implements OnInit {
  user: any;
  public pageSize =5;
  public currentPage = 0;
  public totalSize: number= 0;
  applicationNo: any;
  public displayedColumns: string[] = ['sn','pdfname','uploadedOn','uploadedBy'];
  public dataSource = new MatTableDataSource<any>();
  public maskFileName: any;
  
  constructor(private applicationService: ApplicationService, private storageService:StrorageService) { }

  ngOnInit() {
    this.user=this.storageService.getUser();
    this.getActivityLogs();
  }

  public getApplicantPaginatorData(event: PageEvent) {
    this.currentPage = event.pageIndex + 1;
    this.pageSize = event.pageSize;
    this.getActivityLogs()
  }

  getActivityLogs(){
    this.applicationService.getSeconActivityLog(this.currentPage, this.pageSize, this.maskFileName).subscribe(res => {
      console.log('res',res);
      if(res){
        this.refreshDataTable(res);
      }
    })
  }
  searchApplicant(){
    this.resetPagination();
    this.getActivityLogs();
  }
  resetPagination() {
    this.currentPage = 0;
    this.pageSize = 5;
  }

  onClear() {
    this.maskFileName = null;
    this.getActivityLogs();
  }

  private refreshDataTable = (res: any) => {
    this.dataSource.data = res.data;
    this.totalSize = res.totalRecord;
    this.currentPage = (res.currentPage - 1);
    this.dataSource._updateChangeSubscription();
  }

  formattDate = (date:any) =>{
    let pipe = new DatePipe('en-IN');
    return pipe.transform(date, 'dd/MM/yyyy');
  }
}
