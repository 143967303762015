<div>
        <div class="row application-form-row col-sm-12 col-md-12">
            <div class="col-sm-6 offset-12">
            <label class="form-label">12. Is the Award regular : <span class="text-danger"> * </span></label>
            </div>
            <div class="row radio-row col-sm-6 offset-3">
                <div class="row radio-row1 col-sm-6 offset-5">
                    <input id="isAwardRegular" type="radio"  [disabled] = "data && data.isAwardRegular && !isEditable" [(ngModel)]="isAwardRegular" value="Yes" name="isAwardRegular" />
                        <label for="single" class="radio-text">Yes </label>
                    <input id="isAwardRegular" type="radio" [disabled] = "data && data.isAwardRegular && !isEditable" [(ngModel)]="isAwardRegular" value="No" name="isAwardRegular"/>
                        <label for="joint" class="radio-text">No</label>
        </div>
        </div>

        <div class="row application-form-row col-sm-12 col-md-12"  *ngIf="isAwardRegular == 'No'">
            <div class="col-sm-4 offset-12" style="margin-left: 2rem;">
            <label class="form-label">13. If  No, indicate nature of irregularity : </label>
            </div>
            <div class="col-sm-6 offset-4" style="margin-top: -2rem;">
                <mat-checkbox 
                [(ngModel)]="violationOfSupremeCourtA" [disabled] = "data && data.violationOfSupremeCourtA && !isEditable" 
                [checked]="violationOfSupremeCourtA" (ngModelChange)='onCheckboxChange(violationOfSupremeCourtA)'><label class="form-label"> A. Violation of Supreme Court Direction </label>
            </mat-checkbox>
            </div>
        </div>
        <div class="row application-form-row col-sm-12 col-md-12"  *ngIf="violationOfSupremeCourtA == 'true' && isAwardRegular == 'No' || violationOfSupremeCourtA == true  && isAwardRegular == 'No'">
            <div class="col-sm-6 offset-5">
                <mat-checkbox  [(ngModel)]="awardPassedForConstructionB" [disabled] = "data && data.awardPassedForConstructionB && !isEditable" 
                [checked]="awardPassedForConstructionB" ><label class="form-label"> 1.  Award passed for constructions put up after 3-8-2018.Para 6 of Order dt. 3/12/20 </label>
            </mat-checkbox>
            </div>
        </div>
        <div class="row application-form-row col-sm-12 col-md-12"  *ngIf="violationOfSupremeCourtA == 'true' && isAwardRegular == 'No' || violationOfSupremeCourtA == true  && isAwardRegular == 'No'">
            <div class="col-sm-6 offset-5">
                <mat-checkbox 
                [(ngModel)]="buildingConstructedAfterC" [disabled] = "data && data.buildingConstructedAfterC && !isEditable" 
                [checked]="buildingConstructedAfterC"><label class="form-label">
                    2. Buildings constructed after 3/8/2018 which are mentioned  
                        in survey & engineer report and sketch,<br> but not discussed  
                        in the award, para of 14-7-2021 Order.</label>
            </mat-checkbox>
            </div>
        </div>
        <div class="row application-form-row col-sm-12 col-md-12"  *ngIf="violationOfSupremeCourtA == 'true' && isAwardRegular == 'No' || violationOfSupremeCourtA == true  && isAwardRegular == 'No'">
            <div class="col-sm-6 offset-5">
                <mat-checkbox 
                [(ngModel)]="awardPassedInFavourKatheadarD" [disabled] = "data && data.awardPassedInFavourKatheadarD && !isEditable" 
                [checked]="awardPassedInFavourKatheadarD"><label class="form-label">3. Award passed in favour of Kathedar/GPA 60:40 scheme  
                    offered to him/her<br> even though revenue layout formed, 
                    Para 10 & 11 of the Order dt.5/5/2021</label>
            </mat-checkbox>
            </div>
        </div>
        <div class="row application-form-row col-sm-12 col-md-12" *ngIf="violationOfSupremeCourtA == 'true' && isAwardRegular == 'No' || violationOfSupremeCourtA == true  && isAwardRegular == 'No'">
            <div class="col-sm-6 offset-5">
                <mat-checkbox 
                [(ngModel)]="awardPassedInApplicationSubmittedE" [disabled] = "data && data.awardPassedInApplicationSubmittedE && !isEditable" 
                [checked]="awardPassedInApplicationSubmittedE"><label class="form-label"> 4. Award passed in respect of buildings where application 
                    submitted to JCC. </label>
            </mat-checkbox>
            </div>
        </div>
        <div class="row application-form-row col-sm-12 col-md-12"  *ngIf="violationOfSupremeCourtA == 'true' && isAwardRegular == 'No' || violationOfSupremeCourtA == true  && isAwardRegular == 'No'">
            <div class="col-sm-6 offset-5">
                <mat-checkbox 
                [(ngModel)]="awardPassedInApplicationNotSubmittedF" [disabled] = "data && data.awardPassedInApplicationNotSubmittedF && !isEditable" 
                [checked]="awardPassedInApplicationNotSubmittedF"><label class="form-label">5. Award passed in respect of buildings where  application not
                    submitted to JCC</label>
            </mat-checkbox>
            </div>
        </div>
        <div class="row application-form-row col-sm-12 col-md-12"  *ngIf="violationOfSupremeCourtA == 'true' && isAwardRegular == 'No' || violationOfSupremeCourtA == true  && isAwardRegular == 'No'">
            <div class="col-sm-6 offset-5">
                <mat-checkbox 
                [(ngModel)]="awardNotDiscussedMentionedReportG" [disabled] = "data && data.awardNotDiscussedMentionedReportG && !isEditable" 
                [checked]="awardNotDiscussedMentionedReportG"><label class="form-label">6. Buildings constructed in the acquired area which are mentioned in Surveyor and Engineer
                    <br> report, but not discussed in the Award.</label>
            </mat-checkbox>
            </div>
        </div>
        <div class="row application-form-row col-sm-12 col-md-12" *ngIf="isAwardRegular == 'No'">
            <div class="col-sm-4 offset-12" style="margin-left: 2rem;">
            </div>
            <div class="col-sm-6 offset-4" >
                <mat-checkbox 
                [(ngModel)]="violationOfLaqG" [disabled] = "data && data.violationOfLaqG && !isEditable" 
                [checked]="violationOfLaqG"(ngModelChange)='onViolationCheckboxChange(violationOfLaqG)'><label class="form-label">B. Violation of LAQ Act </label>
            </mat-checkbox>
            </div>
        </div>
        <div class="row application-form-row col-sm-12 col-md-12"  *ngIf="violationOfLaqG == 'true' && isAwardRegular == 'No' || violationOfLaqG == true && isAwardRegular == 'No'">
            <div class="col-sm-6 offset-5">
                <mat-checkbox 
                [(ngModel)]="awardPassedGovtLandH" [disabled] = "data && data.awardPassedGovtLandH && !isEditable" 
                [checked]="awardPassedGovtLandH"><label class="form-label"> 1.  Award passed for Govt. land </label>
            </mat-checkbox>
            </div>
        </div>
        <div class="row application-form-row col-sm-12 col-md-12" *ngIf="violationOfLaqG == 'true' && isAwardRegular == 'No' || violationOfLaqG == true && isAwardRegular == 'No'">
            <div class="col-sm-6 offset-6">
                <mat-checkbox 
                [(ngModel)]="awardPassedKharabLandI" [disabled] = "data && data.awardPassedKharabLandI && !isEditable" 
                [checked]="awardPassedKharabLandI"><label class="form-label">  (a). Award passed for Kharab A & B lands.</label>
            </mat-checkbox>
            </div>
        </div>
        <div class="row application-form-row col-sm-12 col-md-12" *ngIf="violationOfLaqG && isAwardRegular == 'No' || violationOfLaqG && isAwardRegular == 'No'">
            <div class="col-sm-6 offset-6">
                <mat-checkbox 
                [(ngModel)]="awardPassedGomalJ" [disabled] = "data && data.awardPassedGomalJ && !isEditable" 
                [checked]="awardPassedGomalJ"><label class="form-label">  (b). Award passed for Gomal/ Muffath kaval/ Inam Land. </label>
            </mat-checkbox>
            </div>
        </div>
        <div class="row application-form-row col-sm-12 col-md-12"*ngIf="violationOfLaqG && isAwardRegular == 'No' || violationOfLaqG && isAwardRegular == 'No'">
            <div class="col-sm-6 offset-6">
                <mat-checkbox 
                [(ngModel)]="awardPassedContravationK" [disabled] = "data && data.awardPassedContravationK && !isEditable" 
                [checked]="awardPassedContravationK"><label class="form-label"> (c). Award passed in contravention of section 79 (a) &(b) of  
                    LRF Act, and RTC <br> in the name of Government.</label>
            </mat-checkbox>
            </div>
        </div>
        <div class="row application-form-row col-sm-12 col-md-12" *ngIf="violationOfLaqG && isAwardRegular == 'No' || violationOfLaqG && isAwardRegular == 'No'">
            <div class="col-sm-6 offset-6">
                <mat-checkbox 
                [(ngModel)]="awardPassedRevenueActL" [disabled] = "data && data.awardPassedRevenueActL && !isEditable" 
                [checked]="awardPassedRevenueActL"><label class="form-label">(d). Award passed for the land to Govt. under Land 
                    Revenue Act. </label>
            </mat-checkbox>
            </div>
        </div>
        <div class="row application-form-row col-sm-12 col-md-12" *ngIf="violationOfLaqG && isAwardRegular == 'No' || violationOfLaqG && isAwardRegular == 'No'">
            <div class="col-sm-6 offset-5">
                <mat-checkbox 
                [(ngModel)]="awardPassedForRtcM" [disabled] = "data && data.awardPassedForRtcM && !isEditable" 
                [checked]="awardPassedForRtcM"><label class="form-label"> 2. Award passed for lands where there is  in Col (3) and (9) of RTC.</label>
            </mat-checkbox>
            </div>
        </div>
        <div fxLayout="row" style="margin-top:8%;margin-left: -102%;">
            <button fxFlexOffset="70" class="btn btn-next" (click)="onSubmit()" >Save & Continue</button>
        </div>
       </div>
       