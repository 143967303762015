<div class="content-area col-sm-10 offset-sm-1">
<div class ="container">
  <div class="row content-area">
    <div class=" title col-sm-10 offset-sm-1 ">
      <div class="col-sm-12">
          <h5 class="header-text">AWARD SCRUTINY WORKBENCH</h5>
          <label class="info-title">AWARD SKL NO : {{awardSklNumber}}</label>
            <div class="row header">
            </div>
            <div class="col-sm-6 Village">
              <div fxLayout="row">
                <button fxFlexOffset="-16%" class="btnn btnn-next" style="margin-top:-17%;" (click)="onBack()">Back</button>
            </div>
              <label class="aaa">Village Name: {{villageName}}</label>
            </div>
      <div class="col-sm-6 offset-sm-9 Survey">
        <div class="rows key-text">
            Survey No : {{surveyNumber}}
        </div>
        <div class="rows key-text">
          Award No: {{awardNumber}}
        </div>
      </div>

      <div class="col-sm-5 offset-sm-8">
       <div class=" value-texts" >
           <span>{{survey_no ? survey_no : ' ' }}<span>    {{survey_noOption ? (" - " + survey_noOption) : ""}}</span></span>
       </div>
      </div>
     </div>
    <!-- </div> -->
  <div fxLayout="row" fxLayoutGap="6px" style="margin-left:-5%;">
    <div *ngFor="let menu of menus" (click)="openSelectedMenu(menu)">
        <mat-card [class.form-selected-card]="menu.active"
        [class.form-not-selected-card]="!menu.active">
                {{menu.name}}
        </mat-card>
    </div>
</div>
      <!-- new code-->
      <!-- <div class="content-area col-sm-12">
        <div class ="container">
          <div class="row content-area"> -->
      <div *ngIf="selectedMenu">
          <div class="jcc-card">
            <div fxLayout="row">
                  <app-basic-info  *ngIf="selectedMenu['code'] == 'basic_info' || showBasicInfo" fxFlex="100%" (newApplicantCallback)="updateUI($event)" (nextScreenCallback)="nextScreenCallback($event)"></app-basic-info>
                  <app-award-details *ngIf="(selectedMenu['code'] == 'award_details' && showAwardDetails)"></app-award-details>
                  <app-structure-details  *ngIf="(selectedMenu['code'] == 'structure_details' && showStructureDetails )" fxFlex="100%" (nextScreenCallback)="nextScreenCallback($event)"></app-structure-details >
                  <app-award-enclosure *ngIf="(selectedMenu['code'] == 'enclosures' && showEnclosureDetails)" fxFlex="100%" (nextScreenCallback)="nextScreenCallback($event)"></app-award-enclosure >
                    <app-award-analysis *ngIf="(selectedMenu['code'] == 'award_analysis' && showAwardAnalysisDetails)" fxFlex="100%" (nextScreenCallback)="nextScreenCallback($event)"></app-award-analysis >
                    <app-preview *ngIf="(selectedMenu['code'] == 'preview' && showPreviewDetails)" fxFlex="100%" (nextScreenCallback)="nextScreenCallback($event)" (oneTimeEditCallback)="oneTimeEditCallback($event)"></app-preview>
             </div>
</div>
      </div>
      </div>
      </div>
    <!-- </div>
  </div>
  </div> -->
  <app-footer></app-footer>
</div>
</div>

