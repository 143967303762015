<div class ="container">
    <div class="row content-area"><br>
        <br>
        <br>
        <div class="col-sm-12 title" style="padding-right: 148px;">
            <div *ngIf="notificationType == 'OLD'">
                SCRUTINY WORKBENCH - Old Notification
            </div>
            <div *ngIf="notificationType == 'NEW'">
                SCRUTINY WORKBENCH - New Notification
            </div>
        </div>
        <div class ="col-sm-11">
        <app-applicant-view [data]=applicationData></app-applicant-view>
       
            <br>
            <div class="label col-sm-10" *ngIf = "applicationData">  
            <div class="row">
                <div class="col-sm-8">
                    <label >Comments</label>
                </div>
                <div class="col-sm-4">
                    <label >{{applicationData.activeStatus.comment}}</label>
                  </div>
            </div>
            <br>
            <div>
            <br>
            <div class="row" >
                <div class="col-sm-8 ">
                    <label>Status</label>
                </div>
                <div class="col-sm-4">
                    <label class="color">{{applicationData.activeStatus.statusVO.status}}</label>
                  </div>
            </div>
           <br>
            <div fxLayout="row" fxLayoutGap="30px" fxLayoutAlign="space-around">
                <div *ngFor="let message of commentTypes">
                    <mat-checkbox [(ngModel)]="message.checked" [disabled]=disable>{{message.landType}}</mat-checkbox>
                </div>
            </div>
             <br>
            </div>
            </div>
            </div>
        </div>
        
    </div>
    

    