import { DatePipe, DOCUMENT } from '@angular/common';
import { Component, Inject, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ApplicationService } from 'src/services/application.service';
import { StrorageService } from 'src/services/strorage.service';
import { faCheck, faTimes, faTrash } from '@fortawesome/free-solid-svg-icons';
import { ConfirmDialogService } from 'src/app/shared/confirm-dialog/confirm-dialog.service';
import { MatSnackBar } from '@angular/material/snack-bar';


@Component({
  selector: 'app-secon-pdf-upload',
  templateUrl: './secon-pdf-upload.component.html',
  styleUrls: ['./secon-pdf-upload.component.scss']
})
export class SeconPdfUploadComponent implements OnInit {

  constructor(private storageService:StrorageService, private applicationService: ApplicationService,  
    public confirmDialogService: ConfirmDialogService,private _snackBar: MatSnackBar,
    private router:Router, @Inject(DOCUMENT) private document) { 
  }

  userData: any;

  formData = new FormData();

  firstName: string;

  startDate: any;

  toDate: any;

  dpDate: any;

  startDateFormat: any;

  endDateFormat: any;


  public faCheck = faCheck;

  public faTimes = faTimes;

  public faTrash = faTrash;

  public files: any[];

  public isLoading = false;

  maxDate = new Date();

  ngOnInit() {
    window.scroll(0, 0);
    this.userData = this.storageService.getUser();
    if (this.userData) {
      this.firstName = this.userData['firstName'];
    }
    console.log(this.startDate);
  }
  templateData: any[];

  onStartDateChangeEvent(event: any) {
    let startDate = event.value;
    var datePipe = new DatePipe("en-US");
    startDate = datePipe.transform(startDate, 'MM/dd/yyyy');
    this.startDateFormat = startDate;
  }

  public showActivityLog = () => {
    this.router.navigate(['/secon-dp-activitylog']);
  }

  onDateChangeEvent(event: any) {
    // var datePipe = new DatePipe("en-US");
    // this.dpDate = datePipe.transform(event.value, 'dd/MM/yyyy');
  }

  formattDate = (date:any) =>{
    let pipe = new DatePipe('en-IN');
    return pipe.transform(date, 'dd/MM/yyyy');
  }

  openSnackbar(message: string, action: string){
    this._snackBar.open(message, action);
  }

  onFileChanged = (event: any) => {
    const pdfFile: File = event.target.files[0];
    if (pdfFile) {
      this.formData.append("file",pdfFile);

      // const upload$ = this.http.post("/api/thumbnail-upload", formData);

      // upload$.subscribe();
    }
  }

  updateDpDate = (event: any) => {
    this.dpDate = event.value;
    this.formData.delete("date");
    this.formData.append("date", this.formattDate(this.dpDate));
  }

  dismissIconClick = (id: any) => {
    (<HTMLInputElement>this.document.getElementById(id)).value = null;
    if (!this.files || this.files.length <= 0) {
      return;
    }
    this.files.map((row, index) => {
      if (row.name == id) {
        this.files.splice(index, 1);
      }
    })
  }

    upload = () => {
    const options = {
      message: 'Confirm upload & save DP file : JCC_DP_'+this.formattDate(this.dpDate),
      cancelText:'Cancel',
      confirmText: 'Ok'
    };
    this.confirmDialogService.openDialog(options);
    this.confirmDialogService.confirmed().subscribe(confirmed => {
      if(confirmed){
        this.applicationService.uploadSaveSeconPdf(this.formData).subscribe(res => {
          console.log("res", res);
          this.openSnackbar('Successfully Saved', 'Dismiss');
        })
      }
    });
  }

}
