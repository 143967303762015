<div class ="container">
    <div class="row content-area"><br>
        <br>
        <br>
        <div class="col-sm-12 title">
            <div *ngIf = "notificationType == 'OLD'">
                PERSONAL INTERVIEW WORKBENCH - Old Notification
            </div>
            <div *ngIf = "notificationType == 'NEW'">
                PERSONAL INTERVIEW WORKBENCH - New Notification
            </div>
        </div>
        <div class ="col-sm-12">
        <app-applicant-view [data]=applicationData></app-applicant-view>
        <br>
      
        <div class="label col-sm-11" *ngIf = "applicationData">  
            <div class="sub-title">
                Scrutiny Details
                
             </div>
        <div class="row"  >
            <div class="col-sm-8 " >
                <label >Scrutinized By:</label>&nbsp;&nbsp;<b><span >{{applicationData.scrutinyStatus ? applicationData.scrutinyStatus.createdBy : "" }}</span></b>
            </div>
            <div class="row-sm-8 " >
                <label >Date:</label>&nbsp;&nbsp;<b><span>{{applicationData.scrutinyStatus ? applicationData.scrutinyStatus.createdAt :"" }}</span></b>
            </div>
         </div>
            <br>
            <div class="row" >
                <div class="col-sm-8" >
                    <label >Comments</label>
                </div>
                <div class="col-sm-4">
                    <label ><span>{{applicationData.scrutinyStatus ? applicationData.scrutinyStatus.comment : "" }}</span></label>
                  </div>
            </div>
            <br>
            <div>
            <br>

            <div class="row">
                <div class="col-sm-8 ">
                    <label>Status</label>
                </div>
                <div class="col-sm-4">
                    <label class="color"><span>{{applicationData.scrutinyStatus.statusVO ? applicationData.scrutinyStatus.statusVO.status :""}}</span></label>
                  </div>
            </div>
           <br>
            <div fxLayout="row" fxLayoutGap="30px" fxLayoutAlign="space-around">
                <div *ngFor="let message of scrutinyLandTypes">
                    <mat-checkbox [(ngModel)]="message.checked" [disabled]=disable>{{message.landType}}</mat-checkbox>
                </div>
            </div>
             <br>
             <div  *ngIf="applicationData.fieldVisitStatus">
             <div class="sub-title">
                Field Visit Observations
             </div> 
             <br>
             <div class="row" >
                <div class="col-sm-8 " >
                    <label >Referred By:</label>&nbsp;&nbsp;<b>{{applicationData.fieldVisitStatus.createdBy}}</b>
                </div>
                <div class="row-sm-8 " >
                    <label >Date:</label>&nbsp;&nbsp;<b>{{applicationData.fieldVisitStatus.createdAt}}</b>
                </div>
             </div>
            <br>
             <div class="row">
                <div class="col-sm-8" >
                    <label >Comments</label>
                </div>
                <div class="col-sm-4">
                    <label >{{applicationData.fieldVisitStatus.comment}}</label>
                  </div>
            </div>
            <br>
            <div class="row">
                <div class="col-sm-8" >
                    <label >Status</label>
                </div>
                <div class="col-sm-4">
                    <label class="color">{{applicationData.fieldVisitStatus.statusVO.status}}</label>
                  </div>
            </div>
            <br>
             <div fxLayout="row" fxLayoutAlign="space-around">
                <div *ngFor="let message of commentTypes">
                    <mat-checkbox [(ngModel)]="message.checked"[disabled]="true" >{{message.landType}}</mat-checkbox>
                </div>
            </div>
            </div>
            <div class="sub-title">
                Adjudication Observations
             </div> 
             <br>
             <div class="row">
                <div class="col-sm-8 " >
                    <label >Referred By:</label>&nbsp;&nbsp;<b><span>{{applicationData.adjudicationStatus ? applicationData.adjudicationStatus.createdBy : ""}}</span></b>
                </div>
                <div class="row-sm-8 " >
                    <label >Date:</label>&nbsp;&nbsp;<b><span>{{applicationData.adjudicationStatus ? applicationData.adjudicationStatus.createdAt :""}}</span></b>
                </div>
             </div>
            <br>
             <div class="row" >
                <div class="col-sm-8" >
                    <label >Comments</label>
                </div>
                <div class="col-sm-4" >
                    <label ><span>{{applicationData.adjudicationStatus ? applicationData.adjudicationStatus.comment : ""}} </span></label>
                  </div>
            </div>
            <br>
            <div class="row">
                <div class="col-sm-8" >
                    <label >Status</label>
                </div>
                <div class="col-sm-4">
                    <label class="color">{{applicationData.adjudicationStatus && applicationData.adjudicationStatus.statusVO ? applicationData.adjudicationStatus.statusVO.status : ""}}</label>
                  </div>
            </div>
            <br>
            <div class="row">
                <div class="col-sm-8" >
                    <label >Sub Status</label>
                </div>
                <div class="col-sm-4">
                    <label class="color"><span>{{ (applicationData.adjudicationStatus && applicationData.adjudicationStatus.statusOptionVos && applicationData.adjudicationStatus.statusOptionVos.length > 0) ? applicationData.adjudicationStatus.statusOptionVos[0].name : ""}}</span></label>
                  </div>
            </div>
            <!-- pi user status -->
            <div class="sub-title">
                Personal Interview Observations
             </div> 
             <br>
             <div class="row" >
                <div class="col-sm-8 " >
                    <label >Referred By:</label>&nbsp;&nbsp;<b>{{applicationData.activeStatus.createdBy}}</b>
                </div>
                <div class="row-sm-8 " >
                    <label >Date:</label>&nbsp;&nbsp;<b>{{applicationData.activeStatus.createdAt}}</b>
                </div>
             </div>
            <br>
             <div class="row">
                <div class="col-sm-8" >
                    <label >Comments</label>
                </div>
                <div class="col-sm-4">
                    <label >{{applicationData.activeStatus.comment}}</label>
                  </div>
            </div>
            <br>
            <div class="row">
                <div class="col-sm-8" >
                    <label >Status</label>
                </div>
                <div class="col-sm-4">
                    <label class="color">{{applicationData.activeStatus.statusVO.status}}</label>
                  </div>
            </div>
            <br>
            <div class="row" *ngIf="applicationData.activeStatus.statusOptionVos && applicationData.activeStatus.statusOptionVos.length > 0">
                <div class="col-sm-8" >
                    <label >Sub Status</label>
                </div>
                <div class="col-sm-4">
                    <label class="color">{{applicationData.activeStatus.statusOptionVos[0].name}}</label>
                  </div>
            </div>
            </div>
        </div>
        <app-footer></app-footer>
    </div>
    

    
