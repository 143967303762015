import { Component, HostListener, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-decision-making-comment-dialog-box',
  templateUrl: './decision-making-comment-dialog-box.component.html',
  styleUrls: ['./decision-making-comment-dialog-box.component.scss']
})
export class DecisionMakingCommentDialogBoxComponent implements OnInit {

  constructor(private dialogRef: MatDialogRef<DecisionMakingCommentDialogBoxComponent>, 
    @Inject(MAT_DIALOG_DATA) public data: { message: string }) { }

  private response: any = {};
  public comment:any;

  ngOnInit(): void {
  }

  public onOkClicked = () => {
    this.response.confirmed = true;
    this.response.comment = this.comment;
    this.dialogRef.close(this.response);
  }

  public onCancelClicked = () => {
    this.response.confirmed = false;
    this.dialogRef.close(this.response);
  }

  @HostListener("keydown.esc") 
  public onEsc() {
    this.response.confirmed = false;
    this.dialogRef.close(this.response);
  }
}

