<div class="container">
  <div>
      <app-top-bar selectedTab="home"></app-top-bar>
      <app-header></app-header>
  </div>
  <br>
  <div class="">
    <div class="row">
      <div class="col-sm-3">
        <button mat-button class="prep-daily-updates" (click)="routeDpaFunctionalHead()">Back to Functional Heads</button>
      </div>
      <div class="col-sm-5">
        <h1 class="text-center heading-align">Layout Formation – EE Module</h1>
      </div>
      <div class="col-sm-3 ml-5 user-details">
        <div class="value-text">
          Welcome <br>
          {{firstName}} !
        </div>
      </div>
      <div class="w-100"></div>
      <div class="col-sm-10 mb-5">
        <div class="d-flex justify-content-around mt-3">
          <button mat-button class="prep-activities" (click)="onClickDailyUpdates()">Daily Updates from the Field</button>
          <button mat-button class="civil-work" (click)="onClickEEObservation()">EE Observations</button>
        </div>
      </div>
    <div *ngIf="this.showDu" class = "m-auto w-100">
      <app-dpa-activity-log-common></app-dpa-activity-log-common>
    </div>
    </div>
  </div>
  <div class="d-flex justify-content-center">
    <div class="divider"></div>
  </div>
  <app-footer></app-footer>
</div>