<div class="container">
  <div>
      <app-top-bar selectedTab="home"></app-top-bar>
      <app-header></app-header>
  </div>
  <br>
  <div class="">
    <div class="row">
      <div class="col-sm-1">
        <button mat-button class="create-task" (click)="onClickEEmodule()">EE Module</button>
      </div>
      <div class="col-sm-8 ml-5">
        <h1 class="text-center heading-align">Layout Formation – Functional Heads</h1>
      </div>
      <div class="col-sm-2 user-details">
        <div class="value-text">
          Welcome <br>
          {{firstName}} !
        </div>
      </div>
      <div class="w-100"></div>
      <div class="col-sm-10 mb-5">
        <div class="d-flex justify-content-around mt-3">
          <button mat-button class="prep-activities" (click)="prepActivity()">Prep Activities</button>
          <button mat-button class="civil-work" (click)="civilWork()">Civil Work</button>
        </div>

        <div class="d-flex justify-content-end mt-5" *ngIf="showCivilWorkOptions">
          <button mat-button class="create-task mx-5" (click)="createNewTask()">Create New Task</button>
          <button mat-button class="btn-secondary dpaGeneration mx-5" (click)="dpaGeneration()">DAP - Generation</button>
        </div>
      </div>
      
    </div>
  </div>
  <div class="d-flex justify-content-center">
    <div class="divider"></div>
  </div>
  <app-footer></app-footer>
</div>