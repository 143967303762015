<div class="container">
    <div>
        <app-top-bar selectedTab="home"></app-top-bar>
        <app-header></app-header>
    </div>
    <br>
    <div class="tool-bar">
        <div class="col-sm-1 ">
            <button class="btn btn-primary" (click)="refreshClick()">
                <fa-icon [icon]="faSync" class="close-icon" [spin]="isRefreshing"></fa-icon>
            </button>
        </div>
        <div class="col-sm-1 ">
            <button mat-button class="btn btn-primary" (click)="clickBack()">Back</button>

        </div>
        <div class="col-sm-5 title" style="margin-left:5rem;">
            RC & Register Management Module
        </div>

        <br>
         <div class="col-sm-3 offset-sm-1 user-details">
            <div class="value-text">
                Welcome <br>
                {{user['firstName']}} !
            </div>
        </div>
    </div>
    <div>
        <section>
            <div fxLayout="row" fxLayoutAlign="space-between">
                <div class="row justify-content-between">
                    <div class="col-4 title">
                    </div>
                </div>
                <div class="col-sm-3 offset-sm-1">
                </div>
            </div>
            <div class="col-sm-11 title" style="margin-top: -3rem;">
                Activity Log - RC Printed & Received
            </div>
            <div class="row col-sm-12">
                <div class="col-sm-6">
                    <div class="col-sm-8">
                        <button class="btn btn-primary act-log" (click)="fetchRcRegisterActivityLog()">
                            Sent to Manipal
                            <br>
                            <!-- ({{manipalApplicantsCount}}) -->
                        </button>
                    </div>
                </div>
                <div class="col-sm-6">
                    <div class="col-sm-8">
                        <button class="btn btn-primary act-log" (click)="clickRcRegistered()">
                            Received from Manipal
                            <br>
                            <!-- ({{rcRegisteredApplicantsCount}}) -->
                        </button>
                    </div>
                </div>
            </div>

            <!-- data table -->
            <mat-card>
                <table mat-table [dataSource]="dataSource">
                    <ng-container matColumnDef="sn">
                        <th mat-header-cell *matHeaderCellDef> Serial No. </th>
                        <td mat-cell *matCellDef="let element ;let i =index;" class="column-container">{{i+1}}
                    </ng-container>
                    <!-- Position Column -->
                    <ng-container matColumnDef="batchName">
                        <th mat-header-cell *matHeaderCellDef> Batch Name. </th>
                        <td mat-cell *matCellDef="let element">
                            {{element.batchName}}
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="batchSize">
                        <th mat-header-cell *matHeaderCellDef> Batch Size </th>
                        <td mat-cell *matCellDef="let element">
                            {{element.batchSize}}
                        </td>
                    </ng-container>

                    <!-- Name Column -->
                    <ng-container matColumnDef="receivedBy">
                        <th mat-header-cell *matHeaderCellDef> Received By </th>
                        <td mat-cell *matCellDef="let element"> {{element.createdBy}} </td>
                    </ng-container>

                    <!-- Telephone Column -->
                    <ng-container matColumnDef="receivedOn">
                        <th mat-header-cell *matHeaderCellDef> Received On </th>
                        <td mat-cell *matCellDef="let element"> {{element.createdDate}} </td>
                    </ng-container>

                    <ng-container matColumnDef="view">
                        <th mat-header-cell *matHeaderCellDef> view </th>
                        <td mat-cell *matCellDef="let element">
                            <a target="_blank" href="{{element.reportFileUrl}}" class="preview-link pdf-btn">
                                <img src="../assets/pdf-activity.jpeg" class="logo"/>
                            </a>
                            <a target="_blank" href="{{element.excelReportFileUrl}}" class="preview-link pdf-btn">
                                <img src="../assets/excel-activity.jpeg" class="logo"/>
                            </a>
                    </ng-container>
                    <ng-container matColumnDef="action">
                        <th mat-header-cell *matHeaderCellDef  class="data-header"> Actions </th>
                        <td mat-cell *matCellDef="let element" class="column-container">
                            <button *ngIf ="element.statusCode === 'sent_to_manipal'" class="btn btn-primary" (click)="rcReceived(element)">RC Received</button>
                        </td>
                    </ng-container>
                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                </table>
                <div class="no-records-text" *ngIf="!dataSource.data || dataSource.data.length <= 0">No Records Found!
                </div>
                <mat-paginator [pageSize]="pageSize" [pageSizeOptions]="[5, 10, 20]" [showFirstLastButtons]="true"
                    [pageIndex]="currentPage" [length]="totalSize" (page)="getApplicantPaginatorData($event, dataSource.data)">
                </mat-paginator>
            </mat-card>
        </section>
    </div>
    <app-footer></app-footer>
</div>