<div class="row">
    <app-status-option-tiles [statusCode]="statusCode" (onTilesClick)="onTilesClickedCallback($event)"
        (onApiLoaded)="onApiLoaded($event)"></app-status-option-tiles>
    <div class="row">
        <div>
            <div *ngFor="let village of villages; let i = index;" class="village-options">
                <div class="col-sm-8 offset-sm-3" (click)="onVillageChange(village.village)"
                    [class.cursor-style]="type == 'village'">
                    <div class="button " [class.village]="type == 'village'"
                        [style.background-color]=" village.village.id == selectVillage ? colors[i] :'grey'"
                        [style.border-color]=" village.village.id == selectVillage ? 'black' :'grey'"
                        [style.color]=" village.village.id == selectVillage ? 'black' :'#ffffff'">


                        <div>
                            <!-- [ngClass]="{'selectedCard' : village.select}"> -->
                            <div class="center-align">
                                {{village.village.name}}
                            </div>
                            <div class="center-align">
                                Applications count
                            </div>
                            <div class="center-align">
                                {{village.applicationCount}}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>



    </div>



</div>
<br><br>