import { Component, OnInit,ChangeDetectorRef } from '@angular/core';
import { Router } from '@angular/router';
import { ApplicationService } from 'src/services/application.service';
import { faSync } from '@fortawesome/free-solid-svg-icons';
import { PageEvent } from '@angular/material/paginator';
import { StrorageService } from 'src/services/strorage.service';
import { MatTableDataSource } from '@angular/material/table';
import {MatTableModule} from '@angular/material/table';
import { ConfirmDialogService } from '../shared/confirm-dialog/confirm-dialog.service';
@Component({
  selector: 'app-app-transfer-bda',
  templateUrl: './app-transfer-bda.component.html',
  styleUrls: ['./app-transfer-bda.component.scss']
})
export class AppTransferBdaComponent implements OnInit {
public faSync = faSync;
public isRefreshing = false;
public batchSize: number;
public batchName: String;
public pagesize = 5;
public show = false;
public totalSize = 0;
public dataSource = new MatTableDataSource();
public userName ="Username";
public currentPage = 0;
public displayedColumns: string[] = ['reportId','batchName','batchSize','receivedOn','generatedBy','generatedAt', 'actions'];
user: any;
public statusCode: any = "reported";
 
constructor( private cdr:ChangeDetectorRef, private strorageService:StrorageService,
  private applicationService: ApplicationService, private router: Router, 
  private confirmDialogService: ConfirmDialogService){}
 
ngOnInit(): void {

  this.user = this.strorageService.getUser();
    this.userName=this.user['firstName'];

 this.getAllReports();
 
  }

 
  onPostscReceivedbyJccClick() {
    this.statusCode = "reported";
    this.getAllReports();
  }

  onJCCtoBDAClick(){
    this.statusCode = 'sc_ordered_jcc_to_bda_commr';
    this.getAllReports();
  }

  onTransfertoBDAClick(){
    
  }

  public getApplicantPaginatorData(event: PageEvent) {
    this.currentPage = event.pageIndex;
    this.pagesize = event.pageSize;
    this.getAllReports();
  }
  public getAllReports(): void {
    this.applicationService.getAllReports(this.statusCode,this.currentPage, this.pagesize).subscribe(res =>{
      this.dataSource.data = res;
      this.refreshDataTable(res);
})
}

public refreshClick(): void {
  this.getAllReports();
}

    private refreshDataTable = (res: any) => {
      this.show = true;
      this.dataSource.data = res.data;
      this.totalSize = res.totalRecord;
      this.dataSource._updateChangeSubscription();
      this.cdr.detectChanges();
    }

    public transferStatusTo = (row: any, status: any, title: any): void => {
      const options = {
        title: 'Confirm',
        message: 'Do you want to transfer the Batch:'+this.batchName+' to '+title,
        cancelText:'No',
        confirmText: 'Yes'
      };
      this.confirmDialogService.openDialog(options);
      this.confirmDialogService.confirmed().subscribe(confirmed => {
        if(confirmed) {
          this.applicationService.transferStatus(row.reportId, status).subscribe(res =>{
            console.log("Report Successfully generated");
            this.getAllReports();
          })
        }
      });
    }

}
