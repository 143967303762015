import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-layout-sector-package-dashboard',
  templateUrl: './layout-sector-package-dashboard.component.html',
  styleUrls: ['./layout-sector-package-dashboard.component.scss']
})
export class LayoutSectorPackageDashboardComponent implements OnInit {
  selectedSectorCode: any;
  title: any;
  previousHeading: any;

  constructor(private activatedRoute: ActivatedRoute) {
    this.selectedSectorCode = this.activatedRoute.snapshot.queryParams["selectedSectorCode"];
    this.title = this.activatedRoute.snapshot.queryParams["title"];
    this.previousHeading = this.activatedRoute.snapshot.queryParams["previousHeading"]
   }

  ngOnInit() {
  }

}
