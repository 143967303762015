<div class="container">
    <div>
        <app-top-bar selectedTab="home"></app-top-bar>
        <app-header></app-header>
    </div>
    <br>

    <div class="tool-bar">
        <div class="refresh-btn">

        </div>
        <div class="col-sm-1 ">
            <button mat-button class="btn btn-primary" (click)="clickBack()">Back</button>

        </div>
        <div class="col-sm-5 title ">
           Edit Batch Processing  Form
        </div>
    </div><br>
    <div class="d-flex justify-content-center applnum-bold">
        <label>Group No: {{groupNumber}}</label>
        </div>
      <div class="col-sm-4 on-header-left">
        Group Size : {{groupSize}}
       </div>
       <div class="col-sm-4 on-header-right">
        Eligible for Batch processing : {{eligibleApplicantCount}}
       </div>
       <div>
       
    <div *ngFor="let data of dataList; let i=index">
        <hr class="new mt-4">
        <div class="jcc-no">
          <label style="font-size: 16px;"><b>{{i+1}}. {{data.applicationNo}}</b></label>
          </div>
        <div class="sub-title">
        </div>
        <div class="row application-form-row col-sm-12">
            <div class="col-sm-2">
                <label class="form-label">Applicant Name: <span class="text-danger"> * </span></label>
            </div>
            <div class="col-sm-10">
                <input type="string" [(ngModel)]="data.applicantName" class="form-textbox"
                    [readonly]="!isEditable" />
            </div>
        </div>
        <div class="row application-form-row col-sm-12">
            <div class="col-sm-2">
                <label class="form-label">Prop.Address: <span class="text-danger"> * &nbsp;</span></label>
            </div>
            <div class="col-sm-10">
                <input type="string" [(ngModel)]="data.buildingAddress" class="form-textbox"
                    [readonly]="!isEditable" />
            </div>
        </div>
        <div class="row application-form-row col-sm-12">
            <div class="col-sm-2">
                <label class="form-label">Applicant Name:<br> (Kannada) <span class="text-danger"> *
                        &nbsp;</span></label>
            </div>
            <div class="col-sm-10">
            <input type="string" [(ngModel)]="data.applicantNameKannada" class="form-textbox"/>
            </div>
        </div>
        <div class="row application-form-row col-sm-12" style="margin-top:2rem ;">
            <div class="col-sm-6">
                <div class="col-sm-3">
                    <label class="form-label">Village Name:<br> (English) <span class="text-danger"> *
                            &nbsp;</span></label>
                </div>
                <div class="col-sm-4 offset-2" style="margin-top: -2rem;">
                    <select class="form-texttbox" [(ngModel)] = "data.villageId">
                        <option *ngFor="let village of villages"  [value]="village.id" >{{village.id}} {{village.name}}
                        </option>
                    </select>
                </div>
            </div>
            <div class="col-sm-6">
                <div class="col-sm-3">
                    <label class="form-label">Village Name:<br> (Kannada) <span class="text-danger"> *
                            &nbsp;</span></label>
                </div>
                <div class="col-sm-4 offset-2" style="margin-top: -2rem;">
                    <select class="form-texttbox" [(ngModel)] = "data.villageId" >
                        <option *ngFor="let village of villages"  [value]="village.id" >{{village.id}} {{village.villageNameKannada}}
                        </select>
                </div>
            </div>
        </div>
        <div class="row application-form-row col-sm-12" style="margin-top:2rem;">
            <div class="col-sm-6">
                <div class="col-sm-3">
                    <label class="form-label">Sy No: <span class="text-danger"> *
                            &nbsp;</span></label>
                </div>
                <div class="col-sm-4 offset-2" style="margin-top: -2rem;">
                    <input type="string" [(ngModel)]="data.surveyNumber" class="form-texttbox"/>
                </div>
            </div>
            <div class="col-sm-6">
                <div class="col-sm-3">
                    <label class="form-label">Site No: </label>
                </div>
                <div class="col-sm-4 offset-2" style="margin-top: -2rem;">
                    <input type="string" [(ngModel)]="data.siteNumber" class="form-texttbox"/>
                </div>
            </div>
        </div>
        <div class="row application-form-row col-sm-12" style="margin-top:2rem;">
            <div class="col-sm-6">
                <div class="col-sm-3">
                    <label class="form-label">Sital Area: <span class="text-danger"> *
                            &nbsp;</span></label>
                </div>
                <div class="col-sm-4 offset-2" style="margin-top: -2rem;">
                    <input type="string" [(ngModel)]="data.sitalArea" class="form-texttbox" />
                </div>
            </div>
            </div>
    <div>
        <div class="sub-title col-sm-12">
          <div class="col-sm-4 ">
              <label style="font-weight: bold;">SC Info</label>
          </div>
              <div class="col-sm-6 offset-sm-9">
                  <label class="form-label">Active Status: </label>
                  <label><span [ngClass]="{
                      'positive' : data.statusCode != 'cd_done'}">{{data.status ? data.status : ""}}</span></label>
              </div>
      </div>
        <!-- <first line> -->
        <div class="row application-form-row col-sm-12" style="margin-top: 3rem;">
            <div class="col-sm-4">
                <div class="col-sm-4">
                    <label class="form-label">Annexure: <span class="text-danger"> *
                            &nbsp;</span></label>
                </div>
                <div class="col-sm-4 offset-2" style="margin-top: -2rem;">
                    <input type="string" [(ngModel)]="data.annexureName" [disabled]="data.annexureName" class="form-texttbox" readonly/>
                </div>
            </div>
            <div class="col-sm-4">
                <div class="col-sm-5" style="margin-bottom: 7px;">
                    <label class="form-label">Sl.No:(SC Approved list)<span class="text-danger"> *
                            &nbsp;</span> </label>
                </div>
                <div class="col-sm-4 offset-3" style="margin-top: -3rem;">
                    <input type="number" [(ngModel)]="data.scApprovalNo" [disabled]="data.scApprovalNo" class="form-texttbox" readonly/>
                </div>
            </div>
            <div class="col-sm-4">
                    <label class="form-label">Report Dt: <span class="text-danger"> *
                            &nbsp;</span>
                <input type="string" style="width:212px" onkeydown="return false" [(ngModel)]="data.approvalDate" [disabled]="data.approvalDate" class="form-textbox"/>
                </label>
            
        </div>
        <div class="row application-form-row col-sm-12" style="margin-top: 3rem;">           
            <div class="col-sm-4">
                <div class="col-sm-5" >
                    <label class="form-label" style="margin-left: -7px;">Processed By: <span class="text-danger"> *
                            &nbsp;</span></label>
                </div>
                <div class="col-sm-4 offset-3" style="margin-top: -2rem;">
                    <input type="string" [(ngModel)]="data.processedBy" [disabled]="data.processedBy" class="form-texttbox" />
                </div>
            </div>
        </div>
        <div class="sub-title row application-form-row col-sm-12">
            <div class="col-sm-2">
                  <label><b>CD Info</b></label>
              </div>
              <div class="col-sm-4 offset-6">
              <mat-checkbox [(ngModel)]="data.precedenceSitalArea" (change)='precedenceSitalAreaClicked($event)'> <span  style="color:red;" > Sital Area – CD data takes Precedence </span></mat-checkbox>
            </div>
          </div>
        <div class="row application-form-row col-sm-12" style="margin-top: 2rem;">
            <div class="col-sm-4">
                <div class="col-sm-5">
                    <label class="form-label">Length: <span class="text-danger"> *
                            &nbsp;</span></label>
                </div>
                <div class="col-sm-4 offset-3" style="margin-top: -2rem;">
                    <input type="number"   [(ngModel)]="data.lengthAvg"  class="form-texttbox" /> 
                </div>
            </div>
            <div class="col-sm-4">
                <div class="col-sm-5">
                    <label class="form-label">Breadth: <span class="text-danger"> *
                            &nbsp;</span></label>
                </div>
                <div class="col-sm-4 offset-3" style="margin-top: -2rem;">
                    <input type="number"  [(ngModel)]="data.breadthAvg" class="form-texttbox" />
                </div>
            </div>
            <div class="col-sm-4">
                <div class="col-sm-5">
                    <label class="form-label">No of Floors: <span class="text-danger"> *
                            &nbsp;</span></label>
                </div>
                <div class="col-sm-4 offset-3" style="margin-top: -2rem;">
                    <input type="number" [(ngModel)]="data.noOfFloor"  class="form-texttbox" />
                </div>
            </div>
        </div>
        <div class="row application-form-row col-sm-12" style="margin-top: 3rem;">
            <div class="col-sm-4">
                <div class="col-sm-5">
                    <label class="form-label">Sital Area: <span class="text-danger"> *
                            &nbsp;</span></label>
                </div>
                <div class="col-sm-4 offset-3" style="margin-top: -2rem;">
                    <input type="string" [(ngModel)]="data.sitalAreaDimension"  class="form-texttbox" />
                </div>
            </div>
            <div class="col-sm-4">
                <div class="col-sm-5">
                    <label class="form-label">Plinth Area: <span class="text-danger"> *
                            &nbsp;</span> </label>
                </div>
                <div class="col-sm-4 offset-3" style="margin-top: -2rem;">
                    <input type="string" [(ngModel)]="data.plinthArea"  class="form-texttbox"/>
                </div>
            </div>
            <div class="col-sm-4">
                <div class="col-sm-5">
                    <label class="form-label">Build-up Area: <span class="text-danger"> *
                            &nbsp;</span></label>
                </div>
                <div class="col-sm-4 offset-3" style="margin-top: -2rem;">
                    <input type="string" [(ngModel)]="data.totalBuildUpArea"  class="form-texttbox"/>
                </div>
            </div>
        </div>
        <div class="sub-title">
            <div class="col-sm-8 ">
                <label style="font-weight: bold;">Checkbandi</label>
            </div>
        </div>
        <div class="row application-form-row col-sm-12 ">
            <div class="col-sm-6">
                <div class="col-sm-3">
                    <label class="form-label">East: <span class="text-danger"> *
                            &nbsp;</span></label>
                </div>
                <div class="col-sm-4 offset-2" style="margin-top: -2rem;">
                    <input type="string" [(ngModel)]="data.east"  class="form-texttbox" />
                </div>
            </div>
            <div class="col-sm-6">
                <div class="col-sm-3">
                    <label class="form-label"> North: <span class="text-danger"> *
                            &nbsp;</span></label>
                </div>
                <div class="col-sm-4 offset-2" style="margin-top: -2rem;">
                    <input type="string" [(ngModel)]="data.north"  class="form-texttbox" >
                </div>
            </div>
        </div>

        <div class="row application-form-row col-sm-12" style="margin-top:2rem;">
            <div class="col-sm-6">
                <div class="col-sm-3">
                    <label class="form-label">West: <span class="text-danger"> *
                            &nbsp;</span></label>
                </div>
                <div class="col-sm-4 offset-2" style="margin-top: -2rem;">
                    <input type="string" [(ngModel)]="data.west"  class="form-texttbox" />
                </div>
            </div>
            <div class="col-sm-6">
                <div class="col-sm-3">
                    <label class="form-label">South: <span class="text-danger"> *
                            &nbsp;</span></label>
                </div>
                <div class="col-sm-4 offset-2" style="margin-top: -2rem;">
                    <input type="string" [(ngModel)]="data.south"  class="form-texttbox" />
                </div>
            </div>
        </div>
    </div>
</div>
    </div>

        <div class="col-sm-12">
            <div class="container">
                <div class="row">
                    <div class="col-sm-4 offset-sm-5">
                        <button style="margin-top: 36px;" class="btn btn-primary refresh-btn" (click)="onSubmitClick(dataList)">
                            Submit
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <app-footer></app-footer>
