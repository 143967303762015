import { Component, OnInit, Input, ChangeDetectorRef, ViewChild, OnDestroy } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { faSync } from '@fortawesome/free-solid-svg-icons';
import { FormGroup, FormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { Router, ActivatedRoute } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { JccnoEncodeDecodeService } from 'src/services/jccno-encode-decode/jccno-encode-decode.service';
import { UserService } from 'src/services/user-service/user.service';
import { ApplicationService } from 'src/services/application.service';
import { StrorageService } from 'src/services/strorage.service';
import { PageEvent } from '@angular/material/paginator';
import { ApplicantHelperService } from 'src/services/applicant-helper/applicant-helper.service';
import { StaticsComponent } from 'src/app/statics/statics.component';
import { AdjudicationUserTrackActivityService } from '../services/adjudication-user-track-activity.service';
import { ConfirmDialogService } from 'src/app/shared/confirm-dialog/confirm-dialog.service';

@Component({
  selector: 'app-adjudication-pre-dashboard',
  templateUrl: './adjudication-pre-dashboard.component.html',
  styleUrls: ['./adjudication-pre-dashboard.component.scss']
})
export class AdjudicationPreDashboardComponent implements OnInit, OnDestroy {

  @Input() color: string;
  public displayedColumns: string[] = ['sn','applicationNo', 'applicantName', 'recommendationName'];
  @ViewChild(StaticsComponent) staticsComponent;

  public dataSource = new MatTableDataSource();

  public pageSize = 5;
  public currentPage = 0;
  public totalSize = 0;

  public oldNotificationBtn : boolean;

  public newNotificationBtn : boolean;

  public dateFilters = [
    {
      "label":'Pre 25 Nov 2014',
      "subtitle":'(All dates Pre 25 Nov 2014)',
      "tag":"pre_25_nov_2014",
      "color":"#4fa65f",
      'active':false,
      'count': 0,
      "id":1
    },
    {
      "label":'26 Nov 2014 - 3 Aug 2018 ',
      "subtitle":'Between 26 Nov 2014 - 3 Aug 2018',
      "tag":"26_nov_2014_3_aug_2018",
      "color":"#b156e6",
      'active':false,
      'count': 0,
      "id":2
    },
    {
      "label":'Spillover',
      "subtitle":'(Any date Post 3 Aug 2018)',
      "tag":"post_4_aug_2018",
      "color":"#ea6b47",
      "active":false,
      'count': 0,
      "id":3
    }, 
    {
      "label":'No Dates\n',
      "subtitle":'Applications w/o Dates',
      "tag":"no_dates",
      "color":"#15E0F7",
      'active':false,
      'count': 0,
      "id":4
    } 
  ];

  
  public userName = "firstName";
  public villageName="Villagename";
  public show;
  public faSync = faSync;
  public isRefreshing = false;
  public isScrutinyUser;
  public isDateFilterSelected = false;
  public isApplicationSelected=false;
  public selectedButton=false;
  public jjRecommendedByBtn = false;
  public applicants:any[]=[];
  public buttonsTexts:Array<string> = ['First button'];
  enableEdit: boolean = false;
  public isLoading = false;
  public colors=['#FF9633','#11C4E8'];

status = 'Enable';
   
   myGroup = new FormGroup({
    applicationNo: new FormControl()
  
  });
  options: any[] = [];
  filters :any[] = [];
  villageId: any;
  village: any;
  villages : any[];
  recommendations : any[];
  selectedVillages: number;
  selectapp:boolean=false;
  selectedDateFilter: any;
  selectedPlanApproveFilter: any;
  public user:any;
  public title: string;
  totalCount: any[]=[];
  count: any;
  totalRecord:any;
 
  filteredOptions: Observable<any[]>;
  applicationNo=null;
  private adjudicationUserLastActivity: any;
  private applicantServiceApiCallObserver: any;
  notificationType: string = 'OLD';
  oldNotificationApplicantCount: any[];
  newNotificationApplicantCount: any[];
  recommendationApplicantsCount: any;
  recommendedNameId: any;
  private userSelectedRecommendedById:any;

  constructor(private router: Router, private route: ActivatedRoute,public dialog: MatDialog, 
    private jccEncodeDecoder: JccnoEncodeDecodeService,private userService: UserService,
    private applicationService: ApplicationService,private strorageService:StrorageService,
    private cdr:ChangeDetectorRef,private applicantHelper :ApplicantHelperService,
    private adjudicationUserTrackActivityService: AdjudicationUserTrackActivityService,
    private confirmDialogService: ConfirmDialogService) { }

  ngOnInit(): void {
    this.userSelectedRecommendedById = this.adjudicationUserTrackActivityService.getSelectedRecommendedBy();
    if(this.userSelectedRecommendedById) {
      this.recommendedNameId = this.userSelectedRecommendedById;
    }
    this.adjudicationUserLastActivity = this.adjudicationUserTrackActivityService.getLastPreDashboardActivity();
    this.user=this.strorageService.getUser();
    this.fetchVillages();
    this.getDashboardCount();
    this.notificationApplicantsCount();
    this.fetchRecommendationCount();
    this.oldNotificationBtn = true;
    this.setRecommendationByActive();
   }
   
   ngAfterViewInit() {
    
    }
    

  fetchVillages(){
    this.applicantServiceApiCallObserver = this.applicationService.fetchAllVillages().subscribe(res=>{
      this.villages = res;
      this.villages.unshift({
        "id":"",
        "name":"All"
       });
       this.restoreLastUserActivity();
    })
  }
  
  fetchRecommendationCount(){
    this.applicantServiceApiCallObserver = this.applicationService.getRecommendationCount().subscribe(res=>{
      this.recommendations = res;
      this.setRecommendationByActive();
    })
  }

  restoreLastUserActivity(): void {
    if(!this.adjudicationUserLastActivity) {
      return;
    }
    let lastActivity = this.adjudicationUserLastActivity;
    if(lastActivity.villageId) {
      this.villageId = lastActivity.villageId;
      this.onVillageChange();
    }
    if(lastActivity.selectedDateFilter) {
      this.setActiveOrInActive(lastActivity.selectedDateFilter);
      this.clickDateFilter(lastActivity.selectedDateFilter);
    }
  }

  setActiveOrInActive(tag: string): void {
    for(let  i =0;i < this.dateFilters.length;i++) {
      if(this.dateFilters[i].tag == tag) {
        this.dateFilters[i].active = true;
        continue;
      }
      this.dateFilters[i].active = false;
    }
  }

  public getApplicantPaginatorData(event: PageEvent) {
    this.currentPage = event.pageIndex;
    this.pageSize = event.pageSize;
    this.getApplicants();
  }

  onVillageChange(): void{
    this.show=false;
    this.getDashboardCount();
  }

  getDashboardCount() {
    var total=0;
    this.applicantServiceApiCallObserver = this.applicationService.fetchAdjurictionDashboardCount(this.villageId, this.recommendedNameId, this.notificationType).subscribe(res =>{
      console.log("filter count yet to be process",res.data);
      let data = res.data;
      for(let i =0;i < this.dateFilters.length;i++) {
        for(let j =0;j < data.length;j++) {
            if(this.dateFilters[i].tag == data[j].key) {
              this.dateFilters[i].count = data[j].value;
              total = total + parseInt(data[j].value);
              console.log(this.dateFilters[i].count);
              break;
            }
        }
      }

      //don't update when the user click back from workbench screen
      if(!this.adjudicationUserLastActivity || !this.adjudicationUserLastActivity.selectedDateFilter) {
        this.totalSize = total;
        console.log("getDashboardCount()", this.totalSize);
      }
    });
}

getApplicants() {
  this.applicantServiceApiCallObserver = this.applicationService.getApplicantsByStatusList(this.selectedDateFilter, 
    this.currentPage, this.pageSize,this.applicationNo,this.villageId, this.notificationType, this.recommendedNameId).subscribe(res =>{
    this.refreshDataTable(res);
    console.log(res);
 });
}

private refreshDataTable = (res: any) => {
  this.totalSize = res.totalRecord;
  console.log("refreshDataTable()", this.totalSize);
  this.dataSource.data = res.data;
  this.dataSource._updateChangeSubscription();
  if (!this.cdr['destroyed']) {
    this.cdr.detectChanges();
  }
}
    
clickDateFilter = (dateFilterTag: any) => {
  this.selectedDateFilter = dateFilterTag;
  this.recommendedNameId = this.recommendedNameId;
  this.setActiveOrInActive(dateFilterTag);
  this.getApplicants();
  this.show=true;
}

searchApplicant(){
  if(!this.applicationNo || this.applicationNo == '') {
      let options = {};
      options['message'] = 'Please enter application no';
      options['title'] = 'Alert',
      options['confirmText'] = 'OK'
      this.confirmDialogService.openDialog(options);
      this.confirmDialogService.confirmed().subscribe(confirmed => {
        console.log("removed alert dialog");
      });
  } else {
    this.selectedDateFilter = undefined;
    this.show=false;
    this.currentPage = 0;
    this.getApplicants();
    this.show= true;
  }
}

onClear(){
  this.applicationNo="";
  this.show= false;
}
  public showActivityLog = () => {
    this.router.navigate(['/adjudication-activity-log']);
  }
    onPreviewClick = (id: any) => {
      this.adjudicationUserTrackActivityService.setSelectedRecommendedBy(this.userSelectedRecommendedById);
      this.adjudicationUserTrackActivityService.setLastPreDashboardActivity(this.villageId, this.selectedDateFilter);
    //let fullURL = location.protocol+'//'+location.hostname+(location.port ? ':'+location.port: '');
    //window.open(fullURL+'#/adjudication-workbench?application_id='+id+'&title='+this.title, '_blank');
     this.router.navigate(['/adjudication-workbench'], {queryParams:{application_id:id,title: this.title}});
     console.log("query",this.selectedDateFilter)
    }
    clickReportingLink=()=>{
      this.router.navigate(['/adjudication-reporting']);
    }
    refreshClick(){
      let currentComponent = this;
      this.isRefreshing = true;
      setTimeout(()=>{
        currentComponent.isRefreshing = false;
      }, 3000)
      this.getDashboardCount();
     if(this.show){
       this.getApplicants();
     }
     this.oldNotificationBtn = true;
     this.newNotificationBtn = false;
    }

    ngOnDestroy() {
      // for me I was detecting changes using `detectChanges()` inside a subscription with `subscribe()` to observable without limitation, so was enough for me to just `unsubscribe()` like the following line;
      this.applicantServiceApiCallObserver.unsubscribe();
    }

    notificationBtnClick(notificationTypeArg: any) {
      this.notificationType = notificationTypeArg;
      if(this.notificationType == 'NEW') {
        this.newNotificationBtn = true;
        this.oldNotificationBtn = false;
        this.recommendedNameId = null;
        this.getApplicants();
        this.getDashboardCount();
        this.notificationApplicantsCount();
      }else {
        this.newNotificationBtn = false;
        this.oldNotificationBtn = true;
        this.getApplicants();
        this.getDashboardCount();
        this.notificationApplicantsCount();
      }
    }

    notificationApplicantsCount (){
     this.applicationService.fetchNotificationApplicantsCount().subscribe(res=>{
        this.oldNotificationApplicantCount = res['oldNotificationCount'];
        this.newNotificationApplicantCount = res['newNotificationCount'];
      })
    }

    clickRecommendedBy(recommendedNameId: any, data:any) {
      this.recommendedNameId = recommendedNameId;
      this.userSelectedRecommendedById = data.id;
      this.setRecommendationByActive();
      this.getDashboardCount();
      this.getApplicants();
      this.notificationApplicantsCount();
    }
  
    private setRecommendationByActive() {
      if(this.userSelectedRecommendedById == null 
        || this.userSelectedRecommendedById == undefined) {
        return;
      }
      if( this.recommendations != null) {
        for(let i =0;i < this.recommendations.length;i++) {
          this.recommendations[i].active = (this.recommendations[i].id == this.userSelectedRecommendedById) ? true : false;
        }
      }
    }
}
