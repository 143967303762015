<div class="container">
      <div>
          <section>
            <div fxLayout="row" fxLayoutAlign="space-between">
                <div class="col-sm-3 offset-sm-9">
                    <div fxLayout="row" fxLayoutAlign="space-around">
                        <input class="form-control border-end-0 border rounded-pill" style="font-size: 14px !important;"
                            type="text" [(ngModel)]="maskFileName" placeholder="Search" id="maskFileName">
                        <span class="input-group-append"><br>&nbsp;
                            <button mat-button class="btn btn-primary " (click)="searchApplicant()">Search</button>
                            <button mat-button *ngIf="maskFileName"matSuffix mat-icon-button aria-label="Clear" (click)="onClear()">
                                <mat-icon>close</mat-icon>
                            </button>
                        </span>
                    </div>
                </div>
            </div>
           <!-- data table -->
           <mat-card>
              <table mat-table [dataSource]="dataSource">
                  <ng-container matColumnDef="sn">
                      <th mat-header-cell *matHeaderCellDef> Sl. No. </th>
                      <td mat-cell *matCellDef="let element ;let i =index;" class="column-container">{{i+1}}
                        </ng-container>
                        <ng-container matColumnDef="pdfname">
                            <th mat-header-cell *matHeaderCellDef> DP PDF Name  </th>
                            <td mat-cell *matCellDef="let element">
                                <a target="_blank" href="{{element.documentFileUrl}}" class="preview-link">{{element.maskFileName}}</a> 
                            </td>
                        </ng-container>

              <ng-container matColumnDef="uploadedOn">
                  <th mat-header-cell *matHeaderCellDef>Uploaded on .</th>
                  <td mat-cell *matCellDef="let element">
                    {{this.formattDate(element.updatedOn)}}
                  </td>
              </ng-container>

                  <!-- Position Column -->
               
                  
                  <!-- Name Column -->
                  <ng-container matColumnDef="uploadedBy">
                      <th mat-header-cell *matHeaderCellDef> Uploaded by</th>
                      <td mat-cell *matCellDef="let element"> {{element.updatedBy}} </td>
                  </ng-container>
                  
                  <!-- Telephone Column -->
                 
                  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                  <tr mat-row *matRowDef="let row; columns: displayedColumns;" ></tr>
              </table>

              <div class="no-records-text" *ngIf="!dataSource.data || dataSource.data.length <= 0">No Records Found!</div>
              <mat-paginator [pageSize]="pageSize" [pageSizeOptions]="[5, 10, 20]" [showFirstLastButtons]="true" 
              [pageIndex]="currentPage" [length]="totalSize" (page)="getApplicantPaginatorData($event)">
          </mat-paginator>
          </mat-card>
          </section>

</div>
