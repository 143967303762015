<div class="content-area">
    <div class="col-sm-10 offset-sm-1">
        <app-top-bar selectedTab="home"></app-top-bar>
        <div class="heading">
           <p>Layout Formation / Package Sector / {{sectorName}}</p> 
        </div>
        <img src="{{sectorMapUrl}}" width="100%"/>
    </div>
    <div>
        <div class="col-md-9 offset-md-1">
            <div class="row">
                <app-sector-document-tile></app-sector-document-tile>
            </div>
        </div>
    </div>
</div>
