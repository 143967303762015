import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { PageEvent } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { ApplicationService } from 'src/services/application.service';
import { JccnoEncodeDecodeService } from 'src/services/jccno-encode-decode/jccno-encode-decode.service';
import { StrorageService } from 'src/services/strorage.service';
import { UserService } from 'src/services/user-service/user.service';

@Component({
  selector: 'app-adjudication-reporting',
  templateUrl: './adjudication-reporting.component.html',
  styleUrls: ['./adjudication-reporting.component.scss']
})
export class AdjudicationReportingComponent implements OnInit {
  
  public dataSource = new MatTableDataSource();
 
  public displayedColumns: string[] = ['sn','applicationNo', 'village', 'landType'];
  public pageSize = 5;
  public currentPage = 0;
  public totalSize = 0;
  selectedLand:any;
  landList: any = [];
  villageId: any;
  village: any;
  villages : any[];
  selectedVillages: number;
  public user:any;

  constructor(private router: Router, private route: ActivatedRoute,public dialog: MatDialog, 
    private jccEncodeDecoder: JccnoEncodeDecodeService,private userService: UserService,
    private applicationService: ApplicationService,private strorageService:StrorageService,private cdr:ChangeDetectorRef) { }

  ngOnInit(): void {
    this.user=this.strorageService.getUser();
    this.getApplicants();
    this.getLandTypes();
    this.fetchVillages();
  }
  public getApplicantPaginatorData(event: PageEvent) {
    this.currentPage = event.pageIndex;
    this.pageSize = event.pageSize;
    this.getApplicants();
  }
  onLandTypeSelection(event) {
   let landIdList="2,3";
   
    }
    onSelectLand=(landId)=>{
      console.log(this.selectedLand);
      let data = this.selectedLand;
      let landIds = '';
      for(let i=0;i<data.length;i++){
        if(landIds == '') {
          landIds = data[i] + ',';
      }else {
        landIds = landIds + data[i] + ',';
      }
    }
    landIds = landIds.replace(/,\s*$/, "");
    this.applicationService.getReportingApplicants(this.currentPage,this.pageSize,landIds,this.villageId).subscribe(res =>{
      this.totalSize = res.data.totalRecord;
      this.refreshDataTable(res);
    });
  }
   getApplicants=()=>{
    this.applicationService.getReportingApplicants(this.currentPage,this.pageSize,this.selectedLand,this.villageId).subscribe(res =>{
      this.totalSize = res.data.totalRecord;
      this.refreshDataTable(res);
    });
   }
   clickBack(){
    window.history.back();

  }
  onChange(event:any){
   
  }
 
  fetchVillages(){
    this.applicationService.fetchAllVillages().subscribe(res=>{
      this.villages = res;
      this.villages.unshift({
        "id":"",
        "name":"All"
       });
    })
  }
 
  selectVillages(village: any){
    this.getApplicants();
  }
   private refreshDataTable = (res: any) => {
    this.totalSize = res.totalRecord;
    this.dataSource.data = res.data;
    this.dataSource._updateChangeSubscription();
    this.cdr.detectChanges();
  }
  getLandTypes=()=>{
    this.applicationService.getFieldVisitStatus().subscribe(res=>{
      this.landList=res;
      console.log(this.landList);
      
    })
  }
}
