import { Component, HostListener, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { ApplicationService } from 'src/services/application.service';

@Component({
  selector: 'app-dpa-ee-observation-preview-dialogbox',
  templateUrl: './dpa-ee-observation-preview-dialogbox.component.html',
  styleUrls: ['./dpa-ee-observation-preview-dialogbox.component.scss']
})
export class DpaEeObservationPreviewDialogboxComponent implements OnInit {

  public faTimes = faTimes;

  public comments: any;

  eeObservationData: any;

  public sectorName: any;

  public concernSourceName: any;

  imageUrlList: any;
  
  showImage: boolean = false;

  constructor(private dialogRef: MatDialogRef<DpaEeObservationPreviewDialogboxComponent>,private dialog: MatDialog,
    private applicationService: ApplicationService,@Inject(MAT_DIALOG_DATA) 
    public data) { }

  ngOnInit() {
    this.eeObservationData = this.data.eeObservationData;
    this.setValue(this.eeObservationData);
  }

  public onOkClicked = () => {
    let data = {};
    data['confirmed'] = true;
    this.dialogRef.close(data);
  }

  public onCancelClicked = () => {
    let data = {};
    data['confirmed'] = false;
    this.dialogRef.close(data);
  }

  @HostListener("keydown.esc") 
  public onEsc() {
    this.dialogRef.close(false);
  }

  public setValue(data: any) {
    this.sectorName = data.sectorName;
    this.concernSourceName = data.concernSourceName;
    this.comments = data.comments;
    this.imageUrlList = data.imageUrlList;
  }

  showImageOnClick() {
    this.showImage = true;
  }

}
