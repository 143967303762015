import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { faSync } from '@fortawesome/free-solid-svg-icons';
import { ApplicationService } from 'src/services/application.service';
import { PageEvent } from '@angular/material/paginator';
import { MessageDialogService } from 'src/app/shared/message-dialog/message-dialog.service';
import { ConfirmDialogService } from 'src/app/shared/confirm-dialog/confirm-dialog.service';
import { Location } from '@angular/common';
import { Router, ActivatedRoute } from '@angular/router';
import { StrorageService } from 'src/services/strorage.service';
import { DownloadReport } from './download-report';

@Component({
  selector: 'app-community-view-applicants',
  templateUrl: './community-view-applicants.component.html',
  styleUrls: ['./community-view-applicants.component.scss']
})
export class CommunityViewApplicantsComponent implements OnInit {

  public isRefreshing = false;
  public faSync = faSync;
  public selectedDateFilter: any;
  villages : any[];
  public applicationNo: any;
  public villageId: any;
  public displayedColumns: string[] = ['select_all','siNo', 'applicationNo','applicantName','address', 'surveyNumber','type', 'planSanctioned', 'builtUpArea', 'sitalAreas'];
  public dataSource = new MatTableDataSource();
  public show = false;
  public pagesize = 5;
  public currentPage = 0;
  public totalSize = 0;
  public selectVillage;
  public applicantCount : any;
  public selectedId : any;
  public pageSize = 5;
  public batchSize: number;
  public batchName: String;
  public statusCode: any = 'ready_for_reporting';
  public reportStartPageNumber: number;
  public selectedApplicants: any = [];
  public selectedVillageApplicants: any = [];
  public userName = "Username";
  public selectedReportNameId: any;
  public selectedReportTitleId: any;
  public reportNames: any = [];
  public reportTitles: any = [];
  fileURL: any;
  title = 'mte-test';
  user:any;
  public colors = ['#E74C3C', '#1ABB9C', '#3498DB', '#F39C12', '#9B59B6', '#50C1CF', '#34495E', '#E9967A', '#FA8072', '#E74C3C','#E74C3C', '#1ABB9C', '#3498DB', '#F39C12', '#9B59B6', '#50C1CF', '#34495E'];
  public serialNo: number;
  statusOptionId: any;
  preview: any;
  subStatus: any;
  
  constructor(private applicationService: ApplicationService, private router: Router,private strorageService:StrorageService,
    private location: Location,private route: ActivatedRoute, private messageDialogService: MessageDialogService,private confirmDialogService: ConfirmDialogService,
    private cdr:ChangeDetectorRef)
     {
      this.statusCode = this.route.snapshot.queryParams['statusCode'];
      console.log(this.statusCode);
      this.statusOptionId = this.route.snapshot.queryParams['statusOptionId'];
      this.subStatus = this.route.snapshot.queryParams['subStatus'];
      }

  ngOnInit(): void {
    this.dataSource = new MatTableDataSource();
    this.user = this.strorageService.getUser();
    this.userName=this.user['firstName'];
    this.getAllApplicants();
    this.fetchVillages();
    this.getReportNameConfigList();
    this.getReportTitleConfigList();
  }

  public getAllApplicants(): void {
    this.selectedVillageApplicants = [];
    this.applicationService.getAllApplicants(this.statusCode,this.applicationNo,this.villageId,this.statusOptionId).subscribe(res =>{
      this.dataSource.data = res;
      this.refreshDataTable(res);

    })
  }

  public refreshClick(): void {
    this.getAllApplicants();
  }

  exportTable(){
    DownloadReport.exportToPdf("ExampleTable");
  }

  onClear(){
    this.applicationNo="";
    this.show= false;
    this.getAllApplicants();
  }

  public goBack(): void {
    this.location.back();
  }

  public getApplicantPaginatorData(event: PageEvent) {
    this.currentPage = event.pageIndex;
    this.pagesize = event.pageSize;
    this.getAllApplicants();
  }

  private setSelectDefaultValue(data: any): void {
    for(let i = 0;i < data.length;i++) {
      data[i].checked = true;
      for(let j = 0;j < this.selectedApplicants.length;j++) {
        if(this.selectedApplicants[j].applicationNo == data[i].applicationNo) {
          data[i].checked = true;
        }
      }
      this.selectOrDeSelectApplicants(null, data[i]);
    }
  }

  private refreshDataTable = (res: any) => {
     this.setSelectDefaultValue(res);
     this.dataSource.data = res;
     this.dataSource._updateChangeSubscription();
     this.cdr.detectChanges();
   }
   searchApplicant(){
    this.getAllApplicants();
  }

  selectOrDeSelectApplicants(event:any, element: any) {
    if(this.selectedApplicants.length == 0) {
      if(element.checked) {
        this.selectedApplicants.push(element);
      }
    } else {
      let idx = -1;
      for(let i = 0;i < this.selectedApplicants.length;i++) {
        if(this.selectedApplicants[i].applicationNo == element.applicationNo) {
          idx = i;
          break;
        }
      }
      if(idx == -1) {
        if(element.checked) {
          this.selectedApplicants.push(element);
        }
      } else {
        if(!element.checked) {
          this.selectedApplicants.splice(idx, 1);
        } else {
          this.selectedApplicants[idx].checked = element.checked;
        }
      }
    }
    this.batchSize = this.selectedApplicants.length;
    this.selectOrDeselectVillageApplicants(event, element);
  }

  selectOrDeselectVillageApplicants(event:any, element: any) {
    if(this.selectedVillageApplicants.length == 0) {
      if(element.checked) {
        this.selectedVillageApplicants.push(element);
      }
    } else {
      let idx = -1;
      for(let i = 0;i < this.selectedVillageApplicants.length;i++) {
        if(this.selectedVillageApplicants[i].applicationNo == element.applicationNo) {
          idx = i;
          break;
        }
      }
      if(idx == -1) {
        if(element.checked) {
          this.selectedVillageApplicants.push(element);
        }
      } else {
        if(!element.checked) {
          this.selectedVillageApplicants.splice(idx, 1);
        } else {
          this.selectedVillageApplicants[idx].checked = element.checked;
        }
      }
    }
    this.totalSize = this.selectedVillageApplicants.length;
  }

  parseAllSelectedJccNo(): any {
    let jccNos = [];
    for(let i = 0;i < this.selectedApplicants.length;i++) {
      jccNos.push(this.selectedApplicants[i].applicationNo);
    }
    return jccNos;
  }

  public getReportTitleConfigList(): void {
    this.applicationService.getReportTitleConfigList().subscribe(data => {
      this.reportTitles = data;
    });
  }

  public getReportNameConfigList(): void {
    this.applicationService.getReportNameConfigList().subscribe(data => {
      this.reportNames = data;
    });
  }

  generateReport(): void {
    let jccNos = this.parseAllSelectedJccNo();
    if(jccNos.length == 0) {
      const options = {
        title: 'Alert!',
        message: 'Please select Applicants',
        confirmText: 'OK'
      };
      this.messageDialogService.openDialog(options);
      return;
    }
    else if(!this.batchSize) {
      const options = {
        title: 'Alert!',
        message: 'No Batch size is Entered, Please Enter batch size.',
        confirmText: 'OK'
      };
      this.messageDialogService.openDialog(options);
      return;
    }
     else if(!this.batchName) {
      const options = {
        title: 'Alert!',
        message: 'No Batch name is Entered, Please Enter batch name.',
        confirmText: 'OK'
      };
      this.messageDialogService.openDialog(options);
      return;
    }else if(!this.reportStartPageNumber) {
      const options = {
        title: 'Alert!',
        message: 'Please Enter Report start page number.',
        confirmText: 'OK'
      };
      this.messageDialogService.openDialog(options);
      return;
    }else if(!this.serialNo) {
      const options = {
        title: 'Alert!',
        message: 'Please Enter Serial number.',
        confirmText: 'OK'
      };
      this.messageDialogService.openDialog(options);
      return;
    }
    else if(!this.selectedReportNameId) {
      const options = {
        title: 'Alert!',
        message: 'Please select report name.',
        confirmText: 'OK'
      };
      this.messageDialogService.openDialog(options);
      return;
    }
    else if(!this.selectedReportTitleId) {
      const options = {
        title: 'Alert!',
        message: 'Please Select Report Title.',
        confirmText: 'OK'
      };
      this.messageDialogService.openDialog(options);
      return;
    }
    else{
      const options = {
        title: 'Confirmation!',
        message: 'Confirm Report '+this.selectedReportNameId.reportName+' ' +this.batchName+'.',
        cancelText:'No',
        confirmText: 'Yes'
      };
      this.confirmDialogService.openDialog(options);
      this.confirmDialogService.confirmed().subscribe(confirmed => {
        if(confirmed) {
          let payload = {};
          payload['reportStartPageNumber'] = this.reportStartPageNumber;
          payload['batchName'] = this.batchName;
          payload['batchSize'] = this.batchSize;
          payload['serialNo'] = this.serialNo;
          payload['applicationNos'] = jccNos;
          payload['reportNameConfigId'] = this.selectedReportNameId.id;
          payload['reportTitleConfigId'] = this.selectedReportTitleId;
          this.applicationService.generateReportBySelectedApplicants(payload).subscribe(res =>{
            console.log("Report Successfully generated");
            const resultOptions = {
              title: 'Message!',
              message: 'Report for Batch '+this.batchName+' generated successfully.',
              confirmText: 'OK'
            };
            this.messageDialogService.openDialog(resultOptions); 
            this.router.navigate(['/view-committee-report']);
            return;
          })
        }
      });
     
    }
  

}

previewReport(): void {
  let jccNos = this.parseAllSelectedJccNo();
  if(jccNos.length == 0) {
    const options = {
      title: 'Alert!',
      message: 'Please select Applicants',
      confirmText: 'OK'
    };
    this.messageDialogService.openDialog(options);
    return;
  }
  else if(!this.batchSize) {
    const options = {
      title: 'Alert!',
      message: 'No Batch size is Entered, Please Enter batch size.',
      confirmText: 'OK'
    };
    this.messageDialogService.openDialog(options);
    return;
  }
   else if(!this.batchName) {
    const options = {
      title: 'Alert!',
      message: 'No Batch name is Entered, Please Enter batch name.',
      confirmText: 'OK'
    };
    this.messageDialogService.openDialog(options);
    return;
  }else if(!this.reportStartPageNumber) {
    const options = {
      title: 'Alert!',
      message: 'Please Enter Report start page number.',
      confirmText: 'OK'
    };
    this.messageDialogService.openDialog(options);
    return;
  }else if(!this.serialNo) {
    const options = {
      title: 'Alert!',
      message: 'Please Enter Serial number.',
      confirmText: 'OK'
    };
    this.messageDialogService.openDialog(options);
    return;
  }
  else if(!this.selectedReportNameId) {
    const options = {
      title: 'Alert!',
      message: 'Please select report name.',
      confirmText: 'OK'
    };
    this.messageDialogService.openDialog(options);
    return;
  }
  else if(!this.selectedReportTitleId) {
    const options = {
      title: 'Alert!',
      message: 'Please Select Report Title.',
      confirmText: 'OK'
    };
    this.messageDialogService.openDialog(options);
    return;
  }
  else{
    const options = {
      title: 'Confirmation!',
      message: 'Confirm Preview '+this.selectedReportNameId.reportName+' '+this.batchName+'.',
      cancelText:'No',
      confirmText: 'Yes'
    };
    console.log(this.selectedReportNameId.reportName);
    this.confirmDialogService.openDialog(options);
    this.confirmDialogService.confirmed().subscribe(confirmed => {
      if(confirmed) {
        let payload = {};
        payload['reportStartPageNumber'] = this.reportStartPageNumber;
        payload['batchName'] = this.batchName;
        payload['batchSize'] = this.batchSize;
        payload['serialNo'] = this.serialNo;
        payload['applicationNos'] = jccNos;
        payload['reportNameConfigId'] = this.selectedReportNameId.id;
        payload['reportTitleConfigId'] = this.selectedReportTitleId;
        this.applicationService.previewReportBySelectedApplicants(payload).subscribe(res =>{
          console.log("Report Successfully generated");
          console.log(res);
          let data = res['fileURL'];
          console.log(data);
          window.open(data, '_blank');
          // const resultOptions = {
          //   title: 'Message!',
          //   message: 'Report for Batch '+this.batchName+' generated successfully.',
          //   confirmText: 'OK'
          // };
          // this.messageDialogService.openDialog(resultOptions);
          // this.router.navigate(['/view-committee-report']);
          // return;
        })
      }
    });
   
  }


}
onVillageChange(): void{
  this.totalSize = 0;
  this.getAllApplicants();
}

fetchVillages(){
  this.applicationService.fetchAllVillages().subscribe(res=>{
    this.villages = res;
    this.villages.unshift({
      "id":"",
      "name":"All"
     });
     
  })
}

  unSelectAll(): void {
    for(let i = 0;i < this.dataSource.data.length;i++) {
      this.dataSource.data[i]['checked'] = false;
      this.selectOrDeSelectApplicants(null, this.dataSource.data[i]);
    }
  }
}