<div class="content-area">
  <div class="container">
      <div class="row">
          <div>
              <div *ngFor="let village of villages; let i = index;" class="village-options">
                  <div class="col-sm-12 offset-sm-3" (click)="onVillageChange(village.village.id)" 
                  [class.cursor-style]="type == 'village'">
                      <div class="button " [class.village]="type == 'village'"  
                      [style.background-color]=" village.village.id == selectVillage ? colors[i] :'grey'"
                      [style.border-color]=" village.village.id == selectVillage ? 'black' :'grey'"
                      [style.color]=" village.village.id == selectVillage ? 'black' :'#ffffff'">
                      <div>
                          <div class="center-align">
                              {{village.village.name}}
                          </div>
                          <div  class="center-align">
                              Applications count
                              </div>
                              <div class="center-align">
                                  {{village.applicationCount}}
                              </div>
                              </div>
                      </div>
                  </div>
              </div>
          </div>
      </div>
  </div>
</div> 