import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class PiUserTrackActivityService {


  private lastDashboardActivity: any;

  constructor() { }

  public setLastDashboardActivity = (villageId: any) => {
    this.lastDashboardActivity = {};
    this.lastDashboardActivity.villageId = villageId;
  }

  public getLastDashboardActivity = () => {
    return this.lastDashboardActivity;
  }
}
