import { DatePipe } from '@angular/common';
import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { PageEvent } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { faSync } from '@fortawesome/free-solid-svg-icons';
import { ApplicationService } from 'src/services/application.service';
import { StrorageService } from 'src/services/strorage.service';

@Component({
  selector: 'app-dpa-work-item-activity-log',
  templateUrl: './dpa-work-item-activity-log.component.html',
  styleUrls: ['./dpa-work-item-activity-log.component.scss']
})
export class DpaWorkItemActivityLogComponent implements OnInit {

 
  isRefreshing: boolean;
  user: any;
  applicationNo: any;
  selectedVillageId: any;
  selectedSurveyId: any;
  sectorList : any [] = [];
  sectorId :any = '';
  mainActivityList : any [] = [];
  public pageSize =5;
  public currentPage = 0;
  public totalSize: number= 0;
  public villageId: any = '';
  public mainActivityId: any = '';
  public taskName: any;
  public displayedColumns: string[] = ['sectorNo','itemNo',  'function','mainActivity', 'scop', 'unit','createdBy','createdOn', 'action'];
  public dataSource = new MatTableDataSource<any>();
  public workType: string;
  public title:string = '';
  public faSync = faSync;

  constructor(private applicationService: ApplicationService, 
    private cdr:ChangeDetectorRef,  private storageService:StrorageService, 
    public route: ActivatedRoute,  private router: Router) {
    this.workType = this.route.snapshot.queryParamMap.get("workType");
   }

  ngOnInit() {
    this.user=this.storageService.getUser();
    this.getActivityLogs();
    this.getAllSector();
    this.getAllMainActivity();
  }

  refreshClick(){
    let currentComponent = this;
    this.isRefreshing = true;
    setTimeout(()=>{
      currentComponent.isRefreshing = false;
    }, 3000)
    this.getActivityLogs();
  }

  sectorChange = (sectorId: number) => {
    this.getActivityLogs();
  }

  getActivityLogs(){
    this.applicationService.getWorkTaskItemActivityLog(this.currentPage, this.pageSize, this.sectorId, this.mainActivityId, this.taskName).subscribe(res => {
      console.log('res',res);
        this.refreshDataTable(res);
    })
  }

  private refreshDataTable = (res: any) => {
    this.dataSource.data = res.data;
    this.totalSize = res.totalRecord;
    this.currentPage = (res.currentPage - 1);
    this.dataSource._updateChangeSubscription();
    if (!this.cdr['destroyed']) {
      this.cdr.detectChanges();
    }
  }

  onClear() {
    this.taskName = null;  
    this.getActivityLogs();
  }

  clickBack(){
    this.router.navigate(['/dpa-functional-head']);
  }


  public getApplicantPaginatorData(event: PageEvent) {
    this.currentPage = event.pageIndex + 1;
    this.pageSize = event.pageSize;
    this.getActivityLogs();
  }

  getAllSector() {
    this.applicationService.getAllSectors().subscribe(res => {
      if(res){
        this.sectorList = res;
      }
    })
  }

  getAllMainActivity() {
    this.applicationService.getFunctionTypeMainActivities(2).subscribe(res => {
      console.log("rses", res);
      this.mainActivityList = res;
    })
  
  }

  searchApplicant(){
    this.resetPagination();
    this.getActivityLogs();
  }
  resetPagination() {
    this.currentPage = 0;
    this.pageSize = 5;
  }

  editWorkTaskItem(workTaskItemId: any) {
    this.router.navigate(['/edit-dpa-work-item'],{ queryParams: { workTaskItemId :workTaskItemId} })
  }
}
