import { NgModule } from "@angular/core";
import { ApiLoaderComponent } from "./loading/api-loader/api-loader.component";
import { ErrorDialogComponent } from "./error/error-dialog/error-dialog.component";
import { CommonModule } from "@angular/common";
import { RouterModule } from "@angular/router";
import { ErrorDialogService } from "./error/error-dialog.service";
import { ApiLoaderService } from "./loading/api-loader.service";
import { MaterialModule } from "../material.module";
import { ConfirmDialogComponent } from './confirm-dialog/confirm-dialog/confirm-dialog.component';
import { ConfirmDialogService } from './confirm-dialog/confirm-dialog.service';
import { ApplicantDatatableComponent } from './applicant/applicant-datatable/applicant-datatable.component';
import { TopBarComponent } from '../top-bar/top-bar.component';
import { HeaderComponent } from '../header/header.component';
import { FooterComponent } from '../footer/footer.component';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { HttpClient } from '@angular/common/http';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { RoleConfigService } from './config/role/role.config.service';
import { ApplicantViewComponent } from './applicant/applicant-view/applicant-view.component';
import { ApplicantWorkbenchComponent } from '../applicant-workbench/applicant-workbench.component';
import { FormsModule } from '@angular/forms';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MessageConstantService } from "./services/messageconstant/message-constant.service";
const sharedComponents = [ApiLoaderComponent, ErrorDialogComponent, 
  ConfirmDialogComponent, ApplicantDatatableComponent, TopBarComponent, HeaderComponent, FooterComponent,ApplicantViewComponent, ApplicantWorkbenchComponent];

@NgModule({
  declarations: [...sharedComponents, ApplicantViewComponent],
  imports: [CommonModule, RouterModule, MaterialModule,MatCheckboxModule,FormsModule, 
    TranslateModule.forRoot({
      loader: {
          provide: TranslateLoader,
          useFactory: HttpLoaderFactory,
          deps: [HttpClient]
      }
  })],
  exports: [...sharedComponents],
  providers: [ErrorDialogService, ApiLoaderService, ConfirmDialogService, RoleConfigService, 
  MessageConstantService],
  entryComponents: [...sharedComponents]
})
export class SharedModule {}

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}
